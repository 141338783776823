import React, { useMemo, useState } from "react";
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AssetInvestmentObjectiveDataSheetSection } from "james/marketData";
import { InstrumentRiskProfile } from "james/financial";
import InfoIcon from "@mui/icons-material/Info";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import WebAssetRoundedIcon from "@mui/icons-material/WebAssetRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { SocialMediaLinks } from "james/marketData/AssetInvestmentObjectiveDataSheetSection";
import CloseIcon from "@mui/icons-material/Close";
import { AssetType } from "james/views/marketListingView/Model";
import {
  MarketListingViewModel,
  PublicModel as PublicMarketListingViewModel,
} from "james/views/marketListingView";

const instrumentRiskColors = {
  lowRiskColor: "#acdcdd",
  lowModerateColor: "#ACDDB2",
  moderateColor: "#FFCA83",
  moderateHighColor: "#F27049",
  highColor: "#D94A4B",
};

interface InvestmentObjectiveProps {
  dataSheetSection: AssetInvestmentObjectiveDataSheetSection;
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel;
}

export function AssetInvestmentObjectiveDataSheetSectionRenderer({
  dataSheetSection,
  marketListingViewModel,
}: InvestmentObjectiveProps) {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [riskToolTip, setRiskToolTip] = useState(false);
  //This splits the investment objective on standerd markdown to a string array
  //this allows it to render paragraph breaks
  const investmentObjectiveWithLineBeaks =
    dataSheetSection.investmentObjective.split(`<br>`);
  const isSubscription = useMemo(
    () =>
      [
        AssetType.FixedRateBond,
        AssetType.FloatingRateBond,
        AssetType.Bond,
        AssetType.Note,
        AssetType.PreferenceShare,
        AssetType.Share,
        "",
      ].includes(marketListingViewModel.assetType),
    [marketListingViewModel.assetType],
  );

  const riskProfileColor = useMemo(() => {
    let color = "#000000";
    switch (dataSheetSection.instrumentRiskProfile) {
      case InstrumentRiskProfile.Low:
        color = instrumentRiskColors.lowRiskColor;
        break;

      case InstrumentRiskProfile.LowToModerate:
        color = instrumentRiskColors.lowModerateColor;
        break;

      case InstrumentRiskProfile.Moderate:
        color = instrumentRiskColors.moderateColor;
        break;

      case InstrumentRiskProfile.ModerateToHigh:
        color = instrumentRiskColors.moderateHighColor;
        break;

      case InstrumentRiskProfile.High:
        color = instrumentRiskColors.highColor;
        break;
    }
    return color;
  }, [dataSheetSection.instrumentRiskProfile]);

  return (
    <Box>
      <RiskToolTipDialog
        dialogProps={{ open: riskToolTip }}
        close={() => setRiskToolTip(false)}
        riskColor={riskProfileColor}
        instrumentRiskProfile={dataSheetSection.instrumentRiskProfile}
      />
      <Box
        sx={{
          height: { sm: "auto" },
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { sm: "row", xs: "column" },
          alignItems: "center",
          mr: { sm: 5, xs: 3 },
          gap: 4,
          mt: 4,
          mb: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {/* This space stops some unintended render behaviour leave it be please */}{" "}
          {investmentObjectiveWithLineBeaks.map((line, index) => (
            <Typography
              key={index}
              id="dataSheetSection-investmentObjective-Typography"
              color={"textSecondary"}
              variant={"body1"}
              sx={{
                wordBreak: "break-word",
              }}
            >
              {line}
            </Typography>
          ))}
        </Box>
        {!isSubscription && (
          <Card
            sx={(theme) => ({
              p: 3,
              mt: 2,
              minWidth: "264px",
              height: { sm: "171px" },
              textAlign: { sm: "right", xs: "center" },
              fontSize: "16px",
              color: theme.palette.text.secondary,
              backgroundColor: theme.palette.custom.midnight,
            })}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontSize: 16,
              })}
            >
              Risk Profile
              {!smDown && (
                <IconButton
                  sx={{ ml: 0.5, height: 16, width: 16 }}
                  onClick={() => setRiskToolTip(!riskToolTip)}
                >
                  <InfoIcon sx={{ height: "12px", width: "12px" }} />
                </IconButton>
              )}
            </Typography>
            <Typography
              id="dataSheetSection-riskProfile-Typography"
              variant="h4"
              sx={{
                color: riskProfileColor,
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              {dataSheetSection.instrumentRiskProfile}
              {smDown && (
                <IconButton onClick={() => setRiskToolTip(!riskToolTip)}>
                  <InfoIcon
                    sx={(theme) => ({
                      height: 16,
                      width: 16,
                      ml: 1.5,
                      color: theme.palette.text.secondary,
                    })}
                  />
                </IconButton>
              )}
            </Typography>
            <Divider
              orientation="horizontal"
              sx={(theme) => ({
                my: 2,
                border: `0.5px solid ${theme.palette.text.secondary}`,
                borderRadius: 4,
              })}
            />
            <Typography variant={"h5"} children="Investor Profile" />
            <Typography
              id="dataSheetSection-investorProfile-Typography"
              variant={"h5"}
              sx={(theme) => ({
                fontWeight: "bold",
                color: theme.palette.text.primary,
              })}
              children={dataSheetSection.investorProfile}
            />
          </Card>
        )}
      </Box>
      {/* Link section */}
      {dataSheetSection.socialMediaLinks &&
        (dataSheetSection.socialMediaLinks.youtube ||
          dataSheetSection.socialMediaLinks.linkedin ||
          dataSheetSection.socialMediaLinks.web ||
          dataSheetSection.socialMediaLinks.facebook) && (
          <Links socialMediaLinks={dataSheetSection.socialMediaLinks} />
        )}
    </Box>
  );
}

interface LinksProps {
  socialMediaLinks: SocialMediaLinks;
}

const Links = ({ socialMediaLinks }: LinksProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: { xs: "center", sm: "flex-start" },
        mr: 3,
        mb: 5,
      }}
    >
      <Typography>Be part of the conversation:</Typography>
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "space-around",
          "& > *": {
            display: "flex",
            alignItems: "center",
            mr: { sm: 5 },
            "> *": {
              color: theme.palette.secondary.main,
            },
            "> .text": {
              display: {
                sm: "flex",
                xs: "none",
              },
            },
          },
          //Leaves this here or else the spacing does not exist
          gap: 0,
        })}
      >
        {socialMediaLinks.youtube && (
          <Box
            component={Link}
            underline="none"
            href={socialMediaLinks.youtube}
            sx={{
              mr: { xs: 3 },
              ml: { sm: "-8px", xs: 3 },
            }}
          >
            <IconButton
              sx={{
                color: "inherit",
              }}
            >
              <YouTubeIcon />
            </IconButton>
            <Typography className="text">YouTube</Typography>
          </Box>
        )}
        {socialMediaLinks.facebook && (
          <Box
            component={Link}
            underline="none"
            href={socialMediaLinks.facebook}
            sx={{
              mr: { xs: 3 },
            }}
          >
            <IconButton
              sx={{
                color: "inherit",
              }}
            >
              <FacebookRoundedIcon />
            </IconButton>
            <Typography className="text">Facebook</Typography>
          </Box>
        )}
        {socialMediaLinks.linkedin && (
          <Box
            component={Link}
            underline="none"
            href={socialMediaLinks.linkedin}
            sx={{
              mr: { xs: 3 },
            }}
          >
            <IconButton
              sx={{
                color: "inherit",
              }}
            >
              <LinkedInIcon />
            </IconButton>
            <Typography className="text">LinkedIn</Typography>
          </Box>
        )}
        {socialMediaLinks.web && (
          <Box
            component={Link}
            underline="none"
            href={socialMediaLinks.web}
            sx={{
              mr: { xs: 3 },
            }}
          >
            <IconButton
              sx={{
                color: "inherit",
              }}
            >
              <WebAssetRoundedIcon />
            </IconButton>
            <Typography className="text">Web</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface RiskToolTipDialogProps {
  dialogProps: DialogProps;
  close: () => void;
  instrumentRiskProfile: InstrumentRiskProfile | "";
  riskColor: string;
}

const RiskToolTipDialog = ({
  dialogProps,
  close,
  instrumentRiskProfile,
  riskColor,
}: RiskToolTipDialogProps) => {
  return (
    <Dialog
      {...dialogProps}
      PaperProps={{
        sx: (theme) => ({
          "&.MuiDialog-paper": {
            [theme.breakpoints.down("sm")]: {
              position: "absolute",
              margin: 0,
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: "16px 16px 0 0",
            },
          },
        }),
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          p: 2,
          pr: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Risk Profile</Typography>
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          px: 4,
          pb: 6,
        }}
      >
        <Typography
          sx={{
            mt: 3,
            mb: 2,
            fontSize: 22,
            fontWeight: "bold",
            color: riskColor,
          }}
        >
          {instrumentRiskProfile}
        </Typography>
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.tertiary,
          })}
        >
          {getRiskProfileText(instrumentRiskProfile)}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

const getRiskProfileText = (
  instrumentRiskProfile: InstrumentRiskProfile | "",
): string => {
  switch (instrumentRiskProfile) {
    case InstrumentRiskProfile.Low:
    case InstrumentRiskProfile.LowToModerate:
      return "Investments with a low risk profile are typically associated with stable assets, such as blue-chip stocks, government bonds, or real estate investment trusts. These assets usually have a history of providing reliable returns and are considered to be less volatile and less risky than other types of investments.";
    case InstrumentRiskProfile.Moderate:
    case InstrumentRiskProfile.ModerateToHigh:
      return "Investments with a medium risk profile are typically associated with assets that are more volatile than those with a low risk profile. This category may include some stocks, corporate bonds, and mutual funds that invest in a mix of assets.";
    case InstrumentRiskProfile.High:
      return "Investments with a high risk profile are typically associated with assets that are more volatile and less stable than those with a low or medium risk profile. This category may include emerging market stocks, high-yield bonds, and alternative investments such as hedge funds or private equity.";
  }
  return "";
};
