import { AuditEntry } from "../audit";
import { Amount } from "james/ledger";

export const CouponPaymentRecipientTypeName =
  "mesh::financial/CouponPaymentRecipient";

export class CouponPaymentRecipient {
  public ["@type"]: string = CouponPaymentRecipientTypeName;

  public id = "";
  public couponPaymentID = "";
  public accountLedgerID = "";
  public holdingTokens: Amount = new Amount();
  public principalAmount: Amount = new Amount();
  public interestAmount: Amount = new Amount();
  public auditEntry: AuditEntry = new AuditEntry();

  constructor(c?: CouponPaymentRecipient) {
    if (!c) {
      return;
    }
    this.id = c.id;
    this.couponPaymentID = c.couponPaymentID;
    this.accountLedgerID = c.accountLedgerID;
    this.holdingTokens = new Amount(c.holdingTokens);
    this.principalAmount = new Amount(c.principalAmount);
    this.interestAmount = new Amount(c.interestAmount);
    this.auditEntry = new AuditEntry(c.auditEntry);
  }
}
