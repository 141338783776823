import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  FinancialCouponPaymentViewModel,
  FinancialCouponPaymentViewReader,
  FinancialCouponPaymentViewUnscopedReader,
} from "james/views/financialCouponPaymentView";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useApplicationContext } from "context/Application/Application";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { TextExactCriterion } from "james/search/criterion";
import { Amount } from "components/Ledger/Amount";
import { useSnackbar } from "notistack";
import {
  FileCopy as CopyPasteIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { CouponPaymentStateChip } from "./Chips";
import { CouponPaymentStateController } from "james/financial/CouponPaymentStateController";
import { TransactionTable } from "components/Ledger/Transaction/TransactionTable";
import { ViewTransactionBatch } from "components/Ledger/TransactionBatch/ViewTransactionBatch";
import dayjs from "dayjs";
import { CouponPaymentRecipientTable } from "./CouponPaymentRecipientTable";
import { useErrorContext } from "context/Error";

export type ViewProps = {
  system: boolean;
  height: number;
};

enum ViewTab {
  Overview = "Overview",
  Recipients = "Recipients",
  Funding = "Funding",
  Distribution = "Distribution",
}

const allViewTabs: ViewTab[] = [
  ViewTab.Overview,
  ViewTab.Recipients,
  ViewTab.Funding,
  ViewTab.Distribution,
];

export function ViewCouponPayment(props: ViewProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [couponPaymentViewModel, setCouponPaymentViewModel] = useState<
    FinancialCouponPaymentViewModel | undefined
  >(undefined);
  const [viewTab, setViewTab] = useState<ViewTab>(ViewTab.Overview);

  // navigate to table if no couponPaymentViewModel id query param has been set
  useEffect(() => {
    // try and get a couponPaymentViewModel id from url
    const couponPaymentViewModelIDFromURL = searchParams.get("id");
    if (!couponPaymentViewModelIDFromURL) {
      navigate(window.location.href.replace(/\/view.*$/, "/table"));
      return;
    }
  }, [searchParams.get("id")]);

  const [reload, setReload] = useState(false);
  const [fetchingCouponPaymentViewModel, setFetchingCouponPaymentViewModel] =
    useState(false);
  useLayoutEffect(() => {
    (async () => {
      // try and get a couponPaymentViewModel id from url
      const couponPaymentViewModelIDFromURL = searchParams.get("id");
      if (!couponPaymentViewModelIDFromURL) {
        return;
      }

      // do not perform load if
      if (
        couponPaymentViewModel &&
        // couponPaymentViewModel ID already set correctly
        couponPaymentViewModelIDFromURL === couponPaymentViewModel.id && // AND
        // reload not requested
        !reload
      ) {
        return;
      }

      // otherwise clear reload
      setReload(false);

      // load necessary data
      setFetchingCouponPaymentViewModel(true);
      try {
        setCouponPaymentViewModel(
          props.system
            ? (
                await FinancialCouponPaymentViewUnscopedReader.UnscopedReadOne({
                  context: authContext,
                  criteria: {
                    id: TextExactCriterion(couponPaymentViewModelIDFromURL),
                  },
                })
              ).model
            : (
                await FinancialCouponPaymentViewReader.ReadOne({
                  context: authContext,
                  criteria: {
                    id: TextExactCriterion(couponPaymentViewModelIDFromURL),
                  },
                })
              ).model,
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving couponPaymentViewModel: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setFetchingCouponPaymentViewModel(false);
    })();
  }, [authContext, history, reload, couponPaymentViewModel]);

  if (!couponPaymentViewModel || fetchingCouponPaymentViewModel) {
    return <Box>loading...</Box>;
  }

  return (
    <Box
      sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto 1fr",
        rowGap: theme.spacing(0.5),
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          marginTop: theme.spacing(-1),
          gap: theme.spacing(1),
        })}
      >
        <Link
          to={window.location.pathname.replace(/\/view.*$/, "/table")}
          style={{ textDecoration: "none" }}
        >
          <Typography
            sx={(theme) => ({
              cursor: "pointer",
              color: theme.palette.text.hint,
              "&:hover": {
                color: theme.palette.primary.main,
                textDecoration: "underline",
              },
            })}
            variant="caption"
          >
            {`Coupon Payments >`}
          </Typography>
        </Link>
        <Typography
          variant="caption"
          sx={(theme) => ({ color: theme.palette.text.primary })}
        >
          {`${couponPaymentViewModel.number}: ${couponPaymentViewModel.sequenceNumber}`}
        </Typography>
      </Box>
      <Paper square sx={(theme) => ({ paddingLeft: theme.spacing(4) })}>
        <Grid container>
          <Grid item>
            <Tabs
              value={viewTab}
              onChange={(_, value) => {
                setViewTab(value);
              }}
              textColor={"inherit"}
            >
              {allViewTabs.map((v, i) => (
                <Tab key={i} value={v} label={v} />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Paper>
      {(() => {
        switch (viewTab) {
          case ViewTab.Overview:
            return (
              <Overview
                system={props.system}
                model={couponPaymentViewModel}
                reload={() => setReload(true)}
              />
            );

          case ViewTab.Recipients:
            return (
              <CouponPaymentRecipientTable
                height={props.height - 65}
                couponPaymentID={couponPaymentViewModel.couponPaymentID}
              />
            );

          case ViewTab.Funding:
            return (
              <TransactionTable
                height={props.height - 65}
                title={
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyItems: "center",
                      gap: theme.spacing(1),
                    })}
                  >
                    <Typography>Funding</Typography>
                  </Box>
                }
                constantCriteria={{
                  "metaData.couponPaymentID": TextExactCriterion(
                    couponPaymentViewModel.couponPaymentID,
                  ),
                  "metaData.caller": TextExactCriterion(
                    "financial-CouponPaymentStateController.FundCouponPayment",
                  ),
                }}
              />
            );

          case ViewTab.Distribution:
            return (
              <ViewTransactionBatch
                transactionBatchCriteria={{
                  "metaData.couponPaymentID": TextExactCriterion(
                    couponPaymentViewModel.couponPaymentID,
                  ),
                }}
                height={props.height - 65}
              />
            );

          default:
            return null;
        }
      })()}
    </Box>
  );
}

type OverviewProps = {
  model: FinancialCouponPaymentViewModel;
  system: boolean;
  reload: () => void;
};

const Overview = (props: OverviewProps) => {
  const { authContext, viewConfiguration } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator } = useErrorContext();

  const [resolutionInProgress, setResolutionInProgress] = useState(false);
  const handleResolveCouponPaymentState = async () => {
    setResolutionInProgress(true);
    try {
      await CouponPaymentStateController.ResolveCouponPaymentState({
        context: authContext,
        couponPaymentID: props.model.couponPaymentID,
        manualInvocation: true,
      });
      props.reload();
      enqueueSnackbar("Coupon Payment State Resolution Completed", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error resolving coupon Payment state`, e);
      enqueueSnackbar(`error resolving coupon Payment state: ${err.message}`, {
        variant: "error",
      });
    }
    setResolutionInProgress(false);
  };

  return (
    <Card>
      <CardContent
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1),
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: theme.spacing(1),
          })}
        >
          <Typography variant={"h5"}>
            Coupon Payment {props.model.number}
          </Typography>
          {props.system && (
            <Tooltip title={"Copy Coupon Payment ID"}>
              <CopyPasteIcon
                sx={(theme) => ({
                  fontSize: 20,
                  color: theme.palette.action.disabled,
                  "&:hover": {
                    color: theme.palette.action.active,
                  },
                  cursor: "pointer",
                })}
                onClick={() =>
                  navigator.clipboard
                    .writeText(props.model.couponPaymentID)
                    .then(() => enqueueSnackbar("Coupon Payment ID Copied"))
                }
              />
            </Tooltip>
          )}
          <CouponPaymentStateChip state={props.model.state} />
          <Box
            sx={(theme) => ({
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            })}
          >
            {viewConfiguration?.["Corporate Action"]?.["Coupon Payment"]?.[
              "Actions"
            ]?.ResolveCouponPaymentState && (
              <Button
                disabled={resolutionInProgress}
                variant={"contained"}
                color={"primary"}
                children={"Resolve State"}
                onClick={handleResolveCouponPaymentState}
              />
            )}
            <Tooltip title={"Refresh"}>
              <span>
                <IconButton onClick={props.reload} size={"small"}>
                  <ReloadIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            rowGap: theme.spacing(1),
          })}
        >
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Sequence Number
            </Typography>
            <Typography>{props.model.sequenceNumber}</Typography>
          </Box>
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Number
            </Typography>
            <Typography>{props.model.number}</Typography>
          </Box>
          <Box />

          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Fix Date
            </Typography>
            <Typography>
              {dayjs(props.model.fixDate).format("DD/MM/YYYY HH:mm:ss")}
            </Typography>
          </Box>
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Payment Date
            </Typography>
            <Typography>
              {dayjs(props.model.paymentDate).format("DD/MM/YYYY HH:mm:ss")}
            </Typography>
          </Box>
          <Box />

          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Nominal Amount
            </Typography>
            <Amount amount={props.model.nominalAmount} />
          </Box>
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Interest Amount
            </Typography>
            <Amount amount={props.model.interestAmount} />
          </Box>
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Fee
            </Typography>
            <Amount amount={props.model.feeAmount} />
          </Box>

          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Total Amount
            </Typography>
            <Amount amount={props.model.totalAmount} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
