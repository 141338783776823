import {
  DigitalInstrument,
  NewDigitalInstrument,
  NullDigitalInstrument,
} from "james/financial/DigitalInstrument";

export const ModelTypeName = "mesh::financialDigitalInstrumentView/Model";

export class Model {
  ["@type"]: string = ModelTypeName;

  public digitalInstrument: DigitalInstrument = new NullDigitalInstrument();

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.digitalInstrument = NewDigitalInstrument(model.digitalInstrument);
  }
}
