import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { DigitalFixedRateBond } from "./DigitalFixedRateBond";
import { InvestorProfile } from "./InvestorProfile";
import { InstrumentRiskProfile } from "./InstrumentRiskProfile";
import { DebtSeniorityLevel } from "./DebtSeniorityLevel";
import { InstrumentOwnershipLimitations } from "./InstrumentOwnershipLimitations";
import { Amount } from "../ledger";
import BigNumber from "bignumber.js";
import { Calendar } from "./Calendar";
import { CouponFrequency } from "./CouponFrequency";
import { DayCountConvention } from "./DayCountConvention";
import { BusinessDayConvention } from "./BusinessDayConvention";
import { DateGenerationRule } from "./DateGenerationRule";

export type CreateDraftDigitalFixedRateBondRequest = {
  context: Context;
  ownerID: string;
  isin: string;
  name: string;
  fractionalisationAllowed: boolean;
  investorProfile: InvestorProfile | string;
  investorProfileDescription: string;
  riskProfile: InstrumentRiskProfile | string;
  riskProfileDescription: string;
  debtSeniorityLevel: DebtSeniorityLevel | string;
  covenants: string;
  votingRights: boolean;
  votingRightsDescription: string;
  ownershipLimitations: InstrumentOwnershipLimitations;
  issuePrice: Amount;
  nominal: Amount;
  totalNominal: Amount;
  redemption: BigNumber;
  issueDate: string;
  maturityDate: string;
  calendar: Calendar;
  couponPaymentFrequency: CouponFrequency | string;
  firstCouponPaymentDate: string | null;
  nextToLastCouponPaymentDate: string | null;
  couponInterestCommencementDate: string | null;
  dayCountConvention: DayCountConvention | string;
  endOfMonthConvention: boolean;
  businessDayConvention: BusinessDayConvention | string;
  terminationDateBusinessDayConvention: BusinessDayConvention | string;
  dateGenerationRule: DateGenerationRule;
  fixDays: number;
  exCouponDays: number;
  recordDays: number;
  couponRate: BigNumber;
};

export type CreateDraftDigitalFixedRateBondResponse = {
  digitalFixedRateBond: DigitalFixedRateBond;
};

export type PreIssueDigitalFixedRateBondRequest = {
  context: Context;
  digitalFixedRateBondID: string;
};

export type PreIssueDigitalFixedRateBondResponse = {
  digitalFixedRateBond: DigitalFixedRateBond;
};

export const DigitalFixedRateBondStateControllerServiceProviderName =
  "financial-DigitalFixedRateBondStateController";

export const DigitalFixedRateBondStateController = {
  async CreateDraftDigitalFixedRateBond(
    request: CreateDraftDigitalFixedRateBondRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CreateDraftDigitalFixedRateBondResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DigitalFixedRateBondStateControllerServiceProviderName}.CreateDraftDigitalFixedRateBond`,
        request,
      },
      opts,
    );

    return {
      digitalFixedRateBond: new DigitalFixedRateBond(
        response.digitalFixedRateBond,
      ),
    };
  },
  async PreIssueDigitalFixedRateBond(
    request: PreIssueDigitalFixedRateBondRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<PreIssueDigitalFixedRateBondResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DigitalFixedRateBondStateControllerServiceProviderName}.PreIssueDigitalFixedRateBond`,
        request,
      },
      opts,
    );

    return {
      digitalFixedRateBond: new DigitalFixedRateBond(
        response.digitalFixedRateBond,
      ),
    };
  },
};
