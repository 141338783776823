import { CouponPaymentState } from "james/financial/CouponPayment";
import { Amount } from "james/ledger";
import dayjs from "dayjs";

export const ModelTypeName = "mesh::financialInstrumentCashFlowView/Model";

export class Model {
  public ["@type"] = ModelTypeName;

  public id = "";
  public date: string = dayjs().format();
  public amount: Amount = new Amount();
  public corporateActionState: CouponPaymentState | "" = "";
  public corporateActionID = "";
  public cashFlowType: CashFlowType | "" = "";
  public instrumentID = "";

  constructor(l?: Model) {
    if (!l) {
      return;
    }

    this.id = l.id;
    this.date = l.date;
    this.amount = new Amount(l.amount);
    this.corporateActionState = l.corporateActionState;
    this.corporateActionID = l.corporateActionID;
    this.cashFlowType = l.cashFlowType;
    this.instrumentID = l.instrumentID;
  }
}

export enum CashFlowType {
  CouponPaymentCashFlow = "Coupon Payment",
  MaturityCashFlow = "Maturity",
  DividendCashFlow = "Dividend",
  InterestCashFlow = "Interest",
  PrincipalCashFlow = "Principal",
}
