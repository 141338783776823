import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { CouponPayment } from "./CouponPayment";

export type ResolveCouponPaymentStateRequest = {
  context: Context;
  couponPaymentID: string;
  manualInvocation: boolean;
};

export type ResolveCouponPaymentStateResponse = {
  couponPayment: CouponPayment;
};

export type FundCouponPaymentRequest = {
  context: Context;
  couponPaymentID: string;
};

export type FundCouponPaymentResponse = {
  couponPayment: CouponPayment;
};

export const CouponPaymentStateControllerServiceProviderName =
  "financial-CouponPaymentStateController";

export const CouponPaymentStateController = {
  async ResolveCouponPaymentState(
    request: ResolveCouponPaymentStateRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ResolveCouponPaymentStateResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${CouponPaymentStateControllerServiceProviderName}.ResolveCouponPaymentState`,
        request,
      },
      opts,
    );
    return { couponPayment: new CouponPayment(response.couponPayment) };
  },
  async FundCouponPayment(
    request: FundCouponPaymentRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<FundCouponPaymentResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${CouponPaymentStateControllerServiceProviderName}.FundCouponPayment`,
        request,
      },
      opts,
    );
    return { couponPayment: new CouponPayment(response.couponPayment) };
  },
};
