import { ValidationResult } from "common/validation";
import { Listing, MechanismType } from "james/market";
import { BigNumber } from "bignumber.js";
import { DigitalFixedRateBond, DigitalFloatingRateBond } from "james/financial";

export interface ListAssetFormData {
  asset: DigitalFixedRateBond | DigitalFloatingRateBond;
  listing: Listing;
  marketMechanism: MechanismType | "";
  minimumDealSize: BigNumber;
  maximumDealSize: BigNumber;
}

export type FormUpdaterSpecsType = {
  listing: (
    listing: Listing,
    prevFormData?: ListAssetFormData,
  ) => ListAssetFormData;
  marketMechanism: (
    marketMechanism: MechanismType | "",
    prevFormData?: ListAssetFormData,
  ) => ListAssetFormData;
  minimumDealSize: (
    minimumDealSize: BigNumber,
    prevFormData?: ListAssetFormData,
  ) => ListAssetFormData;
  maximumDealSize: (
    maximumDealSize: BigNumber,
    prevFormData?: ListAssetFormData,
  ) => ListAssetFormData;
};

export const formDataUpdaterSpecs: FormUpdaterSpecsType = {
  listing(
    listing: Listing,
    prevFormData?: ListAssetFormData,
  ): ListAssetFormData {
    const formData = prevFormData as ListAssetFormData;
    return {
      ...formData,
      listing: new Listing(listing),
    };
  },
  marketMechanism: (
    marketMechanism: MechanismType | "",
    prevFormData?: ListAssetFormData,
  ): ListAssetFormData => {
    const formData = prevFormData as ListAssetFormData;
    formData.listing.marketMechanisms[0].type = marketMechanism;
    formData.marketMechanism = marketMechanism;
    return {
      ...formData,
      listing: new Listing(formData.listing),
    };
  },
  minimumDealSize: (
    minimumDealSize: BigNumber,
    prevFormData?: ListAssetFormData,
  ): ListAssetFormData => {
    const formData = prevFormData as ListAssetFormData;
    formData.listing.marketMechanisms[0].quoteParameters[0].minimumDealSize.value =
      minimumDealSize;
    formData.minimumDealSize = minimumDealSize;
    return {
      ...formData,
      listing: new Listing(formData.listing),
    };
  },
  maximumDealSize: (
    maximumDealSize: BigNumber,
    prevFormData?: ListAssetFormData,
  ): ListAssetFormData => {
    const formData = prevFormData as ListAssetFormData;
    formData.listing.marketMechanisms[0].quoteParameters[0].maximumDealSize.value =
      maximumDealSize;
    formData.maximumDealSize = maximumDealSize;
    return {
      ...formData,
      listing: new Listing(formData.listing),
    };
  },
};

export const formDataValidationFunc = async (
  formData: ListAssetFormData,
): Promise<ValidationResult> => {
  const validationResult: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  if (formData.minimumDealSize.isLessThanOrEqualTo(new BigNumber(0))) {
    validationResult.valid = false;
    validationResult.fieldValidations.minimumDealSize =
      "Minimum deal size must be greater than 0";
  }

  if (
    formData.minimumDealSize.isGreaterThan(
      formData.asset.totalNominal.value.dividedBy(formData.asset.nominal.value),
    )
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.minimumDealSize =
      "Minimum deal size must be less than total nominal value / nominal value";
  }

  if (formData.maximumDealSize.isLessThanOrEqualTo(formData.minimumDealSize)) {
    validationResult.valid = false;
    validationResult.fieldValidations.maximumDealSize =
      "Maximum deal size must be greater than minimum deal size";
  }

  return validationResult;
};
