import React, { ChangeEvent, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  AutocompleteGetTagProps,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { ClientType, MapClientTypeToRoleType } from "james/client";
import {
  AllGroupClassifications,
  Group,
  GroupClassification,
  GroupManager,
  GroupRepository,
  GroupRoleRemover,
} from "james/group";
import { Role, RoleRepository, RoleType } from "james/role";
import { TextExactCriterion } from "james/search/criterion/text";
import { IDIdentifier } from "james/search/identifier";
import { Determiner, ScopeFields } from "james/search/scope/Determiner";
import { Permission } from "james/security";
import isEqual from "lodash/isEqual";
import { useSnackbar } from "notistack";
import { Client, ClientKind } from "james/client/Client";
import { useAccountContext } from "context/Account/Account";
import { useApplicationContext } from "context/Application/Application";
import { Manager as ClientManagerRead } from "james/client/ManagerRead";
import { useErrorContext } from "context/Error";

const PREFIX = "CreateOrEditGroupDialog";

const classes = {
  dialogContentRootOverride: `${PREFIX}-dialogContentRootOverride`,
  groupInfoTextBox: `${PREFIX}-groupInfoTextBox`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogContentRootOverride}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumnGap: theme.spacing(8.5),
    paddingTop: "16px",
  },

  [`& .${classes.groupInfoTextBox}`]: {
    color: theme.palette.text.primary,
    fontSize: 16,
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    margin: theme.spacing(1, 0),
  },
}));

interface GroupInformation {
  ownerID: string;
  name: string;
  classification: string;
  description: string;
  roleTypes: string[];
}

function NewGroupInformation(g?: GroupInformation): GroupInformation {
  if (g) {
    return {
      name: g.name,
      roleTypes: [...g.roleTypes],
      classification: g.classification,
      description: g.description,
      ownerID: g.ownerID,
    };
  }
  return {
    name: "",
    roleTypes: [],
    classification: "",
    description: "",
    ownerID: "",
  };
}

interface CreateOrEditGroupDialogProps {
  closeDialog: () => void;
  group?: Group;
  onCreateGroup?: (createdGroup: Group) => void;
  onUpdateGroup?: () => void;
}

export function CreateOrEditGroupDialog(props: CreateOrEditGroupDialogProps) {
  const [group] = useState(new Group(props.group));
  const [loading, setLoading] = useState<boolean>(true);
  const [roleOptions, setRoleOptions] = useState<string[]>([]);
  const [parentGroupOptions, setParentGroupOptions] = useState<Group[]>([]);
  const [rolesOwnedByGroup, setRolesOwnedByGroup] = useState<Role[]>([]);
  const [ownerID, setParentGroup] = useState<string>("");
  const { authContext, myClient, myClientRetrievalErr } =
    useApplicationContext();
  const [groupInfo, setGroupInfo] = useState<GroupInformation>(
    NewGroupInformation(),
  );
  const [originalGroupInfo, setOriginalGroupInfo] = useState<GroupInformation>(
    NewGroupInformation(),
  );

  const { enqueueSnackbar } = useSnackbar();
  const [validationState, setValidationState] = useState<{
    [key: string]: string | undefined;
  }>({});
  const { stellarAccountContext } = useAccountContext();
  const { errorContextDefaultErrorFeedback, errorContextErrorTranslator } =
    useErrorContext();

  useEffect(() => {
    const initialLoad = async () => {
      setLoading(true);

      if (!myClient && myClientRetrievalErr) {
        errorContextDefaultErrorFeedback(myClientRetrievalErr);
        return;
      }

      if (!myClient) {
        return;
      }

      // search for all of the groups referenced by retrieved criteria
      // i.e. search for all groups in which user has the group-Manager.AddGroup permission
      try {
        // get a criteria with all of the id's of groups in which
        // the user has the group-Manager.AddGroup permission
        const parentGroupsCriteria =
          await Determiner.DetermineScopeCriteriaByRoles({
            context: authContext,
            service: new Permission({
              serviceName: "AddGroup",
              serviceProvider: "group-Manager",
              description: "addGroup service",
            }),
            criteria: {},
            scopeFields: [ScopeFields.IDField],
            buildScopeTree: true,
          });

        const searchGroupsResponse = await GroupRepository.SearchGroups({
          context: authContext,
          criteria: parentGroupsCriteria.criteria,
        });
        if (props.group) {
          const groupFromProps = props.group;
          // remove disallowed options
          setParentGroupOptions(
            searchGroupsResponse.records.filter(
              (g) =>
                !(
                  // the group cannot be set as it's own owner
                  (
                    g.id === groupFromProps.id ||
                    // the group cannot have a group set as it's owner
                    // if it is the owner of that group
                    g.ownerID === groupFromProps.id
                  )
                ),
            ),
          );
        } else {
          setParentGroupOptions(searchGroupsResponse.records);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          "unable to search for potential parent groups",
          err.message ? err.message : err.toString(),
        );
        return;
      }

      //
      // compile a list roles which can be assigned to this new or existing group
      // this list is derived from a list of client types
      //

      // first determine which client types to use
      let clientTypes: ClientType[] = [];
      let clientKind: ClientKind | "";
      if (group.id === "") {
        // if this is a new group being created, the list of roles is derived
        // from logged in user's client types
        clientTypes = new Client(myClient).clientTypes;
        clientKind = new Client(myClient).clientKind;
      } else {
        // otherwise if this is an existing group the roles will be derived from
        // the group's owner client types
        try {
          const retrieveGroupClientResponse =
            await ClientManagerRead.RetrieveGroupClient({
              context: authContext,
              groupID: group.id,
            });
          clientTypes = retrieveGroupClientResponse.client.clientTypes;
          clientKind = retrieveGroupClientResponse.client.clientKind;
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error("unable to retrieve group's client: ", err);
          return;
        }
      }

      // create a set of unique roles
      const roleTypes: Set<string> = new Set<string>();
      // for every client type
      clientTypes.forEach((clientType: ClientType) => {
        // get a list of roles derived from this client type
        const clientTypeRoles: RoleType[] = MapClientTypeToRoleType(
          clientType,
          clientKind,
        );
        // for every role
        clientTypeRoles.forEach((cRole: RoleType) => {
          // add it if it is unique
          roleTypes.add(cRole);
        });
      });
      setRoleOptions(Array.from(roleTypes));

      // if this is an existing group then retrieve data to populate the dialog
      if (group.id !== "") {
        try {
          // search for all of the roles owned by this group
          const searchRolesResponse = await RoleRepository.SearchRoles({
            context: authContext,
            criteria: { ownerID: TextExactCriterion(group.id) },
          });
          setRolesOwnedByGroup(searchRolesResponse.records);
          setGroupInfo(
            NewGroupInformation({
              name: group.name,
              ownerID: group.ownerID,
              roleTypes: searchRolesResponse.records.map((role) => role.name),
              classification: group.classification,
              description: group.description,
            }),
          );
          setOriginalGroupInfo(
            NewGroupInformation({
              name: group.name,
              ownerID: group.ownerID,
              roleTypes: searchRolesResponse.records.map((role) => role.name),
              classification: group.classification,
              description: group.description,
            }),
          );
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error("error searching for group roles: ", err);
          return;
        }
      }

      setLoading(false);
    };
    initialLoad().finally();
  }, [group, authContext, myClient, props.group]);

  const textFieldOnChange =
    (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
      handleClearValidation(name);
      let newValue = event.target.value;
      if (name === "description" && newValue.length > 175) {
        newValue = newValue.slice(0, 175);
      }
      const newGroupsInfo = {
        ...groupInfo,
        [name]: newValue,
      };
      if (name === "ownerID") {
        setParentGroup(event.target.value);
      }
      setGroupInfo(newGroupsInfo);
    };

  const performValidation: () => boolean = () => {
    const newValidationState: { [key: string]: string | undefined } = {};
    if (groupInfo.name === "") {
      newValidationState.name = "Cannot be blank";
    }
    if (groupInfo.ownerID === "") {
      newValidationState.ownerID = "Select one";
    }
    if (groupInfo.classification === "") {
      newValidationState.classification = "Select one";
    }
    if (groupInfo.roleTypes.length === 0) {
      newValidationState.roleTypes = "Choose at least 1";
    }

    setValidationState(newValidationState);

    return !Object.keys(newValidationState).length;
  };

  const handleClearValidation = (field: string) => {
    setValidationState({
      ...validationState,
      [field]: undefined,
    });
  };

  const handleUpdateGroup = async () => {
    if (!performValidation()) {
      return;
    }

    setLoading(true);

    // filter out roles that need to be added to the group
    const rolesToAddToGroup = groupInfo.roleTypes.filter(
      (roleToAdd) => !rolesOwnedByGroup.find((role) => role.name === roleToAdd),
    );

    // perform role creation to add roles to group
    const roleCreationPromises = rolesToAddToGroup.map((roleOption) =>
      (async () => {
        try {
          await GroupManager.AddRoleToGroup({
            context: authContext,
            groupID: group.id,
            roleType: roleOption,
          });
          enqueueSnackbar(`${roleOption} Role Added to Group`, {
            variant: "success",
          });
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error("error adding role to group", e);
          enqueueSnackbar(
            `unable to add ${roleOption} to group: ${err.message}`,
            { variant: "error" },
          );
        }
      })(),
    );

    // determine which roles should be removed from group
    const rolesToRemoveFromGroup = rolesOwnedByGroup.filter(
      (roleOwnedByGroup) =>
        !groupInfo.roleTypes.find(
          (roleOption) => roleOption === roleOwnedByGroup.name,
        ),
    );

    // perform removal of roles from group
    const roleRemovalPromises = rolesToRemoveFromGroup.map((role) =>
      (async () => {
        try {
          // TODO: do with consistency
          await GroupRoleRemover.RemoveRoleFromGroup({
            context: authContext,
            roleID: role.id,
          });

          enqueueSnackbar(`${role.name} Role Removed From Group`, {
            variant: "success",
          });
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          enqueueSnackbar(
            `unable to remove ${role.name} from group: ${
              err.message ? err.message : err.toString()
            }`,
            { variant: "error" },
          );
        }
      })(),
    );

    const updateGroupPromise = (async () => {
      const updatedGroup = new Group({
        ...new Group(),
        name: groupInfo.name,
        ownerID: groupInfo.ownerID,
        id: group.id,
        classification: groupInfo.classification as GroupClassification,
        description: groupInfo.description,
      });

      // if group details have changed perform update
      if (!isEqual(updatedGroup, group)) {
        try {
          // TODO: do with consistency
          await GroupRepository.UpdateGroup({
            context: authContext,
            identifier: IDIdentifier(group.id),
            group: updatedGroup,
          });
          enqueueSnackbar("Group Updated", { variant: "success" });
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            "error updating group:",
            err.message ? err.message : err.toString(),
          );
          enqueueSnackbar(
            `unable to update group details: ${
              err.message ? err.message : err.toString()
            }`,
            { variant: "error" },
          );
        }
      }
    })();

    // wait for executing promises to resolve
    await Promise.all([
      ...roleCreationPromises,
      ...roleRemovalPromises,
      updateGroupPromise,
    ]);

    setOriginalGroupInfo(NewGroupInformation(groupInfo));
    props.closeDialog();
    if (props.onUpdateGroup) {
      props.onUpdateGroup();
    }
  };

  const handleCreateGroup = async () => {
    // confirm that the group to be created is valid
    if (!performValidation()) {
      return;
    }

    setLoading(true);

    try {
      const addGroupResponse = await GroupManager.AddGroup({
        context: authContext,
        classification: groupInfo.classification,
        ownerID: groupInfo.ownerID,
        name: groupInfo.name,
        description: groupInfo.description,
        roleTypes: groupInfo.roleTypes.map((roleOption) => roleOption),
      });
      enqueueSnackbar("Group Created", { variant: "success" });
      if (props.onCreateGroup) {
        props.onCreateGroup(addGroupResponse.group);
      }
      props.closeDialog();
      stellarAccountContext.refreshAccounts();
      return;
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        "error creating group: ",
        err.message ? err.message : err.toString(),
      );
      enqueueSnackbar(
        `unable to create group: ${err.message ? err.message : err.toString()}`,
        { variant: "error" },
      );
    }

    setLoading(false);
  };

  //
  // this effect runs each time the parent group is changed
  // in order to reset the available roles as derived from the
  // client type of the parent group
  // note: this is only relevant for user's of the managing company
  //
  useEffect(() => {
    const updateGroupRoles = async () => {
      setLoading(true);

      // clear group roles
      setGroupInfo((v) => ({ ...v, roleTypes: [] }));

      if (ownerID === "") {
        // if parent group has been cleared do not attempt to
        // retrieve anything further
        setLoading(false);
        return;
      }

      try {
        // retrieve the group owner client to get client types
        const retrieveGroupClientResponse =
          await ClientManagerRead.RetrieveGroupClient({
            context: authContext,
            groupID: ownerID,
          });

        // populate group roles derived from the group's owner client types
        const roleTypes: Set<string> = new Set<string>();
        const clientKind = retrieveGroupClientResponse.client.clientKind;

        // for every client type
        retrieveGroupClientResponse.client.clientTypes.forEach(
          (clientType: ClientType) => {
            // get a list of roles derived from this client type
            const clientTypeRoles = MapClientTypeToRoleType(
              clientType,
              clientKind,
            );
            // for every role
            clientTypeRoles.forEach((cRole: RoleType) => {
              // add it if it is unique
              roleTypes.add(cRole);
            });
          },
        );
        setRoleOptions(Array.from(roleTypes));

        setLoading(false);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          "error updating group role options: ",
          err.message ? err.message : err.toString(),
        );
      }
    };
    updateGroupRoles().finally();
  }, [ownerID, authContext]);

  return (
    <StyledDialog open maxWidth="md">
      <DialogTitle>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="subtitle1">Group Information</Typography>
          </Grid>
          {loading && (
            <Grid item>
              <CircularProgress size={25} />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" spacing={1} alignItems="center">
          {!isEqual(originalGroupInfo, groupInfo) && (
            <Grid item>
              {group.id === "" ? (
                // show create group button if this is a new group
                <Button
                  id="popup-groupsEdit-createGroup"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleCreateGroup}
                  disabled={loading}
                >
                  Create Group
                </Button>
              ) : (
                // otherwise show update group button
                <Button
                  id="popup-groupsEdit-updateGroup"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleUpdateGroup}
                  disabled={loading}
                >
                  Update Group
                </Button>
              )}
            </Grid>
          )}
          <Grid item>
            <IconButton
              size="small"
              onClick={props.closeDialog}
              disabled={loading}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRootOverride }}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h6" children="Group Details" />
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="groupsEdit-groupName-formfield"
                  variant="outlined"
                  label="Group Name"
                  margin="dense"
                  value={groupInfo.name}
                  onChange={textFieldOnChange("name")}
                  disabled={loading}
                  error={!!validationState.name}
                  helperText={validationState.name}
                />
              </Grid>
              {groupInfo.classification !==
                GroupClassification.ClientClassification && (
                <Grid item xs={6}>
                  <TextField
                    label="Parent Group"
                    fullWidth
                    id="groupsEdit-parentGroup-formfield"
                    select
                    margin="dense"
                    disabled={loading}
                    variant="outlined"
                    onChange={textFieldOnChange("ownerID")}
                    value={groupInfo.ownerID}
                    error={!!validationState.ownerID}
                    helperText={validationState.ownerID}
                  >
                    {parentGroupOptions.map((parentGroupOption, idx) => (
                      <MenuItem key={idx} value={parentGroupOption.id}>
                        {parentGroupOption.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h6" children="Group Roles" />
          </Grid>
          <Grid item>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option === value}
              multiple
              id="groupsEdit-roleOptions"
              options={roleOptions}
              disabled={
                groupInfo.classification ===
                  GroupClassification.ClientClassification || loading
              }
              onChange={(__: object, value) => {
                handleClearValidation("roleTypes");
                setGroupInfo({
                  ...groupInfo,
                  roleTypes: value,
                });
              }}
              getOptionLabel={(option) => option}
              value={groupInfo.roleTypes}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  id="groupsEdit-roleOptions-formfield"
                  disabled={
                    groupInfo.classification ===
                      GroupClassification.ClientClassification || loading
                  }
                  label="Roles"
                  InputLabelProps={{ shrink: true }}
                  error={!!validationState.roleTypes}
                  helperText={validationState.roleTypes}
                />
              )}
              renderTags={(
                value: string[],
                getTagProps: AutocompleteGetTagProps,
              ) =>
                value.map((option, index: number) => {
                  if (
                    groupInfo.classification ===
                    GroupClassification.ClientClassification
                  ) {
                    return (
                      <Chip
                        size="small"
                        sx={(theme) => ({
                          margin: theme.spacing(0.5),
                        })}
                        key={index}
                        color="info"
                        label={option}
                      />
                    );
                  }
                  return (
                    <Chip
                      size="small"
                      sx={(theme) => ({
                        margin: theme.spacing(0.5),
                      })}
                      color="info"
                      label={option}
                      {...getTagProps({ index })}
                      key={index}
                    />
                  );
                })
              }
            />
          </Grid>
          <Grid item>
            <Typography variant="h6" children="Group Description" />
          </Grid>
          <Grid item>
            <TextField
              className={classes.groupInfoTextBox}
              variant="outlined"
              id="groupsEdit-groupDescription-formfield"
              label="Why this group?"
              rows={7}
              onChange={textFieldOnChange("description")}
              maxRows={7}
              value={groupInfo.description}
              multiline
              disabled={loading}
              helperText={`${
                175 - groupInfo.description.length
              } Characters Remaining`}
            />
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h6">
              Select the type of group you wish to create
            </Typography>
          </Grid>
          <Grid item>
            {groupInfo.classification ===
            GroupClassification.ClientClassification ? (
              // if this is a 'Client' group then the type cannot be changed
              <TextField
                label="Group Type"
                fullWidth
                id="groupsEdit-groupTypeI-formfield"
                margin="dense"
                variant="outlined"
                value={groupInfo.classification}
                InputProps={{ readOnly: true }}
                disabled={loading}
              />
            ) : (
              // otherwise the group type can be chosen
              <TextField
                label="Group Type"
                fullWidth
                select
                margin="dense"
                id="groupsEdit-groupTypeI-formfield"
                variant="outlined"
                onChange={textFieldOnChange("classification")}
                value={groupInfo.classification}
                disabled={loading}
                error={!!validationState.classification}
                helperText={validationState.classification}
              >
                {AllGroupClassifications.filter(
                  (c) => c !== GroupClassification.ClientClassification,
                ).map((gC, idx) => (
                  <MenuItem key={idx} value={gC}>
                    {gC}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
}
