import { ClientKind } from "../client/Client";

export class InstrumentOwnershipLimitations {
  public clientKinds: ClientKind[] = [];
  public clientCitizenshipCountries: string[] = [];
  public clientResidencyCountries: string[] = [];

  constructor(i?: InstrumentOwnershipLimitations) {
    if (!i) {
      return;
    }
    this.clientKinds = [...i.clientKinds];
    this.clientCitizenshipCountries = [...i.clientCitizenshipCountries];
    this.clientResidencyCountries = [...i.clientResidencyCountries];
  }
}
