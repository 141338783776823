import { Currency } from "../../../../james/financial/Currency";
import { Model as FinancialCurrencyStablecoinViewModel } from "../../../../james/views/financialCurrencyStablecoinView/Model";
import { Group } from "../../../../james/group";

export * from "./DigitalFixedRateBondForm";
export * from "./DigitalFloatingRateBondForm";
export * from "./AssetListingForm";

export enum TabPath {
  Overview = "overview",
  PrimaryMarket = "primaryMarket",
  MarketingMedia = "marketingMedia",
  CorporateActions = "corporateActions",
}
export const TabPaths = (base: string) => [
  {
    path: `${base}/${TabPath.Overview}`,
    name: "Overview",
  },
  {
    path: `${base}/${TabPath.PrimaryMarket}`,
    name: "Primary Market",
  },
  {
    path: `${base}/${TabPath.MarketingMedia}`,
    name: "Marketing Media",
  },
  {
    path: `${base}/${TabPath.CorporateActions}`,
    name: "Corporate Actions",
  },
];

export enum FormMode {
  Edit = "edit",
  View = "view",
}

export type FormData = {
  instrumentTypeFriendlyNames: {
    name: string;
    friendlyName: string;
  }[];
  groupOwners: Group[];
  currencyStablecoins: FinancialCurrencyStablecoinViewModel[];
  currencies: Currency[];
};
