// source: search/criterion.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var search_textExactCriterion_pb = require('../search/textExactCriterion_pb.js');
goog.object.extend(proto, search_textExactCriterion_pb);
var search_textListCriterion_pb = require('../search/textListCriterion_pb.js');
goog.object.extend(proto, search_textListCriterion_pb);
var search_uint32ExactCriterion_pb = require('../search/uint32ExactCriterion_pb.js');
goog.object.extend(proto, search_uint32ExactCriterion_pb);
var search_uint32ListCriterion_pb = require('../search/uint32ListCriterion_pb.js');
goog.object.extend(proto, search_uint32ListCriterion_pb);
goog.exportSymbol('proto.search.Criterion', null, global);
goog.exportSymbol('proto.search.Criterion.CriterionCase', null, global);
goog.exportSymbol('proto.search.ORCriterion', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.Criterion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.search.Criterion.oneofGroups_);
};
goog.inherits(proto.search.Criterion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.Criterion.displayName = 'proto.search.Criterion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.search.ORCriterion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.search.ORCriterion.repeatedFields_, null);
};
goog.inherits(proto.search.ORCriterion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.search.ORCriterion.displayName = 'proto.search.ORCriterion';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.search.Criterion.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.search.Criterion.CriterionCase = {
  CRITERION_NOT_SET: 0,
  TEXTEXACTCRITERION: 1,
  TEXTLISTCRITERION: 2,
  UINT32EXACTCRITERION: 3,
  UINT32LISTCRITERION: 4,
  ORCRITERION: 5
};

/**
 * @return {proto.search.Criterion.CriterionCase}
 */
proto.search.Criterion.prototype.getCriterionCase = function() {
  return /** @type {proto.search.Criterion.CriterionCase} */(jspb.Message.computeOneofCase(this, proto.search.Criterion.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.Criterion.prototype.toObject = function(opt_includeInstance) {
  return proto.search.Criterion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.Criterion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.Criterion.toObject = function(includeInstance, msg) {
  var f, obj = {
    textexactcriterion: (f = msg.getTextexactcriterion()) && search_textExactCriterion_pb.TextExactCriterion.toObject(includeInstance, f),
    textlistcriterion: (f = msg.getTextlistcriterion()) && search_textListCriterion_pb.TextListCriterion.toObject(includeInstance, f),
    uint32exactcriterion: (f = msg.getUint32exactcriterion()) && search_uint32ExactCriterion_pb.Uint32ExactCriterion.toObject(includeInstance, f),
    uint32listcriterion: (f = msg.getUint32listcriterion()) && search_uint32ListCriterion_pb.Uint32ListCriterion.toObject(includeInstance, f),
    orcriterion: (f = msg.getOrcriterion()) && proto.search.ORCriterion.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.Criterion}
 */
proto.search.Criterion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.Criterion;
  return proto.search.Criterion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.Criterion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.Criterion}
 */
proto.search.Criterion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new search_textExactCriterion_pb.TextExactCriterion;
      reader.readMessage(value,search_textExactCriterion_pb.TextExactCriterion.deserializeBinaryFromReader);
      msg.setTextexactcriterion(value);
      break;
    case 2:
      var value = new search_textListCriterion_pb.TextListCriterion;
      reader.readMessage(value,search_textListCriterion_pb.TextListCriterion.deserializeBinaryFromReader);
      msg.setTextlistcriterion(value);
      break;
    case 3:
      var value = new search_uint32ExactCriterion_pb.Uint32ExactCriterion;
      reader.readMessage(value,search_uint32ExactCriterion_pb.Uint32ExactCriterion.deserializeBinaryFromReader);
      msg.setUint32exactcriterion(value);
      break;
    case 4:
      var value = new search_uint32ListCriterion_pb.Uint32ListCriterion;
      reader.readMessage(value,search_uint32ListCriterion_pb.Uint32ListCriterion.deserializeBinaryFromReader);
      msg.setUint32listcriterion(value);
      break;
    case 5:
      var value = new proto.search.ORCriterion;
      reader.readMessage(value,proto.search.ORCriterion.deserializeBinaryFromReader);
      msg.setOrcriterion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.Criterion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.Criterion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.Criterion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.Criterion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextexactcriterion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      search_textExactCriterion_pb.TextExactCriterion.serializeBinaryToWriter
    );
  }
  f = message.getTextlistcriterion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      search_textListCriterion_pb.TextListCriterion.serializeBinaryToWriter
    );
  }
  f = message.getUint32exactcriterion();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      search_uint32ExactCriterion_pb.Uint32ExactCriterion.serializeBinaryToWriter
    );
  }
  f = message.getUint32listcriterion();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      search_uint32ListCriterion_pb.Uint32ListCriterion.serializeBinaryToWriter
    );
  }
  f = message.getOrcriterion();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.search.ORCriterion.serializeBinaryToWriter
    );
  }
};


/**
 * optional TextExactCriterion textExactCriterion = 1;
 * @return {?proto.search.TextExactCriterion}
 */
proto.search.Criterion.prototype.getTextexactcriterion = function() {
  return /** @type{?proto.search.TextExactCriterion} */ (
    jspb.Message.getWrapperField(this, search_textExactCriterion_pb.TextExactCriterion, 1));
};


/**
 * @param {?proto.search.TextExactCriterion|undefined} value
 * @return {!proto.search.Criterion} returns this
*/
proto.search.Criterion.prototype.setTextexactcriterion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.search.Criterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.Criterion} returns this
 */
proto.search.Criterion.prototype.clearTextexactcriterion = function() {
  return this.setTextexactcriterion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.Criterion.prototype.hasTextexactcriterion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TextListCriterion textListCriterion = 2;
 * @return {?proto.search.TextListCriterion}
 */
proto.search.Criterion.prototype.getTextlistcriterion = function() {
  return /** @type{?proto.search.TextListCriterion} */ (
    jspb.Message.getWrapperField(this, search_textListCriterion_pb.TextListCriterion, 2));
};


/**
 * @param {?proto.search.TextListCriterion|undefined} value
 * @return {!proto.search.Criterion} returns this
*/
proto.search.Criterion.prototype.setTextlistcriterion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.search.Criterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.Criterion} returns this
 */
proto.search.Criterion.prototype.clearTextlistcriterion = function() {
  return this.setTextlistcriterion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.Criterion.prototype.hasTextlistcriterion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Uint32ExactCriterion uint32ExactCriterion = 3;
 * @return {?proto.search.Uint32ExactCriterion}
 */
proto.search.Criterion.prototype.getUint32exactcriterion = function() {
  return /** @type{?proto.search.Uint32ExactCriterion} */ (
    jspb.Message.getWrapperField(this, search_uint32ExactCriterion_pb.Uint32ExactCriterion, 3));
};


/**
 * @param {?proto.search.Uint32ExactCriterion|undefined} value
 * @return {!proto.search.Criterion} returns this
*/
proto.search.Criterion.prototype.setUint32exactcriterion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.search.Criterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.Criterion} returns this
 */
proto.search.Criterion.prototype.clearUint32exactcriterion = function() {
  return this.setUint32exactcriterion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.Criterion.prototype.hasUint32exactcriterion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Uint32ListCriterion uint32ListCriterion = 4;
 * @return {?proto.search.Uint32ListCriterion}
 */
proto.search.Criterion.prototype.getUint32listcriterion = function() {
  return /** @type{?proto.search.Uint32ListCriterion} */ (
    jspb.Message.getWrapperField(this, search_uint32ListCriterion_pb.Uint32ListCriterion, 4));
};


/**
 * @param {?proto.search.Uint32ListCriterion|undefined} value
 * @return {!proto.search.Criterion} returns this
*/
proto.search.Criterion.prototype.setUint32listcriterion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.search.Criterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.Criterion} returns this
 */
proto.search.Criterion.prototype.clearUint32listcriterion = function() {
  return this.setUint32listcriterion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.Criterion.prototype.hasUint32listcriterion = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ORCriterion orCriterion = 5;
 * @return {?proto.search.ORCriterion}
 */
proto.search.Criterion.prototype.getOrcriterion = function() {
  return /** @type{?proto.search.ORCriterion} */ (
    jspb.Message.getWrapperField(this, proto.search.ORCriterion, 5));
};


/**
 * @param {?proto.search.ORCriterion|undefined} value
 * @return {!proto.search.Criterion} returns this
*/
proto.search.Criterion.prototype.setOrcriterion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.search.Criterion.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.search.Criterion} returns this
 */
proto.search.Criterion.prototype.clearOrcriterion = function() {
  return this.setOrcriterion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.search.Criterion.prototype.hasOrcriterion = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.search.ORCriterion.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.search.ORCriterion.prototype.toObject = function(opt_includeInstance) {
  return proto.search.ORCriterion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.search.ORCriterion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.ORCriterion.toObject = function(includeInstance, msg) {
  var f, obj = {
    criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    proto.search.Criterion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.search.ORCriterion}
 */
proto.search.ORCriterion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.search.ORCriterion;
  return proto.search.ORCriterion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.search.ORCriterion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.search.ORCriterion}
 */
proto.search.ORCriterion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.search.Criterion;
      reader.readMessage(value,proto.search.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.search.ORCriterion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.search.ORCriterion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.search.ORCriterion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.search.ORCriterion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.search.Criterion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Criterion criteria = 1;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.search.ORCriterion.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.search.Criterion, 1));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.search.ORCriterion} returns this
*/
proto.search.ORCriterion.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.search.ORCriterion.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.search.ORCriterion} returns this
 */
proto.search.ORCriterion.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


goog.object.extend(exports, proto.search);
