import {
  Box,
  Button,
  CircularProgress,
  Typography,
  alpha,
} from "@mui/material";
import React, { useMemo } from "react";
import { useBuilderContext } from "../../../../Context";
import {
  DataTable,
  RowType,
} from "@mesh/common-js-react/dist/Tables/DataTable";
import { Assetflow } from "@mesh/common-js/dist/financial/assetflow_pb";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { decimalToBigNumber, formatTextNum } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { assetflowCategoryToString } from "@mesh/common-js/dist/financial/assetFlowCategory";
import { DateTimeFormat } from "const/dateformats";
import {
  FaceOutlined as FaceIcon,
  ErrorOutline as WarningIcon,
} from "@mui/icons-material";

export type AssetflowsProps = {
  height: number;
};

export const Assetflows = (props: AssetflowsProps) => {
  const { simulationMode } = useBuilderContext();

  return simulationMode ? <Simulated {...props} /> : <Actual {...props} />;
};

const Simulated = (props: AssetflowsProps) => {
  const { formData, simulatedAssetflows } = useBuilderContext();

  const assetflowColumns: RowType<
    Assetflow,
    Omit<
      Assetflow.AsObject,
      | "id"
      | "number"
      | "ownerid"
      | "auditentry"
      | "state"
      | "legid"
      | "smartinstrumentid"
      | "data"
    >
  > = useMemo(
    () => ({
      sequencenumber: {
        title: "Seq No.",
        renderCell: (rowData: Assetflow) => {
          return rowData.getSequencenumber();
        },
      },
      paymentdate: {
        title: "Payment Date",
        renderCell: (rowData: Assetflow) => {
          return protobufTimestampToDayjs(
            rowData.getPaymentdate() ?? new Timestamp(),
          ).format(DateTimeFormat);
        },
      },
      category: {
        title: "Category",
        renderCell: (rowData: Assetflow) => {
          return assetflowCategoryToString(rowData.getCategory());
        },
      },
      amount: {
        title: "Amount",
        renderCell: (rowData: Assetflow) => {
          return `${rowData.getAmount()?.getToken()?.getCode()} ${formatTextNum(
            decimalToBigNumber(
              rowData.getAmount()?.getValue() ?? new Decimal(),
            ),
          )}`;
        },
      },
    }),
    [formData.smartInstrument.getTimezone()],
  );

  if (!simulatedAssetflows.length) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(0.5),
          })}
        >
          <FaceIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Nothing to see here"
          />
          <Typography
            variant="body2"
            children={
              <span>
                You will see <i>Asset Flows</i> here after running{" "}
                <b>Calculate</b>
              </span>
            }
          />
        </Box>
      </Box>
    );
  }

  return (
    <DataTable
      tableContainerClassName="meshScroll"
      height={props.height - 50}
      data={simulatedAssetflows}
      columns={assetflowColumns}
    />
  );
};

const Actual = (props: AssetflowsProps) => {
  const {
    formData,
    assetflows,
    assetflowsLoaded,
    assetflowLoadError,
    clearAssetflowLoadError,
  } = useBuilderContext();

  const assetflowColumns: RowType<
    Assetflow,
    Omit<
      Assetflow.AsObject,
      | "id"
      | "number"
      | "ownerid"
      | "auditentry"
      | "state"
      | "legid"
      | "smartinstrumentid"
      | "data"
    >
  > = useMemo(
    () => ({
      sequencenumber: {
        title: "Seq No.",
        renderCell: (rowData: Assetflow) => {
          return rowData.getSequencenumber();
        },
      },
      paymentdate: {
        title: "Payment Date",
        renderCell: (rowData: Assetflow) => {
          return protobufTimestampToDayjs(
            rowData.getPaymentdate() ?? new Timestamp(),
          ).format(DateTimeFormat);
        },
      },
      category: {
        title: "Category",
        renderCell: (rowData: Assetflow) => {
          return assetflowCategoryToString(rowData.getCategory());
        },
      },
      amount: {
        title: "Amount",
        renderCell: (rowData: Assetflow) => {
          return `${rowData.getAmount()?.getToken()?.getCode()} ${formatTextNum(
            decimalToBigNumber(
              rowData.getAmount()?.getValue() ?? new Decimal(),
            ),
          )}`;
        },
      },
    }),
    [formData.smartInstrument.getTimezone()],
  );

  if (!assetflowsLoaded) {
    return (
      <Box
        sx={(theme) => ({
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyItems: "center",
          gap: theme.spacing(2),
        })}
      >
        <CircularProgress size={70} />
        <Typography variant="h5" color="textSecondary" children="Loading..." />
      </Box>
    );
  }

  if (assetflowLoadError) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(0.5),
          })}
        >
          <WarningIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Something Went Wrong"
          />
          <Typography variant="body2" children={assetflowLoadError} />
          <Button onClick={clearAssetflowLoadError}>Try Again</Button>
        </Box>
      </Box>
    );
  }

  if (!assetflows.length) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(0.5),
          })}
        >
          <FaceIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Nothing to see here"
          />
          <Typography
            variant="body2"
            children={
              <span>
                You will see <i>Asset Flows</i> once they have been generated.
              </span>
            }
          />
        </Box>
      </Box>
    );
  }

  return (
    <DataTable
      tableContainerClassName="meshScroll"
      height={props.height - 50}
      data={assetflows}
      columns={assetflowColumns}
    />
  );
};
