import React, { useEffect } from "react";
import { Client, ClientKYCStatus, ClientStatus } from "james/client";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import dayjs from "dayjs";
import { useClientDetailsViewStore } from "./store";
import { useInitialDataFetch } from "./hooks/useInitialDataFetch";
import { useCompanyClientKYCStatusUpdater } from "./hooks/useCompanyClientStateUpdater";
import { useSaveClientDetails } from "./hooks/useSaveClientDetails";
import { DateField } from "components/FormFields";
import { dateIsValid } from "utilities/date";

type ClientViewProps = {
  Client: Client;
};

export function ClientView(props: ClientViewProps) {
  const store = useClientDetailsViewStore();
  useInitialDataFetch(props.Client);
  const { saveClientDetails } = useSaveClientDetails();
  const { markClientKYCStatusVerified, markClientKYCStatusOutstanding } =
    useCompanyClientKYCStatusUpdater();
  const theme = useTheme();

  useEffect(() => {
    return () => {
      store.clear();
    };
  }, []);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
        overflowY: "unset",
        height: "94%",
        paddingLeft: "32px",
        paddingRight: "32px",
      }}
    >
      <Backdrop open={store.loading}>
        <CircularProgress size={100} />
      </Backdrop>
      {/*Client Details Section*/}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          paddingBottom: (theme) => theme.spacing(2),
          paddingTop: (theme) => theme.spacing(4),
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Backdrop open={store.loading}>
          <CircularProgress size={100} />
        </Backdrop>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Typography variant={"h4"} fontWeight={"bold"}>
            {props.Client.name}
          </Typography>
          <HistoryIcon color={"disabled"} />
          <div>
            <Typography variant={"body2"} color="textSecondary">
              {`Last updated ${dayjs(store.lastUpdateDate).format(
                "YYYY-MM-DD, HH:mm A",
              )}`}
            </Typography>
            <Typography variant={"caption"} color="textSecondary">
              {store.lastUpdateBy}
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => saveClientDetails(props.Client)}
            disabled={store.loading}
            variant={"contained"}
            color={"primary"}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          overflowY: "scroll",
          height: "auto",
        }}
        className={"meshScroll"}
      >
        {/*Company Information Section*/}
        <Box
          sx={{
            paddingTop: (theme) => theme.spacing(4),
            paddingBottom: (theme) => theme.spacing(4),
            display: "flex",
            column: "column",
            width: "100%",
          }}
        >
          <Typography variant={"h4"} fontWeight={"bold"}>
            Client Information
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            width: "100%",
            paddingBottom: (theme) => theme.spacing(3),
            gridTemplateColumns: "repeat(3, 1fr)",
            gridColumnGap: (theme) => theme.spacing(10),
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                marginBottom: (theme) => theme.spacing(1),
              }}
            >
              <Typography
                color={"textSecondary"}
                children={"Type"}
                variant={"caption"}
              />
              <Typography children={"Issuer"} variant={"body1"} />
            </Box>
            <Box>
              <DateField
                label="KYC Reset Date"
                sx={{
                  width: "100%",
                }}
                id="clientView-kycResetDate-formfield"
                value={
                  store.kycResetDate &&
                  !(
                    dayjs(store.kycResetDate).isSame(dayjs("001-01-01")) ||
                    dayjs(store.kycResetDate).isBefore(dayjs("001-01-01"))
                  )
                    ? dayjs(store.kycResetDate).format()
                    : ""
                }
                onChange={(newValue) => {
                  if (!(newValue && dateIsValid(newValue))) {
                    return;
                  }
                  store.setKYCResetDate(dayjs(newValue).format());
                }}
                renderInput={(textFieldProps: TextFieldProps) => (
                  <TextField
                    {...textFieldProps}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    placeholder="MM/DD/YYYY"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Box>

            <Box>
              <DateField
                label="KYC Verification Date"
                id="clientView-kycVerificationDate-formfield"
                sx={{
                  width: "100%",
                }}
                value={
                  store.kycVerificationDate &&
                  !(
                    dayjs(store.kycVerificationDate).isSame(
                      dayjs("01-01-0001"),
                    ) ||
                    dayjs(store.kycVerificationDate).isBefore(
                      dayjs("001-01-01"),
                    )
                  )
                    ? dayjs(store.kycVerificationDate).format()
                    : ""
                }
                onChange={(newValue) => {
                  if (!(newValue && dateIsValid(newValue))) {
                    return;
                  }
                  store.setKYCVerificationDate(dayjs(newValue).format());
                }}
                renderInput={(textFieldProps: TextFieldProps) => (
                  <TextField
                    {...textFieldProps}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    placeholder="MM/DD/YYYY"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Box>

            <TextField
              id="clientView-name-formfield"
              margin="dense"
              variant="outlined"
              label="Short Name"
              placeholder="e.g Registered Client Name"
              value={store.shortName}
              onChange={(e) => store.setShortName(e.target.value)}
              fullWidth
            />
            <TextField
              id="clientView-shortName-formfield"
              margin="dense"
              variant="outlined"
              label="Name"
              value={store.name}
              onChange={(e) => store.setName(e.target.value)}
              placeholder="e.g Registered Company Name"
              fullWidth
            />
            <DateField
              id="clientView-registrationDate-formfield"
              sx={{
                width: "100%",
              }}
              label="Registration Date"
              value={
                store.registrationDate &&
                !(
                  dayjs(store.registrationDate).isSame(dayjs("001-01-01")) ||
                  dayjs(store.registrationDate).isBefore(dayjs("001-01-01"))
                )
                  ? dayjs(store.registrationDate).format()
                  : ""
              }
              onChange={(newValue) => {
                if (!(newValue && dateIsValid(newValue))) {
                  return;
                }
                store.setRegistrationDate(dayjs(newValue).format());
              }}
              renderInput={(textFieldProps: TextFieldProps) => (
                <TextField
                  {...textFieldProps}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  placeholder="MM/DD/YYYY"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <TextField
              style={{
                whiteSpace: "pre-wrap",
                color: theme.palette.text.primary,
                fontSize: 16,
                width: "100%",
              }}
              rows={7}
              label={"Description"}
              multiline
              value={store.description}
              onChange={(e) => store.setDescription(e.target.value)}
              placeholder="Type Something here"
            />
            <Box
              sx={{
                marginTop: (theme) => theme.spacing(1),
              }}
            >
              <Typography
                color={"textSecondary"}
                children={"PAAS Agreement Version"}
                variant={"caption"}
              />
              <Typography
                children={store.paasAgreementVersion}
                variant={"body1"}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <TextField
              id="clientView-website-formfield"
              margin="dense"
              variant="outlined"
              label="Website"
              placeholder={"e.g. www.hello.com"}
              value={store.website}
              onChange={(e) => store.setWebsite(e.target.value)}
              fullWidth
            />
            <TextField
              id="clientView-linkedIn-formfield"
              margin="dense"
              variant="outlined"
              label="LinkedIn"
              placeholder={"e.g. www.hello.com"}
              value={store.linkedIn}
              onChange={(e) => store.setLinkedin(e.target.value)}
              fullWidth
            />
            <TextField
              id="clientView-twitter-formfield"
              margin="dense"
              variant="outlined"
              label="Twitter"
              placeholder={"e.g. www.hello.com"}
              value={store.twitter}
              onChange={(e) => store.setTwitter(e.target.value)}
              fullWidth
            />
            <TextField
              id="clientView-otherSite-formfield"
              margin="dense"
              variant="outlined"
              label="Other Site"
              placeholder={"e.g. www.hello.com"}
              value={store.otherSite}
              onChange={(e) => store.setOtherSite(e.target.value)}
              fullWidth
            />
            <Typography
              sx={{
                marginTop: (theme) => theme.spacing(3),
                marginBottom: (theme) => theme.spacing(1),
              }}
              variant={"h6"}
              fontWeight={"bold"}
            >
              Status
            </Typography>
            <FormControl
              sx={{
                paddingBottom: (theme) => theme.spacing(1),
                marginLeft: (theme) => theme.spacing(1),
              }}
            >
              <RadioGroup
                defaultValue={ClientStatus.ActiveStatus}
                value={store.selectedClientStatus}
              >
                <FormControlLabel
                  value={ClientStatus.ActiveStatus}
                  label={ClientStatus.ActiveStatus}
                  control={<Radio />}
                />
                <FormControlLabel
                  value={ClientStatus.InactiveStatus}
                  label={ClientStatus.InactiveStatus}
                  control={<Radio />}
                />
                <FormControlLabel
                  value={ClientStatus.CompleteProfile}
                  label={ClientStatus.CompleteProfile}
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            width: "100%",
            paddingBottom: (theme) => theme.spacing(3),
            gridTemplateColumns: "repeat(3, 1fr)",
            gridColumnGap: (theme) => theme.spacing(10),
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                paddingTop: (theme) => theme.spacing(4),
                paddingBottom: (theme) => theme.spacing(4),
                display: "flex",
                column: "column",
                width: "100%",
              }}
            >
              <Typography variant={"h4"} fontWeight={"bold"}>
                Verification Information
              </Typography>
            </Box>
            <Typography variant={"h6"} fontWeight={"bold"}>
              KYC Status
            </Typography>
            <FormControl
              sx={{
                paddingBottom: (theme) => theme.spacing(1),
                marginLeft: (theme) => theme.spacing(1),
              }}
            >
              <RadioGroup
                defaultValue={ClientKYCStatus.Outstanding}
                onChange={(e, v) => {
                  if (v === ClientKYCStatus.VerificationInProgressStatus) {
                    return;
                  }
                  store.setSelectedKYCStatus(v as ClientKYCStatus);
                }}
                value={store.selectedKYCStatus}
              >
                <FormControlLabel
                  value={ClientKYCStatus.Outstanding}
                  control={<Radio />}
                  label={ClientKYCStatus.Outstanding}
                />
                <FormControlLabel
                  control={<Radio />}
                  value={ClientKYCStatus.VerificationInProgressStatus}
                  label={ClientKYCStatus.VerificationInProgressStatus}
                />
                <FormControlLabel
                  value={ClientKYCStatus.VerifiedStatus}
                  control={<Radio />}
                  label={ClientKYCStatus.VerifiedStatus}
                />
              </RadioGroup>
              <Button
                sx={{ marginTop: (theme) => theme.spacing(2) }}
                variant={"outlined"}
                disabled={store.loading}
                onClick={async () => {
                  if (
                    store.selectedKYCStatus === ClientKYCStatus.VerifiedStatus
                  ) {
                    await markClientKYCStatusVerified(props.Client);
                    return;
                  }

                  if (store.selectedKYCStatus === ClientKYCStatus.Outstanding) {
                    await markClientKYCStatusOutstanding(props.Client);
                    return;
                  }
                }}
              >
                {`Mark as ${store.selectedKYCStatus}`}
              </Button>
            </FormControl>
          </Box>
          <Box
            sx={{
              width: "100%",
              marginTop: (theme) => theme.spacing(10),
            }}
          >
            <TextField
              style={{
                whiteSpace: "pre-wrap",
                color: theme.palette.text.primary,
                fontSize: 16,
                width: "100%",
              }}
              rows={7}
              label={"Status Reason"}
              multiline
              value={store.kycStatusReason}
              onChange={(e) => store.setKYCStatusReason(e.target.value)}
              placeholder="Type Something here"
            />
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          ></Box>
        </Box>
      </Box>
    </Box>
  );
}
