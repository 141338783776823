import { Client } from "james/client";
import React, { useEffect } from "react";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import dayjs from "dayjs";
import { useCompanyDetailViewStore } from "./store";
import {
  AllLegalForms,
  ConnectedCompany,
  LegalForm,
} from "james/legal/ConnectedCompany";
import { countries } from "james/country";
import {
  IndustryClassification,
  IndustryClassificationOptions,
} from "james/legal/IndustryClassification";
import isEqual from "lodash/isEqual";
import {
  AllStockExchanges,
  StockExchange,
} from "james/financial/StockExchange";
import { FETable } from "components/Table/FETable";
import {
  CompanyRepresentative,
  ConnectedIndividual,
  ConnectedIndividual as ConnectedIndividualClass,
} from "james/legal";
import { Address } from "james/location";
import { CountryOption } from "james/country/countries";
import DeleteIcon from "@mui/icons-material/Delete";
import { EditOutlined as EditIcon } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import { ConnectedEntitiesDialog } from "./components/ConnectedEntitiesDialog/ConnectedEntitiesDialog";
import { ConnectedIndividualDialog } from "./components/ConnectedIndividualDialog/ConnectedIndividualDialog";
import { useInitialDataFetch } from "./hooks/useInitialDataFetch";
import { useSaveCompanyDetails } from "./hooks/useSaveCompanyDetails";

type CompanyViewProps = {
  Client: Client;
};

export function CompanyView(props: CompanyViewProps) {
  const store = useCompanyDetailViewStore();
  useInitialDataFetch(props.Client);
  const { saveCompanyDetails } = useSaveCompanyDetails();
  const theme = useTheme();

  useEffect(() => {
    return () => {
      store.clear();
    };
  }, []);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
        overflowY: "unset",
        height: "94%",
        paddingLeft: "32px",
        paddingRight: "32px",
      }}
    >
      {store.showConnectedIndividualDialog && (
        <ConnectedIndividualDialog
          open={store.showConnectedIndividualDialog}
          onClose={() => {
            store.setShowConnectedIndividualDialog(
              !store.showConnectedIndividualDialog,
            );
          }}
          connectedIndividual={store.selectedConnectedIndividual}
          onAdd={(c: ConnectedIndividual) => {
            store.addConnectedIndividualToList(c);
            store.setShowConnectedIndividualDialog(
              !store.showConnectedIndividualDialog,
            );
          }}
          onUpdate={(c: ConnectedIndividual) => {
            store.updateConnectedIndividualInList(
              store.selectedConnectedIndividualIdx,
              c,
            );
            store.setSelectedConnectedIndividual(
              store.connectedIndividuals[store.selectedConnectedIndividualIdx],
            );
            store.setShowConnectedIndividualDialog(
              !store.showConnectedIndividualDialog,
            );
          }}
        />
      )}
      {store.showConnectedEntitiesDialog && (
        <ConnectedEntitiesDialog
          open={store.showConnectedEntitiesDialog}
          onClose={() => {
            store.setShowConnectedEntitiesDialog(
              !store.showConnectedEntitiesDialog,
            );
          }}
          connectedCompany={store.selectedConnectedEntity}
          onAdd={(c: ConnectedCompany) => {
            store.addConnectedEntityToList(c);
            store.setShowConnectedEntitiesDialog(
              !store.showConnectedEntitiesDialog,
            );
          }}
          onUpdate={(c: ConnectedCompany) => {
            store.updateConnectedCompanyInList(
              store.selectedConnectedEntitiesIdx,
              c,
            );
            store.setSelectedConnectedEntity(
              store.connectedCompanies[store.selectedConnectedEntitiesIdx],
            );
            store.setShowConnectedEntitiesDialog(
              !store.showConnectedEntitiesDialog,
            );
          }}
        />
      )}
      {/*Client Details Section*/}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          paddingBottom: (theme) => theme.spacing(2),
          paddingTop: (theme) => theme.spacing(4),
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Backdrop open={store.loading}>
          <CircularProgress size={100} />
        </Backdrop>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Typography variant={"h4"} fontWeight={"bold"}>
            {props.Client.name}
          </Typography>
          <HistoryIcon color={"disabled"} />
          <div>
            <Typography variant={"body2"} color="textSecondary">
              {`Last update ${dayjs(store.lastUpdateDate).format(
                "YYYY-MM-DD, HH:mm A",
              )}`}
            </Typography>
            <Typography variant={"caption"} color="textSecondary">
              {store.lastUpdateBy}
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => saveCompanyDetails()}
            variant={"contained"}
            color={"primary"}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          overflowY: "scroll",
          height: "auto",
        }}
        className={"meshScroll"}
      >
        {/*Company Information Section*/}
        <Box
          sx={{
            paddingTop: (theme) => theme.spacing(4),
            paddingBottom: (theme) => theme.spacing(4),
            display: "flex",
            column: "column",
            width: "100%",
          }}
        >
          <Typography variant={"h4"} fontWeight={"bold"}>
            Company Information
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            width: "100%",
            height: "240px",
            paddingBottom: (theme) => theme.spacing(3),
            gridTemplateColumns: "repeat(3, 1fr)",
            gridColumnGap: (theme) => theme.spacing(10),
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <TextField
              id="companyProfile-registeredName"
              margin="dense"
              variant="outlined"
              label="Registered Name"
              fullWidth
              value={store.registeredName}
              onChange={(e) => store.setRegisteredName(e.target.value)}
            />
            <TextField
              id="companyDetails-taxReferenceNumber"
              margin="dense"
              fullWidth
              label="Tax Number"
              value={store.taxNumber}
              onChange={(e) => store.setTaxNumber(e.target.value)}
              variant="outlined"
              placeholder="0000000000"
            />
            <TextField
              id="companyProfile-companyRegistrationNumber"
              margin="dense"
              fullWidth
              variant="outlined"
              label="Registration Number"
              value={store.registrationNumber}
              onChange={(e) => store.setRegistrationNumber(e.target.value)}
              placeholder="YYYY/MMMMMM/NN"
            />
            <TextField
              id="companyDetails-vatRegistrationNumber"
              margin="dense"
              fullWidth
              variant="outlined"
              label="Vat Number"
              value={store.vatRegistrationNumber}
              onChange={(e) => store.setVATRegistrationNumber(e.target.value)}
              placeholder="0000000000"
            />
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <TextField
              id="companyDetails-listingReference"
              label="Listing Reference"
              margin="dense"
              fullWidth
              value={store.listingReference}
              onChange={(e) => store.setListingReference(e.target.value)}
              variant="outlined"
            />
            <TextField
              id="companyDetails-countryOfIncorporation"
              margin="dense"
              variant="outlined"
              select
              fullWidth
              value={store.countryOfIncorporation}
              onChange={(e) => store.setCountryOfIncorporation(e.target.value)}
              label="Country of Incorporation"
            >
              <MenuItem value="ZA">SOUTH AFRICA</MenuItem>
            </TextField>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option === value}
              id="companyProfile-formOfIncorporation-autocomplete"
              options={AllLegalForms}
              fullWidth
              value={(() => {
                const formOfIncorporation = AllLegalForms.find(
                  (formOfIncorporation) =>
                    formOfIncorporation === store.formOfIncorporation,
                );
                return formOfIncorporation || null;
              })()}
              onChange={(__: object, formOfIncorporation: LegalForm | null) => {
                if (formOfIncorporation) {
                  store.setFormOfIncorporation(formOfIncorporation);
                }
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  id="companyProfile-formOfIncorporation-textField"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Form Of Incorporation"
                />
              )}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          ></Box>
        </Box>
        {/*Company Addresses*/}
        <Box
          sx={{
            display: "grid",
            width: "100%",
            height: "400px",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridColumnGap: (theme) => theme.spacing(10),
          }}
        >
          {/*Company Business Addresses*/}
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                paddingBottom: (theme) => theme.spacing(2),
              }}
              variant={"h6"}
            >
              Operating Address
            </Typography>
            <TextField
              id="companyView--businessAddressLine1-formField"
              margin="dense"
              variant="outlined"
              label="Address 1"
              value={store.businessAddress.addressLine1}
              onChange={(e) =>
                store.setBusinessAddress(
                  new Address({
                    ...store.businessAddress,
                    addressLine1: e.target.value,
                  }),
                )
              }
              fullWidth
            />
            <TextField
              id="companyView--businessAddressLine2-formField"
              margin="dense"
              placeholder="Optional"
              variant="outlined"
              label="Address 2"
              value={store.businessAddress.addressLine2}
              onChange={(e) =>
                store.setBusinessAddress(
                  new Address({
                    ...store.businessAddress,
                    addressLine2: e.target.value,
                  }),
                )
              }
              fullWidth
            />
            <TextField
              id="companyView--businessAddressSuburb-formField"
              margin="dense"
              variant="outlined"
              label="Suburb"
              placeholder="Optional"
              value={store.businessAddress.suburb}
              onChange={(e) =>
                store.setBusinessAddress(
                  new Address({
                    ...store.businessAddress,
                    suburb: e.target.value,
                  }),
                )
              }
              fullWidth
            />
            <TextField
              id="companyView-businessAddressCity-formfield"
              margin="dense"
              variant="outlined"
              label="City"
              value={store.businessAddress.city}
              onChange={(e) =>
                store.setBusinessAddress(
                  new Address({
                    ...store.businessAddress,
                    city: e.target.value,
                  }),
                )
              }
              fullWidth
            />
            <TextField
              id="companyView-businessAddressProvince-formfield"
              margin="dense"
              variant="outlined"
              placeholder="Optional"
              label="Province"
              value={store.businessAddress.province}
              onChange={(e) =>
                store.setBusinessAddress(
                  new Address({
                    ...store.businessAddress,
                    province: e.target.value,
                  }),
                )
              }
              fullWidth
            />
            <Autocomplete
              id="companyView-businessAddressCountry-autoCompleteFormfield"
              sx={{
                marginBottom: (theme) => theme.spacing(1),
              }}
              options={countries}
              disabled={store.loading}
              getOptionLabel={(option) => (option ? option.label : "")}
              isOptionEqualToValue={(option, value) => {
                if (value.value === "") {
                  return true;
                }
                return option.value === value.value;
              }}
              onChange={(__: object, country: CountryOption | null) => {
                if (country) {
                  store.setBusinessAddress(
                    new Address({
                      ...store.businessAddress,
                      countryCode: country.value,
                    }),
                  );
                }
              }}
              value={(() => {
                const c = countries.find(
                  (countryOption) =>
                    countryOption.value === store.businessAddress.countryCode,
                );
                return c || null;
              })()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Country"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <TextField
              id="companyView-businessAddressPostalCode-formfield"
              margin="dense"
              variant="outlined"
              label="Postal Code"
              value={store.businessAddress.postalCode}
              onChange={(e) =>
                store.setBusinessAddress(
                  new Address({
                    ...store.businessAddress,
                    postalCode: e.target.value,
                  }),
                )
              }
              placeholder="Optional"
            />
          </Box>
          {/*Company Registered Addresses*/}
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                paddingBottom: (theme) => theme.spacing(2),
              }}
              variant={"h6"}
            >
              Registered Address
            </Typography>
            <TextField
              id="companyView-registeredAddressLine1-formfield"
              margin="dense"
              variant="outlined"
              label="Address 1"
              value={store.registeredAddress.addressLine1}
              onChange={(e) =>
                store.setRegisteredAddress(
                  new Address({
                    ...store.registeredAddress,
                    addressLine1: e.target.value,
                  }),
                )
              }
              fullWidth
            />
            <TextField
              id="companyView-registeredAddressLine2-formfield"
              margin="dense"
              placeholder="Optional"
              variant="outlined"
              label="Address 2"
              value={store.registeredAddress.addressLine2}
              onChange={(e) =>
                store.setRegisteredAddress(
                  new Address({
                    ...store.registeredAddress,
                    addressLine2: e.target.value,
                  }),
                )
              }
              fullWidth
            />
            <TextField
              id="companyView-registeredAddressSuburb-formfield"
              margin="dense"
              variant="outlined"
              label="Suburb"
              value={store.registeredAddress.suburb}
              onChange={(e) =>
                store.setRegisteredAddress(
                  new Address({
                    ...store.registeredAddress,
                    suburb: e.target.value,
                  }),
                )
              }
              placeholder="Optional"
              fullWidth
            />
            <TextField
              id="companyView-registeredAddressCity-formfield"
              margin="dense"
              variant="outlined"
              value={store.registeredAddress.city}
              onChange={(e) =>
                store.setRegisteredAddress(
                  new Address({
                    ...store.registeredAddress,
                    city: e.target.value,
                  }),
                )
              }
              label="City"
              fullWidth
            />
            <TextField
              id="companyView-registeredAddressProvince-formfield"
              margin="dense"
              variant="outlined"
              placeholder="Optional"
              value={store.registeredAddress.province}
              onChange={(e) =>
                store.setRegisteredAddress(
                  new Address({
                    ...store.registeredAddress,
                    province: e.target.value,
                  }),
                )
              }
              label="Province"
              fullWidth
            />
            <Autocomplete
              isOptionEqualToValue={(option, value) => option === value}
              id="cocompanyView-registeredAddressCountry-formfield"
              sx={{
                marginBottom: (theme) => theme.spacing(1),
              }}
              options={countries}
              disabled={store.loading}
              getOptionLabel={(option) => (option ? option.label : "")}
              onChange={(__: object, country: CountryOption | null) => {
                if (country) {
                  store.setRegisteredAddress(
                    new Address({
                      ...store.registeredAddress,
                      countryCode: country.value,
                    }),
                  );
                }
              }}
              value={(() => {
                const c = countries.find(
                  (countryOption) =>
                    countryOption.value === store.registeredAddress.countryCode,
                );
                return c || null;
              })()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Country"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <TextField
              id="companyView-registeredAddressPostalCode-formfield"
              margin="dense"
              variant="outlined"
              label="Postal Code"
              placeholder="Optional"
              value={store.registeredAddress.postalCode}
              onChange={(e) =>
                store.setRegisteredAddress(
                  new Address({
                    ...store.registeredAddress,
                    postalCode: e.target.value,
                  }),
                )
              }
            />
          </Box>
          {/*Company Head Office Addresses*/}
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                paddingBottom: (theme) => theme.spacing(2),
              }}
              variant={"h6"}
            >
              Head Office Address
            </Typography>
            <TextField
              id="companyView-headOfficeAddressLine1-formfield"
              margin="dense"
              variant="outlined"
              label="Address 1"
              value={store.headOfficeAddress.addressLine1}
              onChange={(e) =>
                store.setHeadOfficeAddress(
                  new Address({
                    ...store.headOfficeAddress,
                    addressLine1: e.target.value,
                  }),
                )
              }
              fullWidth
            />
            <TextField
              id="companyView-headOfficeAddressLine2-formfield"
              margin="dense"
              placeholder="Optional"
              variant="outlined"
              label="Address 2"
              value={store.headOfficeAddress.addressLine2}
              onChange={(e) =>
                store.setHeadOfficeAddress(
                  new Address({
                    ...store.headOfficeAddress,
                    addressLine2: e.target.value,
                  }),
                )
              }
              fullWidth
            />
            <TextField
              id="companyView-headOfficeSuburb-formfield"
              margin="dense"
              variant="outlined"
              label="Suburb"
              placeholder="Optional"
              value={store.headOfficeAddress.suburb}
              onChange={(e) =>
                store.setHeadOfficeAddress(
                  new Address({
                    ...store.headOfficeAddress,
                    suburb: e.target.value,
                  }),
                )
              }
              fullWidth
            />
            <TextField
              id="companyView-headOfficeCity-formfield"
              margin="dense"
              variant="outlined"
              label="City"
              value={store.headOfficeAddress.city}
              onChange={(e) =>
                store.setHeadOfficeAddress(
                  new Address({
                    ...store.headOfficeAddress,
                    city: e.target.value,
                  }),
                )
              }
              fullWidth
            />
            <TextField
              id="companyView-headOfficeProvince-formfield"
              margin="dense"
              variant="outlined"
              placeholder="Optional"
              label="Province"
              value={store.headOfficeAddress.province}
              onChange={(e) =>
                store.setHeadOfficeAddress(
                  new Address({
                    ...store.headOfficeAddress,
                    province: e.target.value,
                  }),
                )
              }
              fullWidth
            />
            <Autocomplete
              isOptionEqualToValue={(option, value) => option === value}
              id="companyView-headOfficeCountry-autoCompleteFormfield"
              sx={{
                marginBottom: (theme) => theme.spacing(1),
              }}
              options={countries}
              disabled={store.loading}
              getOptionLabel={(option) => (option ? option.label : "")}
              onChange={(__: object, country: CountryOption | null) => {
                if (country) {
                  store.setHeadOfficeAddress(
                    new Address({
                      ...store.headOfficeAddress,
                      countryCode: country.value,
                    }),
                  );
                }
              }}
              value={(() => {
                const c = countries.find(
                  (countryOption) =>
                    countryOption.value === store.headOfficeAddress.countryCode,
                );
                return c || null;
              })()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Country"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <TextField
              id="companyView-headOfficePostalCode-formfield"
              margin="dense"
              variant="outlined"
              label="Postal Code"
              placeholder="Optional"
              value={store.headOfficeAddress.postalCode}
              onChange={(e) =>
                store.setHeadOfficeAddress(
                  new Address({
                    ...store.headOfficeAddress,
                    postalCode: e.target.value,
                  }),
                )
              }
            />
          </Box>
        </Box>
        {/*Company Classification*/}
        <Box
          sx={{
            display: "grid",
            width: "100%",
            height: "120px",
            paddingTop: (theme) => theme.spacing(2),
            paddingBottom: (theme) => theme.spacing(2),
            gridTemplateColumns: "repeat(3, 1fr)",
            gridColumnGap: (theme) => theme.spacing(10),
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                paddingBottom: (theme) => theme.spacing(2),
              }}
              variant={"h6"}
            >
              Industry Classification
            </Typography>
            <Autocomplete
              id="companyView-industryClassification-formfield"
              options={IndustryClassificationOptions}
              getOptionLabel={(option) =>
                !isEqual(
                  new IndustryClassification(option),
                  new IndustryClassification(),
                )
                  ? `${option.industryName} - ${option.subIndustryName}`
                  : ""
              }
              isOptionEqualToValue={(option, value) => {
                if (
                  (value.industryName as IndustryClassification | "") === ""
                ) {
                  return true;
                }
                return option.industryName === value.industryName;
              }}
              value={store.industryClassification}
              onChange={(
                __: object,
                industryClassification: IndustryClassification | null,
              ) => {
                if (industryClassification) {
                  store.setIndustryClassification(industryClassification);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Industry Classification"
                  InputProps={{
                    ...params.InputProps,
                    placeholder: "Select...",
                  }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                paddingBottom: (theme) => theme.spacing(2),
              }}
              variant={"h6"}
            >
              Listed Exchange
            </Typography>
            <Autocomplete
              id="companyView-listedExchange-formfield"
              options={AllStockExchanges}
              isOptionEqualToValue={(
                option: StockExchange,
                value: StockExchange,
              ) => {
                return option.name === value.name && option.code === value.code;
              }}
              renderOption={(props, option) => (
                <Typography
                  {...props}
                  key={option.name}
                  children={`${option.code}: ${option.name}`}
                />
              )}
              getOptionLabel={(option) => option.name}
              value={(() => {
                const c = AllStockExchanges.find(
                  (stockExchange) =>
                    stockExchange.code === store.listedExchange.code,
                );
                return c || null;
              })()}
              onChange={(__: object, listedExchange: StockExchange | null) => {
                if (listedExchange) {
                  store.setListedExchange(listedExchange);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Listed Exchange"
                  margin="dense"
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          ></Box>
        </Box>
        {/*Company Representative*/}
        <Box
          sx={{
            paddingTop: (theme) => theme.spacing(4),
            paddingBottom: (theme) => theme.spacing(4),
            display: "flex",
            column: "column",
            width: "100%",
          }}
        >
          <Typography variant={"h4"} fontWeight={"bold"}>
            Company Representative
          </Typography>
        </Box>
        {/*Company Representative Contacts*/}
        <Box
          sx={{
            display: "grid",
            width: "100%",
            height: "240px",
            paddingTop: (theme) => theme.spacing(3),
            paddingBottom: (theme) => theme.spacing(3),
            gridTemplateColumns: "repeat(3, 1fr)",
            gridColumnGap: (theme) => theme.spacing(10),
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <TextField
              id="companyView-companyRepresentativeFirstName-formfield"
              variant="outlined"
              fullWidth
              margin="dense"
              label="First Name"
              value={store.companyRepresentative.firstName}
              onChange={(e) =>
                store.setCompanyRepresentative(
                  new CompanyRepresentative({
                    ...store.companyRepresentative,
                    firstName: e.target.value,
                  }),
                )
              }
            />
            <TextField
              id="companyView-companyRepresentativeLastName-formfield"
              variant="outlined"
              fullWidth
              margin="dense"
              label="Last Name"
              value={store.companyRepresentative.lastName}
              onChange={(e) =>
                store.setCompanyRepresentative(
                  new CompanyRepresentative({
                    ...store.companyRepresentative,
                    lastName: e.target.value,
                  }),
                )
              }
            />
            <TextField
              id="companyView-companyRepresentativeMiddleName-formfield"
              margin="dense"
              variant="outlined"
              fullWidth
              label="Middle Names(s)"
              placeholder="Optional"
              value={store.companyRepresentative.middleNames}
              onChange={(e) =>
                store.setCompanyRepresentative(
                  new CompanyRepresentative({
                    ...store.companyRepresentative,
                    middleNames: e.target.value,
                  }),
                )
              }
            />
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <TextField
              id="companyView-companyRepresentativeTelephoneNumber-formfield"
              variant="outlined"
              fullWidth
              margin="dense"
              label="Telephone"
              value={store.companyRepresentative.telephoneNumber}
              onChange={(e) =>
                store.setCompanyRepresentative(
                  new CompanyRepresentative({
                    ...store.companyRepresentative,
                    telephoneNumber: e.target.value,
                  }),
                )
              }
            />
            <TextField
              id="companyView-companyRepresentativeCellphoneNumber-formfield"
              variant="outlined"
              margin="dense"
              fullWidth
              label="Mobile"
              value={store.companyRepresentative.cellphoneNumber}
              onChange={(e) =>
                store.setCompanyRepresentative(
                  new CompanyRepresentative({
                    ...store.companyRepresentative,
                    cellphoneNumber: e.target.value,
                  }),
                )
              }
            />
            <TextField
              id="companyView-companyRepresentativeEmailAddress-formfield"
              variant="outlined"
              fullWidth
              label="Email"
              margin="dense"
              value={store.companyRepresentative.emailAddress}
              onChange={(e) =>
                store.setCompanyRepresentative(
                  new CompanyRepresentative({
                    ...store.companyRepresentative,
                    emailAddress: e.target.value,
                  }),
                )
              }
            />
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          ></Box>
        </Box>
        {/*Company Representative Addresses*/}
        <Box
          sx={{
            display: "grid",
            width: "100%",
            height: "400px",
            paddingTop: (theme) => theme.spacing(2),
            paddingBottom: (theme) => theme.spacing(2),
            gridTemplateColumns: "repeat(3, 1fr)",
            gridColumnGap: (theme) => theme.spacing(10),
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                paddingBottom: (theme) => theme.spacing(2),
              }}
              variant={"h6"}
            >
              Physical Address
            </Typography>
            <TextField
              id="companyView-companyRepresentativePhysicalAddressLine1-formfield"
              margin="dense"
              variant="outlined"
              label="Address 1"
              value={
                new Address(store.companyRepresentative.physicalAddress)
                  .addressLine1
              }
              onChange={(e) => {
                const currentAddress = new Address(
                  store.companyRepresentative.physicalAddress,
                );
                store.setCompanyRepresentative({
                  ...store.companyRepresentative,
                  physicalAddress: new Address({
                    ...currentAddress,
                    addressLine1: e.target.value,
                  }),
                });
              }}
              fullWidth
            />
            <TextField
              id="companyView-companyRepresentativePhysicalAddressLine2-formfield"
              margin="dense"
              placeholder="Optional"
              variant="outlined"
              label="Address 2"
              value={
                new Address(store.companyRepresentative.physicalAddress)
                  .addressLine2
              }
              onChange={(e) => {
                const currentAddress = new Address(
                  store.companyRepresentative.physicalAddress,
                );
                store.setCompanyRepresentative({
                  ...store.companyRepresentative,
                  physicalAddress: new Address({
                    ...currentAddress,
                    addressLine2: e.target.value,
                  }),
                });
              }}
              fullWidth
            />
            <TextField
              id="companyView-companyRepresentativePhysicalAddressSuburb-formfield"
              margin="dense"
              variant="outlined"
              label="Suburb"
              placeholder="Optional"
              value={
                new Address(store.companyRepresentative.physicalAddress).suburb
              }
              onChange={(e) => {
                const currentAddress = new Address(
                  store.companyRepresentative.physicalAddress,
                );
                store.setCompanyRepresentative({
                  ...store.companyRepresentative,
                  physicalAddress: new Address({
                    ...currentAddress,
                    suburb: e.target.value,
                  }),
                });
              }}
              fullWidth
            />
            <TextField
              id="companyView-companyRepresentativePhysicalAddressCity-formfield"
              margin="dense"
              variant="outlined"
              label="City"
              value={
                new Address(store.companyRepresentative.physicalAddress).city
              }
              onChange={(e) => {
                const currentAddress = new Address(
                  store.companyRepresentative.physicalAddress,
                );
                store.setCompanyRepresentative({
                  ...store.companyRepresentative,
                  physicalAddress: new Address({
                    ...currentAddress,
                    city: e.target.value,
                  }),
                });
              }}
              fullWidth
            />
            <TextField
              id="companyView-companyRepresentativePhysicalAddressProvince-formfield"
              margin="dense"
              variant="outlined"
              placeholder="Optional"
              label="Province"
              value={
                new Address(store.companyRepresentative.physicalAddress)
                  .province
              }
              onChange={(e) => {
                const currentAddress = new Address(
                  store.companyRepresentative.physicalAddress,
                );
                store.setCompanyRepresentative({
                  ...store.companyRepresentative,
                  physicalAddress: new Address({
                    ...currentAddress,
                    province: e.target.value,
                  }),
                });
              }}
              fullWidth
            />
            <Autocomplete
              id="companyView-companyRepresentativePhysicalAddressCountry-formfield"
              sx={{
                marginBottom: (theme) => theme.spacing(1),
              }}
              options={countries}
              disabled={store.loading}
              getOptionLabel={(option) => (option ? option.label : "")}
              isOptionEqualToValue={(option, value) => {
                if (value.value === "") {
                  return true;
                }
                return option.value === value.value;
              }}
              onChange={(__: object, country: CountryOption | null) => {
                if (country) {
                  const currentAddress = new Address(
                    store.companyRepresentative.physicalAddress,
                  );
                  store.setCompanyRepresentative({
                    ...store.companyRepresentative,
                    physicalAddress: new Address({
                      ...currentAddress,
                      countryCode: country.value,
                    }),
                  });
                }
              }}
              value={(() => {
                const c = countries.find(
                  (countryOption) =>
                    countryOption.value ===
                    store.companyRepresentative.physicalAddress?.countryCode,
                );
                return c || null;
              })()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Country"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <TextField
              id="companyView-companyRepresentativePhysicalAddressPostalCode-formfield"
              margin="dense"
              variant="outlined"
              label="Postal Code"
              placeholder="Optional"
              value={
                new Address(store.companyRepresentative.physicalAddress)
                  .postalCode
              }
              onChange={(e) => {
                const currentAddress = new Address(
                  store.companyRepresentative.physicalAddress,
                );
                store.setCompanyRepresentative({
                  ...store.companyRepresentative,
                  physicalAddress: new Address({
                    ...currentAddress,
                    postalCode: e.target.value,
                  }),
                });
              }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                paddingBottom: (theme) => theme.spacing(2),
              }}
              variant={"h6"}
            >
              Postal Address
            </Typography>
            <TextField
              id="companyView-companyRepresentativePostalAddressLine1-formfield"
              margin="dense"
              variant="outlined"
              label="Address 1"
              value={
                new Address(store.companyRepresentative.postalAddress)
                  .addressLine1
              }
              onChange={(e) => {
                const currentAddress = new Address(
                  store.companyRepresentative.postalAddress,
                );
                store.setCompanyRepresentative({
                  ...store.companyRepresentative,
                  postalAddress: new Address({
                    ...currentAddress,
                    addressLine1: e.target.value,
                  }),
                });
              }}
              fullWidth
            />
            <TextField
              id="companyView-companyRepresentativePostalAddressLine2-formfield"
              margin="dense"
              placeholder="Optional"
              variant="outlined"
              label="Address 2"
              value={
                new Address(store.companyRepresentative.postalAddress)
                  .addressLine2
              }
              onChange={(e) => {
                const currentAddress = new Address(
                  store.companyRepresentative.postalAddress,
                );
                store.setCompanyRepresentative({
                  ...store.companyRepresentative,
                  postalAddress: new Address({
                    ...currentAddress,
                    addressLine2: e.target.value,
                  }),
                });
              }}
              fullWidth
            />
            <TextField
              id="companyView-companyRepresentativePostalAddressSuburb-formfield"
              margin="dense"
              variant="outlined"
              label="Suburb"
              placeholder="Optional"
              value={
                new Address(store.companyRepresentative.postalAddress).suburb
              }
              onChange={(e) => {
                const currentAddress = new Address(
                  store.companyRepresentative.postalAddress,
                );
                store.setCompanyRepresentative({
                  ...store.companyRepresentative,
                  postalAddress: new Address({
                    ...currentAddress,
                    suburb: e.target.value,
                  }),
                });
              }}
              fullWidth
            />
            <TextField
              id="companyView-companyRepresentativePostalAddressCity-formfield"
              margin="dense"
              variant="outlined"
              value={
                new Address(store.companyRepresentative.postalAddress).city
              }
              onChange={(e) => {
                const currentAddress = new Address(
                  store.companyRepresentative.postalAddress,
                );
                store.setCompanyRepresentative({
                  ...store.companyRepresentative,
                  postalAddress: new Address({
                    ...currentAddress,
                    city: e.target.value,
                  }),
                });
              }}
              label="City"
              fullWidth
            />
            <TextField
              id="companyView-companyRepresentativePostalAddressProvince-formfield"
              margin="dense"
              variant="outlined"
              placeholder="Optional"
              value={
                new Address(store.companyRepresentative.postalAddress).province
              }
              onChange={(e) => {
                const currentAddress = new Address(
                  store.companyRepresentative.postalAddress,
                );
                store.setCompanyRepresentative({
                  ...store.companyRepresentative,
                  postalAddress: new Address({
                    ...currentAddress,
                    province: e.target.value,
                  }),
                });
              }}
              label="Province"
              fullWidth
            />
            <Autocomplete
              id="companyView-companyRepresentativePostalAddressCountry-formfield"
              sx={{
                marginBottom: (theme) => theme.spacing(1),
              }}
              options={countries}
              disabled={store.loading}
              getOptionLabel={(option) => (option ? option.label : "")}
              isOptionEqualToValue={(option, value) => {
                if (value.value === "") {
                  return true;
                }
                return option.value === value.value;
              }}
              onChange={(__: object, country: CountryOption | null) => {
                if (country) {
                  const currentAddress = new Address(
                    store.companyRepresentative.postalAddress,
                  );
                  store.setCompanyRepresentative({
                    ...store.companyRepresentative,
                    postalAddress: new Address({
                      ...currentAddress,
                      countryCode: country.value,
                    }),
                  });
                }
              }}
              value={(() => {
                const c = countries.find(
                  (countryOption) =>
                    countryOption.value ===
                    store.companyRepresentative.postalAddress?.countryCode,
                );
                return c || null;
              })()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Country"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <TextField
              id="companyView-companyRepresentativePostalAddressPostalCode-formfield"
              margin="dense"
              variant="outlined"
              label="Postal Code"
              value={
                new Address(store.companyRepresentative.postalAddress)
                  .postalCode
              }
              onChange={(e) => {
                const currentAddress = new Address(
                  store.companyRepresentative.postalAddress,
                );
                store.setCompanyRepresentative({
                  ...store.companyRepresentative,
                  postalAddress: new Address({
                    ...currentAddress,
                    postalCode: e.target.value,
                  }),
                });
              }}
              placeholder="Optional"
            />
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          ></Box>
        </Box>
        {/*Connected Individuals*/}
        <Box
          sx={{
            paddingTop: (theme) => theme.spacing(4),
            paddingBottom: (theme) => theme.spacing(4),
            display: "flex",
            column: "column",
            width: "100%",
          }}
        >
          <Typography variant={"h4"} fontWeight={"bold"}>
            Connected Individuals
          </Typography>
        </Box>
        <FETable
          singleSelect
          height={326}
          backgroundColor={theme.palette.custom.midnight}
          data={store.connectedIndividuals}
          title="List of Individuals"
          toolBarControls={(() => {
            const controls: React.ReactNode[] = [];

            if (store.selectedConnectedIndividualIdx >= 0) {
              controls.push(
                <Tooltip title="Remove" placement="top">
                  <span>
                    <IconButton
                      id="connectedIndividualTable-remove-iconButton"
                      size="small"
                      disabled={store.loading}
                      onClick={() => {
                        store.removeConnectedIndividualFromList(
                          store.selectedConnectedIndividualIdx,
                        );
                        store.setSelectedConnectedIndividualIdx(-1);
                        store.setSelectedConnectedEntity(undefined);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>,
              );

              controls.push(
                <Tooltip title="Edit" placement="top">
                  <span>
                    <IconButton
                      id="connectedIndividualTable-edit-iconButton"
                      size="small"
                      disabled={store.loading}
                      onClick={() => {
                        store.setShowConnectedIndividualDialog(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>,
              );
            } else {
              controls.push(
                <Button
                  variant="contained"
                  color="secondary"
                  id="connectedIndividual-newIndividual"
                  onClick={() => {
                    store.setShowConnectedIndividualDialog(true);
                  }}
                >
                  <Add />
                  Individual
                </Button>,
              );
            }
            return controls;
          })()}
          onSingleSelectChange={(selectedRowData, idx) => {
            if (selectedRowData) {
              store.setSelectedConnectedIndividualIdx(idx);
              store.setSelectedConnectedIndividual(
                selectedRowData as ConnectedIndividual,
              );
            } else {
              store.setSelectedConnectedIndividualIdx(-1);
              store.setSelectedConnectedIndividual(undefined);
            }
          }}
          columns={[
            {
              label: "First Name",
              field: "firstName",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => (
                <Typography
                  variant="body1"
                  color="textPrimary"
                  children={(data as ConnectedIndividualClass).firstName}
                />
              ),
            },
            {
              label: "Last Name",
              field: "lastName",
            },
            {
              label: "Email",
              field: "emailAddress",
            },
            {
              label: "Mobile",
              field: "cellphoneNumber",
            },
          ]}
        />
        {/*Connected Entities*/}
        <Box
          sx={{
            paddingTop: (theme) => theme.spacing(4),
            paddingBottom: (theme) => theme.spacing(4),
            display: "flex",
            column: "column",
            width: "100%",
          }}
        >
          <Typography variant={"h4"} fontWeight={"bold"}>
            Connected Entities
          </Typography>
        </Box>
        <FETable
          singleSelect
          height={408}
          backgroundColor={theme.palette.custom.midnight}
          data={store.connectedCompanies}
          title="List of Connected Entities"
          toolBarControls={(() => {
            const controls: React.ReactNode[] = [];

            if (store.selectedConnectedEntitiesIdx >= 0) {
              controls.push(
                <Tooltip title="Remove" placement="top">
                  <span>
                    <IconButton
                      id="connectedNonIndividualTable-remove-iconButton"
                      size="small"
                      disabled={store.loading}
                      onClick={() => {
                        store.removeConnectedEntityFromList(
                          store.selectedConnectedEntitiesIdx,
                        );
                        store.setSelectedConnectedEntitiesIdx(-1);
                        store.setSelectedConnectedEntity(undefined);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>,
              );

              controls.push(
                <Tooltip title="Edit" placement="top">
                  <span>
                    <IconButton
                      id="connectedNonIndividualTable-edit-iconButton"
                      size="small"
                      disabled={store.loading}
                      onClick={() => store.setShowConnectedEntitiesDialog(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>,
              );
            } else {
              controls.push(
                <Button
                  variant="contained"
                  color="secondary"
                  id="connectedNonIndividual-newNonIndividual-button"
                  onClick={() => store.setShowConnectedEntitiesDialog(true)}
                >
                  <Add />
                  New Entity
                </Button>,
              );
            }

            return controls;
          })()}
          onSingleSelectChange={(selectedRowData, idx) => {
            if (selectedRowData) {
              store.setSelectedConnectedEntitiesIdx(idx);
              store.setSelectedConnectedEntity(
                selectedRowData as ConnectedCompany,
              );
            } else {
              store.setSelectedConnectedEntitiesIdx(-1);
              store.setSelectedConnectedEntity(undefined);
            }
          }}
          columns={[
            {
              label: "Name",
              field: "businessName",
              accessor: (data) => (
                <Typography
                  variant="body1"
                  color="textPrimary"
                  children={(data as ConnectedCompany).businessName}
                />
              ),
            },
            {
              label: "Representative Name",
              field: "representativeName",
              accessor: (data) =>
                (data as ConnectedCompany).companyRepresentative.firstName,
            },
            {
              label: "Representative Email",
              field: "representativeEmail",
              accessor: (data) =>
                (data as ConnectedCompany).companyRepresentative.emailAddress,
            },
            {
              label: "Representative Mobile",
              field: "representativeMobile",
              accessor: (data) =>
                (data as ConnectedCompany).companyRepresentative
                  .cellphoneNumber,
            },
          ]}
        />
      </Box>
    </Box>
  );
}
