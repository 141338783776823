import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Reader,
  ReadRequest,
} from "james/views/stellarTransactionHistoryView/Reader";
import { BPTable } from "components/Table";
import {
  alpha,
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NewSorting, Query } from "james/search/query";
import { Amount } from "components/Ledger/Amount";
import {
  Model,
  EffectType,
} from "james/views/stellarTransactionHistoryView/Model";
import { DateFormat } from "const/dateformats";
import dayjs from "dayjs";
import { Token } from "james/ledger";
import { TextExactCriterion } from "james/search/criterion";
import { useSnackbar } from "notistack";
import { InfiniteScrollList } from "components/Table/InfCardTable";
import { FaceOutlined as FaceIcon } from "@mui/icons-material";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

type AssetTransactionHistoryTableProps = {
  token: Token;
  accountID: string;
};

export function AssetTransactionHistoryTable(
  props: AssetTransactionHistoryTableProps,
) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) {
    return (
      <MobileDesktopAssetTransactionHistoryTable
        token={props.token}
        accountID={props.accountID}
      />
    );
  }

  return (
    <DesktopAssetTransactionHistoryTable
      token={props.token}
      accountID={props.accountID}
    />
  );
}

const MobileDesktopAssetTransactionHistoryTable = (
  props: AssetTransactionHistoryTableProps,
) => {
  const { errorContextErrorTranslator } = useErrorContext();
  const [total, setTotal] = useState(0);
  const { authContext } = useApplicationContext();
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState<Model[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const parentContainerHandle = useRef<HTMLDivElement>(null);

  const loadMoreItems =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (criteria: any, query: Query, updateOffset: () => void) => async () => {
      setLoading(true);

      try {
        const response = await Reader.Read({
          context: authContext,
          query,
          criteria,
        });

        updateOffset();

        setModels([...models, ...response.models]);

        setTotal(response.total);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error reading stellar transaction view models:${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `Error Retrieving Transaction History ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }

      setLoading(false);
    };

  return (
    <Box ref={parentContainerHandle}>
      <Typography
        sx={(theme) => ({
          padding: theme.spacing(1.5, 0, 1.5, 2),
          backgroundColor: theme.palette.custom.midnight,
        })}
      >
        Transaction History
      </Typography>
      <InfiniteScrollList
        total={total}
        data={models}
        isLoading={loading}
        clearData={() => setModels([])}
        loadMoreData={loadMoreItems}
        rowWidth={"auto"}
        listHeight={300}
        rowHeight={150}
        batchSize={5}
        threshold={0}
        rowGap={0}
        disableMeshScroll={true}
        hideScrollbars={true}
        lastRowPadding={16}
        noDataSplashComponent={
          <Box
            sx={{
              height: "98%",
              position: "fixed",
              width: "100%",
              display: "flex",
              backgroundColor: (theme) => theme.palette.background.paper,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
            }}
          >
            <FaceIcon
              sx={(theme) => ({
                fontSize: 110,
                color: alpha(theme.palette.background.default, 0.5),
              })}
            />
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
                marginBottom: (theme) => theme.spacing(2),
              }}
              children={"No Transaction History"}
            />
            <Typography
              variant="h5"
              sx={{
                width: "226px",
                color: (theme) => theme.palette.text.disabled,
                textAlign: "center",
              }}
              children="You will see an entry once a transaction is made."
            />
          </Box>
        }
        renderLoadingRow={
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
              alignContent: "center",
              height: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(2, auto)",
              padding: (theme) => theme.spacing(2, 0, 2, 0),
              margin: (theme) => theme.spacing(0, 2, 0, 2),
              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: (theme) => theme.spacing(1),
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Skeleton sx={{ transform: "none" }} height={12} width={80} />
              <Skeleton sx={{ transform: "none" }} height={12} width={120} />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: (theme) => theme.spacing(1),
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <Skeleton sx={{ transform: "none" }} height={12} width={100} />
              <Skeleton sx={{ transform: "none" }} height={12} width={150} />
            </Box>
          </Box>
        }
        initialCriteria={{
          "amount.token.code": TextExactCriterion(props.token.code),
          "amount.token.issuer": TextExactCriterion(props.token.issuer),
          "amount.token.network": TextExactCriterion(props.token.network),
          accountID: TextExactCriterion(props.accountID),
        }}
        initialSorting={[
          NewSorting("effectOperationIDInt", "desc"),
          NewSorting("effectApplicationOrder", "desc"),
        ]}
        renderData={(data) => {
          const sign =
            data.effectType === EffectType.AccountDebited ? "-" : "+";

          return (
            <Box
              sx={{
                height: "100%",
                backgroundColor: (theme) => theme.palette.background.paper,
                alignContent: "center",
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, auto)",
                padding: (theme) => theme.spacing(2, 0, 2, 0),
                margin: (theme) => theme.spacing(0, 2, 0, 2),
                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: (theme) => theme.spacing(1),
                  justifyContent: "center",
                  flexDirection: "column",
                  // We are setting the width of the Box to be half the parent width to allow for longer transaction
                  // descriptions to be displayed correctly
                  width: parentContainerHandle.current
                    ? parentContainerHandle.current.clientWidth / 2
                    : "auto",
                }}
              >
                <span>
                  <Typography
                    noWrap={data.description.includes("TxHash")}
                    variant={"body1"}
                    component={"div"}
                    children={data.description}
                  />
                  <Typography
                    children={dayjs(data.effectLedgerCloseTime).format(
                      DateFormat,
                    )}
                  />
                </span>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gap: (theme) => theme.spacing(1),
                  justifyContent: "end",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Typography
                      sx={(theme) => ({
                        color:
                          sign === "+"
                            ? theme.palette.success.light
                            : theme.palette.error.light,
                      })}
                    >
                      {sign}
                    </Typography>
                    <Amount
                      codeTypographyProps={{
                        sx: (theme) => ({
                          color:
                            sign === "+"
                              ? alpha(theme.palette.success.light, 0.5)
                              : alpha(theme.palette.error.light, 0.5),
                        }),
                      }}
                      valueTypographyProps={{
                        sx: (theme) => ({
                          color:
                            sign === "+"
                              ? theme.palette.success.light
                              : theme.palette.error.light,
                        }),
                      }}
                      amount={data.amount}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Amount amount={data.balance} />
                  </Box>
                </div>
              </Box>
            </Box>
          );
        }}
      />
    </Box>
  );
};

const DesktopAssetTransactionHistoryTable = (
  props: AssetTransactionHistoryTableProps,
) => {
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const [models, setModels] = useState<Model[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [readRequest, setReadRequest] = useState({
    context: authContext,
    criteria: {
      "amount.token.code": TextExactCriterion(props.token.code),
      "amount.token.issuer": TextExactCriterion(props.token.issuer),
      "amount.token.network": TextExactCriterion(props.token.network),
      accountID: TextExactCriterion(props.accountID),
    },
    query: new Query({
      limit: 5,
      offset: 0,
      sorting: [
        NewSorting("effectOperationIDInt", "desc"),
        NewSorting("effectApplicationOrder", "desc"),
      ],
    }),
  });

  const handleReadModels = useCallback(
    async (request: ReadRequest) => {
      setLoading(true);
      try {
        const readResponse = await Reader.Read(request);
        setModels(readResponse.models);
        setTotal(readResponse.total);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error reading stellar transaction view models: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Retrieving Transaction History", {
          variant: "error",
        });
      }
      setLoading(false);
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    handleReadModels({
      ...readRequest,
      criteria: {
        ...readRequest.criteria,
        "amount.token.code": TextExactCriterion(props.token.code),
        "amount.token.issuer": TextExactCriterion(props.token.issuer),
        "amount.token.network": TextExactCriterion(props.token.network),
        accountID: TextExactCriterion(props.accountID),
      },
    }).then();
  }, [
    props.accountID,
    props.token.code,
    props.token.issuer,
    props.token.network,
    readRequest,
    handleReadModels,
  ]);

  // render desktop table
  return (
    <BPTable
      divTable
      singleSelect
      title={
        <Typography
          variant="subtitle1"
          sx={{
            ml: 3,
          }}
        >
          Transaction History
        </Typography>
      }
      height={450}
      columns={[
        {
          label: (
            <Typography
              sx={{ ml: { sm: 3, xs: 0 }, fontWeight: "bold", fontSize: 14 }}
            >
              Date
            </Typography>
          ),
          field: "effectLedgerCloseTime",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            const effectLedgerCloseTimeDayjs = dayjs(
              (data as Model).effectLedgerCloseTime,
            );
            return (
              <Typography
                sx={{
                  ml: 3,
                }}
                children={effectLedgerCloseTimeDayjs.format(DateFormat)}
              />
            );
          },
        },
        {
          label: (
            <Typography padding={"0 1.5rem"} fontWeight={"bold"}>
              Description
            </Typography>
          ),
          field: "description",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => (
            <Typography
              sx={{
                overflowWrap: "break-word",
                maxWidth: 250,
                padding: "0 1.5rem",
              }}
              children={(data as Model).description}
            />
          ),
        },
        {
          label: "Amount",
          field: "amount.value.float",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            let sign = "+";
            const stellarTransactionHistoryViewModel = data as Model;
            if (
              stellarTransactionHistoryViewModel.effectType ===
              EffectType.AccountDebited
            ) {
              sign = "-";
            }

            return (
              <Box sx={{ display: "flex" }}>
                <Typography>{sign}</Typography>
                <Amount
                  valueTypographyProps={{ fontSize: () => "0.75rem" }}
                  amount={stellarTransactionHistoryViewModel.amount}
                />
              </Box>
            );
          },
        },
        {
          label: "Balance",
          field: "balance.value.float",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            const stellarTransactionHistoryViewModel = data as Model;

            return (
              <Amount
                valueTypographyProps={{
                  fontSize: "0.75rem",
                  paddingRight: "1rem",
                }}
                amount={stellarTransactionHistoryViewModel.balance}
              />
            );
          },
        },
      ]}
      loading={loading}
      query={readRequest.query}
      onQueryChange={(newQuery: Query) =>
        setReadRequest({
          ...readRequest,
          query: newQuery,
        })
      }
      data={models}
      totalNoRecords={total}
    />
  );
};
