"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringToRateSource = exports.rateSourceToString = exports.allRateSources = void 0;
const rateSource_pb_1 = require("./rateSource_pb");
// Get all rate sources as enum values
exports.allRateSources = Object.values(rateSource_pb_1.RateSource).filter((value) => typeof value === "number");
const rateSourceToStringMapping = {
    [rateSource_pb_1.RateSource.UNDEFINED_RATE_SOURCE]: "-",
    [rateSource_pb_1.RateSource.LIBOR_RATE_SOURCE]: "LIBOR",
    [rateSource_pb_1.RateSource.EURIBOR_RATE_SOURCE]: "EURIBOR",
    [rateSource_pb_1.RateSource.TIBOR_RATE_SOURCE]: "TIBOR",
    [rateSource_pb_1.RateSource.SOFR_RATE_SOURCE]: "SOFR",
    [rateSource_pb_1.RateSource.FED_FUNDS_RATE_SOURCE]: "FED FUNDS",
    [rateSource_pb_1.RateSource.SOUTH_AFRICA_PRIME_RATE_SOURCE]: "Prime (RSA)",
    [rateSource_pb_1.RateSource.OIS_RATE_SOURCE]: "OIS",
    [rateSource_pb_1.RateSource.JIBAR1_RATE_SOURCE]: "JIBAR1",
    [rateSource_pb_1.RateSource.JIBAR3_RATE_SOURCE]: "JIBAR3",
    [rateSource_pb_1.RateSource.CDOR_RATE_SOURCE]: "CDOR",
    [rateSource_pb_1.RateSource.AONIA_RATE_SOURCE]: "AONIA",
    [rateSource_pb_1.RateSource.SARON_RATE_SOURCE]: "SARON",
    [rateSource_pb_1.RateSource.STIBOR_RATE_SOURCE]: "STIBOR",
    [rateSource_pb_1.RateSource.HIBOR_RATE_SOURCE]: "HIBOR",
    [rateSource_pb_1.RateSource.BBSW_RATE_SOURCE]: "BBSW",
    [rateSource_pb_1.RateSource.SIBOR_RATE_SOURCE]: "SIBOR",
    [rateSource_pb_1.RateSource.EONIA_RATE_SOURCE]: "EONIA",
    [rateSource_pb_1.RateSource.CIBOR_RATE_SOURCE]: "CIBOR",
    [rateSource_pb_1.RateSource.NIBOR_RATE_SOURCE]: "NIBOR",
    [rateSource_pb_1.RateSource.PRIBOR_RATE_SOURCE]: "PRIBOR",
    [rateSource_pb_1.RateSource.WIBOR_RATE_SOURCE]: "WIBOR",
    [rateSource_pb_1.RateSource.MOSPRIME_RATE_SOURCE]: "MOSPRIME",
    [rateSource_pb_1.RateSource.US_REPOS_RATE_SOURCE]: "US REPOS",
    [rateSource_pb_1.RateSource.MUNICIPAL_BONDS_RATE_SOURCE]: "MUNICIPAL BONDS",
    [rateSource_pb_1.RateSource.MORTGAGE_BONDS_RATE_SOURCE]: "MORTGAGE BONDS",
    [rateSource_pb_1.RateSource.CORPORATE_BONDS_RATE_SOURCE]: "CORPORATE BONDS",
    [rateSource_pb_1.RateSource.COMMERCIAL_PAPER_RATE_SOURCE]: "COMMERCIAL PAPER",
    [rateSource_pb_1.RateSource.TREASURY_BILLS_RATE_SOURCE]: "TREASURY BILLS",
};
// Reverse mapping from string to RateSource enum
const stringToRateSourceMapping = {};
for (const [key, value] of Object.entries(rateSourceToStringMapping)) {
    stringToRateSourceMapping[value] = Number(key);
}
class UnsupportedRateSourceError extends Error {
    constructor(rateSourceStr) {
        const message = `Unsupported coupon frequency string: ${rateSourceStr}`;
        super(message);
        this.rateSourceStr = rateSourceStr;
    }
}
/**
 * Converts a RateSource enum instance to a custom string representation.
 * @param {RateSource} rateSource - The rate source to convert.
 * @returns {string} The custom string representation of the coupon frequency.
 */
function rateSourceToString(rateSource) {
    if (rateSource in rateSourceToStringMapping) {
        return rateSourceToStringMapping[rateSource];
    }
    else {
        throw new UnsupportedRateSourceError(rateSource);
    }
}
exports.rateSourceToString = rateSourceToString;
class UnsupportedRateSourceStringError extends Error {
    constructor(rateSourceStr) {
        const message = `Unsupported coupon frequency string: ${rateSourceStr}`;
        super(message);
        this.rateSourceStr = rateSourceStr;
    }
}
/**
 * Converts a custom string representation to a RateSource enum instance.
 * @param {string} couponFrequencyStr - The custom string representation of the coupon frequency.
 * @returns {RateSource} The corresponding RateSource enum instance.
 */
function stringToRateSource(rateSourceStr) {
    if (rateSourceStr in rateSourceToStringMapping) {
        return stringToRateSourceMapping[rateSourceStr];
    }
    else {
        throw new UnsupportedRateSourceStringError(rateSourceStr);
    }
}
exports.stringToRateSource = stringToRateSource;
