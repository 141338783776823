"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringToCouponFrequency = exports.couponFrequencyToString = exports.allCouponFrequencies = void 0;
const couponFrequency_pb_1 = require("./couponFrequency_pb");
// Get all coupon frequencies as enum values
exports.allCouponFrequencies = Object.values(couponFrequency_pb_1.CouponFrequency).filter((value) => typeof value === "number");
// Define explicit mappings between CouponFrequency enums and custom string representations
const couponFrequencyToStringMapping = {
    [couponFrequency_pb_1.CouponFrequency.UNDEFINED_COUPON_FREQUENCY]: "-",
    [couponFrequency_pb_1.CouponFrequency.MONTHLY_COUPON_FREQUENCY]: "Monthly",
    [couponFrequency_pb_1.CouponFrequency.QUARTERLY_COUPON_FREQUENCY]: "Quarterly",
    [couponFrequency_pb_1.CouponFrequency.SEMI_ANNUALLY_COUPON_FREQUENCY]: "Semi-Annualy",
    [couponFrequency_pb_1.CouponFrequency.ANNUALLY_COUPON_FREQUENCY]: "Annualy",
};
// Reverse mapping from string to CouponFrequency enum
const stringToCouponFrequencyMapping = {};
for (const [key, value] of Object.entries(couponFrequencyToStringMapping)) {
    stringToCouponFrequencyMapping[value] = Number(key);
}
class UnsupportedCouponFrequencyError extends Error {
    constructor(couponFrequency) {
        const message = `Unsupported CouponFrequency: ${couponFrequency}`;
        super(message);
        this.couponFrequency = couponFrequency;
    }
}
/**
 * Converts a CouponFrequency enum instance to a custom string representation.
 * @param {CouponFrequency} couponFrequency - The coupon frequency to convert.
 * @returns {string} The custom string representation of the coupon frequency.
 */
function couponFrequencyToString(couponFrequency) {
    if (couponFrequency in couponFrequencyToStringMapping) {
        return couponFrequencyToStringMapping[couponFrequency];
    }
    else {
        throw new UnsupportedCouponFrequencyError(couponFrequency);
    }
}
exports.couponFrequencyToString = couponFrequencyToString;
class UnsupportedCouponFrequencyStringError extends Error {
    constructor(couponFrequencyStr) {
        const message = `Unsupported coupon frequency string: ${couponFrequencyStr}`;
        super(message);
        this.couponFrequencyStr = couponFrequencyStr;
    }
}
/**
 * Converts a custom string representation to a CouponFrequency enum instance.
 * @param {string} couponFrequencyStr - The custom string representation of the coupon frequency.
 * @returns {CouponFrequency} The corresponding CouponFrequency enum instance.
 */
function stringToCouponFrequency(couponFrequencyStr) {
    if (couponFrequencyStr in stringToCouponFrequencyMapping) {
        return stringToCouponFrequencyMapping[couponFrequencyStr];
    }
    else {
        throw new UnsupportedCouponFrequencyStringError(couponFrequencyStr);
    }
}
exports.stringToCouponFrequency = stringToCouponFrequency;
