import { useCurrentAPICall, useIsMounted } from "hooks";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import {
  DirectOrderSubmitter,
  InvestorSubmitDirectBuyOrderRequest,
} from "james/market/DirectOrderSubmitter";
import {
  DirectOrderFeeCalculator,
  CalculateDirectOrderFeeResponse,
} from "james/remuneration/DirectOrderFeeCalculator";
import {
  DirectOrder,
  DirectOrderState,
  DirectOrderType,
} from "james/market/DirectOrder";
import { TouchedFields, ValidationResult } from "common/validation";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { TextNumField } from "components/FormFields";
import { Amount } from "components/Ledger/Amount";
import cx from "classnames";
import InfoIcon from "@mui/icons-material/Info";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { Balance } from "james/stellar/Account";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { formatTextNum } from "utilities/number";
import { NumFieldHlpTxt } from "validationHelperText";
import { useNotificationContext } from "context/Notification";
import { GroupNotificationChannel } from "james/group";
import {
  MarketDirectOrderViewModelChangedNotification,
  MarketDirectOrderViewModelChangedNotificationTypeName,
  MarketDirectOrderViewNotificationChannelName,
} from "james/views/marketDirectOrderView";
import { Notification } from "james/notification/Notification";
import { QuoteParameter } from "james/market/Mechanism";
import { Header } from "../Header";
import { InvestorDirectOrderCardProps } from "./InvestorDirectOrderCard";
import BigNumber from "bignumber.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import range from "lodash/range";
import { Model as StellarAccountViewModel } from "james/views/stellarAccountView";
import LogRocket from "logrocket";
import { AssetEvent } from "const/logRocket";
import { userTypingAPICallDebounceIntervalMS } from "common/debouncing";
import { JSONRPCCallAbortedError } from "utilities/network/jsonRPCRequest";
import { useAccountContext } from "context/Account/Account";
import { LedgerIDIdentifier } from "james/search/identifier";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import {
  TransactionDetails,
  TransactionStage,
  TransactionTypes,
} from "types/gtm";
import dayjs from "dayjs";
import { useGTMTriggersPusher } from "hooks/analytics/useGTMTriggersPusher";
const PREFIX = "BuyCard";

const classes = {
  sectionWithPadding: `${PREFIX}-sectionWithPadding`,
  sectionWithBottomBorder: `${PREFIX}-sectionWithBottomBorder`,
  sectionWithColumnsPoint5Gap: `${PREFIX}-sectionWithColumnsPoint5Gap`,
  titleMessage: `${PREFIX}-titleMessage`,
  textNumFieldCode: `${PREFIX}-textNumFieldCode`,
  balanceLayout: `${PREFIX}-balanceLayout`,
  balanceAvailableText: `${PREFIX}-balanceAvailableText`,
  errorHelperTextLayout: `${PREFIX}-errorHelperTextLayout`,
  estimatedValuesTitle: `${PREFIX}-estimatedValuesTitle`,
  estimatedValuesInfoIcon: `${PREFIX}-estimatedValuesInfoIcon`,
  estimatedValuesLayout: `${PREFIX}-estimatedValuesLayout`,
  tradeFeeLayout: `${PREFIX}-tradeFeeLayout`,
  tradeFeeWhyTheseFeesLink: `${PREFIX}-tradeFeeWhyTheseFeesLink`,
  orderBreakdownControlRow: `${PREFIX}-orderBreakdownControlRow`,
  orderBreakDownLayout: `${PREFIX}-orderBreakDownLayout`,
  ledgerAmountCode: `${PREFIX}-ledgerAmountCode`,
  ledgerAmountValue: `${PREFIX}-ledgerAmountValue`,
  secondaryText: `${PREFIX}-secondaryText`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Dialog)(({ theme }) => ({
  [`& .${classes.sectionWithPadding}`]: {
    width: "100%",
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 6),
    },
  },

  [`& .${classes.sectionWithBottomBorder}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.sectionWithColumnsPoint5Gap}`]: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
  },

  [`& .${classes.titleMessage}`]: {
    padding: theme.spacing(3, 0, 3, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5, 0, 3, 0),
    },
    maxWidth: 350,
    fontWeight: "bold",
  },

  [`& .${classes.textNumFieldCode}`]: {
    marginRight: 8,
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.balanceLayout}`]: {
    paddingLeft: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(3),
    },
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1),
    alignItems: "center",
  },

  [`& .${classes.balanceAvailableText}`]: {
    color: theme.palette.text.secondary,
  },

  [`& .${classes.errorHelperTextLayout}`]: {
    maxWidth: 300,
    paddingLeft: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(3),
    },
  },

  [`& .${classes.estimatedValuesTitle}`]: {
    padding: theme.spacing(3, 0),
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },

  [`& .${classes.estimatedValuesInfoIcon}`]: {
    color: theme.palette.secondary.light,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
  },

  [`& .${classes.estimatedValuesLayout}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.tradeFeeLayout}`]: {
    padding: theme.spacing(3, 0),
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },

  [`& .${classes.tradeFeeWhyTheseFeesLink}`]: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  [`& .${classes.orderBreakdownControlRow}`]: {
    padding: theme.spacing(3, 0),
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1),
    alignItems: "center",
  },

  [`& .${classes.orderBreakDownLayout}`]: {
    display: "grid",
    paddingBottom: theme.spacing(3),
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(5),
  },

  [`& .${classes.ledgerAmountCode}`]: {
    color: alpha(theme.palette.warning.main, 0.38),
    cursor: "pointer",
    "&:hover": {
      color: alpha(theme.palette.warning.main, 0.5),
    },
  },

  [`& .${classes.ledgerAmountValue}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.secondaryText}`]: {
    color: theme.palette.text.secondary,
  },
}));

const infoIconContent =
  "The price displayed is an estimation. " +
  "The final price is captured with confirmation, and might differ from the " +
  "indicative price. Tokens are therefore also estimated when ordering.";

function validateInvestorSubmitDirectBuyOrderRequest(
  request: InvestorSubmitDirectBuyOrderRequest,
  feeCalculationResult: CalculateDirectOrderFeeResponse,
  assetValuationTokenBalance: Balance,
  valuationTokenViewModel: LedgerTokenViewModel,
  assetIssuanceTokenViewModel: LedgerTokenViewModel,
  touchedFields: TouchedFields,
  ignoreTouchedFields: boolean,
  marketMechanismQuoteParameter: QuoteParameter,
  assetFractionalisationAllowed: boolean,
): ValidationResult {
  // prepare a validation result
  const validationResult: ValidationResult = {
    // assumed to be true -
    // any error must set to false regardless of touched field state
    valid: true,
    // field validations
    fieldValidations: {},
  };

  if (request.slippage.gt(100)) {
    validationResult.fieldValidations.slippage = "can't exceed 100%";
    validationResult.valid = false;
  }

  // if amount is not set
  if (request.amountIncl.value.isZero()) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.amountIncl) {
      // then an error message should be shown on it
      validationResult.fieldValidations.amountIncl =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  } else if (
    assetValuationTokenBalance.amount.value.lt(request.amountIncl.value)
  ) {
    // if valuation token balance is not sufficient
    // then validation has failed
    validationResult.valid = false;

    // then an error message should be shown on it REGARDLESS of touched
    // field state
    validationResult.fieldValidations.amountIncl = `Insufficient Balance: ${
      valuationTokenViewModel.token.code
    } ${formatTextNum(assetValuationTokenBalance.amount.value, {
      noDecimalPlaces: 2,
      addDecimalPadding: true,
    })}`;
  }

  if (
    // if resultant token amount is out of
    // the deal size bounds as set on the listing
    feeCalculationResult.tokens.gt(
      marketMechanismQuoteParameter.maximumDealSize.value,
    ) ||
    feeCalculationResult.tokens.lt(
      marketMechanismQuoteParameter.minimumDealSize.value,
    )
  ) {
    // then validation has failed
    validationResult.valid = false;

    // and if the amountIncl field has been touched
    if (ignoreTouchedFields || touchedFields.amountIncl) {
      // then an error message should be shown on the 'buttonTokens'
      validationResult.fieldValidations.buttonTokens = `Order size out of bounds. Min ${formatTextNum(
        marketMechanismQuoteParameter.minimumDealSize.value,
        {
          noDecimalPlaces: 7,
          addDecimalPadding: false,
        },
      )} Max ${formatTextNum(
        marketMechanismQuoteParameter.maximumDealSize.value,
        {
          noDecimalPlaces: 7,
          addDecimalPadding: false,
        },
      )} ${assetIssuanceTokenViewModel.token.code}`;
    }
  }

  // apply asset fractionalisation allowed check
  if (
    !(assetFractionalisationAllowed || feeCalculationResult.tokens.isInteger())
  ) {
    // if the check fails then validation has failed
    validationResult.valid = false;

    // and if the tokensField has been touched
    if (ignoreTouchedFields || touchedFields.tokens) {
      // then an error message should be shwon on the tokens text num field
      validationResult.fieldValidations.tokens = "Must be a whole number";
    }
  }

  return validationResult;
}

enum LastFieldChanged {
  Neither,
  RequestAmountIncl,
  EnteredTokens,
}

export function BuyCard(props: InvestorDirectOrderCardProps) {
  const isMounted = useIsMounted();

  const { authContext } = useApplicationContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();
  const { registerNotificationCallback } = useNotificationContext();
  const dialogContentRef = useRef<HTMLDivElement>(null);
  const [showOrderBreakdown, setShowOrderBreakdown] = useState(false);
  const { stellarAccountContext } = useAccountContext();
  const toggleShowOrderBreakDown = () => {
    // open order breakdown
    setShowOrderBreakdown(!showOrderBreakdown);

    // and scroll to the bottom in 500ms
    range(10, 500, 10).forEach((v) =>
      setTimeout(() => {
        if (dialogContentRef.current) {
          dialogContentRef.current.scrollTop =
            dialogContentRef.current.scrollHeight;
        }
      }, v),
    );
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { pushTransactionStart, pushTransactionAbandon } =
    useGTMTriggersPusher();

  // ---- request ----
  const [buyTokensAmount, setBuyTokensAmount] = useState(
    props.assetIssuanceTokenViewModel.token.newAmountOf("0"),
  );
  const [investorDirectBuyOrderRequest, setInvestorDirectBuyOrderRequest] =
    useState<InvestorSubmitDirectBuyOrderRequest>({
      context: authContext,
      initiatingPartyGroupID: props.potentialTradingAccounts[0]?.ownerID ?? "",
      buyToken: props.listing.token,
      amountIncl:
        props.marketMechanismQuoteParameter.quoteToken.newAmountOf("0"),
      estimatedPrice: props.indicativePrice.buyPrice,
      slippage: new BigNumber(10),
    });

  // ---- fee calculation (updated each time request amountIncl changes) ----
  const [lastFieldChanged, setLastFieldChanged] = useState(
    LastFieldChanged.Neither,
  );
  const [calculateDirectOrderFeeResponse, setCalculateDirectOrderFeeResponse] =
    useState<CalculateDirectOrderFeeResponse>({
      feeAmount: new BigNumber("0"),
      amountIncl: new BigNumber("0"),
      amountExcl: new BigNumber("0"),
      tokens: new BigNumber("0"),
    });
  const [feeCalculationInProgress, setFeeCalculationInProgress] =
    useState(false);
  const feeCalculationTimeoutRef = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );
  const [
    isCurrentCalculateDirectOrderFeeAPICall,
    initCalculateDirectOrderFeeAPICall,
  ] = useCurrentAPICall();
  useEffect(() => {
    if (!isMounted()) {
      return;
    }

    // fees cannot be calculated if neither field changed
    if (lastFieldChanged === LastFieldChanged.Neither) {
      return;
    }

    // only perform fee calculation if either a non-zero amountIncl of token amount is entered
    if (
      investorDirectBuyOrderRequest.amountIncl.value.isZero() &&
      buyTokensAmount.value.isZero()
    ) {
      setCalculateDirectOrderFeeResponse({
        feeAmount: new BigNumber("0"),
        amountIncl: new BigNumber("0"),
        amountExcl: new BigNumber("0"),
        tokens: new BigNumber("0"),
      });
      return;
    }

    // indicate that fee calculation is in progress
    setFeeCalculationInProgress(true);

    // initialise API call
    const { apiCallID, abortController } = initCalculateDirectOrderFeeAPICall();

    // set up new fee calculation to take place after debounce interval
    clearTimeout(feeCalculationTimeoutRef.current);
    feeCalculationTimeoutRef.current = setTimeout(async () => {
      try {
        const result = await DirectOrderFeeCalculator.CalculateDirectOrderFee(
          {
            context: authContext,
            directOrderType: DirectOrderType.Buy,
            price: props.indicativePrice.buyPrice.value,
            amountIncl:
              lastFieldChanged === LastFieldChanged.RequestAmountIncl
                ? investorDirectBuyOrderRequest.amountIncl.value
                : undefined,
            tokens:
              lastFieldChanged === LastFieldChanged.EnteredTokens
                ? buyTokensAmount.value
                : undefined,
          },
          { signal: abortController.signal },
        );
        if (isMounted() && isCurrentCalculateDirectOrderFeeAPICall(apiCallID)) {
          setLastFieldChanged(LastFieldChanged.Neither);
          setBuyTokensAmount(buyTokensAmount.setValue(result.tokens));
          setInvestorDirectBuyOrderRequest({
            ...investorDirectBuyOrderRequest,
            amountIncl: investorDirectBuyOrderRequest.amountIncl.setValue(
              result.amountIncl,
            ),
          });
          setCalculateDirectOrderFeeResponse(result);
        } else {
          return;
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        if (
          errorContextErrorTranslator.translateError(e).code ===
          JSONRPCCallAbortedError.ErrorCode
        ) {
          return;
        }
        console.error(
          `error calculating direct order fee: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setFeeCalculationInProgress(false);
    }, userTypingAPICallDebounceIntervalMS);
  }, [
    lastFieldChanged,
    isMounted,
    authContext,
    investorDirectBuyOrderRequest.amountIncl,
    buyTokensAmount,
    props.indicativePrice.buyPrice,
  ]);

  // ----- Request update and validation -----
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [requestValidationResult, setRequestValidationResult] =
    useState<ValidationResult>({
      valid: false,
      fieldValidations: {},
    });
  const validationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  type InvestorSubmitDirectBuyOrderRequestField =
    keyof InvestorSubmitDirectBuyOrderRequest;
  type InvestorSubmitDirectBuyOrderRequestValue<
    T extends InvestorSubmitDirectBuyOrderRequestField,
  > = InvestorSubmitDirectBuyOrderRequest[T];

  // request update handler
  const handleUpdateRequest =
    (field: string, fieldsAffected?: string[]) =>
    <T extends InvestorSubmitDirectBuyOrderRequestField>(
      newValue: InvestorSubmitDirectBuyOrderRequestValue<T>,
    ) => {
      // prepare updated request
      const updatedRequest = {
        ...investorDirectBuyOrderRequest,
        [field]: newValue,
      };

      // prepare updated touched fields
      const updatedTouchedFields = {
        ...touchedFields,
        tokens: true,
        [field]: true,
      };
      if (fieldsAffected) {
        fieldsAffected.forEach((f) => {
          updatedTouchedFields[f] = true;
        });
      }

      setTouchedFields(updatedTouchedFields);
      setInvestorDirectBuyOrderRequest(updatedRequest);
    };

  // update asset balance and signatory status each time selected account changes
  const [assetValuationTokenBalance, setAssetValuationTokenBalance] = useState(
    new Balance(),
  );
  const [userIsSignatoryOnTradingAcc, setUserIsSignatoryOnTradingAcc] =
    useState(false);
  useEffect(() => {
    (async () => {
      if (stellarAccountContext.loading) {
        return;
      }

      if (stellarAccountContext.error) {
        console.error(`initialization error: ${stellarAccountContext.error}`);
        enqueueSnackbar(`Initialization Error`, {
          variant: "error",
        });

        // close the dialog
        props.closeDialog();
      }

      const selectedAccount = props.potentialTradingAccounts.find(
        (acc) =>
          acc.ownerID === investorDirectBuyOrderRequest.initiatingPartyGroupID,
      );
      if (selectedAccount) {
        // get signatory status
        // determine if executing user is a signatory this account
        try {
          if (isMounted()) {
            setUserIsSignatoryOnTradingAcc(
              await stellarAccountContext.checkUserSignatoryOnAccount(
                LedgerIDIdentifier(selectedAccount.ledgerID),
              ),
            );
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error determining if user is signatory on account: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar("Error Determining Signatory Status", {
            variant: "error",
          });
        }

        // get relevant asset balance
        const newAssetValuationTokenBalance = selectedAccount.getTokenBalance(
          props.marketMechanismQuoteParameter.quoteToken,
        );
        if (newAssetValuationTokenBalance) {
          setAssetValuationTokenBalance(newAssetValuationTokenBalance);
        } else {
          setAssetValuationTokenBalance(
            new Balance({
              amount:
                props.marketMechanismQuoteParameter.quoteToken.newAmountOf("0"),
              limit:
                props.marketMechanismQuoteParameter.quoteToken.newAmountOf("0"),
              sellingLiabilities:
                props.marketListingViewModel.token.newAmountOf("0"),
              buyingLiabilities:
                props.marketListingViewModel.token.newAmountOf("0"),
            } as Balance),
          );
        }
      }
    })();
  }, [
    investorDirectBuyOrderRequest.initiatingPartyGroupID,
    stellarAccountContext.loading,
    stellarAccountContext.error,
  ]);

  // form validation each time any of the following change:
  // - request
  // - relevant asset balance
  useEffect(() => {
    // clear any pending validation
    clearTimeout(validationTimeoutRef.current);

    // defer validation to take place in 200ms
    setValidationInProgress(true);
    clearTimeout(validationTimeoutRef.current);
    validationTimeoutRef.current = setTimeout(() => {
      setRequestValidationResult(
        validateInvestorSubmitDirectBuyOrderRequest(
          investorDirectBuyOrderRequest,
          calculateDirectOrderFeeResponse,
          assetValuationTokenBalance,
          props.assetValuationTokenViewModel,
          props.assetIssuanceTokenViewModel,
          touchedFields,
          false,
          props.marketMechanismQuoteParameter,
          props.marketListingViewModel.assetFractionalisationAllowed,
        ),
      );
      setValidationInProgress(false);
    }, 200);
  }, [
    investorDirectBuyOrderRequest,
    props.listing,
    calculateDirectOrderFeeResponse,
    assetValuationTokenBalance,
    props.assetValuationTokenViewModel,
    props.assetIssuanceTokenViewModel,
    touchedFields,
  ]);

  // order submission
  const [orderSubmissionInProgress, setOrderSubmissionInProgress] =
    useState(false);
  const handleSubmitOrder = async () => {
    setOrderSubmissionInProgress(true);
    let directOrder: DirectOrder;
    try {
      // submit direct order
      directOrder = (
        await DirectOrderSubmitter.InvestorSubmitDirectBuyOrder(
          investorDirectBuyOrderRequest,
        )
      ).directOrder;

      // notify that submission is in progress
      enqueueSnackbar(`Order #${directOrder.number} is being submitted`, {
        variant: "info",
      });

      LogRocket.track(AssetEvent.placeDirectOrder, {
        assetName: props.marketListingViewModel.assetName,
        assetShortName: props.marketListingViewModel.assetShortName,
        assetType: props.marketListingViewModel.assetType,
        revenue: calculateDirectOrderFeeResponse.feeAmount.toNumber(),
      });

      // close the dialog
      props.closeDialog();
    } catch (e) {
      console.error(`submitting order`, e);
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(`Error Submitting Order: ${err.message}`, {
        variant: "warning",
      });
      setOrderSubmissionInProgress(false);
      return;
    }

    try {
      // register callback to fire once the order has reached awaiting confirmation
      const deregister = await registerNotificationCallback(
        new GroupNotificationChannel({
          groupID: directOrder.initiatingPartyGroupID,
          name: MarketDirectOrderViewNotificationChannelName,
          private: true,
        }),
        [MarketDirectOrderViewModelChangedNotificationTypeName],
        (n: Notification) => {
          if (
            n instanceof MarketDirectOrderViewModelChangedNotification &&
            n.model.directOrderID === directOrder.id
          ) {
            // notify based on state
            switch (n.model.state) {
              case DirectOrderState.Pending:
                // Do nothing during transient states
                // Return so that deregister is not called.
                return;

              case DirectOrderState.AwaitingConfirmation:
                // trigger the transaction started analytics event
                pushTransactionStart({
                  // use the Direct Order Number to uniquely identify the transaction
                  transaction_id: n.model.number,
                  transaction_type: TransactionTypes.directOrder,
                  // use the asset short name as transaction_asset_name
                  transaction_asset_name: n.model.assetName,
                  // use the combination asset code:issuer:network to create an asset id
                  transaction_asset_id: `${n.model.tokens.token.code}:${n.model.tokens.token.issuer}:${n.model.tokens.token.network}`,
                  // use the price on model to set asset sell price
                  transaction_asset_sell_price:
                    n.model.orderType === DirectOrderType.Sell
                      ? n.model.price.value.toString()
                      : "0",
                  // use the price on model to set asset buy price
                  transaction_asset_buy_price:
                    n.model.orderType === DirectOrderType.Buy
                      ? n.model.price.value.toString()
                      : "0",
                  // set the type of transaction in the case spot trades
                  transaction_asset_type:
                    props.marketListingViewModel.assetType,
                  // set transaction asset issuer
                  transaction_asset_issuer:
                    props.assetIssuanceTokenViewModel.issuer,
                  // use the instrument risk profile to set the asset risk rating
                  transaction_asset_risk_rating:
                    props.marketListingViewModel.instrumentRiskProfile,
                  // this is the stage of the transaction
                  transaction_stage: TransactionStage.start,
                  // this is the date in which the transaction is initiated
                  transaction_date: dayjs().format(),
                  // set the transaction slippage
                  transaction_slippage:
                    investorDirectBuyOrderRequest.slippage.toString(),
                  // set transaction trade fee
                  transaction_trade_fee: n.model.feeAmount.value.toString(),
                  // set the transaction currency
                  transaction_currency: n.model.feeAmount.token.code,
                  // use the quote amount on the spot as the investment amount
                  transaction_investment_amount:
                    n.model.amountIncl.value.toString(),
                  // use asset listing instrument risk profile as investor profile
                  transaction_asset_investor_profile:
                    props.marketListingViewModel.instrumentRiskProfile.toString(),
                } as TransactionDetails);

                enqueueSnackbar(
                  `Order #${n.model.number} is awaiting confirmation`,
                  { variant: "success" },
                );
                break;

              case DirectOrderState.Failed:
                // trigger transaction failed analytics event
                pushTransactionAbandon({
                  // use the Direct Order Number to uniquely identify the transaction
                  transaction_id: n.model.number,
                  transaction_type: TransactionTypes.directOrder,
                  // use the asset short name as transaction_asset_name
                  transaction_asset_name: n.model.assetName,
                  // use the combination asset code:issuer:network to create an asset id
                  transaction_asset_id: `${n.model.tokens.token.code}:${n.model.tokens.token.issuer}:${n.model.tokens.token.network}`,
                  // use the price on model to set asset sell price
                  transaction_asset_sell_price:
                    n.model.orderType === DirectOrderType.Sell
                      ? n.model.price.value.toString()
                      : "0",
                  // use the price on model to set asset buy price
                  transaction_asset_buy_price:
                    n.model.orderType === DirectOrderType.Buy
                      ? n.model.price.value.toString()
                      : "0",
                  // set the type of transaction in the case spot trades
                  transaction_asset_type:
                    props.marketListingViewModel.assetType,
                  // set transaction asset issuer
                  transaction_asset_issuer:
                    props.assetIssuanceTokenViewModel.issuer,
                  // use the instrument risk profile to set the asset risk rating
                  transaction_asset_risk_rating:
                    props.marketListingViewModel.instrumentRiskProfile,
                  transaction_stage: TransactionStage.abandon,
                  // this is the date in which the transaction is initiated
                  transaction_date: dayjs().format(),
                  // set the transaction slippage
                  transaction_slippage:
                    investorDirectBuyOrderRequest.slippage.toString(),
                  // set transaction trade fee
                  transaction_trade_fee: n.model.feeAmount.value.toString(),
                  // set the transaction currency
                  transaction_currency: n.model.feeAmount.token.code,
                  // use the quote amount on the spot as the investment amount
                  transaction_investment_amount:
                    n.model.amountIncl.value.toString(),
                  // use asset listing instrument risk profile as investor profile
                  transaction_asset_investor_profile:
                    props.marketListingViewModel.instrumentRiskProfile.toString(),
                } as TransactionDetails);

                enqueueSnackbar(`Order #${n.model.number} has failed`, {
                  variant: "error",
                });
                break;

              case DirectOrderState.UnderInvestigation:
                enqueueSnackbar(
                  `Something has gone wrong with Order #${n.model.number} - it's status is being investigated`,
                  { variant: "warning" },
                );
                break;
            }
            deregister();
          }
        },
      );
    } catch (e) {
      console.error("error registring for order transaction notifications", e);
      enqueueSnackbar(
        "Warning! Unable to Register for Notifications on Order - Please Check the Orders Table and Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  };

  const valuesCalculating = feeCalculationInProgress;

  const placeOrderButton = (
    <Tooltip
      placement={isMobile ? "top" : "bottom"}
      arrow
      title={(() => {
        switch (true) {
          case !requestValidationResult.valid:
            return requestValidationResult.fieldValidations.buttonTokens
              ? requestValidationResult.fieldValidations.buttonTokens
              : "Please ensure that all fields have been completed correctly";
          case valuesCalculating:
            return "Calculation in Progress";
          case validationInProgress:
            return "Validation in Progress";
          case !userIsSignatoryOnTradingAcc:
            return "You are not a Signatory on the Trading Account";
        }
        return "";
      })()}
    >
      <span>
        <Button
          data-link-info={JSON.stringify({
            content_interaction_id: "transact-cta",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "place order",
            content_interaction_type: InteractionType.Button,
            content_interaction_driver: InteractionDriver.TransactionComplete,
          } as DataLinkInfoType)}
          sx={{
            height: {
              xs: "48px",
              sm: "33px",
            },
          }}
          fullWidth
          id="marketDirectOrderInvestorBuyCard-submitOrder-button"
          color="primary"
          variant="contained"
          children="place order"
          disabled={
            valuesCalculating ||
            validationInProgress ||
            !requestValidationResult.valid ||
            orderSubmissionInProgress ||
            !userIsSignatoryOnTradingAcc
          }
          onClick={handleSubmitOrder}
        />
      </span>
    </Tooltip>
  );

  return (
    <Root open fullScreen={isMobile}>
      <Header
        {...{
          disableClose: orderSubmissionInProgress,
          showLoading: orderSubmissionInProgress,
          ...props,
        }}
      />
      <DialogContent
        ref={dialogContentRef}
        sx={{
          backgroundColor: theme.palette.custom.midnight,
          width: { sm: 420 },
          display: "flex",
          flexDirection: "column",
          alignItems: { sm: "center" },
          padding: "unset !important",
        }}
        className={isMobile ? undefined : "meshScroll"}
      >
        {props.potentialTradingAccounts.length > 1 && (
          <Box
            sx={{
              width: "100%",
              margin: theme.spacing(1, 0),
              padding: {
                sm: theme.spacing(0, 6),
                xs: theme.spacing(0, 3),
              },
            }}
          >
            <Typography
              sx={{
                color: "text.disabled",
                marginBottom: theme.spacing(1),
              }}
              variant={"body2"}
            >
              Choose which account you would like to trade with:
            </Typography>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option === value}
              id="marketPlaceDirectOrderInvestorBuyCard-tradingAccount-autocomplete"
              disabled={orderSubmissionInProgress}
              fullWidth
              value={props.potentialTradingAccounts.find(
                (psa) =>
                  psa.ownerID ===
                  investorDirectBuyOrderRequest.initiatingPartyGroupID,
              )}
              disableClearable
              onChange={(
                e,
                selectedSourceAccountViewModel: StellarAccountViewModel | null,
              ) => {
                if (selectedSourceAccountViewModel) {
                  handleUpdateRequest("initiatingPartyGroupID")(
                    selectedSourceAccountViewModel.ownerID,
                  );
                }
              }}
              options={props.potentialTradingAccounts}
              getOptionLabel={(option: StellarAccountViewModel) =>
                `${option.accountOwnerGroupName} ${option.category}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  id="marketPlaceDirectOrderInvestorBuyCard-tradingAccount-autocompleteTextField"
                  label={"Account"}
                />
              )}
            />
          </Box>
        )}
        <Box
          className={classes.sectionWithPadding}
          sx={{
            backgroundColor: theme.palette.custom.cardInner,
          }}
        >
          <Typography
            className={classes.titleMessage}
            variant="h4"
            children="How much of the instrument would you like to buy?"
          />
          <TextNumField
            id="marketPlaceDirectOrderInvestorBuyCard-amountIncl-textNumField"
            disallowNegative
            label="Investment Amount"
            noDecimalPlaces={2}
            fullWidth
            disabled={orderSubmissionInProgress}
            value={investorDirectBuyOrderRequest.amountIncl.value}
            onChange={(e) => {
              setLastFieldChanged(LastFieldChanged.RequestAmountIncl);
              handleUpdateRequest("amountIncl")(
                investorDirectBuyOrderRequest.amountIncl.setValue(
                  e.target.value,
                ),
              );
            }}
            InputProps={{
              startAdornment: (
                <Tooltip
                  title={`Issued by ${props.assetValuationTokenViewModel.issuer}`}
                  placement="top"
                >
                  <Typography
                    id={
                      "marketDirectOrderInvestorBuyCard-amountTextNumField-unitsStartAdornment"
                    }
                    variant="body1"
                    className={classes.textNumFieldCode}
                    children={props.assetValuationTokenViewModel.token.code}
                  />
                </Tooltip>
              ),
              endAdornment: (
                <InputAdornment position={"end"} sx={{ alignItems: "center" }}>
                  <Button
                    id={
                      "marketDirectOrderInvestorDirectOrder-buyCard-paySetMax-button"
                    }
                    sx={{
                      width: "39px !important",
                      minWidth: 0,
                      padding: theme.spacing(0, 0.5),
                      marginBottom: "-3px",
                    }}
                    variant={"text"}
                    color={"secondary"}
                    children={"max"}
                    onClick={() => {
                      if (assetValuationTokenBalance.amount.value.isZero()) {
                        return;
                      }
                      setLastFieldChanged(LastFieldChanged.RequestAmountIncl);
                      handleUpdateRequest("amountIncl")(
                        investorDirectBuyOrderRequest.amountIncl.setValue(
                          assetValuationTokenBalance.availableBalance().value,
                        ),
                      );
                    }}
                  />
                </InputAdornment>
              ),
            }}
            error={
              !!requestValidationResult.fieldValidations.amountIncl &&
              !feeCalculationInProgress
            }
          />
          {!requestValidationResult.fieldValidations.amountIncl ? (
            // if there are no field validations to show then available balance
            <div className={classes.balanceLayout}>
              <Typography
                id="buyCard-availableInvestmentAmount-typography"
                className={classes.balanceAvailableText}
                variant="caption"
                children="Available:"
              />
              <Amount
                id="marketDirectOrderInvestorBuyCard-availableBalance-amountField"
                codeTypographyProps={{
                  variant: "caption",
                  color: "textSecondary",
                }}
                valueTypographyProps={{
                  variant: "caption",
                  color: "textSecondary",
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={assetValuationTokenBalance.availableBalance()}
              />
            </div>
          ) : (
            // otherwise show error text
            <Typography
              id="buyCard-investmentAmountError-typography"
              className={classes.errorHelperTextLayout}
              variant="body2"
              color="error"
              children={requestValidationResult.fieldValidations.amountIncl}
            />
          )}
          {!props.marketListingViewModel.assetFractionalisationAllowed && (
            <TextNumField
              id={"marketPlaceDirectOrderInvestorBuyCard-tokens-incrementField"}
              noDecimalPlaces={7}
              label="Tokens Bought"
              disallowNegative
              fullWidth
              sx={{
                marginTop: theme.spacing(-1),
                marginBottom: theme.spacing(3),
              }}
              value={buyTokensAmount.value}
              onChange={(e) => {
                setTouchedFields((prevValue) => ({
                  ...prevValue,
                  tokens: true,
                  amountIncl: true,
                }));
                setLastFieldChanged(LastFieldChanged.EnteredTokens);
                setBuyTokensAmount(buyTokensAmount.setValue(e.target.value));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={`Issued by ${props.assetIssuanceTokenViewModel.issuer}`}
                      placement="top"
                    >
                      <Typography
                        variant="body1"
                        className={classes.textNumFieldCode}
                        children={buyTokensAmount.token.code}
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              error={!!requestValidationResult.fieldValidations.tokens}
              helperText={requestValidationResult.fieldValidations.tokens}
            />
          )}
        </Box>
        <div
          className={cx(
            classes.sectionWithPadding,
            classes.sectionWithBottomBorder,
          )}
        >
          <div className={classes.estimatedValuesTitle}>
            <Typography variant="h6" children="Estimated Values:" />
            <Tooltip
              title={infoIconContent}
              arrow
              placement={isMobile ? "top" : "right"}
            >
              <InfoIcon className={classes.estimatedValuesInfoIcon} />
            </Tooltip>
            {valuesCalculating && <CircularProgress size={20} />}
          </div>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                mr: theme.spacing(2),
                mb: theme.spacing(3),
              }}
            >
              <Typography
                color="textSecondary"
                variant="subtitle1"
                children="Tokens"
              />
              <Amount
                reverse
                id="marketDirectOrderInvestorBuyCard-tokens-amountField"
                codeTypographyProps={{
                  variant: "h6",
                  className: cx(classes.ledgerAmountCode, {
                    [classes.secondaryText]: valuesCalculating,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h6",
                  className: cx(classes.ledgerAmountValue, {
                    [classes.secondaryText]: valuesCalculating,
                  }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 7,
                  addDecimalPadding: false,
                }}
                amount={props.listing.token.newAmountOf(
                  calculateDirectOrderFeeResponse.tokens,
                )}
              />
            </Box>
            <Box
              sx={{
                minWidth: "124px",
                mb: theme.spacing(3),
              }}
            >
              <Typography
                color="textSecondary"
                variant="subtitle1"
                children="Price per Token"
              />
              <Amount
                id="marketDirectOrderInvestorBuyCard-price-amountField"
                codeTypographyProps={{
                  variant: "h6",
                  className: cx(classes.ledgerAmountCode, {
                    [classes.secondaryText]: valuesCalculating,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h6",
                  className: cx(classes.ledgerAmountValue, {
                    [classes.secondaryText]: valuesCalculating,
                  }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={investorDirectBuyOrderRequest.estimatedPrice}
              />
            </Box>
          </Box>

          <Box
            sx={{
              pb: theme.spacing(3),
              display: "flex",
              gap: theme.spacing(2),
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="caption"
              className={classes.secondaryText}
              children="Trade Fee:"
            />
            <Amount
              id="marketDirectOrderInvestorBuyCard-tradeFee-amountField"
              codeTypographyProps={{
                variant: "caption",
                color: "textSecondary",
                className: cx({ [classes.secondaryText]: valuesCalculating }),
              }}
              valueTypographyProps={{
                variant: "caption",
                color: "textSecondary",
                className: cx({ [classes.secondaryText]: valuesCalculating }),
              }}
              formatTextNumOpts={{
                noDecimalPlaces: 2,
                addDecimalPadding: true,
              }}
              amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                calculateDirectOrderFeeResponse.feeAmount,
              )}
            />
            <Typography
              id="marketDirectOrderInvestorBuyCard-whyTheseFees-link"
              data-link-info={JSON.stringify({
                content_interaction_id: "info-link",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "Why these fees",
                content_interaction_type: InteractionType.Link,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
              variant="caption"
              color="secondary"
              className={classes.tradeFeeWhyTheseFeesLink}
              onClick={() => window.open("https://mesh.trade/fees", "_blank")}
              children="Why these fees?"
            />
          </Box>
        </div>

        <div className={cx(classes.sectionWithPadding)}>
          <div
            data-link-info={JSON.stringify({
              content_interaction_id: "info-link",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "Order Preview & Slippage",
              content_interaction_type: InteractionType.Link,
              content_interaction_driver: InteractionDriver.MoreInfo,
            } as DataLinkInfoType)}
            className={classes.orderBreakdownControlRow}
          >
            <IconButton
              id="marketDirectOrderInvestorBuyCard-orderBreakDownToggle-iconButton"
              data-link-info={JSON.stringify({
                content_interaction_id: "info-link",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "Order Preview & Slippage",
                content_interaction_type: InteractionType.Icon,
                content_interaction_driver: InteractionDriver.MoreInfo,
              } as DataLinkInfoType)}
              size="small"
              onClick={toggleShowOrderBreakDown}
            >
              {showOrderBreakdown ? (
                <ExpandLessIcon
                  data-link-info={JSON.stringify({
                    content_interaction_id: "info-link",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_text: "Order Preview & Slippage",
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_driver: InteractionDriver.MoreInfo,
                  } as DataLinkInfoType)}
                  color="primary"
                />
              ) : (
                <ExpandMoreIcon
                  data-link-info={JSON.stringify({
                    content_interaction_id: "info-link",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_text: "Order Preview & Slippage",
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_driver: InteractionDriver.MoreInfo,
                  } as DataLinkInfoType)}
                  color="primary"
                />
              )}
            </IconButton>
            <Typography variant="h6" children="Order Preview & Slippage" />
          </div>
          <Collapse in={showOrderBreakdown}>
            <div className={classes.orderBreakDownLayout}>
              <Typography
                className={classes.secondaryText}
                variant="caption"
                children="Pay Stablecoin:"
              />
              <Amount
                id="marketDirectOrderInvestorBuyCard-orderBreakDown-payStablecoin-amountField"
                codeTypographyProps={{
                  variant: "caption",
                  className: cx({ [classes.secondaryText]: valuesCalculating }),
                }}
                valueTypographyProps={{
                  variant: "caption",
                  className: cx({ [classes.secondaryText]: valuesCalculating }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                  calculateDirectOrderFeeResponse.amountIncl,
                )}
              />
              <Typography
                children="Trade Fee:"
                className={classes.secondaryText}
                variant="caption"
              />
              <Amount
                id="marketDirectOrderInvestorBuyCard-orderBreakDown-tradeFee-amountField"
                codeTypographyProps={{
                  variant: "caption",
                  className: cx({ [classes.secondaryText]: valuesCalculating }),
                }}
                valueTypographyProps={{
                  variant: "caption",
                  className: cx({ [classes.secondaryText]: valuesCalculating }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                  calculateDirectOrderFeeResponse.feeAmount,
                )}
              />

              <Typography
                className={classes.secondaryText}
                variant="caption"
                children="Receive Tokens:"
              />
              <div className={classes.sectionWithColumnsPoint5Gap}>
                <Amount
                  id="marketDirectOrderInvestorBuyCard-orderBreakDown-receiveTokens-amountField"
                  reverse
                  codeTypographyProps={{
                    variant: "caption",
                    className: cx({
                      [classes.secondaryText]: valuesCalculating,
                    }),
                  }}
                  valueTypographyProps={{
                    variant: "caption",
                    className: cx({
                      [classes.secondaryText]: valuesCalculating,
                    }),
                  }}
                  formatTextNumOpts={{
                    noDecimalPlaces: 7,
                    addDecimalPadding: false,
                  }}
                  amount={props.listing.token.newAmountOf(
                    calculateDirectOrderFeeResponse.tokens,
                  )}
                />
                <Typography
                  variant="caption"
                  className={cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  })}
                  children="(Est.)"
                />
              </div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pt: 2,
                }}
              >
                <TextNumField
                  id="investorBuyCard-Slippage-TextNumField"
                  disallowNegative
                  label="Slippage"
                  onChange={(e) =>
                    handleUpdateRequest("slippage")(
                      new BigNumber(e.target.value),
                    )
                  }
                  value={investorDirectBuyOrderRequest.slippage}
                  error={!!requestValidationResult.fieldValidations.slippage}
                  helperText={requestValidationResult.fieldValidations.slippage}
                  FormHelperTextProps={{
                    sx: {
                      mx: 0,
                    },
                  }}
                  InputProps={{
                    margin: "dense",
                    endAdornment: (
                      <InputAdornment position="end" children="%" />
                    ),
                    sx: {
                      width: "120px",
                    },
                  }}
                />
                <Tooltip
                  arrow
                  title={`The amount of unfavourable price movement you are 
                          willing to accept, for movement in price between 
                          quoted price and execution price`}
                >
                  <InfoIcon
                    width="24px"
                    height="24px"
                    sx={{
                      ml: 1,
                      mt: 0.5,
                      color: theme.palette.secondary.light,
                    }}
                  />
                </Tooltip>
              </Box>
            </div>
          </Collapse>
          {!isMobile && (
            <Box
              sx={{
                width: "100%",
                paddingBottom: 5,
              }}
              data-link-info={JSON.stringify({
                content_interaction_id: "transact-cta",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "place order",
                content_interaction_type: InteractionType.Button,
                content_interaction_driver: InteractionDriver.DriveTransaction,
              } as DataLinkInfoType)}
            >
              {placeOrderButton}
            </Box>
          )}
        </div>
        {isMobile && (
          <Box
            sx={{
              marginTop: "auto",
              position: "sticky",
              bottom: 0,
              backgroundColor: theme.palette.custom.midnight,
              padding: theme.spacing(3, 3, 4, 3),
              height: "104px",
              width: "100%",
              boxShadow: 24,
            }}
          >
            {placeOrderButton}
          </Box>
        )}
      </DialogContent>
    </Root>
  );
}
