import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";

export type SynchroniseCouponPaymentsForBondRequest = {
  context: Context;
  bondID: string;
};

export type SynchroniseCouponPaymentsForBondResponse = Record<string, never>;

export const CouponPaymentSynchroniserServiceProviderName =
  "financial-CouponPaymentSynchroniser";

export const CouponPaymentSynchroniser = {
  async SynchroniseCouponPaymentsForBond(
    request: SynchroniseCouponPaymentsForBondRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<SynchroniseCouponPaymentsForBondResponse> {
    return await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${CouponPaymentSynchroniserServiceProviderName}.SynchroniseCouponPaymentsForBond`,
        request,
      },
      opts,
    );
  },
};
