export enum DebtSeniorityLevel {
  FirstLienLoanSeniorSecured = "First-Lien Loan - Senior Secured",
  SecondLienLoanSecured = "Second-Lien Loan - Secured",
  SeniorUnsecured = "Senior Unsecured",
  SeniorSubordinated = "Senior Subordinated",
  Subordinated = "Subordinated",
  JuniorSubordinated = "Junior Subordinated",
}

export const allDebtSeniorityLevels: DebtSeniorityLevel[] = [
  DebtSeniorityLevel.FirstLienLoanSeniorSecured,
  DebtSeniorityLevel.SecondLienLoanSecured,
  DebtSeniorityLevel.SeniorUnsecured,
  DebtSeniorityLevel.SeniorSubordinated,
  DebtSeniorityLevel.Subordinated,
  DebtSeniorityLevel.JuniorSubordinated,
];
