/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: financial/rateResetCorporateActionReaderUNSCOPED_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var financial_rateResetCorporateAction_pb = require('../financial/rateResetCorporateAction_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var num_decimal_pb = require('../num/decimal_pb.js')

var financial_corporateActionState_pb = require('../financial/corporateActionState_pb.js')

var financial_rateResetCorporateActionReader_meshproto_pb = require('../financial/rateResetCorporateActionReader_meshproto_pb.js')
const proto = {};
proto.financial = require('./rateResetCorporateActionReaderUNSCOPED_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.RateResetCorporateActionReaderUNSCOPEDClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.RateResetCorporateActionReaderUNSCOPEDPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadOneRateResetCorporateActionRequest,
 *   !proto.financial.ReadOneRateResetCorporateActionResponse>}
 */
const methodDescriptor_RateResetCorporateActionReaderUNSCOPED_ReadOneRateResetCorporateActionUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.RateResetCorporateActionReaderUNSCOPED/ReadOneRateResetCorporateActionUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_rateResetCorporateActionReader_meshproto_pb.ReadOneRateResetCorporateActionRequest,
  financial_rateResetCorporateActionReader_meshproto_pb.ReadOneRateResetCorporateActionResponse,
  /**
   * @param {!proto.financial.ReadOneRateResetCorporateActionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_rateResetCorporateActionReader_meshproto_pb.ReadOneRateResetCorporateActionResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadOneRateResetCorporateActionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadOneRateResetCorporateActionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadOneRateResetCorporateActionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateResetCorporateActionReaderUNSCOPEDClient.prototype.readOneRateResetCorporateActionUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateResetCorporateActionReaderUNSCOPED/ReadOneRateResetCorporateActionUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateResetCorporateActionReaderUNSCOPED_ReadOneRateResetCorporateActionUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadOneRateResetCorporateActionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadOneRateResetCorporateActionResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateResetCorporateActionReaderUNSCOPEDPromiseClient.prototype.readOneRateResetCorporateActionUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateResetCorporateActionReaderUNSCOPED/ReadOneRateResetCorporateActionUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateResetCorporateActionReaderUNSCOPED_ReadOneRateResetCorporateActionUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadManyRateResetCorporateActionRequest,
 *   !proto.financial.ReadManyRateResetCorporateActionResponse>}
 */
const methodDescriptor_RateResetCorporateActionReaderUNSCOPED_ReadManyRateResetCorporateActionUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.RateResetCorporateActionReaderUNSCOPED/ReadManyRateResetCorporateActionUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_rateResetCorporateActionReader_meshproto_pb.ReadManyRateResetCorporateActionRequest,
  financial_rateResetCorporateActionReader_meshproto_pb.ReadManyRateResetCorporateActionResponse,
  /**
   * @param {!proto.financial.ReadManyRateResetCorporateActionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_rateResetCorporateActionReader_meshproto_pb.ReadManyRateResetCorporateActionResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadManyRateResetCorporateActionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadManyRateResetCorporateActionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadManyRateResetCorporateActionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateResetCorporateActionReaderUNSCOPEDClient.prototype.readManyRateResetCorporateActionUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateResetCorporateActionReaderUNSCOPED/ReadManyRateResetCorporateActionUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateResetCorporateActionReaderUNSCOPED_ReadManyRateResetCorporateActionUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadManyRateResetCorporateActionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadManyRateResetCorporateActionResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateResetCorporateActionReaderUNSCOPEDPromiseClient.prototype.readManyRateResetCorporateActionUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateResetCorporateActionReaderUNSCOPED/ReadManyRateResetCorporateActionUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateResetCorporateActionReaderUNSCOPED_ReadManyRateResetCorporateActionUNSCOPED);
};


module.exports = proto.financial;

