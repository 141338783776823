import { Divider, Typography, Button, Box, Tooltip } from "@mui/material";
import BigNumber from "bignumber.js";
import { DateField, TextNumField } from "components/FormFields";
import dayjs, { Dayjs } from "dayjs";
import { SubscriptionOrderBook } from "james/market/SubscriptionOrderBook";
import { useEffect, useState } from "react";
import {
  formDataUpdaterSpecs,
  formDataValidationFunc,
} from "./useValidatedForm";
import { useValidatedForm } from "hooks/useForm";
import { SubscriptionOrderBookStateController } from "james/market/SubscriptionOrderBookStateController";
import { useApplicationContext } from "context/Application/Application";
import { enqueueSnackbar } from "notistack";
import { DigitalFixedRateBond, DigitalFloatingRateBond } from "james/financial";
import { Listing } from "james/market";
import { ListingState } from "james/market/Listing";
import { Amount } from "james/ledger";
import { useErrorContext } from "context/Error";

export type SubscriptionOrderBookFormProps = {
  asset: DigitalFixedRateBond | DigitalFloatingRateBond;
  subscriptionOrderBook: SubscriptionOrderBook;
  listing: Listing;
};
export const SubscriptionOrderBookForm = (
  props: SubscriptionOrderBookFormProps,
) => {
  const { authContext } = useApplicationContext();
  const { errorContextDefaultWarningFeedback } = useErrorContext();
  const [isActiveSubscriptionOrderBook, setIsActiveSubscriptionOrderBook] =
    useState(false);

  const isActiveListing = props.listing.state === ListingState.Active;
  const initialSubscriptionOrderBook = new SubscriptionOrderBook(
    props.subscriptionOrderBook,
  );

  initialSubscriptionOrderBook.openDate = dayjs()
    .add(1, "day")
    .startOf("day")
    .toString();
  initialSubscriptionOrderBook.closeDate = dayjs()
    .add(2, "day")
    .startOf("day")
    .toString();
  initialSubscriptionOrderBook.settlementDate = dayjs(
    props.asset.issueDate,
  ).toString();
  initialSubscriptionOrderBook.unitPrice = new Amount(props.asset.nominal);
  initialSubscriptionOrderBook.subscriptionAmount = new Amount(
    props.asset.totalNominal,
  );
  initialSubscriptionOrderBook.overSubscriptionAmount = new Amount({
    value: props.asset.totalNominal.value.plus(1),
    token: props.asset.totalNominal.token,
  });

  const [
    formData,
    formDataValidationResult,
    formDataUpdate,
    formDataValidationInProgress,
  ] = useValidatedForm(
    formDataValidationFunc,
    async () => {
      return {
        asset: props.asset,
        subscriptionOrderBook: new SubscriptionOrderBook(
          initialSubscriptionOrderBook,
        ),
      };
    },
    formDataUpdaterSpecs,
    {
      asset: props.asset,
      subscriptionOrderBook: new SubscriptionOrderBook(
        initialSubscriptionOrderBook,
      ),
    },
    new Set<string>(Object.keys(initialSubscriptionOrderBook)),
  );

  useEffect(() => {
    formDataUpdate.subscriptionOrderBook(initialSubscriptionOrderBook);
  }, [props.subscriptionOrderBook, props.listing]);

  const handleCreateSubscriptionOrderBook: () => Promise<SubscriptionOrderBook> =
    async () => {
      try {
        const newSubscriptionOrderBookResponse = (
          await SubscriptionOrderBookStateController.NewSubscriptionOrderBook({
            context: authContext,
            token: props.asset.assetToken(),
            UnitPrice: formData.subscriptionOrderBook.unitPrice,
            OpenDate: dayjs(formData.subscriptionOrderBook.openDate),
            CloseDate: dayjs(formData.subscriptionOrderBook.closeDate),
            SettlementDate: dayjs(
              formData.subscriptionOrderBook.settlementDate,
            ),
            SubscriptionAmount:
              formData.subscriptionOrderBook.subscriptionAmount,
            OverSubscriptionAmount:
              formData.subscriptionOrderBook.overSubscriptionAmount,
          })
        ).SubscriptionOrderBook;

        enqueueSnackbar("Subscription Order Book Created", {
          variant: "success",
        });
        setIsActiveSubscriptionOrderBook(true);

        return newSubscriptionOrderBookResponse;
      } catch (error) {
        errorContextDefaultWarningFeedback(
          error,
          "Could Not Create Subscription Order Book",
        );
        setIsActiveSubscriptionOrderBook(false);
        return new SubscriptionOrderBook();
      }
    };

  return (
    <>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          m: 1,
          p: 1,
        }}
      >
        <Typography
          variant="h4"
          sx={(theme) => ({
            fontSize: 20, // h4 font size
            fontWeight: theme.typography.fontWeightBold, // Bold font
          })}
        >
          Create Subscription Order Book
        </Typography>
        <Tooltip
          title={
            isActiveListing
              ? formDataValidationResult.valid
                ? "Create subscription order book for asset"
                : "Please ensure all fields are valid before creating subscription order book"
              : "Listing must be active to create subscription order book"
          }
        >
          <span>
            <Button
              color="primary"
              variant="contained"
              disabled={
                isActiveSubscriptionOrderBook ||
                !formDataValidationResult.valid ||
                formDataValidationInProgress ||
                !isActiveListing
              }
              onClick={handleCreateSubscriptionOrderBook}
            >
              Create Subscription Order Book
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Box
        sx={{
          width: "50%",
          display: "flex",
          justifyContent: "start",
          gap: 1,
          m: 2,
        }}
      >
        <DateField
          label="Open Date"
          id="subscriptionOrderBookForm-open-dateField"
          disabled={isActiveSubscriptionOrderBook || !isActiveListing}
          onChange={(newDate) => {
            formDataUpdate.openDate(newDate as Dayjs);
          }}
          minDate={dayjs().add(1, "day")}
          maxDate={dayjs(formData.subscriptionOrderBook.closeDate).add(
            -1,
            "day",
          )}
          value={dayjs(formData.subscriptionOrderBook.openDate)}
          slotProps={{
            textField: {
              sx: { width: "100%" },
              id: "digitalBondSubscriptionOrderBook-openDate-dateField",
              error: !!formDataValidationResult.fieldValidations.openDate,
              helperText: formDataValidationResult.fieldValidations.openDate,
            },
          }}
        />
        <DateField
          label="Close Date"
          id="digitalBondSubscriptionOrderBook-closeDate-dateField"
          disabled={isActiveSubscriptionOrderBook || !isActiveListing}
          onChange={(newDate) => {
            formDataUpdate.closeDate(newDate as Dayjs);
          }}
          minDate={dayjs(formData.subscriptionOrderBook.openDate).add(1, "day")}
          maxDate={dayjs(formData.subscriptionOrderBook.settlementDate).startOf(
            "day",
          )}
          value={dayjs(formData.subscriptionOrderBook.closeDate)}
          slotProps={{
            textField: {
              sx: { width: "100%" },
              id: "digitalBondSubscriptionOrderBook-closeDate-dateField",
              error: !!formDataValidationResult.fieldValidations.closeDate,
              helperText: formDataValidationResult.fieldValidations.closeDate,
            },
          }}
        />

        <DateField
          label="Settlement Date"
          id="digitalBondSubscriptionOrderBook-settlementDate-dateField"
          disabled
          onChange={(d) => {
            formDataUpdate.settlementDate(d as Dayjs);
          }}
          value={dayjs(formData.subscriptionOrderBook.settlementDate)}
          slotProps={{
            textField: {
              sx: { width: "100%" },
              id: "digitalBondSubscriptionOrderBook-settlementDate-dateField",
              error: !!formDataValidationResult.fieldValidations.settlementDate,
              helperText:
                formDataValidationResult.fieldValidations.settlementDate,
            },
          }}
        />
      </Box>
      <Box
        sx={{
          width: "50%",
          display: "flex",
          justifyContent: "start",
          gap: 1,
          m: 2,
        }}
      >
        <TextNumField
          disabled
          disallowNegative
          label="Unit Price"
          noDecimalPlaces={props.asset.fractionalisationAllowed ? 7 : 0}
          value={formData.subscriptionOrderBook.unitPrice.value}
          onChange={(e) => formDataUpdate.unitPrice(BigNumber(e.target.value))}
          error={!!formDataValidationResult.fieldValidations.unitPrice}
          helperText={formDataValidationResult.fieldValidations.unitPrice}
        />
        <TextNumField
          disabled
          disallowNegative
          label="Target Subscription Amount"
          value={formData.subscriptionOrderBook.subscriptionAmount.value}
          noDecimalPlaces={props.asset.fractionalisationAllowed ? 7 : 0}
          onChange={(e) =>
            formDataUpdate.subscriptionAmount(BigNumber(e.target.value))
          }
          error={!!formDataValidationResult.fieldValidations.subscriptionAmount}
          helperText={
            formDataValidationResult.fieldValidations.subscriptionAmount
              ? formDataValidationResult.fieldValidations.subscriptionAmount
              : "Default: Total Nominal Value of Asset"
          }
        />

        <TextNumField
          disabled={isActiveSubscriptionOrderBook || !isActiveListing}
          disallowNegative
          label="Over Subscription Amount"
          value={formData.subscriptionOrderBook.overSubscriptionAmount.value}
          noDecimalPlaces={7}
          onChange={(e) =>
            formDataUpdate.overSubscriptionAmount(BigNumber(e.target.value))
          }
          error={
            !!formDataValidationResult.fieldValidations.overSubscriptionAmount
          }
          helperText={
            formDataValidationResult.fieldValidations.overSubscriptionAmount
              ? formDataValidationResult.fieldValidations.overSubscriptionAmount
              : "Default: Maximum Deal Size x Asset Nominal Value"
          }
        />
      </Box>
    </>
  );
};
