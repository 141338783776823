import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { FaceOutlined as FaceIcon } from "@mui/icons-material";
import { SubscriptionOrderBookStateChip } from "../../../../../components/MarketSubscriptions/Chips";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingBar } from "../../../../../components/LoadingBar/LoadingBar";
import BigNumber from "bignumber.js";
import { Amount } from "../../../../../components/Ledger/Amount";
import { SubscriptionOrderBookState } from "../../../../../james/market/SubscriptionOrderBook";
import { useErrorContext } from "../../../../../context/Error";
import { useApplicationContext } from "../../../../../context/Application/Application";
import {
  MarketSubscriptionOrderBookSettlementViewReader,
  MarketSubscriptionOrderBookSettlementViewUnscopedReader,
} from "../../../../../james/views/marketSubscriptionOrderBookSettlementView";
import { Model as SubscriptionOrderBookModel } from "../../../../../james/views/marketSubscriptionOrderBookSettlementView/Model";
import { TextExactCriterion } from "../../../../../james/search/criterion";
import { SubscriptionUnderwritingTable } from "../../../../../components/MarketSubscriptions/SubscriptionUnderwritingTable";
import { SubscriptionOrderTable } from "../../../../../components/MarketSubscriptions/SubscriptionOrderTable";
import { TransactionState } from "../../../../../james/ledger";
import { TransactionSignatureController } from "../../../../../james/stellar/TransactionSignatureController";
import { MarketSubscriptionOrderBookViewModel } from "../../../../../james/views/marketSubscriptionOrderBookView";
import { AssetHoldersTable } from "../../../../../components/Ledger/AssetHoldersTable";
import RefreshIcon from "@mui/icons-material/Refresh";

export type Props = {
  system: boolean;
  subscriptionOrderBook: MarketSubscriptionOrderBookViewModel;
  reload: () => void;
  underWriterView?: boolean;
};

export const PrimaryMarket = (props: Props) => {
  const { authContext, viewConfiguration } = useApplicationContext();
  const { errorContextErrorTranslator, errorContextDefaultWarningFeedback } =
    useErrorContext();

  const [settlementModel, setSettlementModel] = useState<
    SubscriptionOrderBookModel | undefined
  >(undefined);
  useEffect(() => {
    (async () => {
      if (props.underWriterView) return;

      // if book not yet set then do nothing
      if (props.subscriptionOrderBook.subscriptionOrderBookID === "") {
        return;
      }

      // if model already set and is for the current book token then do nothing
      if (
        settlementModel &&
        settlementModel.outstandingTokens.token.isEqualTo(
          props.subscriptionOrderBook.token,
        )
      ) {
        return;
      }
      try {
        setSettlementModel(
          props.system || props.underWriterView
            ? (
                await MarketSubscriptionOrderBookSettlementViewUnscopedReader.UnscopedReadOne(
                  {
                    context: authContext,
                    criteria: {
                      subscriptionOrderBookID: TextExactCriterion(
                        props.subscriptionOrderBook.subscriptionOrderBookID,
                      ),
                    },
                  },
                )
              ).model
            : (
                await MarketSubscriptionOrderBookSettlementViewReader.ReadOne({
                  context: authContext,
                  criteria: {
                    subscriptionOrderBookID: TextExactCriterion(
                      props.subscriptionOrderBook.subscriptionOrderBookID,
                    ),
                  },
                })
              ).model,
        );
      } catch (e) {
        errorContextDefaultWarningFeedback(
          e,
          "error reading subscription order settlement view",
        );
      }
    })();
  }, [props.subscriptionOrderBook, authContext]);

  const [signingInProgress, setSigningInProgress] = useState(false);
  const handleSign = useCallback(async () => {
    setSigningInProgress(true);
    try {
      await TransactionSignatureController.AddMyMeshKeySignatureToTransaction({
        context: authContext,
        transactionID: props.subscriptionOrderBook.settlementTransactionID,
      });
      props.reload();
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error signing: ${err.message ? err.message : err.toString()}`,
      );
      errorContextDefaultWarningFeedback(e, "error signing for settlement");
    }
    setSigningInProgress(false);
  }, [authContext]);

  if (props.subscriptionOrderBook.id === "") {
    return (
      <CardContent
        sx={{
          height: 400,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "grid",
            gridTemplateColumns: "auto",
            justifyItems: "center",
            gridRowGap: theme.spacing(2),
          })}
        >
          <FaceIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Nothing to see here"
          />
          <Typography
            variant="body2"
            children={
              "You will see primary market details here your instrument has been pre-issued & listed"
            }
          />
        </Box>
      </CardContent>
    );
  }

  return (
    <>
      <CardContent
        sx={{
          pt: 3,
          px: 4,
          pb: 5,
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            gap: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            borderBottom: `${theme.palette.divider} 1px solid`,
          })}
        >
          <Typography
            variant={"h4"}
            sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
          >
            Book {props.subscriptionOrderBook.number}
          </Typography>
          <SubscriptionOrderBookStateChip
            state={props.subscriptionOrderBook.state}
          />
          {signingInProgress && <CircularProgress size={20} />}
          {viewConfiguration?.["Subscriptions"]?.["Sign for Settlement"] &&
            props.subscriptionOrderBook.state ===
              SubscriptionOrderBookState.SettlementInProgress &&
            props.subscriptionOrderBook.settlementTransactionState ===
              TransactionState.SigningInProgress && (
              <Button
                sx={{ marginLeft: "auto" }}
                variant={"contained"}
                color={"primary"}
                disabled={signingInProgress}
                onClick={handleSign}
              >
                Sign for Settlement
              </Button>
            )}
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            columnGap: 5,
            rowGap: 2,
          }}
        >
          <>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Open Date
              </Typography>
              <Typography
                id={"primaryMarket-subscriptionOrderBook-openDate"}
                variant="h5"
              >
                {dayjs(props.subscriptionOrderBook.openDate).format(
                  "DD/MM/YYYY HH:mm:ss",
                )}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Close Date
              </Typography>
              <Typography
                variant="h5"
                id={"primaryMarket-subscriptionOrderBook-closeDate"}
              >
                {dayjs(props.subscriptionOrderBook.closeDate).format(
                  "DD/MM/YYYY HH:mm:ss",
                )}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Settlement Date
              </Typography>
              <Typography
                variant="h5"
                id={"primaryMarket-subscriptionOrderBook-settlementDate"}
              >
                {dayjs(props.subscriptionOrderBook.settlementDate).format(
                  "DD/MM/YYYY HH:mm:ss",
                )}
              </Typography>
            </Box>
          </>

          <>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Unit Price
              </Typography>
              <Amount
                id={"primaryMarket-subscriptionOrderBook-unitPrice"}
                amount={props.subscriptionOrderBook.unitPrice}
                codeTypographyProps={{
                  variant: "h5",
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h5",
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Subscription Total
              </Typography>
              <Amount
                id={"primaryMarket-subscriptionOrderBook-subscriptionAmount"}
                amount={props.subscriptionOrderBook.subscriptionAmount}
                codeTypographyProps={{
                  variant: "h5",
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h5",
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Over Subscription Total
              </Typography>
              <Amount
                id={
                  "primaryMarket-subscriptionOrderBook-overSubscriptionAmount"
                }
                amount={props.subscriptionOrderBook.overSubscriptionAmount}
                codeTypographyProps={{
                  variant: "h5",
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h5",
                }}
              />
            </Box>
          </>
        </Box>
      </CardContent>

      {/* Settlement Information */}
      {!props.underWriterView && (
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={(theme) => ({
              pl: 4,
              flexDirection: "row-reverse",
              backgroundColor: theme.palette.custom.midnight,
            })}
            expandIcon={
              <ExpandMoreIcon
                id="primaryMarket-settlementInformation-accordionToggleIconButton"
                color="primary"
              />
            }
          >
            <Typography sx={{ ml: 4 }} variant={"h6"}>
              Settlement Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={(theme) => ({
              padding: theme.spacing(3, 4, 5, 4),
              display: "flex",
              flexDirection: "column",
              gap: 1,
            })}
          >
            {settlementModel ? (
              <>
                <Box>
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: theme.spacing(2),
                    })}
                  >
                    <Box>
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Amount Subscribed
                      </Typography>
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                          }),
                        }}
                        amount={settlementModel.subscribedAmount}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        marginLeft: "auto",
                      }}
                    >
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Outstanding Amount
                      </Typography>
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                          }),
                        }}
                        amount={settlementModel.outstandingAmount}
                      />
                    </Box>
                  </Box>
                  <LoadingBar
                    percent={settlementModel.subscribedAmount.value
                      .dividedBy(
                        props.subscriptionOrderBook.subscriptionAmount.value,
                      )
                      .multipliedBy(new BigNumber(100))}
                  />
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      flexDirection: "row",
                      marginTop: theme.spacing(2),
                    })}
                  >
                    <Box>
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                          }),
                        }}
                        amount={settlementModel.subscribedTokens}
                      />
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Tokens Subscribed
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        marginLeft: "auto",
                      }}
                    >
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                          }),
                        }}
                        amount={settlementModel.outstandingTokens}
                      />
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Outstanding Tokens
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {/* ------------------ Settlement Amount ------------------ */}
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      columnGap: 5,
                      rowGap: 2,
                    }}
                  >
                    <Box>
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Total Settlement Amount
                      </Typography>
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                        }}
                        amount={settlementModel.settlementAmount}
                      />
                    </Box>

                    <Box>
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Left Over Amount
                      </Typography>
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                        }}
                        amount={settlementModel.settlementAmount.setValue(
                          settlementModel.leftOverAmount,
                        )}
                      />
                    </Box>
                  </Box>

                  {/* ------------------ Settlement Tokens ------------------ */}
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      columnGap: 5,
                      rowGap: 2,
                    }}
                  >
                    <Box>
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Total Settlement Tokens
                      </Typography>
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                        }}
                        amount={settlementModel.settlementTokens}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant={"body2"}
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        Left Over Tokens
                      </Typography>
                      <Amount
                        codeTypographyProps={{
                          variant: "h5",
                          sx: (theme) => ({
                            color: theme.palette.text.secondary,
                          }),
                        }}
                        valueTypographyProps={{
                          variant: "h5",
                        }}
                        amount={settlementModel.settlementTokens.setValue(
                          settlementModel.leftOverTokens,
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <Box
                sx={(theme) => ({
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  rowGap: theme.spacing(2),
                  alignItems: "center",
                  justifyItems: "center",
                })}
              >
                <CircularProgress size={40} />
                <Typography
                  variant={"h5"}
                  color={"textSecondary"}
                  children={"Getting things ready for you..."}
                />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      )}

      {/* Underwriters */}
      <Accordion defaultExpanded>
        <AccordionSummary
          sx={(theme) => ({
            pl: 4,
            flexDirection: "row-reverse",
            backgroundColor: theme.palette.custom.midnight,
          })}
          expandIcon={
            <ExpandMoreIcon
              id="primaryMarket-underwriters-accordionToggleIconButton"
              color="primary"
            />
          }
        >
          <Typography sx={{ ml: 4 }} variant={"h6"}>
            Underwriters
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            pt: 3,
            px: 4,
            pb: 5,
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          {settlementModel && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                columnGap: 5,
              }}
            >
              <Box>
                <Typography
                  variant={"body2"}
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                >
                  Total Underwriter Take Up Amount
                </Typography>
                <Amount
                  codeTypographyProps={{
                    variant: "h5",
                    sx: (theme) => ({
                      color: theme.palette.text.secondary,
                    }),
                  }}
                  valueTypographyProps={{
                    variant: "h5",
                  }}
                  amount={settlementModel.underwriterTakeUpAmount}
                />
              </Box>
              <Box>
                <Typography
                  variant={"body2"}
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                >
                  Total Underwriter Take Up Tokens
                </Typography>
                <Amount
                  codeTypographyProps={{
                    variant: "h5",
                    sx: (theme) => ({
                      color: theme.palette.text.secondary,
                    }),
                  }}
                  valueTypographyProps={{
                    variant: "h5",
                  }}
                  amount={settlementModel.underwriterTakeUpTokens}
                />
              </Box>
            </Box>
          )}
          <SubscriptionUnderwritingTable
            style={(theme) => ({
              ".BPTable-tableWrapper": {
                backgroundColor: theme.palette.custom.grape,
              },
            })}
            system={props.system}
            height={400}
            subscriptionOrderBookID={
              props.subscriptionOrderBook.subscriptionOrderBookID
            }
            hideColumns={["Res. Count", "Asset Name", "Book"]}
            showEmptyCard
          />
        </AccordionDetails>
      </Accordion>

      {/* Subscriptions */}
      {!props.underWriterView && (
        <>
          {props.subscriptionOrderBook.state ===
          SubscriptionOrderBookState.Settled ? (
            <Accordion defaultExpanded>
              <AccordionSummary
                sx={(theme) => ({
                  flexDirection: "row-reverse",
                  backgroundColor: theme.palette.custom.midnight,
                })}
                expandIcon={
                  <ExpandMoreIcon
                    sx={(theme) => ({
                      marginLeft: theme.spacing(4),
                      marginRight: theme.spacing(2.5),
                    })}
                    id="primaryMarket-subscriptions-accordionToggleIconButton"
                    color="primary"
                  />
                }
              >
                <Typography variant={"h6"}>Asset Holders</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  pt: 3,
                  px: 4,
                  pb: 5,
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                }}
              >
                <AssetHoldersTable token={props.subscriptionOrderBook.token} />
              </AccordionDetails>
            </Accordion>
          ) : (
            <Accordion defaultExpanded>
              <AccordionSummary
                sx={(theme) => ({
                  flexDirection: "row-reverse",
                  backgroundColor: theme.palette.custom.midnight,
                })}
                expandIcon={
                  <ExpandMoreIcon
                    sx={(theme) => ({
                      marginLeft: theme.spacing(4),
                      marginRight: theme.spacing(2.5),
                    })}
                    id="primaryMarket-subscriptions-accordionToggleIconButton"
                    color="primary"
                  />
                }
              >
                <Typography variant={"h6"}>Subscriptions</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  pt: 3,
                  px: 4,
                  pb: 5,
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                }}
              >
                <SubscriptionOrderTable
                  style={(theme) => ({
                    ".BPTable-tableWrapper": {
                      backgroundColor: theme.palette.custom.grape,
                    },
                  })}
                  system={props.system}
                  height={400}
                  hideColumns={["Res. Count", "Asset Name", "Book"]}
                  subscriptionOrderBookID={
                    props.subscriptionOrderBook.subscriptionOrderBookID
                  }
                  showEmptyCard
                />
              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}
    </>
  );
};

interface NoDataCardProps {
  title: string;
  subTitle: string[];
  refresh?: () => void;
  loading?: boolean;
}
export const NoDataCard = ({
  title,
  subTitle,
  refresh,
  loading,
}: NoDataCardProps) => (
  <Card
    sx={(theme) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.secondary,
      textAlign: "center",
      backgroundColor: theme.palette.custom.grape,
      height: 166,
      position: "relative",
    })}
  >
    {refresh && (
      <IconButton
        sx={{ position: "absolute", top: 4, right: 8 }}
        onClick={refresh}
      >
        <RefreshIcon />
      </IconButton>
    )}
    {loading ? (
      <CircularProgress />
    ) : (
      <>
        <Typography
          sx={{
            mb: 2,
            fontWeight: "bold",
          }}
          variant="h3"
          children={title}
          color="textSecondary"
        />
        {subTitle.map((text, idx) => {
          return (
            <Typography
              key={idx}
              variant="body1"
              children={text}
              color="textSecondary"
            />
          );
        })}
      </>
    )}
  </Card>
);
