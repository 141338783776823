import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { BPTable } from "components/Table";
import { NewSorting, Query } from "james/search/query";
import { useSearchFundingReceipts } from "james/banking/fundingReceiptsRepository";
import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextFieldProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Clear as ClearIcon,
  Close as CloseIcon,
  Receipt,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { DateField, TextField, TextNumField } from "components/FormFields";
import {
  DateRangeCriterion,
  TextExactCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import {
  Model as StellarAccountViewModel,
  UnscopedReader as UnscopedStellarAccountViewReader,
} from "james/views/stellarAccountView";
import { Balance } from "james/stellar/Account";
import { useSnackbar } from "notistack";
import { AccountFunder } from "james/banking/accountFunder";
import dayjs from "dayjs";
import { AuditEntry } from "james/audit";
import { Amount } from "components/Ledger/Amount";
import { FundingReceipt } from "james/banking/fundingReceipt";
import {
  CurrencyStablecoin,
  FinancialCurrencyStablecoinRepository,
} from "james/financial";
import { Amount as LedgerAmount } from "james/ledger";
import { DateRangeValue } from "james/search/criterion/date/Range";
import { dateIsValid } from "utilities/date/dateIsValid";
import { useAccountContext } from "context/Account/Account";
import { useStellarContext } from "context/Stellar";
import { LedgerIDIdentifier } from "james/search/identifier";
import { useApplicationContext } from "context/Application/Application";
import { useGTMTriggersPusher } from "hooks/analytics/useGTMTriggersPusher";
import { TransactionTypes } from "../../types/gtm";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";

const PREFIX = "Funding";

const classes = {
  assetToMintSection: `${PREFIX}-assetToMintSection`,
  detail: `${PREFIX}-detail`,
  collapse: `${PREFIX}-collapse`,
  dialogTitleContent: `${PREFIX}-dialogTitleContent`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  textSearchField: `${PREFIX}-textSearchField`,
  dateFilterField: `${PREFIX}-dateFilterField`,
  iconButton: `${PREFIX}-iconButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.assetToMintSection}`]: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.detail}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(3, auto)",
  },

  [`& .${classes.collapse}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.dialogTitleContent}`]: {
    alignItems: "center",
  },

  [`& .${classes.dialogTitle}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.textSearchField}`]: {
    width: 400,
  },

  [`& .${classes.dateFilterField}`]: {
    width: 180,
  },

  [`& .${classes.iconButton}`]: {
    fontSize: 20,
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
    cursor: "pointer",
  },
}));

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("id", "desc")],
});

export function Funding() {
  const { authContext } = useApplicationContext();
  const [displayDialog, setDisplayDialog] = useState(false);
  const {
    searchFundingReceiptsRequest,
    searchFundingReceiptsResponse,
    setSearchFundingReceiptsRequest,
    loading: tableLoading,
  } = useSearchFundingReceipts({
    context: authContext,
    criteria: {},
    query: new Query(initialQuery),
  });
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [
          {
            accountNumber: TextSubstringCriterion(textSearchCriterionTextField),
          },
          {
            bankReference: TextSubstringCriterion(textSearchCriterionTextField),
          },
          { bankName: TextSubstringCriterion(textSearchCriterionTextField) },
        ],
      });
    }
  }, [textSearchCriterionTextField]);
  const [dateTimeCriterionFrom, setDateTimeCriterionFrom] = useState<
    DateRangeValue | undefined
  >(undefined);
  const [dateTimeCriterionTo, setDateTimeCriterionTo] = useState<
    DateRangeValue | undefined
  >(undefined);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = {};

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    if (dateTimeCriterionFrom || dateTimeCriterionTo) {
      criteria["auditEntry.time"] = DateRangeCriterion(
        dateTimeCriterionFrom,
        dateTimeCriterionTo,
      );
    }

    setSearchFundingReceiptsRequest({
      context: searchFundingReceiptsRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [
    textSearchCriterion,
    searchFundingReceiptsRequest.context,
    setSearchFundingReceiptsRequest,
    authContext,
    dateTimeCriterionFrom,
    dateTimeCriterionTo,
  ]);

  return (
    <>
      <BPTable
        loading={tableLoading}
        height={window.innerHeight - 138 - noticeBannerHeight}
        title="Funding Receipts"
        data={searchFundingReceiptsResponse.records}
        columns={[
          {
            label: "Account Number",
            field: "accountNumber",
          },
          {
            label: "Bank Transaction Ref.",
            field: "bankReference",
          },
          {
            label: "Bank Name",
            field: "bankName",
          },
          {
            label: "Amount",
            field: "amount.value.float",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => (
              <Amount amount={(data as FundingReceipt).amount} />
            ),
          },
          {
            label: "Date",
            field: "auditEntry.time",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => {
              const auditEntry = data.auditEntry as AuditEntry;
              return dayjs(auditEntry.time).format("YYYY/MM/DD hh:mm");
            },
          },
        ]}
        query={searchFundingReceiptsRequest.query}
        onQueryChange={(query: Query) =>
          setSearchFundingReceiptsRequest({
            ...searchFundingReceiptsRequest,
            query,
          })
        }
        totalNoRecords={searchFundingReceiptsResponse.total}
        toolBarControls={[
          <Tooltip title="Add Funding Receipt">
            <span>
              <IconButton
                onClick={() => setDisplayDialog(true)}
                id="funding-addfundingReceipt-button"
                disabled={tableLoading}
                size="small"
              >
                <Receipt />
              </IconButton>
            </span>
          </Tooltip>,
          <Tooltip title="Reload">
            <span>
              <IconButton
                size="small"
                onClick={() =>
                  setSearchFundingReceiptsRequest({
                    ...searchFundingReceiptsRequest,
                    query: new Query(initialQuery),
                  })
                }
              >
                <ReloadIcon />
              </IconButton>
            </span>
          </Tooltip>,
        ]}
        filters={[
          <TextField
            variant="outlined"
            margin="dense"
            className={classes.textSearchField}
            label="Search Text Fields"
            placeholder="Start Typing..."
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: textSearchCriterionTextField ? (
                <InputAdornment
                  position="end"
                  children={
                    <IconButton
                      size="small"
                      onClick={() => setTextSearchCriterionTextField("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                />
              ) : undefined,
            }}
            value={textSearchCriterionTextField}
            onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
          />,
          <DateField
            label="From"
            id="funding-dateRangeFrom-dateField"
            className={classes.dateFilterField}
            value={dateTimeCriterionFrom ? dateTimeCriterionFrom.date : null}
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setDateTimeCriterionFrom(undefined);
              } else {
                setDateTimeCriterionFrom(
                  newValue
                    ? {
                        date: newValue.startOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      }
                    : undefined,
                );
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (() => (
                    <>
                      {dateTimeCriterionFrom && (
                        <Tooltip title="Clear" placement="top">
                          <IconButton
                            className={classes.iconButton}
                            size="small"
                            onClick={() => setDateTimeCriterionFrom(undefined)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {textFieldProps.InputProps &&
                      textFieldProps.InputProps.endAdornment
                        ? textFieldProps.InputProps.endAdornment
                        : null}
                    </>
                  ))(),
                }}
              />
            )}
          />,
          <DateField
            label="To"
            id="funding-dateRangeTo-dateField"
            className={classes.dateFilterField}
            value={dateTimeCriterionTo ? dateTimeCriterionTo.date : null}
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setDateTimeCriterionTo(undefined);
              } else {
                setDateTimeCriterionTo(
                  newValue
                    ? {
                        date: newValue.endOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      }
                    : undefined,
                );
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (() => (
                    <>
                      {dateTimeCriterionTo && (
                        <Tooltip title="Clear" placement="top">
                          <IconButton
                            className={classes.iconButton}
                            size="small"
                            onClick={() => {
                              setDateTimeCriterionTo(undefined);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {textFieldProps.InputProps &&
                      textFieldProps.InputProps.endAdornment
                        ? textFieldProps.InputProps.endAdornment
                        : null}
                    </>
                  ))(),
                }}
              />
            )}
          />,
        ]}
      />
      {displayDialog && (
        <FundingReceiptDialog
          onClose={() => {
            setDisplayDialog(false);
            setSearchFundingReceiptsRequest({
              ...searchFundingReceiptsRequest,
              query: new Query(initialQuery),
            });
          }}
          open={displayDialog}
        />
      )}
    </>
  );
}

interface FundingReceiptProps {
  open: boolean;
  onClose: () => void;
}

function FundingReceiptDialog(props: FundingReceiptProps) {
  const { errorContextErrorTranslator, errorContextDefaultWarningFeedback } =
    useErrorContext();
  const { authContext } = useApplicationContext();
  const [collapse, setCollapse] = useState(false);
  const [reference, setReference] = useState("");
  const [targetStellarAccountViewModel, setTargetStellarAccountViewModel] =
    useState(new StellarAccountViewModel());
  const [mZARStablecoinBalance, setMZARStablecoinBalance] = useState<
    Balance | undefined
  >(undefined);
  const [unitsToMint, setUnitsToMint] = useState<LedgerAmount>(
    new LedgerAmount(),
  );
  const [apiLoading, setApiLoading] = useState(false);
  const [paymentReference, setPaymentReference] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { stellarAccountContextPopulateModelWithLedgerDetails } =
    useStellarContext();
  const [validationState, setValidationState] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }>({});
  const theme = useTheme();

  // on dialog open:
  // - Retrieve mZAR stablecoin model
  // - Determine if user is signatory on stablecoin issuance account
  const [initialising, setInitialising] = useState(true);
  const [userSignatoryOnAccount, setUserSignatoryOnAccount] = useState(false);
  const [mZARCurrencyStablecoin, setMZARCurrencyStablecoin] = useState(
    new CurrencyStablecoin(),
  );
  const { stellarAccountContext } = useAccountContext();
  const { pushFundAccountComplete } = useGTMTriggersPusher();

  useEffect(() => {
    (async () => {
      if (stellarAccountContext.loading) {
        return;
      }

      if (stellarAccountContext.error) {
        console.error(`initialisation error::${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialisation Error: ${stellarAccountContext.error}`,
          {
            variant: "error",
          },
        );

        // close the dialog
        props.onClose();
      }

      // retrieve mZAR stablecoin view model and issuance token
      let retrievedMZARCurrencyStablecoin: CurrencyStablecoin;
      try {
        const currencyStablecoins = (
          await FinancialCurrencyStablecoinRepository.SearchCurrencyStablecoin({
            context: authContext,
            criteria: {
              "token.code": TextExactCriterion("mZAR"),
            },
          })
        ).records;
        if (currencyStablecoins.length !== 1) {
          console.error(
            "unexpected number of currency stablecoins retrieved for mZAR",
          );
          enqueueSnackbar(
            "unexpected number of currency stablecoins retrieved for mZAR",
            { variant: "error" },
          );
          setInitialising(false);
          return;
        }
        retrievedMZARCurrencyStablecoin = currencyStablecoins[0];
        if (!unitsToMint) {
          setUnitsToMint(
            retrievedMZARCurrencyStablecoin.token.newAmountOf("0"),
          );
        }
        setMZARCurrencyStablecoin(currencyStablecoins[0]);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving mZAR currency stablecoin: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error retrieving mZAR currency stablecoin", {
          variant: "error",
        });
        setInitialising(false);
        return;
      }

      try {
        setUserSignatoryOnAccount(
          await stellarAccountContext.checkUserSignatoryOnAccount(
            LedgerIDIdentifier(retrievedMZARCurrencyStablecoin.token.issuer),
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user is signatory on destination account: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Determining Signatory Status", {
          variant: "error",
        });
      }

      setInitialising(false);
    })();
  }, [
    targetStellarAccountViewModel,
    authContext,
    enqueueSnackbar,
    stellarAccountContext.loading,
    stellarAccountContext.error,
  ]);

  const performValidations = () => {
    const newValidationState: { [key: string]: string | undefined } = {};

    if (!mZARStablecoinBalance) {
      newValidationState.limit = "Limit not set on destination account.";
    }

    if (unitsToMint.value.isZero()) {
      newValidationState.unitsToMint = "Cannot be zero";
    }

    if (!paymentReference) {
      newValidationState.paymentReference = "Cannot be blank.";
    }

    setValidationState(newValidationState);
    return !Object.keys(newValidationState).length;
  };

  const handleClearValidation = (field: string) => {
    setValidationState({
      ...validationState,
      [field]: undefined,
    });
  };

  const handleFund = async () => {
    if (!performValidations()) {
      return;
    }

    setApiLoading(true);
    try {
      await AccountFunder.FundAccount({
        context: authContext,
        currencyStablecoinID: mZARCurrencyStablecoin.id,
        bankReference: paymentReference,
        accountNumber: targetStellarAccountViewModel.number,
        amount: unitsToMint,
      });
      enqueueSnackbar("Account Funded", { variant: "success" });
      pushFundAccountComplete({
        account_transaction_type: TransactionTypes.Fund,
        account_transaction_amount: unitsToMint.value.toString(),
        account_no: targetStellarAccountViewModel.number,
      });
      props.onClose();
      return;
    } catch (e) {
      errorContextDefaultWarningFeedback(e, "Unable to Complete Funding");
    }
    setApiLoading(false);
  };

  const getAccountDetails = async () => {
    setApiLoading(true);
    setValidationState({});
    try {
      setCollapse(true);

      // retrieve view model of account that is to be funded
      const ledgerAccViewModel =
        await stellarAccountContextPopulateModelWithLedgerDetails(
          (
            await UnscopedStellarAccountViewReader.UnscopedReadOne({
              context: authContext,
              criteria: {
                number: TextExactCriterion(reference),
              },
            })
          ).model,
        );
      setTargetStellarAccountViewModel(ledgerAccViewModel);

      // look for mZAR balance in balances of the account
      const balance = ledgerAccViewModel.getTokenBalance(
        mZARCurrencyStablecoin.token,
      );

      setMZARStablecoinBalance(balance);
    } catch (e) {
      setCollapse(false);
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error retrieving target stellar account view model : ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Error retrieving account details: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setApiLoading(false);
  };

  return (
    <StyledDialog open={props.open}>
      <DialogTitle>
        <Grid
          className={classes.dialogTitleContent}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item className={classes.dialogTitle}>
            <Typography variant="h5">Fund Account</Typography>
          </Grid>
          <Grid item>
            <IconButton
              id="fundReceipt-close-button"
              disabled={apiLoading}
              onClick={props.onClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ width: "570px" }}>
        <div className={classes.assetToMintSection}>
          <TextField
            disabled={initialising || apiLoading}
            id="fundReceipt-assetCode-textField"
            label="Asset Code"
            value={mZARCurrencyStablecoin.token.code}
            readOnly
          />
          <TextNumField
            disabled={initialising || apiLoading}
            id="fundReceipt-mintAmount-textNumField"
            label="Units to Mint"
            value={unitsToMint.value}
            error={!!validationState.unitsToMint}
            helperText={validationState.unitsToMint}
            disallowNegative
            onChange={(e) => {
              handleClearValidation("unitsToMint");
              setUnitsToMint(
                mZARCurrencyStablecoin.token.newAmountOf(e.target.value),
              );
            }}
          />
        </div>
        <div className={classes.assetToMintSection}>
          <TextField
            disabled={initialising || apiLoading}
            fullWidth
            id="fundReceipt-reference-textField"
            onChange={(e) => setReference(e.target.value)}
            label="Client Reference (Account Nr.)"
            value={reference}
          />
          <div
            style={{
              color: "secondary",
              display: "flex",
              paddingLeft: theme.spacing(2),
              alignItems: "center",
            }}
          >
            <Button
              id="fundReceipt-fund-button"
              variant="contained"
              color="secondary"
              disabled={apiLoading || initialising}
              onClick={getAccountDetails}
            >
              Fetch
            </Button>
          </div>
        </div>
      </DialogContent>
      <Collapse className={classes.collapse} in={collapse}>
        {!apiLoading && (
          <div className={classes.detail}>
            <TextField
              id="fundReceipt-client-name"
              label="Client"
              readOnly
              value={targetStellarAccountViewModel.accountOwnerClientName}
            />
            <TextField
              id="fundReceipt-group-name"
              label="Group"
              readOnly
              value={targetStellarAccountViewModel.accountOwnerGroupName}
            />
            <TextField
              id="fundReceipt-account-number"
              label="Account Number"
              readOnly
              value={targetStellarAccountViewModel.number}
            />
            <TextNumField
              id="fundReceipt-stablecoinBalance-textfield"
              label="mZAR Balance"
              readOnly
              error={!mZARStablecoinBalance}
              value={mZARStablecoinBalance?.availableBalance().value}
            />
            <TextNumField
              id="fundReceipt-stablecoinlimit-textfield"
              label="mZAR limit"
              fullWidth
              readOnly
              value={mZARStablecoinBalance?.limit.value}
              error={!!validationState.limit || !mZARStablecoinBalance}
              helperText={validationState.limit}
            />
          </div>
        )}
        {!apiLoading && (
          <TextField
            id="fundReceipt-stablecoinledgerID-textfield"
            label="Ledger ID"
            fullWidth
            readOnly
            value={targetStellarAccountViewModel.ledgerID}
          />
        )}
        {!apiLoading && (
          <TextField
            fullWidth
            style={{ marginBottom: theme.spacing(2) }}
            id="fundReceipt-paymentReference-balance"
            label="Bank Transaction Ref."
            value={paymentReference}
            error={!!validationState.paymentReference}
            helperText={validationState.paymentReference}
            onChange={(e) => {
              handleClearValidation("paymentReference");
              setPaymentReference(e.target.value);
            }}
          />
        )}
        {!apiLoading && (
          <Tooltip
            placement="top"
            title={
              userSignatoryOnAccount
                ? ""
                : "user is not a signatory on issuance account"
            }
          >
            <span>
              <Button
                id="fundReceipt-fundAccount-balance"
                variant="contained"
                color="primary"
                fullWidth
                size="small"
                onClick={handleFund}
                disabled={!userSignatoryOnAccount}
                children="fund account"
              />
            </span>
          </Tooltip>
        )}
        {apiLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={25} />
          </div>
        )}
      </Collapse>
    </StyledDialog>
  );
}
