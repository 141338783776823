import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { UserManagerRead } from "james/user";
import { doUpload } from "utilities/network/upload";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Drawer,
  TextField,
  Typography,
} from "@mui/material";
import cx from "classnames";
import { AddAPhotoRounded as AddAPhotoIcon } from "@mui/icons-material";
import { PersonManagerRead, PersonManagerWrite } from "james/legal/person";
import { useSnackbar } from "notistack";
import { ValidateContactNumber } from "utilities/validation";
import { useWidth } from "utilities/general";
import { isWidthDown, isWidthUp } from "@mui/material/Hidden/withWidth";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

const PREFIX = "UserProfile";

const classes = {
  contentLayout: `${PREFIX}-contentLayout`,
  sectionHeading: `${PREFIX}-sectionHeading`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
  circleContent: `${PREFIX}-circleContent`,
  twoCols: `${PREFIX}-twoCols`,
  userDetailsWrapper: `${PREFIX}-userDetailsWrapper`,
  centreJustifyContentTypography: `${PREFIX}-centreJustifyContentTypography`,
  subtitleTypographyPadding: `${PREFIX}-subtitleTypographyPadding`,
  avatar: `${PREFIX}-avatar`,
  avatarWrapper: `${PREFIX}-avatarWrapper`,
  avatarNoImage: `${PREFIX}-avatarNoImage`,
  profilePictureVertical: `${PREFIX}-profilePictureVertical`,
  profilePictureHorizontal: `${PREFIX}-profilePictureHorizontal`,
  hiddenInput: `${PREFIX}-hiddenInput`,
  circularProgressWrapper: `${PREFIX}-circularProgressWrapper`,
  textDisabled: `${PREFIX}-textDisabled`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.contentLayout}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(3),
  },

  [`& .${classes.sectionHeading}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.buttonWrapper}`]: {
    marginTop: theme.spacing(5),
    justifyContent: "center",
    display: "flex",
  },

  [`& .${classes.circleContent}`]: {
    display: "grid",
    gridTemplateRows: "repeat(5,auto)",
  },

  [`& .${classes.twoCols}`]: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
  },

  [`& .${classes.userDetailsWrapper}`]: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "120px",
    marginRight: "120px",
  },

  [`& .${classes.centreJustifyContentTypography}`]: {
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.subtitleTypographyPadding}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.avatar}`]: {
    width: 56,
    height: 56,
    cursor: "pointer",
    alignItems: "start",
  },

  [`& .${classes.avatarWrapper}`]: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.avatarNoImage}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  [`& .${classes.profilePictureVertical}`]: {
    width: 56,
  },

  [`& .${classes.profilePictureHorizontal}`]: {
    height: 56,
  },

  [`& .${classes.hiddenInput}`]: {
    visibility: "hidden",
    width: 0,
    height: 0,
  },

  [`& .${classes.circularProgressWrapper}`]: {
    width: 60,
    height: 60,
    padding: 0,
    margin: 0,
  },

  [`& .${classes.textDisabled}`]: {
    color: theme.palette.text.disabled,
  },
}));

interface UserFirstTimeLoginScreenProps {
  onSubmitClick: (param: { firstName: string; lastName: string }) => void;
}

export function UserProfile(props: UserFirstTimeLoginScreenProps) {
  const { errorContextErrorTranslator } = useErrorContext();

  const width = useWidth();
  const isMobile = isWidthDown("sm", width);
  const isDesktop = isWidthUp("sm", width);
  const [apiLoading, setAPILoading] = useState(false);
  const { authContext, myProfilePictureURL, loginClaims } =
    useApplicationContext();
  const [profilePictureURL, setProfilePictureURL] =
    useState(myProfilePictureURL);
  const [profilePictureUploadInProgress, setProfilePictureUploadInProgress] =
    useState(false);
  const [profilePictureUploadPercentage, setProfilePictureUploadPercentage] =
    useState(0);
  const profilePictureRef = useRef<HTMLImageElement>(null);
  const [profilePictureVertical, setProfilePictureVertical] = useState(false);
  const [firstName, setFirstName] = useState(loginClaims.firstName);
  const [lastName, setLastName] = useState(loginClaims.lastName);
  const [middleNames, setMiddleNames] = useState("");
  const [cellphoneNumber, setCellphoneNumber] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [validationState, setValidationState] = useState<{
    [key: string]: string | undefined;
  }>({});

  const desktopInputRef = useRef<HTMLInputElement>(null);
  const mobileInputRef = useRef<HTMLInputElement>(null);

  const performValidation = () => {
    const newValidationState: { [key: string]: string | undefined } = {};

    if (!firstName) {
      newValidationState.firstName = "First Name is required";
    }

    if (!lastName) {
      newValidationState.lastName = "Last Name is required";
    }

    if (cellphoneNumber && !ValidateContactNumber(cellphoneNumber)) {
      newValidationState.cellphoneNumber = "Cellphone number is invalid";
    }

    if (telephoneNumber && !ValidateContactNumber(telephoneNumber)) {
      newValidationState.telephoneNumber = "Telephone number is invalid";
    }

    setValidationState(newValidationState);
    return !Object.keys(newValidationState).length;
  };

  const handleClearValidationState = (field: string) => {
    setValidationState({
      ...validationState,
      [field]: undefined,
    });
  };

  const handleUpdateMyPerson = async () => {
    // perform validations
    if (!performValidation()) {
      return;
    }

    setAPILoading(true);
    try {
      // retrieve my person
      const retrieveMyPersonResponse = await PersonManagerRead.RetrieveMyPerson(
        {
          context: authContext,
        },
      );

      // set the necessary fields
      retrieveMyPersonResponse.person.firstName = firstName;
      retrieveMyPersonResponse.person.lastName = lastName;
      retrieveMyPersonResponse.person.middleNames = middleNames;
      retrieveMyPersonResponse.person.contactDetails.cellphoneNumber =
        cellphoneNumber;
      retrieveMyPersonResponse.person.contactDetails.telephoneNumber =
        telephoneNumber;

      // update person entity
      await PersonManagerWrite.UpdateMyPerson({
        context: authContext,
        person: retrieveMyPersonResponse.person,
      });
      props.onSubmitClick({ firstName, lastName });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Error Updating Profile: ${err.message ? err.message : err.toString()}`,
        { variant: "error" },
      );
      console.error(
        `error updating user profile: ${
          err.message ? err.message : err.toString()
        }`,
      );
      setAPILoading(false);
    }
  };

  const retrieveUserProfilePicture = async () => {
    setAPILoading(true);
    try {
      setProfilePictureURL(
        (
          await UserManagerRead.GetMyProfilePictureDownloadURL({
            context: authContext,
          })
        ).url,
      );
    } catch (e) {
      console.error("error getting user profile picture", e);
    }
    setAPILoading(false);
  };

  const handleSelectPictureFile = async () => {
    let uploadInputRef;
    if (desktopInputRef.current) {
      uploadInputRef = desktopInputRef.current;
    } else {
      uploadInputRef = mobileInputRef.current;
    }

    try {
      // confirm that a file has been selected
      if (
        !(uploadInputRef && uploadInputRef.files && uploadInputRef.files.length)
      ) {
        console.error("no upload input ref files");
        return;
      }

      // get a reference to the selected file and confirm it is not null
      const fileToUpload = uploadInputRef.files[0];
      if (!fileToUpload) {
        console.error("file to upload is null");
        return;
      }

      setProfilePictureUploadInProgress(true);
      setProfilePictureUploadPercentage(0);

      // construct a file reader
      // read file
      // perform upload
      const fileReader = new FileReader();
      fileReader.onloadend = async () => {
        const fileData: ArrayBuffer | null =
          fileReader.result as ArrayBuffer | null;
        if (fileData === null) {
          console.error("file data is null");
          return;
        }

        const uploadURL = (
          await UserManagerRead.GetMyProfilePictureUploadURL({
            context: authContext,
          })
        ).url;
        doUpload({
          url: uploadURL,
          documentType: fileToUpload.type,
          documentData: fileData,
          onComplete: () => {
            setProfilePictureUploadInProgress(false);
            setProfilePictureUploadPercentage(0);
            retrieveUserProfilePicture().finally();
          },
          onProgress: (percentageComplete: number) =>
            setProfilePictureUploadPercentage(percentageComplete),
        });
      };

      fileReader.readAsArrayBuffer(uploadInputRef.files[0]);
    } catch (e) {
      console.error("error trying to access picture file", e);
    }
  };

  return (
    <Root>
      {isDesktop && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateRows: "repeat(5,auto)",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              Let&apos;s get to know you
            </Typography>
            <Typography
              sx={(theme) => ({
                display: "flex",
                justifyContent: "center",
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(2),
                color: theme.palette.text.secondary,
              })}
            >
              Enter your details to make the most of your Mesh experience.
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "center",
              marginTop: theme.spacing(3),
            })}
          >
            {apiLoading || profilePictureUploadInProgress ? (
              <Box
                sx={{
                  width: 60,
                  height: 60,
                  padding: 0,
                  margin: 0,
                }}
              >
                <CircularProgress
                  size={55}
                  variant={
                    profilePictureUploadPercentage
                      ? "determinate"
                      : "indeterminate"
                  }
                  value={profilePictureUploadPercentage}
                />
              </Box>
            ) : (
              <Avatar
                id="userFirstTimeLogin-profilePic-button"
                className={cx(classes.avatar, {
                  [classes.avatarNoImage]: !profilePictureURL,
                })}
                onClick={() => {
                  if (desktopInputRef.current != null) {
                    desktopInputRef.current.click();
                  }
                }}
              >
                {profilePictureURL ? (
                  <img
                    onLoad={() => {
                      if (profilePictureRef.current) {
                        setProfilePictureVertical(
                          profilePictureRef.current.naturalWidth <
                            profilePictureRef.current.naturalHeight,
                        );
                      }
                    }}
                    ref={profilePictureRef}
                    alt=""
                    src={profilePictureURL}
                    className={cx({
                      [classes.profilePictureVertical]: profilePictureVertical,
                      [classes.profilePictureHorizontal]:
                        !profilePictureVertical,
                    })}
                  />
                ) : (
                  <AddAPhotoIcon />
                )}
              </Avatar>
            )}
          </Box>
          <Box>
            <input
              id={"desktop-hidden-input"}
              disabled={apiLoading}
              ref={desktopInputRef}
              onChange={handleSelectPictureFile}
              type="file"
              accept=".png,.jpg,.jpeg"
              className={classes.hiddenInput}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={(theme) => ({
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(1),
              })}
              variant="h6"
              children="Personal Details"
            />
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                gap: theme.spacing(2),
              })}
            >
              <TextField
                id="userFirstTimeLogin-firstName-formField"
                disabled={apiLoading}
                InputLabelProps={{ shrink: undefined }}
                variant="outlined"
                label="First Name"
                value={firstName}
                error={!!validationState.firstName}
                helperText={validationState.firstName}
                onChange={(e) => {
                  handleClearValidationState("firstName");
                  setFirstName(e.target.value);
                }}
              />
              <TextField
                id="userFirstTimeLogin-middleNames-formField"
                disabled={apiLoading}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label="Middle Name(s)"
                value={middleNames}
                error={!!validationState.middleNames}
                helperText={validationState.middleNames}
                onChange={(e) => {
                  handleClearValidationState("middleNames");
                  setMiddleNames(e.target.value);
                }}
                placeholder="Optional"
              />
            </Box>
            <TextField
              id="userFirstTimeLogin-lastName-formField"
              disabled={apiLoading}
              InputLabelProps={{ shrink: undefined }}
              variant="outlined"
              label="Last Name"
              value={lastName}
              error={!!validationState.lastName}
              helperText={validationState.lastName}
              onChange={(e) => {
                handleClearValidationState("lastName");
                setLastName(e.target.value);
              }}
            />
            <Typography
              sx={(theme) => ({
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(1),
              })}
              variant="h6"
              children="Contact Details"
            />
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                gap: theme.spacing(2),
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(1),
              })}
            >
              <TextField
                disabled={apiLoading}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                id="userFirstTimeLogin-cellphoneNumber-formField"
                label="Mobile"
                value={cellphoneNumber}
                error={!!validationState.cellphoneNumber}
                helperText={validationState.cellphoneNumber}
                onChange={(e) => {
                  handleClearValidationState("cellphoneNumber");
                  setCellphoneNumber(e.target.value);
                }}
                placeholder="Optional"
              />
              <TextField
                disabled={apiLoading}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                id="userFirstTimeLogin-telephoneNumber-formField"
                label="Telephone"
                value={telephoneNumber}
                error={!!validationState.telephoneNumber}
                helperText={validationState.telephoneNumber}
                onChange={(e) => {
                  handleClearValidationState("telephoneNumber");
                  setTelephoneNumber(e.target.value);
                }}
                placeholder="Optional"
              />
            </Box>
            <Box
              sx={(theme) => ({
                marginTop: theme.spacing(5),
                marginBottom: theme.spacing(3),
                justifyContent: "center",
                display: "flex",
              })}
            >
              <Button
                id="userFirstTimeLogin-next-button"
                disabled={apiLoading}
                onClick={handleUpdateMyPerson}
                variant="contained"
                color="primary"
                size="large"
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {isMobile && (
        <div>
          <Box
            sx={(theme) => ({
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: theme.spacing(3),
            })}
          >
            <Box
              sx={(theme) => ({
                padding: theme.spacing(0, 2, 0, 2),
                width: "300px",
              })}
            >
              <Typography
                variant="h1"
                sx={{
                  justifyContent: "center",
                  fontSize: "27px",
                }}
              >
                Let&apos;s get to know you
              </Typography>
              <Typography
                sx={(theme) => ({
                  justifyContent: "center",
                  marginTop: theme.spacing(3),
                  color: theme.palette.text.secondary,
                })}
                align="center"
              >
                Enter your details to make the most of your Mesh experience.
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                display: "flex",
                justifyContent: "center",
                marginTop: theme.spacing(3),
              })}
            >
              {apiLoading || profilePictureUploadInProgress ? (
                <Box
                  sx={{
                    width: 60,
                    height: 60,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <CircularProgress
                    size={55}
                    variant={
                      profilePictureUploadPercentage
                        ? "determinate"
                        : "indeterminate"
                    }
                    value={profilePictureUploadPercentage}
                  />
                </Box>
              ) : (
                <Avatar
                  id="userFirstTimeLogin-profilePic-button"
                  className={cx(classes.avatar, {
                    [classes.avatarNoImage]: !profilePictureURL,
                  })}
                  onClick={() => {
                    if (mobileInputRef.current != null) {
                      mobileInputRef.current.click();
                    }
                  }}
                >
                  {profilePictureURL ? (
                    <img
                      onLoad={() => {
                        if (profilePictureRef.current) {
                          setProfilePictureVertical(
                            profilePictureRef.current.naturalWidth <
                              profilePictureRef.current.naturalHeight,
                          );
                        }
                      }}
                      ref={profilePictureRef}
                      alt=""
                      src={profilePictureURL}
                      className={cx({
                        [classes.profilePictureVertical]:
                          profilePictureVertical,
                        [classes.profilePictureHorizontal]:
                          !profilePictureVertical,
                      })}
                    />
                  ) : (
                    <AddAPhotoIcon />
                  )}
                </Avatar>
              )}
            </Box>
            <Box>
              <input
                id={"mobile-hidden-input"}
                disabled={apiLoading}
                ref={mobileInputRef}
                onChange={handleSelectPictureFile}
                type="file"
                accept=".png,.jpg,.jpeg"
                className={classes.hiddenInput}
              />
            </Box>
            <Box
              sx={{
                alignItems: "center",
              }}
            >
              <Typography
                sx={(theme) => ({
                  marginBottom: theme.spacing(1),
                  fontSize: "15px",
                })}
                variant="h6"
                children="Personal Details"
              />
            </Box>
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                marginLeft: theme.spacing(3),
                marginRight: theme.spacing(3),
                gap: theme.spacing(1),
                maxWidth: "308px",
                width: "100%",
              })}
            >
              <TextField
                id="userFirstTimeLogin-firstName-formField"
                disabled={apiLoading}
                InputLabelProps={{ shrink: undefined }}
                variant="outlined"
                label="First Name"
                value={firstName}
                error={!!validationState.firstName}
                helperText={validationState.firstName}
                onChange={(e) => {
                  handleClearValidationState("firstName");
                  setFirstName(e.target.value);
                }}
              />
              <TextField
                id="userFirstTimeLogin-middleNames-formField"
                disabled={apiLoading}
                InputLabelProps={{ shrink: undefined }}
                variant="outlined"
                label="Middle Name/s (optional)"
                value={middleNames}
                error={!!validationState.middleNames}
                helperText={validationState.middleNames}
                onChange={(e) => {
                  handleClearValidationState("middleNames");
                  setMiddleNames(e.target.value);
                }}
                placeholder="Optional"
              />
              <TextField
                id="userFirstTimeLogin-lastName-formField"
                disabled={apiLoading}
                InputLabelProps={{ shrink: undefined }}
                variant="outlined"
                label="Last Name"
                value={lastName}
                error={!!validationState.lastName}
                helperText={validationState.lastName}
                onChange={(e) => {
                  handleClearValidationState("lastName");
                  setLastName(e.target.value);
                }}
              />
            </Box>
            <Box
              sx={(theme) => ({
                alignItems: "center",
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(1),
              })}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                }}
                variant="h6"
                children="Contact Details"
              />
            </Box>
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                marginLeft: theme.spacing(3),
                marginRight: theme.spacing(3),
                gap: theme.spacing(1),
                maxWidth: "308px",
                marginBottom: theme.spacing(15),
                width: "100%",
              })}
            >
              <TextField
                disabled={apiLoading}
                InputLabelProps={{ shrink: undefined }}
                variant="outlined"
                id="userFirstTimeLogin-cellphoneNumber-formField"
                label="Mobile"
                value={cellphoneNumber}
                error={!!validationState.cellphoneNumber}
                helperText={validationState.cellphoneNumber}
                onChange={(e) => {
                  handleClearValidationState("cellphoneNumber");
                  setCellphoneNumber(e.target.value);
                }}
                placeholder="Optional"
              />
              <TextField
                disabled={apiLoading}
                InputLabelProps={{ shrink: undefined }}
                variant="outlined"
                id="userFirstTimeLogin-telephoneNumber-formField"
                label="Telephone"
                value={telephoneNumber}
                error={!!validationState.telephoneNumber}
                helperText={validationState.telephoneNumber}
                onChange={(e) => {
                  handleClearValidationState("telephoneNumber");
                  setTelephoneNumber(e.target.value);
                }}
                placeholder="Optional"
              />
            </Box>
          </Box>
          <Drawer
            PaperProps={{
              style: {
                border: "none",
                boxShadow: "0 -10px 12px -14px #000",
              },
            }}
            variant="permanent"
            anchor="bottom"
          >
            <Box
              sx={(theme) => ({
                padding: theme.spacing(3, 3, 3, 3),
                backgroundColor: theme.palette.background.default,
                alignItems: "center",
              })}
            >
              <Button
                sx={{
                  width: "100%",
                  height: 52,
                }}
                id="userFirstTimeLogin-next-button"
                disabled={apiLoading}
                onClick={handleUpdateMyPerson}
                variant="contained"
                color="primary"
                size="large"
              >
                Next
              </Button>
            </Box>
          </Drawer>
        </div>
      )}
    </Root>
  );
}
