"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextField = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
function TextField(_a) {
    var { readOnly, variant } = _a, rest = __rest(_a, ["readOnly", "variant"]);
    if (readOnly) {
        return ((0, jsx_runtime_1.jsx)(material_1.TextField, Object.assign({}, rest, { variant: "standard", InputProps: Object.assign(Object.assign({}, rest.InputProps), { readOnly: true, disableUnderline: true }) })));
    }
    return (0, jsx_runtime_1.jsx)(material_1.TextField, Object.assign({}, rest, { variant: variant || "outlined" }));
}
exports.TextField = TextField;
