"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LedgerAmountField = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const TextNumField_1 = require("./TextNumField");
const decimal_pb_1 = require("@mesh/common-js/dist/num/decimal_pb");
const material_1 = require("@mui/material");
const ledger_1 = require("@mesh/common-js/dist/ledger");
const token_pb_1 = require("@mesh/common-js/dist/ledger/token_pb");
function LedgerAmountField(props) {
    var _a, _b, _c, _d, _e;
    return ((0, jsx_runtime_1.jsx)(TextNumField_1.TextNumField, Object.assign({}, props, { value: (_b = (_a = props === null || props === void 0 ? void 0 : props.value) === null || _a === void 0 ? void 0 : _a.getValue()) !== null && _b !== void 0 ? _b : new decimal_pb_1.Decimal(), onChange: (newValue) => {
            var _a, _b;
            if (!props.onChange) {
                return;
            }
            props.onChange((0, ledger_1.newAmountFromDecimal)(newValue, (_b = (_a = props === null || props === void 0 ? void 0 : props.value) === null || _a === void 0 ? void 0 : _a.getToken()) !== null && _b !== void 0 ? _b : new token_pb_1.FutureToken()));
        }, InputProps: {
            startAdornment: ((0, jsx_runtime_1.jsx)(material_1.InputAdornment, { position: "start", children: (0, jsx_runtime_1.jsx)(material_1.Typography, { children: (_e = (_d = (_c = props === null || props === void 0 ? void 0 : props.value) === null || _c === void 0 ? void 0 : _c.getToken()) === null || _d === void 0 ? void 0 : _d.getCode()) !== null && _e !== void 0 ? _e : "-" }) })),
        } })));
}
exports.LedgerAmountField = LedgerAmountField;
