import {
  ScheduleConfigurationWrapper,
  rateSourceToString,
  scheduleConfigurationTypeToString,
} from "@mesh/common-js/dist/financial";
import { AssetflowCategory } from "@mesh/common-js/dist/financial/assetflowCategory_pb";
import { BusinessDayConvention } from "@mesh/common-js/dist/financial/businessDayConvention_pb";
import { DayCountConvention } from "@mesh/common-js/dist/financial/dayCountConvention_pb";
import { PeriodUnit } from "@mesh/common-js/dist/financial/periodUnit_pb";
import { RateSource } from "@mesh/common-js/dist/financial/rateSource_pb";
import { ScheduleConfigurationType } from "@mesh/common-js/dist/financial/scheduleConfigurationType_pb";
import { DeferrableFloatingRateSmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLegDeferrableFloatingRate_pb";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { ValidationResult } from "common/validation";
import { validateScheduleConfiguration } from "../../../ScheduleConfiguration";

export type DeferrableFloatingRateSmartInstrumentLegValidationData = {
  smartInstrument: SmartInstrument;
};

export function validateDeferrableFloatingRateSmartInstrumentLeg(
  leg: DeferrableFloatingRateSmartInstrumentLeg,
  data: DeferrableFloatingRateSmartInstrumentLegValidationData,
): ValidationResult {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };
  const getFieldValidationPrefix = () =>
    `deferrableFloatingRateSmartInstrumentLeg-${leg.getId()}`;
  const notValid = (field: string, helperText: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[
      `${getFieldValidationPrefix()}-${field}`
    ] = helperText;
  };

  // perform necessary conversions
  const notionalAmount = leg.getNotional()?.getValue() ?? new Decimal();
  const referenceRateFactor = leg.getReferenceratefactor() ?? new Decimal();
  const rateSpread = leg.getRatespread() ?? new Decimal();
  const rateFloor = leg.getRatefloor() ?? new Decimal();
  const wrappedScheduleConfiguration = new ScheduleConfigurationWrapper(
    leg.getScheduleconfiguration(),
  );

  //perform validations
  if (leg.getName() === "") {
    notValid("name", "Must be set");
  } else if (leg.getName().length < 3) {
    notValid("name", "Must be at least 3 characters");
  } else if (
    // placeholder for actual name validation
    leg.getName() === "Non-unique name"
  ) {
    notValid("name", "A leg with this name already exists");
  }

  if (
    !decimalToBigNumber(notionalAmount).isPositive() &&
    !decimalToBigNumber(notionalAmount).isNegative()
  ) {
    notValid("notionalAmount", "Invalid character");
  } else if (decimalToBigNumber(notionalAmount).isLessThanOrEqualTo(0)) {
    notValid("notionalAmount", "Must be greater than 0");
  }

  if (
    leg.getAssetflowcategory() ===
    AssetflowCategory.UNDEFINED_ASSETFLOW_CATEGORY
  ) {
    notValid("assetFlowCategory", "Must be set");
  }

  if (leg.getRatesource() === RateSource.UNDEFINED_RATE_SOURCE) {
    notValid("rateSource", "Must be set");
  } else if (
    leg.getRatesource() !== RateSource.SOUTH_AFRICA_PRIME_RATE_SOURCE
  ) {
    notValid(
      "rateSource",
      `Only ${rateSourceToString(RateSource.SOUTH_AFRICA_PRIME_RATE_SOURCE)} supported`,
    );
  }

  if (
    !decimalToBigNumber(referenceRateFactor).isPositive() &&
    !decimalToBigNumber(referenceRateFactor).isNegative()
  ) {
    notValid("referenceRateFactor", "Invalid character");
  } else if (!decimalToBigNumber(referenceRateFactor).isPositive()) {
    notValid("referenceRateFactor", "Must be greater than 0");
  }

  if (
    !decimalToBigNumber(rateSpread).isPositive() &&
    !decimalToBigNumber(rateSpread).isNegative()
  ) {
    notValid("rateSpread", "Invalid character");
  }

  if (
    !decimalToBigNumber(rateFloor).isPositive() &&
    !decimalToBigNumber(rateFloor).isNegative()
  ) {
    notValid("rateFloor", "Invalid character");
  } else if (!decimalToBigNumber(rateFloor).isPositive()) {
    notValid("rateFloor", "Must be greater than 0");
  }

  if (
    leg.getDaycountconvention() ===
    DayCountConvention.UNDEFINED_DAY_COUNT_CONVENTION
  ) {
    notValid("dayCountConvention", "Must be set");
  }

  if (leg.getRateresetperiodcount() < 0) {
    notValid("rateResetPeriodCount", "Cannot be less than 0");
  }

  if (leg.getRateresetperiodunit() === PeriodUnit.UNDEFINED_PERIOD_UNIT) {
    notValid("rateResetPeriodUnit", "Must be set");
  }

  if (
    leg.getRateresetbusinessdayconvention() ===
    BusinessDayConvention.UNDEFINED_BUSINESS_DAY_CONVENTION
  ) {
    notValid("rateResetBusinessDayConvention", "Must be set");
  }

  if (
    wrappedScheduleConfiguration.scheduleConfigurationType ===
    ScheduleConfigurationType.UNDEFINED_SCHEDULE_CONFIGURATION_TYPE
  ) {
    notValid("scheduleConfiguationType", "Must be set");
  } else if (
    wrappedScheduleConfiguration.scheduleConfigurationType !==
    ScheduleConfigurationType.NON_PERPETUAL_SCHEDULE_CONFIGURATION_TYPE
  ) {
    notValid(
      "scheduleConfiguationType",
      `Only ${scheduleConfigurationTypeToString(ScheduleConfigurationType.NON_PERPETUAL_SCHEDULE_CONFIGURATION_TYPE)} supported`,
    );
  } else {
    const scheduleConfiguration = leg.getScheduleconfiguration();
    if (!scheduleConfiguration) {
      throw new TypeError("schedule configuration is not set");
    }
    const scheduleValidationResult = validateScheduleConfiguration(
      scheduleConfiguration,
      data,
      getFieldValidationPrefix(),
    );
    validationResult.valid =
      scheduleValidationResult.valid && validationResult.valid;
    for (const field in scheduleValidationResult.fieldValidations) {
      validationResult.fieldValidations[field] =
        scheduleValidationResult.fieldValidations[field];
    }
  }

  return validationResult;
}
