import { Token } from "james/ledger";
import { StateStore } from "./type"; // Import the StateStore type from the 'type' file.
import { useCallback } from "react"; // Import the useCallback hook from React.
import { determineEnvironment } from "utilities/general"; // Import a utility function to determine the current environment.

// Define a type for the local storage store with three functions.
export type localStorageStore = {
  getStore: () => StateStore; // Function to get the store's current state.
  updateStore: (store: StateStore | object) => void; // Function to update the store's state.
  clearStore: () => void; // Function to clear the store's state.
};

// Define a hook to use local storage for state management.
export const useLocalStorage = (): localStorageStore => {
  // Define a function to get the current state of the store from local storage.
  const getStore = useCallback((): StateStore => {
    const env = determineEnvironment(); // Determine the current environment.

    // Retrieve the serialized store data from local storage.
    const store = localStorage.getItem(`${env.toLowerCase()}/meshtrade-store`);

    // If the store is not defined, return an initial store state.
    if (!store) {
      return {
        userID: "",
        loggedIn: false,
        individualRegistrationInProgress: false,
        companyRegistrationInProgress: false,
        // TODO: this needs to change to be named a specific redirectURL so that the purpose of this is clearer.
        // This is only used for redirecting to an asset and this makes it look like it is used for general redirects
        // after login which is not the case.
        redirectURL: "",
        redirectAssetName: "",
        redirectAssetShortName: "",
        redirectToken: new Token(),
        smartInstrumentsSecret: "",
      } as StateStore;
    }
    // Parse the stored JSON string back into a StateStore object.
    return JSON.parse(store) as StateStore;
  }, []);

  // Define a function to update the current state of the store in local storage.
  const updateStore = useCallback((store: StateStore) => {
    const currentStore = getStore(); // Get the current state of the store.

    // Merge the current store state with the new state.
    const updatedStore = { ...currentStore, ...store };

    // Serialize and persist the updated store state in local storage.
    const env = determineEnvironment();
    localStorage.setItem(
      `${env.toLowerCase()}/meshtrade-store`,
      JSON.stringify(updatedStore as StateStore) as string,
    );
  }, []);

  // Define a function to clear the store's state in local storage.
  const clearStore = useCallback(() => {
    const env = determineEnvironment(); // Determine the current environment.

    // Reset the store to its initial state and serialize it to a JSON string.
    localStorage.setItem(
      `${env.toLowerCase()}/meshtrade-store`,
      JSON.stringify({
        userID: "",
        loggedIn: false,
        individualRegistrationInProgress: false,
        companyRegistrationInProgress: false,
        redirectURL: "",
        redirectAssetName: "",
        redirectAssetShortName: "",
        redirectToken: new Token(),
        smartInstrumentsSecret: "",
      } as StateStore) as string,
    );
  }, []);

  // Return the local storage store with the defined functions.
  return {
    getStore,
    updateStore,
    clearStore,
  } as localStorageStore;
};
