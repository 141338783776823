import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Model as AccountViewModel } from "james/views/stellarAccountView";
import { FinancialCurrencyStablecoinViewModel } from "james/views/financialCurrencyStablecoinView";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import {
  Close as CloseIcon,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { TextField, TextNumField } from "components/FormFields";
import { AccountDefundingFeeCalculator } from "james/remuneration/AccountDefundingFeeCalculator";
import { useSnackbar } from "notistack";
import { formatTextNum } from "utilities/number";
import {
  TextExactCriterion,
  TextSubstringCriterion,
} from "james/search/criterion/text";
import { useRead } from "james/views/financialCurrencyStablecoinView/Reader";
import { BankAccount } from "james/banking";
import { useCurrentAPICall, useIsMounted } from "hooks";
import { Amount } from "james/ledger";
import { FundAccountDialog } from "../FundAccountDialog/FundAccountDialog";

import {
  LinkedBankAccountDialog,
  LinkedBankAccountMode,
} from "../LinkedBankAccountDialog/LinkedBankAccountDialog";
import BigNumber from "bignumber.js";

import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import ReportProblemSharpIcon from "@mui/icons-material/ReportProblemSharp";
import { DefundOrderConfimationDialog } from "./components/DefundOrderConfirmationDialog";
import { useAccountContext } from "context/Account/Account";
import { useWindowSize } from "utilities/general";
import { LedgerIDIdentifier } from "james/search/identifier";
import { JSONRPCCallAbortedError } from "utilities/network/jsonRPCRequest";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { AccountsContext } from "../../Accounts";

interface DefundAccountProps {
  open: boolean;
  onClose: () => void;
  accountModel: AccountViewModel;
}

// This is to hide max defund element in the interim as the calculation for max defund isn't precise.
const showMaxDefund = false;

export function DefundAccountDialog(props: DefundAccountProps) {
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const isMounted = useIsMounted();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMountedCheckFunc = useIsMounted();
  const [showFundAccDialog, setShowFundAccDialog] = useState(false);
  const [expandFeeSection, setExpandFeeSection] = useState(false);
  const [expressDefund, setExpressDefund] = useState(false);
  const [defundAccountAck, setDefundAccountAck] = useState(false);
  const [displayLinkBankAccountDialog, setDisplayLinkBankAccountDialog] =
    useState(false);
  const [zarBalance, setZARBalance] = useState<Amount>(new Amount());
  const [zarTokenViewModel, setZARTokenViewModel] =
    useState<FinancialCurrencyStablecoinViewModel>(
      new FinancialCurrencyStablecoinViewModel(),
    );
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [defundAmount, setDefundAmount] = useState<Amount>(new Amount());
  const [defundFee, setDefundFee] = useState<Amount>(new Amount());
  const [feeCalculationLoading, setFeeCalculationLoading] = useState(false);
  const [bankAccount, setBankAccount] = useState(new BankAccount());
  const calculateDefundFeesTimeout = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );
  const [validationState, setValidationState] = useState<{
    [key: string]: string | undefined;
  }>({});
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { bankAccounts } = useContext(AccountsContext);
  const { readResponse, loading } = useRead({
    context: authContext,
    criteria: {
      "token.code": TextExactCriterion("mZAR"),
      issuer: TextSubstringCriterion("Mesh"),
    },
  });
  const [userIsASignatoryOnTradingAcc, setUserIsASignatoryOnTradingAcc] =
    useState(false);
  const { stellarAccountContext } = useAccountContext();

  useEffect(() => {
    (async () => {
      if (!isMountedCheckFunc()) {
        return;
      }

      if (stellarAccountContext.loading) {
        return;
      }

      if (stellarAccountContext.error) {
        console.error(`initialisation error::${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialisation Error: ${stellarAccountContext.error}`,
          {
            variant: "error",
          },
        );

        return;
      }

      // check if the user is an signatory on the account
      try {
        if (isMountedCheckFunc()) {
          setUserIsASignatoryOnTradingAcc(
            await stellarAccountContext.checkUserSignatoryOnAccount(
              LedgerIDIdentifier(props.accountModel.ledgerID),
            ),
          );
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user is signatory on account: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Determining Signatory Status", {
          variant: "error",
        });
      }
    })();
  }, [
    isMountedCheckFunc,
    stellarAccountContext.error,
    stellarAccountContext.loading,
    props.accountModel.id,
    enqueueSnackbar,
  ]);

  // find the mesh issued mZAR stablecoin
  useLayoutEffect(() => {
    let isMounted = true;
    props.accountModel.balances.forEach((v) => {
      if (
        v.tokenViewModel.token.code === "mZAR" &&
        v.tokenViewModel.issuer.includes("Mesh")
      ) {
        if (isMounted) {
          setZARBalance(v.availableBalance());
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [readResponse.models, props.accountModel.balances]);

  // find the financial currency stablecoin view model
  useLayoutEffect(() => {
    let isMounted = true;

    if (readResponse.models.length === 1) {
      if (isMounted) {
        setDefundAmount(readResponse.models[0].token.newAmountOf("0"));
        setZARTokenViewModel(readResponse.models[0]);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [readResponse.models]);

  const [
    isCurrentCalculateAccountDefundingFeesAPICall,
    initCalculateAccountDefundingFeesAPICall,
  ] = useCurrentAPICall();
  const calculateFee = (amount: Amount, isExpress: boolean) => {
    // indicate that fee calculation is in progress
    setFeeCalculationLoading(true);

    // initialise the API call
    const { apiCallID, abortController } =
      initCalculateAccountDefundingFeesAPICall();

    // set up new fee calculation to take place after debounce interval
    clearTimeout(calculateDefundFeesTimeout.current);
    calculateDefundFeesTimeout.current = setTimeout(async () => {
      try {
        const calculateDefundFeeResponse =
          await AccountDefundingFeeCalculator.CalculateAccountDefundingFees(
            {
              context: authContext,
              defundAmount: amount,
              express: isExpress,
            },
            { signal: abortController.signal },
          );
        if (
          isMounted() &&
          isCurrentCalculateAccountDefundingFeesAPICall(apiCallID)
        ) {
          setDefundFee(calculateDefundFeeResponse.feeAmount);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        if (err.code === JSONRPCCallAbortedError.ErrorCode) {
          return;
        }
        if (
          isMounted() &&
          isCurrentCalculateAccountDefundingFeesAPICall(apiCallID)
        ) {
          errorContextDefaultErrorFeedback(err);
        }
      }
      if (
        isMounted() &&
        isCurrentCalculateAccountDefundingFeesAPICall(apiCallID)
      ) {
        setFeeCalculationLoading(false);
      }
    }, 400);
  };

  // This useEffect listens for defund amount changes and triggers validation checks everytime that happens
  useEffect(() => {
    if (defundAmount.value.eq(0)) {
      return;
    }

    const newValidationState: { [key: string]: string | undefined } = {};

    if (defundAmount.value.plus(defundFee.value).gt(zarBalance.value)) {
      newValidationState.defundAmount = "Insufficient funds available";
    }

    if (defundAmount.value.lt(new BigNumber(100))) {
      newValidationState.defundAmount = "Minimum defund amount is 100 mZAR";
    }

    setValidationState(newValidationState);
  }, [defundAmount]);

  const performValidations = () => {
    const newValidationState: { [key: string]: string | undefined } = {};

    if (defundAmount.value.plus(defundFee.value).gt(zarBalance.value)) {
      newValidationState.defundAmount = "Insufficient funds available";
    }

    if (defundAmount.value.lt(new BigNumber(100))) {
      newValidationState.defundAmount = "Minimum defund amount is 100 mZAR";
    }

    if (bankAccount.id === "") {
      newValidationState.bankAccount = "Please select a bank account to pay to";
    }

    setValidationState(newValidationState);
    return !Object.keys(newValidationState).length;
  };

  const handleClearValidation = (field: string) => {
    setValidationState({
      ...validationState,
      [field]: undefined,
    });
  };

  return (
    <>
      <Dialog
        fullScreen
        open={props.open}
        PaperProps={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          "data-component-info": JSON.stringify({
            component_id: "defund_account",
            component_business_name: "Defund your account",
            component_title: "defund your account",
            component_driver: InteractionDriver.DefundAccount,
          } as DataComponentInfo),
        }}
      >
        <DialogTitle
          sx={(theme) => ({ backgroundColor: theme.palette.custom.spaceblue })}
        >
          <Grid
            sx={{
              alignItems: "center",
            }}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid
              item
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                alignItems: "center",
                gridColumnGap: theme.spacing(2),
              }}
            >
              <Box
                sx={{
                  height: 32,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="" width={40} src={meshMiniLogo} />
              </Box>
              <Typography
                variant="h5"
                children={isMobile ? "Defunding" : "Defunding Mesh mZAR"}
              />
            </Grid>
            <Grid item>
              <IconButton
                disabled={loading}
                size="small"
                id="defundAccount-close-button"
                onClick={props.onClose}
                data-link-info={JSON.stringify({
                  content_interaction_id: "defund-confirm",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_type: InteractionType.Button,
                  content_interaction_text: "close",
                  content_interaction_driver: InteractionDriver.DefundAccount,
                } as DataLinkInfoType)}
              >
                <CloseIcon
                  data-link-info={JSON.stringify({
                    content_interaction_id: "defund-confirm",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Button,
                    content_interaction_text: "close",
                    content_interaction_driver: InteractionDriver.DefundAccount,
                  } as DataLinkInfoType)}
                />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {(() => {
          if (!loading) {
            return (
              <>
                <DialogContent
                  sx={{
                    padding: {
                      // !important is being used because DialogContent-root and DialogTitle-root classes are being applied
                      // on this component, and they have a higher CSS specificity
                      sm: `${theme.spacing(5, 8, 5, 8)} !important`,
                      xs: `${theme.spacing(5, 3, 0, 4)} !important`,
                    },
                    backgroundColor: theme.palette.custom.midnight,
                    display: "flex",
                    justifyContent: "center",
                    overflowY: "auto",
                    boxShadow: "0 -10px 12px -14px #000 inset",
                  }}
                  className={"meshScroll"}
                >
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: theme.typography.fontWeightBold,
                        marginBottom: {
                          sm: theme.spacing(3),
                          xs: theme.spacing(2),
                        },
                      }}
                    >
                      Defund your account
                    </Typography>
                    {/* Amount numField */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <TextNumField
                        id="defundAccount-defundAmount-formfield"
                        sx={{
                          maxWidth: { sm: "304px" },
                          margin: theme.spacing(1, 0, 3, 0),
                        }}
                        fullWidth
                        disallowNegative
                        label="Amount (Min 100 mZAR)"
                        variant="outlined"
                        value={defundAmount.value}
                        disabled={loading || defundAmount.isUndefined()}
                        error={!!validationState.defundAmount}
                        FormHelperTextProps={{
                          sx: {
                            paddingRight: 0,
                            marginRight: 0,
                          },
                        }}
                        helperText={
                          validationState.defundAmount
                            ? validationState.defundAmount
                            : `Available Balance: mZAR ${formatTextNum(
                                zarBalance.value,
                                { addDecimalPadding: true },
                              )} `
                        }
                        onChange={(e) => {
                          setDefundAmount(
                            defundAmount.setValue(e.target.value),
                          );
                          handleClearValidation("defundAmount");
                          calculateFee(
                            defundAmount.setValue(e.target.value),
                            expressDefund,
                          );
                        }}
                        InputProps={{
                          startAdornment: (
                            <Tooltip
                              title={`Issued by ${zarTokenViewModel.issuer}`}
                              placement="top"
                            >
                              <Typography
                                variant="body1"
                                sx={(theme) => ({
                                  marginRight: 2,
                                  color: theme.palette.text.secondary,
                                  cursor: "pointer",
                                  "&:hover": {
                                    color: theme.palette.primary.light,
                                  },
                                })}
                                children={zarTokenViewModel.token.code}
                              />
                            </Tooltip>
                          ),
                          endAdornment: (
                            <div>
                              {showMaxDefund && (
                                <Typography
                                  id="fundAccount-max-typography"
                                  onClick={() =>
                                    setDefundAmount(
                                      defundAmount.setValue(zarBalance.value),
                                    )
                                  }
                                  sx={(theme) =>
                                    isMobile
                                      ? {
                                          paddingTop: theme.spacing(1),
                                          paddingBottom: theme.spacing(1),
                                        }
                                      : {}
                                  }
                                  color="secondary"
                                >
                                  MAX
                                </Typography>
                              )}
                            </div>
                          ),
                        }}
                      />
                      {showMaxDefund && (
                        <Tooltip
                          title={
                            "Maximum amount will be calculated based on available balance, subtracting the Mesh Defunding fee."
                          }
                          placement={isMobile ? "left" : "right"}
                          PopperProps={{
                            style: {
                              width: isMobile ? "260px" : "320px",
                            },
                          }}
                        >
                          <ErrorRoundedIcon
                            sx={{
                              marginTop: theme.spacing(2),
                              color: theme.palette.secondary.light,
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    {/* Pay To */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: { sm: "center" },
                      }}
                    >
                      <Autocomplete
                        id="defundAccount-bankAccount-button"
                        sx={{
                          maxWidth: { sm: "304px" },
                          paddingBottom: theme.spacing(1),
                        }}
                        fullWidth
                        disabled={loading || defundAmount.isUndefined()}
                        options={bankAccounts ? bankAccounts : []}
                        isOptionEqualToValue={(option, value) => {
                          if (value.bankName === "") {
                            return true;
                          }
                          return option.bankName === value.bankName;
                        }}
                        onChange={(__, value: BankAccount | null) => {
                          if (value) {
                            handleClearValidation("bankAccount");
                            setBankAccount(value);
                          }
                        }}
                        getOptionLabel={(option) => {
                          if (!option.bankName) {
                            return "";
                          }
                          return `${
                            option.bankName
                          } ... ${option.number.substring(
                            option.number.length - 4,
                            option.number.length,
                          )}`;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Pay to:"
                            variant="outlined"
                            placeholder="Select Account"
                            disabled={loading || defundAmount.isUndefined()}
                            margin="dense"
                            InputLabelProps={{ shrink: true }}
                            helperText={validationState.bankAccount}
                            error={!!validationState.bankAccount}
                          />
                        )}
                      />
                      {!bankAccounts && (
                        <CircularProgress
                          sx={{
                            marginLeft: theme.spacing(2),
                          }}
                          size={20}
                        />
                      )}
                      {!isMobile && (
                        <Box
                          sx={[
                            {
                              display: "flex",
                              alignItems: "center",
                              marginLeft: { sm: theme.spacing(3) },
                            },
                          ]}
                        >
                          <Checkbox
                            disabled={loading || defundAmount.isUndefined()}
                            checked={expressDefund}
                            onChange={() => {
                              setExpressDefund(!expressDefund);

                              // recalculate the defund fee when the checkbox changes
                              calculateFee(defundAmount, !expressDefund);
                            }}
                            inputProps={{
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              "data-link-info": JSON.stringify({
                                content_interaction_id: "defund-information",
                                content_interaction_action:
                                  InteractionAction.Click,
                                content_interaction_type:
                                  InteractionType.Checkbox,
                                content_interaction_text: "express defund",
                                content_interaction_driver:
                                  InteractionDriver.DefundExpress,
                              } as DataLinkInfoType),
                            }}
                          />
                          <Typography>
                            Express Defund (Additional fee)
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Typography
                      component="div"
                      variant="caption"
                      children="Can’t find the account you’re looking for?"
                      sx={{
                        paddingLeft: {
                          xs: theme.spacing(2),
                          sm: theme.spacing(0),
                        },
                      }}
                    />

                    {isMobile ? (
                      <Button
                        onClick={() =>
                          setDisplayLinkBankAccountDialog(
                            !displayLinkBankAccountDialog,
                          )
                        }
                        color="secondary"
                        size={"large"}
                        sx={{
                          height: "48px",
                          padding: theme.spacing(0, 2, 0, 2),
                        }}
                        variant={"text"}
                        children={"Link a new account"}
                      />
                    ) : (
                      <Link
                        sx={{
                          cursor: "pointer",
                        }}
                        variant={"caption"}
                        color="secondary"
                        underline={"hover"}
                        onClick={() =>
                          setDisplayLinkBankAccountDialog(
                            !displayLinkBankAccountDialog,
                          )
                        }
                      >
                        Link a new account
                      </Link>
                    )}

                    {isMobile && (
                      <Box
                        sx={[
                          {
                            display: "flex",
                            alignItems: "center",
                            margin: theme.spacing(2, 0, 2, 0),
                          },
                        ]}
                      >
                        <Checkbox
                          disabled={loading || defundAmount.isUndefined()}
                          checked={expressDefund}
                          inputProps={{
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            "data-link-info": JSON.stringify({
                              content_interaction_id: "defund-information",
                              content_interaction_action:
                                InteractionAction.Click,
                              content_interaction_type:
                                InteractionType.Checkbox,
                              content_interaction_text: "express defund",
                              content_interaction_driver:
                                InteractionDriver.DefundExpress,
                            } as DataLinkInfoType),
                          }}
                          onChange={() => {
                            setExpressDefund(!expressDefund);

                            // recalculate the defund fee when the checkbox changes
                            calculateFee(defundAmount, !expressDefund);
                          }}
                        />
                        <Typography>Express Defund (Additional fee)</Typography>
                      </Box>
                    )}

                    {/* Please Note Section */}
                    <Box
                      sx={{
                        margin: theme.spacing(3, 0, 2, 0),
                        maxWidth: "500px",
                      }}
                    >
                      <Typography
                        sx={(theme) => ({ marginBottom: theme.spacing(1) })}
                        variant="h6"
                        children="Please note"
                      />
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        children={
                          "Mesh will make the payment to your linked bank account using the funding reference. " +
                          "Standard payments may take up to 2 business days to reflect. Express defunding will" +
                          " settle same day, but there is an additional charge."
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        sx={(theme) => ({ margin: theme.spacing(0, 0, 3, 0) })}
                        control={
                          <Checkbox
                            disabled={loading || defundAmount.isUndefined()}
                            checked={defundAccountAck}
                            onClick={() =>
                              setDefundAccountAck(!defundAccountAck)
                            }
                            name="checkedB"
                            color="primary"
                            id="defundAccount-ack-checkbox"
                            inputProps={{
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              "data-link-info": JSON.stringify({
                                content_interaction_id:
                                  "defund-account-accept-tnc",
                                content_interaction_action:
                                  InteractionAction.Click,
                                content_interaction_type:
                                  InteractionType.Checkbox,
                                content_interaction_text: "accept tnc",
                                content_interaction_driver:
                                  InteractionDriver.DefundAcceptTerms,
                              } as DataLinkInfoType),
                            }}
                          />
                        }
                        label="I acknowledge"
                      />
                    </Box>

                    {/* Defund Button for desktop and tablet*/}
                    {!isMobile && (
                      <Box
                        sx={{
                          display: "flex",
                          marginBottom: theme.spacing(4),
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          placement="top"
                          title={
                            userIsASignatoryOnTradingAcc
                              ? ""
                              : "You are not a Signatory on this Account"
                          }
                        >
                          {/* Defund Button */}
                          <span
                            data-link-info={JSON.stringify({
                              content_interaction_id: "defund-information",
                              content_interaction_action:
                                InteractionAction.Click,
                              content_interaction_type: InteractionType.Button,
                              content_interaction_text: "defund",
                              content_interaction_driver:
                                InteractionDriver.DefundAccount,
                            } as DataLinkInfoType)}
                          >
                            <Button
                              id="defundAccount-defund-button"
                              sx={{
                                height: "36px",
                                marginRight: theme.spacing(1),
                              }}
                              component="div"
                              data-link-info={JSON.stringify({
                                content_interaction_id: "defund-information",
                                content_interaction_action:
                                  InteractionAction.Click,
                                content_interaction_type:
                                  InteractionType.Button,
                                content_interaction_text: "defund",
                                content_interaction_driver:
                                  InteractionDriver.DefundAccount,
                              } as DataLinkInfoType)}
                              variant="contained"
                              color="primary"
                              size="large"
                              disabled={
                                !defundAccountAck ||
                                loading ||
                                !userIsASignatoryOnTradingAcc ||
                                defundAmount.isUndefined()
                              }
                              children="defund"
                              onClick={() => {
                                // do not open the confirmation dialog if validations are failing
                                if (!performValidations()) {
                                  return;
                                }

                                setOpenConfirmationDialog(
                                  !openConfirmationDialog,
                                );
                              }}
                            />
                          </span>
                        </Tooltip>
                        {loading && <CircularProgress size={20} />}
                      </Box>
                    )}

                    {/* Defund Fee collapse section */}
                    <div>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          id="defundAccount-expand-button"
                          disabled={
                            feeCalculationLoading ||
                            loading ||
                            defundAmount.isUndefined()
                          }
                          onClick={() => setExpandFeeSection(!expandFeeSection)}
                          size="small"
                          data-link-info={JSON.stringify({
                            content_interaction_id: "defund-information",
                            content_interaction_action: InteractionAction.Click,
                            content_interaction_type: InteractionType.Icon,
                            content_interaction_text: "defund fee",
                            content_interaction_driver:
                              InteractionDriver.DefundFeesExplore,
                          } as DataLinkInfoType)}
                        >
                          {!expandFeeSection && (
                            <KeyboardArrowDown
                              data-link-info={JSON.stringify({
                                content_interaction_id: "defund-information",
                                content_interaction_action:
                                  InteractionAction.Click,
                                content_interaction_type: InteractionType.Icon,
                                content_interaction_text: "defund fee",
                                content_interaction_driver:
                                  InteractionDriver.DefundFeesExplore,
                              } as DataLinkInfoType)}
                              color="primary"
                            />
                          )}
                          {expandFeeSection && (
                            <KeyboardArrowUp
                              data-link-info={JSON.stringify({
                                content_interaction_id: "defund-information",
                                content_interaction_action:
                                  InteractionAction.Click,
                                content_interaction_type: InteractionType.Icon,
                                content_interaction_text: "defund fee",
                                content_interaction_driver:
                                  InteractionDriver.DefundFeesExplore,
                              } as DataLinkInfoType)}
                              color="primary"
                            />
                          )}
                        </IconButton>
                        <Typography
                          sx={{
                            marginLeft: theme.spacing(0.5),
                            marginRight: theme.spacing(1),
                          }}
                          color="textSecondary"
                          variant="body1"
                          children="Defund Fee:"
                        />
                        <Tooltip
                          title={`Issued by ${zarTokenViewModel.issuer}`}
                          placement="top-start"
                        >
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              sx={(theme) => ({
                                cursor: "pointer",
                                "&:hover": {
                                  color: theme.palette.primary.light,
                                },
                                margin: theme.spacing(0, 1, 0, 1),
                              })}
                              variant="body1"
                              children={zarTokenViewModel.token.code}
                              color={
                                validationState.defundAmount?.includes(
                                  "Insufficient",
                                )
                                  ? "error"
                                  : ""
                              }
                            />
                          </Box>
                        </Tooltip>
                        <Typography
                          variant="body1"
                          color={
                            validationState.defundAmount?.includes(
                              "Insufficient",
                            )
                              ? "error"
                              : ""
                          }
                          children={formatTextNum(defundFee.value, {
                            addDecimalPadding: true,
                          })}
                        />
                        {validationState.defundAmount?.includes(
                          "Insufficient",
                        ) && (
                          <Tooltip
                            sx={(theme) => ({ marginLeft: theme.spacing(1) })}
                            title={validationState.defundAmount}
                            placement={isMobile ? "top" : "right"}
                            children={<ReportProblemSharpIcon color="error" />}
                          />
                        )}
                        {feeCalculationLoading && (
                          <CircularProgress size={20} />
                        )}
                      </Box>
                      <Collapse timeout={200} in={expandFeeSection}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            margin: theme.spacing(0, 0, 1, 5),
                          }}
                        >
                          {isMobile ? (
                            <Button
                              href={"https://mesh.trade/fees"}
                              color="secondary"
                              children={"why these fees?"}
                              sx={{
                                height: "48px",
                                display: "flex",
                                padding: theme.spacing(0),
                                justifyContent: "flex-start",
                                margin: theme.spacing(1, 2, 2, 0),
                              }}
                              variant={"text"}
                              size={"large"}
                              fullWidth={false}
                              data-link-info={JSON.stringify({
                                content_interaction_id: "defund-information",
                                content_interaction_action:
                                  InteractionAction.Click,
                                content_interaction_type:
                                  InteractionType.Button,
                                content_interaction_text: "why these fees?",
                                content_interaction_driver:
                                  InteractionDriver.DefundFeesExplore,
                              } as DataLinkInfoType)}
                            />
                          ) : (
                            <Link
                              sx={{
                                cursor: "pointer",
                                marginBottom: theme.spacing(2),
                              }}
                              data-link-info={JSON.stringify({
                                content_interaction_id: "defund-information",
                                content_interaction_action:
                                  InteractionAction.Click,
                                content_interaction_type: InteractionType.Link,
                                content_interaction_text: "why these fees?",
                                content_interaction_driver:
                                  InteractionDriver.DefundFeesExplore,
                              } as DataLinkInfoType)}
                              variant={"body1"}
                              color="secondary"
                              target="_blank"
                              underline={"hover"}
                              href="https://mesh.trade/fees"
                              children={"Why these fees?"}
                            />
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography
                              noWrap
                              variant="body1"
                              color="textSecondary"
                              sx={{
                                marginRight: theme.spacing(2),
                              }}
                            >
                              Fee Acc:
                            </Typography>
                            <Typography noWrap variant="body1" component="span">
                              {`${props.accountModel.accountOwnerGroupName} ${props.accountModel.category}`}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                            }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="body1"
                              children="Available"
                            />
                            <Tooltip
                              title={`Issued by ${zarTokenViewModel.issuer}`}
                              placement="top"
                            >
                              <Typography
                                color={
                                  validationState.defundAmount?.includes(
                                    "Insufficient",
                                  )
                                    ? "error"
                                    : ""
                                }
                                sx={(theme) => ({
                                  margin: theme.spacing(0, 1, 0, 1),
                                  cursor: "pointer",
                                  "&:hover": {
                                    color: theme.palette.primary.light,
                                  },
                                })}
                                variant="body1"
                                children={zarTokenViewModel.token.code}
                              />
                            </Tooltip>
                            <Typography
                              variant="body1"
                              color={
                                validationState.defundAmount?.includes(
                                  "Insufficient",
                                )
                                  ? "error"
                                  : ""
                              }
                              children={`${formatTextNum(zarBalance.value, {
                                addDecimalPadding: true,
                              })} ${
                                !isMobile &&
                                validationState.defundAmount?.includes(
                                  "Insufficient",
                                )
                                  ? " - Insufficient Funds"
                                  : ""
                              }`}
                            />
                          </Box>
                          {isMobile &&
                            validationState.defundAmount?.includes(
                              "Insufficient",
                            ) && (
                              <Typography
                                sx={(theme) => ({
                                  marginLeft: theme.spacing(10),
                                })}
                                color={"error"}
                                children={"- Insufficient Funds"}
                              />
                            )}
                          {validationState.defundAmount?.includes(
                            "Insufficient",
                          ) && (
                            <Button
                              id="fundAccount-fund-button"
                              sx={{
                                height: {
                                  xs: "48px",
                                  sm: "36px",
                                },
                                width: "162px",
                                marginTop: theme.spacing(2),
                                marginBottom: theme.spacing(2),
                              }}
                              component="div"
                              variant="contained"
                              color="secondary"
                              size="medium"
                              disabled={loading}
                              children="fund account"
                              onClick={() =>
                                setShowFundAccDialog(!showFundAccDialog)
                              }
                            />
                          )}
                        </Box>
                      </Collapse>
                    </div>
                  </Box>
                </DialogContent>
                {isMobile && (
                  <DialogTitle sx={{ padding: 0 }}>
                    <Box
                      sx={{
                        margin: theme.spacing(3, 3, 5, 3),
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={
                          userIsASignatoryOnTradingAcc
                            ? ""
                            : "You are not a Signatory on this Account"
                        }
                      >
                        <Box
                          component={"span"}
                          data-link-info={JSON.stringify({
                            content_interaction_id: "defund-information",
                            content_interaction_action: InteractionAction.Click,
                            content_interaction_type: InteractionType.Button,
                            content_interaction_text: "defund",
                            content_interaction_driver:
                              InteractionDriver.DefundAccount,
                          } as DataLinkInfoType)}
                        >
                          <Button
                            id="defundAccount-defund-button"
                            sx={[
                              { height: "48px" },
                              loading && { marginRight: theme.spacing(1) },
                            ]}
                            component="div"
                            data-link-info={JSON.stringify({
                              content_interaction_id: "defund-information",
                              content_interaction_action:
                                InteractionAction.Click,
                              content_interaction_type: InteractionType.Button,
                              content_interaction_text: "defund",
                              content_interaction_driver:
                                InteractionDriver.DefundAccount,
                            } as DataLinkInfoType)}
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={
                              !defundAccountAck ||
                              loading ||
                              !userIsASignatoryOnTradingAcc ||
                              defundAmount.isUndefined()
                            }
                            children="defund"
                            onClick={() => {
                              // do not open the confirmation dialog if validations are failing
                              if (!performValidations()) {
                                return;
                              }

                              setOpenConfirmationDialog(
                                !openConfirmationDialog,
                              );
                            }}
                            fullWidth
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                    {loading && (
                      <CircularProgress
                        size={25}
                        sx={{
                          margin: theme.spacing(0, 3, 1, 0),
                        }}
                      />
                    )}
                  </DialogTitle>
                )}
              </>
            );
          }
          return (
            <>
              {isMobile ? (
                <DefundAccountDialogLoader />
              ) : (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <DefundAccountDialogLoader />
                </Box>
              )}
            </>
          );
        })()}
      </Dialog>
      {displayLinkBankAccountDialog && (
        <LinkedBankAccountDialog
          open={displayLinkBankAccountDialog}
          mode={LinkedBankAccountMode.Create}
          onClose={() => {
            setDisplayLinkBankAccountDialog(!displayLinkBankAccountDialog);
          }}
          ownerID={props.accountModel.ownerID}
        />
      )}
      {showFundAccDialog && (
        <FundAccountDialog
          onClose={() => setShowFundAccDialog(!showFundAccDialog)}
          open={showFundAccDialog}
          accountID={props.accountModel.id}
        />
      )}
      {openConfirmationDialog && (
        <DefundOrderConfimationDialog
          open={openConfirmationDialog}
          onClose={() => setOpenConfirmationDialog(false)}
          defundAmount={defundAmount}
          bankAccount={bankAccount}
          expressDefund={expressDefund}
          accountNumber={props.accountModel.number}
          accountID={props.accountModel.id}
          onDefundOrderSubmissionComplete={props.onClose}
        />
      )}
    </>
  );
}

export function DefundAccountDialogLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [, windowHeight] = useWindowSize();

  if (isMobile) {
    return (
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          height: windowHeight - 50,
          margin: theme.spacing(2),
        })}
      >
        <Box
          sx={{
            height: "100%",
          }}
        >
          <Skeleton
            sx={(theme) => ({
              transform: "none",
              marginBottom: theme.spacing(3),
            })}
            component={"div"}
            width={"75%"}
            height={24}
          />
          <Skeleton
            sx={(theme) => ({
              transform: "none",
              marginBottom: theme.spacing(6),
            })}
            component={"div"}
            width={"100%"}
            height={48}
          />
          <Skeleton
            sx={(theme) => ({
              transform: "none",
              marginBottom: theme.spacing(1),
            })}
            component={"div"}
            height={48}
          />
          <Skeleton
            sx={(theme) => ({
              transform: "none",
              marginLeft: theme.spacing(2),
              marginBottom: theme.spacing(1),
            })}
            component={"div"}
            height={14}
          />
          <Skeleton
            sx={(theme) => ({
              transform: "none",
              marginLeft: theme.spacing(2),
              marginBottom: theme.spacing(5.4),
            })}
            component={"div"}
            width={"61%"}
            height={24}
          />
          <Skeleton
            sx={(theme) => ({
              transform: "none",
              marginLeft: theme.spacing(2),
              marginBottom: theme.spacing(5.4),
            })}
            component={"div"}
            height={14}
          />
          <Skeleton
            sx={(theme) => ({
              transform: "none",
              marginBottom: theme.spacing(2.5),
            })}
            component={"div"}
            width={"42%"}
            height={18}
          />
          <Skeleton
            sx={(theme) => ({
              transform: "none",
              marginBottom: theme.spacing(0.5),
            })}
            component={"div"}
            width={"100%"}
            height={14}
          />
          <Skeleton
            sx={(theme) => ({
              transform: "none",
              marginBottom: theme.spacing(0.5),
            })}
            component={"div"}
            width={"100%"}
            height={14}
          />
          <Skeleton
            sx={(theme) => ({
              transform: "none",
              marginBottom: theme.spacing(4),
            })}
            component={"div"}
            width={"91%"}
            height={14}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: "center",
            top: "-40px",
          }}
        >
          <Skeleton
            sx={{
              transform: "none",
              maxWidth: "312px",
            }}
            component={"div"}
            width={"100%"}
            height={48}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.spacing(5),
        width: "500px",
      })}
    >
      <Skeleton
        sx={(theme) => ({
          transform: "none",
          maxWidth: "232px",
          minWidth: "116px",
          marginBottom: theme.spacing(3),
        })}
        component={"div"}
        width={"100%"}
        height={24}
      />
      <Skeleton
        sx={(theme) => ({
          transform: "none",
          maxWidth: "309px",
          minWidth: "154px",
          marginBottom: theme.spacing(6),
        })}
        component={"div"}
        width={"100%"}
        height={48}
      />
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          marginRight: theme.spacing(5),
          marginBottom: theme.spacing(1.6),
        })}
      >
        <Skeleton
          sx={(theme) => ({
            transform: "none",
            maxWidth: "309px",
            minWidth: "154px",
            marginRight: theme.spacing(5),
          })}
          component={"div"}
          width={"100%"}
          height={48}
        />
        <Skeleton
          sx={{
            transform: "none",
            maxWidth: "309px",
            minWidth: "154px",
          }}
          component={"div"}
          width={"100%"}
          height={14}
        />
      </Box>
      <Skeleton
        sx={(theme) => ({
          transform: "none",
          maxWidth: "309px",
          minWidth: "154px",
          marginBottom: theme.spacing(0.5),
        })}
        component={"div"}
        width={"100%"}
        height={14}
      />
      <Skeleton
        sx={(theme) => ({
          transform: "none",
          maxWidth: "128px",
          minWidth: "64px",
          marginBottom: theme.spacing(3),
        })}
        component={"div"}
        width={"100%"}
        height={14}
      />
      <Skeleton
        sx={(theme) => ({
          transform: "none",
          maxWidth: "128px",
          minWidth: "64px",
          marginBottom: theme.spacing(2.5),
        })}
        component={"div"}
        width={"100%"}
        height={18}
      />

      <Skeleton
        sx={(theme) => ({
          transform: "none",
          maxWidth: "516px",
          minWidth: "64px",
          marginBottom: theme.spacing(0.5),
        })}
        component={"div"}
        width={"100%"}
        height={14}
      />
      <Skeleton
        sx={(theme) => ({
          transform: "none",
          maxWidth: "516px",
          minWidth: "64px",
          marginBottom: theme.spacing(0.5),
        })}
        component={"div"}
        width={"100%"}
        height={14}
      />
      <Skeleton
        sx={(theme) => ({
          transform: "none",
          maxWidth: "516px",
          minWidth: "64px",
          marginBottom: theme.spacing(0.5),
        })}
        component={"div"}
        width={"100%"}
        height={14}
      />
      <Skeleton
        sx={(theme) => ({
          transform: "none",
          maxWidth: "181px",
          minWidth: "64px",
          marginBottom: theme.spacing(4.5),
        })}
        component={"div"}
        width={"100%"}
        height={14}
      />
      <Skeleton
        sx={(theme) => ({
          transform: "none",
          maxWidth: "181px",
          minWidth: "64px",
          marginBottom: theme.spacing(4.5),
        })}
        component={"div"}
        width={"100%"}
        height={14}
      />

      <Skeleton
        sx={(theme) => ({
          transform: "none",
          maxWidth: "110px",
          minWidth: "55px",
          marginBottom: theme.spacing(4),
        })}
        component={"div"}
        width={"100%"}
        height={36}
      />
      <Skeleton
        sx={(theme) => ({
          transform: "none",
          maxWidth: "244px",
          minWidth: "122px",
          marginBottom: theme.spacing(4),
        })}
        component={"div"}
        width={"100%"}
        height={14}
      />
    </Box>
  );
}
