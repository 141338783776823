import React from "react";
import { NonPerpetualScheduleConfiguration } from "@mesh/common-js/dist/financial/scheduleConfigurationNonPerpetual_pb";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
} from "@mui/material";
import { DateTimeField } from "@mesh/common-js-react/dist/FormFields";
import dayjs from "dayjs";
import {
  allBusinessDayConventions,
  allCalendars,
  allDateGenerationRules,
  allFrequencys,
  businessDayConventionToString,
  calendarToString,
  dateGenerationRuleToString,
  frequencyToString,
} from "@mesh/common-js/dist/financial";
import { Calendar } from "@mesh/common-js/dist/financial/calendar_pb";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ValidationResult } from "common/validation";
import { Frequency } from "@mesh/common-js/dist/financial/frequency_pb";
import { TextField } from "components/FormFields";
dayjs.extend(utc);
dayjs.extend(timezone);

export type NonPerpetualScheduleConfigurationFormProps = {
  disabled: boolean;
  readOnly: boolean;
  nonPerpetualScheduleConfiguration: NonPerpetualScheduleConfiguration;
  onChange: (
    updatedScheduleConfiguration: NonPerpetualScheduleConfiguration,
  ) => void;
  formDataValidationResult: ValidationResult;
  validationResultFieldPrefix: string;
};

export const NonPerpetualScheduleConfigurationForm = (
  props: NonPerpetualScheduleConfigurationFormProps,
) => {
  const getFieldValidation = (field: string) => {
    return props.formDataValidationResult.fieldValidations[
      `${props.validationResultFieldPrefix}-${field}`
    ];
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexWrap: "wrap",
        columnGap: theme.spacing(1),
        padding: theme.spacing(0.5, 2),
        alignItems: "center",
      })}
    >
      <DateTimeField
        id="smartInstrument-scheduleConfiguration-nonPerpetual-startDate-dateTimeField"
        sx={{ maxWidth: 320, width: "100%" }}
        disabled={props.disabled}
        readOnly={props.readOnly}
        label={"Start Date"}
        value={props.nonPerpetualScheduleConfiguration.getStartdate()}
        onChange={(newValue) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setStartdate(newValue),
          )
        }
        error={!!getFieldValidation("startDate")}
        helperText={getFieldValidation("startDate")}
      />
      <DateTimeField
        id="smartInstrument-scheduleConfiguration-nonPerpetual-firstScheduledDate-dateTimeField"
        sx={{ maxWidth: 320, width: "100%" }}
        disabled={props.disabled}
        readOnly={props.readOnly}
        label={"First Scheduled Date"}
        nullable
        value={props.nonPerpetualScheduleConfiguration.getFirstscheduleddate()}
        onChange={(newValue) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setFirstscheduleddate(
              newValue,
            ),
          )
        }
        error={!!getFieldValidation("firstScheduledDate")}
        helperText={getFieldValidation("firstScheduledDate")}
      />
      <DateTimeField
        id="smartInstrument-scheduleConfiguration-nonPerpetual-secondToLastScheduledDate-dateTimeField"
        sx={{ maxWidth: 320, width: "100%" }}
        disabled={props.disabled}
        readOnly={props.readOnly}
        label={"Second to Last Scheduled Date"}
        nullable
        value={props.nonPerpetualScheduleConfiguration.getSecondtolastscheduleddate()}
        onChange={(newValue) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setSecondtolastscheduleddate(
              newValue,
            ),
          )
        }
        error={!!getFieldValidation("secondToLastScheduledDate")}
        helperText={getFieldValidation("secondToLastScheduledDate")}
      />
      <DateTimeField
        id="smartInstrument-scheduleConfiguration-nonPerpetual-endDate-dateTimeField"
        sx={{ maxWidth: 320, width: "100%" }}
        disabled={props.disabled}
        readOnly={props.readOnly}
        label={"End Date"}
        value={props.nonPerpetualScheduleConfiguration.getEnddate()}
        onChange={(newValue) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setEnddate(newValue),
          )
        }
        error={!!getFieldValidation("endDate")}
        helperText={getFieldValidation("endDate")}
      />
      <TextField
        fullWidth
        sx={{ maxWidth: 320 }}
        id="smartInstrument-scheduleConfiguration-nonPerpetual-frequency-selectField"
        disabled={props.disabled}
        readOnly={props.readOnly}
        label="Frequency"
        select
        value={props.nonPerpetualScheduleConfiguration.getFrequency()}
        onChange={(e) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setFrequency(
              Number(e.target.value),
            ),
          )
        }
        error={!!getFieldValidation("frequency")}
        helperText={getFieldValidation("frequency")}
      >
        {allFrequencys.map((v: Frequency) => {
          return (
            <MenuItem key={v} value={v}>
              {frequencyToString(v)}
            </MenuItem>
          );
        })}
      </TextField>
      <Autocomplete
        multiple
        fullWidth
        disabled={props.disabled}
        readOnly={props.readOnly}
        sx={{ maxWidth: 320 }}
        id="smartInstrument-scheduleConfiguration-nonPerpetual-calendars-selectField"
        options={allCalendars.filter((c) => c !== Calendar.UNDEFINED_CALENDAR)}
        getOptionLabel={(option: Calendar) => calendarToString(option)}
        filterSelectedOptions
        ChipProps={{ size: "small" }}
        value={props.nonPerpetualScheduleConfiguration.getCalendarsList()}
        onChange={(_, value: Calendar[]) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setCalendarsList(value),
          )
        }
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={props.disabled}
            readOnly={props.readOnly}
            label="Calendars"
            placeholder="Select..."
            error={!!getFieldValidation("calendars")}
            helperText={getFieldValidation("calendars")}
          />
        )}
      />
      <TextField
        fullWidth
        sx={{ maxWidth: 320 }}
        id="smartInstrument-scheduleConfiguration-nonPerpetual-businessDayConvention-selectField"
        disabled={props.disabled}
        readOnly={props.readOnly}
        label="Business Day Convention"
        select
        value={props.nonPerpetualScheduleConfiguration.getBusinessdayconvention()}
        onChange={(e) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setBusinessdayconvention(
              Number(e.target.value),
            ),
          )
        }
        error={!!getFieldValidation("businessDayConvention")}
        helperText={getFieldValidation("businessDayConvention")}
      >
        {allBusinessDayConventions.map((v) => {
          return (
            <MenuItem key={v} value={v}>
              {businessDayConventionToString(v)}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        fullWidth
        sx={{ maxWidth: 320 }}
        id="smartInstrument-scheduleConfiguration-nonPerpetual-endDateBusinessDayConvention-selectField"
        disabled={props.disabled}
        readOnly={props.readOnly}
        label="End Date Business Day Convention"
        select
        value={props.nonPerpetualScheduleConfiguration.getEnddatebusinessdayconvention()}
        onChange={(e) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setEnddatebusinessdayconvention(
              Number(e.target.value),
            ),
          )
        }
        error={!!getFieldValidation("endDateBusinessDayConvention")}
        helperText={getFieldValidation("endDateBusinessDayConvention")}
      >
        {allBusinessDayConventions.map((v) => {
          return (
            <MenuItem key={v} value={v}>
              {businessDayConventionToString(v)}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        fullWidth
        sx={{ maxWidth: 320 }}
        id="smartInstrument-scheduleConfiguration-nonPerpetual-dateGenerationRule-selectField"
        disabled={props.disabled}
        readOnly={props.readOnly}
        label="Date Generation Rule"
        select
        value={props.nonPerpetualScheduleConfiguration.getDategenerationrule()}
        onChange={(e) =>
          props.onChange(
            props.nonPerpetualScheduleConfiguration.setDategenerationrule(
              Number(e.target.value),
            ),
          )
        }
        error={!!getFieldValidation("dateGenerationRule")}
        helperText={getFieldValidation("dateGenerationRule")}
      >
        {allDateGenerationRules.map((v) => {
          return (
            <MenuItem key={v} value={v}>
              {dateGenerationRuleToString(v)}
            </MenuItem>
          );
        })}
      </TextField>
      <Box
        className="checkboxes"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          width: "100%",
          maxWidth: 322,
        }}
      >
        <FormControlLabel
          sx={{ width: "100%" }}
          id="smartInstrument-scheduleConfiguration-nonPerpetual-endOfMonthConvention-checkBox"
          disabled={props.disabled}
          control={
            <Checkbox
              disableFocusRipple={props.readOnly}
              disableTouchRipple={props.readOnly}
            />
          }
          label="End of Month Convention"
          checked={props.nonPerpetualScheduleConfiguration.getEndofmonthconvention()}
          onChange={(_, checked) => {
            if (props.readOnly) {
              return;
            }
            props.onChange(
              props.nonPerpetualScheduleConfiguration.setEndofmonthconvention(
                checked,
              ),
            );
          }}
        />
      </Box>
    </Box>
  );
};
