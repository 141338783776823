import { Amount } from "james/ledger";
import { ValidationResult } from "common/validation";
import { AccountInspector } from "james/stellar";
import { Context } from "james/security";
import { LedgerIDIdentifier, NumberIdentifier } from "james/search/identifier";
import { Model as StellarAccountViewModel } from "james/views/stellarAccountView";
import { Fee } from "james/remuneration/Fee";
import { TransferAccountType } from "./TransferMainDialog";
import BigNumber from "bignumber.js";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { ManagingCompanyClientName } from "const";

export type State = {
  userID: string;
  transferAmount: Amount;
  accountType: string;
  accountID: string;
  reference: string;
  referenceCheckbox: boolean;
  transferTokenAvailableBalance: Amount;
  mZARBalance: Amount;
  transferFees: Fee[] | undefined;
  ledgerAccountModel: StellarAccountViewModel;
  tokenCategory: TokenCategory | "";
};

export type FormUpdaterSpecsType = {
  userID: (value: string, prevState?: State) => State;
  accountID: (value: string, prevState?: State) => State;
  accountType: (value: string, prevState?: State) => State;
  transferTokenAvailableBalance: (value: Amount, prevState?: State) => State;
  mZARBalance: (value: Amount, prevState?: State) => State;
  reference: (value: string, prevState?: State) => State;
  referenceCheckbox: (value: boolean, prevState?: State) => State;
  transferAmount: (value: Amount, prevState?: State) => State;
  transferFees: (value: Fee[] | undefined, prevState?: State) => State;
  tokenCategory: (value: TokenCategory | "", prevState?: State) => State;
  ledgerAccountModel: (
    value: StellarAccountViewModel,
    prevState?: State,
  ) => State;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  userID(value: string, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      userID: value,
    };
  },
  accountID(value: string, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      accountID: value,
    };
  },
  accountType(value: string, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      accountType: value,
    };
  },
  transferTokenAvailableBalance(
    value: Amount,
    prevState: State | undefined,
  ): State {
    return {
      ...(prevState as State),
      transferTokenAvailableBalance: value,
    };
  },
  reference(value: string, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      reference: value,
    };
  },
  referenceCheckbox(value: boolean, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      referenceCheckbox: value,
    };
  },
  transferAmount(value: Amount, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      transferAmount: value,
    };
  },
  transferFees(value: Fee[] | undefined, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      transferFees: value,
    };
  },
  tokenCategory(
    value: TokenCategory | "",
    prevState: State | undefined,
  ): State {
    return {
      ...(prevState as State),
      tokenCategory: value,
    };
  },
  mZARBalance(value: Amount, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      mZARBalance: value,
    };
  },
  ledgerAccountModel(
    value: StellarAccountViewModel,
    prevState: State | undefined,
  ): State {
    return {
      ...(prevState as State),
      ledgerAccountModel: value,
    };
  },
};

type UnfilteredValidationResultType = {
  valid: boolean;
  fieldValidations: { [key: string]: string | null };
};

export let UnfilteredValidationResult: UnfilteredValidationResultType = {
  valid: true,
  fieldValidations: {},
};

// define your validation func
export const validationFunc = async (
  formState: State,
): Promise<ValidationResult> => {
  const newValidationState: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  if (formState.referenceCheckbox && !formState.reference) {
    newValidationState.fieldValidations.reference = "Cannot be blank";
    newValidationState.fieldValidations.transferHoverText =
      "All fields must be populated";
  }

  // validate that the reference is 40 characters long
  if (formState.reference.length > 40) {
    newValidationState.fieldValidations.reference =
      "Cannot be longer than 40 characters";
  }

  // validate that the stellar account ID is populated
  if (!formState.accountID) {
    newValidationState.fieldValidations.accountID = "Cannot be blank";
    newValidationState.fieldValidations.transferHoverText =
      "All fields must be populated";
  } else if (
    formState.accountID === formState.ledgerAccountModel.number ||
    formState.accountID === formState.ledgerAccountModel.ledgerID
  ) {
    newValidationState.fieldValidations.accountID =
      "From and To accounts cannot be the same";
  }

  // validate that the recipient account type is populated
  if (!formState.accountType) {
    newValidationState.fieldValidations.accountType = "Cannot be blank";
    newValidationState.fieldValidations.transferHoverText =
      "All fields must be populated";
  }

  // validate that transfer amount is not less than 0.0002
  if (formState.transferAmount.value.lt(new BigNumber(0.00002))) {
    newValidationState.fieldValidations.transferAmount =
      "Cannot be less than 0.0000200";
  }

  // validate that transfer amount greater than 0
  if (formState.transferAmount.value.isZero()) {
    newValidationState.fieldValidations.transferAmount =
      "Cannot be empty or zero";
    newValidationState.fieldValidations.transferHoverText =
      "All fields must be populated";
  }

  // only validate transfer fees when user is not MC user
  if (
    formState.transferFees &&
    !formState.ledgerAccountModel.accountOwnerClientName.includes(
      ManagingCompanyClientName,
    )
  ) {
    // calculate total fee amount
    const totalFeeAmount = formState.transferFees.reduce(
      (total, current) =>
        current
          .feeAmount()
          .setValue(current.feeAmount().value.plus(total.value)),
      new Amount(),
    );

    // in this scenario we want to validate if the total transfer amount is less than the total available
    // when transferred token and fee token are the same
    if (formState.tokenCategory === TokenCategory.DigitalInstrument) {
      if (totalFeeAmount.value.gt(formState.mZARBalance.value)) {
        newValidationState.fieldValidations.transferFees =
          "Insufficient balance for fees";
        newValidationState.fieldValidations.transferHoverText =
          "Insufficient balance for fees";
      }
    } else if (totalFeeAmount.token.code === formState.mZARBalance.token.code) {
      if (
        totalFeeAmount.value
          .plus(formState.transferAmount.value)
          .gt(formState.mZARBalance.value)
      ) {
        newValidationState.fieldValidations.transferFees =
          "Insufficient balance for fees";
        newValidationState.fieldValidations.transferHoverText =
          "Insufficient balance for fees";
      }
    } else {
      if (
        totalFeeAmount.value
          .plus(formState.transferAmount.value)
          .gt(formState.transferTokenAvailableBalance.value)
      ) {
        newValidationState.fieldValidations.transferFees =
          "Insufficient balance for fees";
        newValidationState.fieldValidations.transferHoverText =
          "Insufficient balance for fees";
      }
    }

    if (
      formState.transferTokenAvailableBalance.value.lt(
        formState.transferAmount.value,
      )
    ) {
      newValidationState.fieldValidations.transferAmount =
        "Insufficient balance to make transfer";
      newValidationState.fieldValidations.transferHoverText =
        "Insufficient balance to make transfer";
    }
  }

  if (formState.userID && formState.accountID && formState.accountType) {
    if (formState.accountType === TransferAccountType.MeshAccountType) {
      const LedgerAccountExistsResponse =
        await AccountInspector.CheckAccountExists({
          context: new Context({
            userID: formState.userID,
          }),
          accountIdentifier: NumberIdentifier(formState.accountID),
        });

      if (!LedgerAccountExistsResponse.exists) {
        newValidationState.fieldValidations.accountID =
          "Mesh account does not exist";
      }
    } else if (
      formState.accountType === TransferAccountType.StellarAccountType
    ) {
      // check if the account exists on stellar

      type StellarAccountExistsResponseType = {
        exists: boolean;
      };
      let StellarAccountExistsResponse: StellarAccountExistsResponseType = {
        exists: false,
      };
      try {
        StellarAccountExistsResponse =
          await AccountInspector.CheckAccountExists({
            context: new Context({ userID: formState.userID }),
            accountIdentifier: LedgerIDIdentifier(formState.accountID),
          });

        if (!StellarAccountExistsResponse.exists) {
          newValidationState.fieldValidations.accountID =
            "Stellar account does not exist";
        }
      } catch (e) {
        newValidationState.fieldValidations.accountID =
          "Stellar account does not exist";
      }

      if (!StellarAccountExistsResponse.exists) {
        newValidationState.fieldValidations.accountID =
          "Stellar account has no linked Mesh account";
      } else {
        const LedgerAccountExistsResponse =
          await AccountInspector.CheckAccountExists({
            context: new Context({
              userID: formState.userID,
            }),
            accountIdentifier: LedgerIDIdentifier(formState.accountID),
          });

        if (!LedgerAccountExistsResponse.exists) {
          newValidationState.fieldValidations.accountID =
            "The Stellar account has no linked Mesh account";
        }
      }
    }
  }

  if (Object.keys(newValidationState.fieldValidations).length > 0) {
    newValidationState.valid = false;
  }

  UnfilteredValidationResult = newValidationState;
  return newValidationState;
};
