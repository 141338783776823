import React, { useMemo, useState } from "react";
import {
  Box,
  Card,
  Collapse,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HowDoesItWorkSectionProps } from "./HowDoesItWork";
import meshIconWithHoldingDev from "assets/images/howDoesItWork/meshIconWithHoldingDev.png";
import meshArtUsers from "assets/images/howDoesItWork/meshArtUsers.png";
import { ContentType } from "./HowDoesItWorkContentProvider";
import { AssetType } from "../../../../../james/views/marketListingView/Model";
import CircleIcon from "@mui/icons-material/Circle";
import cx from "classnames";

type DescriptionType = {
  definition: ContentType[];
  who: ContentType[];
  what: ContentType[];
  investors?: ContentType[];
  outro?: ContentType[];
  readMore?: ContentType[];
};

export const DescriptionSection = ({
  section,
  marketListingViewModel,
}: HowDoesItWorkSectionProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [readMore, setReadMore] = useState(false);
  const [readMoreOutro, setReadMoreOutro] = useState(false);

  const isBond = [
    AssetType.FixedRateBond,
    AssetType.FloatingRateBond,
    AssetType.Bond,
    "",
  ].includes(marketListingViewModel.assetType);
  const isPrefshare =
    marketListingViewModel.assetType === AssetType.PreferenceShare;
  const content: DescriptionType | undefined = useMemo(() => {
    if (section.content) {
      return section.content as DescriptionType;
    }
    return;
  }, []);

  return (
    <>
      {content && (
        <Box
          className={cx({
            column: isBond || isPrefshare,
          })}
          sx={{
            display: "flex",
            flexDirection: {
              sm: "row",
              xs: "column",
            },
            backgroundColor: theme.palette.custom.cardInner,
            "&.column": {
              flexDirection: "column",
              backgroundColor: theme.palette.background.paper,
            },
          }}
        >
          <Box
            className={cx({
              isBond: isBond,
              isPrefshare: isPrefshare,
            })}
            sx={{
              display: "flex",
              flexDirection: "column",
              p: {
                sm: 5,
                xs: 3,
              },
              mb: 2,
              "&.isBond": {
                flexDirection: { sm: "row", xs: "column" },
                justifyContent: "space-between",
                width: {
                  sm: "100%",
                },
                pb: 4,
                mb: 0,
                gap: { sm: 4 },
              },
              "&.isPrefshare": {
                width: {
                  sm: "100%",
                },
                mb: 0,
              },
              width: {
                sm: "60%",
              },
            }}
          >
            {/*What is*/}
            <Box
              sx={{
                position: "relative",
              }}
            >
              {/* funky border handle */}
              <Box
                className={cx({
                  isBond: isBond,
                })}
                sx={{
                  position: "absolute",

                  borderBottomRightRadius: "4px",
                  [theme.breakpoints.up("sm")]: {
                    top: "21px",
                    bottom: "21px",
                    right: "-4px",
                    borderTopRightRadius: "4px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    left: "24px",
                    bottom: "-4px",
                    right: "24px",
                    borderBottomLeftRadius: "4px",
                  },
                  border: `2px solid ${theme.palette.secondary.main}`,
                  "&.isBond": {
                    [theme.breakpoints.up("sm")]: {
                      top: "32px",
                      bottom: "32px",
                      right: "-4px",
                      borderTopRightRadius: "4px",
                    },
                  },
                }}
              />
              <Card
                className={cx({
                  isBond: isBond,
                })}
                sx={{
                  p: {
                    sm: 4,
                    xs: 3,
                  },
                  pb: 2,
                  backgroundColor: theme.palette.custom.grape,
                  "&.isBond": {
                    width: { sm: 395, sx: "full" },
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      sm: "row",
                      xs: "column",
                    },
                    alignItems: {
                      xs: "center",
                      sm: "flex-start",
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      "&::after": {
                        content: `"${content.what[0].imageLabel}"`,
                        color: theme.palette.custom.cardInner,
                        fontSize: () => {
                          if (content.what[0].imageLabel) {
                            const l = content.what[0].imageLabel.length;
                            return l > 4 ? "9px" : l === 4 ? "10px" : "12px";
                          }
                        },
                        letterSpacing: 0.05,
                        fontWeight: "bold",
                        position: "absolute",
                        textAlign: "center",
                        borderRadius: "50%",
                        top: "-6px",
                        right: "-6px",
                        width: "36px",
                        height: "36px",
                        backgroundColor: theme.palette.secondary.main,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    }}
                  >
                    <Box
                      component={"img"}
                      src={meshIconWithHoldingDev}
                      sx={{
                        width: "90px",
                        height: "90px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      ml: {
                        sm: 3,
                      },
                      mt: {
                        sm: 0,
                        xs: 2,
                      },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: {
                        sm: "flex-start",
                        xs: "center",
                      },
                      textAlign: {
                        sm: "left",
                        xs: "center",
                      },
                      position: "relative",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        mb: 2,
                        textAlign: "left",
                      }}
                    >
                      {content.what[0].title}
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "14px",
                        textAlign: "left",
                        pr: { sm: 0, xs: 1 },
                      }}
                    >
                      {content.what[0].info ? content.what[0].info[0] : ""}
                    </Typography>
                    {!readMore && content.what.length > 1 && (
                      <Typography
                        component={Link}
                        underline="none"
                        sx={{ mt: 2 }}
                        onClick={() => setReadMore(!readMore)}
                      >
                        Read More
                      </Typography>
                    )}
                  </Box>
                </Box>
                {content.what.length > 1 && (
                  <Box
                    className={cx({
                      isPrefshare: isPrefshare,
                    })}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mt: 3,
                      gap: 1,
                      "&.isPrefshare": { mt: 0 },
                    }}
                  >
                    <Collapse in={readMore}>
                      {content.what
                        .slice(1, content.what.length)
                        .map((w, idx) => (
                          <Box
                            key={idx}
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "18px auto",
                            }}
                          >
                            <CircleIcon sx={{ width: 10 }} />
                            <Typography color="textSecondary">
                              {w.info}
                            </Typography>
                          </Box>
                        ))}
                    </Collapse>
                    {readMore && (
                      <Typography
                        component={Link}
                        underline="none"
                        onClick={() => setReadMore(!readMore)}
                      >
                        See Less
                      </Typography>
                    )}
                  </Box>
                )}
              </Card>
            </Box>

            {content.definition && (
              <Box
                sx={{
                  pt: 5,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                    fontSize: "14px",
                    mb: 3,
                  }}
                >
                  {content.definition[0].title}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    columnGap: 2,
                  }}
                >
                  {content.definition.length > 1 &&
                    content.definition
                      .slice(1, content.definition.length)
                      .map((def, idx) => {
                        return (
                          <Box
                            key={idx}
                            sx={{
                              minWidth: "220px",
                              maxWidth: {
                                lg: "300px",
                                md: "400px",
                                xs: "100%",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                mb: 1,
                                fontSize: "16px",
                              }}
                              children={`${def.title}`}
                            />
                            <Typography
                              sx={{
                                color: theme.palette.text.secondary,
                                mb: 3,
                              }}
                            >
                              {def.info}
                            </Typography>
                          </Box>
                        );
                      })}
                </Box>
              </Box>
            )}
          </Box>
          {/*Who is this for*/}
          <Box
            className={cx({
              isBond: isBond,
              isPrefshare: isPrefshare,
            })}
            sx={{
              backgroundColor: theme.palette.custom.grape,
              width: {
                sm: "40%",
                xs: "100%",
              },
              "&.isBond": {
                backgroundColor: theme.palette.custom.grape,
                width: "100%",
              },
              "&.isPrefshare": {
                width: "100%",
              },
            }}
          >
            <Box
              className={cx({
                isBond: isBond,
                isPrefshare: isPrefshare,
              })}
              sx={{
                px: {
                  sm: 5,
                  xs: 3,
                },
                pt: {
                  sm: 10,
                  xs: 5,
                },
                pb: 4,
                "&.isBond": {
                  display: "flex",
                  flexDirection: { sm: "row-reverse", xs: "column" },
                  flexWrap: { sm: "wrap-reverse", xs: "no-wrap" },
                  alignItems: "center",
                  justifyContent: "flex-end",
                  pt: 5,
                  gap: 2,
                },
                "&.isPrefshare": {
                  display: "flex",
                  flexDirection: { sm: "row-reverse", xs: "column" },
                  flexWrap: { sm: "wrap-reverse", xs: "no-wrap" },
                  alignItems: "center",
                  justifyContent: "flex-end",
                  pt: 5,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={meshArtUsers} width="275px" height="118px" />
              </Box>
              <Box
                className={cx({
                  isPrefshare: isPrefshare,
                })}
                sx={{ "&.isPrefshare": { width: "55%" } }}
              >
                <Typography
                  className={cx({
                    isBond: isBond,
                  })}
                  sx={{
                    mb: 2,
                    fontSize: "20px",
                    fontWeight: "bold",
                    "&.isBond": {
                      mt: { xs: 4, sm: 0 },
                    },
                  }}
                >
                  Who is this for?
                </Typography>
                {content &&
                  content.who[0].info &&
                  content.who[0].info?.map((v, i) => {
                    return (
                      <Typography
                        className={cx({
                          isBond: isBond,
                          isPrefshare: isPrefshare,
                        })}
                        key={i}
                        sx={{
                          color: theme.palette.text.secondary,
                          "&.isBond": {
                            width: { sm: 375 },
                          },
                          "&.isPrefshare": { mt: 2, width: "85%" },
                        }}
                      >
                        {v}
                      </Typography>
                    );
                  })}
              </Box>
            </Box>
            <Box
              sx={{
                pb: 4,
                px: {
                  xs: 3, // Extra small screens
                  sm: 5, // Small screens
                },
                display: "grid",
                gridTemplateColumns: { sm: "1fr 1fr", xs: "1fr" },
                gap: 3,
              }}
            >
              {content.investors &&
                content.investors.map((c, i) => (
                  <Box
                    key={i}
                    sx={{
                      boxSizing: "border-box", // Include padding in the width calculation
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "30px",
                          height: "100%",
                          textAlign: "right",
                        }}
                      >
                        {isMobile ? (
                          <Box
                            sx={{
                              width: "8px",
                              height: "8px",
                              borderRadius: "8px",
                              backgroundColor: theme.palette.secondary.main,
                              mt: 1,
                              mr: 2,
                            }}
                          />
                        ) : (
                          <Typography
                            sx={{
                              color: theme.palette.secondary.main,
                              fontWeight: "bold",
                              fontSize: "20px",
                              mt: 0.5,
                              mr: 2,
                              textAlign: "right",
                            }}
                          >
                            {c.title}.
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <Typography
                          sx={(theme) => ({
                            color: theme.palette.text.secondary,
                          })}
                        >
                          {c.info}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
            {content.outro && (
              <Box sx={{ ml: 5 }}>
                <Typography
                  sx={{
                    mb: content.readMore ? 1 : 5,
                    color: theme.palette.text.secondary,
                    width: { sm: "auto" },
                  }}
                >
                  {content.outro[0].info}
                </Typography>
              </Box>
            )}

            {content.readMore && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  px: {
                    sm: 5,
                    xs: 3,
                  },
                  pb: 5,
                }}
              >
                {!readMoreOutro && (
                  <Link
                    underline="none"
                    onClick={() => setReadMoreOutro(!readMoreOutro)}
                  >
                    Read More
                  </Link>
                )}

                <Collapse in={readMoreOutro}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 3,
                    }}
                  >
                    {content.readMore.map((v, idx) => (
                      <Box key={idx}>
                        <Typography variant="h5" fontWeight="bold">
                          {v.title}
                        </Typography>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "20px auto",
                            mt: 3,
                            rowGap: 0.5,
                          }}
                        >
                          {v.info &&
                            v.info.map((text, i) => (
                              <React.Fragment key={i}>
                                <CircleIcon
                                  sx={{ width: 10, height: 10, mt: 0.5 }}
                                />
                                <Typography color="textSecondary">
                                  {text}
                                </Typography>
                              </React.Fragment>
                            ))}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Collapse>
                {readMoreOutro && (
                  <Link
                    underline="none"
                    sx={{ mt: 2 }}
                    onClick={() => setReadMoreOutro(!readMoreOutro)}
                  >
                    See Less
                  </Link>
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
