import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { Asset } from "../../../../../james/ledger/Asset";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CouponPaymentTable } from "../../../../../components/CorporateActions/CouponPayment/CouponPaymentTable";
import { Token } from "../../../../../james/ledger";
import { NoDataCard } from "../PrimaryMarket";

export type Props = {
  asset: Asset;
  system: boolean;
};

export const CorporateActions = (props: Props) => {
  if (
    props.asset.assetID() === "" ||
    props.asset.assetToken().isEqualTo(new Token())
  ) {
    return (
      <Box
        sx={{
          px: 4,
          pb: 5,
          pt: 3,
        }}
      >
        <NoDataCard
          title={"No Coupon Payments Listed?"}
          subTitle={[
            "You will see a list once a the instrument",
            "has been pre-issued.",
          ]}
        />
      </Box>
    );
  }

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          sx={(theme) => ({
            flexDirection: "row-reverse",
            backgroundColor: theme.palette.custom.midnight,
          })}
          expandIcon={
            <ExpandMoreIcon
              sx={(theme) => ({
                marginLeft: theme.spacing(4),
                marginRight: theme.spacing(2.5),
              })}
              id="issuanceHub-corporateActions-couponPayments-accordionToggleIconButton"
              color="primary"
            />
          }
        >
          <Typography variant={"h6"}>Coupon Payments</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={(theme) => ({
            p: theme.spacing(3, 4, 5, 4),
          })}
        >
          <CouponPaymentTable
            style={(theme) => ({
              ".BPTable-tableWrapper": {
                backgroundColor: theme.palette.custom.grape,
              },
            })}
            system={props.system}
            height={450}
            bondID={props.asset.assetID()}
            showEmptyCard={true}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};
