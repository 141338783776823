"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNetworkNoDecimalPlaces = exports.stringToFutureNetwork = exports.futureNetworkToString = exports.allFutureNetworks = void 0;
const network_pb_1 = require("./network_pb");
// Get all futureNetworks as enum values
exports.allFutureNetworks = Object.values(network_pb_1.FutureNetwork).filter((value) => typeof value === "number");
// Define explicit mappings between FutureNetwork enums and custom string representations
const networkToStringMapping = {
    [network_pb_1.FutureNetwork.UNDEFINED_NETWORK]: "-",
    [network_pb_1.FutureNetwork.MESH_X_AGGREGATION_NETWORK]: "Mesh-X",
    [network_pb_1.FutureNetwork.STELLAR_TEST_SDF_NETWORK]: "Stellar Test SDF",
    [network_pb_1.FutureNetwork.STELLAR_PUBLIC_NETWORK]: "Stellar Public",
    [network_pb_1.FutureNetwork.BITCOIN_TEST_NETWORK]: "Bitcoin Test",
    [network_pb_1.FutureNetwork.BITCOIN_PUBLIC_NETWORK]: "Bitcoin Public",
    [network_pb_1.FutureNetwork.LITECOIN_TEST_NETWORK]: "Litecoin Test",
    [network_pb_1.FutureNetwork.LITECOIN_PUBLIC_NETWORK]: "Litecoin Public",
    [network_pb_1.FutureNetwork.ETHEREUM_TEST_NETWORK]: "Ethereum Test",
    [network_pb_1.FutureNetwork.ETHEREUM_PUBLIC_NETWORK]: "Ethereum PUblic",
    [network_pb_1.FutureNetwork.RIPPLE_TEST_NETWORK]: "Ripple Test",
    [network_pb_1.FutureNetwork.RIPPLE_PUBLIC_NETWORK]: "Ripple Public",
    [network_pb_1.FutureNetwork.CTSE_TEST_NETWORK]: "CTSE Test",
    [network_pb_1.FutureNetwork.CTSE_PRODUCTION_NETWORK]: "CTSE Production",
};
// Reverse mapping from string to FutureNetwork enum
const stringToFutureNetworkMapping = {};
for (const [key, value] of Object.entries(networkToStringMapping)) {
    stringToFutureNetworkMapping[value] = Number(key);
}
class UnsupportedFutureNetworkError extends Error {
    constructor(futureNetwork) {
        const message = `Unsupported FutureNetwork: ${futureNetwork}`;
        super(message);
        this.futureNetwork = futureNetwork;
    }
}
/**
 * Converts a FutureNetwork enum instance to a custom string representation.
 * @param {FutureNetwork} futureNetwork - The futureNetwork to convert.
 * @returns {string} The custom string representation of the futureNetwork.
 */
function futureNetworkToString(futureNetwork) {
    if (futureNetwork in networkToStringMapping) {
        return networkToStringMapping[futureNetwork];
    }
    else {
        throw new UnsupportedFutureNetworkError(futureNetwork);
    }
}
exports.futureNetworkToString = futureNetworkToString;
class UnsupportedFutureNetworkStringError extends Error {
    constructor(futureNetworkStr) {
        const message = `Unsupported futureNetwork string: ${futureNetworkStr}`;
        super(message);
        this.futureNetworkStr = futureNetworkStr;
    }
}
/**
 * Converts a custom string representation to a FutureNetwork enum instance.
 * @param {string} futureNetworkStr - The custom string representation of the futureNetwork.
 * @returns {FutureNetwork} The corresponding FutureNetwork enum instance.
 */
function stringToFutureNetwork(futureNetworkStr) {
    if (futureNetworkStr in stringToFutureNetworkMapping) {
        return stringToFutureNetworkMapping[futureNetworkStr];
    }
    else {
        throw new UnsupportedFutureNetworkStringError(futureNetworkStr);
    }
}
exports.stringToFutureNetwork = stringToFutureNetwork;
// Define a mapping from Network to the number of decimal places
const networkDecimalPlaces = {
    [network_pb_1.FutureNetwork.STELLAR_PUBLIC_NETWORK]: 7,
    [network_pb_1.FutureNetwork.STELLAR_TEST_SDF_NETWORK]: 7,
};
/**
 * Creates a new Amount object using a BigNumber and a Token.
 *
 * @param {FutureNetwork} network - The network for which to get decimal places.
 * @returns {number} Returns the number of decimal places supported on network.
 */
function getNetworkNoDecimalPlaces(network) {
    if (network in networkDecimalPlaces) {
        return networkDecimalPlaces[network];
    }
    else {
        throw new UnsupportedFutureNetworkError(network);
    }
}
exports.getNetworkNoDecimalPlaces = getNetworkNoDecimalPlaces;
