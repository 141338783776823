import React, { useEffect, useState } from "react";
import { Box, Divider, Theme, useMediaQuery } from "@mui/material";
import { IssuerSection } from "./components/IssuerSection";
import { UnderwritersSection } from "./components/UnderwritersSection";
import { AssetInspector } from "james/ledger/AssetInspector";
import { Token } from "james/ledger";
import { AssetParticipants } from "james/ledger/AssetParticipants";
import { ParticipantsLoader } from "./components/ParticipantsLoader";
import {
  Model as MarketListingViewModel,
  AssetType,
} from "james/views/marketListingView/Model";
import { PublicModel as PublicMarketListingViewModel } from "james/views/marketListingView";
import cx from "classnames";
import { AdvisorSection } from "./components/AdvisorSection";
import { AgentSection } from "./components/AgentSection";
import { GuarantorSection } from "./components/GuarantorSection";

interface ParticipantsProps {
  token: Token;
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel;
}

export const Participants = ({
  token,
  marketListingViewModel,
}: ParticipantsProps) => {
  const [participants, setParticipants] = useState<AssetParticipants>();
  const [loading, setLoading] = useState(false);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isSubscription = [
    AssetType.FixedRateBond,
    AssetType.FloatingRateBond,
    AssetType.PreferenceShare,
    AssetType.Note,
    AssetType.Bond,
    AssetType.Share,
    "",
  ].includes(marketListingViewModel.assetType);
  useEffect(() => {
    setTimeout(async () => {
      setLoading(true);
      try {
        const getAssetParticipantsResponse =
          await AssetInspector.GetAssetParticipants({
            token: token,
          });

        setParticipants(getAssetParticipantsResponse.participants);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    });
  }, [token]);
  return (
    <>
      {participants && !loading ? (
        <Box>
          <Box
            className={cx({
              "no-underwriters": [participants.underwriters.length > 0],
            })}
            sx={(theme) => ({
              backgroundColor: theme.palette.custom.midnight,
              pb: 5,
              "&.no-underwriters": {
                height: "100%",
                bottom: 0,
              },
            })}
          >
            <IssuerSection
              logoUrl={
                marketListingViewModel.issuerLogo?.url ??
                participants.issuer.logoUrl ??
                ""
              }
              issuerProfile={participants.issuer}
            />
            {participants.underwriters.length > 0 && (
              <Divider sx={{ mx: { sm: 5, xs: 3 } }} />
            )}
            <UnderwritersSection underwriters={participants.underwriters} />
            {participants.gurantor && participants.gurantor.name && (
              <GuarantorSection gurantor={participants.gurantor} />
            )}
          </Box>
          {isSubscription && (
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: { sm: "row", xs: "column" },
                backgroundColor: theme.palette.background.paper,
                mb: 4,
              })}
            >
              {participants.advisor && participants.advisor.description && (
                <>
                  <AdvisorSection advisor={participants.advisor} />
                  {!smDown && (
                    <Divider
                      orientation="vertical"
                      sx={(theme) => ({
                        my: 5,
                        height: "auto",
                        color: theme.palette.divider,
                      })}
                    ></Divider>
                  )}
                  {smDown && (
                    <Divider
                      sx={(theme) => ({
                        mx: 3,
                        color: theme.palette.divider,
                      })}
                    ></Divider>
                  )}
                </>
              )}
              <AgentSection
                assetName={marketListingViewModel.assetName}
                issuerName={participants.issuer.name}
              />
            </Box>
          )}
        </Box>
      ) : (
        <ParticipantsLoader />
      )}
    </>
  );
};
