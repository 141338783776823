import React, { useMemo } from "react";
import { Box, IconButton, Link, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IssuerProfile } from "james/ledger/AssetParticipants";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";

interface IssuerSectionProps {
  issuerProfile: IssuerProfile;
  logoUrl: string;
}

export const IssuerSection = ({
  issuerProfile,
  logoUrl,
}: IssuerSectionProps) => {
  const issuerWebsite = useMemo((): string => {
    if (!issuerProfile.website) return "";
    try {
      return new URL(issuerProfile.website).hostname.replace("www.", "");
    } catch {
      return issuerProfile.website;
    }
  }, [issuerProfile.website]);

  return (
    <Box sx={{ px: { sm: 5, xs: 3 }, pt: 4 }}>
      <Box>
        <Typography
          variant="h4"
          sx={(theme) => ({
            "& > .title": { color: theme.palette.text.secondary },
            fontWeight: "bold",
          })}
        >
          <span className="title">Issuer:</span> {issuerProfile.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: { sm: "flex-start" },
            width: "100%",
            flexDirection: {
              sm: "row",
              xs: "column",
            },
          }}
        >
          <Typography
            variant="body1"
            sx={(theme) => ({
              mr: 4,
              my: 2,
              color: theme.palette.text.secondary,
            })}
            children={issuerProfile.description}
          />
          {(issuerProfile.logoUrl || logoUrl) && (
            <Box
              minHeight={155}
              minWidth={206}
              maxHeight={155}
              maxWidth={206}
              overflow={"hidden"}
            >
              <Box
                component={"img"}
                maxHeight={155}
                maxWidth={206}
                src={logoUrl !== "" ? logoUrl : issuerProfile.logoUrl}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: "grid",
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
          gap: 2,
          my: { sm: 5, xs: 0 },
          mb: { sm: 4, xs: 3 },
          "& > .infoBlock": {
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
          },
        })}
      >
        {issuerProfile.representativeName && (
          <Box className="infoBlock">
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontSize: 12,
              })}
            >
              Company Representative
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
              {issuerProfile.representativeName}
            </Typography>
          </Box>
        )}
        {issuerProfile.website && (
          <Box className="infoBlock">
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontSize: 12,
              })}
            >
              Website
            </Typography>
            <Typography
              component={Link}
              target="_blank"
              underline="none"
              href={
                issuerProfile.website.includes("http")
                  ? issuerProfile.website
                  : `https://${issuerProfile.website}`
              }
              sx={(theme) => ({
                fontSize: 16,
                color: theme.palette.secondary.main,
              })}
            >
              {issuerWebsite}
            </Typography>
          </Box>
        )}
        {issuerProfile.contactNumber && (
          <Box className="infoBlock">
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontSize: 12,
              })}
            >
              Contact Number
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
              {issuerProfile.contactNumber}
            </Typography>
          </Box>
        )}
        {!issuerProfile.companyAddress.isEmpty() && (
          <Box className="infoBlock">
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontSize: 12,
              })}
            >
              Address
            </Typography>
            <Typography
              sx={{ fontSize: 16 }}
            >{`${issuerProfile.companyAddress.toString()} `}</Typography>
          </Box>
        )}
        {issuerProfile.email && (
          <Box className="infoBlock">
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontSize: 12,
              })}
            >
              Email
            </Typography>
            <Link
              component={Link}
              underline="hover"
              href={`mailto:${issuerProfile.email}`}
              sx={{ fontSize: 16 }}
            >
              {issuerProfile.email}
            </Link>
          </Box>
        )}
        <Box sx={{ pt: 1, ml: -1 }}>
          {issuerProfile.twitter && (
            <IconButton
              target="_blank"
              href={`https://twitter.com/${issuerProfile.twitter}`}
            >
              <XIcon
                sx={(theme) => ({
                  color: theme.palette.secondary.main,
                  height: 24,
                  width: 24,
                })}
              />
            </IconButton>
          )}
          {issuerProfile.linkedIn && (
            <IconButton
              target="_blank"
              href={`https://www.linkedin.com/company/${issuerProfile.linkedIn}`}
            >
              <LinkedInIcon
                sx={(theme) => ({
                  color: theme.palette.secondary.main,
                  height: 24,
                  width: 24,
                })}
              />
            </IconButton>
          )}
          {issuerProfile.facebook && (
            <IconButton target="_blank" href={issuerProfile.facebook}>
              <FacebookRoundedIcon
                sx={(theme) => ({
                  color: theme.palette.secondary.main,
                  height: 24,
                  width: 24,
                })}
              />
            </IconButton>
          )}
          {issuerProfile.instagram && (
            <IconButton target="_blank" href={issuerProfile.instagram}>
              <InstagramIcon
                sx={(theme) => ({
                  color: theme.palette.secondary.main,
                  height: 24,
                  width: 24,
                })}
              />
            </IconButton>
          )}
          {issuerProfile.youtube && (
            <IconButton target="_blank" href={issuerProfile.youtube}>
              <YouTubeIcon
                sx={(theme) => ({
                  color: theme.palette.secondary.main,
                  height: 24,
                  width: 24,
                })}
              />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};
