import React, { useMemo, useRef, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import meshLogo from "assets/images/logo/meshLogo.svg";
import meshLogoNoWords from "assets/images/logo/meshLogoNoWords.svg";

// Icons
import AppsIcon from "@mui/icons-material/Apps";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { RouteType } from "routes/Route";
import { useLocation, useNavigate } from "react-router-dom";
import { getViewConfigurationApps } from "utilities/configuration/viewConfiguration";
import { ClientKYCStatus } from "james/client/Client";
import Tooltip from "@mui/material/Tooltip";
import { useWidth } from "utilities/general";
import { isWidthDown } from "@mui/material/Hidden/withWidth";
import { ProfileMenu } from "./components/ProfileMenu";
import { AppSwitcherMenu } from "./components/AppSwitcherMenu";
import { useApplicationContext } from "context/Application/Application";
import { useAppTourContext } from "context/AppTour/AppTour";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

export const MainAppBarHeight = 56;

type Props = {
  userProfileRoute?: RouteType;
  clientProfileRoute?: RouteType;
};

const appSectionRootPathHomePathIndex: { [key: string]: string } = {
  "/builder": "/builder",
  "/market": "/market/markets",
  "/administration": "/administration",
  "/market-management": "/market-management",
};

const pathnameToCommonNameMap: { [key: string]: string } = {
  "/builder": "Instrument Builder",
  "/market/markets": "Markets",
  "/market/orders": "Orders",
  "/market/trades": "Trades",
  "/administration": "Administration",
  "/market-management": "Market Management",
  "/market/subscriptions": "Subscriptions",
};

export function MainAppBar(props: Props) {
  const width = useWidth();
  const isMobile = useMemo(() => isWidthDown("sm", width), [width]);
  const {
    viewConfiguration,
    myProfilePictureURL,
    myClientKYCStatus,
    loginClaims,
  } = useApplicationContext();
  const navigate = useNavigate();
  const location = useLocation();
  const profilePictureRef = useRef<HTMLImageElement>(null);
  const [profilePictureVertical, setProfilePictureVertical] = useState(false);
  const { registerElement } = useAppTourContext();
  const { Banner, NotificationBannerHeight, KYCBanner, KYBBanner } =
    useAppNoticeContext();

  // app switcher menu
  const [anchorElApps, setAnchorElApps] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenAppsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElApps(event.currentTarget);
  };

  // profile menu
  const [anchorElProfile, setAnchorElProfile] =
    React.useState<null | HTMLElement>(null);

  const handleOpenProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorElProfile) {
      setAnchorElProfile(null);
      return;
    }
    setAnchorElProfile(event.currentTarget);
  };

  return (
    <>
      <AppBar
        sx={(theme) => ({
          width: "100%",
          backgroundColor: theme.palette.custom.midnight,
          height: MainAppBarHeight + NotificationBannerHeight,
          overflow: "hidden",
          [theme.breakpoints.down("sm")]: {
            boxShadow: 24,
          },
        })}
        elevation={0}
      >
        {KYCBanner}
        {KYBBanner}
        {Banner}
        <Toolbar
          sx={{
            "&.MuiToolbar-root": {
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              padding: (theme) => theme.spacing(0, 1),
            },
            "&.MuiToolbar-regular": {
              minHeight: MainAppBarHeight,
            },
          }}
          disableGutters
        >
          {/* Left And Right section for mobile */}
          {isMobile && (
            <>
              {/* Left section box*/}
              <Box
                sx={(theme) => ({
                  display: "grid",
                  gridAutoFlow: "column",
                  alignItems: "center",
                  [theme.breakpoints.down("sm")]: {
                    gridColumnGap: theme.spacing(1),
                  },
                })}
              >
                <img
                  style={{
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                  id="mainAppBar-mesh-image"
                  src={meshLogoNoWords}
                  alt="Mesh Logo"
                  height={40}
                  onClick={() => {
                    for (const appSectionRootPath of Object.keys(
                      appSectionRootPathHomePathIndex,
                    )) {
                      if (
                        Object.prototype.hasOwnProperty.call(
                          appSectionRootPathHomePathIndex,
                          appSectionRootPath,
                        ) &&
                        window.location.pathname.includes(appSectionRootPath)
                      ) {
                        navigate(
                          appSectionRootPathHomePathIndex[appSectionRootPath],
                        );
                      }
                    }
                  }}
                />
                <Typography variant="h5">
                  {pathnameToCommonNameMap[window.location.pathname]}
                </Typography>
              </Box>
              {/* Right section box */}
              <Box
                sx={{
                  justifySelf: "end",
                  display: "grid",
                  gridAutoFlow: "column",
                  alignItems: "center",
                }}
              >
                <IconButton
                  ref={registerElement("mainAppBar-profileMenu-iconButton")}
                  data-link-info={JSON.stringify({
                    content_interaction_id: "nav-link",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_text: "profile",
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_driver: InteractionDriver.Navigation,
                  } as DataLinkInfoType)}
                  id="mainAppBar-profileMenu-iconButton"
                  size="small"
                  sx={{
                    marginLeft: (theme) => theme.spacing(1),
                  }}
                  onClick={handleOpenProfileMenu}
                >
                  {myProfilePictureURL ? (
                    <Avatar
                      sx={{
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <img
                        onLoad={() => {
                          if (profilePictureRef.current) {
                            setProfilePictureVertical(
                              profilePictureRef.current.naturalWidth <
                                profilePictureRef.current.naturalHeight,
                            );
                          }
                        }}
                        ref={profilePictureRef}
                        alt=""
                        src={myProfilePictureURL}
                        style={{
                          width: profilePictureVertical ? "40px" : "auto",
                          height: !profilePictureVertical ? "40px" : "auto",
                        }}
                      />
                    </Avatar>
                  ) : (
                    <Avatar
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.custom.lavender,
                        width: "40px",
                        height: "40px",
                      }}
                      alt="profile-picture"
                    >
                      {loginClaims.firstName[0] + loginClaims.lastName[0]}
                    </Avatar>
                  )}
                </IconButton>
              </Box>
            </>
          )}
          {/* Left And Right section for tablet and desktop */}
          {!isMobile && (
            <>
              {/* Left section box*/}
              <Box
                sx={(theme) => ({
                  display: "grid",
                  gridAutoFlow: "column",
                  alignItems: "center",
                  [theme.breakpoints.down("sm")]: {
                    gridColumnGap: theme.spacing(1),
                  },
                })}
              >
                <img
                  style={{
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                  id="mainAppBar-mesh-image"
                  src={meshLogo}
                  alt="Mesh Logo"
                  height={60}
                  onClick={() => {
                    for (const appSectionRootPath of Object.keys(
                      appSectionRootPathHomePathIndex,
                    )) {
                      if (
                        Object.prototype.hasOwnProperty.call(
                          appSectionRootPathHomePathIndex,
                          appSectionRootPath,
                        ) &&
                        location.pathname.includes(appSectionRootPath)
                      ) {
                        navigate(
                          appSectionRootPathHomePathIndex[appSectionRootPath],
                        );
                      }
                    }
                  }}
                />
                {getViewConfigurationApps(viewConfiguration).length > 1 && (
                  <IconButton
                    id="mainAppBar-appSwitcher-iconButton"
                    onClick={handleOpenAppsMenu}
                    size="large"
                  >
                    <AppsIcon
                      sx={{
                        color: (theme) => theme.palette.action.active,
                      }}
                    />
                  </IconButton>
                )}
              </Box>
              {/* Right section box */}
              <Box
                sx={{
                  justifySelf: "end",
                  display: "grid",
                  gridAutoFlow: "column",
                  alignItems: "center",
                }}
              >
                {viewConfiguration.Wallet && (
                  <Hidden smDown>
                    <Tooltip
                      title={
                        myClientKYCStatus !== ClientKYCStatus.VerifiedStatus
                          ? "KYC Verification required"
                          : ""
                      }
                      disableHoverListener
                      placement="top"
                    >
                      <span
                        data-link-info={JSON.stringify({
                          content_interaction_id: "nav-link",
                          content_interaction_action: InteractionAction.Click,
                          content_interaction_text: "accounts", // This should match what is should on UI
                          content_interaction_type: InteractionType.Button,
                          content_interaction_driver:
                            InteractionDriver.Navigation,
                        } as DataLinkInfoType)}
                      >
                        <Button
                          ref={registerElement(
                            "marketPlaceRibbon-funcAccount-button",
                            true,
                          )}
                          id="mainAppBar-accounts-button"
                          sx={{
                            color: (theme) => theme.palette.text.secondary,
                            marginRight: "16px",
                            fontSize: "12px",
                            textTransform: "none",
                          }}
                          onClick={() => {
                            navigate("/accounts", {
                              state: { from: location },
                            });
                          }}
                          variant="text"
                          // It seems that the only way to change the icon size is with inline styles
                          startIcon={
                            <AccountBalanceWalletIcon
                              data-link-info={JSON.stringify({
                                content_interaction_id: "nav-link",
                                content_interaction_action:
                                  InteractionAction.Click,
                                content_interaction_text: "accounts", // This should match what is should on UI
                                content_interaction_type: InteractionType.Icon,
                                content_interaction_driver:
                                  InteractionDriver.Navigation,
                              } as DataLinkInfoType)}
                              style={{ fontSize: "24px" }}
                            />
                          }
                          data-link-info={JSON.stringify({
                            content_interaction_id: "nav-link",
                            content_interaction_action: InteractionAction.Click,
                            content_interaction_text: "accounts", // This should match what is should on UI
                            content_interaction_type: InteractionType.Button,
                            content_interaction_driver:
                              InteractionDriver.Navigation,
                          } as DataLinkInfoType)}
                        >
                          Accounts
                        </Button>
                      </span>
                    </Tooltip>
                  </Hidden>
                )}
                <Hidden smDown>
                  <Box
                    sx={(theme) => ({
                      display: "grid",
                      gridTemplateColumns: "auto",
                      justifyItems: "end",
                      borderLeft: `1px solid ${theme.palette.text.disabled}`,
                      paddingLeft: theme.spacing(3),
                    })}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      children="Hello"
                    />
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      children={loginClaims.firstName}
                    />
                  </Box>
                </Hidden>
                <IconButton
                  ref={registerElement("mainAppBar-profileMenu-iconButton")}
                  id="mainAppBar-profileMenu-iconButton"
                  size="small"
                  sx={{
                    marginLeft: (theme) => theme.spacing(1),
                  }}
                  onClick={handleOpenProfileMenu}
                  data-link-info={JSON.stringify({
                    content_interaction_id: "nav-link",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_text: "profile", // This should match what is should on UI
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_driver: InteractionDriver.Navigation,
                  } as DataLinkInfoType)}
                >
                  {myProfilePictureURL ? (
                    <Avatar
                      sx={{
                        width: "40px",
                        height: "40px",
                      }}
                      data-link-info={JSON.stringify({
                        content_interaction_id: "nav-link",
                        content_interaction_action: InteractionAction.Click,
                        content_interaction_text: "profile", // This should match what is should on UI
                        content_interaction_type: InteractionType.Icon,
                        content_interaction_driver:
                          InteractionDriver.Navigation,
                      } as DataLinkInfoType)}
                    >
                      <img
                        onLoad={() => {
                          if (profilePictureRef.current) {
                            setProfilePictureVertical(
                              profilePictureRef.current.naturalWidth <
                                profilePictureRef.current.naturalHeight,
                            );
                          }
                        }}
                        data-link-info={JSON.stringify({
                          content_interaction_id: "nav-link",
                          content_interaction_action: InteractionAction.Click,
                          content_interaction_text: "profile", // This should match what is should on UI
                          content_interaction_type: InteractionType.Icon,
                          content_interaction_driver:
                            InteractionDriver.Navigation,
                        } as DataLinkInfoType)}
                        ref={profilePictureRef}
                        alt=""
                        src={myProfilePictureURL}
                        style={{
                          width: profilePictureVertical ? "40px" : "auto",
                          height: !profilePictureVertical ? "40px" : "auto",
                        }}
                      />
                    </Avatar>
                  ) : (
                    <Avatar
                      sx={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: (theme) =>
                          theme.palette.custom.lavender,
                      }}
                      alt="profile-picture"
                      data-link-info={JSON.stringify({
                        content_interaction_id: "nav-link",
                        content_interaction_action: InteractionAction.Click,
                        content_interaction_text: "profile", // This should match what is should on UI
                        content_interaction_type: InteractionType.Icon,
                        content_interaction_driver:
                          InteractionDriver.Navigation,
                      } as DataLinkInfoType)}
                    >
                      {loginClaims.firstName[0] + loginClaims.lastName[0]}
                    </Avatar>
                  )}
                </IconButton>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <ProfileMenu
        anchorElProfile={anchorElProfile}
        onClose={() => setAnchorElProfile(null)}
        userProfileRoute={props.userProfileRoute}
        clientProfileRoute={props.clientProfileRoute}
      />
      <AppSwitcherMenu
        anchorElApps={anchorElApps}
        clearAnchorElApps={() => setAnchorElApps(null)}
      />
    </>
  );
}
