import React, { useCallback, useContext, useEffect, useState } from "react";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import {
  Model as LedgerTokenViewModel,
  Reader as LedgerTokenViewReader,
} from "../../../../../james/views/ledgerTokenView";
import { useIsMounted } from "hooks";
import { Group, GroupRepository } from "james/group";
import { useApplicationContext } from "context/Application/Application";
import {
  TextListCriterion,
  TextNINListCriterion,
} from "james/search/criterion";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { Determiner, ScopeFields } from "james/search/scope/Determiner";
import { Permission } from "james/security";
import { ValidationResult } from "common/validation";
import { StellarNetwork } from "james/stellar";
import { TokenWrapper } from "@mesh/common-js/dist/ledger";
import BigNumber from "bignumber.js";
import { AssetClass } from "@mesh/common-js/dist/financial/assetClass_pb";
import { UnitCategory } from "@mesh/common-js/dist/financial/unitCategory_pb";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { dayjsToProtobufTimestamp } from "@mesh/common-js/dist/googleProtobufConverters";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { useValidatedForm } from "hooks/useForm";
import {
  formDataUpdaterSpecs,
  formDataValidationFunc,
  FormUpdaterSpecsType as FormDataUpdaterType,
  SmartInstrumentFormData,
} from "./useValidatedForm";
import { SmartInstrumentState } from "@mesh/common-js/dist/financial/smartInstrumentState_pb";
import { RateResetCorporateAction } from "@mesh/common-js/dist/financial/rateResetCorporateAction_pb";
import { useAPIContext } from "context/API";
import { GenerateSmartInstrumentRateResetsRequest } from "@mesh/common-js/dist/financial/smartInstrumentRateResetGenerator_pb";
import { Assetflow } from "@mesh/common-js/dist/financial/assetflow_pb";
import { CalculateSmartInstrumentAssetflowsRequest } from "@mesh/common-js/dist/financial/smartInstrumentCalculator_pb";
import { PaymentDeferralCorporateAction } from "@mesh/common-js/dist/financial/paymentDeferralCorporateAction_pb";
import { Payment } from "@mesh/common-js/dist/financial/payment_pb";
import { useErrorContext } from "context/Error";
import { useSnackbar } from "notistack";
import {
  CreateDraftSmartInstrumentRequest,
  PreIssueSmartInstrumentRequest,
} from "@mesh/common-js/dist/financial/smartInstrumentStateController_pb";
import {
  ChangeSmartInstrumentDocumentsRequest,
  DraftUpdateSmartInstrumentRequest,
} from "@mesh/common-js/dist/financial/smartInstrumentUpdater_pb";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { ReadOneSmartInstrumentRequest } from "@mesh/common-js/dist/financial/smartInstrumentReader_meshproto_pb";
import { isEqual } from "lodash";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";
import { ReadManyRateResetCorporateActionRequest } from "@mesh/common-js/dist/financial/rateResetCorporateActionReader_meshproto_pb";
import { ReadManyAssetflowRequest } from "@mesh/common-js/dist/financial/assetflowReader_meshproto_pb";
import { ReadManyPaymentRequest } from "@mesh/common-js/dist/financial/paymentReader_meshproto_pb";
import { ReadManyPaymentDeferralCorporateActionRequest } from "@mesh/common-js/dist/financial/paymentDeferralCorporateActionReader_meshproto_pb";
dayjs.extend(utc);

export enum ViewMode {
  Undefined = "-",
  View = "view",
  Edit = "edit",
}

export type BuilderContextType = {
  apiCallInProgress: boolean;

  viewMode: ViewMode;
  setEditViewMode: () => void;
  setViewViewMode: () => void;

  initialised: boolean;
  initialisationError: string | null;
  clearInitialisationError: () => void;

  ledgerTokenViewModels: LedgerTokenViewModel[];
  potentialGroupOwners: Group[];

  formData: SmartInstrumentFormData;
  formDataValidationResult: ValidationResult;
  formDataUpdater: FormDataUpdaterType;

  rateResets: RateResetCorporateAction[];
  rateResetsLoaded: boolean;
  rateResetLoadError: string | null;
  clearRateResetLoadError: () => void;
  reloadRateResets: () => void;

  paymentDeferrals: PaymentDeferralCorporateAction[];
  paymentDeferralsLoaded: boolean;
  paymentDeferralLoadError: string | null;
  clearPaymentDeferralLoadError: () => void;
  reloadPaymentDeferrals: () => void;

  assetflows: Assetflow[];
  assetflowsLoaded: boolean;
  assetflowLoadError: string | null;
  clearAssetflowLoadError: () => void;
  reloadAssetflows: () => void;

  payments: Payment[];
  paymentsLoaded: boolean;
  paymentLoadError: string | null;
  clearPaymentLoadError: () => void;
  reloadPayments: () => void;

  simulationMode: boolean;
  toggleSimulationMode: () => void;
  runSimulation: () => void;
  simulationInProgress: boolean;
  simulationError: string | null;
  simulatedRateResets: RateResetCorporateAction[];
  simulatedPaymentDeferrals: PaymentDeferralCorporateAction[];
  simulatedAssetflows: Assetflow[];
  simulatedPayments: Payment[];

  saveDraftOrCreateAction: () => Promise<void>;
  changeDocumentsAction: () => Promise<void>;
  preIssueAction: () => Promise<void>;
};

export const defaultContext: BuilderContextType = {
  apiCallInProgress: false,

  viewMode: ViewMode.View,
  setEditViewMode: () => null,
  setViewViewMode: () => null,

  initialised: false,
  initialisationError: null,
  clearInitialisationError: () => null,

  ledgerTokenViewModels: [],
  potentialGroupOwners: [],
  formData: {
    smartInstrument: new SmartInstrument(),
    smartIntrumentCopy: new SmartInstrument(),
  },
  formDataValidationResult: {
    valid: false,
    fieldValidations: {},
  },
  formDataUpdater: formDataUpdaterSpecs,

  rateResets: [],
  rateResetsLoaded: false,
  rateResetLoadError: null,
  clearRateResetLoadError: () => null,
  reloadRateResets: () => null,

  paymentDeferrals: [],
  paymentDeferralsLoaded: false,
  paymentDeferralLoadError: null,
  clearPaymentDeferralLoadError: () => null,
  reloadPaymentDeferrals: () => null,

  assetflows: [],
  assetflowsLoaded: false,
  assetflowLoadError: null,
  clearAssetflowLoadError: () => null,
  reloadAssetflows: () => null,

  payments: [],
  paymentsLoaded: false,
  paymentLoadError: null,
  clearPaymentLoadError: () => null,
  reloadPayments: () => null,

  simulationMode: false,
  toggleSimulationMode: () => null,
  runSimulation: () => null,
  simulationInProgress: false,
  simulationError: null,
  simulatedRateResets: [],
  simulatedPaymentDeferrals: [],
  simulatedAssetflows: [],
  simulatedPayments: [],

  saveDraftOrCreateAction: () => new Promise(() => null),
  changeDocumentsAction: () => new Promise(() => null),
  preIssueAction: () => new Promise(() => null),
};

const BuiderContext = React.createContext<BuilderContextType>(defaultContext);

export const useBuilderContext = () => useContext(BuiderContext);

export const BuilderContextProvider: React.FC<{
  system: boolean;
  children: React.ReactNode;
}> = ({ system, children }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { errorContextDefaultWarningFeedback } = useErrorContext();
  const {
    financial: {
      smartInstrumentRateResetGenerator,
      smartInstrumentCalculator,
      smartInstrumentStateController,
      smartInstrumentUpdater,
      smartInstrumentReader,
      smartInstrumentReaderUNSCOPED,
      rateResetCorporateActionReader,
      rateResetCorporateActionReaderUNSCOPED,
      paymentDeferralCorporateActionReader,
      paymentDeferralCorporateActionReaderUNSCOPED,
      assetflowReader,
      assetflowReaderUNSCOPED,
      paymentReader,
      paymentReaderUNSCOPED,
    },
  } = useAPIContext();
  const { authContext, viewConfiguration } = useApplicationContext();
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, formDataValidationResult, formDataUpdater] =
    useValidatedForm(
      formDataValidationFunc,
      undefined,
      formDataUpdaterSpecs,
      defaultContext.formData,
      new Set<string>([]),
      { skipTouchedFieldsOnValidation: true },
    );

  // ----------- associated data -----------
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataLoadError, setDataLoadError] = useState<string | null>(null);
  const [assetTokenViewModels, setAssetTokenViewModels] = useState<
    LedgerTokenViewModel[]
  >([]);
  const [potentialGroupOwners, setPotentialGroupOwners] = useState<Group[]>([]);
  useEffect(() => {
    // do nothing if:
    // - required data already loaed
    // - if there was a data loading error
    // - component no longer mounted
    if (dataLoaded || dataLoadError || !isMounted()) {
      return;
    }

    // Otherwise data loading needs to take place - do that now.
    (async () => {
      // prepare data required for builder
      let retrievedPotentialGroupOwners: Group[] = [];
      let retrievedLedgerTokenViewModels: LedgerTokenViewModel[] = [];

      // fetch required data
      try {
        await Promise.all([
          // fetch all eligible ledger token view models
          (async () => {
            retrievedLedgerTokenViewModels = (
              await LedgerTokenViewReader.Read({
                criteria: {
                  tokenCategory: TextNINListCriterion([
                    TokenCategory.InstrumentStablecoin,
                    TokenCategory.DigitalInstrument,
                    TokenCategory.LiquidityPoolShares,
                    TokenCategory.Unknown,
                  ]),
                  "token.network": TextListCriterion([
                    StellarNetwork.PublicNetwork,
                    StellarNetwork.TestSDFNetwork,
                  ]),
                },
              })
            ).models;

            // confirm at least 1 asset token found
            if (!retrievedLedgerTokenViewModels.length) {
              throw new Error("no token view models found");
            }
          })(),

          // fetch potential instrument group owners
          (async () => {
            retrievedPotentialGroupOwners = system
              ? (
                  await GroupRepository.SearchGroups({
                    context: authContext,
                    criteria: {},
                  })
                ).records
              : (
                  await GroupRepository.SearchGroups({
                    context: authContext,
                    criteria: (
                      await Determiner.DetermineScopeCriteriaByRoles({
                        context: authContext,
                        service: new Permission({
                          // FIXME: use a general instrument creation permission here
                          serviceName: "ReadOneSmartInstrument",
                          serviceProvider: "financial-SmartInstrumentReader",
                          description: "-",
                        }),
                        criteria: {},
                        scopeFields: [ScopeFields.IDField],
                        buildScopeTree: false,
                      })
                    ).criteria,
                  })
                ).records;

            // confirm at least 1 group owner found
            if (!retrievedPotentialGroupOwners.length) {
              throw new Error("no potential group owners found");
            }
          })(),
        ]);
        if (isMounted()) {
          setAssetTokenViewModels(retrievedLedgerTokenViewModels);
          setPotentialGroupOwners(retrievedPotentialGroupOwners);
          setDataLoaded(true);
        }
      } catch (e) {
        // if anything goes wrong store a data loaded error
        console.error("error initialising", e);
        setDataLoadError("Error Fetching Required Data.");
      }
    })();
  }, [dataLoaded, dataLoadError, isMounted]);

  // ----------- instrument loaded -----------
  const [instrumentLoaded, setInstrumentLoaded] = useState(false);
  const [instrumentLoadError, setInstrumentLoadError] = useState<string | null>(
    null,
  );
  useEffect(() => {
    (async () => {
      // do nothing if:
      // - data not yet loaded
      // - instrument already loaed
      // - if there was an instrument loading error
      // - component no longer mounted
      if (
        !dataLoaded ||
        instrumentLoaded ||
        instrumentLoadError ||
        !isMounted()
      ) {
        return;
      }

      // get an id from the url
      const urlID = searchParams.get("id");

      // if the smart instrument in the form is already set to the
      // smart instrument identified by the id found in the URL then do nothing
      if (formData.smartInstrument.getId() === urlID) {
        return;
      }

      // prepare instrument and associated corporat action data for state
      let instrumentForState: SmartInstrument | undefined;

      // If the id in the url is set then fetch the associated instrument,
      // otherwise populate the form state with a blank new instrument.
      if (urlID) {
        try {
          instrumentForState = (
            system
              ? await smartInstrumentReaderUNSCOPED.readOneSmartInstrumentUNSCOPED(
                  new ReadOneSmartInstrumentRequest()
                    .setContext(authContext.toFuture())
                    .setCriteriaList([newTextExactCriterion("id", urlID)]),
                )
              : await smartInstrumentReader.readOneSmartInstrument(
                  new ReadOneSmartInstrumentRequest()
                    .setContext(authContext.toFuture())
                    .setCriteriaList([newTextExactCriterion("id", urlID)]),
                )
          ).getSmartinstrument();
        } catch (e) {
          // if anything goes wrong store a data loaded error
          setInstrumentLoadError("Error Fetching Instrument Data.");
          return;
        }
      } else {
        instrumentForState = new SmartInstrument()
          .setId("")
          .setState(SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE)
          .setName("New Smart Instrument")
          .setOwnerid(potentialGroupOwners[0].id)
          .setUnitnominal(
            new TokenWrapper(
              (
                assetTokenViewModels.find((at) => at.token.code === "mZAR") ??
                assetTokenViewModels[0]
              ).token.toFutureToken(),
            ).newAmountOf(new BigNumber("100")),
          )
          .setAssetclass(AssetClass.FIXED_INCOME_ASSET_CLASS)
          .setUnitcategory(UnitCategory.NOTE_UNIT_CATEGORY)
          .setIssuedate(
            dayjsToProtobufTimestamp(
              dayjs().utc().startOf("day").set("h", -2).add(1, "months"),
            ),
          )
          .setTimezone(Timezone.UTC_TIMEZONE)
          .setFractionalisationallowed(true);
      }

      // only update state after fetching the associated data if the component is still mounted
      if (!isMounted()) {
        return;
      }

      // update the smart instrument in state
      if (!instrumentForState) {
        setInstrumentLoadError("Instrument not correctly initialised.");
        return;
      }
      formDataUpdater.smartInstrument(instrumentForState);

      setInstrumentLoaded(true);
    })();
  }, [dataLoaded, instrumentLoaded, instrumentLoadError, isMounted]);

  const [rateResetsLoaded, setRateResetsLoaded] = useState(false);
  const reloadRateResets = () => setRateResetsLoaded(false);
  const [rateResetLoadError, setRateResetLoadError] = useState<string | null>(
    null,
  );
  const clearRateResetLoadError = () => setRateResetLoadError(null);
  const [rateResets, setRateResets] = useState<RateResetCorporateAction[]>([]);
  useEffect(() => {
    // do nothing if
    // - smart instrument is not yet loaded OR
    // - smart instrument is still draft OR
    // - there was an error loading rate resets OR
    // - rate resets are already loaded
    if (
      !instrumentLoaded ||
      formData.smartInstrument.getState() ===
        SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE ||
      rateResetLoadError ||
      rateResetsLoaded
    ) {
      return;
    }

    (async () => {
      let rateResetsForState: RateResetCorporateAction[];
      try {
        rateResetsForState = (
          await (system
            ? rateResetCorporateActionReaderUNSCOPED.readManyRateResetCorporateActionUNSCOPED(
                new ReadManyRateResetCorporateActionRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion(
                      "smartinstrumentid",
                      formData.smartInstrument.getId(),
                    ),
                  ]),
              )
            : rateResetCorporateActionReader.readManyRateResetCorporateAction(
                new ReadManyRateResetCorporateActionRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion(
                      "smartinstrumentid",
                      formData.smartInstrument.getId(),
                    ),
                  ]),
              ))
        ).getRecordsList();
      } catch (e) {
        setRateResetLoadError("Error Fetching Rate Resets.");
        return;
      }

      if (isMounted()) {
        setRateResets(rateResetsForState);
        setRateResetsLoaded(true);
      }
    })();
  }, [instrumentLoaded, isMounted, formData.smartInstrument.getState()]);

  const [assetflowsLoaded, setAssetflowsLoaded] = useState(false);
  const reloadAssetflows = () => setAssetflowsLoaded(false);
  const [assetflowLoadError, setAssetflowLoadError] = useState<string | null>(
    null,
  );
  const clearAssetflowLoadError = () => setAssetflowLoadError(null);
  const [assetflows, setAssetflows] = useState<Assetflow[]>([]);
  useEffect(() => {
    // do nothing if
    // - smart instrument is not yet loaded OR
    // - smart instrument is still draft OR
    // - there was an error loading rate resets OR
    // - rate resets are already loaded
    if (
      !instrumentLoaded ||
      formData.smartInstrument.getState() ===
        SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE ||
      assetflowLoadError ||
      assetflowsLoaded
    ) {
      return;
    }

    (async () => {
      let assetflowsForState: Assetflow[];
      try {
        assetflowsForState = (
          await (system
            ? assetflowReaderUNSCOPED.readManyAssetflowUNSCOPED(
                new ReadManyAssetflowRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion(
                      "smartinstrumentid",
                      formData.smartInstrument.getId(),
                    ),
                  ]),
              )
            : assetflowReader.readManyAssetflow(
                new ReadManyAssetflowRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion(
                      "smartinstrumentid",
                      formData.smartInstrument.getId(),
                    ),
                  ]),
              ))
        ).getRecordsList();
      } catch (e) {
        setAssetflowLoadError("Error Fetching Rate Resets.");
        return;
      }

      if (isMounted()) {
        setAssetflows(assetflowsForState);
        setAssetflowsLoaded(true);
      }
    })();
  }, [instrumentLoaded, isMounted, formData.smartInstrument.getState()]);

  const [paymentsLoaded, setPaymentsLoaded] = useState(false);
  const reloadPayments = () => setPaymentsLoaded(false);
  const [paymentLoadError, setPaymentLoadError] = useState<string | null>(null);
  const clearPaymentLoadError = () => setPaymentLoadError(null);
  const [payments, setPayments] = useState<Payment[]>([]);
  useEffect(() => {
    // do nothing if
    // - smart instrument is not yet loaded OR
    // - smart instrument is still draft OR
    // - there was an error loading rate resets OR
    // - rate resets are already loaded
    if (
      !instrumentLoaded ||
      formData.smartInstrument.getState() ===
        SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE ||
      paymentLoadError ||
      paymentsLoaded
    ) {
      return;
    }

    (async () => {
      let paymentsForState: Payment[];
      try {
        paymentsForState = (
          await (system
            ? paymentReaderUNSCOPED.readManyPaymentUNSCOPED(
                new ReadManyPaymentRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion(
                      "smartinstrumentid",
                      formData.smartInstrument.getId(),
                    ),
                  ]),
              )
            : paymentReader.readManyPayment(
                new ReadManyPaymentRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion(
                      "smartinstrumentid",
                      formData.smartInstrument.getId(),
                    ),
                  ]),
              ))
        ).getRecordsList();
      } catch (e) {
        setPaymentLoadError("Error Fetching Rate Resets.");
        return;
      }

      if (isMounted()) {
        setPayments(paymentsForState);
        setPaymentsLoaded(true);
      }
    })();
  }, [instrumentLoaded, isMounted, formData.smartInstrument.getState()]);

  const [paymentDeferralsLoaded, setPaymentDeferralsLoaded] = useState(false);
  const reloadPaymentDeferrals = () => setPaymentDeferralsLoaded(false);
  const [paymentDeferralLoadError, setPaymentDeferralLoadError] = useState<
    string | null
  >(null);
  const clearPaymentDeferralLoadError = () => setPaymentDeferralLoadError(null);
  const [paymentDeferrals, setPaymentDeferrals] = useState<
    PaymentDeferralCorporateAction[]
  >([]);
  useEffect(() => {
    // do nothing if
    // - smart instrument is not yet loaded OR
    // - smart instrument is still draft OR
    // - there was an error loading rate resets OR
    // - rate resets are already loaded
    if (
      !instrumentLoaded ||
      formData.smartInstrument.getState() ===
        SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE ||
      paymentDeferralLoadError ||
      paymentDeferralsLoaded
    ) {
      return;
    }

    (async () => {
      let paymentDeferralsForState: PaymentDeferralCorporateAction[];
      try {
        paymentDeferralsForState = (
          await (system
            ? paymentDeferralCorporateActionReaderUNSCOPED.readManyPaymentDeferralCorporateActionUNSCOPED(
                new ReadManyPaymentDeferralCorporateActionRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion(
                      "smartinstrumentid",
                      formData.smartInstrument.getId(),
                    ),
                  ]),
              )
            : paymentDeferralCorporateActionReader.readManyPaymentDeferralCorporateAction(
                new ReadManyPaymentDeferralCorporateActionRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion(
                      "smartinstrumentid",
                      formData.smartInstrument.getId(),
                    ),
                  ]),
              ))
        ).getRecordsList();
      } catch (e) {
        setPaymentDeferralLoadError("Error Fetching Rate Resets.");
        return;
      }

      if (isMounted()) {
        setPaymentDeferrals(paymentDeferralsForState);
        setPaymentDeferralsLoaded(true);
      }
    })();
  }, [instrumentLoaded, isMounted, formData.smartInstrument.getState()]);

  const clearInitialisationError = useCallback(() => {
    setDataLoadError(null);
    setInstrumentLoadError(null);
  }, [dataLoadError, instrumentLoadError]);

  // ----------- view mode -----------
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Undefined);
  const setEditViewMode = () => {
    const query = new URLSearchParams(searchParams);
    query.set("mode", ViewMode.Edit);
    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
    setViewMode(ViewMode.Edit);
  };
  const setViewViewMode = () => {
    const query = new URLSearchParams(searchParams);
    query.set("mode", ViewMode.View);
    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
    setViewMode(ViewMode.View);
  };
  useEffect(() => {
    if (
      // if view mode is not undefined OR
      viewMode !== ViewMode.Undefined ||
      // data not yet loaded or there was an error while loading OR
      !dataLoaded ||
      dataLoadError ||
      // instrument not yet loaded or that was an error while loading
      !instrumentLoaded ||
      instrumentLoadError
    ) {
      // then do nothing
      return;
    }
    // otherwise set the initial view mode

    // prepare url search params from the existing search params
    const query = new URLSearchParams(searchParams);

    if (formData.smartInstrument.getId() === "") {
      // if the instrument ID is not set and the user has permission to
      // create a new instrument
      if (viewConfiguration["Smart Instruments"]?.Write?.CreateDraft) {
        // then edit mode should be active
        query.set("mode", ViewMode.Edit);
        navigate({
          pathname: location.pathname,
          search: query.toString(),
        });
        setViewMode(ViewMode.Edit);
      } else {
        // otherwise the user should not see this screen
        navigate({ pathname: "/builder/smart-instruments/table" });
      }
    } else {
      // if execution reaches here then the instrument ID is set,
      // consider if a view mode has been given in the search query
      const viewModeFromURL = searchParams.get("mode");
      let newViewMode: ViewMode = viewModeFromURL
        ? (viewModeFromURL as ViewMode)
        : ViewMode.View;

      // if edit mode was requested and the user doesn't have permission to edit then
      // default to view mode
      if (
        newViewMode === ViewMode.Edit &&
        !viewConfiguration["Smart Instruments"]?.Write?.UpdateDraft
      ) {
        newViewMode = ViewMode.View;
      }

      // set view mode in state and url
      query.set("mode", newViewMode);
      navigate({
        pathname: location.pathname,
        search: query.toString(),
      });
      setViewMode(newViewMode);
    }
  }, [
    viewMode,
    formData.smartInstrument,
    instrumentLoaded,
    instrumentLoadError,
    dataLoaded,
    dataLoadError,
    viewConfiguration,
  ]);

  // ----------- simulation -----------
  const [simulationMode, setSimulationMode] = useState(false);
  const toggleSimulationMode = useCallback(
    () => setSimulationMode((prev) => !prev),
    [simulationMode],
  );
  const [simulationInProgress, setSimulationInProgress] = useState(false);
  const [simulationError, setSimulationError] = useState<string | null>(null);
  const [simulatedRateResets, setSimulatedRateResets] = useState<
    RateResetCorporateAction[]
  >([]);
  const [simulatedAssetflows, setSimulatedAssetflows] = useState<Assetflow[]>(
    [],
  );
  const runSimulation = async () => {
    // indicate that simulation is in progress
    setSimulationInProgress(true);

    // clear simulation error
    setSimulationError(null);

    // clear last simulation result
    setSimulatedRateResets([]);
    setSimulatedAssetflows([]);

    // generate rate resets
    let updatedSimulatedRateResets: RateResetCorporateAction[];
    try {
      updatedSimulatedRateResets = (
        await smartInstrumentRateResetGenerator.generateSmartInstrumentRateResets(
          new GenerateSmartInstrumentRateResetsRequest()
            .setEvaluationdate(dayjsToProtobufTimestamp(dayjs()))
            .setSmartinstrument(formData.smartInstrument),
        )
      ).getRateresetsList();
    } catch (e) {
      console.error("error generating rate resets", e);
      setSimulationError(`error generating rate resets: ${e}`);
      setSimulationInProgress(false);
      return;
    }

    // calcualte asset flows
    let updatedSimulatedAssetflows: Assetflow[];
    try {
      updatedSimulatedAssetflows = (
        await smartInstrumentCalculator.calculateSmartInstrumentAssetflows(
          new CalculateSmartInstrumentAssetflowsRequest()
            .setEvaluationdate(dayjsToProtobufTimestamp(dayjs()))
            .setRateresetsList(updatedSimulatedRateResets)
            .setSmartinstrument(formData.smartInstrument),
        )
      ).getAssetflowsList();
    } catch (e) {
      console.error("error calculating asset flows", e);
      setSimulationError(`error calculating asset flows: ${e}`);
      setSimulationInProgress(false);
      return;
    }

    // indicate that simulation is stopped
    setSimulatedRateResets(updatedSimulatedRateResets);
    setSimulatedAssetflows(updatedSimulatedAssetflows);
    setSimulationInProgress(false);
  };
  useEffect(() => {
    // simulation mode is on if the instrument is draft
    setSimulationMode(
      formData.smartInstrument.getState() ===
        SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE,
    );
  }, [formData.smartInstrument.getState()]);

  // ----------- actions -----------
  const [saveDraftOrCreateInProgress, setSaveDraftOrCreateInProgress] =
    useState(false);
  const saveDraftOrCreateAction = async () => {
    // saving can only be done in edit mode
    if (viewMode !== ViewMode.Edit) {
      return;
    }

    // save draft or create only available when instrument is in the draft state
    // (the determination between performing a draft save and a create being if the ID is set or not)
    if (
      formData.smartInstrument.getState() !==
      SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE
    ) {
      return;
    }

    // perform save draft or create
    setSaveDraftOrCreateInProgress(true);
    try {
      let updatedSmartInstrument: SmartInstrument | undefined;
      if (formData.smartInstrument.getId() === "") {
        updatedSmartInstrument = (
          await smartInstrumentStateController.createDraftSmartInstrument(
            new CreateDraftSmartInstrumentRequest()
              .setContext(authContext.toFuture())
              .setOwnerid(formData.smartInstrument.getOwnerid())
              .setName(formData.smartInstrument.getName())
              .setAssetclass(formData.smartInstrument.getAssetclass())
              .setIssuedate(formData.smartInstrument.getIssuedate())
              .setTimezone(formData.smartInstrument.getTimezone())
              .setUnitcategory(formData.smartInstrument.getUnitcategory())
              .setUnitnominal(formData.smartInstrument.getUnitnominal())
              .setFractionalisationallowed(
                formData.smartInstrument.getFractionalisationallowed(),
              )
              .setLegsList(formData.smartInstrument.getLegsList())
              .setCallabilityconfiguration(
                formData.smartInstrument.getCallabilityconfiguration(),
              ),
          )
        ).getSmartinstrument();
      } else {
        // only call the api to perform update if the smart instrument has changed
        if (
          isEqual(
            formData.smartInstrument.toObject(),
            formData.smartIntrumentCopy.toObject(),
          )
        ) {
          updatedSmartInstrument = formData.smartInstrument;
        } else {
          updatedSmartInstrument = (
            await smartInstrumentUpdater.draftUpdateSmartInstrument(
              new DraftUpdateSmartInstrumentRequest()
                .setContext(authContext.toFuture())
                .setSmartinstrument(formData.smartInstrument),
            )
          ).getSmartinstrument();
        }
      }

      if (updatedSmartInstrument) {
        // replace the instrument stored in state with the updated instrument returned from api call
        formDataUpdater.smartInstrument(updatedSmartInstrument);

        // update view mode and id url search parameters
        const query = new URLSearchParams();
        query.set("mode", ViewMode.View);
        query.set("id", updatedSmartInstrument.getId());
        navigate({
          pathname: location.pathname,
          search: query.toString(),
        });

        // set view mode to view
        setViewMode(ViewMode.View);
      } else {
        // this should never happen
        console.warn("smart instrument not set after performing save actions");
      }
      enqueueSnackbar("Saved!", { variant: "success" });
    } catch (e) {
      errorContextDefaultWarningFeedback(e, "error performing save action");
    }
    setSaveDraftOrCreateInProgress(false);
  };

  const [preissueInProgress, setPreIssueInProgress] = useState(false);
  const preIssueAction = async () => {
    // instrument can only be pre-issued if it is valid and in draft state
    if (
      !formDataValidationResult.valid ||
      formData.smartInstrument.getState() !==
        SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE
    ) {
      return;
    }

    // perform preIssue
    setPreIssueInProgress(true);
    try {
      const preIssuedSmartInstrument = (
        await smartInstrumentStateController.preIssueSmartInstrument(
          new PreIssueSmartInstrumentRequest()
            .setContext(authContext.toFuture())
            .setSmartinstrumentid(formData.smartInstrument.getId()),
        )
      ).getSmartinstrument();
      if (!isMounted()) {
        return;
      }
      if (!preIssuedSmartInstrument) {
        console.warn("smart instrument not set after preissuance");
        return;
      }
      formDataUpdater.smartInstrument(preIssuedSmartInstrument);
      enqueueSnackbar("PreIssued!", { variant: "success" });
    } catch (e) {
      errorContextDefaultWarningFeedback(e, "error performing preissue action");
    }
    setPreIssueInProgress(false);
  };

  const [changeDocumentsInProgress, setChangeDocumentsInProgress] =
    useState(false);
  const changeDocumentsAction = async () => {
    // This action is only available if the smart instrument is NOT in a draft state.
    // Until then document updates are made using the draft update method.
    if (
      formData.smartInstrument.getState() ===
      SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE
    ) {
      return;
    }

    // perform document update
    setChangeDocumentsInProgress(true);
    try {
      // only call the api to perform update if the smart instrument documents have changed
      let updatedSmartInstrument: SmartInstrument | undefined;
      if (
        isEqual(
          formData.smartInstrument.getDocumentsList().map((d) => d.toObject()),
          formData.smartIntrumentCopy
            .getDocumentsList()
            .map((d) => d.toObject()),
        )
      ) {
        updatedSmartInstrument = formData.smartInstrument;
      } else {
        updatedSmartInstrument = (
          await smartInstrumentUpdater.changeSmartInstrumentDocuments(
            new ChangeSmartInstrumentDocumentsRequest()
              .setContext(authContext.toFuture())
              .setSmartinstrumentid(formData.smartInstrument.getId())
              .setUpdateddocumentsList(
                formData.smartInstrument.getDocumentsList(),
              ),
          )
        ).getSmartinstrument();
      }

      if (updatedSmartInstrument) {
        // replace the instrument stored in state with the updated instrument returned from api call
        formDataUpdater.smartInstrument(updatedSmartInstrument);
      } else {
        // this should never happen
        console.warn(
          "smart instrument not set after performing documents changed action",
        );
      }
      enqueueSnackbar("Saved!", { variant: "success" });
    } catch (e) {
      errorContextDefaultWarningFeedback(e, "error performing save action");
    }
    setChangeDocumentsInProgress(false);
  };

  return (
    <BuiderContext.Provider
      value={{
        apiCallInProgress:
          simulationInProgress ||
          saveDraftOrCreateInProgress ||
          preissueInProgress ||
          changeDocumentsInProgress,

        viewMode,
        setEditViewMode,
        setViewViewMode,

        initialised: dataLoaded && instrumentLoaded,
        initialisationError: dataLoadError || instrumentLoadError,
        clearInitialisationError,

        ledgerTokenViewModels: assetTokenViewModels,
        potentialGroupOwners,

        formData,
        formDataValidationResult,
        formDataUpdater,

        rateResets,
        rateResetsLoaded,
        rateResetLoadError,
        clearRateResetLoadError,
        reloadRateResets,

        paymentDeferrals,
        paymentDeferralsLoaded,
        paymentDeferralLoadError,
        clearPaymentDeferralLoadError,
        reloadPaymentDeferrals,

        assetflows,
        assetflowsLoaded,
        assetflowLoadError,
        clearAssetflowLoadError,
        reloadAssetflows,

        payments,
        paymentsLoaded,
        paymentLoadError,
        clearPaymentLoadError,
        reloadPayments,

        simulationMode,
        toggleSimulationMode,
        runSimulation,
        simulationInProgress,
        simulationError,
        simulatedRateResets,
        simulatedPaymentDeferrals: [],
        simulatedAssetflows,
        simulatedPayments: [],

        saveDraftOrCreateAction,
        changeDocumentsAction,
        preIssueAction,
      }}
    >
      {children}
    </BuiderContext.Provider>
  );
};
