import config from "react-global-configuration";
import { Context } from "../security";
import { Amount } from "../ledger/Amount";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";

export interface FundAccountRequest {
  context: Context;
  currencyStablecoinID: string;
  amount: Amount;
  bankReference: string;
  accountNumber: string;
}

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FundAccountResponse {}

export const AccountFunderServiceProviderName = "banking-AccountFunder";

export const AccountFunder = {
  async FundAccount(
    request: FundAccountRequest,
    additionalHeaders?: Headers,
  ): Promise<FundAccountResponse> {
    return jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${AccountFunderServiceProviderName}.FundAccount`,
      request,
    });
  },
};
