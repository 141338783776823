import {
  Calendar,
  DateGenerationRule,
  DigitalFixedRateBond,
  InstrumentRiskProfile,
  InvestorProfile,
} from "../../../../../james/financial";
import { FormData } from "../index";
import { ValidationResult } from "../../../../../common/validation";
import dayjs from "dayjs";
import { DebtSeniorityLevel } from "../../../../../james/financial/DebtSeniorityLevel";
import { InstrumentOwnershipLimitations } from "../../../../../james/financial/InstrumentOwnershipLimitations";
import { Amount, Token } from "../../../../../james/ledger";
import BigNumber from "bignumber.js";
import { CouponFrequency } from "../../../../../james/financial/CouponFrequency";
import { DayCountConvention } from "../../../../../james/financial/DayCountConvention";
import { BusinessDayConvention } from "../../../../../james/financial/BusinessDayConvention";
import { Document } from "../../../../../james/document";

export type DigitalFixedRateBondFormData = {
  generalFormData: FormData;
  digitalFixedRateBond: DigitalFixedRateBond;
};

export type FormUpdaterSpecsType = {
  digitalFixedRateBond: (
    digitalFixedRateBond: DigitalFixedRateBond,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  name: (
    name: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  isin: (
    isin: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  fractionalisationAllowed: (
    fractionalisationAllowed: boolean,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  investorProfile: (
    investorProfile: InvestorProfile,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  investorProfileDescription: (
    investorProfileDescription: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  riskProfile: (
    riskProfile: InstrumentRiskProfile,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  riskProfileDescription: (
    riskProfileDescription: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  debtSeniorityLevel: (
    debtSeniorityLevel: DebtSeniorityLevel,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  covenants: (
    covenants: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  votingRights: (
    votingRights: boolean,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  votingRightsDescription: (
    votingRights: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  ownershipLimitations: (
    ownershipLimitations: InstrumentOwnershipLimitations,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  issuePrice: (
    issuePrice: Amount,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  nominal: (
    nominal: Amount,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  currencyStablecoinToken: (
    token: Token,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;

  // --------------- FixedRateBondStub fields (i.e. fields required for calculations) ----------------------
  totalNominal: (
    totalNominal: Amount,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  redemption: (
    redemption: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  issueDate: (
    issueDate: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  maturityDate: (
    maturityDate: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  calendar: (
    calendar: Calendar,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  couponPaymentFrequency: (
    couponPaymentFrequency: CouponFrequency,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  firstCouponPaymentDate: (
    firstCouponPaymentDate: string | null,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  nextToLastCouponPaymentDate: (
    nextToLastCouponPaymentDate: string | null,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  couponInterestCommencementDate: (
    couponInterestCommencementDate: string | null,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  dayCountConvention: (
    dayCountConvention: DayCountConvention,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  endOfMonthConvention: (
    endOfMonthConvention: boolean,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  businessDayConvention: (
    businessDayConvention: BusinessDayConvention,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  terminationDateBusinessDayConvention: (
    terminationDateBusinessDayConvention: BusinessDayConvention,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  dateGenerationRule: (
    dateGenerationRule: DateGenerationRule,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  fixDays: (
    fixDays: number,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  exCouponDays: (
    exCouponDays: number,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  recordDays: (
    recordDays: number,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  couponRate: (
    couponRate: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
  supportingDocuments: (
    documents: Document[],
    prevFormData?: DigitalFixedRateBondFormData,
  ) => DigitalFixedRateBondFormData;
};

export const formDataUpdaterSpecs: FormUpdaterSpecsType = {
  digitalFixedRateBond(
    digitalFixedRateBond: DigitalFixedRateBond,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(digitalFixedRateBond),
    };
  },
  name(
    name: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // perform update
    formData.digitalFixedRateBond.name =
      name.length > 60 ? formData.digitalFixedRateBond.name : name;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  isin(
    isin: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // perform update
    formData.digitalFixedRateBond.isin =
      isin.length > 20 ? formData.digitalFixedRateBond.isin : isin;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  fractionalisationAllowed(
    fractionalisationAllowed: boolean,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.fractionalisationAllowed =
      fractionalisationAllowed;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  investorProfile(
    investorProfile: InvestorProfile,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.investorProfile = investorProfile;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  investorProfileDescription(
    investorProfileDescription: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.investorProfileDescription =
      investorProfileDescription.length > 400
        ? formData.digitalFixedRateBond.investorProfileDescription
        : investorProfileDescription;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  riskProfile(
    riskProfile: InstrumentRiskProfile,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.riskProfile = riskProfile;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  riskProfileDescription(
    riskProfileDescription: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.riskProfileDescription =
      riskProfileDescription.length > 400
        ? formData.digitalFixedRateBond.riskProfileDescription
        : riskProfileDescription;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  debtSeniorityLevel(
    debtSeniorityLevel: DebtSeniorityLevel,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.debtSeniorityLevel = debtSeniorityLevel;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  covenants(
    covenants: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.covenants =
      covenants.length > 200
        ? formData.digitalFixedRateBond.covenants
        : covenants;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  votingRights(
    votingRights: boolean,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.votingRights = votingRights;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  votingRightsDescription(
    votingRightsDescription: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.votingRightsDescription =
      votingRightsDescription.length > 400
        ? formData.digitalFixedRateBond.votingRightsDescription
        : votingRightsDescription;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  ownershipLimitations(
    ownershipLimitations: InstrumentOwnershipLimitations,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.ownershipLimitations = ownershipLimitations;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  issuePrice(
    issuePrice: Amount,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.issuePrice = issuePrice;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  nominal(
    nominal: Amount,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.nominal = nominal;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  currencyStablecoinToken(
    token: Token,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.issuePrice = token.newAmountOf(
      formData.digitalFixedRateBond.issuePrice.value,
    );
    formData.digitalFixedRateBond.nominal = token.newAmountOf(
      formData.digitalFixedRateBond.nominal.value,
    );
    formData.digitalFixedRateBond.totalNominal = token.newAmountOf(
      formData.digitalFixedRateBond.totalNominal.value,
    );
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },

  // --------------- FixedRateBondStub fields (i.e. fields required for calculations) ----------------------
  totalNominal(
    totalNominal: Amount,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.totalNominal = totalNominal;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  redemption(
    redemption: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.redemption = new BigNumber(redemption);
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  issueDate(
    issueDate: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // parse given date
    const newIssueDate = dayjs(issueDate);

    // find associated currency stablecoin and underlying currency
    const ccyStablecoin = formData.generalFormData.currencyStablecoins.find(
      (ccyStablecoin) =>
        ccyStablecoin.token.isEqualTo(
          formData.digitalFixedRateBond.totalNominal.token,
        ),
    );
    const ccy = formData.generalFormData.currencies.find(
      (ccy) => ccy.currencyID() === ccyStablecoin?.currencyID,
    );

    // confirm currency found and date valid
    if (!ccy || !newIssueDate.isValid()) {
      return {
        ...formData,
      };
    }

    // adjust dates appropriately
    formData.digitalFixedRateBond.issueDate = ccy.firstStartOfDayBefore(
      ccy.firstCutOffAfter(newIssueDate.format()),
    );

    // update bond
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  maturityDate(
    maturityDate: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // parse given date
    const newMaturityDate = dayjs(maturityDate);

    // find associated currency stablecoin and underlying currency
    const ccyStablecoin = formData.generalFormData.currencyStablecoins.find(
      (ccyStablecoin) =>
        ccyStablecoin.token.isEqualTo(
          formData.digitalFixedRateBond.totalNominal.token,
        ),
    );
    const ccy = formData.generalFormData.currencies.find(
      (ccy) => ccy.currencyID() === ccyStablecoin?.currencyID,
    );

    // confirm currency found and date valid
    if (!ccy || !newMaturityDate.isValid()) {
      return {
        ...formData,
      };
    }

    // adjust dates appropriately
    formData.digitalFixedRateBond.maturityDate = ccy.firstStartOfDayBefore(
      ccy.firstCutOffAfter(newMaturityDate.format()),
    );

    // update bond
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  calendar(
    calendar: Calendar,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.calendar = calendar;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  couponPaymentFrequency(
    couponPaymentFrequency: CouponFrequency,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.couponPaymentFrequency =
      couponPaymentFrequency;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  firstCouponPaymentDate(
    firstCouponPaymentDate: string | null,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    if (firstCouponPaymentDate === null) {
      return {
        ...formData,
        digitalFixedRateBond: new DigitalFixedRateBond(
          formData.digitalFixedRateBond,
        ),
      };
    }

    // parse given date
    const newFirstCouponPaymentDate = dayjs(firstCouponPaymentDate);

    // find associated currency stablecoin and underlying currency
    const ccyStablecoin = formData.generalFormData.currencyStablecoins.find(
      (ccyStablecoin) =>
        ccyStablecoin.token.isEqualTo(
          formData.digitalFixedRateBond.totalNominal.token,
        ),
    );
    const ccy = formData.generalFormData.currencies.find(
      (ccy) => ccy.currencyID() === ccyStablecoin?.currencyID,
    );

    // confirm currency found and date valid
    if (!ccy || !newFirstCouponPaymentDate.isValid()) {
      formData.digitalFixedRateBond.firstCouponPaymentDate = null;
      return {
        ...formData,
        digitalFixedRateBond: new DigitalFixedRateBond(
          formData.digitalFixedRateBond,
        ),
      };
    }

    // adjust dates appropriately
    formData.digitalFixedRateBond.firstCouponPaymentDate =
      ccy.firstStartOfDayBefore(
        ccy.firstCutOffAfter(newFirstCouponPaymentDate.format()),
      );

    // update bond
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  nextToLastCouponPaymentDate(
    nextToLastCouponPaymentDate: string | null,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    if (nextToLastCouponPaymentDate === null) {
      return {
        ...formData,
        digitalFixedRateBond: new DigitalFixedRateBond(
          formData.digitalFixedRateBond,
        ),
      };
    }

    // parse given date
    const newNextToLastCouponPaymentDate = dayjs(nextToLastCouponPaymentDate);

    // find associated currency stablecoin and underlying currency
    const ccyStablecoin = formData.generalFormData.currencyStablecoins.find(
      (ccyStablecoin) =>
        ccyStablecoin.token.isEqualTo(
          formData.digitalFixedRateBond.totalNominal.token,
        ),
    );
    const ccy = formData.generalFormData.currencies.find(
      (ccy) => ccy.currencyID() === ccyStablecoin?.currencyID,
    );

    // confirm currency found and date valid
    if (!ccy || !newNextToLastCouponPaymentDate.isValid()) {
      formData.digitalFixedRateBond.nextToLastCouponPaymentDate = null;
      return {
        ...formData,
        digitalFixedRateBond: new DigitalFixedRateBond(
          formData.digitalFixedRateBond,
        ),
      };
    }

    // adjust dates appropriately
    formData.digitalFixedRateBond.nextToLastCouponPaymentDate =
      ccy.firstStartOfDayBefore(
        ccy.firstCutOffAfter(newNextToLastCouponPaymentDate.format()),
      );

    // update bond
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  couponInterestCommencementDate(
    couponInterestCommencementDate: string | null,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    if (couponInterestCommencementDate === null) {
      return {
        ...formData,
        digitalFixedRateBond: new DigitalFixedRateBond(
          formData.digitalFixedRateBond,
        ),
      };
    }

    // parse given date
    const newCouponInterestCommencementDate = dayjs(
      couponInterestCommencementDate,
    );

    // find associated currency stablecoin and underlying currency
    const ccyStablecoin = formData.generalFormData.currencyStablecoins.find(
      (ccyStablecoin) =>
        ccyStablecoin.token.isEqualTo(
          formData.digitalFixedRateBond.totalNominal.token,
        ),
    );
    const ccy = formData.generalFormData.currencies.find(
      (ccy) => ccy.currencyID() === ccyStablecoin?.currencyID,
    );

    // confirm currency found and date valid
    if (!ccy || !newCouponInterestCommencementDate.isValid()) {
      formData.digitalFixedRateBond.couponInterestCommencementDate = null;
      return {
        ...formData,
        digitalFixedRateBond: new DigitalFixedRateBond(
          formData.digitalFixedRateBond,
        ),
      };
    }

    // adjust dates appropriately
    formData.digitalFixedRateBond.couponInterestCommencementDate =
      ccy.firstStartOfDayBefore(
        ccy.firstCutOffAfter(newCouponInterestCommencementDate.format()),
      );

    // update bond
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  dayCountConvention(
    dayCountConvention: DayCountConvention,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.dayCountConvention = dayCountConvention;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  endOfMonthConvention(
    endOfMonthConvention: boolean,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.endOfMonthConvention = endOfMonthConvention;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  businessDayConvention(
    businessDayConvention: BusinessDayConvention,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.businessDayConvention = businessDayConvention;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  terminationDateBusinessDayConvention(
    terminationDateBusinessDayConvention: BusinessDayConvention,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.terminationDateBusinessDayConvention =
      terminationDateBusinessDayConvention;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  dateGenerationRule(
    dateGenerationRule: DateGenerationRule,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.dateGenerationRule = dateGenerationRule;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  fixDays(
    fixDays: number,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.fixDays = fixDays;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  exCouponDays(
    exCouponDays: number,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.exCouponDays = exCouponDays;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  recordDays(
    recordDays: number,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.recordDays = recordDays;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  couponRate(
    couponRate: string,
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    // update bond
    formData.digitalFixedRateBond.couponRate = new BigNumber(couponRate);
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
  supportingDocuments(
    documents: Document[],
    prevFormData?: DigitalFixedRateBondFormData,
  ): DigitalFixedRateBondFormData {
    const formData = prevFormData as DigitalFixedRateBondFormData;

    formData.digitalFixedRateBond.supportingDocuments = documents;
    return {
      ...formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        formData.digitalFixedRateBond,
      ),
    };
  },
};

const isinRegex = /\b[A-Z]{2}[A-Z0-9]{9}[0-9]\b/;

export const formDataValidationFunc = async (
  formData: DigitalFixedRateBondFormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  // name
  if (formData.digitalFixedRateBond.name.length === 0) {
    validationResult.valid = false;
    validationResult.fieldValidations.name = "Cannot be blank";
  } else if (formData.digitalFixedRateBond.name.length < 3) {
    validationResult.valid = false;
    validationResult.fieldValidations.name = "Cannot be less than 3 characters";
  }

  // isin
  if (
    formData.digitalFixedRateBond.isin.length > 0 &&
    !isinRegex.test(formData.digitalFixedRateBond.isin)
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.name = "Must be a valid ISIN";
  }

  // investor profile description
  if (formData.digitalFixedRateBond.investorProfileDescription.length === 0) {
    validationResult.valid = false;
    validationResult.fieldValidations.investorProfileDescription =
      "Cannot be blank";
  }

  // risk profile description
  if (formData.digitalFixedRateBond.riskProfileDescription.length === 0) {
    validationResult.valid = false;
    validationResult.fieldValidations.riskProfileDescription =
      "Cannot be blank";
  }

  // risk profile description
  if (
    formData.digitalFixedRateBond.votingRights &&
    formData.digitalFixedRateBond.votingRightsDescription.length === 0
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.votingRightsDescription =
      "Cannot be blank";
  }

  // issue price
  if (formData.digitalFixedRateBond.issuePrice.value.lte(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations.issuePrice = "Must be greater than 0";
  }

  // nominal
  if (formData.digitalFixedRateBond.nominal.value.lte(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations.nominal = "Must be greater than 0";
  }

  // --------------- FixedRateBondStub fields (i.e. fields required for calculations) ----------------------

  // total nominal
  if (formData.digitalFixedRateBond.totalNominal.value.lte(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations.totalNominal = "Must be greater than 0";
  } else if (
    formData.digitalFixedRateBond.totalNominal.value.lte(
      formData.digitalFixedRateBond.nominal.value,
    )
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.totalNominal =
      "Must be greater than nominal";
  }

  // redemption
  if (formData.digitalFixedRateBond.redemption.lte(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations.redemption = "Must be greater than 0";
  }

  // issueDate
  const issueDate = dayjs(formData.digitalFixedRateBond.issueDate);
  if (!issueDate.isValid()) {
    validationResult.valid = false;
    validationResult.fieldValidations.issueDate = "Must be a valid date";
  } else if (issueDate.isBefore(dayjs())) {
    validationResult.valid = false;
    validationResult.fieldValidations.issueDate = "Must be in the future";
  }
  // TODO: add valid business day checking for dates

  // maturityDate
  const maturityDate = dayjs(formData.digitalFixedRateBond.maturityDate);
  if (!maturityDate.isValid()) {
    validationResult.valid = false;
    validationResult.fieldValidations.maturityDate = "Must be a valid date";
  }

  // first coupon payment date
  const firstCouponPaymentDate = dayjs(
    formData.digitalFixedRateBond.firstCouponPaymentDate,
  );
  if (!firstCouponPaymentDate.isValid()) {
    validationResult.valid = false;
    validationResult.fieldValidations.firstCouponPaymentDate =
      "Must be a valid date";
  }

  // next to last coupon payment date
  const nextToLastCouponPaymentDate = dayjs(
    formData.digitalFixedRateBond.nextToLastCouponPaymentDate,
  );
  if (!nextToLastCouponPaymentDate.isValid()) {
    validationResult.valid = false;
    validationResult.fieldValidations.nextToLastCouponPaymentDate =
      "Must be valid";
  }

  // coupon interest commencement date
  const couponInterestCommencementDate = dayjs(
    formData.digitalFixedRateBond.couponInterestCommencementDate,
  );
  if (!couponInterestCommencementDate.isValid()) {
    validationResult.valid = false;
    validationResult.fieldValidations.couponInterestCommencementDate =
      "Must be a valid date";
  }

  // issue & maturity date
  if (issueDate.isBefore(dayjs())) {
    // If issue date is before today.
    validationResult.valid = false;
    validationResult.fieldValidations.issueDate = "Must be in the future";
  }
  if (issueDate.isAfter(maturityDate) || issueDate.isSame(maturityDate)) {
    validationResult.valid = false;
    validationResult.fieldValidations.maturityDate = "Must be after issue date";
    validationResult.fieldValidations.issueDate =
      "Must be before maturity date";
  }

  // first coupon payment dates
  if (firstCouponPaymentDate.isBefore(issueDate)) {
    validationResult.valid = false;
    validationResult.fieldValidations.firstCouponPaymentDate =
      "should be >= issue date";
  }

  // coupon commencement date
  if (couponInterestCommencementDate.isBefore(issueDate)) {
    validationResult.valid = false;
    validationResult.fieldValidations.couponInterestCommencementDate =
      "should be >= issue date";
  }

  if (
    couponInterestCommencementDate.isAfter(firstCouponPaymentDate) ||
    couponInterestCommencementDate.isSame(firstCouponPaymentDate)
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.couponInterestCommencementDate =
      "should be < first coupon payment date";
  }

  if (
    nextToLastCouponPaymentDate.isBefore(firstCouponPaymentDate) ||
    nextToLastCouponPaymentDate.isSame(firstCouponPaymentDate)
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.nextToLastCouponPaymentDate =
      "Must be after first coupon payment date";
  }

  // calendar
  if (formData.digitalFixedRateBond.calendar === Calendar.UNDEFINED_CALENDAR) {
    validationResult.valid = false;
    validationResult.fieldValidations.calendar = "Must be set";
  }

  // fix days
  if (formData.digitalFixedRateBond.fixDays <= 0) {
    validationResult.valid = false;
    validationResult.fieldValidations.fixDays = "Must be greater than 0";
  }

  // record days
  if (formData.digitalFixedRateBond.recordDays > 0) {
    validationResult.valid = false;
    validationResult.fieldValidations.recordDays =
      "Greater than 0 not yet supported";
  }

  // exCoupon, and record days
  if (
    formData.digitalFixedRateBond.exCouponDays <
    formData.digitalFixedRateBond.recordDays
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.fixDays =
      "Must be greater than or equal to record days";
    validationResult.fieldValidations.recordDays =
      "Must be less than or equal to fix days";
  }

  // coupon rate
  if (formData.digitalFixedRateBond.couponRate.lte(new BigNumber(0))) {
    validationResult.valid = false;
    validationResult.fieldValidations.couponRate = "Must be greater than 0";
  }

  return validationResult;
};
