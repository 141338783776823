import { Context } from "james/security";
import { Role } from "james/role";
import { Identifier } from "james/search/identifier/Identifier";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { useEffect, useRef, useState } from "react";
import { useApplicationContext } from "../../context/Application/Application";
import { useErrorContext } from "context/Error";

export interface RetrieveRoleRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveRoleResponse {
  role: Role;
}

export interface SearchRolesRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchRolesResponse {
  records: Role[];
  total: number;
}

export const Repository = {
  serviceProvider: "role-Repository",
  async RetrieveRole(
    request: RetrieveRoleRequest,
  ): Promise<RetrieveRoleResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Repository.serviceProvider}.RetrieveRole`,
      request,
    });
    return { role: new Role(response.role) };
  },
  async SearchRoles(request: SearchRolesRequest): Promise<SearchRolesResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${Repository.serviceProvider}.SearchRoles`,
      request,
    });
    return {
      records: response.records.map((c: Role) => new Role(c)),
      total: response.total,
    };
  },
};

export function useSearchRoles(
  initialSearchRolesRequest?: SearchRolesRequest,
  shouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const [searchRolesRequest, setSearchRolesRequest] =
    useState<SearchRolesRequest>(
      initialSearchRolesRequest || {
        context: authContext,
        query: new Query(),
        criteria: {},
      },
    );
  const [searchRolesResponse, setSearchRolesResponse] =
    useState<SearchRolesResponse>({
      total: 0,
      records: [],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setSearchRolesResponse(
          await Repository.SearchRoles(searchRolesRequest),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching for roles: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching for roles: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchRolesRequest, shouldNotExecute]);

  return {
    searchRolesRequest,
    setSearchRolesRequest,
    searchRolesResponse,
    loading,
    error,
  };
}
