import config from "react-global-configuration";
import TagManager from "react-gtm-module";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { Environment, smartInstrumentsSecret } from "./const";
import { userLoginTypes } from "./const/gtm";

function getAppVersion(): string {
  return window.Version ?? "vX.X.X";
}

export const SetupWebApp = (environment: Environment): void => {
  setupReactGlobalConfiguration(environment);
  setupAnalaytics(environment);
  setupSentry(environment);

  return;
};

const setupReactGlobalConfiguration = (environment: Environment): void => {
  switch (environment) {
    case Environment.Development:
      config.set({
        environment,
        applicationVersion: getAppVersion(),
        // Firebase
        firebaseConfig: {
          apiKey: "AIzaSyAZWo4O9MsELEGXhgbQX-zvUu7dzR1MJ4E",
          authDomain: "development.mesh.trade",
          projectId: "meshtrade-development",
          storageBucket: "meshtrade-development.appspot.com",
          messagingSenderId: "634995748095",
          appId: "1:634995748095:web:07ddc11e0c75e802ec70ca",
        },
        // Pusher
        pusherConfig: {
          appKey: "ecc3e1dd6b72f6cea5ce",
          cluster: "eu",
        },
        // Freshworks
        freshworksWidgetID: "80000006071",
        // Mesh.Core
        meshURL: "https://development-mesh-core-app.mesh.trade",
        meshAuthorizedURL:
          "https://development-mesh-core-app.mesh.trade/api/authorized",
        meshAuthenticatedURL:
          "https://development-mesh-core-app.mesh.trade/api/authenticated",
        meshConsistencyAuthorizedURL:
          "https://development-mesh-core-app.mesh.trade/api/consistency/authorized",
        meshConsistencyAuthenticatedURL:
          "https://development-mesh-core-app.mesh.trade/api/consistency/authenticated",
        meshAuthenticationURL:
          "https://development-mesh-core-app.mesh.trade/authentication",
        meshValidateTokenURL:
          "https://development-mesh-core-app.mesh.trade/authentication/validate-token",
        meshPublicURL:
          "https://development-mesh-core-app.mesh.trade/api/public",
        // Consistency
        consistencyHorizonAuthenticatedURL:
          "https://development-consistency-horizon.mesh.trade/api/mesh-authenticated",
        consistencyHorizonAuthorizedURL:
          "https://development-consistency-horizon.mesh.trade/api/mesh-authorized",
        apiGatewayURL:
          "https://development-service-mesh-api-gateway.mesh.trade",
        recaptchaSiteKey: "6LcV_SkpAAAAAOqU_q0jvF7VpCc7mP-gorBzbNl9",
        // Microsoft Clarity
        clarityProjectId: "lyztwejmhs",
        smartInstrumentsSecret: smartInstrumentsSecret,
      });
      break;

    case Environment.Testing: // testing environment
      config.set({
        environment,
        applicationVersion: getAppVersion(),
        // Firebase
        firebaseConfig: {
          apiKey: "AIzaSyAufIxAKZQ-qs1CaJGXmhsmb8fx1dc_kKk",
          authDomain: "testing.mesh.trade",
          projectId: "meshtrade-testing",
          storageBucket: "meshtrade-testing.appspot.com",
          messagingSenderId: "1026566443938",
          appId: "1:1026566443938:web:797ddcd9e4f570c36058fb",
        },
        // Pusher
        pusherConfig: {
          appKey: "08929a1c03e3bfb6b396",
          cluster: "eu",
        },
        // Freshworks
        freshworksWidgetID: "80000006071",
        // Mesh.Core
        meshURL: "https://testing-mesh-core-app.mesh.trade",
        meshAuthorizedURL:
          "https://testing-mesh-core-app.mesh.trade/api/authorized",
        meshAuthenticatedURL:
          "https://testing-mesh-core-app.mesh.trade/api/authenticated",
        meshConsistencyAuthorizedURL:
          "https://testing-mesh-core-app.mesh.trade/api/consistency/authorized",
        meshConsistencyAuthenticatedURL:
          "https://testing-mesh-core-app.mesh.trade/api/consistency/authenticated",
        meshAuthenticationURL:
          "https://testing-mesh-core-app.mesh.trade/authentication",
        meshValidateTokenURL:
          "https://testing-mesh-core-app.mesh.trade/authentication/validate-token",
        meshPublicURL: "https://testing-mesh-core-app.mesh.trade/api/public",
        // Consistency
        consistencyHorizonAuthenticatedURL:
          "https://testing-consistency-horizon.mesh.trade/api/mesh-authenticated",
        consistencyHorizonAuthorizedURL:
          "https://testing-consistency-horizon.mesh.trade/api/mesh-authorized",
        apiGatewayURL: "https://testing-service-mesh-api-gateway.mesh.trade",
        recaptchaSiteKey: "6LcV_SkpAAAAAOqU_q0jvF7VpCc7mP-gorBzbNl9",
        // Microsoft Clarity
        clarityProjectId: "m15pr878b1",
        smartInstrumentsSecret: smartInstrumentsSecret,
      });
      break;

    case Environment.Staging:
      environment = Environment.Staging;
      config.set({
        environment,
        applicationVersion: getAppVersion(),
        // Firebase
        firebaseConfig: {
          apiKey: "AIzaSyDIV381_ncYzz2oPhtE1Zaq_gpOIFMFrn4",
          authDomain: "staging.mesh.trade",
          projectId: "meshtrade-staging",
          storageBucket: "meshtrade-staging.appspot.com",
          messagingSenderId: "262164795701",
          appId: "1:262164795701:web:566e64b42aef1a38207c7c",
        },
        // Pusher
        pusherConfig: {
          appKey: "c94dd0a4f2e8e9e3f691",
          cluster: "eu",
        },
        // Freshworks
        freshworksWidgetID: "80000006071",
        // Mesh.Core
        meshURL: "https://staging-mesh-core-app.mesh.trade",
        meshAuthorizedURL:
          "https://staging-mesh-core-app.mesh.trade/api/authorized",
        meshAuthenticatedURL:
          "https://staging-mesh-core-app.mesh.trade/api/authenticated",
        meshConsistencyAuthorizedURL:
          "https://staging-mesh-core-app.mesh.trade/api/consistency/authorized",
        meshConsistencyAuthenticatedURL:
          "https://staging-mesh-core-app.mesh.trade/api/consistency/authenticated",
        meshAuthenticationURL:
          "https://staging-mesh-core-app.mesh.trade/authentication",
        meshValidateTokenURL:
          "https://staging-mesh-core-app.mesh.trade/authentication/validate-token",
        meshPublicURL: "https://staging-mesh-core-app.mesh.trade/api/public",
        // Consistency
        consistencyHorizonAuthenticatedURL:
          "https://staging-consistency-horizon.mesh.trade/api/mesh-authenticated",
        consistencyHorizonAuthorizedURL:
          "https://staging-consistency-horizon.mesh.trade/api/mesh-authorized",
        apiGatewayURL: "https://staging-service-mesh-api-gateway.mesh.trade",
        recaptchaSiteKey: "6LcV_SkpAAAAAOqU_q0jvF7VpCc7mP-gorBzbNl9",
        smartInstrumentsSecret: smartInstrumentsSecret,
      });
      break;

    case Environment.Production: // production environment
      config.set({
        environment,
        applicationVersion: getAppVersion(),
        // Firebase
        firebaseConfig: {
          apiKey: "AIzaSyA6AQEz6hJAB7nsJPHv24QfPOf0Lrz3rv0",
          authDomain: "app.mesh.trade",
          projectId: "meshtrade-production",
          storageBucket: "meshtrade-production.appspot.com",
          messagingSenderId: "343851239599",
          appId: "1:343851239599:web:1afbe5ed6f61e3b93aec5a",
          measurementId: "G-YKLLMKHH2Z",
        },
        // Pusher
        pusherConfig: {
          appKey: "17d3b791c4495ef295c1",
          cluster: "eu",
        },
        // Freshworks
        freshworksWidgetID: "80000006071",
        // Mesh.Core
        meshURL: "https://production-mesh-core-app.mesh.trade",
        meshAuthorizedURL:
          "https://production-mesh-core-app.mesh.trade/api/authorized",
        meshAuthenticatedURL:
          "https://production-mesh-core-app.mesh.trade/api/authenticated",
        meshConsistencyAuthorizedURL:
          "https://production-mesh-core-app.mesh.trade/api/consistency/authorized",
        meshConsistencyAuthenticatedURL:
          "https://production-mesh-core-app.mesh.trade/api/consistency/authenticated",
        meshAuthenticationURL:
          "https://production-mesh-core-app.mesh.trade/authentication",
        meshValidateTokenURL:
          "https://production-mesh-core-app.mesh.trade/authentication/validate-token",
        meshPublicURL: "https://production-mesh-core-app.mesh.trade/api/public",
        // Consistency
        consistencyHorizonAuthenticatedURL:
          "https://production-consistency-horizon.mesh.trade/api/mesh-authenticated",
        consistencyHorizonAuthorizedURL:
          "https://production-consistency-horizon.mesh.trade/api/mesh-authorized",
        apiGatewayURL: "https://production-service-mesh-api-gateway.mesh.trade",
        recaptchaSiteKey: "6Lc1FCopAAAAAD8PU-82opBzQlJJa1ebn1Zrf_Rb",
        // Microsoft Clarity
        clarityProjectId: "lxf6ohndx9",
        smartInstrumentsSecret: smartInstrumentsSecret,
      });
      break;

    case Environment.Local:
    default:
      config.set({
        environment,
        applicationVersion: getAppVersion(),
        // Firebase
        firebaseConfig: {
          apiKey: "AIzaSyAZWo4O9MsELEGXhgbQX-zvUu7dzR1MJ4E",
          authDomain: "meshtrade-development.firebaseapp.com",
          projectId: "meshtrade-development",
          storageBucket: "meshtrade-development.appspot.com",
          messagingSenderId: "634995748095",
          appId: "1:634995748095:web:07ddc11e0c75e802ec70ca",
        },
        // Pusher
        pusherConfig: {
          appKey: "ecc3e1dd6b72f6cea5ce",
          cluster: "eu",
        },
        // Mesh.Core
        meshURL: `http://${window.location.host.slice(
          0,
          window.location.host.indexOf(":"),
        )}:8080`,
        meshAuthorizedURL: `http://${window.location.host.slice(
          0,
          window.location.host.indexOf(":"),
        )}:8080/api/authorized`,
        meshConsistencyAuthorizedURL: `http://${window.location.host.slice(
          0,
          window.location.host.indexOf(":"),
        )}:8080/api/consistency/authorized`,
        meshConsistencyAuthenticatedURL: `http://${window.location.host.slice(
          0,
          window.location.host.indexOf(":"),
        )}:8080/api/consistency/authenticated`,
        meshAuthenticatedURL: `http://${window.location.host.slice(
          0,
          window.location.host.indexOf(":"),
        )}:8080/api/authenticated`,
        meshAuthenticationURL: `http://${window.location.host.slice(
          0,
          window.location.host.indexOf(":"),
        )}:8080/authentication`,
        meshValidateTokenURL: `http://${window.location.host.slice(
          0,
          window.location.host.indexOf(":"),
        )}:8080/authentication/validate-token`,
        meshPublicURL: `http://${window.location.host.slice(
          0,
          window.location.host.indexOf(":"),
        )}:8080/api/public`,
        // Consistency
        consistencyHorizonAuthenticatedURL: `http://${window.location.host.slice(
          0,
          window.location.host.indexOf(":"),
        )}:8092/api/mesh-authenticated`,
        consistencyHorizonAuthorizedURL: `http://${window.location.host.slice(
          0,
          window.location.host.indexOf(":"),
        )}:8092/api/mesh-authorized`,
        apiGatewayURL: `http://${window.location.host.slice(
          0,
          window.location.host.indexOf(":"),
        )}:10000`,
        // Freshworks
        freshworksWidgetID: "80000006071",
        recaptchaSiteKey: "6LcV_SkpAAAAAOqU_q0jvF7VpCc7mP-gorBzbNl9",
        // Microsoft Clarity
        clarityProjectId: "lyztwejmhs",
        smartInstrumentsSecret: smartInstrumentsSecret,
      });
      break;
  }
  return;
};

const setupSentry = (environment: Environment): void => {
  switch (environment) {
    case Environment.Staging:
    case Environment.Production:
      // We initialise after the config setup so that we can use this as extra info in Sentry reporting
      Sentry.init({
        dsn: "https://6c035b0e1c80443fbef4d27b967b0e75@o573466.ingest.sentry.io/5723988",
        release: `feather@${getAppVersion()}`,
        integrations: [new BrowserTracing()],
        environment,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // pass the LogRocket session URL with a timestamp to the
        // dayjs that an error is caught by Sentry
        beforeSend(event) {
          const logRocketSession = LogRocket.sessionURL;
          if (logRocketSession !== null) {
            if (event && event.extra) {
              event.extra["LogRocket"] = logRocketSession;
            }
            return event;
          } else {
            return event;
          }
        },
      });
      break;

    default:
      console.debug(`skipping sentry monitoring in ${environment} environment`);
  }
  return;
};

const setupAnalaytics = (environment: Environment): void => {
  // set the initial global mtDataLayer
  window.mtDataLayer = {
    account: {},
    error: {},
    page: {},
    search: {},
    transaction: {},
    tutorial: {},
    user: {
      user_login_type: userLoginTypes.guest,
    },
    platform: {
      platform_name: "mesh-trade-platform", // constant
      platform_type: "internal", // constant
      platform_category: "trading-app", // constant
      platform_prefix: "MTP", // constant MTP (Mesh Trading Platform)
      platform_ecosystem_type: "internal", // constant
      platform_base_country: "south africa", // constant
      platform_country_code: "ZA",
    },
  };

  switch (environment) {
    // Uncomment for local development
    case Environment.Local:
    case Environment.Development:
    case Environment.Testing:
    case Environment.Staging:
      {
        // Initialize Google Tag Manager
        TagManager.initialize({
          gtmId: "GTM-5HF9297S",
        });

        //
        //   // initialise LogRocket
        //   LogRocket.init("atmxzl/feather-development");
        //   setupLogRocketReact(LogRocket);
        //   LogRocket.getSessionURL((sessionURL) => {
        //     Sentry.configureScope((scope) => {
        //       scope.setExtra("sessionURL", sessionURL);
        //     });
        //   });
      }
      break;

    case Environment.Production:
      // Initialize Google Tag Manager
      TagManager.initialize({
        gtmId: "GTM-5XKDBNBM",
      });
      // initialise LogRocket
      LogRocket.init("atmxzl/feather-production", {
        network: {
          requestSanitizer(request) {
            if (request.url.toLowerCase().includes("identitytoolkit")) {
              request.body = undefined;
            }
            return request;
          },
        },
      });
      setupLogRocketReact(LogRocket);
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra("sessionURL", sessionURL);
        });
      });
      break;

    default:
      console.debug(
        `skipping lockRocket initialisation in ${environment} environment`,
      );
  }

  return;
};
