import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { DigitalFloatingRateBond } from "./DigitalFloatingRateBond";
import { Document } from "james/document";

export type DraftUpdateDigitalFloatingRateBondRequest = {
  context: Context;
  digitalFloatingRateBond: DigitalFloatingRateBond;
};

export type DraftUpdateDigitalFloatingRateBondResponse = {
  digitalFloatingRateBond: DigitalFloatingRateBond;
};

export type AddDigitalFloatingRateBondSupportingDocumentRequest = {
  context: Context;
  digitalFloatingRateBondID: string;
  supportingDocument: Document;
};

export type AddDigitalFloatingRateBondSupportingDocumentResponse = {
  digitalFloatingRateBond: DigitalFloatingRateBond;
};

export type DeleteDigitalFloatingRateBondSupportingDocumentRequest = {
  context: Context;
  digitalFloatingRateBondID: string;
  supportingDocumentID: string;
};

export type DeleteDigitalFloatingRateBondSupportingDocumentResponse = {
  digitalFloatingRateBond: DigitalFloatingRateBond;
};

export const DigitalFloatingRateBondUpdaterServiceProviderName =
  "financial-DigitalFloatingRateBondUpdater";

export const DigitalFloatingRateBondUpdater = {
  async DraftUpdateDigitalFloatingRateBond(
    request: DraftUpdateDigitalFloatingRateBondRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<DraftUpdateDigitalFloatingRateBondResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DigitalFloatingRateBondUpdaterServiceProviderName}.DraftUpdateDigitalFloatingRateBond`,
        request,
      },
      opts,
    );

    return {
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        response.digitalFloatingRateBond,
      ),
    };
  },
  async AddDigitalFloatingRateBondSupportingDocument(
    request: AddDigitalFloatingRateBondSupportingDocumentRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<AddDigitalFloatingRateBondSupportingDocumentResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DigitalFloatingRateBondUpdaterServiceProviderName}.AddDigitalFloatingRateBondSupportingDocument`,
        request,
      },
      opts,
    );

    return {
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        response.digitalFloatingRateBond,
      ),
    };
  },
  async DeleteDigitalFloatingRateBondSupportingDocument(
    request: DeleteDigitalFloatingRateBondSupportingDocumentRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<DeleteDigitalFloatingRateBondSupportingDocumentResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DigitalFloatingRateBondUpdaterServiceProviderName}.DeleteDigitalFloatingRateBondSupportingDocument`,
        request,
      },
      opts,
    );

    return {
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        response.digitalFloatingRateBond,
      ),
    };
  },
};
