import React from "react";
import { Router } from "routes";
import {
  ViewCouponPayment,
  CouponPaymentTable,
} from "components/CorporateActions/CouponPayment";

export function CouponPayment() {
  return (
    <Router
      baseURL={"/market-management/corporate-action/coupon-payment"}
      redirectPath={"/market-management/corporate-action/coupon-payment/table"}
      routes={[
        {
          name: "Table",
          id: "table",
          path: "/market-management/corporate-action/coupon-payment/table",
          component: (
            <CouponPaymentTable system height={window.innerHeight - 138} />
          ),
          allowSubPaths: true,
        },
        {
          name: "View",
          id: "view",
          path: "/market-management/corporate-action/coupon-payment/view",
          component: (
            <ViewCouponPayment system height={window.innerHeight - 138} />
          ),
          allowSubPaths: true,
        },
      ]}
    />
  );
}
