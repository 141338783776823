export enum DateGenerationRule {
  UNDEFINED_DATE_GENERATION_RULE = 0,
  BACKWARD_DATE_GENERATION_RULE = 1,
  FORWARD_DATE_GENERATION_RULE = 2,
  ZERO_DATE_GENERATION_RULE = 3,
  THIRD_WEDNESDAY_DATE_GENERATION_RULE = 4,
  THIRD_WEDNESDAY_INCLUSIVE_DATE_GENERATION_RULE = 5,
  TWENTIETH_DATE_GENERATION_RULE = 6,
  TWENTIETH_IMM_DATE_GENERATION_RULE = 7,
  OLD_CDS_DATE_GENERATION_RULE = 8,
  CDS_DATE_GENERATION_RULE = 9,
  CDS2015_DATE_GENERATION_RULE = 10,
}

// Get all date generation rule as enum values
export const allDateGenerationRules: DateGenerationRule[] = Object.values(
  DateGenerationRule,
).filter((value) => typeof value === "number") as DateGenerationRule[];

// Define explicit mappings between DateGenerationRule enums and custom string representations
const dateGenerationRuleToStringMapping: {
  [key in DateGenerationRule]: string;
} = {
  [DateGenerationRule.UNDEFINED_DATE_GENERATION_RULE]: "-",
  [DateGenerationRule.BACKWARD_DATE_GENERATION_RULE]: "BACKWARD_DATE",
  [DateGenerationRule.FORWARD_DATE_GENERATION_RULE]: "FORWARD_DATE",
  [DateGenerationRule.ZERO_DATE_GENERATION_RULE]: "ZERO_DATE",
  [DateGenerationRule.THIRD_WEDNESDAY_DATE_GENERATION_RULE]:
    "THIRD_WEDNESDAY_DATE",
  [DateGenerationRule.THIRD_WEDNESDAY_INCLUSIVE_DATE_GENERATION_RULE]:
    "THIRD_WEDNESDAY_INCLUSIVE_DATE",
  [DateGenerationRule.TWENTIETH_DATE_GENERATION_RULE]: "TWENTIETH_DATE",
  [DateGenerationRule.TWENTIETH_IMM_DATE_GENERATION_RULE]: "TWENTIETH_IMM_DATE",
  [DateGenerationRule.OLD_CDS_DATE_GENERATION_RULE]: "OLD_CDS_DATE",
  [DateGenerationRule.CDS_DATE_GENERATION_RULE]: "CDS_DATE",
  [DateGenerationRule.CDS2015_DATE_GENERATION_RULE]: "CDS2015_DATE",
};

// Reverse mapping from string to DateGenerationRule enum
const stringToDateGenerationRuleMapping: Record<string, DateGenerationRule> =
  {};
for (const [key, value] of Object.entries(dateGenerationRuleToStringMapping)) {
  stringToDateGenerationRuleMapping[value] = Number(key);
}

class UnsupportedDateGenerationRuleError extends Error {
  dateGenerationRule: DateGenerationRule;

  constructor(dateGenerationRule: DateGenerationRule) {
    const message = `Unsupported DateGenerationRule: ${dateGenerationRule}`;
    super(message);
    this.dateGenerationRule = dateGenerationRule;
  }
}

/**
 * Converts a DateGenerationRule enum instance to a custom string representation.
 * @param {DateGenerationRule} dateGenerationRule - The date generation rule to convert.
 * @returns {string} The custom string representation of the date generation rule.
 */
export function dateGenerationRuleToString(
  dateGenerationRule: DateGenerationRule,
): string {
  if (dateGenerationRule in dateGenerationRuleToStringMapping) {
    return dateGenerationRuleToStringMapping[dateGenerationRule];
  } else {
    throw new UnsupportedDateGenerationRuleError(dateGenerationRule);
  }
}

class UnsupportedDateGenerationRuleStringError extends Error {
  dateGenerationRuleStr: string;

  constructor(dateGenerationRuleStr: string) {
    const message = `Unsupported date generation rule string: ${dateGenerationRuleStr}`;
    super(message);
    this.dateGenerationRuleStr = dateGenerationRuleStr;
  }
}

/**
 * Converts a custom string representation to a DateGenerationRule enum instance.
 * @param {string} dateGenerationRuleStr - The custom string representation of the date generation rule.
 * @returns {DateGenerationRule} The corresponding DateGenerationRule enum instance.
 */
export function stringToDateGenerationRule(
  dateGenerationRuleStr: string,
): DateGenerationRule {
  if (dateGenerationRuleStr in stringToDateGenerationRuleMapping) {
    return stringToDateGenerationRuleMapping[dateGenerationRuleStr];
  } else {
    throw new UnsupportedDateGenerationRuleStringError(dateGenerationRuleStr);
  }
}
