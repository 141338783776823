export function convertShortnameToInteractionDriver(assetShortname: string) {
  if (assetShortname === "MOS_BFL_P2_20340430_01") {
    return "die_mos_join_waitlist";
  } else if (assetShortname === "ZAWFINPSH001") {
    return "water_fin_join_waitlist";
  }
  return assetShortname.replace(" ", "_").concat("_join_waitlist");
}

export function convertShortnameToInteractionDriverForm(
  assetShortname: string,
) {
  if (assetShortname === "MOS_BFL_P2_20340430_01") {
    return "die-mos-open-waiting-list-form";
  } else if (assetShortname === "ZAWFINPSH001") {
    return "water-fin-join-waiting-list-form";
  }
  return assetShortname.replace(" ", "-").concat("-join-waiting-list-form");
}
