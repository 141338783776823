"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringToDayCountConvention = exports.dayCountConventionToString = exports.allDayCountConventions = void 0;
const dayCountConvention_pb_1 = require("./dayCountConvention_pb");
// Get all day count conventions as enum values
exports.allDayCountConventions = Object.values(dayCountConvention_pb_1.DayCountConvention).filter((value) => typeof value === "number");
// Define explicit mappings between DayCountConvention enums and custom string representations
const dayCountConventionToStringMapping = {
    [dayCountConvention_pb_1.DayCountConvention.UNDEFINED_DAY_COUNT_CONVENTION]: "-",
    [dayCountConvention_pb_1.DayCountConvention.ACTUAL_OVER_365_FIXED_DAY_COUNT_CONVENTION]: "Actual/365 Fixed",
    [dayCountConvention_pb_1.DayCountConvention.ACTUAL_OVER_360_DAY_COUNT_CONVENTION]: "Actual/360",
    [dayCountConvention_pb_1.DayCountConvention.ACTUAL_OVER_364_DAY_COUNT_CONVENTION]: "Actual/364",
    [dayCountConvention_pb_1.DayCountConvention.ACTUAL_OVER_366_DAY_COUNT_CONVENTION]: "Actual/366",
    [dayCountConvention_pb_1.DayCountConvention.ACTUAL_OVER_ACTUAL_ISMA_DAY_COUNT_CONVENTION]: "Actual/Actual ISMA",
    [dayCountConvention_pb_1.DayCountConvention.THIRTY_OVER_360_ISMA_DAY_COUNT_CONVENTION]: "30/360 ISMA",
    [dayCountConvention_pb_1.DayCountConvention.THIRTY_OVER_365_DAY_COUNT_CONVENTION]: "30/365",
    [dayCountConvention_pb_1.DayCountConvention.ACTUAL_OVER_365_25_DAY_COUNT_CONVENTION]: "Actual/365.25",
};
// Reverse mapping from string to DayCountConvention enum
const stringToDayCountConventionMapping = {};
for (const [key, value] of Object.entries(dayCountConventionToStringMapping)) {
    stringToDayCountConventionMapping[value] = Number(key);
}
class UnsupportedDayCountConventionError extends Error {
    constructor(dayCountConvention) {
        const message = `Unsupported DayCountConvention: ${dayCountConvention}`;
        super(message);
        this.dayCountConvention = dayCountConvention;
    }
}
/**
 * Converts a DayCountConvention enum instance to a custom string representation.
 * @param {DayCountConvention} dayCountConvention - The day count convention to convert.
 * @returns {string} The custom string representation of the day count convention.
 */
function dayCountConventionToString(dayCountConvention) {
    if (dayCountConvention in dayCountConventionToStringMapping) {
        return dayCountConventionToStringMapping[dayCountConvention];
    }
    else {
        throw new UnsupportedDayCountConventionError(dayCountConvention);
    }
}
exports.dayCountConventionToString = dayCountConventionToString;
class UnsupportedDayCountConventionStringError extends Error {
    constructor(dayCountConventionStr) {
        const message = `Unsupported day count convention string: ${dayCountConventionStr}`;
        super(message);
        this.dayCountConventionStr = dayCountConventionStr;
    }
}
/**
 * Converts a custom string representation to a DayCountConvention enum instance.
 * @param {string} dayCountConventionStr - The custom string representation of the day count convention.
 * @returns {DayCountConvention} The corresponding DayCountConvention enum instance.
 */
function stringToDayCountConvention(dayCountConventionStr) {
    if (dayCountConventionStr in stringToDayCountConventionMapping) {
        return stringToDayCountConventionMapping[dayCountConventionStr];
    }
    else {
        throw new UnsupportedDayCountConventionStringError(dayCountConventionStr);
    }
}
exports.stringToDayCountConvention = stringToDayCountConvention;
