import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import { Close as CloseIcon } from "@mui/icons-material";
import { Client } from "james/client";
import { BankAccountView } from "./components/BankAccountDetailsView";
import { CompanyView } from "./components/CompanyDetailsView/CompanyView";
import { ClientView } from "./components/ClientDetailsView/ClientView";

type DashboardProps = {
  open: boolean;
  closeDialog: () => void;
  client: Client;
  refresh: () => void;
};

export enum View {
  ClientDetails = "Client Details",
  CompanyDetails = "Company Details",
  BankAccountDetails = "Bank Account Details",
}

export function Dashboard(props: DashboardProps) {
  const [currentView, setCurrentView] = useState<View>(View.ClientDetails);
  return (
    <Dialog open={props.open} fullScreen>
      <HeaderSection
        closeDialog={props.closeDialog}
        name={props.client.name}
        refresh={props.refresh}
      />
      <DialogContent
        sx={{
          overflowY: "hidden",
        }}
      >
        <NavigationSection
          onChange={(val) => {
            switch (val) {
              case ActiveTab.Client:
                setCurrentView(View.ClientDetails);
                break;
              case ActiveTab.BankAccount:
                setCurrentView(View.BankAccountDetails);
                break;
              case ActiveTab.Company:
                setCurrentView(View.CompanyDetails);
                break;
            }
          }}
        />
        {currentView === View.BankAccountDetails && (
          <BankAccountView Client={props.client} />
        )}
        {currentView === View.CompanyDetails && (
          <CompanyView Client={props.client} />
        )}
        {currentView === View.ClientDetails && (
          <ClientView Client={props.client} />
        )}
      </DialogContent>
    </Dialog>
  );
}

type TitleSectionProps = {
  closeDialog: () => void;
  name: string;
  refresh: () => void;
};

const HeaderSection = (props: TitleSectionProps) => {
  return (
    <DialogTitle
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        display: "grid",
        gridTemplateColumns: "auto 1fr",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignContent: "center",
          gap: "8px",
        }}
      >
        <img alt="" height={32} src={meshMiniLogo} />
        <Typography
          alignSelf="center"
          variant="h5"
          children="Compliance View"
        />
        <Typography
          alignSelf="center"
          variant="h5"
          sx={{
            color: (theme) => theme.palette.text.disabled,
          }}
          children={props.name}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignContent: "center",
          justifyContent: "flex-end",
        }}
      >
        <Tooltip title="Close" placement="top">
          <span>
            <IconButton
              id={"ComplianceDashboard-close-button"}
              size="small"
              onClick={() => {
                props.refresh();
                props.closeDialog();
              }}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </DialogTitle>
  );
};

export enum ActiveTab {
  Client = "Client",
  Company = "Company",
  BankAccount = "Bank Account",
}

type NavigationSectionProps = {
  onChange: (tab: ActiveTab) => void;
};
const NavigationSection = (props: NavigationSectionProps) => {
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.Client);

  return (
    <Box>
      <Tabs
        value={activeTab}
        onChange={(event, value) => {
          setActiveTab(value);
          props.onChange(value);
        }}
        textColor={"inherit"}
      >
        <Tab key={0} value={"Client"} label={"Client"} />
        <Tab key={1} value={"Company"} label={"Company"} />
        <Tab key={2} value={"Bank Account"} label={"Bank Account"} />
      </Tabs>
    </Box>
  );
};
