import { Box, Typography, alpha } from "@mui/material";
import React, { useMemo } from "react";
import { useBuilderContext } from "../../../../Context";
import {
  DataTable,
  RowType,
} from "@mesh/common-js-react/dist/Tables/DataTable";
import { Payment } from "@mesh/common-js/dist/financial/payment_pb";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { decimalToBigNumber, formatTextNum } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { FaceOutlined as FaceIcon } from "@mui/icons-material";
import { DateTimeFormat } from "const/dateformats";

export type PaymentsProps = {
  height: number;
};

export const Payments = (props: PaymentsProps) => {
  const { formData, simulatedPayments } = useBuilderContext();

  const paymentColumns: RowType<
    Payment,
    Omit<Payment.AsObject, "id" | "ownerid" | "auditentry" | "state" | "data">
  > = useMemo(
    () => ({
      date: {
        title: "Date",
        renderCell: (rowData: Payment) => {
          return protobufTimestampToDayjs(rowData.getDate() ?? new Timestamp())
            .tz(timezoneToString(formData.smartInstrument.getTimezone()))
            .format(DateTimeFormat);
        },
      },
      category: {
        title: "Category",
        renderCell: (rowData: Payment) => {
          return rowData.getCategory();
        },
      },
      amount: {
        title: "Amount",
        renderCell: (rowData: Payment) => {
          return `${rowData.getAmount()?.getToken()?.getCode()} ${formatTextNum(
            decimalToBigNumber(
              rowData.getAmount()?.getValue() ?? new Decimal(),
            ),
          )}`;
        },
      },
    }),
    [formData.smartInstrument.getTimezone()],
  );

  if (!simulatedPayments.length) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(0.5),
          })}
        >
          <FaceIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Nothing to see here"
          />
          <Typography
            variant="body2"
            children={
              <span>
                You will see <i>Payments</i> here after creating at least one
              </span>
            }
          />
        </Box>
      </Box>
    );
  }

  return (
    <DataTable
      tableContainerClassName="meshScroll"
      height={props.height - 50}
      data={simulatedPayments}
      columns={paymentColumns}
    />
  );
};
