// source: financial/smartInstrumentReader_meshproto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var search_criterion_pb = require('../search/criterion_pb.js');
goog.object.extend(proto, search_criterion_pb);
var search_query_pb = require('../search/query_pb.js');
goog.object.extend(proto, search_query_pb);
var financial_smartInstrument_pb = require('../financial/smartInstrument_pb.js');
goog.object.extend(proto, financial_smartInstrument_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var ledger_token_pb = require('../ledger/token_pb.js');
goog.object.extend(proto, ledger_token_pb);
var financial_smartInstrumentLeg_pb = require('../financial/smartInstrumentLeg_pb.js');
goog.object.extend(proto, financial_smartInstrumentLeg_pb);
var financial_smartInstrumentState_pb = require('../financial/smartInstrumentState_pb.js');
goog.object.extend(proto, financial_smartInstrumentState_pb);
var financial_assetClass_pb = require('../financial/assetClass_pb.js');
goog.object.extend(proto, financial_assetClass_pb);
var ledger_amount_pb = require('../ledger/amount_pb.js');
goog.object.extend(proto, ledger_amount_pb);
var financial_unitCategory_pb = require('../financial/unitCategory_pb.js');
goog.object.extend(proto, financial_unitCategory_pb);
var financial_callabilityConfiguration_pb = require('../financial/callabilityConfiguration_pb.js');
goog.object.extend(proto, financial_callabilityConfiguration_pb);
var i8n_timezone_pb = require('../i8n/timezone_pb.js');
goog.object.extend(proto, i8n_timezone_pb);
var document_document_pb = require('../document/document_pb.js');
goog.object.extend(proto, document_document_pb);
goog.exportSymbol('proto.financial.ReadManySmartInstrumentRequest', null, global);
goog.exportSymbol('proto.financial.ReadManySmartInstrumentResponse', null, global);
goog.exportSymbol('proto.financial.ReadOneSmartInstrumentRequest', null, global);
goog.exportSymbol('proto.financial.ReadOneSmartInstrumentResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ReadOneSmartInstrumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.ReadOneSmartInstrumentRequest.repeatedFields_, null);
};
goog.inherits(proto.financial.ReadOneSmartInstrumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ReadOneSmartInstrumentRequest.displayName = 'proto.financial.ReadOneSmartInstrumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ReadOneSmartInstrumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.ReadOneSmartInstrumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ReadOneSmartInstrumentResponse.displayName = 'proto.financial.ReadOneSmartInstrumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ReadManySmartInstrumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.ReadManySmartInstrumentRequest.repeatedFields_, null);
};
goog.inherits(proto.financial.ReadManySmartInstrumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ReadManySmartInstrumentRequest.displayName = 'proto.financial.ReadManySmartInstrumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ReadManySmartInstrumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.ReadManySmartInstrumentResponse.repeatedFields_, null);
};
goog.inherits(proto.financial.ReadManySmartInstrumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ReadManySmartInstrumentResponse.displayName = 'proto.financial.ReadManySmartInstrumentResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.ReadOneSmartInstrumentRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ReadOneSmartInstrumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ReadOneSmartInstrumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ReadOneSmartInstrumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadOneSmartInstrumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
    criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ReadOneSmartInstrumentRequest}
 */
proto.financial.ReadOneSmartInstrumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ReadOneSmartInstrumentRequest;
  return proto.financial.ReadOneSmartInstrumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ReadOneSmartInstrumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ReadOneSmartInstrumentRequest}
 */
proto.financial.ReadOneSmartInstrumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ReadOneSmartInstrumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ReadOneSmartInstrumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ReadOneSmartInstrumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadOneSmartInstrumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.ReadOneSmartInstrumentRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.ReadOneSmartInstrumentRequest} returns this
*/
proto.financial.ReadOneSmartInstrumentRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ReadOneSmartInstrumentRequest} returns this
 */
proto.financial.ReadOneSmartInstrumentRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ReadOneSmartInstrumentRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.financial.ReadOneSmartInstrumentRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.financial.ReadOneSmartInstrumentRequest} returns this
*/
proto.financial.ReadOneSmartInstrumentRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.financial.ReadOneSmartInstrumentRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.ReadOneSmartInstrumentRequest} returns this
 */
proto.financial.ReadOneSmartInstrumentRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ReadOneSmartInstrumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ReadOneSmartInstrumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ReadOneSmartInstrumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadOneSmartInstrumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ReadOneSmartInstrumentResponse}
 */
proto.financial.ReadOneSmartInstrumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ReadOneSmartInstrumentResponse;
  return proto.financial.ReadOneSmartInstrumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ReadOneSmartInstrumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ReadOneSmartInstrumentResponse}
 */
proto.financial.ReadOneSmartInstrumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ReadOneSmartInstrumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ReadOneSmartInstrumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ReadOneSmartInstrumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadOneSmartInstrumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional SmartInstrument smartInstrument = 1;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.ReadOneSmartInstrumentResponse.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 1));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.ReadOneSmartInstrumentResponse} returns this
*/
proto.financial.ReadOneSmartInstrumentResponse.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ReadOneSmartInstrumentResponse} returns this
 */
proto.financial.ReadOneSmartInstrumentResponse.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ReadOneSmartInstrumentResponse.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.ReadManySmartInstrumentRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ReadManySmartInstrumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ReadManySmartInstrumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ReadManySmartInstrumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadManySmartInstrumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
    criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance),
    query: (f = msg.getQuery()) && search_query_pb.Query.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ReadManySmartInstrumentRequest}
 */
proto.financial.ReadManySmartInstrumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ReadManySmartInstrumentRequest;
  return proto.financial.ReadManySmartInstrumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ReadManySmartInstrumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ReadManySmartInstrumentRequest}
 */
proto.financial.ReadManySmartInstrumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 3:
      var value = new search_query_pb.Query;
      reader.readMessage(value,search_query_pb.Query.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ReadManySmartInstrumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ReadManySmartInstrumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ReadManySmartInstrumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadManySmartInstrumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      search_query_pb.Query.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.ReadManySmartInstrumentRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.ReadManySmartInstrumentRequest} returns this
*/
proto.financial.ReadManySmartInstrumentRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ReadManySmartInstrumentRequest} returns this
 */
proto.financial.ReadManySmartInstrumentRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ReadManySmartInstrumentRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.financial.ReadManySmartInstrumentRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.financial.ReadManySmartInstrumentRequest} returns this
*/
proto.financial.ReadManySmartInstrumentRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.financial.ReadManySmartInstrumentRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.ReadManySmartInstrumentRequest} returns this
 */
proto.financial.ReadManySmartInstrumentRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional search.Query query = 3;
 * @return {?proto.search.Query}
 */
proto.financial.ReadManySmartInstrumentRequest.prototype.getQuery = function() {
  return /** @type{?proto.search.Query} */ (
    jspb.Message.getWrapperField(this, search_query_pb.Query, 3));
};


/**
 * @param {?proto.search.Query|undefined} value
 * @return {!proto.financial.ReadManySmartInstrumentRequest} returns this
*/
proto.financial.ReadManySmartInstrumentRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ReadManySmartInstrumentRequest} returns this
 */
proto.financial.ReadManySmartInstrumentRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ReadManySmartInstrumentRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.ReadManySmartInstrumentResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ReadManySmartInstrumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ReadManySmartInstrumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ReadManySmartInstrumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadManySmartInstrumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    financial_smartInstrument_pb.SmartInstrument.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ReadManySmartInstrumentResponse}
 */
proto.financial.ReadManySmartInstrumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ReadManySmartInstrumentResponse;
  return proto.financial.ReadManySmartInstrumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ReadManySmartInstrumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ReadManySmartInstrumentResponse}
 */
proto.financial.ReadManySmartInstrumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ReadManySmartInstrumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ReadManySmartInstrumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ReadManySmartInstrumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadManySmartInstrumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated SmartInstrument records = 1;
 * @return {!Array<!proto.financial.SmartInstrument>}
 */
proto.financial.ReadManySmartInstrumentResponse.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.financial.SmartInstrument>} */ (
    jspb.Message.getRepeatedWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 1));
};


/**
 * @param {!Array<!proto.financial.SmartInstrument>} value
 * @return {!proto.financial.ReadManySmartInstrumentResponse} returns this
*/
proto.financial.ReadManySmartInstrumentResponse.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.financial.SmartInstrument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.financial.SmartInstrument}
 */
proto.financial.ReadManySmartInstrumentResponse.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.financial.SmartInstrument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.ReadManySmartInstrumentResponse} returns this
 */
proto.financial.ReadManySmartInstrumentResponse.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.financial.ReadManySmartInstrumentResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.financial.ReadManySmartInstrumentResponse} returns this
 */
proto.financial.ReadManySmartInstrumentResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.financial);
