import dayjs from "dayjs";
import { Document } from "james/document";
import { AuditEntry } from "../audit";
import { Amount } from "james/ledger";
import { BondState } from "./Bond";
import { Token } from "../ledger/Token";
import BigNumber from "bignumber.js";
import { InvestorProfile } from "./InvestorProfile";
import { InstrumentRiskProfile } from "./InstrumentRiskProfile";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";
import { AssetClassDEPRECATED } from "./AssetClassDEPRECATED";
import { FinancialInstrumentState } from "./InstrumentState";
import { CouponFrequency } from "./CouponFrequency";
import { BusinessDayConvention } from "./BusinessDayConvention";
import { DayCountConvention } from "./DayCountConvention";
import { DebtSeniorityLevel } from "./DebtSeniorityLevel";
import { InstrumentOwnershipLimitations } from "./InstrumentOwnershipLimitations";
import { DigitalBond } from "./DigitalBond";
import { Calendar } from "./Calendar";
import { DateGenerationRule } from "./DateGenerationRule";

export const DigitalFixedRateBondTypeName =
  "mesh::financial/DigitalFixedRateBond";

export class DigitalFixedRateBond implements DigitalBond {
  public ["@type"]: string = DigitalFixedRateBondTypeName;

  public id = "";
  public ownerID = "";
  public state: BondState | "" = "";
  public auditEntry: AuditEntry = new AuditEntry();
  public isin = "";
  public name = "";
  public shortName = "";
  public token: Token = new Token();
  public fractionalisationAllowed = false;
  public supportingDocuments: Document[] = [];
  public investorProfile: InvestorProfile | "" = "";
  public investorProfileDescription = "";
  public riskProfile: InstrumentRiskProfile | "" = "";
  public riskProfileDescription = "";
  public debtSeniorityLevel: DebtSeniorityLevel | "" = "";
  public covenants = "";
  public votingRights = false;
  public votingRightsDescription = "";
  public ownershipLimitations: InstrumentOwnershipLimitations =
    new InstrumentOwnershipLimitations();
  public issuePrice: Amount = new Amount();
  public nominal: Amount = new Amount();

  // --------------- FixedRateBondStub fields (i.e. fields required for calculations) ----------------------
  public totalNominal: Amount = new Amount();
  public redemption: BigNumber = new BigNumber("0");
  public issueDate: string = dayjs().format();
  public maturityDate: string = dayjs().format();
  public calendar: Calendar = Calendar.UNDEFINED_CALENDAR;
  public couponPaymentFrequency: CouponFrequency | "" = "";
  public firstCouponPaymentDate: string | null = null;
  public nextToLastCouponPaymentDate: string | null = null;
  public couponInterestCommencementDate: string | null = null;
  public dayCountConvention: DayCountConvention | "" = "";
  public endOfMonthConvention = false;
  public businessDayConvention: BusinessDayConvention | "" = "";
  public terminationDateBusinessDayConvention: BusinessDayConvention | "" = "";
  public dateGenerationRule: DateGenerationRule =
    DateGenerationRule.UNDEFINED_DATE_GENERATION_RULE;
  public fixDays = 0;
  public exCouponDays = 0;
  public recordDays = 0;
  public couponRate: BigNumber = new BigNumber("0");

  constructor(digitalFixedRateBond?: DigitalFixedRateBond) {
    if (!digitalFixedRateBond) {
      return;
    }
    this.id = digitalFixedRateBond.id;
    this.ownerID = digitalFixedRateBond.ownerID;
    this.state = digitalFixedRateBond.state;
    this.auditEntry = new AuditEntry(digitalFixedRateBond.auditEntry);
    this.isin = digitalFixedRateBond.isin;
    this.name = digitalFixedRateBond.name;
    this.shortName = digitalFixedRateBond.shortName;
    this.token = new Token(digitalFixedRateBond.token);
    this.fractionalisationAllowed =
      digitalFixedRateBond.fractionalisationAllowed;
    if (digitalFixedRateBond.supportingDocuments) {
      this.supportingDocuments = digitalFixedRateBond.supportingDocuments.map(
        (d) => new Document(d),
      );
    }
    this.investorProfile = digitalFixedRateBond.investorProfile;
    this.investorProfileDescription =
      digitalFixedRateBond.investorProfileDescription;
    this.riskProfile = digitalFixedRateBond.riskProfile;
    this.riskProfileDescription = digitalFixedRateBond.riskProfileDescription;
    this.debtSeniorityLevel = digitalFixedRateBond.debtSeniorityLevel;
    this.covenants = digitalFixedRateBond.covenants;
    this.votingRights = digitalFixedRateBond.votingRights;
    this.votingRightsDescription = digitalFixedRateBond.votingRightsDescription;
    this.ownershipLimitations = new InstrumentOwnershipLimitations(
      digitalFixedRateBond.ownershipLimitations,
    );
    this.issuePrice = new Amount(digitalFixedRateBond.issuePrice);
    this.nominal = new Amount(digitalFixedRateBond.nominal);

    // --------------- FixedRateBondStub fields (i.e. fields required for calculations) ----------------------
    this.totalNominal = new Amount(digitalFixedRateBond.totalNominal);
    this.redemption = new BigNumber(digitalFixedRateBond.redemption);
    this.issueDate = digitalFixedRateBond.issueDate;
    this.maturityDate = digitalFixedRateBond.maturityDate;
    this.calendar = digitalFixedRateBond.calendar;
    this.couponPaymentFrequency = digitalFixedRateBond.couponPaymentFrequency;
    this.couponInterestCommencementDate = dayjs(
      digitalFixedRateBond.couponInterestCommencementDate,
    ).isSame(dayjs("0001-01-01T00:00:00Z"))
      ? null
      : digitalFixedRateBond.couponInterestCommencementDate;
    this.firstCouponPaymentDate = dayjs(
      digitalFixedRateBond.firstCouponPaymentDate,
    ).isSame(dayjs("0001-01-01T00:00:00Z"))
      ? null
      : digitalFixedRateBond.firstCouponPaymentDate;
    this.nextToLastCouponPaymentDate = dayjs(
      digitalFixedRateBond.nextToLastCouponPaymentDate,
    ).isSame(dayjs("0001-01-01T00:00:00Z"))
      ? null
      : digitalFixedRateBond.nextToLastCouponPaymentDate;
    this.dayCountConvention = digitalFixedRateBond.dayCountConvention;
    this.endOfMonthConvention = digitalFixedRateBond.endOfMonthConvention;
    this.businessDayConvention = digitalFixedRateBond.businessDayConvention;
    this.terminationDateBusinessDayConvention =
      digitalFixedRateBond.terminationDateBusinessDayConvention;
    this.dateGenerationRule = digitalFixedRateBond.dateGenerationRule;
    this.fixDays = digitalFixedRateBond.fixDays;
    this.exCouponDays = digitalFixedRateBond.exCouponDays;
    this.recordDays = digitalFixedRateBond.recordDays;
    this.couponRate = new BigNumber(digitalFixedRateBond.couponRate);
  }

  assetID(): string {
    return this.id;
  }

  assetIssueDate(): string {
    return this.issueDate;
  }

  assetName(): string {
    return this.name;
  }

  assetOwnerID(): string {
    return this.ownerID;
  }

  assetShortName(): string {
    return this.shortName;
  }

  assetToken(): Token {
    return this.token;
  }

  assetFractionalisationAllowed(): boolean {
    return this.fractionalisationAllowed;
  }

  bondState(): BondState | "" {
    return this.state;
  }

  bondTotalNominal(): Amount {
    return this.totalNominal;
  }

  digitalInstrumentIssuePrice(): Amount {
    return this.issuePrice;
  }

  digitalInstrumentMaximumUnits(): Amount {
    return this.token.newAmountOf(
      this.totalNominal.value.dividedBy(this.nominal.value),
    );
  }

  digitalInstrumentValuationToken(): Token {
    return this.totalNominal.token;
  }

  instrumentAnnualPerformanceLog(): InstrumentAnnualPerformanceLogEntry[] {
    return [];
  }

  instrumentAssetClass(): AssetClassDEPRECATED | "" {
    return AssetClassDEPRECATED.Debt;
  }

  instrumentID(): string {
    return this.id;
  }

  instrumentInvestorProfile(): InvestorProfile | "" {
    return this.investorProfile;
  }

  instrumentIssueDate(): string {
    return this.issueDate;
  }

  instrumentMaturityDate(): string {
    return this.maturityDate;
  }

  instrumentName(): string {
    return this.name;
  }

  instrumentOwnerID(): string {
    return this.ownerID;
  }

  instrumentRiskProfile(): InstrumentRiskProfile | "" {
    return this.riskProfile;
  }

  instrumentState(): FinancialInstrumentState {
    return this.state;
  }

  instrumentSupportingDocuments(): Document[] {
    return this.supportingDocuments;
  }
}
