import { Button, IconButton, Typography, Box, alpha } from "@mui/material";
import {
  Refresh as ReloadIcon,
  FaceOutlined as FaceIcon,
} from "@mui/icons-material";
import { useApplicationContext } from "context/Application/Application";
import React, { useEffect, useRef, useState } from "react";
import {
  ReadManySmartInstrumentRequest,
  ReadManySmartInstrumentResponse,
} from "@mesh/common-js/dist/financial/smartInstrumentReader_meshproto_pb";
import { useAPIContext } from "context/API";
import { useIsMounted } from "hooks";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { Query as PastQuery } from "james/search/query";
import { BPTable } from "components/Table";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { DateTimeFormat } from "const/dateformats";
import {
  assetClassToString,
  unitCategoryToString,
} from "@mesh/common-js/dist/financial";
import { SmartInstrumentStateChip } from "../Builder/components/StateChip";
import { useNavigate } from "react-router-dom";
import { ViewMode } from "../Builder/Context";
import { SmartInstrumentState } from "@mesh/common-js/dist/financial/smartInstrumentState_pb";

const initialQuery = new Query().setOffset(0).setLimit(15);

export type TableProps = {
  system: boolean;
};

export const Table = (props: TableProps) => {
  const { viewConfiguration } = useApplicationContext();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { authContext } = useApplicationContext();
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const {
    financial: { smartInstrumentReader, smartInstrumentReaderUNSCOPED },
  } = useAPIContext();
  const [readRequest, setReadRequest] =
    useState<ReadManySmartInstrumentRequest>(
      new ReadManySmartInstrumentRequest()
        .setContext(authContext.toFuture())
        .setQuery(initialQuery),
    );
  const [readResponse, setReadResponse] =
    useState<ReadManySmartInstrumentResponse>(
      new ReadManySmartInstrumentResponse(),
    );
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      try {
        let response: ReadManySmartInstrumentResponse;
        if (props.system) {
          response =
            await smartInstrumentReaderUNSCOPED.readManySmartInstrumentUNSCOPED(
              readRequest,
            );
        } else {
          response =
            await smartInstrumentReader.readManySmartInstrument(readRequest);
        }
        if (isMounted()) {
          setReadResponse(response);
        }
      } catch (e) {
        console.error("error reading smart instrument", e);
      }
      setLoading(false);
    }, 400);
  }, [readRequest, isMounted]);
  const [selectedSmartInstrument, setSelectedSmartInstrument] = useState<
    SmartInstrument | undefined
  >(undefined);

  return (
    <BPTable
      loading={loading}
      height={window.innerHeight - 88 - noticeBannerHeight}
      singleSelect
      noDataSplashComponent={noDataSplashComponent()}
      onSingleSelectChange={(data) =>
        setSelectedSmartInstrument(data as SmartInstrument)
      }
      title={"Smart Instruments"}
      query={PastQuery.fromFutureQuery(readRequest.getQuery())}
      onQueryChange={(query) =>
        setReadRequest(readRequest.setQuery(query.toFutureQuery()))
      }
      data={readResponse.getRecordsList()}
      totalNoRecords={readResponse.getTotal()}
      // filters={(() => {
      //   const filters: React.ReactNode[] = [
      //     <TextField
      //       id={"smartInstrumentTable-numberFilter-textField"}
      //       sx={{ width: 160 }}
      //       variant={"outlined"}
      //       margin={"dense"}
      //       label={"Number"}
      //       placeholder={"Start Typing..."}
      //       value={numberSearchCriteria}
      //       InputLabelProps={{ shrink: true }}
      //       onChange={(e) => setNumberSearchCriteria(e.target.value)}
      //     />,
      //     <DateField
      //       label={"From"}
      //       disabled={loading}
      //       id={"smartInstrumentTable-spotDateTimeFromFilter-dateField"}
      //       value={
      //         spotDateTimeCriterionFrom ? spotDateTimeCriterionFrom.date : null
      //       }
      //       onChange={(newValue) => {
      //         if (!(newValue && dateIsValid(newValue.date()))) {
      //           setSpotDateTimeCriterionFrom(undefined);
      //         } else {
      //           setSpotDateTimeCriterionFrom(
      //             newValue
      //               ? {
      //                 date: newValue.startOf("day").format(),
      //                 inclusive: true,
      //                 ignore: false,
      //               }
      //               : undefined,
      //           );
      //         }
      //       }}
      //       renderInput={(textFieldProps: TextFieldProps) => (
      //         <TextField
      //           {...textFieldProps}
      //           id={"smartInstrumentTable-spotDateTimeFromFilter-dateFieldTextField"}
      //           sx={{ width: 160 }}
      //           variant={"outlined"}
      //           margin={"dense"}
      //         />
      //       )}
      //     />,
      //     <DateField
      //       label={"To"}
      //       disabled={loading}
      //       id={"smartInstrumentTable-spotDateTimeToFilter-dateField"}
      //       value={
      //         spotDateTimeCriterionTo ? spotDateTimeCriterionTo.date : null
      //       }
      //       onChange={(newValue) => {
      //         if (!(newValue && dateIsValid(newValue))) {
      //           setSpotDateTimeCriterionTo(undefined);
      //         } else {
      //           setSpotDateTimeCriterionTo(
      //             newValue
      //               ? {
      //                 date: newValue.endOf("day").format(),
      //                 inclusive: true,
      //                 ignore: false,
      //               }
      //               : undefined,
      //           );
      //         }
      //       }}
      //       renderInput={(textFieldProps: TextFieldProps) => (
      //         <TextField
      //           {...textFieldProps}
      //           id={"smartInstrumentTable-spotDateTimeToFilter-dateFieldTextField"}
      //         />
      //       )}
      //     />,
      //     <Autocomplete
      //       isOptionEqualToValue={(option, value) => option === value}
      //       id={"smartInstrumentTable-assetFilter-autocomplete"}
      //       getOptionLabel={(option: TokenViewModel) =>
      //         `${option.token.code} - ${option.issuer}`
      //       }
      //       options={ledgerTokenViewReadResponse.models}
      //       loading={ledgerTokenViewReadLoading}
      //       onChange={(_, selected: TokenViewModel | null) =>
      //         setAssetToken(selected)
      //       }
      //       value={assetToken}
      //       onInputChange={(e, newInputValue, reason) => {
      //         if (reason === "reset") {
      //           return;
      //         }
      //         if (newInputValue === "") {
      //           setLedgerTokenViewReadRequest({
      //             ...ledgerTokenViewReadRequest,
      //             criteria: defaultTokenCriteria,
      //           });
      //         } else {
      //           setLedgerTokenViewReadRequest({
      //             ...ledgerTokenViewReadRequest,
      //             criteria: {
      //               ...defaultTokenCriteria,
      //               "token.code": TextSubstringCriterion(newInputValue),
      //             },
      //           });
      //         }
      //       }}
      //       renderInput={(params) => (
      //         <TextField
      //           {...params}
      //           label={"Asset"}
      //           id={"smartInstrumentTable-assetFilter-autocompleteTextField"}
      //           fullWidth
      //           InputLabelProps={{ shrink: true }}
      //           InputProps={{
      //             ...params.InputProps,
      //             placeholder: "Select...",
      //             sx: { width: 200 },
      //           }}
      //         />
      //       )}
      //     />,
      //     <Autocomplete
      //       isOptionEqualToValue={(option, value) => option === value}
      //       id={"smartInstrumentTable-stateFilter-autocomplete"}
      //       disabled={loading}
      //       multiple
      //       options={AllSpotStates}
      //       filterSelectedOptions
      //       onChange={(_, value: SpotState[]) =>
      //         setSpotStatusesForCriterion(value)
      //       }
      //       ChipProps={{
      //         color: "info",
      //         size: "small",
      //       }}
      //       value={spotStatusesForCriterion}
      //       renderTags={(spotStates: SpotState[]) =>
      //         spotStates.map((s, idx) => (
      //           <SpotStateChip
      //             key={idx}
      //             chipProps={{
      //               onDelete: () =>
      //                 setSpotStatusesForCriterion((prev) =>
      //                   prev.filter((prevState) => prevState !== s),
      //                 ),
      //               deleteIcon: (
      //                 <CancelIcon
      //                   sx={(theme) => ({
      //                     color: `${theme.palette.text.secondary} !important`,
      //                     "&:hover": {
      //                       color: `${theme.palette.secondary.contrastText} !important`,
      //                     },
      //                   })}
      //                 />
      //               ),
      //             }}
      //             state={s}
      //           />
      //         ))
      //       }
      //       renderInput={(params) => (
      //         <TextField
      //           {...params}
      //           id={"smartInstrumentTable-stateFilter-autocompleteTextField"}
      //           sx={{ width: 317 }}
      //           label={"State"}
      //           variant={"outlined"}
      //           margin={"dense"}
      //           InputLabelProps={{ shrink: true }}
      //           placeholder={
      //             spotStatusesForCriterion.length ? undefined : "Select..."
      //           }
      //         />
      //       )}
      //     />,
      //   ];

      //   // if any criteria is set then show a clear all filters button
      //   if (
      //     numberSearchCriteria ||
      //     spotStatusesForCriterion.length ||
      //     spotDateTimeCriterionFrom ||
      //     spotDateTimeCriterionTo ||
      //     assetToken
      //   ) {
      //     filters.push(
      //       <Button
      //         sx={{ marginTop: "10px" }}
      //         id={"smartInstrumentTable-clearAllFilters-button"}
      //         variant={"contained"}
      //         color={"secondary"}
      //         children={"clear all"}
      //         onClick={() => {
      //           setNumberSearchCriteria("");
      //           setSpotStatusesForCriterion([]);
      //           setSpotDateTimeCriterionFrom(undefined);
      //           setSpotDateTimeCriterionTo(undefined);
      //           setAssetToken(null);
      //         }}
      //         startIcon={<ClearFiltersIcon />}
      //       />,
      //     );
      //   }

      //   return filters;
      // })()}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        if (selectedSmartInstrument) {
          controls.push(
            <Button
              variant={"contained"}
              id={"smartInstrumentTable-view-button"}
              children={"View"}
              color={"primary"}
              onClick={() => {
                if (!selectedSmartInstrument) {
                  return;
                }
                const query = new URLSearchParams();
                query.set("id", selectedSmartInstrument.getId());
                query.set("mode", ViewMode.View);
                navigate({
                  pathname: "/builder/smart-instruments/builder",
                  search: query.toString(),
                });
              }}
            />,
          );

          if (
            selectedSmartInstrument.getState() ===
              SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE &&
            viewConfiguration["Smart Instruments"]?.Write?.UpdateDraft
          ) {
            controls.push(
              <Button
                variant={"contained"}
                id={"smartInstrumentTable-edit-button"}
                children={"Edit"}
                color={"primary"}
                onClick={() => {
                  if (!selectedSmartInstrument) {
                    return;
                  }
                  const query = new URLSearchParams();
                  query.set("id", selectedSmartInstrument.getId());
                  query.set("mode", ViewMode.Edit);
                  navigate({
                    pathname: "/builder/smart-instruments/builder",
                    search: query.toString(),
                  });
                }}
              />,
            );
          }
        } else {
          if (viewConfiguration["Smart Instruments"]?.Write?.CreateDraft) {
            controls.push(
              <Button
                variant={"contained"}
                id={"smartInstrumentTable-buildNew-button"}
                children={"Build New"}
                color={"primary"}
                onClick={() => {
                  const query = new URLSearchParams();
                  query.set("mode", ViewMode.Edit);
                  navigate({
                    pathname: "/builder/smart-instruments/builder",
                    search: query.toString(),
                  });
                }}
              />,
            );
          }
        }

        controls.push(
          <IconButton
            id={"smartInstrumentTable-reload-iconButton"}
            size={"small"}
            disabled={loading}
            onClick={() =>
              setReadRequest(
                new ReadManySmartInstrumentRequest()
                  .setContext(readRequest.getContext())
                  .setCriteriaList(readRequest.getCriteriaList())
                  .setQuery(readRequest.getQuery()),
              )
            }
          >
            <ReloadIcon />
          </IconButton>,
        );

        return controls;
      })()}
      columns={[
        {
          label: "Name",
          field: "name",
          accessor: (data) => {
            const model = data as SmartInstrument;
            return (
              <Typography color={"inherit"} variant={"body1"}>
                {model.getName()}
              </Typography>
            );
          },
        },
        {
          label: "Base Token",
          field: "basetoken",
          accessor: (data) => {
            const model = data as SmartInstrument;
            return (
              <Typography color={"inherit"} variant={"body1"}>
                {model.getUnitnominal()?.getToken()?.getCode()}
              </Typography>
            );
          },
        },
        {
          label: "Issue Date",
          field: "issuedate",
          accessor: (data) => {
            const model = data as SmartInstrument;
            return (
              <Typography
                // id={`smartInstrumentTable-spotNumber-text-${model.number}`}
                color={"inherit"}
                variant={"body1"}
              >
                {protobufTimestampToDayjs(
                  model.getIssuedate() ?? new Timestamp(),
                ).format(DateTimeFormat)}
              </Typography>
            );
          },
        },
        {
          label: "Asset Class",
          field: "assetclass",
          accessor: (data) => {
            const model = data as SmartInstrument;
            return (
              <Typography
                // id={`smartInstrumentTable-spotNumber-text-${model.number}`}
                color={"inherit"}
                variant={"body1"}
              >
                {assetClassToString(model.getAssetclass())}
              </Typography>
            );
          },
        },
        {
          label: "Unit Category",
          field: "unitcategory",
          accessor: (data) => {
            const model = data as SmartInstrument;
            return (
              <Typography
                // id={`smartInstrumentTable-spotNumber-text-${model.number}`}
                color={"inherit"}
                variant={"body1"}
              >
                {unitCategoryToString(model.getUnitcategory())}
              </Typography>
            );
          },
        },
        {
          label: "State",
          field: "state",
          accessor: (data) => {
            const model = data as SmartInstrument;
            return <SmartInstrumentStateChip state={model.getState()} />;
          },
        },
      ]}
    />
  );
};

const noDataSplashComponent = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(0.5),
        })}
      >
        <FaceIcon
          sx={(theme) => ({
            fontSize: 110,
            color: alpha(theme.palette.background.default, 0.5),
          })}
        />
        <Typography
          color="secondary"
          variant="h4"
          children="Nothing to see here"
        />
        <Typography
          variant="body2"
          children={
            <span>
              You will see <i>smart instruments</i> once you've created one.
            </span>
          }
        />
      </Box>
    </Box>
  );
};
