import {
  Listing,
  MechanismType,
  ListingStateController,
  ListAssetRequest,
} from "james/market";
import {
  Autocomplete,
  Box,
  Button,
  CardContent,
  Divider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useApplicationContext } from "context/Application/Application";
import BigNumber from "bignumber.js";
import { TextNumField } from "components/FormFields";
import { useSnackbar } from "notistack";
import { LedgerNetwork } from "james/ledger/Network";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ListingStateChip } from "views/MarketListing/MarketListings/Chips";
import { useValidatedForm } from "hooks/useForm";
import {
  formDataUpdaterSpecs,
  formDataValidationFunc,
} from "./useValidatedForm";
import { ListingState } from "james/market/Listing";
import { SubscriptionOrderBookForm } from "../SubscriptionOrderBookForm";
import { SubscriptionOrderBook } from "james/market/SubscriptionOrderBook";
import { DigitalFixedRateBond, DigitalFloatingRateBond } from "james/financial";
import { useErrorContext } from "context/Error";
dayjs.extend(utc);
dayjs.extend(timezone);

const PREFIX = "MarketMechanismClasses";
const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  heading: `${PREFIX}-heading`,
  miniLogoWrapper: `${PREFIX}-miniLogoWrapper`,
  dialogContent: `${PREFIX}-dialogContent`,
  chooseMarketMechanismClassesLayout: `${PREFIX}-chooseMarketMechanismClassesLayout`,
  chooseMarketMechanismClassesFirstRow: `${PREFIX}-chooseMarketMechanismClassesFirstRow`,
  chooseMarketMechanismClassesFormField: `${PREFIX}-chooseMarketMechanismClassesFormField`,
  boldText: `${PREFIX}-boldText`,
  lastActionAnnotationDialogTitleRootOverride: `${PREFIX}-lastActionAnnotationDialogTitleRootOverride`,
  dialogContentRootOverride: `${PREFIX}-dialogContentRootOverride`,
};

const marketmechanismoptions = [
  MechanismType.Subscription,
  MechanismType.DirectOrder,
  MechanismType.Spot,
];

export type AssetListingFormProps = {
  listing: Listing;
  asset: DigitalFixedRateBond | DigitalFloatingRateBond;
  subscriptionOrderBook: SubscriptionOrderBook;
};

export const AssetListingForm: React.FC<AssetListingFormProps> = ({
  ...props
}) => {
  const { authContext, viewConfiguration } = useApplicationContext();
  const { errorContextDefaultWarningFeedback } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();
  const [listingInProgress, setListingInProgress] = useState<boolean>(false);
  const [isActiveListing, setIsActiveListing] = useState<boolean>(
    props.listing.state === ListingState.Active,
  );

  const [initialLoading, setInitialLoading] = useState(true);
  const [mechanism, setMechanism] = useState<MechanismType>(
    MechanismType.Subscription,
  );

  const [
    formData,
    formDataValidationResult,
    formDataUpdate,
    formDataValidationInProgress,
  ] = useValidatedForm(
    formDataValidationFunc,
    async () => {
      return {
        asset: props.asset,
        listing: new Listing(props.listing),
        marketMechanism:
          props.listing.marketMechanisms[0]?.type ?? MechanismType.Subscription,
        minimumDealSize: BigNumber(1),
        maximumDealSize: BigNumber(
          props.subscriptionOrderBook.overSubscriptionAmount.value.isLessThan(1)
            ? 10
            : props.subscriptionOrderBook.overSubscriptionAmount.value,
        ),
      };
    },
    formDataUpdaterSpecs,
    {
      asset: props.asset,
      listing: new Listing(props.listing),
      marketMechanism:
        props.listing.marketMechanisms[0]?.type ?? MechanismType.Subscription,
      minimumDealSize: BigNumber(1),
      maximumDealSize: BigNumber(
        props.subscriptionOrderBook.overSubscriptionAmount.value.isLessThan(1)
          ? 10
          : props.subscriptionOrderBook.overSubscriptionAmount.value,
      ),
    },
    new Set<string>(["minimumDealSize", "maximumDealSize"]),
  );

  useEffect(() => {
    if (!props.asset) {
      return;
    }
    const listing = new Listing(props.listing);
    listing.token = props.asset.assetToken();
    formDataUpdate.listing(listing);
    setInitialLoading(false);
  }, [props.listing]);

  const creatingNewListing = formData.listing.id === "";

  const handleAssetListing: () => Promise<Listing> = async () => {
    const listingRequest: ListAssetRequest = {
      context: authContext,
      assetToken: props.asset.assetToken(),
      marketMechanism: formData.listing.marketMechanisms[0],
      estimatedAnnualReturn: BigNumber(0), // TODO: This should be gotten from the parent asset
      investmentObjective: "", // This is set in the media uploads section.
      exchangeNetwork: props.asset?.assetToken().network as LedgerNetwork,
    };

    if (creatingNewListing) {
      return (await ListingStateController.ListAsset(listingRequest)).listing;
    }

    setListingInProgress(false);
    return formData.listing;
  };

  return (
    <>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            m: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Typography
              variant="h4"
              sx={(theme) => ({
                fontSize: 20, // h4 font size
                fontWeight: theme.typography.fontWeightBold, // Bold font
                letterSpacing: 0,
              })}
            >
              {!isActiveListing ? (
                "Choose Primary Market Mechanism"
              ) : (
                <>{formData.listing.marketMechanisms[0].type}</>
              )}
            </Typography>
            {isActiveListing ? (
              <ListingStateChip state={formData.listing.state} />
            ) : null}
          </Box>

          <Tooltip
            title={
              viewConfiguration?.Instruments?.MarketAssetActions?.List
                ? formDataValidationResult.valid
                  ? "Create market listing for this asset"
                  : "Please ensure all fields are filled correctly before listing"
                : "You do not have permission to list this asset"
            }
            disableFocusListener
            disableTouchListener
          >
            <span>
              <Button
                color="primary"
                variant="contained"
                disabled={
                  viewConfiguration?.Instruments?.MarketAssetActions?.List &&
                  (!formDataValidationResult.valid ||
                    formDataValidationInProgress ||
                    isActiveListing)
                }
                onClick={async () => {
                  setListingInProgress(true);
                  try {
                    const listing = await handleAssetListing();
                    setListingInProgress(false);
                    setIsActiveListing(true);
                    enqueueSnackbar("Listing created", {
                      variant: "success",
                    });
                    listing.state = ListingState.Active;
                    formDataUpdate.listing(listing);
                  } catch (error) {
                    setListingInProgress(false);
                    setIsActiveListing(false);
                    errorContextDefaultWarningFeedback(
                      error,
                      "Could Not Create Listing",
                    );
                    throw error;
                  }
                }}
              >
                Place
              </Button>
            </span>
          </Tooltip>
        </Box>
        {/* Market Mechanism Selection dropdown */}

        <Autocomplete
          sx={{ width: "50%", m: 2 }}
          isOptionEqualToValue={(option, value) => {
            if (value === undefined) {
              return true;
            }
            return option === value;
          }}
          className={classes.chooseMarketMechanismClassesFormField}
          id="newInstrumentDialog-tokenClass-autoComplete"
          options={marketmechanismoptions}
          readOnly={isActiveListing}
          defaultValue={
            formData.listing.marketMechanisms[0]?.type ??
            MechanismType.Subscription
          }
          onChange={(__: object, value) => {
            if (!value) {
              return;
            }
            formDataUpdate.marketMechanism(value as MechanismType);
            setMechanism(value as MechanismType);
          }}
          value={mechanism?.toString() ?? ""}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              id="newInstrumentDialog-tokenClass-autoCompleteTextField"
              label="Select Instrument Type"
              placeholder="Select..."
              variant="outlined"
            />
          )}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 5,
            m: 2,
          }}
        >
          <TextNumField
            disabled={listingInProgress || isActiveListing}
            disallowNegative
            label="Minimum Deal Size"
            noDecimalPlaces={props.asset.fractionalisationAllowed ? 2 : 0}
            value={
              formData.listing.marketMechanisms[0].quoteParameters[0]
                .minimumDealSize.value
            }
            onChange={(e) =>
              formDataUpdate.minimumDealSize(BigNumber(e.target.value))
            }
            error={!!formDataValidationResult.fieldValidations.minimumDealSize}
            helperText={
              formDataValidationResult.fieldValidations.minimumDealSize
            }
          />
          <TextNumField
            disabled={listingInProgress || isActiveListing}
            disallowNegative
            label="Maximum Deal Size"
            noDecimalPlaces={props.asset.fractionalisationAllowed ? 2 : 0}
            value={
              formData.listing.marketMechanisms[0].quoteParameters[0]
                .maximumDealSize.value
            }
            onChange={(e) =>
              formDataUpdate.maximumDealSize(BigNumber(e.target.value))
            }
            error={!!formDataValidationResult.fieldValidations.maximumDealSize}
            helperText={
              formDataValidationResult.fieldValidations.maximumDealSize
            }
          />
        </Box>

        {mechanism === MechanismType.Subscription && !initialLoading ? (
          <>
            <Divider />
            <SubscriptionOrderBookForm
              asset={props.asset}
              subscriptionOrderBook={props.subscriptionOrderBook}
              listing={formData.listing}
            />
          </>
        ) : null}
      </CardContent>
    </>
  );
};
