import { Instrument } from "./Instrument";
import { Amount } from "james/ledger";

export interface Bond extends Instrument {
  bondTotalNominal(): Amount;

  bondState(): BondState | "";
}

export enum BondState {
  Draft = "Draft",
  PreIssued = "Pre-Issued",
  Issued = "Issued",
  Matured = "Matured",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}

export const AllBondStates: BondState[] = [
  BondState.Draft,
  BondState.PreIssued,
  BondState.Issued,
  BondState.Matured,
  BondState.Cancelled,
  BondState.Deleted,
];
