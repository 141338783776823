import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import {
  Model as MarketDirectOrderViewModel,
  ReadRequest,
  ReadResponse,
} from "james/views/marketDirectOrderView";
import { NewSorting, Query } from "james/search/query";
import {
  DateRangeCriterion,
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import {
  Cancel as CancelIcon,
  Close as ClearIcon,
  DeleteForever as ClearFiltersIcon,
  Error as ErrorIcon,
  FaceOutlined as FaceIcon,
  HowToReg as InitiatingUserIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { DirectOrderState, DirectOrderType } from "james/market/DirectOrder";
import { BPTable } from "components/Table";
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { DateFormat } from "const/dateformats";
import { Column } from "components/Table/BPTable/BPTable";
import { Amount } from "components/Ledger/Amount";
import cx from "classnames";
import { DateRangeValue } from "james/search/criterion/date/Range";
import { DateField } from "components/FormFields";
import { useIsMounted } from "hooks";
import { dateIsValid } from "utilities/date/dateIsValid";
import { DirectOrderStateChip } from "./components/Chips";
import { FancyTableModeButton } from "./components/FancyTableModeButton";
import { tableModeInitialCriteriaIdx } from "./Orders";
import { useApplicationContext } from "context/Application/Application";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

const PREFIX = "Table";

const classes = {
  title: `${PREFIX}-title`,
  initiatingPartyCellLayout: `${PREFIX}-initiatingPartyCellLayout`,
  initiatingPartyIcon: `${PREFIX}-initiatingPartyIcon`,
  orderDateTime: `${PREFIX}-orderDateTime`,
  successText: `${PREFIX}-successText`,
  warningText: `${PREFIX}-warningText`,
  errorText: `${PREFIX}-errorText`,
  textSearchFilterField: `${PREFIX}-textSearchFilterField`,
  statusSelectFilterField: `${PREFIX}-statusSelectFilterField`,
  dateFilterField: `${PREFIX}-dateFilterField`,
  clearIcon: `${PREFIX}-clearIcon`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.title}`]: {
    zIndex: 1000,
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: theme.spacing(3),
    alignItems: "center",
  },

  [`& .${classes.initiatingPartyCellLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: theme.spacing(1.5),
    alignItems: "center",
  },

  [`& .${classes.initiatingPartyIcon}`]: {
    color: theme.palette.custom.lavender,
    fontSize: 16,
  },

  [`& .${classes.orderDateTime}`]: {
    fontWeight: "bold",
  },

  [`& .${classes.successText}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.warningText}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.errorText}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.textSearchFilterField}`]: {
    width: 160,
  },

  [`& .${classes.statusSelectFilterField}`]: {
    width: 230,
  },

  [`& .${classes.dateFilterField}`]: {
    width: 160,
  },

  [`& .${classes.clearIcon}`]: {
    marginRight: -15,
    color: theme.palette.action.disabled,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.action.active,
    },
  },
}));

export enum TableMode {
  awaitingConfirmation = "Awaiting Confirmation",
  history = "History",
}

const historyTableModeDirectOrderStatesForFilter = [
  DirectOrderState.Cancelled,
  DirectOrderState.Declined,
  DirectOrderState.Failed,
  DirectOrderState.Accepted,
  DirectOrderState.Settled,
];

export const tableModeInitialQueryIdx: { [key: string]: Query } = {
  [TableMode.awaitingConfirmation]: new Query({
    limit: 10,
    offset: 0,
    sorting: [NewSorting("id", "asc")],
  }),
  [TableMode.history]: new Query({
    limit: 10,
    offset: 0,
    sorting: [NewSorting("lastModifiedTime", "desc")],
  }),
};

export interface TableProps {
  selectedMarketDirectOrderViewModel?: MarketDirectOrderViewModel;
  selectMarketDirectOrderViewModel: (
    model?: MarketDirectOrderViewModel,
  ) => void;
  refreshTableCallbackRef: MutableRefObject<() => void>;
  changeTableSideCallbackRef: MutableRefObject<
    (updatedTableMode: TableMode) => void
  >;
  loading: boolean;
  setReadRequest: Dispatch<SetStateAction<ReadRequest>>;
  initialRender: boolean;
  readResponse: ReadResponse;
  readRequest: ReadRequest;
  setTableMode: (
    value: ((prevState: TableMode) => TableMode) | TableMode,
  ) => void;
  tableMode: TableMode;
}

export function Table(props: TableProps) {
  const theme = useTheme();
  const isMounted = useIsMounted();

  const { loginClaims } = useApplicationContext();
  const [noSplashSubText, setNoSplashSubText] = useState("");
  const { selectMarketDirectOrderViewModel } = props; // function

  // table criteria
  const [textSearchCriteria, setTextSearchCriteria] = useState("");
  const [orderStatusesForCriterion, setOrderStatusesForCriterion] = useState<
    DirectOrderState[]
  >([]);
  const [orderDateTimeCriterionFrom, setOrderDateTimeCriterionFrom] = useState<
    DateRangeValue | undefined
  >(undefined);
  const [orderDateTimeCriterionTo, setOrderDateTimeCriterionTo] = useState<
    DateRangeValue | undefined
  >(undefined);
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  useEffect(() => {
    // prepare new initial criteria for table mode
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = { ...tableModeInitialCriteriaIdx[props.tableMode] };

    // populate criteria with those criteria set as necessary
    if (textSearchCriteria) {
      criteria = {
        ...criteria,
        $or: [
          { number: TextSubstringCriterion(textSearchCriteria) },
          { assetName: TextSubstringCriterion(textSearchCriteria) },
          {
            initiatingPartyClientName:
              TextSubstringCriterion(textSearchCriteria),
          },
          {
            counterpartyClientName: TextSubstringCriterion(textSearchCriteria),
          },
        ],
      };
    }

    if (orderStatusesForCriterion.length) {
      criteria.state = TextListCriterion(orderStatusesForCriterion);
    }

    if (orderDateTimeCriterionFrom || orderDateTimeCriterionTo) {
      criteria.orderDateTime = DateRangeCriterion(
        orderDateTimeCriterionFrom,
        orderDateTimeCriterionTo,
      );
    }

    if (isMounted()) {
      // update read request
      props.setReadRequest({
        context: props.readRequest.context,
        query: tableModeInitialQueryIdx[props.tableMode],
        criteria,
      });

      // and clear selected
      selectMarketDirectOrderViewModel(undefined);
    }

    if (props.tableMode === TableMode.history) {
      setNoSplashSubText(
        "You will see a list once you have actioned an order.",
      );
    }

    if (props.tableMode === TableMode.awaitingConfirmation) {
      setNoSplashSubText("You will see a list once you have a new order.");
    }
  }, [
    isMounted,
    selectMarketDirectOrderViewModel,
    textSearchCriteria,
    orderStatusesForCriterion,
    orderDateTimeCriterionFrom,
    orderDateTimeCriterionTo,
    props.tableMode,
    props.setReadRequest,
    props.readRequest.context,
  ]);

  const handleChangeTableMode = (newTableMode: TableMode) => {
    if (isMounted()) {
      // each time table mode changes clear criteria
      setTextSearchCriteria("");
      setOrderStatusesForCriterion([]);
      setOrderDateTimeCriterionFrom(undefined);
      setOrderDateTimeCriterionTo(undefined);

      // and set table mode
      props.setTableMode(newTableMode);
    }
  };

  // set call back refs
  props.refreshTableCallbackRef.current = () =>
    props.setReadRequest({
      ...props.readRequest,
      query: tableModeInitialQueryIdx[props.tableMode],
    });
  props.changeTableSideCallbackRef.current = handleChangeTableMode;

  // prepare index of the columns that should be in the table the active table mode
  const { current: commonColumns } = useRef<Column[]>([
    {
      label: "Order No.",
      field: "number",
      maxWidth: 160,
      minWidth: 100,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      accessor: (data: { [p: string]: any }) => {
        const marketDirectOrderViewModel = data as MarketDirectOrderViewModel;
        return (
          <Typography
            id={`orderNumber-${marketDirectOrderViewModel.number}`}
            sx={(theme) => ({ marginRight: theme.spacing(5) })}
            color="inherit"
            variant="body1"
          >
            {marketDirectOrderViewModel.number}
          </Typography>
        );
      },
    },
    {
      label: "Buyer",
      field: "",
      maxWidth: 160,
      minWidth: 100,
      sortable: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      accessor: (data: { [p: string]: any }) => {
        const marketDirectOrderViewModel = data as MarketDirectOrderViewModel;

        // if order is of type BUY
        if (marketDirectOrderViewModel.orderType === DirectOrderType.Buy) {
          // then the initiating party is the buyer
          return (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                gridColumnGap: theme.spacing(1.5),
                alignItems: "center",
                marginRight: theme.spacing(5),
              }}
            >
              <Typography
                children={marketDirectOrderViewModel.initiatingPartyClientName}
              />
              <InitiatingUserIcon
                id={`table-buyer-initiatingUserIcon-${marketDirectOrderViewModel.number}-${marketDirectOrderViewModel.initiatingPartyClientName}`}
                className={classes.initiatingPartyIcon}
              />
            </Box>
          );
        }
        // otherwise this is a sell order, the counterparty is the seller
        return (
          <Box sx={(theme) => ({ marginRight: theme.spacing(5) })}>
            <Typography>
              {marketDirectOrderViewModel.counterpartyClientName}
            </Typography>
          </Box>
        );
      },
    },
    {
      label: "Seller",
      field: "",
      minWidth: 100,
      maxWidth: 160,
      sortable: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      accessor: (data: { [p: string]: any }) => {
        const marketDirectOrderViewModel = data as MarketDirectOrderViewModel;

        // if order is of type SELL
        if (marketDirectOrderViewModel.orderType === DirectOrderType.Sell) {
          // then the initiating party is the seller
          return (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                gridColumnGap: theme.spacing(1.5),
                alignItems: "center",
                marginRight: theme.spacing(5),
              }}
            >
              <Typography
                children={marketDirectOrderViewModel.initiatingPartyClientName}
              />
              <InitiatingUserIcon
                id={`table-seller-initiatingUserIcon-${marketDirectOrderViewModel.number}-${marketDirectOrderViewModel.initiatingPartyClientName}`}
                className={classes.initiatingPartyIcon}
              />
            </Box>
          );
        }
        // otherwise this is a buy order, the counterparty is the buyer
        return (
          <Box sx={(theme) => ({ marginRight: theme.spacing(5) })}>
            <Typography>
              {marketDirectOrderViewModel.counterpartyClientName}
            </Typography>
          </Box>
        );
      },
    },
    {
      label: "Instr.",
      field: "",
      minWidth: 100,
      maxWidth: 160,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      accessor: (data: { [p: string]: any }) => {
        const marketDirectOrderViewModel = data as MarketDirectOrderViewModel;
        return (
          <Typography
            sx={(theme) => ({ marginRight: theme.spacing(5) })}
            id={`table-assetName-typography-${marketDirectOrderViewModel.assetName}-${marketDirectOrderViewModel.directOrderID}`}
          >
            {marketDirectOrderViewModel.assetName}
          </Typography>
        );
      },
    },
  ]);

  // prepare columns required for different table modes
  const { current: tableModeColumnIndex } = useRef<{ [key: string]: Column[] }>(
    {
      [TableMode.awaitingConfirmation]: [
        ...commonColumns,
        {
          label: "Date",
          field: "orderDateTime",
          minWidth: 100,
          maxWidth: 160,

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            const marketDirectOrderViewModel =
              data as MarketDirectOrderViewModel;
            const orderDateTimeDayjs = dayjs(
              marketDirectOrderViewModel.orderDateTime,
            );
            const submittedMinutesAgo = dayjs().diff(
              orderDateTimeDayjs,
              "minutes",
              true,
            );

            return (
              <Typography
                sx={(theme) => ({ marginRight: theme.spacing(5) })}
                className={cx(classes.orderDateTime, {
                  [classes.successText]: submittedMinutesAgo < 5,
                  [classes.warningText]:
                    submittedMinutesAgo >= 5 && submittedMinutesAgo < 10,
                  [classes.errorText]: submittedMinutesAgo >= 10,
                })}
                children={orderDateTimeDayjs.format(DateFormat)}
              />
            );
          },
        },
        {
          field: "amountIncl.value.float",
          label: "Amount",
          minWidth: 100,
          maxWidth: 160,

          accessor: (data) => {
            const marketDirectOrderViewModel =
              data as MarketDirectOrderViewModel;

            const viewingAsInitiatingParty =
              loginClaims.clientID ===
              marketDirectOrderViewModel.initiatingPartyClientID;
            const isBuy =
              marketDirectOrderViewModel.orderType === DirectOrderType.Buy;

            return (
              <Box sx={(theme) => ({ marginRight: theme.spacing(2) })}>
                <Amount
                  amount={
                    viewingAsInitiatingParty
                      ? isBuy // View as initiating party to a...
                        ? // Buy:
                          // Show how much stablecoin they pay
                          marketDirectOrderViewModel.amountIncl
                        : // Sell:
                          // Show how much stablecoin they receive
                          marketDirectOrderViewModel.amountIncl
                      : isBuy // View as counterparty to a...
                        ? // Buy:
                          // Show how much stablecoin they receive
                          marketDirectOrderViewModel.amountIncl.setValue(
                            marketDirectOrderViewModel.amountIncl.value.minus(
                              marketDirectOrderViewModel.feeAmount.value,
                            ),
                          )
                        : // Sell:
                          // Show how much stablecoin they pay
                          marketDirectOrderViewModel.amountIncl.setValue(
                            marketDirectOrderViewModel.amountIncl.value.plus(
                              marketDirectOrderViewModel.feeAmount.value,
                            ),
                          )
                  }
                />
              </Box>
            );
          },
        },
      ],
      [TableMode.history]: [
        ...commonColumns,
        {
          label: "Date",
          field: "orderDateTime",
          minWidth: 100,
          maxWidth: 160,

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            const marketDirectOrderViewModel =
              data as MarketDirectOrderViewModel;

            return (
              <Typography sx={(theme) => ({ marginRight: theme.spacing(5) })}>
                {dayjs(marketDirectOrderViewModel.orderDateTime).format(
                  DateFormat,
                )}
              </Typography>
            );
          },
        },
        {
          label: "State",
          field: "state",
          maxWidth: 160,

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            const model = data as MarketDirectOrderViewModel;
            if (model.state === DirectOrderState.Failed) {
              return (
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    gap: theme.spacing(1),
                    alignItems: "center",
                  })}
                >
                  <DirectOrderStateChip
                    state={(data as MarketDirectOrderViewModel).state}
                  />
                  <Tooltip
                    title={model.failureReasons.join(", ")}
                    placement={"top"}
                  >
                    <ErrorIcon
                      sx={{
                        color: "secondary.light",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Box>
              );
            }
            return (
              <Box sx={(theme) => ({ marginRight: theme.spacing(5) })}>
                <DirectOrderStateChip
                  state={(data as MarketDirectOrderViewModel).state}
                />
              </Box>
            );
          },
        },
      ],
    },
  );

  // prepare filters required for different table modes
  const tableModeFilterIndex: { [key in TableMode]: React.ReactNode[] } = {
    [TableMode.awaitingConfirmation]: [],
    [TableMode.history]: [
      <TextField
        variant="outlined"
        margin="dense"
        className={classes.textSearchFilterField}
        id="directOrdersTable-textSearch-textField"
        label="Search"
        placeholder="Start Typing..."
        value={textSearchCriteria}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => setTextSearchCriteria(e.target.value)}
      />,
      <DateField
        label="From"
        disabled={props.loading}
        id="directOrdersTable-orderDateTimeFrom-dateField"
        className={classes.dateFilterField}
        value={
          orderDateTimeCriterionFrom ? orderDateTimeCriterionFrom.date : null
        }
        onChange={(newValue) => {
          if (newValue?.isAfter(orderDateTimeCriterionTo?.date ?? dayjs())) {
            setOrderDateTimeCriterionFrom(undefined);
          } else if (!(newValue && dateIsValid(newValue))) {
            setOrderDateTimeCriterionFrom(undefined);
          } else {
            setOrderDateTimeCriterionFrom(
              newValue
                ? {
                    date: newValue.startOf("day").format(),
                    inclusive: true,
                    ignore: false,
                  }
                : undefined,
            );
          }
        }}
        renderInput={(textFieldProps: TextFieldProps) => (
          <TextField
            {...textFieldProps}
            variant="outlined"
            margin="dense"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (() => (
                <>
                  {orderDateTimeCriterionFrom && (
                    <Tooltip title="Clear" placement="top">
                      <IconButton
                        className={classes.clearIcon}
                        size="small"
                        onClick={() => setOrderDateTimeCriterionFrom(undefined)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {textFieldProps.InputProps &&
                  textFieldProps.InputProps.endAdornment
                    ? textFieldProps.InputProps.endAdornment
                    : null}
                </>
              ))(),
            }}
          />
        )}
      />,
      <DateField
        label="To"
        disabled={props.loading}
        id="directOrdersTable-orderDateTimeTo-dateField"
        className={classes.dateFilterField}
        value={orderDateTimeCriterionTo ? orderDateTimeCriterionTo.date : null}
        onChange={(newValue) => {
          if (
            orderDateTimeCriterionFrom &&
            newValue?.isBefore(orderDateTimeCriterionFrom.date)
          ) {
            setOrderDateTimeCriterionTo(undefined);
          } else if (!(newValue && dateIsValid(newValue))) {
            setOrderDateTimeCriterionTo(undefined);
          } else {
            setOrderDateTimeCriterionTo(
              newValue
                ? {
                    date: newValue.endOf("day").format(),
                    inclusive: true,
                    ignore: false,
                  }
                : undefined,
            );
          }
        }}
        renderInput={(textFieldProps: TextFieldProps) => (
          <TextField
            {...textFieldProps}
            variant="outlined"
            margin="dense"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (() => (
                <>
                  {orderDateTimeCriterionTo && (
                    <Tooltip title="Clear" placement="top">
                      <IconButton
                        className={classes.clearIcon}
                        size="small"
                        onClick={() => {
                          setOrderDateTimeCriterionTo(undefined);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {textFieldProps.InputProps &&
                  textFieldProps.InputProps.endAdornment
                    ? textFieldProps.InputProps.endAdornment
                    : null}
                </>
              ))(),
            }}
          />
        )}
      />,
      <Autocomplete
        isOptionEqualToValue={(option, value) => option === value}
        fullWidth
        id="directOrdersTable-state-autocomplete"
        disabled={props.loading}
        multiple
        options={historyTableModeDirectOrderStatesForFilter}
        filterSelectedOptions
        onChange={(_, value: DirectOrderState[]) =>
          setOrderStatusesForCriterion(value)
        }
        value={orderStatusesForCriterion}
        renderTags={(directOrderStates: DirectOrderState[]) =>
          directOrderStates.map((s, idx) => (
            <Box sx={{ padding: "2px" }}>
              <DirectOrderStateChip
                key={idx}
                state={s}
                chipProps={{
                  onDelete: () => {
                    setOrderStatusesForCriterion((prev) =>
                      prev.filter((prevState) => prevState !== s),
                    );
                  },
                  deleteIcon: (
                    <CancelIcon
                      sx={(theme) => ({
                        color: `${theme.palette.text.secondary} !important`,
                        "&:hover": {
                          color: `${theme.palette.secondary.contrastText} !important`,
                        },
                      })}
                    />
                  ),
                }}
              />
            </Box>
          ))
        }
        ChipProps={{
          color: "info",
          size: "small",
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={{
              minWidth: 388,
            }}
            InputLabelProps={{ shrink: true }}
            className={classes.statusSelectFilterField}
            label="State"
            variant="outlined"
            margin="dense"
            placeholder={
              orderStatusesForCriterion.length ? undefined : "Select..."
            }
            inputProps={{
              ...params.inputProps,
              readOnly: true,
            }}
          />
        )}
      />,
    ],
  };

  if (props.initialRender) {
    return (
      <StyledBox>
        <BPTable
          singleSelect
          height={window.innerHeight - 201 - noticeBannerHeight}
          title={
            <div className={classes.title}>
              <Typography variant="subtitle1" children="Orders" />
            </div>
          }
          columns={[]}
          loading={props.initialRender}
          query={undefined}
          onQueryChange={(newQuery: Query) => newQuery}
          data={[]}
          totalNoRecords={5}
          filterRowStyling={{ maxWidth: "800px" }}
          interactionDriver={InteractionDriver.Search}
        />
      </StyledBox>
    );
  }

  return (
    <StyledBox>
      <BPTable
        singleSelect
        height={window.innerHeight - 201 - noticeBannerHeight}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        selectedRowCheck={(data: { [p: string]: any }) =>
          props.selectedMarketDirectOrderViewModel
            ? (data as MarketDirectOrderViewModel).directOrderID ===
              props.selectedMarketDirectOrderViewModel.directOrderID
            : false
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSingleSelectChange={(data: { [p: string]: any } | undefined) =>
          props.selectMarketDirectOrderViewModel(
            data as MarketDirectOrderViewModel,
          )
        }
        title={
          <div className={classes.title}>
            <Typography variant="subtitle1" children="Orders" />
            <FancyTableModeButton
              tableMode={props.tableMode}
              onTableModeChange={handleChangeTableMode}
            />
          </div>
        }
        toolBarControls={[
          <IconButton
            id="table-reloadOrders-button"
            size="small"
            disabled={props.loading}
            onClick={() => {
              props.selectMarketDirectOrderViewModel(undefined);
              props.setReadRequest({
                ...props.readRequest,
                query: tableModeInitialQueryIdx[props.tableMode],
              });
            }}
            data-link-info={JSON.stringify({
              content_interaction_id: "info-search",
              content_interaction_action: InteractionAction.Click,
              content_interaction_type: InteractionType.Icon,
              content_interaction_text: "refresh",
              content_interaction_driver: InteractionDriver.FindOrders,
            } as DataLinkInfoType)}
          >
            <ReloadIcon
              data-link-info={JSON.stringify({
                content_interaction_id: "info-search",
                content_interaction_action: InteractionAction.Click,
                content_interaction_type: InteractionType.Icon,
                content_interaction_text: "refresh",
                content_interaction_driver: InteractionDriver.FindOrders,
              } as DataLinkInfoType)}
            />
          </IconButton>,
        ]}
        filters={(() => {
          const f: React.ReactNode[] = tableModeFilterIndex[props.tableMode];

          if (
            (orderDateTimeCriterionFrom ||
              orderDateTimeCriterionTo ||
              orderStatusesForCriterion.length ||
              textSearchCriteria) &&
            props.tableMode === TableMode.history
          ) {
            f.push(
              <Button
                sx={{ marginTop: "10px" }}
                id={"marketDirectOrdersTable-clearAllFilters-button"}
                variant={"contained"}
                color={"secondary"}
                children={"clear all"}
                onClick={() => {
                  setOrderStatusesForCriterion([]);
                  setOrderDateTimeCriterionTo(undefined);
                  setOrderDateTimeCriterionFrom(undefined);
                  setTextSearchCriteria("");
                }}
                startIcon={<ClearFiltersIcon />}
              />,
            );
          }

          return f;
        })()}
        columns={tableModeColumnIndex[props.tableMode]}
        loading={props.loading}
        query={props.readRequest.query}
        onQueryChange={(newQuery: Query) =>
          props.setReadRequest({
            ...props.readRequest,
            query: newQuery,
          })
        }
        data={props.readResponse.models}
        totalNoRecords={props.readResponse.total}
        filterRowStyling={{ maxWidth: "800px" }}
        interactionDriver={InteractionDriver.Search}
        noDataSplashComponent={
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaceIcon
              sx={(theme) => ({
                fontSize: 110,
                color: alpha(theme.palette.background.default, 0.5),
              })}
            />
            <Typography
              variant="h4"
              sx={(theme) => ({
                fontWeight: "bold",
                color: theme.palette.text.tertiary,
                margin: theme.spacing(3, 0),
              })}
              children={"No Orders Yet?"}
            />
            <Typography
              variant="h5"
              sx={{
                width: "226px",
                color: (theme) => theme.palette.text.disabled,
                textAlign: "center",
              }}
              children={noSplashSubText}
            />
          </Box>
        }
      />
    </StyledBox>
  );
}
