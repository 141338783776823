// Google Tag Manager
export type DataLinkInfoType = {
  content_interaction_id: string;
  content_interaction_action: InteractionAction;
  content_interaction_text: string; // This should match what is shown on UI
  content_interaction_type: InteractionType; // for button, change to “button”, and icons = icon
  content_interaction_driver: InteractionDriver;
};

export type DataComponentInfo = {
  component_id: string;
  component_business_name: string;
  component_title: string;
  component_driver: string; // primary purpose of the component
};

export type DataComponentTransaction = {
  transaction_asset_buy_price: string;
  transaction_asset_sell_price: string;
  transaction_asset_id: string;
  transaction_asset_name: string;
  transaction_asset_issuer: string;
  transaction_asset_type: string;
  transaction_asset_risk_rating: string;
  transaction_asset_investor_profile: string;
  transaction_asset_currency: string;
};

export enum InteractionType {
  Button = "button",
  Icon = "icon",
  Link = "link",
  Nav = "nav",
  Banner = "banner",
  Checkbox = "checkbox",
  Accordion = "accordion",
  Tab = "tab",
  Select = "select",
  Switch = "switch",
  Dropdown = "dropdown",
}

export enum InteractionDriver {
  Buy = "buy",
  Sell = "sell",
  Subscribe = "subscribe",
  Explore = "explore",
  SignUp = "sign_up",
  SignIn = "sign_in",
  Research = "research",
  Showcase = "showcase",
  FundAccount = "fund_account",
  FundAccountAbandon = "fund_account_abandon",
  FundAccountCompleteClose = "fund_account_complete_close",
  UpdateFundLimit = "update_fund_limit",
  Navigation = "navigation",
  CompleteTutorial = "complete_tutorial",
  Tutorial = "tutorial",
  Join = "join",
  DefundAccount = "defund_account",
  MoreInfo = "more_info",
  TransactBuy = "transact_buy",
  TransactSell = "transact_sell",
  TransactTrade = "transact_trade",
  UpdateAssetLimit = "update_asset_limit",
  AccountTransfer = "account_transfer",
  TransferFunds = "transfer_funds",
  TransferFundsComplete = "transfer_funds_complete",
  BurnAsset = "burn_asset",
  DriveTransaction = "drive_transaction",
  SubmitProfileInformation = "submit_profile_information",
  Logout = "logout",
  TransactionComplete = "transaction_complete",
  DefundAcceptTerms = "defund_accept_terms",
  DefundFeesExplore = "defund_fees_Explore",
  Show_hide_content = "show_hide_content",
  DefundAccountComplete = "defund_account_complete",
  DefundExpress = "defund_express",
  FindOrders = "find_orders",
  ViewPortfolio = "view_portfolio",
  SearchOrders = "search orders",
  Search = "search",
  kycStart = "kyc_start",
  kycUser = "kyc_user",
  kycSubmit = "kyc_submit",
  kycSave = "kyc_save",
  kycComplete = "kyc_complete",
  listTradeHistory = "list_trade_history",
  listSubscriptionOrder = "list_Subscription_orders",
  profileCreation = "profile_creation",
  showOrderStatus = "show_order_status",
  waitingListAmount = "waiting_list_amount",
  optInToWaitingList = "opt-in_to-waitingList",
  notOptInToWaitingList = "not_opt-in_to-waitingList",
  subscribeToAsset = "subcribe_to_asset",
  form = "form",
}

export enum InteractionAction {
  Click = "click",
  Copy = "copy",
  Submit = "submit",
  Check = "check",
  Expand = "expand",
  Swipe = "swipe",
  SelectOption = "select option",
  Select = "select",
  Filter = "filter",
}

export enum userTypes {
  internal = "internal",
  partner = "partner",
}

export enum userLoginTypes {
  guest = "guest",
  loggedIn = "logged-in",
}

export enum userCategories {
  dormant = "dormant",
  active = "active",
  casual = "casual",
}

export enum pageDevice {
  mobile = "mobile web",
  desktop = "desktop web",
}

export enum userAffiliations {
  internal = "internal",
  partner = "partner",
}
