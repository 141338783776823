import { Router } from "routes";
import { MarketingInfo, Table } from "./components";
import { Box } from "@mui/material";
import { useApplicationContext } from "context/Application/Application";
import { MarketingInfoContextProvider } from "./components/MarketingInfo/Context";

export function Marketing() {
  const { viewConfiguration } = useApplicationContext();
  const system = !!viewConfiguration["Smart Instruments"]?.ReadUNSCOPED;

  return (
    <Router
      baseURL={"/builder/marketing"}
      redirectPath={"/builder/marketing/table"}
      routes={[
        {
          name: "Table",
          id: "table",
          path: "/builder/marketing/table",
          component: (
            <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
              <Table system={system} />
            </Box>
          ),
        },
        {
          name: "Marketing-Info",
          id: "marketing-info",
          path: "/builder/marketing/marketing-info",
          component: (
            <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
              <MarketingInfoContextProvider system={system}>
                <MarketingInfo />
              </MarketingInfoContextProvider>
            </Box>
          ),
        },
      ]}
    />
  );
}
