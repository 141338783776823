export enum BusinessDayConvention {
  Following = "Following",
  ModifiedFollowing = "Modified Following",
  Preceding = "Preceding",
  ModifiedPreceding = "Modified Preceding",
  Unadjusted = "Unadjusted",
  HalfMonthModifiedFollowing = "Half-Month Modified Following",
  Nearest = "Nearest",
}

export const allBusinessDayConventions: BusinessDayConvention[] = [
  BusinessDayConvention.Following,
  BusinessDayConvention.ModifiedFollowing,
  BusinessDayConvention.Preceding,
  BusinessDayConvention.ModifiedPreceding,
  BusinessDayConvention.Unadjusted,
  BusinessDayConvention.HalfMonthModifiedFollowing,
  BusinessDayConvention.Nearest,
];
