import dayjs from "dayjs";
import { Amount } from "james/ledger";

export enum EffectType {
  AccountCredited = "account_credited",
  AccountDebited = "account_debited",
  AccountCreated = "account_created",
  AccountRemoved = "account_removed",
}

export class Model {
  public effectID = "";

  public effectOperationID = "";

  public effectOperationIDInt = 0;

  public effectApplicationOrder = 0;

  public ownerID = "";

  public accountID = "";

  public effectLedgerCloseTime: string = dayjs().format();

  public description = "";

  public effectType: EffectType | "" = "";

  public amount: Amount = new Amount();

  public balance: Amount = new Amount();

  constructor(model?: Model) {
    if (!model) {
      return;
    }

    this.effectID = model.effectID;
    this.effectOperationID = model.effectOperationID;
    this.effectOperationIDInt = model.effectOperationIDInt;
    this.effectApplicationOrder = model.effectApplicationOrder;
    this.ownerID = model.ownerID;
    this.accountID = model.accountID;
    this.effectLedgerCloseTime = model.effectLedgerCloseTime;
    this.description = model.description;
    this.effectType = model.effectType;
    this.amount = new Amount(model.amount);
    this.balance = new Amount(model.balance);
  }
}
