import React, { useEffect, useState } from "react";
import { NewSorting, Query } from "james/search/query";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import {
  FinancialDigitalInstrumentViewModel,
  useFinancialDigitalInstrumentViewReaderRead,
} from "james/views/financialDigitalInstrumentView";
import {
  FinancialInstrumentState,
  AllFinancialInstrumentStates,
} from "james/financial/InstrumentState";
import {
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { BPTable } from "components/Table";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  OpenInNew as OpenInNewIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { TextField } from "components/FormFields";
import { useApplicationContext } from "context/Application/Application";
import { Column } from "components/Table/BPTable/BPTable";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

const PREFIX = "DigitalInstrumentTable";

const classes = {
  textSearchField: `${PREFIX}-textSearchField`,
  iconButton: `${PREFIX}-iconButton`,
  statusSelectFilterField: `${PREFIX}-statusSelectFilterField`,
  row: `${PREFIX}-row`,
};

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("id", "desc")],
});

export type DigitalInstrumentTableProps = {
  system: boolean;
  height: number;
  hideColumns?: string[];
};

export function DigitalInstrumentTable(props: DigitalInstrumentTableProps) {
  const { authContext } = useApplicationContext();
  const [, setSelectedDigitalInstrumentViewModel] = useState<
    FinancialDigitalInstrumentViewModel | undefined
  >(undefined);
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  const { readResponse, loading, setReadRequest, readRequest } =
    useFinancialDigitalInstrumentViewReaderRead(
      {
        context: authContext,
        query: new Query(initialQuery),
        criteria: {},
      },
      props.system,
    );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  const [
    digitalInstrumentStatusesCriterion,
    setDigitalInstrumentStatusesCriterion,
  ] = useState<FinancialInstrumentState[]>([]);
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [
          { number: TextSubstringCriterion(textSearchCriterionTextField) },
          {
            subscriptionOrderBookNumber: TextSubstringCriterion(
              textSearchCriterionTextField,
            ),
          },
          {
            assetName: TextSubstringCriterion(textSearchCriterionTextField),
          },
          {
            "maxTakeUpTokens.token.code": TextSubstringCriterion(
              textSearchCriterionTextField,
            ),
          },
        ],
      });
    }
  }, [textSearchCriterionTextField]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = {};

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    if (digitalInstrumentStatusesCriterion.length) {
      criteria.financialInstrumentState = TextListCriterion(
        digitalInstrumentStatusesCriterion,
      );
    }

    setReadRequest({
      context: readRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [
    digitalInstrumentStatusesCriterion,
    textSearchCriterion,
    readRequest.context,
    setReadRequest,
    authContext,
  ]);

  return (
    <BPTable
      singleSelect
      loading={loading}
      height={props.height - noticeBannerHeight}
      title={"Digital Instruments"}
      data={readResponse.models}
      onSingleSelectChange={(data) =>
        setSelectedDigitalInstrumentViewModel(
          data as FinancialDigitalInstrumentViewModel,
        )
      }
      columns={(() => {
        const columns: Column[] = [
          {
            label: "Type",
            field: "@type",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [p: string]: any }) => {
              return (data as FinancialDigitalInstrumentViewModel)
                .digitalInstrument["@type"];
            },
          },
          {
            label: "Name",
            field: "name",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [p: string]: any }) => {
              return (
                data as FinancialDigitalInstrumentViewModel
              ).digitalInstrument.instrumentName();
            },
          },
          {
            label: "State",
            field: "state",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [p: string]: any }) => {
              return (
                data as FinancialDigitalInstrumentViewModel
              ).digitalInstrument.instrumentState();
            },
          },
        ];

        columns.push({
          field: "",
          label: "",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [key: string]: any }) => (
            <Tooltip placement="top" title="View detail in new tab">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(
                    `${window.location.href.replace(
                      /\/table$/,
                      "/view/overview?id=",
                    )}${(
                      data as FinancialDigitalInstrumentViewModel
                    ).digitalInstrument.instrumentID()}`,
                    "_blank",
                  );
                }}
              >
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          ),
        });

        const filterCols = props.hideColumns ?? [];

        return columns.filter((c) => !filterCols.includes(c.field));
      })()}
      query={readRequest.query}
      onQueryChange={(query: Query) =>
        setReadRequest({
          ...readRequest,
          query,
        })
      }
      totalNoRecords={readResponse.total}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        controls.push(
          <Tooltip title={"Refresh"}>
            <span>
              <IconButton
                onClick={() =>
                  setReadRequest({
                    ...readRequest,
                    query: new Query(initialQuery),
                  })
                }
                id={"financialDigitalInstrumentsTable-refresh-iconButton"}
                disabled={loading}
                size={"small"}
              >
                <ReloadIcon />
              </IconButton>
            </span>
          </Tooltip>,
        );

        return controls;
      })()}
      filters={[
        <TextField
          id={"financialDigitalInstrumentsTable-textFilter-textField"}
          variant={"outlined"}
          margin={"dense"}
          className={classes.textSearchField}
          label={"Search Text Field"}
          placeholder={"Start Typing..."}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: textSearchCriterionTextField ? (
              <InputAdornment
                position={"end"}
                children={
                  <IconButton
                    id={
                      "financialDigitalInstrumentsTable-textFilterClearButton-iconButton"
                    }
                    size={"small"}
                    onClick={() => setTextSearchCriterionTextField("")}
                  >
                    <ClearIcon />
                  </IconButton>
                }
              />
            ) : undefined,
          }}
          value={textSearchCriterionTextField}
          onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
        />,
        <Autocomplete
          isOptionEqualToValue={(option, value) => option === value}
          id={"financialDigitalInstrumentsTable-stateFilter-autocomplete"}
          disabled={loading}
          multiple
          options={AllFinancialInstrumentStates}
          filterSelectedOptions
          onChange={(_, value: FinancialInstrumentState[]) =>
            setDigitalInstrumentStatusesCriterion(value)
          }
          ChipProps={{
            color: "info",
            size: "small",
            deleteIcon: (
              <CancelIcon
                sx={(theme) => ({
                  color: `${theme.palette.text.secondary} !important`,
                  "&:hover": {
                    color: `${theme.palette.secondary.contrastText} !important`,
                  },
                })}
              />
            ),
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id={
                "financialDigitalInstrumentsTable-stateFilter-autocompleteTextField"
              }
              className={classes.statusSelectFilterField}
              label={"State"}
              variant={"outlined"}
              margin={"dense"}
              InputLabelProps={{ shrink: true }}
              placeholder={
                digitalInstrumentStatusesCriterion.length
                  ? undefined
                  : "Select..."
              }
            />
          )}
        />,
      ]}
    />
  );
}
