"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringToassetflowState = exports.assetflowStateToString = exports.allassetflowStates = void 0;
const assetflowState_pb_1 = require("./assetflowState_pb");
// Get all frequencies as enum values
exports.allassetflowStates = Object.values(assetflowState_pb_1.AssetflowState).filter((value) => typeof value === "number");
// Define explicit mappings between assetflowState enums and custom string representations
const assetflowStateToStringMapping = {
    [assetflowState_pb_1.AssetflowState.UNDEFINED_ASSETFLOW_STATE]: "-",
    [assetflowState_pb_1.AssetflowState.PROJECTED_ASSETFLOW_STATE]: "Projected",
    [assetflowState_pb_1.AssetflowState.ACTUAL_ASSETFLOW_STATE]: "Actual",
    [assetflowState_pb_1.AssetflowState.PAID_ASSETFLOW_STATE]: "Paid",
};
// Reverse mapping from string to assetflowState enum
const stringToassetflowStateMapping = {};
for (const [key, value] of Object.entries(assetflowStateToStringMapping)) {
    stringToassetflowStateMapping[value] = Number(key);
}
class UnsupportedassetflowStateError extends Error {
    constructor(assetflowState) {
        const message = `Unsupported assetflowState: ${assetflowState}`;
        super(message);
        this.assetflowState = assetflowState;
    }
}
/**
 * Converts a AssetflowState enum instance to a custom string representation.
 * @param {AssetflowState} assetflowState - The assetflowState to convert.
 * @returns {string} The custom string representation of the assetflowState.
 */
function assetflowStateToString(assetflowState) {
    if (assetflowState in assetflowStateToStringMapping) {
        return assetflowStateToStringMapping[assetflowState];
    }
    else {
        throw new UnsupportedassetflowStateError(assetflowState);
    }
}
exports.assetflowStateToString = assetflowStateToString;
class UnsupportedassetflowStateStringError extends Error {
    constructor(assetflowStateStr) {
        const message = `Unsupported assetflowState string: ${assetflowStateStr}`;
        super(message);
        this.assetflowStateStr = assetflowStateStr;
    }
}
/**
 * Converts a custom string representation to a assetflowState enum instance.
 * @param {string} assetflowStateStr - The custom string representation of the assetflowState.
 * @returns {assetflowState} The corresponding assetflowState enum instance.
 */
function stringToassetflowState(assetflowStateStr) {
    if (assetflowStateStr in stringToassetflowStateMapping) {
        return stringToassetflowStateMapping[assetflowStateStr];
    }
    else {
        throw new UnsupportedassetflowStateStringError(assetflowStateStr);
    }
}
exports.stringToassetflowState = stringToassetflowState;
