import {
  Box,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import React, { useState } from "react";
import {
  PaymentDeferrals,
  RateResets,
  Payments,
  Assetflows,
} from "./components";
import { useBuilderContext } from "../../Context";
import { SmartInstrumentState } from "@mesh/common-js/dist/financial/smartInstrumentState_pb";
import { ErrorOutline as WarningIcon } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import {
  smartInstrumentToExcelWorkbook,
  writeAssetflowsToExcelWorkbook,
  writeRateResetsToExcelWorkbook,
} from "./writeToExcel";
import { Workbook } from "exceljs";

export type DataSectionProps = {
  height: number;
};

enum DataSectionTab {
  AssetFlows = "Asset Flows",
  RateResets = "Rate Resets",
  PaymentDeferrals = "Payment Deferrals",
  Payments = "Payments",
}

const allDataSectionTabs: DataSectionTab[] = [
  DataSectionTab.AssetFlows,
  DataSectionTab.RateResets,
  DataSectionTab.PaymentDeferrals,
  DataSectionTab.Payments,
];

export const DataSection = (props: DataSectionProps) => {
  const [selectedTab, setSelectedTab] = useState(DataSectionTab.RateResets);
  const {
    apiCallInProgress,
    simulationInProgress,
    formData,
    formDataValidationResult,
    simulationMode,
    runSimulation,
    simulationError,

    rateResets,
    simulatedRateResets,

    assetflows,
    simulatedAssetflows,
  } = useBuilderContext();

  const downloadData = async () => {
    try {
      // prepare workbook
      const wb = new Workbook();

      // write data to workbook
      smartInstrumentToExcelWorkbook(formData.smartInstrument, wb);
      writeRateResetsToExcelWorkbook(
        simulationMode ? simulatedRateResets : rateResets,
        wb,
        formData.smartInstrument.getLegsList(),
      );
      writeAssetflowsToExcelWorkbook(
        simulationMode ? simulatedAssetflows : assetflows,
        wb,
        formData.smartInstrument.getLegsList(),
      );

      // trigger download
      const data = await wb.xlsx.writeBuffer();
      saveAs(
        new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        }),
        `floatingRateBond-${dayjs().format()}.xlsx`,
      );
    } catch (e) {
      console.error("fail", e);
    }
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.grapeDark,
          display: "grid",
          gridTemplateColumns: "1fr auto",
          paddingRight: theme.spacing(1),
        })}
      >
        <Tabs scrollButtons visibleScrollbar value={selectedTab}>
          {allDataSectionTabs.map(
            (dataSectionTab: DataSectionTab, tabIdx: number) => (
              <Tab
                key={tabIdx}
                value={dataSectionTab}
                label={dataSectionTab}
                onClick={() => setSelectedTab(dataSectionTab)}
              />
            ),
          )}
        </Tabs>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: theme.spacing(1),
          })}
        >
          {formData.smartInstrument.getState() ===
            SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE && (
            <Tooltip
              placement="top"
              title={
                simulationMode ? (
                  (() => {
                    if (!formDataValidationResult.valid) {
                      const problems: string[] = [];
                      Object.keys(
                        formDataValidationResult.fieldValidations,
                      ).forEach((k) => {
                        if (formDataValidationResult.fieldValidations[k]) {
                          problems.push(
                            `${k}: ${formDataValidationResult.fieldValidations[k]}`,
                          );
                        }
                      });
                      return (
                        <Box>
                          <Typography>
                            All problems need to be resolved before calculations
                            can run:
                          </Typography>
                          <ul>
                            {problems.map((p, idx) => (
                              <li key={idx}>{p}</li>
                            ))}
                          </ul>
                        </Box>
                      );
                    }
                    return "Perform calculations";
                  })()
                ) : (
                  <span>
                    <b>Turn on</b> simulation mode to recalculate.
                  </span>
                )
              }
            >
              <span>
                <Button
                  id={"smartInstrumentForm-calculate-button"}
                  variant="contained"
                  color="secondary"
                  disabled={
                    !formDataValidationResult.valid ||
                    apiCallInProgress ||
                    !simulationMode
                  }
                  onClick={runSimulation}
                >
                  Calculate
                </Button>
              </span>
            </Tooltip>
          )}
          <Button
            endIcon={<DownloadIcon />}
            variant="outlined"
            onClick={downloadData}
          >
            Download
          </Button>
        </Box>
      </Box>
      <Box
        // className="meshScroll"
        sx={{
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          height: props.height - 50,
          overflow: "hidden",
        }}
      >
        {(() => {
          if (simulationInProgress) {
            return (
              <Box
                sx={(theme) => ({
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyItems: "center",
                  gap: theme.spacing(2),
                })}
              >
                <CircularProgress size={70} />
                <Typography
                  variant="h5"
                  color="textSecondary"
                  children="Simulation Running..."
                />
              </Box>
            );
          }

          if (simulationError) {
            return (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: theme.spacing(0.5),
                  })}
                >
                  <WarningIcon
                    sx={(theme) => ({
                      fontSize: 110,
                      color: alpha(theme.palette.background.default, 0.5),
                    })}
                  />
                  <Typography
                    color="secondary"
                    variant="h4"
                    children="Something Went Wrong"
                  />
                  <Typography variant="body2" children={simulationError} />
                </Box>
              </Box>
            );
          }

          switch (selectedTab) {
            case DataSectionTab.RateResets:
              return <RateResets height={props.height - 50} />;

            case DataSectionTab.PaymentDeferrals:
              return <PaymentDeferrals height={props.height - 50} />;

            case DataSectionTab.AssetFlows:
              return <Assetflows height={props.height - 50} />;

            case DataSectionTab.Payments:
              return <Payments height={props.height - 50} />;

            default:
              return null;
          }
        })()}
      </Box>
    </>
  );
};
