import { Asset } from "james/ledger/Asset";
import {
  CryptoCurrency,
  CurrencyStablecoin,
  DigitalETF,
  DigitalETFState,
  DigitalETN,
  DigitalETNState,
  ETFStablecoin,
  ETFStablecoinState,
  ETNStablecoin,
  ETNStablecoinState,
  DigitalFixedRateBond,
  DigitalFloatingRateBond,
} from "james/financial";
import { BondState } from "james/financial/Bond";

export function LedgerAssetReadyToList(asset: Asset): boolean {
  switch (true) {
    case asset instanceof CryptoCurrency:
      return true;

    case asset instanceof CurrencyStablecoin:
      return true;

    case asset instanceof DigitalETF: {
      const digitalETF = asset as DigitalETF;
      return [DigitalETFState.PreIssued, DigitalETFState.Issued].includes(
        digitalETF.state as DigitalETFState,
      );
    }

    case asset instanceof ETFStablecoin: {
      const etfStablecoin = asset as ETFStablecoin;
      return [ETFStablecoinState.PreIssued, ETFStablecoinState.Issued].includes(
        etfStablecoin.state as ETFStablecoinState,
      );
    }

    case asset instanceof DigitalETN: {
      const digitalETN = asset as DigitalETN;
      return [DigitalETNState.PreIssued, DigitalETNState.Issued].includes(
        digitalETN.state as DigitalETNState,
      );
    }

    case asset instanceof ETNStablecoin: {
      const etnStablecoin = asset as ETNStablecoin;
      return [ETNStablecoinState.PreIssued, ETNStablecoinState.Issued].includes(
        etnStablecoin.state as ETNStablecoinState,
      );
    }

    case asset instanceof DigitalFixedRateBond: {
      const digitalFixedRateBond = asset as DigitalFixedRateBond;
      return [BondState.PreIssued].includes(
        digitalFixedRateBond.state as BondState,
      );
    }

    case asset instanceof DigitalFloatingRateBond: {
      const digitalFloatingRateBond = asset as DigitalFloatingRateBond;
      return [BondState.PreIssued].includes(
        digitalFloatingRateBond.state as BondState,
      );
    }

    default:
      return false;
  }
}
