import React, { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  useTheme,
} from "@mui/material";
import { ReturnIndicator } from "./components/ReturnIndicator";
import { DirectOrderSection } from "./components/DirectOrderSection";
import { SpotSection } from "./components/SpotSection";
import { IssuePriceSection } from "./components/IssuePriceSection";
import { Header } from "./components/Header";
import { InstrumentTypeCard } from "./components/InstrumentType/InstrumentType";
import {
  CryptoPerformanceChartSplash,
  PerformanceChartSplash,
} from "./components/PerformanceChart";
import { AssetType } from "james/views/marketListingView/Model";
import { Mechanism, MechanismType } from "james/market";
import { Token } from "james/ledger";

import { YieldCoinLabel } from "components/Labels/YieldCoinLabel";
import { RiskProfileText } from "./components/RiskProfile/RiskProfile";
import { IssuerNameCard } from "./components/IssuerName/IssuerName";
import LogRocket from "logrocket";
import { AssetEvent } from "const/logRocket";
import { useNavigate } from "react-router-dom";
import { BondSection } from "./components/BondSection/BondSection";
import { MarketListingViewModel } from "james/views/marketListingView";
import { IssuerIconSection } from "./components/IssuerIcon/IssuerIcon";
import { DataComponentInfo } from "const/gtm";
import { SubscriptionOrderBookState } from "james/market/SubscriptionOrderBook";

const PREFIX = "MarketplaceCard";

const classes = {
  cardRoot: `${PREFIX}-cardRoot`,
  headerRoot: `${PREFIX}-headerRoot`,
  headerTitle: `${PREFIX}-headerTitle`,
  titleSkeletonLayout: `${PREFIX}-titleSkeletonLayout`,
  cardContent: `${PREFIX}-cardContent`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.cardRoot}`]: {
    width: "100%",
    maxWidth: 328,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 340,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 384,
    },
  },

  [`& .${classes.headerRoot}`]: {
    height: 86,
    borderBottom: "none",
    padding: theme.spacing(1, 2),
  },

  [`& .${classes.headerTitle}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1.5),
    alignItems: "center",
    padding: theme.spacing(0, 0.8, 0, 1),
    margin: `-3px 0px -3px 0px`,
  },

  [`& .${classes.titleSkeletonLayout}`]: {
    marginTop: -8,
  },

  [`& .${classes.cardContent}`]: {
    height: 290,
    [theme.breakpoints.up("sm")]: {
      height: 298,
    },
  },
}));

interface MarketplaceCardProps {
  marketListingViewModel: MarketListingViewModel;
  onView?: () => void;
}

const cryptoCardAssetTypes: (AssetType | "")[] = [
  AssetType.CryptoCurrency,
  AssetType.RightsToACryptoCurrency,
  AssetType.RightsToAFiatCurrency,
  AssetType.YieldBearingStablecoin,
];

export const MarketplaceCard = (props: MarketplaceCardProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [hover, setHover] = React.useState<boolean>(false);

  const { current: isCryptoCard } = useRef(
    cryptoCardAssetTypes.includes(props.marketListingViewModel.assetType),
  );
  const { current: assetIsIssued } = useRef(
    dayjs(props.marketListingViewModel.assetIssueDate).isBefore(dayjs()),
  );

  const [defaultQuoteToken, setDefaultQuoteToken] = useState<Token | undefined>(
    undefined,
  );

  const isSubscription = useMemo(
    () =>
      [
        AssetType.FixedRateBond,
        AssetType.FloatingRateBond,
        AssetType.Bond,
        AssetType.Note,
        AssetType.PreferenceShare,
        AssetType.Share,
        "",
      ].includes(props.marketListingViewModel.assetType),
    [props.marketListingViewModel.assetType],
  );

  useEffect(() => {
    // find spot market mechanism
    const spotMarketMechanism =
      props.marketListingViewModel.listingMarketMechanisms.find(
        (mm) => mm.type === MechanismType.Spot,
      );
    if (!spotMarketMechanism) {
      // not a spot listing - do nothing more
      return;
    }
    if (!spotMarketMechanism.quoteParameters.length) {
      console.error("expected at least 1 quote parameter");
      return;
    }

    // get default quote parameter
    let defaultQuoteParameter = spotMarketMechanism.quoteParameters.find(
      (qp) => qp.quoteToken.code === "mZAR",
    );
    if (!defaultQuoteParameter) {
      defaultQuoteParameter = spotMarketMechanism.quoteParameters.find(
        (qp) => qp.quoteToken.code === "USDC",
      );
    }
    if (!defaultQuoteParameter && spotMarketMechanism.quoteParameters.length) {
      defaultQuoteParameter = spotMarketMechanism.quoteParameters[0];
    }

    // return if no quote parameter was found
    if (!defaultQuoteParameter) {
      return;
    }

    setDefaultQuoteToken(defaultQuoteParameter.quoteToken);
  }, [props.marketListingViewModel]);

  return (
    <>
      <StyledCard
        sx={{
          width: "100%",
          maxWidth: 328,
          [theme.breakpoints.up("sm")]: {
            maxWidth: 384,
          },
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={(e) => {
          e.preventDefault();
          if (e && e.stopPropagation) {
            e.stopPropagation();
          }
          e.nativeEvent.stopImmediatePropagation();
          LogRocket.track(AssetEvent.explore, {
            assetName: props.marketListingViewModel.assetName,
            assetShortName: props.marketListingViewModel.assetShortName,
            assetType: props.marketListingViewModel.assetType,
          });
          if (props.onView) {
            props.onView();
          } else {
            navigate(
              `/market/asset-overview?code=${props.marketListingViewModel.token.code}&issuer=${props.marketListingViewModel.token.issuer}&network=${props.marketListingViewModel.token.network}`,
            );
          }
        }}
        data-component-info={JSON.stringify({
          component_id: "asset_card",
          component_business_name: "asset card",
          component_title: props.marketListingViewModel.assetName,
          component_driver: "drive_transaction",
        } as DataComponentInfo)}
      >
        <Header
          cardHover={hover}
          marketListingViewModel={props.marketListingViewModel}
          onView={props.onView}
        />
        <CardContent
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            height: 245,
            p: {
              xs: theme.spacing(3, 2, 1, 2),
              sm: theme.spacing(3, 3, 1, 3),
            },
          }}
        >
          {/* tradfi return percentage and issuer name */}
          {!isCryptoCard && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Box>
                <ReturnIndicator
                  returnDescription={
                    isSubscription
                      ? "Indicated Return"
                      : `Return (${props.marketListingViewModel.returnDescription})
                  `
                  }
                  returnDisclaimer={
                    isSubscription
                      ? props.marketListingViewModel.returnDescription
                      : undefined
                  }
                  subtitle={
                    props.marketListingViewModel.assetType ===
                    AssetType.PreferenceShare
                      ? props.marketListingViewModel.returnDescription
                      : ""
                  }
                  returnValue={props.marketListingViewModel.returnValue}
                  reverseAlign
                />
              </Box>
              <Box
                sx={{
                  maxWidth: "50%",
                }}
              >
                <IssuerNameCard
                  token={props.marketListingViewModel.token}
                  typographyProps={{
                    sx: {
                      color:
                        props.marketListingViewModel.assetType ===
                        AssetType.YieldBearingStablecoin
                          ? theme.palette.text.disabled
                          : theme.palette.text.primary,
                    },
                  }}
                />
              </Box>
            </Box>
          )}

          {/* Action Section */}
          {(() => {
            // if there are no market mechanisms then no action can be returned
            if (!props.marketListingViewModel.listingMarketMechanisms.length) {
              return null;
            }

            // assume that the first listing market mechanism is the relevant one
            let relevantMarketMechanism: Mechanism =
              props.marketListingViewModel.listingMarketMechanisms[0];

            // There is only 1 supported use case for 2 market mechanisms at the moment.
            // This is when 1 market mechanism is subscription order book and the other is
            // direct order. This is to deal with that use case after the book has settled.
            if (
              props.marketListingViewModel.listingMarketMechanisms.length ==
                2 &&
              props.marketListingViewModel.listingMarketMechanisms[0].type ===
                MechanismType.Subscription &&
              props.marketListingViewModel.listingMarketMechanisms[1].type ===
                MechanismType.DirectOrder &&
              props.marketListingViewModel
                .marketSubscriptionOrderBookViewModel &&
              props.marketListingViewModel.marketSubscriptionOrderBookViewModel
                .state === SubscriptionOrderBookState.Settled
            ) {
              relevantMarketMechanism =
                props.marketListingViewModel.listingMarketMechanisms[1];
            }

            switch (relevantMarketMechanism.type) {
              case MechanismType.DirectOrder:
                return assetIsIssued ? (
                  <DirectOrderSection
                    marketListingViewModel={props.marketListingViewModel}
                  />
                ) : (
                  <IssuePriceSection
                    issueDate={props.marketListingViewModel.assetIssueDate}
                  />
                );

              case MechanismType.Spot:
                return assetIsIssued ? (
                  <SpotSection
                    marketListingViewModel={props.marketListingViewModel}
                  />
                ) : (
                  <IssuePriceSection
                    issueDate={props.marketListingViewModel.assetIssueDate}
                  />
                );

              case MechanismType.Subscription:
                return (
                  <BondSection
                    marketListingViewModel={props.marketListingViewModel}
                  />
                );

              default:
                return null;
            }
          })()}

          {/* Yield Coin Label  */}
          {props.marketListingViewModel.assetType ===
            AssetType.YieldBearingStablecoin && (
            <YieldCoinLabel
              sx={{
                position: "absolute",
                left: 0,
                top: 112,
                borderRadius: "0 5px 5px 0",
              }}
            />
          )}

          {/* Issuer Name -- crypto */}
          {isCryptoCard && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                my: 2,
              }}
            >
              <IssuerNameCard
                token={props.marketListingViewModel.token}
                typographyProps={{
                  sx: {
                    color:
                      props.marketListingViewModel.assetType ===
                      AssetType.YieldBearingStablecoin
                        ? theme.palette.text.disabled
                        : theme.palette.text.primary,
                  },
                }}
              />
            </Box>
          )}

          {/* Absolute positioned: Instrument Type & Return */}
          <Box
            sx={{
              zIndex: 1,
              marginTop: "auto",
              display: "grid",
              gridTemplateColumns: "1fr auto",
              alignItems: "flex-start",
              mt: 3,
              pb: 3,
            }}
          >
            <InstrumentTypeCard
              token={props.marketListingViewModel.token}
              assetType={props.marketListingViewModel.assetType}
            />
            {!isCryptoCard && !isSubscription && (
              <RiskProfileText
                riskProfile={props.marketListingViewModel.instrumentRiskProfile}
              />
            )}
          </Box>

          {/* Absolute positioned: PerformanceChart */}
          {dayjs().isAfter(
            dayjs(props.marketListingViewModel.assetIssueDate),
          ) && (
            <Box
              sx={{
                zIndex: 0,
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: 80,
              }}
            >
              {isCryptoCard ? (
                defaultQuoteToken ? (
                  <CryptoPerformanceChartSplash
                    baseToken={props.marketListingViewModel.token}
                    quoteToken={defaultQuoteToken}
                  />
                ) : null
              ) : (
                <PerformanceChartSplash
                  assetToken={props.marketListingViewModel.token}
                />
              )}
            </Box>
          )}
        </CardContent>
        <CardHeader
          sx={{ p: 0 }}
          title={
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                px: 3,
                py: 1,
                height: 64,
                overflowY: "clip",
              }}
            >
              <IssuerIconSection
                marketListingViewModel={props.marketListingViewModel}
              />
            </Box>
          }
        />
      </StyledCard>
    </>
  );
};

export function MarketPlaceSkeletonCard() {
  return (
    <StyledCard classes={{ root: classes.cardRoot }}>
      <CardHeader
        className={classes.headerRoot}
        disableTypography
        title={
          <div className={classes.headerTitle}>
            <Skeleton
              animation="wave"
              variant={"circular"}
              height={50}
              width={50}
            />
            <div className={classes.titleSkeletonLayout}>
              <Skeleton animation="wave" height={30} width={200} />
              <Skeleton animation="wave" height={15} width={160} />
            </div>
          </div>
        }
      />
      <CardContent className={classes.cardContent}>
        <Skeleton animation="wave" height={"100%"} width={"100%"} />
      </CardContent>
    </StyledCard>
  );
}
