import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "../../utilities/network/jsonRPCRequest/jsonRPCRequest";
import { useEffect, useRef, useState } from "react";
import { DirectOrderType } from "../market/DirectOrder";
import { Context } from "../security";
import BigNumber from "bignumber.js";
import { userTypingAPICallDebounceIntervalMS } from "common/debouncing";
import { useCurrentAPICall, useIsMounted } from "../../hooks";
import { JSONRPCCallAbortedError } from "utilities/network/jsonRPCRequest";
import { useErrorContext } from "context/Error";

export interface CalculateDirectOrderFeeRequest {
  context: Context;
  directOrderType: DirectOrderType;
  price: BigNumber;
  tokens?: BigNumber;
  amountIncl?: BigNumber;
}

export interface CalculateDirectOrderFeeResponse {
  feeAmount: BigNumber;
  amountIncl: BigNumber;
  amountExcl: BigNumber;
  tokens: BigNumber;
}

export const DirectOrderFeeCalculator = {
  serviceProvider: "remuneration-DirectOrderFeeCalculator",
  async CalculateDirectOrderFee(
    request: CalculateDirectOrderFeeRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<CalculateDirectOrderFeeResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthenticatedURL"),
        method: `${DirectOrderFeeCalculator.serviceProvider}.CalculateDirectOrderFee`,
        request,
      },
      jsonRPCRequestOpts,
    );

    return {
      feeAmount: new BigNumber(response.feeAmount),
      amountIncl: new BigNumber(response.amountIncl),
      amountExcl: new BigNumber(response.amountExcl),
      tokens: new BigNumber(response.tokens),
    };
  },
};

export function useCalculateDirectOrderFee(
  initialCalculateDirectOrderFeeRequest?: CalculateDirectOrderFeeRequest,
) {
  const { errorContextErrorTranslator } = useErrorContext();
  const [calculateDirectOrderFeeRequest, setCalculateDirectOrderFeeRequest] =
    useState<CalculateDirectOrderFeeRequest | undefined>(
      initialCalculateDirectOrderFeeRequest,
    );
  const [calculateDirectOrderFeeResponse, setCalculateDirectOrderFeeResponse] =
    useState<CalculateDirectOrderFeeResponse>({
      feeAmount: new BigNumber("0"),
      amountIncl: new BigNumber("0"),
      amountExcl: new BigNumber("0"),
      tokens: new BigNumber("0"),
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const isMounted = useIsMounted();
  const [
    isCurrentCalculateDirectOrderFeeAPICall,
    initCalculateDirectOrderFeeAPICall,
  ] = useCurrentAPICall();

  useEffect(() => {
    // set a blank response if request is not set
    if (!calculateDirectOrderFeeRequest) {
      setCalculateDirectOrderFeeResponse({
        feeAmount: new BigNumber("0"),
        amountIncl: new BigNumber("0"),
        amountExcl: new BigNumber("0"),
        tokens: new BigNumber("0"),
      });
      return;
    }

    // indicate that calculation is in progress
    setLoading(true);

    // initialise API call
    const { apiCallID, abortController } = initCalculateDirectOrderFeeAPICall();

    // set up call to take place after debounce interval
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setError(undefined);
      try {
        const response = await DirectOrderFeeCalculator.CalculateDirectOrderFee(
          calculateDirectOrderFeeRequest,
          { signal: abortController.signal },
        );
        if (isMounted() && isCurrentCalculateDirectOrderFeeAPICall(apiCallID)) {
          setCalculateDirectOrderFeeResponse(response);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        if (err.code === JSONRPCCallAbortedError.ErrorCode) {
          return;
        }
        if (isMounted() && isCurrentCalculateDirectOrderFeeAPICall(apiCallID)) {
          console.error(
            `error calculating direct order fees: ${
              err.message ? err.message : err.toString()
            }`,
          );
          setError(
            `error calculating direct order fees: ${
              err.message ? err.message : err.toString()
            }`,
          );
        }
      }
      if (isMounted() && isCurrentCalculateDirectOrderFeeAPICall(apiCallID)) {
        setLoading(false);
      }
    }, userTypingAPICallDebounceIntervalMS);
  }, [calculateDirectOrderFeeRequest]);

  return {
    calculateDirectOrderFeeRequest,
    setCalculateDirectOrderFeeRequest,
    calculateDirectOrderFeeResponse,
    loading,
    error,
  };
}
