import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import {
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextareaAutosize,
  Tooltip,
  Typography,
  Stack,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Close as CloseIcon,
  GetAppOutlined as DownloadIcon,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Client, ClientKYCStatus, ClientRepository } from "james/client";
import { IdentificationType } from "james/legal";
import { countries } from "james/country";
import { DateField, TextField } from "components/FormFields";
import { LuhnAlgorithm } from "utilities/validation";
import cx from "classnames";
import { useSnackbar } from "notistack";
import { download } from "utilities/network/download";
import { Person } from "james/legal/person/Person";
import { IDIdentifier } from "james/search/identifier";
import { ClientDetailsInspector } from "james/kyc/ClientDetailsInspector";
import {
  KYCDocument,
  getDocTypesForDocumentClass,
  KYCDocumentClassification,
  KYCDocumentState,
  KYCDocumentType,
} from "james/kyc/KYCDocument";
import { IndividualClientKYCStateController } from "james/kyc/IndividualClientKYCStateController";
import {
  SourceOfIncomeAndWealthType,
  ValidSourceOfIncomeTypes,
  ValidSourceOfWealthTypes,
} from "james/legal/person";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { ApplicantInspector } from "james/sumsub/ApplicantInspector";

const PREFIX = "ViewKYCInfoDialog";

const documentUploaderClasses = {
  root: `${PREFIX}-root`,
  bottomBorder: `${PREFIX}-bottomBorder`,
  marginBottom: `${PREFIX}-marginBottom`,
  documentTextFieldLine: `${PREFIX}-documentTextFieldLine`,
  documentNameLine: `${PREFIX}-documentNameLine`,
  icon: `${PREFIX}-icon`,
  textFieldBottomMargin: `${PREFIX}-textFieldBottomMargin`,
  hiddenInput: `${PREFIX}-hiddenInput`,
  header: `${PREFIX}-header`,
  deleteIcon: `${PREFIX}-deleteIcon`,
  downloadButton: `${PREFIX}-downloadButton`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const DocumentUploaderRoot = styled("div")(({ theme }) => ({
  [`& .${documentUploaderClasses.root}`]: {
    paddingTop: theme.spacing(1),
  },

  [`& .${documentUploaderClasses.bottomBorder}`]: {
    borderBottom: `0.5px solid ${theme.palette.text.disabled}`,
  },

  [`& .${documentUploaderClasses.marginBottom}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${documentUploaderClasses.documentTextFieldLine}`]: {
    display: "grid",
    gridTemplateColumns: "auto 60px",
    gridColumnGap: theme.spacing(2),
    alignItems: "center",
  },

  [`& .${documentUploaderClasses.documentNameLine}`]: {
    display: "grid",
    gridTemplateColumns: "auto",
    gridColumnGap: theme.spacing(1),
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },

  [`& .${documentUploaderClasses.icon}`]: {
    fontSize: "25px !important",
    marginLeft: theme.spacing(1),
  },

  [`& .${documentUploaderClasses.textFieldBottomMargin}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${documentUploaderClasses.hiddenInput}`]: {
    visibility: "hidden",
    width: 0,
    height: 0,
  },

  [`& .${documentUploaderClasses.header}`]: {
    paddingBottom: theme.spacing(3),
  },

  [`& .${documentUploaderClasses.deleteIcon}`]: {
    width: 14,
    height: 18,
  },

  [`& .${documentUploaderClasses.downloadButton}`]: {
    marginTop: "4px",
    minWidth: 60,
  },
}));

const classes = {
  dialogContent: `${PREFIX}-dialogContent`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  miniLogoWrapper: `${PREFIX}-miniLogoWrapper`,
  accordionSummary: `${PREFIX}-accordionSummary`,
  rightBorder: `${PREFIX}-rightBorder`,
  documentUploadSection: `${PREFIX}-documentUploadSection`,
  circularProgressWrapper: `${PREFIX}-circularProgressWrapper`,
  supportingDocsTypography: `${PREFIX}-supportingDocsTypography`,
  mainHeading: `${PREFIX}-mainHeading`,
  heading: `${PREFIX}-heading`,
  subHeading: `${PREFIX}-subHeading`,
  successColor: `${PREFIX}-successColor`,
  warningColor: `${PREFIX}-warningColor`,
  outStandingChipColor: `${PREFIX}-outStandingChipColor`,
  verifiedChipColor: `${PREFIX}-verifiedChipColor`,
  inProgressChipColor: `${PREFIX}-inProgressChipColor`,
  kycCaptionWrapper: `${PREFIX}-kycCaptionWrapper`,
  kycStatusControls: `${PREFIX}-kycStatusControls`,
  kycTextArea: `${PREFIX}-kycTextArea`,
  updateCDDContainer: `${PREFIX}-updateCDDContainer`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogContent}`]: {
    display: "grid",
    gridTemplateColumns: "2.5fr 1.5fr",
    gridColumnGap: theme.spacing(2),
    margin: 0,
    padding: 0,
  },

  [`& .${classes.dialogTitle}`]: {
    backgroundColor: theme.palette.background.default,
  },

  [`& .${classes.miniLogoWrapper}`]: {
    height: 32,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },

  [`& .${classes.accordionSummary}`]: {
    backgroundColor: theme.palette.background.default,
  },

  [`& .${classes.rightBorder}`]: {
    borderRight: `0.5px solid ${theme.palette.text.disabled}`,
  },

  [`& .${classes.documentUploadSection}`]: {
    padding: "32px",
  },

  [`& .${classes.circularProgressWrapper}`]: {
    width: 60,
    height: 60,
    padding: 0,
    margin: 0,
  },

  [`& .${classes.supportingDocsTypography}`]: {
    paddingBottom: "32px",
  },

  [`& .${classes.mainHeading}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.heading}`]: {
    flexBasis: "50%",
    flexShrink: 0,
  },

  [`& .${classes.subHeading}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2,auto)",
    gridColumnGap: theme.spacing(0.5),
  },

  [`& .${classes.successColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.warningColor}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.outStandingChipColor}`]: {
    backgroundColor: theme.palette.info.main,
    fontWeight: 400,
  },

  [`& .${classes.verifiedChipColor}`]: {
    backgroundColor: theme.palette.success.main,
    fontWeight: 400,
  },

  [`& .${classes.inProgressChipColor}`]: {
    backgroundColor: theme.palette.info.main,
    fontWeight: 400,
  },

  [`& .${classes.kycCaptionWrapper}`]: {
    display: "grid",
    gridRowGap: theme.spacing(2),
    gridTemplateRows: "repeat(3,auto)",
    padding: theme.spacing(3),
  },

  [`& .${classes.kycStatusControls}`]: {
    display: "flex",
  },

  [`& .${classes.kycTextArea}`]: {
    color: theme.palette.text.primary,
    fontSize: 16,
    backgroundColor: theme.palette.background.default,
    width: "100%",
  },

  [`& .${classes.updateCDDContainer}`]: {
    padding: theme.spacing(2),
  },
}));

interface KYCDialogProps {
  open: boolean;
  closeDialog: () => void;
  clientID: string;
  refresh: () => void;
}

enum stepState {
  Completed = "Completed",
  NotCompleted = "Not Completed",
}

export function IndividualClientDetailsDialog(props: KYCDialogProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const [personInfoCompletionState, setPersonalInfoCompletionState] = useState(
    stepState.NotCompleted,
  );
  const [
    residentialAddressCompletionState,
    setResidentialAddressCompletionState,
  ] = useState(stepState.NotCompleted);
  const [validationState] = useState<{ [key: string]: string | undefined }>({});
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [identificationClassDocument, setIdentificationClassDocument] =
    useState<KYCDocument[]>([]);
  const [proofOfResidenceClassDocument, setProofOfResidenceClassDocument] =
    useState<KYCDocument[]>([]);
  const [personEntity, setPersonEntity] = useState(new Person());
  const [selectedKYCStatus, setSelectedKYCStatus] = useState(
    ClientKYCStatus.Outstanding,
  );
  const [client, setClient] = useState<Client>(new Client());
  const [textAreaText, setTextAreaText] = useState(client.kycStatusReason);
  const [clientShortName, setClientShortName] = useState(client.shortName);
  const [sumsubApplicantId, setSumsubApplicantId] = useState<
    string | undefined
  >(undefined);

  const getApplicant = useCallback(async () => {
    setLoading(true);
    if (client.id !== "") {
      const res = await ApplicantInspector.RetrieveApplicant({
        context: authContext,
        clientID: client.id,
      });

      setSumsubApplicantId(res.id);
    }
    setLoading(false);
  }, [client]);

  useEffect(() => {
    getApplicant();
  }, [getApplicant]);

  const handleRefreshClientKYCDocuments = useCallback(async () => {
    try {
      setLoading(true);
      const clientKYCDocumentsResponse =
        await ClientDetailsInspector.GetClientKYCDocuments({
          context: authContext,
          clientID: props.clientID,
        });

      const IDClassDocs = clientKYCDocumentsResponse.documents.filter(
        (value) =>
          value.classification ===
          KYCDocumentClassification.IdentificationDocument,
      );
      setIdentificationClassDocument(IDClassDocs);

      const addressClassDocs = clientKYCDocumentsResponse.documents.filter(
        (value) =>
          value.classification === KYCDocumentClassification.ProofOfResidence,
      );

      setProofOfResidenceClassDocument(addressClassDocs);
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `could not retrieve client: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `could not retrieve client: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setLoading(false);
  }, [authContext, enqueueSnackbar, props.clientID]);

  const handleRefreshPersonEntity = useCallback(async () => {
    try {
      setLoading(true);
      const getIndividualClientPersonalInfoResponse =
        await ClientDetailsInspector.GetIndividualClientPersonalInfo({
          context: authContext,
          clientID: props.clientID,
        });
      setPersonEntity(
        new Person(getIndividualClientPersonalInfoResponse.person),
      );
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `unable to retrieve person:  ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `unable to retrieve person:  ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setLoading(false);
  }, [authContext, enqueueSnackbar, props.clientID]);

  const handleRetrieveClient = useCallback(async () => {
    try {
      setLoading(true);
      const retrieveClientResponse = await ClientRepository.RetrieveClient({
        context: authContext,
        identifier: IDIdentifier(props.clientID),
      });
      setClient(retrieveClientResponse.client);
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error retrieving client: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar("Error retrieving client", { variant: "error" });
    }
    setLoading(false);
  }, [authContext, props.clientID, enqueueSnackbar]);

  useLayoutEffect(() => {
    (async () => {
      await handleRetrieveClient();
      await handleRefreshPersonEntity();
      await handleRefreshClientKYCDocuments();
    })();
  }, [
    handleRefreshClientKYCDocuments,
    handleRefreshPersonEntity,
    handleRetrieveClient,
  ]);

  useLayoutEffect(() => {
    setTextAreaText(client.kycStatusReason);
    setClientShortName(client.shortName);
  }, [client.kycStatusReason, client.shortName]);

  useEffect(() => {
    AllStepsCompletedCheck();
  });

  const AllStepsCompletedCheck = () => {
    completedPersonalDetailsStep();
    completedResidentialAddressStep();
  };

  const completedPersonalDetailsStep = () => {
    if (!personEntity.firstName) {
      setPersonalInfoCompletionState(stepState.NotCompleted);
      return false;
    }

    if (!personEntity.lastName) {
      setPersonalInfoCompletionState(stepState.NotCompleted);
      return false;
    }

    if (!personEntity.identificationNumber) {
      setPersonalInfoCompletionState(stepState.NotCompleted);
      return false;
    }
    if (
      personEntity.identificationType ===
        IdentificationType.SouthAfricanIDIdentificationType &&
      personEntity.identificationNumber.length !== 13
    ) {
      setPersonalInfoCompletionState(stepState.NotCompleted);
      return false;
    }
    if (
      personEntity.identificationType ===
        IdentificationType.SouthAfricanIDIdentificationType &&
      !LuhnAlgorithm(personEntity.identificationNumber)
    ) {
      setPersonalInfoCompletionState(stepState.NotCompleted);
      return false;
    }

    // find identification document classification
    if (identificationClassDocument.length === 0) {
      setPersonalInfoCompletionState(stepState.NotCompleted);
      return false;
    }

    if (identificationClassDocument.length === 1) {
      if (
        identificationClassDocument[0].type ===
          KYCDocumentType.SouthAfricanIDCardFrontType ||
        identificationClassDocument[0].type ===
          KYCDocumentType.SouthAfricanIDCardBackType
      ) {
        setPersonalInfoCompletionState(stepState.NotCompleted);
        return false;
      }
    }

    setPersonalInfoCompletionState(stepState.Completed);
    return true;
  };

  const completedResidentialAddressStep = () => {
    if (!personEntity.residentialAddress.countryCode) {
      setResidentialAddressCompletionState(stepState.NotCompleted);
      return false;
    }

    if (!personEntity.residentialAddress.addressLine1) {
      setResidentialAddressCompletionState(stepState.NotCompleted);
      return false;
    }

    if (!personEntity.residentialAddress.city) {
      setResidentialAddressCompletionState(stepState.NotCompleted);
      return false;
    }

    if (!personEntity.residentialAddress.province) {
      setResidentialAddressCompletionState(stepState.NotCompleted);
      return false;
    }

    if (!personEntity.residentialAddress.postalCode) {
      setResidentialAddressCompletionState(stepState.NotCompleted);
      return false;
    }

    if (proofOfResidenceClassDocument.length === 0) {
      setResidentialAddressCompletionState(stepState.NotCompleted);
      return false;
    }

    setResidentialAddressCompletionState(stepState.Completed);
    return true;
  };

  const handleSetClientKYCStatus = async () => {
    setLoading(true);
    switch (selectedKYCStatus) {
      case ClientKYCStatus.VerifiedStatus:
        try {
          await IndividualClientKYCStateController.SetKYCStatusVerified({
            context: authContext,
            clientID: client.id,
          });
          enqueueSnackbar("Client verification status set to verified", {
            variant: "success",
          });
          await handleRetrieveClient();
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(`error setting kyc status to verified`, e);
          enqueueSnackbar(
            `error setting client verification status to verified: ${err.message}`,
            { variant: "error" },
          );
        }
        break;

      case ClientKYCStatus.Outstanding:
        try {
          await IndividualClientKYCStateController.SetKYCStatusOutstanding({
            context: authContext,
            clientID: client.id,
            reason: textAreaText,
          });
          enqueueSnackbar("Client verification status set to outstanding", {
            variant: "success",
          });
          await handleRetrieveClient();
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error setting client verification status to outstanding: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar(
            `error setting client verification status to outstanding: ${
              err.message ? err.message : err.toString()
            }`,
            { variant: "error" },
          );
        }
    }
    setLoading(false);
  };

  function UpdateCDD() {
    return (
      <Grid
        container
        className={classes.updateCDDContainer}
        direction="row"
        spacing={2}
      >
        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <Autocomplete
                id="kycDialog-kycStatus-clientKYCStatus"
                options={[
                  ClientKYCStatus.Outstanding,
                  ClientKYCStatus.VerifiedStatus,
                ]}
                value={selectedKYCStatus}
                disabled={
                  loading || client.kycStatus === ClientKYCStatus.VerifiedStatus
                }
                isOptionEqualToValue={(option, value) => {
                  if ((value as string) === "") {
                    return true;
                  }
                  return option === (value as string);
                }}
                onChange={(__: object, value) => {
                  setSelectedKYCStatus(
                    value === null || value === undefined
                      ? ClientKYCStatus.Outstanding
                      : value,
                  );
                }}
                popupIcon={
                  <ArrowDropDownIcon id="kycDialog-kycStatus-clientKYCStatus_dropdown_icon" />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Verification Status"
                    variant="outlined"
                    value={selectedKYCStatus}
                    disabled={
                      loading ||
                      client.kycStatus === ClientKYCStatus.VerifiedStatus
                    }
                    onChange={(e) => setClientShortName(e.target.value)}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {selectedKYCStatus === ClientKYCStatus.Outstanding && (
                <TextareaAutosize
                  id="kycDialog-kycReason-textAreaAutosize"
                  className={classes.kycTextArea}
                  maxRows={7}
                  disabled={
                    loading ||
                    client.kycStatus === ClientKYCStatus.VerifiedStatus
                  }
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                  aria-label="maximum height"
                  placeholder="Outstanding Verification status reasons..."
                  value={textAreaText}
                  onChange={(e) => setTextAreaText(e.target.value)}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={(theme) => ({
                  padding: theme.spacing(2, 0, 2, 0),
                })}
              >
                Please note that in order to set the client verification status
                to Outstanding, you must provide a reason. This reason will be
                sent to the client via email to prompt them to correct the
                issue.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <Button
                  disabled={
                    loading ||
                    client.kycStatus === ClientKYCStatus.VerifiedStatus
                  }
                  id="kycDialog-updateStatus-button"
                  variant="outlined"
                  children="update status"
                  onClick={handleSetClientKYCStatus}
                />
                <Button
                  disabled={loading || sumsubApplicantId === undefined}
                  id="kycDialog-updateStatus-button"
                  variant="outlined"
                  children="summary report"
                  href={`https://cockpit.sumsub.com/checkus#/applicants/${sumsubApplicantId}/applicantReport?clientId=meshtrade.co_65855`}
                  target="_blank"
                  endIcon={<OpenInNewIcon />}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <TextField
                id="kycDialog-caption-shortName"
                label="Client Short name"
                variant="outlined"
                fullWidth
                value={clientShortName}
                onChange={(e) => setClientShortName(e.target.value)}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Button
                    id="kycDialog-caption-button"
                    variant="outlined"
                    children="generate shortname"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    id="kycDialog-caption-button"
                    variant="outlined"
                    children="update shortname"
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <StyledDialog open={props.open} fullScreen>
      <DialogTitle className={classes.dialogTitle}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item className={classes.mainHeading}>
            <div className={classes.miniLogoWrapper}>
              <img alt="" width="100%" src={meshMiniLogo} />
            </div>
            <Typography variant="h5" children="Identity Verification" />
          </Grid>
          <Grid item>
            <Chip
              size="small"
              className={cx(
                {
                  [classes.inProgressChipColor]:
                    client.kycStatus ===
                    ClientKYCStatus.VerificationInProgressStatus,
                },
                {
                  [classes.verifiedChipColor]:
                    client.kycStatus === ClientKYCStatus.VerifiedStatus,
                },
                {
                  [classes.outStandingChipColor]:
                    client.kycStatus === ClientKYCStatus.Outstanding,
                },
              )}
              label={
                ClientKYCStatus.VerificationInProgressStatus ===
                client.kycStatus
                  ? "Under review"
                  : ClientKYCStatus.VerifiedStatus === client.kycStatus
                    ? "Completed"
                    : ClientKYCStatus.Outstanding === client.kycStatus &&
                      "Outstanding"
              }
            />
          </Grid>
          {loading && (
            <Grid item>
              <CircularProgress size={30} />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Tooltip title="Close" placement="top">
              <span>
                <IconButton
                  id="KYCDialog-close-button"
                  size="small"
                  onClick={() => {
                    props.refresh();
                    props.closeDialog();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={cx(classes.dialogContent, "meshScroll")}>
        <div className={classes.rightBorder}>
          {UpdateCDD()}
          <Accordion>
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                Personal Details
              </Typography>
              <div className={classes.subHeading}>
                <Typography color="textSecondary">Step 1</Typography>
                <Typography
                  className={cx(
                    {
                      [classes.successColor]:
                        personInfoCompletionState === stepState.Completed,
                    },
                    {
                      [classes.warningColor]:
                        personInfoCompletionState === stepState.NotCompleted,
                    },
                  )}
                >
                  {personInfoCompletionState}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateRows: {
                    md: "repeat(4, auto)",
                    xs: "repeat(5, auto)",
                  },
                  gridAutoFlow: "column",
                  gap: 2,
                }}
              >
                <TextField
                  id="kycDialog-firstName-textField"
                  fullWidth
                  label="First Name"
                  readOnly
                  variant="outlined"
                  disabled
                  value={personEntity.firstName}
                  error={!!validationState.firstName}
                  helperText={validationState.firstName}
                />
                <TextField
                  id="kycDialog-middleName-textfield"
                  fullWidth
                  label="Middle Name(s)"
                  disabled
                  readOnly
                  variant="outlined"
                  value={personEntity.middleNames}
                />
                <TextField
                  id="kycDialog-lastName-textField"
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  value={personEntity.lastName}
                  disabled
                  readOnly
                  error={!!validationState.lastName}
                  helperText={validationState.lastName}
                />
                <DateField
                  id="kycDialog-dateOfBirth-dateOfBirth"
                  label="Date of Birth"
                  value={personEntity.dateOfBirth}
                  disabled
                  readOnly
                  onChange={() => undefined}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="kycDialog-dateOfBirth-dateOfBirth"
                      fullWidth
                      readOnly
                    />
                  )}
                />
                <Autocomplete<"" | SourceOfIncomeAndWealthType>
                  id="kycDialog-sourceOfIncome-autocomplete"
                  options={ValidSourceOfIncomeTypes}
                  value={personEntity.sourceOfIncome}
                  disabled
                  isOptionEqualToValue={(option, value) => {
                    if ((value as string) === "") {
                      return true;
                    }
                    return option === (value as string);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Source of Income"
                      variant="outlined"
                      readOnly
                      disabled
                      fullWidth
                    />
                  )}
                />
                <Autocomplete<"" | SourceOfIncomeAndWealthType>
                  id="kycDialog-sourceOfWealth-autocomplete"
                  options={ValidSourceOfWealthTypes}
                  value={personEntity.sourceOfWealth}
                  disabled
                  isOptionEqualToValue={(option, value) => {
                    if ((value as string) === "") {
                      return true;
                    }
                    return option === (value as string);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Source of Wealth"
                      variant="outlined"
                      readOnly
                      disabled
                      fullWidth
                    />
                  )}
                />
                <Autocomplete<"" | IdentificationType>
                  id="kycDialog-identificationType-autocomplete"
                  options={[
                    IdentificationType.SouthAfricanIDIdentificationType,
                    IdentificationType.PassportIdentificationType,
                  ]}
                  value={personEntity.identificationType}
                  disabled
                  isOptionEqualToValue={(option, value) => {
                    if ((value as string) === "") {
                      return true;
                    }
                    return option === (value as string);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Identification Type"
                      variant="outlined"
                      readOnly
                      disabled
                      fullWidth
                    />
                  )}
                />
                <Autocomplete
                  id="kycDialog-nationality-autocomplete"
                  options={countries}
                  getOptionLabel={(option) => option.label}
                  disabled
                  isOptionEqualToValue={(option, value) => {
                    if ((value.value as string) === "") {
                      return true;
                    }
                    return option.value === (value.value as string);
                  }}
                  value={
                    personEntity.nationality === ""
                      ? {
                          value: "",
                          label: "",
                        }
                      : {
                          value: personEntity.nationality,
                          label: countries.filter(
                            (c) => c.value === personEntity.nationality,
                          )[0].label,
                        }
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      disabled
                      readOnly
                      label="Nationality"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <TextField
                  id="kycDialog-identificationNumber-textfield"
                  fullWidth
                  label="Identification Number"
                  variant="outlined"
                  value={personEntity.identificationNumber}
                  disabled
                  readOnly
                  error={!!validationState.identificationNumber}
                  helperText={validationState.identificationNumber}
                />
                {personEntity.identificationType ===
                  IdentificationType.PassportIdentificationType && (
                  <DateField
                    id="kycDialog-passportExpiry-dateField"
                    label="Passport Expiry Date"
                    value={personEntity.identificationNumberExpiry}
                    disabled
                    readOnly
                    onChange={() => undefined}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="kycDialog-identificationNumberExpiry-DateField"
                        fullWidth
                        readOnly
                      />
                    )}
                  />
                )}
                <TextField
                  id="kycDialog-pepDeclarationStatus-textField"
                  label="PEP Declaration Status"
                  disabled
                  fullWidth
                  readOnly
                  value={personEntity.influentialPerson}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                Residential Address
              </Typography>
              <div className={classes.subHeading}>
                <Typography color="textSecondary">Step 2</Typography>
                <Typography
                  className={cx(
                    {
                      [classes.successColor]:
                        residentialAddressCompletionState ===
                        stepState.Completed,
                    },
                    {
                      [classes.warningColor]:
                        residentialAddressCompletionState ===
                        stepState.NotCompleted,
                    },
                  )}
                >
                  {residentialAddressCompletionState}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="kycDialog-addressLine1-textfield"
                    label="Address 1"
                    variant="outlined"
                    value={personEntity.residentialAddress.addressLine1}
                    disabled
                    readOnly
                    error={!!validationState.addressLine1}
                    helperText={validationState.addressLine1}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="kycDialog-city-textfield"
                    fullWidth
                    label="City"
                    variant="outlined"
                    value={personEntity.residentialAddress.city}
                    disabled
                    readOnly
                    error={!!validationState.city}
                    helperText={validationState.city}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="kycDialog-addressline2-textfield"
                    fullWidth
                    label="Address 2"
                    variant="outlined"
                    value={personEntity.residentialAddress.addressLine2}
                    disabled
                    readOnly
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="kycDialog-province-textfield"
                    fullWidth
                    label="Province"
                    variant="outlined"
                    value={personEntity.residentialAddress.province}
                    error={!!validationState.province}
                    disabled
                    readOnly
                    helperText={validationState.province}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="kycDialog-suburbs-textfield"
                    fullWidth
                    label="Suburb"
                    variant="outlined"
                    disabled
                    readOnly
                    value={personEntity.residentialAddress.suburb}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="kycDialog-postalCode-textfield"
                    fullWidth
                    label="Postal Code"
                    variant="outlined"
                    value={personEntity.residentialAddress.postalCode}
                    error={!!validationState.postalCode}
                    disabled
                    readOnly
                    helperText={validationState.province}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="kycDialog-country-autocomplete"
                    options={countries}
                    getOptionLabel={(option) => option.label}
                    disabled
                    isOptionEqualToValue={(option, value) => {
                      if ((value.value as string) === "") {
                        return true;
                      }
                      return option.value === (value.value as string);
                    }}
                    value={
                      personEntity.residentialAddress.countryCode === ""
                        ? {
                            value: "",
                            label: "",
                          }
                        : {
                            value: personEntity.residentialAddress.countryCode,
                            label: countries.filter(
                              (c) =>
                                c.value ===
                                personEntity.residentialAddress.countryCode,
                            )[0].label,
                          }
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        label="Country"
                        id="kycDialog-country-textfield"
                        variant="outlined"
                        disabled
                        readOnly
                        error={!!validationState.country}
                        helperText={validationState.country}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className={classes.documentUploadSection}>
          <Typography
            className={classes.supportingDocsTypography}
            children="Contact Information"
            variant="h5"
          />
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="kycDialog-emailAddress-textfield"
              label="Email Address"
              variant="outlined"
              value={personEntity.contactDetails.emailAddress}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Contact Number"
              id="kycDialog-cellphoneNumber-textfield"
              sx={(theme) => ({
                marginBottom: theme.spacing(3),
              })}
              variant="outlined"
              value={personEntity.contactDetails.cellphoneNumber}
              disabled
            />
          </Grid>
          <Typography
            className={classes.supportingDocsTypography}
            children="Supporting Documents/ Media"
            variant="h5"
          />
          <IdentityDocumentsDownloader
            verificationInProgress={
              client.kycStatus === ClientKYCStatus.VerificationInProgressStatus
            }
            header={KYCDocumentClassification.IdentificationDocument}
            clientID={client.id}
            kycDocument={identificationClassDocument}
            documentClassification={
              KYCDocumentClassification.IdentificationDocument
            }
            documentTypes={getDocTypesForDocumentClass(
              KYCDocumentClassification.IdentificationDocument,
            )}
            stepsCompletedCheck={AllStepsCompletedCheck}
            refreshClientKYCDocuments={handleRefreshClientKYCDocuments}
          />
          <ResidentialAddressDocumentUploader
            verificationInProgress={
              client.kycStatus === ClientKYCStatus.VerificationInProgressStatus
            }
            header={KYCDocumentClassification.ProofOfResidence}
            clientID={client.id}
            kycDocumentRefs={proofOfResidenceClassDocument}
            documentClassification={KYCDocumentClassification.ProofOfResidence}
            documentTypes={getDocTypesForDocumentClass(
              KYCDocumentClassification.ProofOfResidence,
            )}
            stepsCompletedCheck={AllStepsCompletedCheck}
            refreshClientKYCDocuments={handleRefreshClientKYCDocuments}
          />
        </div>
      </DialogContent>
    </StyledDialog>
  );
}

interface DocumentUploaderProps {
  clientID: string;
  header: string;
  kycDocument: KYCDocument;
  documentClassification: KYCDocumentClassification;
  documentTypes: KYCDocumentType[];
  verificationInProgress: boolean;
  stepsCompletedCheck: () => void;
  refreshClientKYCDocuments: () => void;
  hideBorder: boolean;
}

export function DocumentDownloader(props: DocumentUploaderProps) {
  const [errorMessage] = useState<string | undefined>(undefined);

  const handleDownloadDocument = () => {
    download(props.kycDocument.downloadURL, props.kycDocument.name);
  };

  return (
    <DocumentUploaderRoot
      className={cx(
        documentUploaderClasses.root,
        { [documentUploaderClasses.bottomBorder]: !props.hideBorder },
        { [documentUploaderClasses.marginBottom]: !props.hideBorder },
      )}
    >
      {!!props.header && (
        <Typography
          variant="h6"
          children={props.header}
          className={documentUploaderClasses.header}
        />
      )}
      <div className={documentUploaderClasses.documentTextFieldLine}>
        <Autocomplete<"" | KYCDocumentType>
          id={`kycDialog-${props.documentClassification}documentType-autocomplete`}
          options={props.documentTypes}
          value={props.kycDocument.type}
          disabled={!!props.kycDocument.name || props.verificationInProgress}
          isOptionEqualToValue={(option, value) =>
            value === props.kycDocument.type
          }
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={
                !!props.kycDocument.name || props.verificationInProgress
              }
              label="Document Type"
              variant="outlined"
              error={!!errorMessage}
              helperText={errorMessage}
            />
          )}
        />
        <Button
          id="kycDialog-documentDownload-button"
          className={documentUploaderClasses.downloadButton}
          variant="outlined"
          disabled={!props.kycDocument.type}
          startIcon={<DownloadIcon className={documentUploaderClasses.icon} />}
          onClick={handleDownloadDocument}
        />
      </div>
      {props.kycDocument.state === KYCDocumentState.Uploaded && (
        <div className={documentUploaderClasses.documentNameLine}>
          <Typography
            variant="body2"
            color="secondary"
            children={props.kycDocument.name}
          />
        </div>
      )}
    </DocumentUploaderRoot>
  );
}

interface IdentityDocumentsDownloaderProps {
  clientID: string;
  header: string;
  kycDocument: KYCDocument[];
  documentClassification: KYCDocumentClassification;
  documentTypes: KYCDocumentType[];
  verificationInProgress: boolean;
  stepsCompletedCheck: () => void;
  refreshClientKYCDocuments: () => void;
}

export function IdentityDocumentsDownloader(
  props: IdentityDocumentsDownloaderProps,
) {
  switch (props.kycDocument.length) {
    case 0:
      // indeterminate state no document was found
      return <></>;

    case 1:
      // determine if we have id card back or front document ref if so render the corresponding document uploader
      // if it is front and back document type of passport render document uploader for them and hidden one
      return (
        <DocumentDownloader
          clientID={props.clientID}
          header={props.header}
          kycDocument={props.kycDocument[0]}
          documentClassification={props.documentClassification}
          documentTypes={props.documentTypes}
          verificationInProgress={props.verificationInProgress}
          stepsCompletedCheck={props.stepsCompletedCheck}
          refreshClientKYCDocuments={props.refreshClientKYCDocuments}
          hideBorder={false}
        />
      );

    case 2:
      // the only possibilities here are the ID card back
      return (
        <>
          <DocumentDownloader
            clientID={props.clientID}
            header={props.header}
            kycDocument={props.kycDocument[0]}
            documentClassification={props.documentClassification}
            documentTypes={props.documentTypes}
            verificationInProgress={props.verificationInProgress}
            stepsCompletedCheck={props.stepsCompletedCheck}
            refreshClientKYCDocuments={props.refreshClientKYCDocuments}
            hideBorder
          />
          <DocumentDownloader
            clientID={props.clientID}
            header=""
            kycDocument={props.kycDocument[1]}
            documentClassification={props.documentClassification}
            documentTypes={props.documentTypes}
            verificationInProgress={props.verificationInProgress}
            stepsCompletedCheck={props.stepsCompletedCheck}
            refreshClientKYCDocuments={props.refreshClientKYCDocuments}
            hideBorder={false}
          />
        </>
      );
    default:
      // indeterminate state more than 2 document refs was uploaded
      return <></>;
  }
}

interface ResidentialAddressDocumentUploaderProps {
  clientID: string;
  header: string;
  kycDocumentRefs: KYCDocument[];
  documentClassification: KYCDocumentClassification;
  documentTypes: KYCDocumentType[];
  verificationInProgress: boolean;
  stepsCompletedCheck: () => void;
  refreshClientKYCDocuments: () => void;
}

export function ResidentialAddressDocumentUploader(
  props: ResidentialAddressDocumentUploaderProps,
) {
  switch (props.kycDocumentRefs.length) {
    case 0:
      // indeterminate state no document was found
      return <></>;

    case 1:
      return (
        <DocumentDownloader
          clientID={props.clientID}
          header={props.header}
          kycDocument={props.kycDocumentRefs[0]}
          documentClassification={props.documentClassification}
          documentTypes={props.documentTypes}
          verificationInProgress={props.verificationInProgress}
          stepsCompletedCheck={props.stepsCompletedCheck}
          refreshClientKYCDocuments={props.refreshClientKYCDocuments}
          hideBorder
        />
      );

    default:
      // indeterminate state more than one document was found
      return <></>;
  }
}
