export enum CouponFrequency {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  SemiAnnually = "Semi-Annually",
  Annually = "Annually",
}

export const allCouponFrequencies: CouponFrequency[] = [
  CouponFrequency.Monthly,
  CouponFrequency.Quarterly,
  CouponFrequency.SemiAnnually,
  CouponFrequency.Annually,
];

export const couponFrequencyMap = (
  frequency: string | number | CouponFrequency,
) => {
  switch (frequency) {
    case "Monthly":
    case 1:
      return CouponFrequency.Monthly;
    case "Quarterly":
    case 2:
      return CouponFrequency.Quarterly;
    case "Semi-Annually":
    case 3:
      return CouponFrequency.SemiAnnually;
    case "Annually":
    case 4:
      return CouponFrequency.Annually;
    default:
      return CouponFrequency.Monthly;
  }
};
