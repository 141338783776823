import { Amount } from "james/ledger";
import { AuditEntry } from "../audit";

export const FundingReceiptTypeName = "mesh::banking/FundReceipt";

export class FundingReceipt {
  public ["@type"]: string = FundingReceiptTypeName;

  public id = "";

  public accountNumber = "";

  public currencyStablecoinID = "";

  public amount: Amount = new Amount();

  public bankReference = "";

  public bankName = "";

  public auditEntry: AuditEntry = new AuditEntry();

  constructor(fundingReceipt?: FundingReceipt) {
    if (!fundingReceipt) {
      return;
    }

    this.id = fundingReceipt.id;
    this.amount = new Amount(fundingReceipt.amount);
    this.bankReference = fundingReceipt.bankReference;
    this.bankName = fundingReceipt.bankName;
    this.accountNumber = fundingReceipt.accountNumber;
    this.currencyStablecoinID = fundingReceipt.currencyStablecoinID;
    this.auditEntry = new AuditEntry(fundingReceipt.auditEntry);
  }
}
