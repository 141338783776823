/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ArrowBack as BackArrowIcon,
  Close as CloseIcon,
  FileCopy as CopyPasteIcon,
  Notes as NoteIcon,
  Refresh as ReloadIcon,
  Storage as PayloadIcon,
} from "@mui/icons-material";
import dayjs from "dayjs";
import {
  AfterEffectRunnerAsync,
  AfterEffectStateController,
  ConsistencyTransaction,
  ConsistencyTransactionRepository,
  TransactionRunnerAsync,
  WriteOperationStateController,
} from "consistency/consistency";
import { IDIdentifier } from "james/search/identifier";
import { FETable } from "components/Table/FETable";
import { Operation } from "consistency/consistency/Operation";
import {
  WriteOperation,
  WriteOperationState,
  WriteOperationTypeName,
} from "consistency/consistency/WriteOperation";
import { ReadOperation } from "consistency/consistency/ReadOperation";
import { TransactionState } from "consistency/consistency/Transaction";
import MultiRowInfoIcon from "views/Consistency/Dashboard/MultiRowInfoIcon";
import cx from "classnames";
import { getRandomColor } from "utilities/color";
import { Column } from "components/Table/BPTable/BPTable";
import {
  AfterEffect,
  AfterEffectState,
} from "consistency/consistency/AfterEffect";
import ReactJson from "react-json-view";
import { Popover } from "components/PopOver/Popover";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

const PREFIX = "View";

const classes = {
  viewCardLayout: `${PREFIX}-viewCardLayout`,
  breadCrumbLayout: `${PREFIX}-breadCrumbLayout`,
  clickableBreadcrumb: `${PREFIX}-clickableBreadcrumb`,
  nonClickableBreadcrumb: `${PREFIX}-nonClickableBreadcrumb`,
  headerCardContent: `${PREFIX}-headerCardContent`,
  clickableText: `${PREFIX}-clickableText`,
  copyPasteIcon: `${PREFIX}-copyPasteIcon`,
  textFieldLabel: `${PREFIX}-textFieldLabel`,
  writeOperationsStateTextFieldLabel: `${PREFIX}-writeOperationsStateTextFieldLabel`,
  row: `${PREFIX}-row`,
  actionButtonsCell: `${PREFIX}-actionButtonsCell`,
  conflictSignaturesList: `${PREFIX}-conflictSignaturesList`,
  lastActionAnnotationDialogTitleRootOverride: `${PREFIX}-lastActionAnnotationDialogTitleRootOverride`,
  dialogContentRootOverride: `${PREFIX}-dialogContentRootOverride`,
  textArea: `${PREFIX}-textArea`,
  textAreaError: `${PREFIX}-textAreaError`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.viewCardLayout}`]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },

  //
  // bread crumbs
  //
  [`& .${classes.breadCrumbLayout}`]: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(-1),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.clickableBreadcrumb}`]: {
    cursor: "pointer",
    color: theme.palette.text.hint,
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
    marginRight: theme.spacing(0.5),
  },

  [`& .${classes.nonClickableBreadcrumb}`]: {
    marginRight: theme.spacing(0.5),
  },

  //
  // header card
  //
  [`& .${classes.headerCardContent}`]: {
    padding: theme.spacing(1),
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "1fr auto",
    gridColumnGap: theme.spacing(1),
  },

  // other
  [`& .${classes.clickableText}`]: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.light,
    },
    cursor: "pointer",
  },

  [`& .${classes.copyPasteIcon}`]: {
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
    cursor: "pointer",
  },

  [`& .${classes.textFieldLabel}`]: {
    margin: 0,
  },

  [`& .${classes.writeOperationsStateTextFieldLabel}`]: {
    width: 300,
    margin: 0,
  },

  [`& .${classes.row}`]: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(0.5),
  },

  [`& .${classes.actionButtonsCell}`]: {
    padding: theme.spacing(0.5),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },

  [`& .${classes.conflictSignaturesList}`]: {
    maxWidth: 350,
    maxHeight: 55,
    overflow: "auto",
  },

  //
  // last action annotation dialog
  //
  [`& .${classes.lastActionAnnotationDialogTitleRootOverride}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
      1,
    )} ${theme.spacing(2)}`,
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.dialogContentRootOverride}`]: {
    width: 540,
  },

  [`& .${classes.textArea}`]: {
    color: theme.palette.text.primary,
    fontSize: 16,
    backgroundColor: theme.palette.background.paper,
    width: 508,
    maxWidth: 508,
  },

  [`& .${classes.textAreaError}`]: {
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

const idealColors = ["#05a906", "#FFBB28", "#b384e7"];

const jsonViewBackgroundColor = "#1d1f21";

const operationsOnlyOpsTableHeight = window.innerHeight - 270;
const opsTableHeight = window.innerHeight - 505;
const afterEffectTableHeight = 220;

export function View() {
  const { errorContextErrorTranslator } = useErrorContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const { viewConfiguration, authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [transaction, setTransaction] = useState<
    ConsistencyTransaction | undefined
  >(undefined);
  const [reload, setReload] = useState(false);
  const [apiLoading, setAPILoading] = useState<boolean>(false);
  const transactionsViewConfiguration = viewConfiguration.Consistency
    ? viewConfiguration.Consistency
      ? viewConfiguration.Consistency.Transactions
      : {}
    : {};

  const usedColors = useRef<{ [key: string]: string }>({});

  const getRandomColorForKey = (key: string) => {
    // if a color is already stored for this key, use it
    if (usedColors.current[key]) {
      return usedColors.current[key];
    }

    // otherwise check if any of the ideal colors could be used
    for (const c of idealColors) {
      if (!Object.values(usedColors.current).includes(c)) {
        usedColors.current[key] = c;
        return usedColors.current[key];
      }
    }

    // otherwise get a new random color
    usedColors.current[key] = getRandomColor([
      ...idealColors,
      ...Object.values(usedColors.current),
    ]);
    return usedColors.current[key];
  };

  // navigate to table if no transaction id query param has been set
  useEffect(() => {
    // try and get a transaction id from url
    const transactionIDFromURL = searchParams.get("id");
    if (!transactionIDFromURL) {
      navigate("/consistency/transactions/table");
      return;
    }
  }, [searchParams.get("id")]);

  useLayoutEffect(() => {
    (async () => {
      // try and get a transaction id from url
      const transactionIDFromURL = searchParams.get("id");
      if (!transactionIDFromURL) {
        return;
      }

      // do not perform load if
      if (
        transaction &&
        // transaction ID already set correctly
        transactionIDFromURL === transaction.id && // AND
        // reload not requested
        !reload
      ) {
        return;
      }
      // otherwise clear reload
      setReload(false);

      // load necessary data
      setAPILoading(true);
      try {
        const txn = (
          await ConsistencyTransactionRepository.RetrieveTransaction({
            context: authContext,
            identifier: IDIdentifier(transactionIDFromURL),
          })
        ).transaction;
        setTransaction(txn);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving transaction: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setAPILoading(false);
    })();
  }, [authContext, history, reload, transaction]);

  // get write operations sate
  const [writeOperationsState, writeOperationsStateLastModified] = useMemo(
    () =>
      transaction
        ? transaction.writeOperationsState()
        : [WriteOperationState.Complete, dayjs()],
    [transaction],
  );

  // Transaction run forward and backward
  const handleRunTransactionForward = useCallback(async () => {
    if (!transaction) {
      return;
    }
    setAPILoading(true);
    try {
      await TransactionRunnerAsync.RunTransactionIDForwardAsync({
        context: authContext,
        transactionID: transaction.id,
      });
      enqueueSnackbar(
        "Transaction Run Forward Asynchronously - refresh this page or check commit phase for progress",
        { variant: "success" },
      );
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error running transaction forward async: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `error running transaction forward async: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setAPILoading(false);
  }, [transaction, authContext, enqueueSnackbar]);
  const handleRunTransactionBackward = useCallback(async () => {
    if (!transaction) {
      return;
    }
    setAPILoading(true);
    try {
      await TransactionRunnerAsync.RunTransactionIDBackwardAsync({
        context: authContext,
        transactionID: transaction.id,
      });
      enqueueSnackbar(
        "Transaction Run Backward Asynchronously - refresh this page or check commit phase for progress",
        { variant: "success" },
      );
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error running transaction backward async: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `error running transaction backward async: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setAPILoading(false);
  }, [transaction, authContext, enqueueSnackbar]);

  // common to write operation and after effect manual actions
  const [lastActionAnnotation, setLastActionAnnotation] = useState("");
  const [annotationBlankError, setAnnotationBlankError] = useState(false);

  // write operation actions
  const [nextWriteOperationState, setNextWriteOperationState] = useState<
    WriteOperationState | undefined
  >(undefined);
  const [writeOperationToChangeID, setWriteOperationToChangeID] = useState("");
  const handleUNSAFESetWriteOperationIDState = useCallback(
    async (
      writeOperationID: string,
      state: WriteOperationState,
      actionAnnotation: string,
    ) => {
      setAPILoading(true);
      try {
        await WriteOperationStateController.UNSAFESetWriteOperationIDState({
          context: authContext,
          writeOperationID,
          state,
          lastActionAnnotation: actionAnnotation,
        });
        enqueueSnackbar("Write Operation State Changed", {
          variant: "success",
        });
        setReload((prev) => !prev);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error setting write operation state: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `error setting write operation state: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }
      setAPILoading(false);
    },
    [authContext, enqueueSnackbar],
  );

  // after effect actions
  const [nextAfterEffectState, setNextAfterEffectState] = useState<
    AfterEffectState | undefined
  >(undefined);
  const [reRunAfterEffect, setReRunAfterEffect] = useState(false);
  const [afterEffectToChangeID, setAfterEffectToChangeID] = useState("");
  const handleUNSAFESetAfterEffectIDState = useCallback(
    async (
      afterEffectID: string,
      state: AfterEffectState,
      actionAnnotation: string,
    ) => {
      setAPILoading(true);
      try {
        await AfterEffectStateController.UNSAFESetAfterEffectIDState({
          context: authContext,
          afterEffectID,
          state,
          lastActionAnnotation: actionAnnotation,
        });
        enqueueSnackbar("After Effect State Changed", { variant: "success" });
        setReload((prev) => !prev);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error setting after effect state: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `error setting after effect state: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }
      setAPILoading(false);
    },
    [authContext, enqueueSnackbar],
  );
  const handleRunAfterEffect = useCallback(
    async (afterEffectID: string) => {
      if (!transaction) {
        return;
      }
      setAPILoading(true);
      try {
        await AfterEffectRunnerAsync.RunAfterEffectIDAsync({
          context: authContext,
          afterEffectID,
        });
        enqueueSnackbar(
          "After effect run asynchronously - refresh this page or check after effect phase for progress",
          { variant: "success" },
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error running after effect async: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `error running after effect async: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }
      setAPILoading(false);
    },
    [transaction, authContext, enqueueSnackbar],
  );

  const operationColumns: Column[] = [
    {
      field: "idx",
      label: "",
      minWidth: 25,
      accessor: (data) => {
        if (!transaction) {
          return "-";
        }
        const operationInRow = data as Operation;
        return transaction.operations.findIndex(
          (operationInList: Operation) =>
            operationInList.operationID() === operationInRow.operationID(),
        );
      },
    },
    {
      field: "id",
      label: "ID",
      accessor: (data) => {
        const op = data as Operation;
        return (
          <div className={classes.row}>
            <Typography variant="body1" children={op.operationID()} />
            <CopyPasteIcon
              className={classes.copyPasteIcon}
              onClick={() =>
                navigator.clipboard
                  .writeText(op.operationID())
                  .then(() => enqueueSnackbar("Operation ID copied"))
              }
            />
          </div>
        );
      },
    },
    {
      label: "Type",
      field: "@type",
      minWidth: 55,
      accessor: (data: { [p: string]: any }) =>
        (data as Operation)["@type"] === WriteOperationTypeName ? (
          <Typography
            variant="body1"
            style={{ color: theme.palette.warning.light }}
            children="Write"
          />
        ) : (
          <Typography
            variant="body1"
            style={{ color: theme.palette.info.light }}
            children="Read"
          />
        ),
    },
    {
      label: "State",
      field: "state",
      minWidth: 100,
      accessor: (data: { [p: string]: any }) => {
        const op = data as Operation;
        switch (true) {
          case op instanceof ReadOperation:
            return (
              <MultiRowInfoIcon
                invertColors
                title="No state since read operations are complete on submission"
              />
            );
          case op instanceof WriteOperation:
            return (
              <div className={classes.row}>
                <Typography
                  variant="body1"
                  children={(op as WriteOperation).state}
                />
                <MultiRowInfoIcon
                  invertColors
                  title={(op as WriteOperation).lastActionAnnotation}
                />
              </div>
            );
          default:
            return "?";
        }
      },
    },
    {
      field: "auditEntry.time",
      label: "Last Modified",
      minWidth: 80,
      accessor: (data) =>
        dayjs((data as Operation).operationAuditEntry().time).format(
          "DD/MM/YYYY HH:mm:ss",
        ),
    },
    {
      label: "Service",
      field: "service",
      accessor: (data: { [p: string]: any }) => (
        <Typography
          variant="body1"
          style={{
            color: getRandomColorForKey(
              (data as Operation).operationServiceURI(),
            ),
          }}
          children={(data as Operation).operationServiceURI()}
        />
      ),
    },
    {
      label: "Conflict Signatures",
      field: "conflictSignatures",
      accessor: (data: { [p: string]: any }) => (
        <div className={cx(classes.conflictSignaturesList, "meshScroll")}>
          <ul>
            {(data as Operation).operationConflictSignatures().map((s, i) => (
              <li key={i}>{s}</li>
            ))}
          </ul>
        </div>
      ),
    },
    {
      field: "payload",
      label: "Payload",
      accessor: (data) => {
        if (!(data instanceof WriteOperation)) {
          return (
            <MultiRowInfoIcon
              invertColors
              title="Read operations contain no payload"
            />
          );
        }
        try {
          return (
            <Popover
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              popOverComponent={
                <Card style={{ backgroundColor: jsonViewBackgroundColor }}>
                  <CardContent>
                    <ReactJson
                      name={false}
                      enableClipboard={(e) =>
                        navigator.clipboard
                          .writeText(JSON.stringify(e))
                          .then(() => enqueueSnackbar("copied"))
                      }
                      src={JSON.parse(data.payload)}
                      theme="google"
                    />
                  </CardContent>
                </Card>
              }
            >
              <Tooltip
                title="select to view write operation payload"
                placement="top"
              >
                <PayloadIcon className={classes.copyPasteIcon} />
              </Tooltip>
            </Popover>
          );
        } catch (e) {
          console.error(`error rendering json payload: ${e}`);
          return "error rendering payload";
        }
      },
    },
  ];
  const afterEffectColumns: Column[] = [
    {
      field: "id",
      label: "ID",
      minWidth: 280,
      accessor: (data) => {
        const txn = data as AfterEffect;
        return (
          <div className={classes.row}>
            <Typography variant="body1" children={txn.id} />
            <CopyPasteIcon
              className={classes.copyPasteIcon}
              onClick={() =>
                navigator.clipboard
                  .writeText(txn.id)
                  .then(() => enqueueSnackbar("After Effect ID copied"))
              }
            />
          </div>
        );
      },
    },
    {
      field: "state",
      label: "State",
      minWidth: 140,
      accessor: (data) => {
        const txn = data as AfterEffect;
        return (
          <div className={classes.row}>
            <Typography variant="body1" children={txn.state} />
            <MultiRowInfoIcon invertColors title={txn.lastActionAnnotation} />
          </div>
        );
      },
    },
    {
      field: "auditEntry.time",
      label: "Last Modified",
      minWidth: 180,
      accessor: (data) => {
        const runningAfterEffect = data as AfterEffect;
        return dayjs(runningAfterEffect.auditEntry.time).format(
          "DD/MM/YYYY HH:mm:ss",
        );
      },
    },
    {
      label: "Service",
      field: "service",
      accessor: (data: { [p: string]: any }) => (
        <Typography
          variant="body1"
          style={{
            color: getRandomColorForKey((data as AfterEffect).serviceURI),
          }}
          children={(data as AfterEffect).serviceURI}
        />
      ),
    },
    {
      field: "payload",
      label: "Payload",
      accessor: (data) => {
        const txn = data as AfterEffect;
        try {
          return (
            <Popover
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              popOverComponent={
                <Card style={{ backgroundColor: "#1d1f21" }}>
                  <CardContent>
                    <ReactJson
                      name={false}
                      enableClipboard={(e) =>
                        navigator.clipboard
                          .writeText(JSON.stringify(e))
                          .then(() => enqueueSnackbar("copied"))
                      }
                      src={JSON.parse(txn.payload)}
                      theme="google"
                    />
                  </CardContent>
                </Card>
              }
            >
              <Tooltip
                title="select to view after effect payload"
                placement="top"
              >
                <PayloadIcon className={classes.copyPasteIcon} />
              </Tooltip>
            </Popover>
          );
        } catch (e) {
          console.error(`error rendering json payload: ${e}`);
          return "error rendering payload";
        }
      },
    },
  ];

  if (!transaction) {
    return (
      <Root>
        {/* Bread Crumbs */}
        <div className={classes.breadCrumbLayout}>
          <Typography
            variant="caption"
            className={classes.clickableBreadcrumb}
            onClick={() => navigate("/consistency/transactions/table")}
          >
            Transactions
          </Typography>
          <Skeleton width={180} />
        </div>
        <Skeleton width="100%" height={100} />
        <Skeleton width="100%" height={300} />
        <Skeleton width="100%" height={300} />
      </Root>
    );
  }

  if (
    transactionsViewConfiguration.Control &&
    transaction.state === TransactionState.Accepted &&
    !(
      writeOperationsState === WriteOperationState.Complete ||
      writeOperationsState === WriteOperationState.RolledBack
    )
  ) {
    operationColumns.push({
      field: "actions",
      label: "Actions",
      minWidth: 0,
      sortable: false,
      accessor(data: { [p: string]: any }): string | number | React.ReactNode {
        if ((data as Operation)["@type"] !== WriteOperationTypeName) {
          return null;
        }
        const writeOp = data as WriteOperation;
        return (
          <div
            className={classes.actionButtonsCell}
            onClick={(e) => e.stopPropagation()}
          >
            {writeOp.state !== WriteOperationState.Pending && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                children="FORCE Pending"
                onClick={() => {
                  setNextWriteOperationState(WriteOperationState.Pending);
                  setWriteOperationToChangeID(writeOp.id);
                }}
              />
            )}
            {writeOp.state !== WriteOperationState.Complete && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                children="FORCE Complete"
                onClick={() => {
                  setNextWriteOperationState(WriteOperationState.Complete);
                  setWriteOperationToChangeID(writeOp.id);
                }}
              />
            )}
            {writeOp.state !== WriteOperationState.RolledBack && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                children="FORCE Rolled Back"
                onClick={() => {
                  setNextWriteOperationState(WriteOperationState.RolledBack);
                  setWriteOperationToChangeID(writeOp.id);
                }}
              />
            )}
          </div>
        );
      },
    });
  }

  if (
    writeOperationsState === WriteOperationState.Complete &&
    transactionsViewConfiguration.Control
  ) {
    afterEffectColumns.push({
      field: "actions",
      label: "Actions",
      minWidth: 0,
      sortable: false,
      accessor(data: { [p: string]: any }): string | number | React.ReactNode {
        const afterEffect = data as AfterEffect;

        switch (afterEffect.state) {
          case AfterEffectState.Pending:
            return (
              <div
                className={classes.actionButtonsCell}
                onClick={(e) => e.stopPropagation()}
              >
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  children="Run Async"
                  onClick={() => handleRunAfterEffect(afterEffect.id)}
                  endIcon={
                    <MultiRowInfoIcon
                      title="After effects may be run when they are pending and their associated transaction is complete. This will:"
                      listRows={[
                        "Submit the transaction to the after effect phase to be run asynchronously",
                      ]}
                    />
                  }
                />
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  children="FORCE Complete"
                  onClick={() => {
                    setNextAfterEffectState(AfterEffectState.Complete);
                    setAfterEffectToChangeID(afterEffect.id);
                  }}
                />
              </div>
            );

          case AfterEffectState.Complete:
            return (
              <div
                className={classes.actionButtonsCell}
                onClick={(e) => e.stopPropagation()}
              >
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  children="FORCE Re-Run"
                  onClick={() => {
                    setNextAfterEffectState(AfterEffectState.Pending);
                    setAfterEffectToChangeID(afterEffect.id);
                    setReRunAfterEffect(true);
                  }}
                />
              </div>
            );

          default:
            return null;
        }
      },
    });
  }

  return (
    <Root>
      {/* Bread Crumbs */}
      <div className={classes.breadCrumbLayout}>
        <Typography
          variant="caption"
          className={classes.clickableBreadcrumb}
          onClick={() => navigate("/consistency/transactions/table")}
        >
          Transactions
        </Typography>
        <Typography
          variant="caption"
          className={classes.nonClickableBreadcrumb}
        >
          {transaction.id}
        </Typography>
      </div>

      <div className={classes.viewCardLayout}>
        {/* Header */}
        <Paper className={classes.headerCardContent}>
          <Grid container direction="row" spacing={1} alignItems="center">
            {[
              <Typography
                variant="subtitle1"
                color="textSecondary"
                children={transaction.originatingProcess}
              />,
              <Typography variant="subtitle1" children="from" />,
              <Typography
                variant="subtitle1"
                color="textSecondary"
                children={transaction.originatingLocation}
              />,
            ].map((item, idx) => (
              <Grid item key={idx}>
                {item}
              </Grid>
            ))}
          </Grid>
          <Grid container direction="row" spacing={1} alignItems="center">
            {(() => {
              const actions: ReactNode[] = [
                // always show the back button
                <Button
                  variant="outlined"
                  children="go back"
                  startIcon={<BackArrowIcon />}
                  onClick={() => navigate("/consistency/transactions/table")}
                />,
              ];

              actions.push(
                <Tooltip title="Reload">
                  <span>
                    <IconButton
                      disabled={apiLoading}
                      size="small"
                      onClick={() => setReload(true)}
                    >
                      <ReloadIcon />
                    </IconButton>
                  </span>
                </Tooltip>,
              );

              return actions;
            })().map((item, idx) => (
              <Grid item key={idx}>
                {item}
              </Grid>
            ))}
          </Grid>
          <Grid container direction="row" spacing={1} alignItems="center">
            {(() => {
              if (!transaction) {
                return [];
              }
              return [
                <TextField
                  label="Created"
                  variant="standard"
                  value={dayjs(transaction.createdAt).format(
                    "DD/MM/YYYY HH:mm:ss",
                  )}
                  className={classes.textFieldLabel}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />,
                <TextField
                  label="State"
                  variant="standard"
                  value={transaction.state}
                  className={classes.textFieldLabel}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />,
                <TextField
                  label="Modified"
                  variant="standard"
                  value={dayjs(transaction.auditEntry.time).format(
                    "DD/MM/YYYY HH:mm:ss",
                  )}
                  className={classes.textFieldLabel}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />,
                <TextField
                  label="Write Operation State"
                  variant="standard"
                  value={`${writeOperationsState} @ ${writeOperationsStateLastModified.format(
                    "DD/MM/YYYY HH:mm:ss",
                  )}`}
                  className={classes.writeOperationsStateTextFieldLabel}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />,
                <TextField
                  label="Initiating User ID"
                  variant="standard"
                  value={
                    transaction.metaData["horizonTxn-initiatingUserID"]
                      ? transaction.metaData["horizonTxn-initiatingUserID"]
                      : "System Transaction"
                  }
                  className={classes.textFieldLabel}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: transaction.metaData[
                      "horizonTxn-initiatingUserID"
                    ] ? (
                      <InputAdornment position="end">
                        <CopyPasteIcon
                          fontSize="small"
                          className={classes.copyPasteIcon}
                          onClick={() =>
                            navigator.clipboard
                              .writeText(
                                transaction.metaData[
                                  "horizonTxn-initiatingUserID"
                                ],
                              )
                              .then(() => enqueueSnackbar("User ID Copied"))
                          }
                        />
                      </InputAdornment>
                    ) : undefined,
                  }}
                />,
                <TextField
                  label="Duration"
                  variant="standard"
                  value={(() => {
                    let diff: number;
                    if (
                      transaction.state === TransactionState.RolledBack ||
                      transaction.state === TransactionState.Complete
                    ) {
                      diff = dayjs(transaction.auditEntry.time).diff(
                        dayjs(transaction.createdAt),
                        "seconds",
                        true,
                      );
                    } else {
                      diff = writeOperationsStateLastModified.diff(
                        dayjs(transaction.createdAt),
                        "seconds",
                        true,
                      );
                    }
                    return `${diff}s`;
                  })()}
                  className={classes.textFieldLabel}
                  InputProps={{ disableUnderline: true }}
                />,
                ...(() => {
                  if (!(transaction && transactionsViewConfiguration.Control)) {
                    return [];
                  }
                  if (transaction.state !== TransactionState.Accepted) {
                    return [];
                  }

                  return [
                    <Button
                      variant="contained"
                      color="secondary"
                      children="run backward"
                      disabled={apiLoading}
                      onClick={handleRunTransactionBackward}
                      endIcon={
                        <MultiRowInfoIcon
                          title="Transactions can be run backward when they are in the Accepted state. This will:"
                          listRows={[
                            "Submit the transaction to the commit phase to be run backward asynchronously",
                          ]}
                        />
                      }
                    />,
                    <Button
                      variant="contained"
                      color="secondary"
                      children="run forward"
                      disabled={apiLoading}
                      onClick={handleRunTransactionForward}
                      endIcon={
                        <MultiRowInfoIcon
                          title="Transactions can be run forward when they are in the Accepted state. This will:"
                          listRows={[
                            "Submit the transaction to the commit phase to be run forward asynchronously",
                          ]}
                        />
                      }
                    />,
                  ];
                })(),
              ];
            })().map((item, idx) => (
              <Grid item key={idx}>
                {item}
              </Grid>
            ))}
          </Grid>
        </Paper>

        {/* Operations */}
        <FETable
          disableSelect
          height={
            transaction.afterEffects.length
              ? opsTableHeight
              : operationsOnlyOpsTableHeight
          }
          title={
            <div className={classes.row}>
              <Typography variant="subtitle1" children="Operations - " />
              <Typography
                variant="subtitle1"
                color="textSecondary"
                children={transaction.operations.length}
              />
            </div>
          }
          data={transaction.operations}
          initialRowsPerPage={100}
          columns={operationColumns}
        />

        {/* after effects */}
        {!!transaction.afterEffects.length && (
          <FETable
            disableSelect
            height={afterEffectTableHeight}
            title={
              <div className={classes.row}>
                <Typography variant="subtitle1" children="After Effects - " />
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  children={transaction.afterEffects.length}
                />
              </div>
            }
            data={transaction.afterEffects}
            initialRowsPerPage={100}
            columns={afterEffectColumns}
          />
        )}
      </div>

      {!!nextWriteOperationState && (
        <Dialog
          open
          maxWidth="lg"
          onClose={() => {
            setNextWriteOperationState(undefined);
            setNextAfterEffectState(undefined);
            setLastActionAnnotation("");
            setWriteOperationToChangeID("");
          }}
        >
          <DialogTitle
            classes={{
              root: classes.lastActionAnnotationDialogTitleRootOverride,
            }}
          >
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <NoteIcon />
              </Grid>
              <Grid item>
                <Typography variant="h5" children="Action Annotation" />
              </Grid>
              {apiLoading && (
                <Grid item>
                  <CircularProgress size={20} />
                </Grid>
              )}
            </Grid>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  children={`force state to '${nextWriteOperationState}'`}
                  onClick={async () => {
                    if (!lastActionAnnotation) {
                      setAnnotationBlankError(true);
                      return;
                    }
                    await handleUNSAFESetWriteOperationIDState(
                      writeOperationToChangeID,
                      nextWriteOperationState,
                      lastActionAnnotation,
                    );
                    setNextWriteOperationState(undefined);
                    setLastActionAnnotation("");
                    setWriteOperationToChangeID("");
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Close" placement="top">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setNextWriteOperationState(undefined);
                      setNextAfterEffectState(undefined);
                      setLastActionAnnotation("");
                      setWriteOperationToChangeID("");
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.dialogContentRootOverride}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography color="textSecondary">
                  Provide a note around why the write operation state force is
                  being forced to &apos;
                  {nextWriteOperationState}
                  &apos;
                </Typography>
              </Grid>
              <Grid item>
                <TextareaAutosize
                  minRows={9}
                  maxRows={9}
                  value={lastActionAnnotation}
                  onChange={(e) => {
                    setAnnotationBlankError(false);
                    let newValue: string = e.target.value;
                    if (newValue.length >= 250) {
                      newValue = newValue.slice(0, 250);
                    }
                    setLastActionAnnotation(newValue);
                  }}
                  placeholder="Note"
                  className={cx(classes.textArea, {
                    [classes.textAreaError]: annotationBlankError,
                  })}
                />
                {annotationBlankError ? (
                  <FormHelperText error>add some text</FormHelperText>
                ) : (
                  <FormHelperText>
                    {250 - lastActionAnnotation.length} Characters Left
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}

      {!!nextAfterEffectState && (
        <Dialog
          open
          maxWidth="lg"
          onClose={() => {
            setNextAfterEffectState(undefined);
            setReRunAfterEffect(false);
            setLastActionAnnotation("");
            setAfterEffectToChangeID("");
          }}
        >
          <DialogTitle
            classes={{
              root: classes.lastActionAnnotationDialogTitleRootOverride,
            }}
          >
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <NoteIcon />
              </Grid>
              <Grid item>
                <Typography variant="h5" children="Action Annotation" />
              </Grid>
              {apiLoading && (
                <Grid item>
                  <CircularProgress size={20} />
                </Grid>
              )}
            </Grid>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  children={`force state to '${nextAfterEffectState}'`}
                  onClick={async () => {
                    if (!lastActionAnnotation) {
                      setAnnotationBlankError(true);
                      return;
                    }
                    await handleUNSAFESetAfterEffectIDState(
                      afterEffectToChangeID,
                      nextAfterEffectState,
                      lastActionAnnotation,
                    );
                    if (reRunAfterEffect) {
                      await handleRunAfterEffect(afterEffectToChangeID);
                    }
                    setNextAfterEffectState(undefined);
                    setReRunAfterEffect(false);
                    setLastActionAnnotation("");
                    setAfterEffectToChangeID("");
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Close" placement="top">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setNextAfterEffectState(undefined);
                      setReRunAfterEffect(false);
                      setLastActionAnnotation("");
                      setAfterEffectToChangeID("");
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.dialogContentRootOverride}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography color="textSecondary">
                  Provide a note around why the after effect state force is
                  being forced to &apos;
                  {nextAfterEffectState}
                  &apos;
                </Typography>
              </Grid>
              <Grid item>
                <TextareaAutosize
                  minRows={9}
                  maxRows={9}
                  value={lastActionAnnotation}
                  onChange={(e) => {
                    setAnnotationBlankError(false);
                    let newValue: string = e.target.value;
                    if (newValue.length >= 250) {
                      newValue = newValue.slice(0, 250);
                    }
                    setLastActionAnnotation(newValue);
                  }}
                  placeholder="Note"
                  className={cx(classes.textArea, {
                    [classes.textAreaError]: annotationBlankError,
                  })}
                />
                {annotationBlankError ? (
                  <FormHelperText error>add some text</FormHelperText>
                ) : (
                  <FormHelperText>
                    {250 - lastActionAnnotation.length} Characters Left
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </Root>
  );
}
