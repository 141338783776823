import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { DigitalFixedRateBond } from "./DigitalFixedRateBond";
import { Document } from "james/document";

export type DraftUpdateDigitalFixedRateBondRequest = {
  context: Context;
  digitalFixedRateBond: DigitalFixedRateBond;
};

export type DraftUpdateDigitalFixedRateBondResponse = {
  digitalFixedRateBond: DigitalFixedRateBond;
};

export type AddDigitalFixedRateBondSupportingDocumentRequest = {
  context: Context;
  digitalFixedRateBondID: string;
  supportingDocument: Document;
};

export type AddDigitalFixedRateBondSupportingDocumentResponse = {
  digitalFixedRateBond: DigitalFixedRateBond;
};

export type DeleteDigitalFixedRateBondSupportingDocumentRequest = {
  context: Context;
  digitalFixedRateBondID: string;
  supportingDocumentID: string;
};

export type DeleteDigitalFixedRateBondSupportingDocumentResponse = {
  digitalFixedRateBond: DigitalFixedRateBond;
};

export const DigitalFixedRateBondUpdaterServiceProviderName =
  "financial-DigitalFixedRateBondUpdater";

export const DigitalFixedRateBondUpdater = {
  async DraftUpdateDigitalFixedRateBond(
    request: DraftUpdateDigitalFixedRateBondRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<DraftUpdateDigitalFixedRateBondResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DigitalFixedRateBondUpdaterServiceProviderName}.DraftUpdateDigitalFixedRateBond`,
        request,
      },
      opts,
    );

    return {
      digitalFixedRateBond: new DigitalFixedRateBond(
        response.digitalFixedRateBond,
      ),
    };
  },
  async AddDigitalFixedRateBondSupportingDocument(
    request: AddDigitalFixedRateBondSupportingDocumentRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<AddDigitalFixedRateBondSupportingDocumentResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DigitalFixedRateBondUpdaterServiceProviderName}.AddDigitalFixedRateBondSupportingDocument`,
        request,
      },
      opts,
    );

    return {
      digitalFixedRateBond: new DigitalFixedRateBond(
        response.digitalFixedRateBond,
      ),
    };
  },
  async DeleteDigitalFixedRateBondSupportingDocument(
    request: DeleteDigitalFixedRateBondSupportingDocumentRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<DeleteDigitalFixedRateBondSupportingDocumentResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DigitalFixedRateBondUpdaterServiceProviderName}.DeleteDigitalFixedRateBondSupportingDocument`,
        request,
      },
      opts,
    );

    return {
      digitalFixedRateBond: new DigitalFixedRateBond(
        response.digitalFixedRateBond,
      ),
    };
  },
};
