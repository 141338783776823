// source: financial/paymentReaderUNSCOPED_meshproto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var search_criterion_pb = require('../search/criterion_pb.js');
goog.object.extend(proto, search_criterion_pb);
var search_query_pb = require('../search/query_pb.js');
goog.object.extend(proto, search_query_pb);
var financial_payment_pb = require('../financial/payment_pb.js');
goog.object.extend(proto, financial_payment_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var num_decimal_pb = require('../num/decimal_pb.js');
goog.object.extend(proto, num_decimal_pb);
var financial_paymentState_pb = require('../financial/paymentState_pb.js');
goog.object.extend(proto, financial_paymentState_pb);
var financial_paymentCategory_pb = require('../financial/paymentCategory_pb.js');
goog.object.extend(proto, financial_paymentCategory_pb);
var ledger_amount_pb = require('../ledger/amount_pb.js');
goog.object.extend(proto, ledger_amount_pb);
var financial_paymentData_pb = require('../financial/paymentData_pb.js');
goog.object.extend(proto, financial_paymentData_pb);
var financial_paymentReader_meshproto_pb = require('../financial/paymentReader_meshproto_pb.js');
goog.object.extend(proto, financial_paymentReader_meshproto_pb);
