import React from "react";
import { DeferrableFloatingRateSmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLegDeferrableFloatingRate_pb";
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  allAssetflowCategories,
  assetflowCategoryToString,
} from "@mesh/common-js/dist/financial/assetFlowCategory";
import { Token } from "james/ledger";
import { TokenIconViewUpload } from "components/Ledger/Token";
import {
  LedgerAmountField,
  TextNumField,
} from "@mesh/common-js-react/dist/FormFields";
import { FutureAmount } from "@mesh/common-js/dist/ledger/amount_pb";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import { Model as LedgerTokenViewModel } from "../../../../../../../../../james/views/ledgerTokenView";
import { ScheduleConfigurationForm } from "../../../../components";
import { AmountWrapper } from "@mesh/common-js/dist/ledger";
import { ValidationResult } from "common/validation";
import {
  dayCountConventionToString,
  allDayCountConventions,
  allRateSources,
  rateSourceToString,
  allPeriodUnits,
  periodunitToString,
  allBusinessDayConventions,
  businessDayConventionToString,
} from "@mesh/common-js/dist/financial";
import { TextField } from "components/FormFields/TextField";
dayjs.extend(utc);
dayjs.extend(timezone);

export type DeferrableFloatingRateSmartInstrumentLegFormProps = {
  disabled: boolean;
  readOnly: boolean;
  leg: DeferrableFloatingRateSmartInstrumentLeg;
  onChange: (updatedLeg: DeferrableFloatingRateSmartInstrumentLeg) => void;
  ledgerTokenViewModels: LedgerTokenViewModel[];
  formDataValidationResult: ValidationResult;
};

export const DeferrableFloatingRateSmartInstrumentLegForm = (
  props: DeferrableFloatingRateSmartInstrumentLegFormProps,
) => {
  const getFieldValidation = (field: string) => {
    return props.formDataValidationResult.fieldValidations[
      `deferrableFloatingRateSmartInstrumentLeg-${props.leg.getId()}-${field}`
    ];
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(0.5),
        padding: theme.spacing(1, 2),
      })}
    >
      <TextField
        id="smartInstrument-legs-deferrableFloatingrateLeg-name-textField"
        disabled={props.disabled}
        readOnly={props.readOnly}
        label={"Leg Name"}
        value={props.leg.getName()}
        onChange={(e) => props.onChange(props.leg.setName(e.target.value))}
        error={!!getFieldValidation("name")}
        helperText={getFieldValidation("name")}
      />
      <Typography variant="h6">Base Information</Typography>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexWrap: "wrap",
          columnGap: theme.spacing(1),
          padding: theme.spacing(0.5, 2),
          alignItems: "center",
        })}
      >
        <TextField
          id="smartInstrument-legs-deferrablefloatingrateLeg-notionalAsset-selectField"
          disabled={props.disabled}
          readOnly={props.readOnly}
          fullWidth
          sx={{ maxWidth: 320 }}
          label="Token"
          select
          value={
            props.ledgerTokenViewModels.find((cst) =>
              cst.token.isEqualTo(
                Token.fromFutureToken(props.leg.getNotional()?.getToken()),
              ),
            )?.id ?? ""
          }
          onChange={(e) =>
            props.onChange(
              props.leg.setNotional(
                new AmountWrapper(props.leg.getNotional()).setToken(
                  (
                    props.ledgerTokenViewModels.find(
                      (cst) => cst.id === e.target.value,
                    )?.token ?? new Token()
                  ).toFutureToken(),
                ).amount,
              ),
            )
          }
          error={!!getFieldValidation("notionalToken")}
          helperText={getFieldValidation("notionalToken")}
        >
          {props.ledgerTokenViewModels.map((cst, idx) => (
            <MenuItem key={idx} value={cst.id}>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: theme.spacing(1),
                })}
              >
                <TokenIconViewUpload
                  disableChangeIcon
                  size={23}
                  token={cst.token}
                />
                <Typography children={`${cst.token.code} - ${cst.name}`} />
              </Box>
            </MenuItem>
          ))}
        </TextField>
        <LedgerAmountField
          id={
            "smartInstrument-legs-deferrablefloatingrateLeg-notionalValue-ledgerAmountField"
          }
          noDecimalPlaces={7}
          disabled={props.disabled}
          readOnly={props.readOnly}
          fullWidth
          sx={{ maxWidth: 320 }}
          disallowNegative
          label="Notional"
          value={props.leg.getNotional()}
          onChange={(newValue: FutureAmount) =>
            props.onChange(props.leg.setNotional(newValue))
          }
          error={!!getFieldValidation("notionalAmount")}
          helperText={getFieldValidation("notionalAmount")}
        />
        <TextField
          fullWidth
          sx={{ maxWidth: 320 }}
          id={
            "smartInstrument-legs-deferrablefloatingrateLeg-assetFlowCategory-selectField"
          }
          disabled={props.disabled}
          readOnly={props.readOnly}
          label="Asset Flow Category"
          select
          value={props.leg.getAssetflowcategory()}
          onChange={(e) =>
            props.onChange(
              props.leg.setAssetflowcategory(Number(e.target.value)),
            )
          }
          error={!!getFieldValidation("assetFlowCategory")}
          helperText={getFieldValidation("assetFlowCategory")}
        >
          {allAssetflowCategories.map((v) => {
            return (
              <MenuItem key={v} value={v}>
                {assetflowCategoryToString(v)}
              </MenuItem>
            );
          })}
        </TextField>
        <Box
          className="checkboxes"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            width: "100%",
            maxWidth: 322,
          }}
        >
          <FormControlLabel
            sx={{ width: "100%" }}
            id="smartInstrument-legs-deferrablefloatingrateLeg-cumulative-checkBox"
            disabled={props.disabled}
            control={
              <Checkbox
                disableTouchRipple={props.readOnly}
                disableFocusRipple={props.readOnly}
              />
            }
            label="Cumulative"
            checked={props.leg.getCumulative()}
            onChange={(_, checked) => {
              if (props.readOnly) {
                return;
              }
              props.onChange(props.leg.setCumulative(checked));
            }}
          />
        </Box>
      </Box>
      <Typography variant="h6">Rate Details</Typography>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexWrap: "wrap",
          columnGap: theme.spacing(1),
          padding: theme.spacing(0.5, 2),
          alignItems: "center",
        })}
      >
        <TextField
          fullWidth
          sx={{ maxWidth: 320 }}
          id={
            "smartInstrument-legs-deferrablefloatingrateLeg-rateSource-selectField"
          }
          disabled={props.disabled}
          readOnly={props.readOnly}
          label="Reference Rate"
          select
          value={props.leg.getRatesource()}
          onChange={(e) =>
            props.onChange(props.leg.setRatesource(Number(e.target.value)))
          }
          error={!!getFieldValidation("rateSource")}
          helperText={getFieldValidation("rateSource")}
        >
          {allRateSources.map((v) => {
            return (
              <MenuItem key={v} value={v}>
                {rateSourceToString(v)}
              </MenuItem>
            );
          })}
        </TextField>
        <TextNumField
          fullWidth
          sx={{ maxWidth: 320 }}
          id={
            "smartInstrument-legs-deferrablefloatingrateLeg-referenceRateFactor-selectField"
          }
          disabled={props.disabled}
          readOnly={props.readOnly}
          label="Reference Rate Factor"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography>%</Typography>
              </InputAdornment>
            ),
          }}
          value={props.leg.getReferenceratefactor()}
          onChange={(newValue) =>
            props.onChange(props.leg.setReferenceratefactor(newValue))
          }
          error={!!getFieldValidation("referenceRateFactor")}
          helperText={getFieldValidation("referenceRateFactor")}
        />
        <TextNumField
          fullWidth
          sx={{ maxWidth: 320 }}
          id={
            "smartInstrument-legs-deferrablefloatingrateLeg-rateSpread-selectField"
          }
          disabled={props.disabled}
          readOnly={props.readOnly}
          label="Spread"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography>%</Typography>
              </InputAdornment>
            ),
          }}
          value={props.leg.getRatespread()}
          onChange={(newValue) =>
            props.onChange(props.leg.setRatespread(newValue))
          }
          error={!!getFieldValidation("rateSpread")}
          helperText={getFieldValidation("rateSpread")}
        />
        <TextNumField
          fullWidth
          sx={{ maxWidth: 320 }}
          id={
            "smartInstrument-legs-deferrablefloatingrateLeg-rateFloor-selectField"
          }
          disabled={props.disabled}
          readOnly={props.readOnly}
          label="Floor"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography>%</Typography>
              </InputAdornment>
            ),
          }}
          value={props.leg.getRatefloor()}
          onChange={(newValue) =>
            props.onChange(props.leg.setRatefloor(newValue))
          }
          error={!!getFieldValidation("rateFloor")}
          helperText={getFieldValidation("rateFloor")}
        />
        <TextField
          fullWidth
          sx={{ maxWidth: 320 }}
          id={
            "smartInstrument-legs-deferrablefloatingrateLeg-dayCountConvention-selectField"
          }
          disabled={props.disabled}
          readOnly={props.readOnly}
          label="Day Count Convention"
          select
          value={props.leg.getDaycountconvention()}
          onChange={(e) =>
            props.onChange(
              props.leg.setDaycountconvention(Number(e.target.value)),
            )
          }
          error={!!getFieldValidation("dayCountConvention")}
          helperText={getFieldValidation("dayCountConvention")}
        >
          {allDayCountConventions.map((v) => {
            return (
              <MenuItem key={v} value={v}>
                {dayCountConventionToString(v)}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          fullWidth
          sx={{ maxWidth: 320 }}
          id={
            "smartInstrument-legs-deferrablefloatingrateLeg-rateResetPeriodCount-selectField"
          }
          disabled={props.disabled}
          readOnly={props.readOnly}
          label="Rate Reset Period Count"
          InputProps={{ type: "number" }}
          value={props.leg.getRateresetperiodcount()}
          onChange={(e) =>
            props.onChange(
              props.leg.setRateresetperiodcount(Number(e.target.value)),
            )
          }
          error={!!getFieldValidation("rateResetPeriodCount")}
          helperText={getFieldValidation("rateResetPeriodCount")}
        />
        <TextField
          fullWidth
          sx={{ maxWidth: 320 }}
          id={
            "smartInstrument-legs-deferrablefloatingrateLeg-rateResetPeriodUnit-selectField"
          }
          disabled={props.disabled}
          readOnly={props.readOnly}
          label="Rate Reset Period Unit"
          select
          value={props.leg.getRateresetperiodunit()}
          onChange={(e) =>
            props.onChange(
              props.leg.setRateresetperiodunit(Number(e.target.value)),
            )
          }
          error={!!getFieldValidation("rateResetPeriodUnit")}
          helperText={getFieldValidation("rateResetPeriodUnit")}
        >
          {allPeriodUnits.map((v) => {
            return (
              <MenuItem key={v} value={v}>
                {periodunitToString(v)}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          fullWidth
          sx={{ maxWidth: 320 }}
          id={
            "smartInstrument-legs-deferrablefloatingrateLeg-rateResetBusinessDayConvention-selectField"
          }
          disabled={props.disabled}
          readOnly={props.readOnly}
          label="Rate Reset Business Day Convention"
          select
          value={props.leg.getRateresetbusinessdayconvention()}
          onChange={(e) =>
            props.onChange(
              props.leg.setRateresetbusinessdayconvention(
                Number(e.target.value),
              ),
            )
          }
          error={!!getFieldValidation("rateResetBusinessDayConvention")}
          helperText={getFieldValidation("rateResetBusinessDayConvention")}
        >
          {allBusinessDayConventions.map((v) => {
            return (
              <MenuItem key={v} value={v}>
                {businessDayConventionToString(v)}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
      <ScheduleConfigurationForm
        disabled={props.disabled}
        readOnly={props.readOnly}
        scheduleConfiguration={props.leg.getScheduleconfiguration()}
        onChange={(updatedScheduleConfiguration) =>
          props.onChange(
            props.leg.setScheduleconfiguration(updatedScheduleConfiguration),
          )
        }
        formDataValidationResult={props.formDataValidationResult}
        validationResultFieldPrefix={`deferrableFloatingRateSmartInstrumentLeg-${props.leg.getId()}`}
      />
    </Box>
  );
};
