import React, { useState } from "react";
import { Document } from "../../../../../james/document";
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  Description,
  FaceOutlined as FaceIcon,
  MoreVert,
  Upload as UploadIcon,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { doUpload } from "utilities/network/upload";
import { useApplicationContext } from "context/Application/Application";
import { InstrumentDocumentController } from "james/financial/InstrumentDocumentController";
import { download } from "utilities/network/download";
import { useErrorContext } from "context/Error";

type Props = {
  showButton: boolean;
  documents: Document[];
  creatingNewInstrument: boolean;
  onDelete?: (document: Document) => void;
  onDocumentUpload?: (document: Document) => void;
};

export const Documents = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const [deletingDocument, setDeletingDocument] = useState(false);

  const vertOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const menuOnClose = () => {
    setAnchorEl(null);
  };

  if (props.creatingNewInstrument) {
    return (
      <Box
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "auto",
          justifyItems: "center",
          gridRowGap: theme.spacing(2),
          pb: 5,
        })}
      >
        <FaceIcon
          sx={(theme) => ({
            fontSize: 110,
            color: alpha(theme.palette.background.default, 0.5),
          })}
        />
        <Typography
          color="secondary"
          variant="h4"
          children="Can't upload documents"
        />
        <Typography
          variant="body2"
          children={"Instrument must be saved before documents can be uploaded"}
        />
      </Box>
    );
  }

  if (props.documents.length === 0) {
    return (
      <Box
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "auto",
          justifyItems: "center",
          gridRowGap: theme.spacing(2),
        })}
      >
        <FaceIcon
          sx={(theme) => ({
            fontSize: 110,
            color: alpha(theme.palette.background.default, 0.5),
          })}
        />
        <Typography
          color="secondary"
          variant="h4"
          children="Nothing to see here"
        />
        <Typography
          variant="body2"
          children={"You will see documents here once you have uploaded them"}
        />
        {props.showButton && (
          <FileUploadButton onUpload={props.onDocumentUpload} />
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <Typography variant="h6" sx={{ ml: 5, mb: 1 }}>
          Description
        </Typography>
        <Divider />
      </Box>
      {props.documents.map((doc, idx) => (
        <Box
          key={idx}
          sx={(theme) => ({
            display: "grid",
            gridTemplateColumns: "auto 1fr auto",
            alignItems: "center",
            columnGap: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
            borderBottom:
              idx !== props.documents.length - 1
                ? `0.5px solid ${theme.palette.divider}`
                : undefined,
          })}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Description
              sx={(theme) => ({ color: theme.palette.text.secondary })}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                wordBreak: "break-word",
              }}
              color="textPrimary"
              variant="body2"
            >
              {doc.description}
            </Typography>
            <Typography
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                wordBreak: "break-word",
              }}
              color="secondary"
              variant="body2"
            >
              {doc.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              id={`AssetDocument-DocOption-button-${idx}`}
              onClick={vertOnClick}
              size={"small"}
            >
              <MoreVert
                sx={(theme) => ({ color: theme.palette.text.secondary })}
              />
            </IconButton>
            <Menu
              open={!!anchorEl}
              anchorEl={anchorEl}
              id={`instrument-documents-Menu-${idx}`}
              onClose={menuOnClose}
              autoFocus={false}
            >
              <MenuItem
                id={`instrument-documents-view-MenuItem-${idx}`}
                onClick={async () => {
                  try {
                    window.open(
                      (
                        await InstrumentDocumentController.RequestDocumentDownloadForInstrument(
                          {
                            context: authContext,
                            documentID: doc.id,
                          },
                        )
                      ).downloadURL,
                      doc.name,
                    );
                  } catch (e) {
                    const err = errorContextErrorTranslator.translateError(e);
                    console.error(
                      `error requesting download url: ${
                        err.message ? err.message : err.toString()
                      }`,
                    );
                  }
                }}
              >
                View
              </MenuItem>
              <MenuItem
                id={`instrument-documents-download-MenuItem-${idx}`}
                onClick={async () => {
                  try {
                    download(
                      (
                        await InstrumentDocumentController.RequestDocumentDownloadForInstrument(
                          {
                            context: authContext,
                            documentID: doc.id,
                          },
                        )
                      ).downloadURL,
                      doc.name,
                    );
                  } catch (e) {
                    const err = errorContextErrorTranslator.translateError(e);
                    console.error(
                      `error requesting download url: ${
                        err.message ? err.message : err.toString()
                      }`,
                    );
                  }
                }}
              >
                Download
              </MenuItem>
              <MenuItem
                disabled={deletingDocument}
                id={`instrument-documents-delete-MenuItem-${idx}`}
                onClick={async () => {
                  setDeletingDocument(true);
                  try {
                    InstrumentDocumentController.DeleteDocumentForInstrument({
                      context: authContext,
                      documentID: doc.id,
                    });

                    if (props.onDelete) {
                      props.onDelete(doc);
                    }
                  } catch (e) {
                    setDeletingDocument(false);
                    console.error(e);
                  }
                  setDeletingDocument(false);
                  setAnchorEl(null);
                }}
              >
                Delete{" "}
                {deletingDocument && (
                  <CircularProgress size={16} sx={{ ml: 1 }} />
                )}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {props.showButton && (
          <FileUploadButton onUpload={props.onDocumentUpload} />
        )}
      </Box>
    </Box>
  );
};

const allowedDocumentTypes = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
];

const allowedDocumentTypesExtensions = [".png", ".jpg", ".jpeg", ".pdf"];

type FileUploadButtonProps = {
  onUpload?: (document: Document) => void;
};

const FileUploadButton = ({ onUpload }: FileUploadButtonProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();
  const [fileName, setFileName] = useState("");

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      // Document type limits (e.g., only PDF and DOCX)
      if (!allowedDocumentTypes.includes(file.type)) {
        enqueueSnackbar(
          `File Type Not Supported. Should be one of: ${allowedDocumentTypes.join(
            ", ",
          )}`,
          { variant: "warning" },
        );
        return;
      }

      // Document size limit (e.g., 20MB)
      const maxSizeInBytes = 20 * 1024 * 1024; // 20MB
      if (file.size > maxSizeInBytes) {
        enqueueSnackbar("File is Too Large for Upload (Max 20 MB)", {
          variant: "warning",
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      try {
        // request to upload a document against the instrument
        let error: Error | null = null;
        const response =
          await InstrumentDocumentController.RequestDocumentUploadForInstrument(
            {
              context: authContext,
            },
          );

        doUpload({
          url: response.uploadURL,
          documentData: await file.arrayBuffer(),
          documentType: file.type,
          onError: (e) => (error = e),
        });
        if (error) {
          throw new Error(`Error: ${(error as Error).message}`);
        }

        if (onUpload) {
          let name = fileName;
          if (fileName === "") {
            name = file.name;
          }
          const documentUploaded: Document = {
            id: response.documentID,
            name: name,
            mimeType: file.type,
            description: "instrument document",
          };
          onUpload(documentUploaded);
        }
      } catch (error) {
        alert(`Error: ${(error as Error).message}`);
      }
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept={allowedDocumentTypesExtensions.join(", ")}
      />
      <TextField
        label="Document Name"
        sx={{ width: 260 }}
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
      />
      <Button
        id={"instruments-documents-firstUpload-button"}
        variant={"outlined"}
        sx={{
          marginTop: "4px",
          minWidth: 60,
        }}
        startIcon={
          <UploadIcon
            sx={{
              fontSize: "25px !important",
              marginLeft: (theme) => theme.spacing(1),
            }}
          />
        }
        onClick={handleButtonClick}
      >
        Upload Document
      </Button>
    </Box>
  );
};
