import { Asset } from "james/ledger/Asset";
import { Token } from "james/ledger/Token";
import { Amount } from "james/ledger/Amount";
import { Instrument } from "./Instrument";
import { DigitalETFTypeName, DigitalETF } from "./DigitalETF";
import { DigitalETNTypeName, DigitalETN } from "./DigitalETN";
import { FinancialInstrumentState } from "./InstrumentState";
import { InstrumentRiskProfile } from "./InstrumentRiskProfile";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";
import { AssetClassDEPRECATED } from "./AssetClassDEPRECATED";
import { InvestorProfile } from "./InvestorProfile";
import { Document } from "../document/Document";
import {
  DigitalFixedRateBondTypeName,
  DigitalFixedRateBond,
} from "./DigitalFixedRateBond";
import {
  DigitalFloatingRateBondTypeName,
  DigitalFloatingRateBond,
} from "./DigitalFloatingRateBond";

export interface DigitalInstrument extends Asset, Instrument {
  digitalInstrumentValuationToken(): Token;

  digitalInstrumentMaximumUnits(): Amount;

  digitalInstrumentIssuePrice(): Amount;
}

export function NewDigitalInstrument(d: DigitalInstrument): DigitalInstrument {
  switch (d["@type"]) {
    case DigitalETFTypeName:
      return new DigitalETF(d as DigitalETF);
    case DigitalETNTypeName:
      return new DigitalETN(d as DigitalETN);
    case DigitalFixedRateBondTypeName:
      return new DigitalFixedRateBond(d as DigitalFixedRateBond);
    case DigitalFloatingRateBondTypeName:
      return new DigitalFloatingRateBond(d as DigitalFloatingRateBond);
    default:
      throw new TypeError(`unknown digital instrument type ${d["@type"]}`);
  }
}

export function DigitalInstrumentImplementedBy(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any,
): object is DigitalInstrument {
  return [
    DigitalETFTypeName,
    DigitalETNTypeName,
    DigitalFixedRateBondTypeName,
    DigitalFloatingRateBondTypeName,
  ].includes(object["@type"]);
}

export class NullDigitalInstrument implements DigitalInstrument {
  "@type": string;

  assetID(): string {
    throw new TypeError("null digital instrument");
  }

  assetIssueDate(): string {
    throw new TypeError("null digital instrument");
  }

  assetName(): string {
    throw new TypeError("null digital instrument");
  }

  assetOwnerID(): string {
    throw new TypeError("null digital instrument");
  }

  assetShortName(): string {
    throw new TypeError("null digital instrument");
  }

  assetToken(): Token {
    throw new TypeError("null digital instrument");
  }

  assetFractionalisationAllowed(): boolean {
    throw new TypeError("null digital instrument");
  }

  digitalInstrumentIssuePrice(): Amount {
    throw new TypeError("null digital instrument");
  }

  digitalInstrumentMaximumUnits(): Amount {
    throw new TypeError("null digital instrument");
  }

  digitalInstrumentValuationToken(): Token {
    throw new TypeError("null digital instrument");
  }

  instrumentAnnualPerformanceLog(): InstrumentAnnualPerformanceLogEntry[] {
    throw new TypeError("null digital instrument");
  }

  instrumentAssetClass(): AssetClassDEPRECATED | "" {
    throw new TypeError("null digital instrument");
  }

  instrumentID(): string {
    throw new TypeError("null digital instrument");
  }

  instrumentInvestorProfile(): InvestorProfile | "" {
    throw new TypeError("null digital instrument");
  }

  instrumentIssueDate(): string {
    throw new TypeError("null digital instrument");
  }

  instrumentMaturityDate(): string {
    throw new TypeError("null digital instrument");
  }

  instrumentName(): string {
    throw new TypeError("null digital instrument");
  }

  instrumentOwnerID(): string {
    throw new TypeError("null digital instrument");
  }

  instrumentRiskProfile(): InstrumentRiskProfile | "" {
    throw new TypeError("null digital instrument");
  }

  instrumentState(): FinancialInstrumentState {
    throw new TypeError("null digital instrument");
  }

  instrumentSupportingDocuments(): Document[] {
    throw new TypeError("null digital instrument");
  }
}
