import {
  Box,
  Button,
  CircularProgress,
  Typography,
  alpha,
} from "@mui/material";
import React, { useMemo } from "react";
import { useBuilderContext } from "../../../../Context";
import {
  DataTable,
  RowType,
} from "@mesh/common-js-react/dist/Tables/DataTable";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { decimalToBigNumber, formatTextNum } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { DateTimeFormat } from "const/dateformats";
import {
  FaceOutlined as FaceIcon,
  ErrorOutline as WarningIcon,
} from "@mui/icons-material";
import { RateResetCorporateAction } from "@mesh/common-js/dist/financial/rateResetCorporateAction_pb";
import { timezoneToString } from "@mesh/common-js/dist/i8n";

export type RateResetsProps = {
  height: number;
};

export const RateResets = (props: RateResetsProps) => {
  const { simulationMode } = useBuilderContext();

  return simulationMode ? <Simulated {...props} /> : <Actual {...props} />;
};

const Simulated = (props: RateResetsProps) => {
  const { formData, simulatedRateResets } = useBuilderContext();

  const rateResetColumns: RowType<
    RateResetCorporateAction,
    Omit<
      RateResetCorporateAction.AsObject,
      | "id"
      | "number"
      | "ownerid"
      | "auditentry"
      | "state"
      | "smartinstrumentid"
      | "legid"
    >
  > = useMemo(
    () => ({
      sequencenumber: {
        title: "Sequence Number",
        renderCell: (rowData: RateResetCorporateAction) => {
          return rowData.getSequencenumber();
        },
      },
      date: {
        title: "Date",
        renderCell: (rowData: RateResetCorporateAction) => {
          return protobufTimestampToDayjs(rowData.getDate() ?? new Timestamp())
            .tz(timezoneToString(formData.smartInstrument.getTimezone()))
            .format(DateTimeFormat);
        },
      },
      oldrate: {
        title: "Old Rate",
        renderCell: (rowData: RateResetCorporateAction) => {
          return formatTextNum(
            decimalToBigNumber(rowData.getOldrate() ?? new Decimal()),
          );
        },
      },
      newrate: {
        title: "New Rate",
        renderCell: (rowData: RateResetCorporateAction) => {
          return formatTextNum(
            decimalToBigNumber(rowData.getNewrate() ?? new Decimal()),
          );
        },
      },
    }),
    [formData.smartInstrument.getTimezone()],
  );

  if (!simulatedRateResets.length) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(0.5),
          })}
        >
          <FaceIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Nothing to see here"
          />
          <Typography
            variant="body2"
            children={
              <span>
                You will see <i>Rate Resets</i> here after running{" "}
                <b>Calculate</b>
              </span>
            }
          />
        </Box>
      </Box>
    );
  }

  return (
    <DataTable
      tableContainerClassName="meshScroll"
      height={props.height - 50}
      data={simulatedRateResets}
      columns={rateResetColumns}
    />
  );
};

const Actual = (props: RateResetsProps) => {
  const {
    formData,
    rateResets,
    rateResetsLoaded,
    rateResetLoadError,
    clearRateResetLoadError,
  } = useBuilderContext();

  const rateResetColumns: RowType<
    RateResetCorporateAction,
    Omit<
      RateResetCorporateAction.AsObject,
      | "id"
      | "number"
      | "ownerid"
      | "auditentry"
      | "state"
      | "smartinstrumentid"
      | "legid"
    >
  > = useMemo(
    () => ({
      sequencenumber: {
        title: "Sequence Number",
        renderCell: (rowData: RateResetCorporateAction) => {
          return rowData.getSequencenumber();
        },
      },
      date: {
        title: "Date",
        renderCell: (rowData: RateResetCorporateAction) => {
          return protobufTimestampToDayjs(rowData.getDate() ?? new Timestamp())
            .tz(timezoneToString(formData.smartInstrument.getTimezone()))
            .format(DateTimeFormat);
        },
      },
      oldrate: {
        title: "Old Rate",
        renderCell: (rowData: RateResetCorporateAction) => {
          return formatTextNum(
            decimalToBigNumber(rowData.getOldrate() ?? new Decimal()),
          );
        },
      },
      newrate: {
        title: "New Rate",
        renderCell: (rowData: RateResetCorporateAction) => {
          return formatTextNum(
            decimalToBigNumber(rowData.getNewrate() ?? new Decimal()),
          );
        },
      },
    }),
    [formData.smartInstrument.getTimezone()],
  );

  if (!rateResetsLoaded) {
    return (
      <Box
        sx={(theme) => ({
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyItems: "center",
          gap: theme.spacing(2),
        })}
      >
        <CircularProgress size={70} />
        <Typography variant="h5" color="textSecondary" children="Loading..." />
      </Box>
    );
  }

  if (rateResetLoadError) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(0.5),
          })}
        >
          <WarningIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Something Went Wrong"
          />
          <Typography variant="body2" children={rateResetLoadError} />
          <Button onClick={clearRateResetLoadError}>Try Again</Button>
        </Box>
      </Box>
    );
  }

  if (!rateResets.length) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(0.5),
          })}
        >
          <FaceIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Nothing to see here"
          />
          <Typography
            variant="body2"
            children={
              <span>
                You will see <i>Rate Resets</i> once they have been generated.
              </span>
            }
          />
        </Box>
      </Box>
    );
  }

  return (
    <DataTable
      tableContainerClassName="meshScroll"
      height={props.height - 50}
      data={rateResets}
      columns={rateResetColumns}
    />
  );
};
