export enum Calendar {
  UNDEFINED_CALENDAR = 0,
  AUSTRALIA_CALENDAR = 1,
  ARGENTINA_CALENDAR = 2,
  BRAZIL_CALENDAR = 3,
  CANADA_CALENDAR = 4,
  CHINA_CALENDAR = 5,
  CZECH_REPUBLIC_CALENDAR = 6,
  DENMARK_CALENDAR = 7,
  FINLAND_CALENDAR = 8,
  FRANCE_CALENDAR = 9,
  GERMANY_CALENDAR = 10,
  HONG_KONG_CALENDAR = 11,
  HUNGARY_CALENDAR = 12,
  ICELAND_CALENDAR = 13,
  INDIA_CALENDAR = 14,
  INDONESIA_CALENDAR = 15,
  ISRAEL_CALENDAR = 16,
  ITALY_CALENDAR = 17,
  JAPAN_CALENDAR = 18,
  MEXICO_CALENDAR = 19,
  NEW_ZEALAND_CALENDAR = 20,
  NORWAY_CALENDAR = 21,
  POLAND_CALENDAR = 22,
  ROMANIA_CALENDAR = 23,
  RUSSIA_CALENDAR = 24,
  RUSSIA_SETTLEMENT_CALENDAR = 25,
  RUSSIA_MOEX_CALENDAR = 26,
  SAUDI_ARABIA_CALENDAR = 27,
  SINGAPORE_CALENDAR = 28,
  SLOVAKIA_CALENDAR = 29,
  SOUTH_AFRICA_CALENDAR = 30,
  SOUTH_KOREA_CALENDAR = 31,
  SWEDEN_CALENDAR = 32,
  SWITZERLAND_CALENDAR = 33,
  TAIWAN_CALENDAR = 34,
  TARGET_CALENDAR = 35,
  THAILAND_CALENDAR = 36,
  TURKEY_CALENDAR = 37,
  UKRAINE_CALENDAR = 38,
  UNITED_KINGDOM_CALENDAR = 39,
  UNITED_KINGDOM_SETTLEMENT_CALENDAR = 40,
  UNITED_STATES_SETTLEMENT_CALENDAR = 41,
  UNITED_STATES_NYSE_CALENDAR = 42,
  UNITED_STATES_GOVERNMENT_BOND_CALENDAR = 43,
  UNITED_STATES_NERC_CALENDAR = 44,
  UNITED_STATES_LIBOR_IMPACT_CALENDAR = 45,
  UNITED_STATES_FEDERAL_RESERVE_CALENDAR = 46,
}

// Get all calendars as enum values
export const allCalendars: Calendar[] = Object.values(Calendar).filter(
  (value) => typeof value === "number",
) as Calendar[];

// Define explicit mappings between Calendar enums and custom string representations
const calendarToStringMapping: {
  [key in Calendar]: string;
} = {
  [Calendar.UNDEFINED_CALENDAR]: "-",
  [Calendar.AUSTRALIA_CALENDAR]: "Australia",
  [Calendar.ARGENTINA_CALENDAR]: "Argentina",
  [Calendar.BRAZIL_CALENDAR]: "Brazil",
  [Calendar.CANADA_CALENDAR]: "Canada",
  [Calendar.CHINA_CALENDAR]: "China",
  [Calendar.CZECH_REPUBLIC_CALENDAR]: "Czech Republic",
  [Calendar.DENMARK_CALENDAR]: "Denmark",
  [Calendar.FINLAND_CALENDAR]: "Finland",
  [Calendar.FRANCE_CALENDAR]: "France",
  [Calendar.GERMANY_CALENDAR]: "Germany",
  [Calendar.HONG_KONG_CALENDAR]: "Hong Kong",
  [Calendar.HUNGARY_CALENDAR]: "Hungary",
  [Calendar.ICELAND_CALENDAR]: "Iceland",
  [Calendar.INDIA_CALENDAR]: "India",
  [Calendar.INDONESIA_CALENDAR]: "Indonesia",
  [Calendar.ISRAEL_CALENDAR]: "Israel",
  [Calendar.ITALY_CALENDAR]: "Italy",
  [Calendar.JAPAN_CALENDAR]: "Japan",
  [Calendar.MEXICO_CALENDAR]: "Mexico",
  [Calendar.NEW_ZEALAND_CALENDAR]: "New Zealand",
  [Calendar.NORWAY_CALENDAR]: "Norway",
  [Calendar.POLAND_CALENDAR]: "Poland",
  [Calendar.ROMANIA_CALENDAR]: "Romania",
  [Calendar.RUSSIA_CALENDAR]: "Russia",
  [Calendar.RUSSIA_SETTLEMENT_CALENDAR]: "Russia (Settlement)",
  [Calendar.RUSSIA_MOEX_CALENDAR]: "Russia (MOEX)",
  [Calendar.SAUDI_ARABIA_CALENDAR]: "Saudi Arabia",
  [Calendar.SINGAPORE_CALENDAR]: "Singapore",
  [Calendar.SLOVAKIA_CALENDAR]: "Slovakia",
  [Calendar.SOUTH_AFRICA_CALENDAR]: "South Africa",
  [Calendar.SOUTH_KOREA_CALENDAR]: "South Korea",
  [Calendar.SWEDEN_CALENDAR]: "Sweden",
  [Calendar.SWITZERLAND_CALENDAR]: "Switzerland",
  [Calendar.TAIWAN_CALENDAR]: "Taiwan",
  [Calendar.TARGET_CALENDAR]: "TARGET",
  [Calendar.THAILAND_CALENDAR]: "Thailand",
  [Calendar.TURKEY_CALENDAR]: "Turkey",
  [Calendar.UKRAINE_CALENDAR]: "Ukraine",
  [Calendar.UNITED_KINGDOM_CALENDAR]: "United Kingdom",
  [Calendar.UNITED_KINGDOM_SETTLEMENT_CALENDAR]: "United Kingdom (Settlement)",
  [Calendar.UNITED_STATES_SETTLEMENT_CALENDAR]: "United States (Settlement)",
  [Calendar.UNITED_STATES_NYSE_CALENDAR]: "United States (NYSE)",
  [Calendar.UNITED_STATES_GOVERNMENT_BOND_CALENDAR]:
    "United States (Government Bond)",
  [Calendar.UNITED_STATES_NERC_CALENDAR]: "United States (NERC)",
  [Calendar.UNITED_STATES_LIBOR_IMPACT_CALENDAR]:
    "United States (LIBOR Impact)",
  [Calendar.UNITED_STATES_FEDERAL_RESERVE_CALENDAR]:
    "United States (Federal Reserve)",
};
// Reverse mapping from string to Calendar enum
const stringToCalendarMapping: Record<string, Calendar> = {};
for (const [key, value] of Object.entries(calendarToStringMapping)) {
  stringToCalendarMapping[value] = Number(key);
}

class UnsupportedCalendarError extends Error {
  calendar: Calendar;

  constructor(calendar: Calendar) {
    const message = `Unsupported Calendar: ${calendar}`;
    super(message);
    this.calendar = calendar;
  }
}

/**
 * Converts a Calendar enum instance to a custom string representation.
 * @param {Calendar} calendar - The calendar to convert.
 * @returns {string} The custom string representation of the calendar.
 */
export function calendarToString(calendar: Calendar): string {
  if (calendar in calendarToStringMapping) {
    return calendarToStringMapping[calendar];
  } else {
    throw new UnsupportedCalendarError(calendar);
  }
}

class UnsupportedCalendarStringError extends Error {
  calendarStr: string;

  constructor(calendarStr: string) {
    const message = `Unsupported calendar string: ${calendarStr}`;
    super(message);
    this.calendarStr = calendarStr;
  }
}

/**
 * Converts a custom string representation to a Calendar enum instance.
 * @param {string} calendarStr - The custom string representation of the calendar.
 * @returns {Calendar} The corresponding Calendar enum instance.
 */
export function stringToCalendar(calendarStr: string): Calendar {
  if (calendarStr in stringToCalendarMapping) {
    return stringToCalendarMapping[calendarStr];
  } else {
    throw new UnsupportedCalendarStringError(calendarStr);
  }
}
