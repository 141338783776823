import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useClientRepositorySearchClients } from "james/client/Repository";
import { Query } from "james/search/query";
import { BPTable } from "components/Table";
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Cancel as CancelIcon,
  Refresh as ReloadIcon,
  VisibilityOutlined as KYCDialogIcon,
} from "@mui/icons-material";
import { Client, ClientKYCStatus } from "james/client";
import {
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion/text";
import { ClientKind } from "james/client/Client";
import { IndividualClientDetailsDialog } from "./components/IndividualClientDetailDialog/IndividualClientDetailsDialog";
import { useApplicationContext } from "context/Application/Application";
import dayjs from "dayjs";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { Dashboard } from "../Dashboard/Dashboard";
import { DateField } from "components/FormFields";
import { dateIsValid } from "utilities/date";
import { DateRangeCriterion } from "james/search/criterion";

const PREFIX = "ClientTable";

const classes = {
  filterField: `${PREFIX}-filterField`,
};

const Root = styled("div")(() => ({
  [`& .${classes.filterField}`]: {
    minWidth: 180,
  },
}));

const initialQuery = new Query({
  limit: 10,
  offset: 0,
  sorting: [],
});

export function Table() {
  const { authContext } = useApplicationContext();
  const [openKYCDialog, setOpenKYCDialog] = useState(false);
  const [openComplianceDashboard, setOpenComplianceDashboard] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client>();
  const [registrationDateTo, setRegistrationDateTo] = useState<
    string | undefined
  >(undefined);
  const [registrationDateFrom, setRegistrationDateFrom] = useState<
    string | undefined
  >(undefined);
  const [kycDateTo, setKYCDateTo] = useState<string | undefined>(undefined);
  const [kycDateFrom, setKYCDateFrom] = useState<string | undefined>(undefined);
  const {
    searchClientsResponse,
    searchClientsRequest,
    setSearchClientsRequest,
    loading: tableLoading,
  } = useClientRepositorySearchClients({
    context: authContext,
    query: new Query(initialQuery),
    criteria: {
      kycStatus: TextListCriterion([
        ClientKYCStatus.VerificationInProgressStatus,
      ]),
      clientKind: TextListCriterion([
        ClientKind.IndividualType,
        ClientKind.CompanyType,
      ]),
    },
  });

  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  return (
    <Root>
      <BPTable
        loading={tableLoading}
        height={window.innerHeight - 138 - noticeBannerHeight}
        title="Clients"
        singleSelect
        onSingleSelectChange={(data) =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setSelectedClient(data ? (data as any) : undefined)
        }
        data={searchClientsResponse.records}
        columns={[
          {
            label: "Name",
            field: "name",
            accessor: (data) => (
              <Typography
                variant="body1"
                id={`clientNameColumnCell-${(data as Client).id}`}
                children={(data as Client).name}
              />
            ),
          },
          {
            label: "Kind",
            field: "clientKind",
          },
          {
            label: "KYC Status",
            field: "kycStatus",
          },
          {
            label: "Registration Date",
            field: "registrationDate",
            accessor: (data) => (
              <Typography
                variant="body1"
                id={`registrationDateColumnCell-${(data as Client).id}`}
                children={dayjs((data as Client).registrationDate).format(
                  "YYYY-MM-DD, HH:mm A",
                )}
              />
            ),
          },
          {
            label: "KYC Date",
            field: "kycVerificationDate",
            accessor: (data) => (
              <Typography
                variant="body1"
                id={`kycVerificationDateColumnCell-${(data as Client).id}`}
                children={
                  (data as Client).kycStatus ===
                    ClientKYCStatus.VerifiedStatus &&
                  dayjs((data as Client).kycVerificationDate).format(
                    "YYYY-MM-DD, HH:mm A",
                  )
                }
              />
            ),
          },
        ]}
        query={searchClientsRequest.query}
        onQueryChange={(query: Query) =>
          setSearchClientsRequest({
            ...searchClientsRequest,
            query,
          })
        }
        totalNoRecords={searchClientsResponse.total}
        toolBarControls={(() => {
          if (selectedClient) {
            return [
              <Tooltip title="View Details">
                <span>
                  <IconButton
                    id="clientTable-viewDetails-button"
                    disabled={tableLoading}
                    size="small"
                    onClick={() => {
                      if (
                        selectedClient.clientKind === ClientKind.IndividualType
                      ) {
                        setOpenKYCDialog(true);
                      } else {
                        setOpenComplianceDashboard(true);
                      }
                    }}
                  >
                    <KYCDialogIcon />
                  </IconButton>
                </span>
              </Tooltip>,
            ];
          }
          return [
            <Tooltip title="Reload">
              <span>
                <IconButton
                  id="clientTable-reload-button"
                  disabled={tableLoading}
                  size="small"
                  onClick={() =>
                    setSearchClientsRequest({
                      ...searchClientsRequest,
                      query: new Query(initialQuery),
                    })
                  }
                >
                  <ReloadIcon />
                </IconButton>
              </span>
            </Tooltip>,
          ];
        })()}
        filters={[
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id="clientTable-kycStatus-autocomplete"
            disabled={tableLoading}
            multiple
            options={[
              ClientKYCStatus.VerifiedStatus,
              ClientKYCStatus.VerificationInProgressStatus,
              ClientKYCStatus.Outstanding,
            ]}
            defaultValue={[ClientKYCStatus.VerificationInProgressStatus]}
            filterSelectedOptions
            onChange={(
              _: React.SyntheticEvent<Element, Event>,
              value: ClientKYCStatus[],
            ) => {
              if (value.length) {
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  criteria: {
                    ...searchClientsRequest.criteria,
                    kycStatus: TextListCriterion(value),
                  },
                  query: new Query(initialQuery),
                });
              } else {
                delete searchClientsRequest.criteria.kycStatus;
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  query: new Query(initialQuery),
                });
              }
            }}
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <CancelIcon
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.filterField}
                label="KYC Status"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                placeholder="Start Typing..."
              />
            )}
          />,
          <TextField
            id="clientTable-name-textfield"
            label="Name"
            variant="outlined"
            margin="dense"
            onChange={(e) => {
              if (e.target.value !== "") {
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  criteria: {
                    ...searchClientsRequest.criteria,
                    name: TextSubstringCriterion(e.target.value),
                  },
                  query: new Query(initialQuery),
                });
              } else {
                delete searchClientsRequest.criteria.name;
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  query: new Query(initialQuery),
                });
              }
            }}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id="clientTable-kind-autocomplete"
            disabled={tableLoading}
            multiple
            options={[ClientKind.CompanyType, ClientKind.IndividualType]}
            defaultValue={[ClientKind.IndividualType, ClientKind.CompanyType]}
            filterSelectedOptions
            onChange={(
              _: React.SyntheticEvent<Element, Event>,
              value: ClientKind[],
            ) => {
              if (value.length) {
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  criteria: {
                    ...searchClientsRequest.criteria,
                    clientKind: TextListCriterion(value),
                  },
                  query: new Query(initialQuery),
                });
              } else {
                delete searchClientsRequest.criteria.clientKind;
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  query: new Query(initialQuery),
                });
              }
            }}
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <CancelIcon
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.filterField}
                label="Kind"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                placeholder="Start Typing..."
              />
            )}
          />,
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2,1fr)",
              gap: (theme) => theme.spacing(2),
              gridGap: (theme) => theme.spacing(2),
            }}
          >
            <DateField
              label="Registration Date From"
              id={"clientsTable-regDateFromFilter-formfield"}
              disabled={tableLoading}
              value={registrationDateFrom ? dayjs(registrationDateFrom) : null}
              renderInput={(textFieldProps: TextFieldProps) => (
                <TextField
                  {...textFieldProps}
                  fullWidth={true}
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={(newValue) => {
                if (!(newValue && dateIsValid(newValue))) {
                  setRegistrationDateFrom(undefined);
                } else {
                  setRegistrationDateFrom(
                    newValue ? newValue.format() : undefined,
                  );
                  setSearchClientsRequest({
                    ...searchClientsRequest,
                    criteria: {
                      ...searchClientsRequest.criteria,
                      registrationDate: DateRangeCriterion(
                        newValue
                          ? {
                              date: newValue.startOf("day").format(),
                              inclusive: true,
                              ignore: false,
                            }
                          : undefined,
                        registrationDateTo
                          ? {
                              date: dayjs(registrationDateTo as string)
                                .endOf("day")
                                .format(),
                              inclusive: true,
                              ignore: false,
                            }
                          : undefined,
                      ),
                    },
                  });
                }
              }}
            />
            <DateField
              label="Registration Date To"
              id={"clientsTable-regDateToFilter-formfield"}
              value={registrationDateTo ? dayjs(registrationDateTo) : null}
              disabled={tableLoading}
              renderInput={(textFieldProps: TextFieldProps) => (
                <TextField
                  {...textFieldProps}
                  fullWidth={true}
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={(newValue) => {
                if (!(newValue && dateIsValid(newValue))) {
                  setRegistrationDateTo(undefined);
                } else {
                  setRegistrationDateTo(
                    newValue ? newValue.format() : undefined,
                  );
                  setSearchClientsRequest({
                    ...searchClientsRequest,
                    criteria: {
                      ...searchClientsRequest.criteria,
                      registrationDate: DateRangeCriterion(
                        registrationDateFrom
                          ? {
                              date: dayjs(registrationDateFrom as string)
                                .startOf("day")
                                .format(),
                              inclusive: true,
                              ignore: false,
                            }
                          : undefined,
                        newValue
                          ? {
                              date: newValue.endOf("day").format(),
                              inclusive: true,
                              ignore: false,
                            }
                          : undefined,
                      ),
                    },
                  });
                }
              }}
            />
          </Box>,
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2,1fr)",
              gap: (theme) => theme.spacing(2),
              gridGap: (theme) => theme.spacing(2),
            }}
          >
            <DateField
              label="KYC'd Date From"
              id={"clientsTable-kycDateFromFilter-formfield"}
              disabled={tableLoading}
              value={kycDateFrom ? dayjs(kycDateFrom) : null}
              renderInput={(textFieldProps: TextFieldProps) => (
                <TextField
                  {...textFieldProps}
                  fullWidth={true}
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={(newValue) => {
                if (!(newValue && dateIsValid(newValue))) {
                  setKYCDateFrom(undefined);
                } else {
                  setKYCDateFrom(newValue ? newValue.format() : undefined);
                  setSearchClientsRequest({
                    ...searchClientsRequest,
                    criteria: {
                      ...searchClientsRequest.criteria,
                      kycStatus: TextListCriterion([
                        ClientKYCStatus.VerifiedStatus,
                      ]),
                      kycVerificationDate: DateRangeCriterion(
                        newValue
                          ? {
                              date: newValue.startOf("day").format(),
                              inclusive: true,
                              ignore: false,
                            }
                          : undefined,
                        kycDateTo
                          ? {
                              date: dayjs(kycDateTo as string)
                                .endOf("day")
                                .format(),
                              inclusive: true,
                              ignore: false,
                            }
                          : undefined,
                      ),
                    },
                  });
                }
              }}
            />
            <DateField
              label="KYC'd Date To"
              id={"clientsTable-kycDateToFilter-formfield"}
              value={kycDateTo ? dayjs(kycDateTo) : null}
              disabled={tableLoading}
              renderInput={(textFieldProps: TextFieldProps) => (
                <TextField
                  {...textFieldProps}
                  fullWidth={true}
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={(newValue) => {
                if (!(newValue && dateIsValid(newValue))) {
                  setKYCDateTo(undefined);
                } else {
                  setKYCDateTo(newValue ? newValue.format() : undefined);
                  setSearchClientsRequest({
                    ...searchClientsRequest,
                    criteria: {
                      ...searchClientsRequest.criteria,
                      kycStatus: TextListCriterion([
                        ClientKYCStatus.VerifiedStatus,
                      ]),
                      kycVerificationDate: DateRangeCriterion(
                        kycDateFrom
                          ? {
                              date: dayjs(kycDateFrom as string)
                                .startOf("day")
                                .format(),
                              inclusive: true,
                              ignore: false,
                            }
                          : undefined,
                        newValue
                          ? {
                              date: newValue.endOf("day").format(),
                              inclusive: true,
                              ignore: false,
                            }
                          : undefined,
                      ),
                    },
                  });
                }
              }}
            />
          </Box>,
        ]}
      />
      {openComplianceDashboard && selectedClient && (
        <Dashboard
          open={openComplianceDashboard}
          closeDialog={() => setOpenComplianceDashboard(false)}
          client={selectedClient}
          refresh={() =>
            setSearchClientsRequest({
              ...searchClientsRequest,
            })
          }
        />
      )}
      {openKYCDialog && selectedClient && (
        <IndividualClientDetailsDialog
          open={openKYCDialog}
          closeDialog={() => setOpenKYCDialog(false)}
          clientID={selectedClient.id}
          refresh={() =>
            setSearchClientsRequest({
              ...searchClientsRequest,
            })
          }
        />
      )}
    </Root>
  );
}
