import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import {
  Close as CloseIcon,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Warning,
} from "@mui/icons-material";
import { TextField, TextNumField } from "components/FormFields";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { Model as StellarAccountViewModel } from "james/views/stellarAccountView";
import { useSnackbar } from "notistack";
import cx from "classnames";
import { Amount } from "components/Ledger/Amount";
import { Amount as LedgerAmount, Token } from "james/ledger";
import { useValidatedForm } from "hooks/useForm";
import { TransferFeeGenerator } from "james/remuneration/TransferFeeGenerator";
import { useCurrentAPICall, useIsMounted } from "hooks";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { TextExactCriterion } from "james/search/criterion";
import {
  formUpdaterSpecs,
  FormUpdaterSpecsType,
  State,
  UnfilteredValidationResult,
  validationFunc,
} from "views/Accounts/components/OffPlatformTransferDialog/Validation";
import { SkeletonLoader } from "components/SkeletonLoader";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { useAccountContext } from "context/Account/Account";
import { LedgerIDIdentifier } from "james/search/identifier";
import { JSONRPCCallAbortedError } from "utilities/network/jsonRPCRequest";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { ValidationResult } from "common/validation";
import { ManagingCompanyClientName } from "const";
import { OffPlatformTransferConfirmationDialog } from "views/Accounts/components/OffPlatformTransferDialog/components/OffPlatformTransferConfirmationDialog";
import {
  Reader,
  Model as RecipientModel,
} from "james/views/stellarRecipientView";
import { RecipientState } from "james/views/stellarRecipientView/Model";

const PREFIX = "OffPlatformTransferMainDialog";

const classes = {
  dialogTitleContent: `${PREFIX}-dialogTitleContent`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  miniLogoWrapper: `${PREFIX}-miniLogoWrapper`,
  boldText: `${PREFIX}-boldText`,
  disabledTextColor: `${PREFIX}-disabledTextColor`,
  amountTextFieldHelperText: `${PREFIX}-amountTextFieldHelperText`,
  feeAccAndBalancesSection: `${PREFIX}-feeAccAndBalancesSection`,
  feeDetailsWrapper: `${PREFIX}-feeDetailsWrapper`,
  startAdornmentText: `${PREFIX}-startAdornmentText`,
  helperTextLayout: `${PREFIX}-helperTextLayout`,
  errorHelperTextLayout: `${PREFIX}-errorHelperTextLayout`,
  errorColor: `${PREFIX}-errorColor`,
  warningIcon: `${PREFIX}-warningIcon`,
  disabledText: `${PREFIX}-disabledText`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.dialogTitleContent}`]: {
    alignItems: "center",
  },

  [`& .${classes.dialogTitle}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.miniLogoWrapper}`]: {
    height: 32,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },

  [`& .${classes.boldText}`]: {
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${classes.disabledTextColor}`]: {
    color: theme.palette.text.disabled,
  },

  [`& .${classes.amountTextFieldHelperText}`]: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(4),
    color: theme.palette.text.secondary,
  },

  [`& .${classes.feeAccAndBalancesSection}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.feeDetailsWrapper}`]: {
    display: "flex",
  },

  [`& .${classes.startAdornmentText}`]: {
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(0.5),
  },

  [`& .${classes.helperTextLayout}`]: {
    display: "grid",
    gridTemplateColumns: "65px auto",
  },

  [`& .${classes.errorHelperTextLayout}`]: {
    display: "flex",
    color: theme.palette.error.main,
  },

  [`& .${classes.errorColor}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.warningIcon}`]: {
    width: 22,
    height: 19,
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.disabledText}`]: {},
}));

// Todo: This dialog should stop depending on props and rather open based on url routes
interface OffPlatformTransferMainDialogProps {
  accountID: string; // The account that you are doing the transfer from
  token: Token; // The token that is being transferred
  open: boolean;
  onClose: () => void;
}

export const OffPlatformTransferMainDialog: React.FunctionComponent<
  OffPlatformTransferMainDialogProps
> = (props) => {
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const [loading, setLoading] = useState(true);
  const [tokenViewModel, setTokenViewModel] = useState<
    undefined | LedgerTokenViewModel
  >(undefined);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const [accountModel, setAccountModel] = useState<
    StellarAccountViewModel | undefined
  >(undefined);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { authContext } = useApplicationContext();
  const calculateTransferFeesTimeout = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );
  const isMounted = useIsMounted();
  const [feeLoadingInProgress, setFeeLoadingInProgress] = useState(false);
  const [isUserSignatoryOnAccount, setIsUserSignatoryOnAccount] =
    useState(false);
  const [hideFeeSection, setHideFeeSection] = useState(false);
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { stellarAccountContext } = useAccountContext();
  const [recipients, setRecipients] = useState<RecipientModel[]>([]);

  const [formState, validationResult, formUpdater, validationInProgress] =
    useValidatedForm<State, FormUpdaterSpecsType>(
      validationFunc,
      undefined,
      formUpdaterSpecs,
      {
        userID: "",
        transferAmount: new LedgerAmount(),
        transferTokenAvailableBalance: new LedgerAmount(),
        mZARBalance: new LedgerAmount(),
        transferFees: [],
        selectedRecipient: null,
        ledgerAccountModel: new StellarAccountViewModel(),
      },

      new Set<string>(),
    );

  useEffect(() => {
    (async () => {
      if (!isMounted()) {
        return;
      }

      if (stellarAccountContext.loading) {
        return;
      }

      if (stellarAccountContext.error) {
        console.error(`initialization error: ${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialization Error: ${stellarAccountContext.error}`,
          {
            variant: "error",
          },
        );

        // close the dialog
        props.onClose();
      }

      // if there was an error retrieving the accounts from the stellarContext
      // show a snackbar and then close the dialog
      if (stellarAccountContext.error) {
        console.error(`initialization error: ${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialization Error: ${stellarAccountContext.error}`,
          { variant: "error" },
        );

        // close the dialog
        props.onClose();
      }

      formUpdater.userID(authContext.userID);

      // retrieve the on platform token
      try {
        const ledgerTokenViewModel = await getLedgerTokenViewModel(props.token);
        if (!isMounted()) {
          return;
        }
        setTokenViewModel(ledgerTokenViewModel);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving on platform token view model: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `Error retrieving on platform token view model: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
        return;
      }

      // retrieve the stellar account view model
      const accountViewModel = stellarAccountContext.accounts.find(
        (a) => a.id === props.accountID,
      );

      // if the account is not found
      if (!accountViewModel) {
        enqueueSnackbar(`Unexpected error finding the account view model`, {
          variant: "error",
        });
        return;
      }

      setAccountModel(accountViewModel);

      if (
        accountViewModel.accountOwnerClientName.includes(
          ManagingCompanyClientName,
        )
      ) {
        setHideFeeSection(true);
      }

      // retrieve the token balance from the account view model
      const tokenBalance = accountViewModel.getTokenBalance(props.token);
      if (tokenBalance) {
        formUpdater.transferTokenAvailableBalance(
          tokenBalance.availableBalance(),
        );
      }

      // retrieve the mZAR token from the account view model
      const mZARBalance = accountViewModel.balances.find(
        (v) =>
          v.tokenViewModel.token.code.includes("mZAR") &&
          v.tokenViewModel.issuer.includes(ManagingCompanyClientName),
      );
      if (mZARBalance) {
        formUpdater.mZARBalance(mZARBalance.availableBalance());
      }

      // check if the user is a signatory on the account
      try {
        if (isMounted()) {
          setIsUserSignatoryOnAccount(
            await stellarAccountContext.checkUserSignatoryOnAccount(
              LedgerIDIdentifier(accountViewModel.ledgerID),
            ),
          );
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user is signatory on account: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Determining Signatory Status", {
          variant: "error",
        });
      }
    })().finally(() => setLoading(false));
  }, [
    stellarAccountContext.loading,
    stellarAccountContext.error,
    isMounted,
    formUpdater,
    enqueueSnackbar,
    props.accountID,
    props.token,
  ]);

  useEffect(() => {
    if (!accountModel) {
      return;
    }

    (async () => {
      try {
        const recipientsModels = (
          await Reader.Read({
            context: authContext,
            criteria: {
              ownerID: TextExactCriterion(accountModel.ownerID),
              state: TextExactCriterion(RecipientState.Active),
            },
          })
        ).models;

        if (recipientsModels.length < 1) {
          enqueueSnackbar("no recipients found", {
            variant: "error",
          });
        }

        setRecipients(recipientsModels);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving recipients: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `Error Retrieving Recipients: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }
    })();
  }, [accountModel]);

  const [
    isCurrentGenerateTransferFeesAPICall,
    initGenerateTransferFeesAPICall,
  ] = useCurrentAPICall();
  const handleCalculateTransferFee = (transferAmount: LedgerAmount) => {
    if (hideFeeSection) {
      return;
    }

    // indicate that fee calculation is in progress
    setFeeLoadingInProgress(true);

    // initialise the API call
    const { apiCallID, abortController } = initGenerateTransferFeesAPICall();

    clearTimeout(calculateTransferFeesTimeout.current);
    calculateTransferFeesTimeout.current = setTimeout(async () => {
      try {
        const fees = (
          await TransferFeeGenerator.GenerateOffPlatformTransferFees(
            {
              context: authContext,
              transferAmount,
              fromAccountOwnerID: props.accountID,
            },
            { signal: abortController.signal },
          )
        ).fees;
        if (isCurrentGenerateTransferFeesAPICall(apiCallID) && isMounted()) {
          formUpdater.transferFees(fees);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        if (err.code === JSONRPCCallAbortedError.ErrorCode) {
          return;
        }
        if (isCurrentGenerateTransferFeesAPICall(apiCallID) && isMounted()) {
          errorContextDefaultErrorFeedback(err);
        }
      }
      if (isCurrentGenerateTransferFeesAPICall(apiCallID) && isMounted()) {
        setFeeLoadingInProgress(false);
      }
    }, 400);
  };

  return (
    <Dialog fullScreen={isMobile} open={props.open}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.custom.spaceblue,
        }}
      >
        <Grid
          className={classes.dialogTitleContent}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item className={classes.dialogTitle}>
            <div className={classes.miniLogoWrapper}>
              <img alt="" width={40} src={meshMiniLogo} />
            </div>
            <Typography
              variant="h5"
              children={`Transfer ${
                isMobile
                  ? ""
                  : `from account: ${accountModel ? accountModel.number : ""}`
              }`}
            />
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={props.onClose}
              disabled={loading}
              id="transferMainDialog-close-button"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: theme.palette.custom.midnight,
          boxShadow: "0px -10px 12px -14px #000 inset",
        }}
        className="meshScroll"
      >
        <StyledBox
          sx={{
            p: 3,
          }}
        >
          <SkeletonLoader loading={loading}>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: theme.spacing(0, 0, 4, 0),
              }}
              variant="h4"
              children="Transfer Information"
            />
          </SkeletonLoader>
          {/* Transfer Amount Text-Num-Field */}
          <SkeletonLoader
            loading={loading}
            width={isMobile ? "100%" : ""}
            sx={{
              margin: theme.spacing(3, 0),
            }}
          >
            <TextNumField
              fullWidth={isMobile}
              id={"offPlatformTransferMainDialog-amount-formfield"}
              disallowNegative
              sx={[!isMobile && { width: 304 }]}
              noDecimalPlaces={7}
              label={"Amount"}
              value={formState.transferAmount.value}
              onChange={(e) => {
                if (tokenViewModel) {
                  const transferAmount = tokenViewModel.token.newAmountOf(
                    e.target.value.toString(),
                  );
                  formUpdater.transferAmount(transferAmount);
                  handleCalculateTransferFee(transferAmount);
                }
              }}
              InputProps={{
                startAdornment: (() => {
                  if (
                    tokenViewModel?.tokenCategory.includes(
                      TokenCategory.RightsToFiatCurrency,
                    ) ||
                    tokenViewModel?.tokenCategory.includes(
                      TokenCategory.CryptoCurrency,
                    )
                  ) {
                    return (
                      <Typography
                        className={classes.startAdornmentText}
                        variant="subtitle1"
                        children={tokenViewModel?.token.code}
                      />
                    );
                  }
                  return undefined;
                })(),
                endAdornment: (() => {
                  if (
                    tokenViewModel?.tokenCategory.includes(
                      TokenCategory.InstrumentStablecoin,
                    ) ||
                    tokenViewModel?.tokenCategory.includes(
                      TokenCategory.DigitalInstrument,
                    )
                  ) {
                    return (
                      <Typography
                        className={classes.startAdornmentText}
                        variant="subtitle1"
                        children={tokenViewModel?.token.code}
                      />
                    );
                  }
                  return undefined;
                })(),
              }}
              error={!!validationResult.fieldValidations.transferAmount}
            />
            {/* Transfer Amount helper text */}
            <Box
              sx={[
                {
                  color: theme.palette.text.secondary,
                  margin: theme.spacing(0, 0, 3, 2),
                },
                !!validationResult.fieldValidations.transferAmount && {
                  color: theme.palette.error.main,
                },
              ]}
            >
              {(() => {
                // if there is an error for this formfield  display error helperText
                if (validationResult.fieldValidations.transferAmount) {
                  if (
                    validationResult.fieldValidations.transferAmount.includes(
                      "Insufficient",
                    )
                  ) {
                    return (
                      <div className={classes.errorHelperTextLayout}>
                        <Typography variant="caption">
                          Insufficient balance:&nbsp;
                        </Typography>
                        <Amount
                          reverse={
                            tokenViewModel?.tokenCategory.includes(
                              TokenCategory.InstrumentStablecoin,
                            ) ||
                            tokenViewModel?.tokenCategory.includes(
                              TokenCategory.DigitalInstrument,
                            )
                          }
                          codeTypographyProps={{
                            variant: "caption",
                          }}
                          valueTypographyProps={{
                            variant: "caption",
                          }}
                          formatTextNumOpts={{ noDecimalPlaces: 7 }}
                          amount={formState.transferTokenAvailableBalance}
                        />
                      </div>
                    );
                  }
                  return (
                    <Typography
                      variant="caption"
                      children={
                        validationResult.fieldValidations.transferAmount
                      }
                    />
                  );
                }

                return (
                  <div className={classes.helperTextLayout}>
                    <Typography variant="caption">Available:</Typography>
                    <Amount
                      reverse={
                        tokenViewModel?.tokenCategory.includes(
                          TokenCategory.InstrumentStablecoin,
                        ) ||
                        tokenViewModel?.tokenCategory.includes(
                          TokenCategory.DigitalInstrument,
                        )
                      }
                      valueTypographyProps={{
                        variant: "caption",
                      }}
                      codeTypographyProps={{
                        variant: "caption",
                      }}
                      formatTextNumOpts={{ noDecimalPlaces: 7 }}
                      amount={formState.transferTokenAvailableBalance}
                    />
                  </div>
                );
              })()}
            </Box>
          </SkeletonLoader>
          <Box>
            <SkeletonLoader loading={loading}>
              <Typography
                sx={{
                  paddingBottom: theme.spacing(1),
                }}
                variant="h6"
                children="Recipient"
              />
            </SkeletonLoader>
            <SkeletonLoader loading={loading} width={isMobile ? "100%" : ""}>
              <Autocomplete
                fullWidth={isMobile}
                id="transferMainDialog-recipientAccountAddressType-autocomplete"
                disableClearable
                options={recipients}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => {
                  if ((value?.label || "") === "") {
                    return true;
                  }
                  return option.label === value.label;
                }}
                value={
                  formState.selectedRecipient === null
                    ? new RecipientModel()
                    : recipients.filter(
                        (m) => m.label === formState.selectedRecipient?.label,
                      )[0]
                }
                sx={[
                  { marginBottom: theme.spacing(2) },
                  !isMobile && { width: 304 },
                ]}
                onChange={(e, selectedRecipient: RecipientModel | null) => {
                  if (selectedRecipient) {
                    formUpdater.selectedRecipient(selectedRecipient);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Recipient Label"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </SkeletonLoader>
            <SkeletonLoader loading={loading} width={isMobile ? "100%" : ""}>
              <TextField
                fullWidth={isMobile}
                id="offPlatformTransferMainDialog-recipient-memo-formfield"
                disabled
                sx={[
                  { marginBottom: theme.spacing(2) },
                  !isMobile && { width: "306px" },
                  !!validationResult.fieldValidations.accountID && {
                    marginBottom: theme.spacing(1),
                  },
                ]}
                value={formState.selectedRecipient?.defaultMemo}
                error={validationResult.fieldValidations.selectedRecipient?.includes(
                  "Memo",
                )}
                helperText={validationResult.fieldValidations.selectedRecipient}
                label="Recipient Memo"
              />
            </SkeletonLoader>
          </Box>
          {hideFeeSection && (
            <TransferFees
              loading={loading}
              feeLoadingInProgress={feeLoadingInProgress}
              formState={formState}
              validationResult={validationResult}
            />
          )}
        </StyledBox>
        {/* Transfer Button Tablet and Desktop */}
        {!isMobile && (
          <Tooltip
            placement="top"
            title={
              UnfilteredValidationResult.fieldValidations.transferHoverText
                ? UnfilteredValidationResult.fieldValidations.transferHoverText
                : !isUserSignatoryOnAccount
                  ? "User is not a signatory on the account"
                  : ""
            }
          >
            <Box
              sx={{
                px: 3,
                mb: 2,
              }}
            >
              {/*box needed for tooltip*/}
              <SkeletonLoader loading={loading}>
                <Button
                  id="offPlatformTransferMainDialog-transfer-button"
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={
                    !validationResult.valid ||
                    validationInProgress ||
                    !isUserSignatoryOnAccount ||
                    !formState.selectedRecipient
                  }
                  sx={{
                    height: "36px",
                  }}
                  onClick={() => {
                    if (validationResult.valid) {
                      setOpenConfirmationDialog(true);
                    }
                  }}
                  children="Transfer"
                />
              </SkeletonLoader>
            </Box>
          </Tooltip>
        )}
        {/* Fee Collapse component controls section */}
      </DialogContent>
      {isMobile && (
        <DialogTitle
          sx={{
            padding: theme.spacing(3, 3, 5, 3),
          }}
        >
          <Tooltip
            placement="top"
            title={
              UnfilteredValidationResult.fieldValidations.transferHoverText
                ? UnfilteredValidationResult.fieldValidations.transferHoverText
                : !isUserSignatoryOnAccount
                  ? "User is not a signatory on the account"
                  : ""
            }
          >
            <Box>
              <SkeletonLoader loading={loading} width="100%">
                <Button
                  fullWidth
                  id="offPlatformTransferMainDialog-transfer-button"
                  color="primary"
                  variant="contained"
                  sx={{
                    height: 48,
                    "&.Mui-disabled": {
                      pointerEvents: "auto",
                    },
                  }}
                  disabled={
                    !validationResult.valid ||
                    validationInProgress ||
                    !isUserSignatoryOnAccount ||
                    !formState.selectedRecipient
                  }
                  onClick={() => {
                    if (validationResult.valid) {
                      setOpenConfirmationDialog(true);
                    }
                  }}
                  children="Transfer"
                />
              </SkeletonLoader>
            </Box>
          </Tooltip>
        </DialogTitle>
      )}
      {openConfirmationDialog && (
        <OffPlatformTransferConfirmationDialog
          open={openConfirmationDialog}
          onClose={() => setOpenConfirmationDialog(false)}
          onTransferSubmissionComplete={() => props.onClose()}
          recipientModel={
            formState.selectedRecipient
              ? formState.selectedRecipient
              : new RecipientModel()
          }
          transferAmount={formState.transferAmount}
          fromAccountID={props.accountID}
          transferFeeSection={
            <TransferFees
              loading={loading}
              feeLoadingInProgress={feeLoadingInProgress}
              formState={formState}
              validationResult={validationResult}
            />
          }
          hideTransferFee={hideFeeSection}
        />
      )}
    </Dialog>
  );
};

interface OffPlatformTransferFeesProps {
  loading: boolean;
  feeLoadingInProgress: boolean;
  formState: State;
  validationResult: ValidationResult;
  tokenViewModel?: LedgerTokenViewModel;
  accountModel?: StellarAccountViewModel;
}

const TransferFees = ({
  loading,
  formState,
  feeLoadingInProgress,
  validationResult,
  tokenViewModel,
  accountModel,
}: OffPlatformTransferFeesProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const [expandFeeSection, setExpandFeeSection] = useState(false);

  return (
    <Box>
      {!loading && (
        <SkeletonLoader loading={loading}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              id="offPlatformTransferMainDialog-expand-button"
              sx={{
                mr: 1,
              }}
              onClick={() => setExpandFeeSection(!expandFeeSection)}
              size="small"
            >
              {!expandFeeSection && <KeyboardArrowDown color="primary" />}
              {expandFeeSection && <KeyboardArrowUp color="primary" />}
            </IconButton>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography
                color="textSecondary"
                variant="body1"
                sx={(theme) => ({
                  minWidth: "92px",
                  mr: 1,
                  ...(feeLoadingInProgress && {
                    color: theme.palette.action.disabled,
                  }),
                })}
                children="Transfer Fee:"
              />
              <Box sx={{ display: "flex" }}>
                {formState.transferFees && (
                  <Amount
                    valueTypographyProps={{
                      className: cx(
                        {
                          [classes.errorColor]:
                            validationResult.fieldValidations.transferFees?.includes(
                              "Insufficient",
                            ),
                        },
                        { [classes.disabledText]: feeLoadingInProgress },
                      ),
                    }}
                    codeTypographyProps={{
                      className: cx(
                        {
                          [classes.errorColor]:
                            validationResult.fieldValidations.transferFees?.includes(
                              "Insufficient",
                            ),
                        },
                        { [classes.disabledText]: feeLoadingInProgress },
                      ),
                    }}
                    formatTextNumOpts={{
                      noDecimalPlaces:
                        isMobile &&
                        validationResult.fieldValidations.transferFees
                          ? 5
                          : 7,
                    }}
                    amount={(() => {
                      const transferFee = formState.transferFees.reduce(
                        (total, current) =>
                          current
                            .feeAmount()
                            .setValue(
                              current.feeAmount().value.plus(total.value),
                            ),
                        new LedgerAmount(),
                      );
                      return transferFee.isUndefined()
                        ? formState.mZARBalance.setValue("0")
                        : transferFee;
                    })()}
                  />
                )}
                {formState.transferFees &&
                  tokenViewModel &&
                  (tokenViewModel.tokenCategory.includes(
                    TokenCategory.DigitalInstrument,
                  ) ||
                    tokenViewModel.tokenCategory.includes(
                      TokenCategory.InstrumentStablecoin,
                    )) && (
                    <Typography
                      color="primary"
                      component="span"
                      variant="caption"
                      children="*"
                    />
                  )}
                {validationResult.fieldValidations.transferFees && (
                  <Warning
                    className={cx(classes.errorColor, classes.warningIcon)}
                  />
                )}
                {feeLoadingInProgress && (
                  <CircularProgress
                    sx={(theme) => ({ marginRight: theme.spacing(0.5) })}
                    size={15}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </SkeletonLoader>
      )}
      <Collapse
        sx={{
          ml: 5,
        }}
        in={expandFeeSection}
      >
        {/* why these fees link */}
        <Box
          component={isMobile ? Button : Link}
          variant={isMobile ? "text" : "body1"}
          underline={"hover"}
          sx={(theme) => ({
            cursor: "pointer",
            color: feeLoadingInProgress
              ? theme.palette.text.disabled
              : theme.palette.secondary.main,
            padding: {
              sm: 0,
              xs: theme.spacing(2, 2, 1, 0),
            },
          })}
          target="_blank"
          href="https://mesh.trade/fees"
          children="Why these fees?"
        />
        {/* Fee Acc  and available balance section */}
        <div className={classes.feeAccAndBalancesSection}>
          <div className={classes.feeDetailsWrapper}>
            <Typography
              variant="caption"
              color="textSecondary"
              className={cx({ [classes.disabledText]: feeLoadingInProgress })}
            >
              Fee Acc:&nbsp;
            </Typography>
            <Typography
              variant="caption"
              component="span"
              className={cx({ [classes.disabledText]: feeLoadingInProgress })}
            >
              {accountModel
                ? `${accountModel.accountOwnerGroupName} ${accountModel.category}`
                : ""}
            </Typography>
          </div>
          <div className={classes.feeDetailsWrapper}>
            <Typography
              variant="caption"
              sx={(theme) => ({
                color: feeLoadingInProgress
                  ? theme.palette.text.disabled
                  : theme.palette.text.secondary,
              })}
            >
              Available:&nbsp;
            </Typography>
            {formState.mZARBalance && (
              <Amount
                amount={formState.mZARBalance}
                valueTypographyProps={{
                  className: cx(
                    {
                      [classes.errorColor]:
                        validationResult.fieldValidations.transferFees?.includes(
                          "Insufficient",
                        ),
                    },
                    { [classes.disabledText]: feeLoadingInProgress },
                  ),
                  variant: "caption",
                }}
                codeTypographyProps={{
                  className: cx(
                    {
                      [classes.errorColor]:
                        validationResult.fieldValidations.transferFees?.includes(
                          "Insufficient",
                        ),
                    },
                    { [classes.disabledText]: feeLoadingInProgress },
                  ),
                  variant: "caption",
                }}
              />
            )}
            {validationResult.fieldValidations.transferFees?.includes(
              "Insufficient",
            ) &&
              !isMobile && (
                <Typography
                  className={cx(classes.errorColor, {
                    [classes.disabledText]: feeLoadingInProgress,
                  })}
                  color="textSecondary"
                  variant="caption"
                >
                  &nbsp;- Insufficient Funds
                </Typography>
              )}
          </div>
          {isMobile &&
            validationResult.fieldValidations.transferFees?.includes(
              "Insufficient",
            ) && (
              <Typography
                variant="caption"
                sx={(theme) => ({
                  padding: theme.spacing(0, 0, 1, 8),
                  color: feeLoadingInProgress
                    ? theme.palette.text.disabled
                    : theme.palette.error.main,
                })}
              >
                - Insufficient Funds
              </Typography>
            )}
        </div>
        {tokenViewModel &&
          (tokenViewModel.tokenCategory.includes(
            TokenCategory.DigitalInstrument,
          ) ||
            tokenViewModel.tokenCategory.includes(
              TokenCategory.InstrumentStablecoin,
            )) && (
            <Typography component="p" variant="caption">
              <Typography
                color="primary"
                component="span"
                variant="caption"
                children="*"
              />
              Fee was calculated using a quoted price and might change at the
              time of transacting.
            </Typography>
          )}
      </Collapse>
    </Box>
  );
};
