import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextFieldProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BPTable } from "components/Table";
import { Amount, LedgerAccountCategory } from "james/ledger";
import { NewSorting, Query } from "james/search/query";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  Close as CloseIcon,
  Receipt,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { DateField, TextField } from "components/FormFields";
import { Model, useRead } from "james/views/bankingDefundOrderView";
import { formatTextNum } from "utilities/number";
import dayjs from "dayjs";
import { DefundOrderState, DefundOrderStateController } from "james/banking";
import { useSnackbar } from "notistack";
import {
  TextExactCriterion,
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion/text";
import {
  DateRangeCriterion,
  DateRangeValue,
} from "james/search/criterion/date/Range";
import { AllDefundOrderStates } from "james/banking/defundOrder";
import { dateIsValid } from "utilities/date/dateIsValid";
import { DefundOrderStateChip } from "./Chips";
import { useAccountContext } from "context/Account/Account";
import { LedgerIDIdentifier } from "james/search/identifier";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";
import { TransactionTable } from "components/Ledger/Transaction";

const PREFIX = "Defunding";

const classes = {
  dialogTitleContent: `${PREFIX}-dialogTitleContent`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  textSearchField: `${PREFIX}-textSearchField`,
  dateFilterField: `${PREFIX}-dateFilterField`,
  iconButton: `${PREFIX}-iconButton`,
  statusSelectFilterField: `${PREFIX}-statusSelectFilterField`,
};

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("id", "desc")],
});

export function Defunding() {
  const { authContext } = useApplicationContext();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedDefundOrderViewModel, setSelectedDefundOrderViewModel] =
    useState<Model | undefined>(undefined);
  const { readResponse, loading, setReadRequest, readRequest } = useRead({
    context: authContext,
    query: new Query(initialQuery),
    criteria: {},
  });
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  const [orderStatusesCriterion, setOrderStatusesCriterion] = useState<
    DefundOrderState[]
  >([]);
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [
          { number: TextSubstringCriterion(textSearchCriterionTextField) },
          {
            bankReference: TextSubstringCriterion(textSearchCriterionTextField),
          },
        ],
      });
    }
  }, [textSearchCriterionTextField]);
  const [dateTimeCriterionFrom, setDateTimeCriterionFrom] = useState<
    DateRangeValue | undefined
  >(undefined);
  const [dateTimeCriterionTo, setDateTimeCriterionTo] = useState<
    DateRangeValue | undefined
  >(undefined);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = {};

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    if (orderStatusesCriterion.length) {
      criteria.state = TextListCriterion(orderStatusesCriterion);
    }

    if (dateTimeCriterionFrom || dateTimeCriterionTo) {
      criteria.creationDate = DateRangeCriterion(
        dateTimeCriterionFrom,
        dateTimeCriterionTo,
      );
    }

    setReadRequest({
      context: readRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [
    orderStatusesCriterion,
    textSearchCriterion,
    readRequest.context,
    setReadRequest,
    authContext,
    dateTimeCriterionFrom,
    dateTimeCriterionTo,
  ]);

  return (
    <>
      <BPTable
        loading={loading}
        height={window.innerHeight - 138 - noticeBannerHeight}
        title="Defunding Orders"
        data={readResponse.models}
        singleSelect
        onSingleSelectChange={(data) =>
          setSelectedDefundOrderViewModel(data as Model)
        }
        columns={[
          {
            label: "Number",
            field: "number",
          },
          {
            label: "Client Name",
            field: "clientName",
            sortable: false,
          },
          {
            label: "Account Number",
            field: "accountNumber",
            sortable: false,
          },
          {
            label: "Bank Ref",
            field: "bankReference",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => {
              const defundOrderModel = data as Model;
              return defundOrderModel.bankReference
                ? defundOrderModel.bankReference
                : "-";
            },
          },
          {
            label: "Fee Amount",
            field: "fee.value.float",
            sortable: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => {
              const feeAmount = new Amount(data.feeAmount as Amount);
              return formatTextNum(feeAmount.value, {
                addDecimalPadding: true,
              });
            },
          },
          {
            label: "Amount",
            field: "amount.value.float",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => {
              const amount = new Amount(data.amount as Amount);
              return formatTextNum(amount.value, { addDecimalPadding: true });
            },
          },
          {
            label: "State",
            field: "state",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => (
              <DefundOrderStateChip state={(data as Model).state} />
            ),
          },
          {
            label: "Express",
            field: "express",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => {
              if (data.express) {
                return (
                  <Chip
                    id="expressDefundOrderChip-yes-chip"
                    sx={(theme) => ({
                      backgroundColor: theme.palette.success.main,
                    })}
                    size="small"
                    label={"Yes"}
                  />
                );
              }
              return (
                <Chip
                  sx={(theme) => ({
                    backgroundColor: theme.palette.warning.main,
                  })}
                  id="expressDefundOrderChip-no-chip"
                  size="small"
                  label={"No"}
                />
              );
            },
          },
          {
            label: "Creation Date",
            field: "creationDate",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) =>
              dayjs(data.creationDate).format("YYYY/MM/DD hh:mm"),
          },
        ]}
        query={readRequest.query}
        onQueryChange={(query: Query) =>
          setReadRequest({
            ...readRequest,
            query,
          })
        }
        totalNoRecords={readResponse.total}
        toolBarControls={(() => {
          const controls: React.ReactNode[] = [];

          if (
            selectedDefundOrderViewModel &&
            selectedDefundOrderViewModel.state ===
              DefundOrderState.AwaitingSettlement
          ) {
            controls.push(
              <Tooltip title="Process defund">
                <span>
                  <IconButton
                    onClick={() => setDisplayDialog(true)}
                    id="defund-processDefund-button"
                    disabled={loading}
                    size="small"
                  >
                    <Receipt />
                  </IconButton>
                </span>
              </Tooltip>,
            );
          }

          controls.push(
            <Tooltip title="Refresh">
              <span>
                <IconButton
                  onClick={() =>
                    setReadRequest({
                      ...readRequest,
                      query: new Query(initialQuery),
                    })
                  }
                  id="defund-refresh-button"
                  disabled={loading}
                  size="small"
                >
                  <ReloadIcon />
                </IconButton>
              </span>
            </Tooltip>,
          );

          return controls;
        })()}
        filters={[
          <TextField
            id="defundOrdersTable-textFilter-textField"
            variant="outlined"
            margin="dense"
            className={classes.textSearchField}
            label="Search Number or Bank Reference"
            placeholder="Start Typing..."
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: textSearchCriterionTextField ? (
                <InputAdornment
                  position="end"
                  children={
                    <IconButton
                      size="small"
                      onClick={() => setTextSearchCriterionTextField("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                />
              ) : undefined,
            }}
            value={textSearchCriterionTextField}
            onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
          />,
          <DateField
            label="From"
            id="defundOrdersTable-dateRangeFromFilter-dateField"
            className={classes.dateFilterField}
            value={dateTimeCriterionFrom ? dateTimeCriterionFrom.date : null}
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setDateTimeCriterionFrom(undefined);
              } else {
                setDateTimeCriterionFrom(
                  newValue
                    ? {
                        date: newValue.startOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      }
                    : undefined,
                );
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (() => (
                    <>
                      {dateTimeCriterionFrom && (
                        <Tooltip title="Clear" placement="top">
                          <IconButton
                            className={classes.iconButton}
                            size="small"
                            onClick={() => setDateTimeCriterionFrom(undefined)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {textFieldProps.InputProps &&
                      textFieldProps.InputProps.endAdornment
                        ? textFieldProps.InputProps.endAdornment
                        : null}
                    </>
                  ))(),
                }}
              />
            )}
          />,
          <DateField
            label="To"
            id="defundOrdersTable-dateRangeToFilter-dateField"
            className={classes.dateFilterField}
            value={dateTimeCriterionTo ? dateTimeCriterionTo.date : null}
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setDateTimeCriterionTo(undefined);
              } else {
                setDateTimeCriterionTo(
                  newValue
                    ? {
                        date: dayjs(newValue).endOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      }
                    : undefined,
                );
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (() => (
                    <>
                      {dateTimeCriterionTo && (
                        <Tooltip title="Clear" placement="top">
                          <IconButton
                            className={classes.iconButton}
                            size="small"
                            onClick={() => {
                              setDateTimeCriterionTo(undefined);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {textFieldProps.InputProps &&
                      textFieldProps.InputProps.endAdornment
                        ? textFieldProps.InputProps.endAdornment
                        : null}
                    </>
                  ))(),
                }}
              />
            )}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id="defundOrdersTable-stateFilter-autocomplete"
            disabled={loading}
            multiple
            options={AllDefundOrderStates}
            filterSelectedOptions
            onChange={(_, value: DefundOrderState[]) =>
              setOrderStatusesCriterion(value)
            }
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <CancelIcon
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.statusSelectFilterField}
                label="State"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                placeholder={
                  orderStatusesCriterion.length ? undefined : "Select..."
                }
              />
            )}
          />,
        ]}
        expandRowComponent={{
          maxHeight: 300,
          component: (selectedRowData) => {
            const viewModel = selectedRowData as Model;
            return (
              <TransactionTable
                height={400}
                title={`Defund Order No ${viewModel.number}`}
                constantCriteria={{
                  "metaData.defundOrderID": TextExactCriterion(viewModel.id),
                }}
              />
            );
          },
        }}
      />
      {displayDialog && (
        <ProcessDefundRequest
          onClose={() => {
            setDisplayDialog(false);
            setReadRequest({
              ...readRequest,
              query: new Query(initialQuery),
            });
          }}
          open={displayDialog}
          model={new Model(selectedDefundOrderViewModel)}
        />
      )}
    </>
  );
}

interface ProcessDefundRequestProps {
  open: boolean;
  onClose: () => void;
  model: Model;
}

function ProcessDefundRequest(props: ProcessDefundRequestProps) {
  const theme = useTheme();
  const { errorContextErrorTranslator } = useErrorContext();
  const [loading, setLoading] = useState(true);
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [bankReference, setBankReferemce] = useState("");
  const [validationState, setValidationState] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }>({});
  const [isUserASignatoryOnAccount, setIsUserSignatoryOnAccount] =
    useState(false);
  const { stellarAccountContext } = useAccountContext();

  // determine if user is signatory on market infrastructure account
  useEffect(() => {
    (async () => {
      if (stellarAccountContext.loading) {
        return;
      }

      if (stellarAccountContext.error) {
        console.error(`initialisation error::${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialisation Error: ${stellarAccountContext.error}`,
          {
            variant: "error",
          },
        );

        // close the dialog
        props.onClose();
      }

      // search for the market infrastructure account
      const marketInfrastructureAccount = stellarAccountContext.accounts.find(
        (val) => val.category === LedgerAccountCategory.MarketInfrastructure,
      );

      // if the market infrastructure account is not found close the dialog
      if (!marketInfrastructureAccount) {
        enqueueSnackbar(
          "Unexpected Error Finding Market Infrastructure Account",
          {
            variant: "error",
          },
        );
        props.onClose();
        return;
      }

      try {
        setIsUserSignatoryOnAccount(
          await stellarAccountContext.checkUserSignatoryOnAccount(
            LedgerIDIdentifier(marketInfrastructureAccount.ledgerID),
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user is signatory on destination account: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Determining Signatory Status", {
          variant: "error",
        });
      }

      setLoading(false);
    })();
  }, [enqueueSnackbar, stellarAccountContext.loading]);

  const performValidations = () => {
    const newValidationState: { [key: string]: string | undefined } = {};

    if (!bankReference) {
      newValidationState.bankReference = "Cannot be blank";
    }

    setValidationState(newValidationState);
    return !Object.keys(newValidationState).length;
  };

  const handleClearValidation = (field: string) => {
    setValidationState({
      ...validationState,
      [field]: undefined,
    });
  };

  const handleProcessDefundOrder = async () => {
    if (!performValidations()) {
      return;
    }

    setLoading(true);
    try {
      await DefundOrderStateController.SettleDefundOrder({
        context: authContext,
        defundOrderID: props.model.id,
        bankReference,
      });
      enqueueSnackbar("Defund Order Settlement In Progress", {
        variant: "success",
      });

      props.onClose();
      return;
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`unable to settle defund order`, e);
      enqueueSnackbar(`unable to settle defund order: ${err.message}`, {
        variant: "error",
      });
    }
    setLoading(false);
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>
        <Grid
          className={classes.dialogTitleContent}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item className={classes.dialogTitle}>
            <Typography variant="h5">Defund Order</Typography>
          </Grid>
          <Grid item>
            <IconButton
              disabled={loading}
              id="processdefundDialog-close-button"
              onClick={props.onClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ width: "570px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            fullWidth
            id="processdefundDialog-client-textField"
            label="Client"
            value={props.model.clientName}
            readOnly
          />
          <TextField
            style={{ width: "180px" }}
            id="processdefundDialog-asset-textField"
            label="Asset"
            value="mZAR"
            readOnly
          />
          <TextField
            fullWidth
            id="processdefundDialog-amount-textField"
            label="Amount"
            value={props.model.amount.value.toString()}
            readOnly
          />
        </div>
        <Divider />
        <Typography
          style={{
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
          }}
          variant="h5"
          children="Pay To"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            fullWidth
            id="processdefundDialog-bankName-textField"
            label="Bank"
            value={props.model.bankAccount.bankName}
            readOnly
          />
          <TextField
            fullWidth
            id="processdefundDialog-bankcode-textField"
            label="Branch"
            value={props.model.bankAccount.branchCode}
            readOnly
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            fullWidth
            id="processdefundDialog-bankAccountNumber-textField"
            label="Linked Account Number"
            value={props.model.bankAccount.number}
            readOnly
          />
          <TextField
            fullWidth
            id="processdefundDialog-accountNumber-textField"
            label="Reference"
            value={props.model.accountNumber}
            readOnly
          />
        </div>
        <Divider style={{ marginBottom: theme.spacing(2) }} />
        <TextField
          id="processdefundDialog-bankReference-textField"
          style={{ marginBottom: theme.spacing(4) }}
          fullWidth
          disabled={loading}
          value={bankReference}
          helperText={validationState.bankReference}
          error={!!validationState.bankReference}
          onChange={(e) => {
            handleClearValidation("bankReference");
            setBankReferemce(e.target.value);
          }}
          label="Bank Reference"
        />
        <div style={{ display: "flex" }}>
          <Tooltip
            placement="top"
            title={
              isUserASignatoryOnAccount
                ? ""
                : "user is not a signatory on market infrastructure account"
            }
          >
            <span>
              <Button
                id="processdefundDialog-processRequest-button"
                variant="contained"
                color="secondary"
                disabled={
                  loading ||
                  props.model.state === DefundOrderState.Settled ||
                  !isUserASignatoryOnAccount
                }
                onClick={handleProcessDefundOrder}
                style={{ marginRight: theme.spacing(1) }}
              >
                Process Request
              </Button>
            </span>
          </Tooltip>
          {loading && <CircularProgress size={25} />}
        </div>
      </DialogContent>
    </Dialog>
  );
}
