import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  AddCircleOutline as AddIcon,
  Close as CloseIcon,
  PersonAddOutlined as AddUserIcon,
  RemoveCircleOutline as RemoveIcon,
} from "@mui/icons-material";
import { Role, RoleRepository } from "james/role";
import {
  TextExactCriterion,
  TextNINListCriterion,
} from "james/search/criterion";
import { GroupUser, UserCompository, UserManagerWrite } from "james/user";
import isEqual from "lodash/isEqual";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { ValidateEmail } from "utilities/validation";
import { useApplicationContext } from "context/Application/Application";
import { Client } from "james/client";
import { useErrorContext } from "context/Error";

const PREFIX = "AddOrInviteUsersDialog";

const classes = {
  dialogTitleRootOverride: `${PREFIX}-dialogTitleRootOverride`,
  closeIcon: `${PREFIX}-closeIcon`,
  dialogContentRootOverride: `${PREFIX}-dialogContentRootOverride`,
  existingRegisteredUsersLayout: `${PREFIX}-existingRegisteredUsersLayout`,
  inviteOffPlatformUserLayout: `${PREFIX}-inviteOffPlatformUserLayout`,
  inviteOffPlatformUserTitle: `${PREFIX}-inviteOffPlatformUserTitle`,
  inviteOffPlatformUserContent: `${PREFIX}-inviteOffPlatformUserContent`,
  existingRegisteredUserRowLayout: `${PREFIX}-existingRegisteredUserRowLayout`,
  userSelectFormField: `${PREFIX}-userSelectFormField`,
  userSelectInputLabel: `${PREFIX}-userSelectInputLabel`,
  existingUserRoleSelectFormField: `${PREFIX}-existingUserRoleSelectFormField`,
  icon: `${PREFIX}-icon`,
  inviteOffPlatformUserRowLayout: `${PREFIX}-inviteOffPlatformUserRowLayout`,
  newUserRoleSelectFormField: `${PREFIX}-newUserRoleSelectFormField`,
  addRemoveRowControlsLayout: `${PREFIX}-addRemoveRowControlsLayout`,
  firstNameLastNameTextField: `${PREFIX}-firstNameLastNameTextField`,
  emailTextField: `${PREFIX}-emailTextField`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogTitleRootOverride}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: `0 ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
    minWidth: 920,
  },

  [`& .${classes.closeIcon}`]: {
    marginRight: theme.spacing(-3),
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
  },

  [`& .${classes.dialogContentRootOverride}`]: {
    display: "grid",
    gridTemplateRows: "auto auto",
    padding: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
  },

  [`& .${classes.existingRegisteredUsersLayout}`]: {
    padding: `0 ${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(3)}`,
    display: "grid",
    gridTemplateRows: "auto auto",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.inviteOffPlatformUserLayout}`]: {
    display: "grid",
    gridTemplateRows: "auto auto",
  },

  [`& .${classes.inviteOffPlatformUserTitle}`]: {
    padding: `0 ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.inviteOffPlatformUserContent}`]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
    display: "grid",
    gridTemplateRows: "auto auto",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.existingRegisteredUserRowLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto auto 1fr",
    gridColumnGap: theme.spacing(2),
  },

  [`& .${classes.userSelectFormField}`]: {
    marginTop: 6,
    width: 300,
  },

  [`& .${classes.userSelectInputLabel}`]: {
    backgroundColor: theme.palette.background.paper,
    padding: "0 4px 0px 4px",
  },

  [`& .${classes.existingUserRoleSelectFormField}`]: {
    width: 470,
    marginRight: theme.spacing(1),
  },

  [`& .${classes.icon}`]: {
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
  },

  [`& .${classes.inviteOffPlatformUserRowLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto auto auto 1fr",
    gridColumnGap: theme.spacing(2),
  },

  [`& .${classes.newUserRoleSelectFormField}`]: {
    marginRight: theme.spacing(2),
    width: 440,
  },

  [`& .${classes.addRemoveRowControlsLayout}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 62,
  },

  [`& .${classes.firstNameLastNameTextField}`]: {
    width: 212,
  },

  [`& .${classes.emailTextField}`]: {
    width: 328,
    marginRight: 8,
  },
}));

interface AddOrInviteUsersDialogProps {
  groupID: string;
  closeDialog: () => void;
  onExistingUsersAddedToGroup?: () => void;
}

interface ExistingUserToAddToGroupEntry {
  user: GroupUser;
  roles: Role[];
}

function NewExistingUserToAddToGroupEntry(): ExistingUserToAddToGroupEntry {
  return {
    user: new GroupUser(),
    roles: [],
  };
}

interface NewUserToAddToGroupEntry {
  details: {
    [key: string]: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  roles: Role[];
}

function NewNewUserToAddToGroupEntry(): NewUserToAddToGroupEntry {
  return {
    details: {
      firstName: "",
      lastName: "",
      email: "",
    },
    roles: [],
  };
}

function ExistingUserIsBlank(
  existingUser: ExistingUserToAddToGroupEntry,
): boolean {
  const newExistingUserToAddToGroup = NewExistingUserToAddToGroupEntry();
  newExistingUserToAddToGroup.user.auditEntry.time =
    existingUser.user.auditEntry.time;
  return isEqual(existingUser, newExistingUserToAddToGroup);
}

export function AddOrInviteUsersDialog(props: AddOrInviteUsersDialogProps) {
  const { authContext, myClient, myClientRetrievalErr } =
    useApplicationContext();
  const [refreshDialog, setRefreshDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rolesOwnedByGroup, setRolesOwnedByGroup] = useState<Role[]>([]);
  const [client, setClient] = useState(new Client(myClient));
  const [existingUsersToAddToGroup, setExistingUsersToAddToGroup] = useState<
    ExistingUserToAddToGroupEntry[]
  >([NewExistingUserToAddToGroupEntry()]);
  const [potentialGroupUsers, setPotentialGroupUsers] = useState<GroupUser[]>(
    [],
  );
  const [newUsersToAddToGroup, setNewUsersToAddToGroup] = useState<
    NewUserToAddToGroupEntry[]
  >([NewNewUserToAddToGroupEntry()]);
  const [fieldValidation, setFieldValidation] = useState<{
    [key: string]: string | undefined;
  }>({});
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextDefaultErrorFeedback, errorContextErrorTranslator } =
    useErrorContext();

  useEffect(() => {
    const initialise = async () => {
      setLoading(true);

      if (!myClient && myClientRetrievalErr) {
        errorContextDefaultErrorFeedback(myClientRetrievalErr);
        return;
      }

      // remain in loading state in client is not loaded yet
      if (!myClient) {
        return;
      }

      setClient(myClient);

      try {
        // find all roles owned by this group
        const searchRolesResponse = await RoleRepository.SearchRoles({
          context: authContext,
          criteria: { ownerID: TextExactCriterion(props.groupID) },
        });
        setRolesOwnedByGroup(searchRolesResponse.records);

        // find all users that:
        //  - do not have a role owned by this group
        //  - have the same client as the logged in user
        const searchUsersResponse = await UserCompository.SearchGroupUsers({
          context: authContext,
          criteria: {
            roleIDs: TextNINListCriterion(
              searchRolesResponse.records.map((r) => r.id),
            ),
            clientID: TextExactCriterion(myClient.id),
          },
        });
        setPotentialGroupUsers(searchUsersResponse.records);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error("error searching for group users", e);
        enqueueSnackbar(
          `error searching for group users: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }
      setLoading(false);
    };
    initialise().finally();
  }, [authContext, myClient, props.groupID, enqueueSnackbar, refreshDialog]);

  const handleDialogRefresh = () => {
    setExistingUsersToAddToGroup([NewExistingUserToAddToGroupEntry()]);
    setNewUsersToAddToGroup([NewNewUserToAddToGroupEntry()]);
    setRefreshDialog(!refreshDialog);
  };

  const handleChangeExistingUserToAddToGroup =
    (userToAddToGroupIdx: number) => (e: SelectChangeEvent<string>) => {
      // clear field validation
      handleClearFieldValidation(
        `existingUsersToAdd-${userToAddToGroupIdx}.groupUser`,
      );

      // look for user (by ID - comes from e.target.value)
      const userToAdd = potentialGroupUsers.find(
        (potentialUser) => potentialUser.id === e.target.value,
      );
      if (!userToAdd) {
        console.error("could not find user to add among potential users");
        return;
      }

      // update current existing user to add to group entry
      existingUsersToAddToGroup[userToAddToGroupIdx] = {
        ...existingUsersToAddToGroup[userToAddToGroupIdx],
        user: new GroupUser(userToAdd),
      };

      // update existing users to add to group, filtering to remove duplicates
      const usersInEntries: string[] = [];
      setExistingUsersToAddToGroup(
        existingUsersToAddToGroup.filter((existingUserToAddToGroupEntry) => {
          // if user is not already in the list --> Filter is TRUE
          if (!usersInEntries.includes(existingUserToAddToGroupEntry.user.id)) {
            usersInEntries.push(existingUserToAddToGroupEntry.user.id);
            return true;
          }
          // otherwise user already in list --> Filter is FALSE
          return false;
        }),
      );
    };

  const handleChangeExistingUserToAddToGroupsRoles =
    (userToAddToGroupIdx: number) =>
    (_: React.SyntheticEvent<Element, Event>, updatedRoles: Role[]) => {
      // clear field validation
      handleClearFieldValidation(
        `existingUsersToAdd-${userToAddToGroupIdx}.roles`,
      );

      // updateRoles
      setExistingUsersToAddToGroup((currentExistingUsersToAddToGroup) => {
        currentExistingUsersToAddToGroup[userToAddToGroupIdx] = {
          ...currentExistingUsersToAddToGroup[userToAddToGroupIdx],
          roles: updatedRoles,
        };
        return [...currentExistingUsersToAddToGroup];
      });
    };

  const handleRemoveRoleOptionFromExistingUserToAddToGroup =
    (userToAddToGroupIdx: number, roleId: string) => () => {
      setExistingUsersToAddToGroup((currentExistingUsersToAddToGroup) => {
        currentExistingUsersToAddToGroup[userToAddToGroupIdx] = {
          ...currentExistingUsersToAddToGroup[userToAddToGroupIdx],
          roles: currentExistingUsersToAddToGroup[
            userToAddToGroupIdx
          ].roles.filter((r) => r.id !== roleId),
        };
        return [...currentExistingUsersToAddToGroup];
      });
    };

  const handleAddExistingUsersRow = (addAfterIdx: number) => () => {
    handleClearAllExistingUserValidation();
    const newExistingUsersToAddToGroup: ExistingUserToAddToGroupEntry[] = [];
    existingUsersToAddToGroup.forEach((entry, idx) => {
      newExistingUsersToAddToGroup.push(entry);
      if (idx === addAfterIdx) {
        newExistingUsersToAddToGroup.push({
          user: new GroupUser(),
          roles: [...existingUsersToAddToGroup[addAfterIdx].roles],
        });
      }
    });
    setExistingUsersToAddToGroup(newExistingUsersToAddToGroup);
  };

  const handleRemoveExistingUsersRow = (rowToRemoveIdx: number) => () => {
    setExistingUsersToAddToGroup((currentExistingUsersToAddToGroup) => {
      handleClearAllExistingUserValidation();
      return currentExistingUsersToAddToGroup.filter(
        (entry, idx) => idx !== rowToRemoveIdx,
      );
    });
  };

  const handleChangeNewUserToAddToGroupDetails =
    (userToChangeIdx: number, field: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleClearFieldValidation(`newUsersToAdd-${userToChangeIdx}.${field}`);
      newUsersToAddToGroup[userToChangeIdx].details[field] = e.target.value;
      setNewUsersToAddToGroup([...newUsersToAddToGroup]);
    };

  const handleChangeNewUserToAddToGroupsRoles =
    (userToChangeIdx: number) =>
    (_: React.SyntheticEvent, updatedRoles: Role[]) => {
      handleClearFieldValidation(`newUsersToAdd-${userToChangeIdx}.roles`);
      newUsersToAddToGroup[userToChangeIdx].roles = updatedRoles;
      setNewUsersToAddToGroup([...newUsersToAddToGroup]);
    };

  const handleAddNewUserToAddToGroupRow = (idxToAddAfter: number) => () => {
    handleClearAllNewUserValidation();
    const updatedNewUsersToAddToGroup: NewUserToAddToGroupEntry[] = [];
    handleClearAllNewUserValidation();
    newUsersToAddToGroup.forEach((entry, idx) => {
      updatedNewUsersToAddToGroup.push(entry);
      if (idxToAddAfter === idx) {
        updatedNewUsersToAddToGroup.push({
          ...NewNewUserToAddToGroupEntry(),
          roles: [...newUsersToAddToGroup[idxToAddAfter].roles],
        });
      }
    });
    setNewUsersToAddToGroup(updatedNewUsersToAddToGroup);
  };

  const handleRemoveNewUserToAddToGroupRow = (idxToRemove: number) => () => {
    handleClearAllNewUserValidation();
    setNewUsersToAddToGroup(
      newUsersToAddToGroup.filter((entry, idx) => idx !== idxToRemove),
    );
  };

  const handleRemoveNewUserRole =
    (userToChangeIdx: number, roleToRemoveID: string) => () => {
      newUsersToAddToGroup[userToChangeIdx].roles = newUsersToAddToGroup[
        userToChangeIdx
      ].roles.filter((r) => r.id !== roleToRemoveID);
      setNewUsersToAddToGroup([...newUsersToAddToGroup]);
    };

  const handleClearFieldValidation = (field: string) => {
    setFieldValidation({
      ...fieldValidation,
      [field]: undefined,
    });
  };

  const handleClearAllExistingUserValidation = () => {
    const newValidationState: { [key: string]: string | undefined } = {};
    for (const field in fieldValidation) {
      if (!field.includes("existingUsersToAdd")) {
        newValidationState[field] = fieldValidation[field];
      }
    }
    setFieldValidation(newValidationState);
  };

  const handleClearAllNewUserValidation = () => {
    const newValidationState: { [key: string]: string | undefined } = {};
    for (const field in fieldValidation) {
      if (!field.includes("newUsersToAdd")) {
        newValidationState[field] = fieldValidation[field];
      }
    }
    setFieldValidation(newValidationState);
  };

  const performValidation: () => boolean = () => {
    const newValidationState: { [key: string]: string | undefined } = {};
    let validationPassed = true;

    // function to validate existing users to add to group
    const blankGroupUser = new GroupUser();
    const performExistingUserToAddEntryValidation = (
      entryIdx: number,
      entry: ExistingUserToAddToGroupEntry,
    ) => {
      // confirm that a user has been selected
      if (isEqual(entry.user, blankGroupUser)) {
        validationPassed = false;
        newValidationState[`existingUsersToAdd-${entryIdx}.groupUser`] =
          "Select a User";
      }
      if (entry.roles.length === 0) {
        newValidationState[`existingUsersToAdd-${entryIdx}.roles`] =
          "Select at Least 1 Role";
        validationPassed = false;
      }
    };

    //
    // perform validation of existing users to add to group
    //
    if (existingUsersToAddToGroup.length === 1) {
      // if there is only 1 row it can be blank
      if (!ExistingUserIsBlank(existingUsersToAddToGroup[0])) {
        // but if it is not blank it must be valid
        performExistingUserToAddEntryValidation(
          0,
          existingUsersToAddToGroup[0],
        );
      }
    } else {
      // otherwise all of them must be valid
      existingUsersToAddToGroup.forEach((entry, idx) =>
        performExistingUserToAddEntryValidation(idx, entry),
      );
    }

    // function to validate new users to add to group
    const validateNewUserToAddToGroupEntry = (
      entryIdx: number,
      entry: {
        details: { [key: string]: string };
        roles: Role[];
      },
    ) => {
      if (entry.roles.length === 0) {
        validationPassed = false;
        newValidationState[`newUsersToAdd-${entryIdx}.roles`] =
          "Select at Least 1 Role";
      }
      if (!entry.details.firstName || entry.details.firstName === "") {
        validationPassed = false;
        newValidationState[`newUsersToAdd-${entryIdx}.firstName`] =
          "Cannot Be Blank";
      }

      if (!entry.details.lastName || entry.details.lastName === "") {
        validationPassed = false;
        newValidationState[`newUsersToAdd-${entryIdx}.lastName`] =
          "Cannot Be Blank";
      }

      if (!entry.details.email || entry.details.email === "") {
        validationPassed = false;
        newValidationState[`newUsersToAdd-${entryIdx}.email`] =
          "Cannot Be Blank";
      } else if (!ValidateEmail(entry.details.email)) {
        validationPassed = false;
        newValidationState[`newUsersToAdd-${entryIdx}.email`] = "Invalid";
      }
    };
    //
    // perform validation of new users to add to group
    //
    if (newUsersToAddToGroup.length === 1) {
      // if there is only 1 row it can be blank
      if (!isEqual(NewNewUserToAddToGroupEntry(), newUsersToAddToGroup[0])) {
        // but if it is not blank then it must be valid
        validateNewUserToAddToGroupEntry(0, newUsersToAddToGroup[0]);
      }
    } else {
      // otherwise all of them must be valid
      newUsersToAddToGroup.forEach((entry, idx) =>
        validateNewUserToAddToGroupEntry(idx, entry),
      );
    }

    setFieldValidation(newValidationState);
    return validationPassed;
  };

  const handleAdd = async () => {
    if (!performValidation()) {
      return;
    }
    setLoading(true);
    let actionTaken = false;
    //
    // perform role assignments for existing users to add them to group
    //
    try {
      if (
        // if there is only 1 row and it is not blank
        (existingUsersToAddToGroup.length === 1 &&
          !ExistingUserIsBlank(existingUsersToAddToGroup[0])) || // OR
        // if there is more than 1 row
        existingUsersToAddToGroup.length > 1
      ) {
        // then perform role assignment to existing users to add them to the group
        await Promise.all(
          existingUsersToAddToGroup.map((existingUserToAddToGroupWithRoles) =>
            (async () => {
              await UserManagerWrite.AssignRolesToUser({
                context: authContext,
                userID: existingUserToAddToGroupWithRoles.user.id,
                roleIDs: existingUserToAddToGroupWithRoles.roles.map(
                  (r) => r.id,
                ),
              });
            })(),
          ),
        );
        actionTaken = true;
        enqueueSnackbar(
          `added ${existingUsersToAddToGroup.length} existing user/s to group`,
          { variant: "success" },
        );
        if (props.onExistingUsersAddedToGroup) {
          props.onExistingUsersAddedToGroup();
        }
      }
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error("error adding roles to existing users", e);
      enqueueSnackbar(`error adding existing users: ${err.message}`, {
        variant: "error",
      });
      setLoading(false);
      return;
    }

    //
    // send invites to new users to add them to group
    //
    try {
      if (
        // if there is only 1 row and it is not blank
        (newUsersToAddToGroup.length === 1 &&
          !isEqual(NewNewUserToAddToGroupEntry(), newUsersToAddToGroup[0])) || // OR
        // if there is more than 1 row
        newUsersToAddToGroup.length > 1
      ) {
        // then send invites to new users to add them to the group
        await Promise.all(
          newUsersToAddToGroup.map((newUserToAddToGroupWithRoles) =>
            (async () => {
              await UserManagerWrite.InviteUser({
                context: authContext,
                email: newUserToAddToGroupWithRoles.details.email,
                roleIDs: newUserToAddToGroupWithRoles.roles.map((r) => r.id),
                ownerID: client.ownerID,
                clientID: client.id,
                firstName: newUserToAddToGroupWithRoles.details.firstName,
                lastName: newUserToAddToGroupWithRoles.details.lastName,
              });
            })(),
          ),
        );
        actionTaken = true;
        enqueueSnackbar(
          `invited ${newUsersToAddToGroup.length} new user/s to group`,
          { variant: "success" },
        );
      }
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error("error sending invites new users", e);
      enqueueSnackbar(
        `error inviting new users: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    if (actionTaken) {
      handleDialogRefresh();
    }
    setLoading(false);
  };

  return (
    <StyledDialog open maxWidth="lg">
      <DialogTitle classes={{ root: classes.dialogTitleRootOverride }}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="h5">Add Or Invite Users</Typography>
          </Grid>
          {loading && (
            <Grid item>
              <CircularProgress size={25} />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Button
              id="addOrInviteUser-addonPlatform-button"
              startIcon={<AddUserIcon />}
              color="primary"
              variant="contained"
              size="small"
              onClick={handleAdd}
              disabled={loading}
            >
              add
            </Button>
          </Grid>
          <Grid item>
            {loading ? (
              <IconButton
                id="addOrInviteUser-closeLoading-button"
                className={classes.closeIcon}
                disabled
                size="large"
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <Tooltip title="Close" placement="top">
                <IconButton
                  id="addOrInviteUser-close-button"
                  onClick={props.closeDialog}
                  className={classes.closeIcon}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRootOverride }}>
        <div className={classes.existingRegisteredUsersLayout}>
          <Typography variant="h6">Existing/Registered Users</Typography>
          <Grid container direction="column" spacing={1}>
            {potentialGroupUsers.length === 0 && (
              <Grid item>
                <Typography variant="caption">
                  No users that are not already in this group were found
                </Typography>
              </Grid>
            )}
            {existingUsersToAddToGroup.map(
              (existingUserToAddWithRoles, euIdx) => (
                <Grid item key={euIdx}>
                  <div className={classes.existingRegisteredUserRowLayout}>
                    <FormControl
                      id="existingUsers-selectUsers-formControl"
                      className={classes.userSelectFormField}
                      variant="outlined"
                      margin="dense"
                      disabled={loading || potentialGroupUsers.length === 0}
                      error={
                        !!fieldValidation[
                          `existingUsersToAdd-${euIdx}.groupUser`
                        ]
                      }
                    >
                      <InputLabel
                        shrink
                        className={classes.userSelectInputLabel}
                      >
                        User
                      </InputLabel>
                      <Select
                        defaultValue=""
                        id="existingUsers-selectUsers-select"
                        onChange={handleChangeExistingUserToAddToGroup(euIdx)}
                        value={existingUserToAddWithRoles.user.id}
                      >
                        {potentialGroupUsers
                          .filter(
                            (pgu) =>
                              !existingUsersToAddToGroup
                                .slice(0, euIdx)
                                .find((eu) => eu.user.id === pgu.id),
                          )
                          .map((u, uIdx) => (
                            <MenuItem key={uIdx} value={u.id}>
                              {u.fullName}
                            </MenuItem>
                          ))}
                      </Select>
                      {!!fieldValidation[
                        `existingUsersToAdd-${euIdx}.groupUser`
                      ] && (
                        <FormHelperText>
                          {
                            fieldValidation[
                              `existingUsersToAdd-${euIdx}.groupUser`
                            ]
                          }
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => option === value}
                      id="existingUsers-selectRole-autocomplete"
                      multiple
                      disabled={loading || potentialGroupUsers.length === 0}
                      options={rolesOwnedByGroup}
                      value={existingUserToAddWithRoles.roles}
                      getOptionLabel={(option: Role) => option.name}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="existingUsers-selectRole-formfield"
                          placeholder={
                            existingUserToAddWithRoles.roles.length
                              ? undefined
                              : "Select Role/s"
                          }
                          variant="outlined"
                          label="Roles"
                          InputLabelProps={{ shrink: true }}
                          className={classes.existingUserRoleSelectFormField}
                          margin="dense"
                          disabled={loading}
                          error={
                            !!fieldValidation[
                              `existingUsersToAdd-${euIdx}.roles`
                            ]
                          }
                          helperText={
                            fieldValidation[`existingUsersToAdd-${euIdx}.roles`]
                          }
                        />
                      )}
                      renderTags={(selectedRoles: Role[]) =>
                        selectedRoles.map((selectedRole, roleIdx) => (
                          <Chip
                            id={`existingUsers-${selectedRole.name}-chip`}
                            size="small"
                            sx={(theme) => ({
                              margin: theme.spacing(0.5),
                            })}
                            key={roleIdx}
                            color="info"
                            label={selectedRole.name}
                            onDelete={
                              loading
                                ? undefined
                                : handleRemoveRoleOptionFromExistingUserToAddToGroup(
                                    euIdx,
                                    selectedRole.id,
                                  )
                            }
                          />
                        ))
                      }
                      onChange={handleChangeExistingUserToAddToGroupsRoles(
                        euIdx,
                      )}
                    />
                    <div className={classes.addRemoveRowControlsLayout}>
                      {loading || potentialGroupUsers.length === 0 ? (
                        <IconButton
                          id="existingUsers-disabledAddUser-button"
                          className={classes.icon}
                          disabled
                          size="small"
                        >
                          <AddIcon />
                        </IconButton>
                      ) : (
                        <Tooltip title="Add Row">
                          <IconButton
                            id="existingUsers-addUser-button"
                            className={classes.icon}
                            size="small"
                            onClick={handleAddExistingUsersRow(euIdx)}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {existingUsersToAddToGroup.length > 1 &&
                        (loading ? (
                          <IconButton
                            id="existingUsers-disabledRemoveUser-button"
                            className={classes.icon}
                            size="small"
                            disabled
                          >
                            <RemoveIcon />
                          </IconButton>
                        ) : (
                          <Tooltip title="Remove Row">
                            <IconButton
                              id="existingUsers-removeUser-button"
                              className={classes.icon}
                              size="small"
                              onClick={handleRemoveExistingUsersRow(euIdx)}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </Tooltip>
                        ))}
                    </div>
                  </div>
                </Grid>
              ),
            )}
          </Grid>
        </div>
        <div className={classes.inviteOffPlatformUserLayout}>
          <Typography
            variant="h5"
            className={classes.inviteOffPlatformUserTitle}
          >
            Invite an off platform user
          </Typography>
          <div className={classes.inviteOffPlatformUserContent}>
            <Typography variant="h6">User Information</Typography>
            <Grid container direction="column" spacing={1}>
              {newUsersToAddToGroup.map((newUsersToAddToGroupEntry, nuIdx) => (
                <Grid item key={nuIdx}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <div className={classes.inviteOffPlatformUserRowLayout}>
                        <TextField
                          id="offPlatformUser-firstName-button"
                          disabled={loading}
                          label="First Name"
                          inputProps={{ maxLength: 40 }}
                          variant="outlined"
                          margin="dense"
                          value={newUsersToAddToGroupEntry.details.firstName}
                          onChange={handleChangeNewUserToAddToGroupDetails(
                            nuIdx,
                            "firstName",
                          )}
                          className={classes.firstNameLastNameTextField}
                          error={
                            !!fieldValidation[
                              `newUsersToAdd-${nuIdx}.firstName`
                            ]
                          }
                          helperText={
                            fieldValidation[`newUsersToAdd-${nuIdx}.firstName`]
                          }
                        />
                        <TextField
                          id="offPlatformUser-lastName-button"
                          disabled={loading}
                          label="Last Name"
                          inputProps={{ maxLength: 40 }}
                          variant="outlined"
                          margin="dense"
                          value={newUsersToAddToGroupEntry.details.lastName}
                          onChange={handleChangeNewUserToAddToGroupDetails(
                            nuIdx,
                            "lastName",
                          )}
                          className={classes.firstNameLastNameTextField}
                          error={
                            !!fieldValidation[`newUsersToAdd-${nuIdx}.lastName`]
                          }
                          helperText={
                            fieldValidation[`newUsersToAdd-${nuIdx}.lastName`]
                          }
                        />
                        <TextField
                          disabled={loading}
                          id="offPlatformUser-emailAddress-button"
                          label="Email Address"
                          inputProps={{ maxLength: 40 }}
                          variant="outlined"
                          margin="dense"
                          value={newUsersToAddToGroupEntry.details.email}
                          onChange={handleChangeNewUserToAddToGroupDetails(
                            nuIdx,
                            "email",
                          )}
                          className={classes.emailTextField}
                          error={
                            !!fieldValidation[`newUsersToAdd-${nuIdx}.email`]
                          }
                          helperText={
                            fieldValidation[`newUsersToAdd-${nuIdx}.email`]
                          }
                        />
                        <div className={classes.addRemoveRowControlsLayout}>
                          {loading ? (
                            <IconButton
                              id="offPlatformUser-disabledAddUser-button"
                              className={classes.icon}
                              size="small"
                              disabled
                            >
                              <AddIcon />
                            </IconButton>
                          ) : (
                            <Tooltip title="Add Row">
                              <IconButton
                                id="offPlatformUser-addUser-button"
                                className={classes.icon}
                                size="small"
                                onClick={handleAddNewUserToAddToGroupRow(nuIdx)}
                              >
                                <AddIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {newUsersToAddToGroup.length > 1 &&
                            (loading ? (
                              <IconButton
                                id="offPlatformUser-disabledRemoveUser-button"
                                className={classes.icon}
                                size="small"
                                disabled
                              >
                                <RemoveIcon />
                              </IconButton>
                            ) : (
                              <Tooltip title="Remove Row">
                                <IconButton
                                  id="offPlatformUser-removeUser-button"
                                  className={classes.icon}
                                  size="small"
                                  onClick={handleRemoveNewUserToAddToGroupRow(
                                    nuIdx,
                                  )}
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </Tooltip>
                            ))}
                        </div>
                      </div>
                    </Grid>
                    <Grid item>
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        id="offPlatformUser-selectRole-autocomplete"
                        multiple
                        disabled={loading}
                        options={rolesOwnedByGroup}
                        value={newUsersToAddToGroupEntry.roles}
                        getOptionLabel={(option: Role) => option.name}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="offPlatformUser-selectRole-formfield"
                            placeholder={
                              newUsersToAddToGroupEntry.roles.length
                                ? undefined
                                : "Select Role/s"
                            }
                            variant="outlined"
                            label="Roles"
                            InputLabelProps={{ shrink: true }}
                            className={classes.newUserRoleSelectFormField}
                            margin="dense"
                            disabled={loading}
                            error={
                              !!fieldValidation[`newUsersToAdd-${nuIdx}.roles`]
                            }
                            helperText={
                              fieldValidation[`newUsersToAdd-${nuIdx}.roles`]
                            }
                          />
                        )}
                        renderTags={(selectedRoles: Role[]) =>
                          selectedRoles.map((selectedRole, roleIdx) => (
                            <Chip
                              id={`offPlatformUser-${selectedRole.name}-chip`}
                              size="small"
                              sx={(theme) => ({
                                margin: theme.spacing(0.5),
                              })}
                              key={roleIdx}
                              color="info"
                              label={selectedRole.name}
                              onDelete={
                                loading
                                  ? undefined
                                  : handleRemoveNewUserRole(
                                      nuIdx,
                                      selectedRole.id,
                                    )
                              }
                            />
                          ))
                        }
                        onChange={handleChangeNewUserToAddToGroupsRoles(nuIdx)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </DialogContent>
    </StyledDialog>
  );
}
