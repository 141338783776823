import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { Query } from "james/search/query";
import {
  Box,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import { CouponPaymentRecipient } from "james/financial/CouponPaymentRecipient";
import { useSearchCouponPaymentRecipient } from "james/financial/CouponPaymentRecipientRepository";
import {
  TextExactCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { BPTable } from "components/Table";
import {
  Clear as ClearIcon,
  FileCopy as CopyPasteIcon,
  OpenInNew as OpenInNewIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { TextField } from "components/FormFields";
import { useApplicationContext } from "context/Application/Application";
import { Amount } from "components/Ledger/Amount";
import { useSnackbar } from "notistack";
import { StellarNetwork } from "james/stellar";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

const PREFIX = "CouponPaymentRecipientTable";

const classes = {
  textSearchField: `${PREFIX}-textSearchField`,
  iconButton: `${PREFIX}-iconButton`,
  row: `${PREFIX}-row`,
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`& .${classes.textSearchField}`]: {
    width: 350,
  },

  [`& .${classes.iconButton}`]: {
    fontSize: 20,
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
    cursor: "pointer",
  },

  [`& .${classes.row}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [],
});

export type CouponPaymentRecipientTableProps = {
  height: number;
  couponPaymentID: string;
};

export function CouponPaymentRecipientTable(
  props: CouponPaymentRecipientTableProps,
) {
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();

  const startingCriteria = useMemo(
    () => ({ couponPaymentID: TextExactCriterion(props.couponPaymentID) }),
    [props.couponPaymentID],
  );
  const {
    loading,
    searchCouponPaymentRecipientResponse,
    searchCouponPaymentRecipientRequest,
    setSearchCouponPaymentRecipientRequest,
  } = useSearchCouponPaymentRecipient({
    context: authContext,
    query: new Query(initialQuery),
    criteria: startingCriteria,
  });
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [
          {
            accountLedgerID: TextSubstringCriterion(
              textSearchCriterionTextField,
            ),
          },
        ],
      });
    }
  }, [textSearchCriterionTextField]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = { ...startingCriteria };

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    setSearchCouponPaymentRecipientRequest({
      context: searchCouponPaymentRecipientRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [
    textSearchCriterion,
    searchCouponPaymentRecipientRequest.context,
    setSearchCouponPaymentRecipientRequest,
    authContext,
  ]);

  return (
    <BPTable
      disableSelect
      loading={loading}
      height={props.height - noticeBannerHeight}
      title={"Recipients of Coupon Payment"}
      data={searchCouponPaymentRecipientResponse.records}
      columns={(() => {
        const columns = [
          {
            label: "Account",
            field: "accountLedgerID",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [p: string]: any }) => {
              const cpr = data as CouponPaymentRecipient;
              return (
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    gap: theme.spacing(0.5),
                  })}
                >
                  <Typography
                    sx={{
                      width: 100,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    variant="body1"
                    children={cpr.accountLedgerID}
                  />
                  <CopyPasteIcon
                    sx={(theme) => ({
                      fontSize: 20,
                      color: theme.palette.action.disabled,
                      "&:hover": {
                        color: theme.palette.action.active,
                      },
                      cursor: "pointer",
                    })}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(cpr.accountLedgerID)
                        .then(() =>
                          enqueueSnackbar("Holding Account ID copied"),
                        )
                    }
                  />
                </Box>
              );
            },
          },
          {
            label: "Holding Tokens",
            field: "holdingTokens.value.float",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [p: string]: any }) => {
              return (
                <Amount
                  amount={(data as CouponPaymentRecipient).holdingTokens}
                  formatTextNumOpts={{
                    noDecimalPlaces: 7,
                    addDecimalPadding: false,
                  }}
                />
              );
            },
          },
          {
            label: "Principal",
            field: "principalAmount.value.float",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [p: string]: any }) => {
              return (
                <Amount
                  amount={(data as CouponPaymentRecipient).principalAmount}
                  formatTextNumOpts={{
                    noDecimalPlaces: 7,
                    addDecimalPadding: false,
                  }}
                />
              );
            },
          },
          {
            label: "Interest",
            field: "interestAmount.value.float",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [p: string]: any }) => {
              return (
                <Amount
                  amount={(data as CouponPaymentRecipient).interestAmount}
                  formatTextNumOpts={{
                    noDecimalPlaces: 7,
                    addDecimalPadding: false,
                  }}
                />
              );
            },
          },
        ];

        columns.push({
          field: "",
          label: "",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [key: string]: any }) => (
            <Tooltip placement="top" title="View Account in Stellar Expert">
              <StyledIconButton
                size="small"
                id={`ledgerTransactionTable-${
                  (data as CouponPaymentRecipient).accountLedgerID
                }-openAccountInStellarExpert-iconButton`}
                onClick={() =>
                  window.open(
                    `https://stellar.expert/explorer/${
                      (data as CouponPaymentRecipient).holdingTokens.token
                        .network === StellarNetwork.TestSDFNetwork
                        ? "testnet"
                        : "public"
                    }/account/${
                      (data as CouponPaymentRecipient).accountLedgerID
                    }`,
                    "_blank",
                  )
                }
              >
                <OpenInNewIcon />
              </StyledIconButton>
            </Tooltip>
          ),
        });

        return columns;
      })()}
      query={searchCouponPaymentRecipientRequest.query}
      onQueryChange={(query: Query) =>
        setSearchCouponPaymentRecipientRequest({
          ...searchCouponPaymentRecipientRequest,
          query,
        })
      }
      totalNoRecords={searchCouponPaymentRecipientResponse.total}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        controls.push(
          <Tooltip title={"Refresh"}>
            <span>
              <IconButton
                onClick={() =>
                  setSearchCouponPaymentRecipientRequest({
                    ...searchCouponPaymentRecipientRequest,
                    query: new Query(initialQuery),
                  })
                }
                id={"financialCouponPaymentRecipientsTable-refresh-iconButton"}
                disabled={loading}
                size={"small"}
              >
                <ReloadIcon />
              </IconButton>
            </span>
          </Tooltip>,
        );

        return controls;
      })()}
      filters={[
        <TextField
          id={"financialCouponPaymentRecipientsTable-textFilter-textField"}
          variant={"outlined"}
          disabled={loading}
          margin={"dense"}
          className={classes.textSearchField}
          label={"Search Text Field"}
          placeholder={"Start Typing..."}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: textSearchCriterionTextField ? (
              <InputAdornment
                position={"end"}
                children={
                  <IconButton
                    id={
                      "financialCouponPaymentRecipientsTable-textFilterClearButton-iconButton"
                    }
                    size={"small"}
                    onClick={() => setTextSearchCriterionTextField("")}
                  >
                    <ClearIcon />
                  </IconButton>
                }
              />
            ) : undefined,
          }}
          value={textSearchCriterionTextField}
          onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
        />,
      ]}
    />
  );
}
