import dayjs from "dayjs";
import { Document } from "james/document";
import { AuditEntry } from "../audit";
import { Amount } from "james/ledger";
import { BondState } from "./Bond";
import { Token } from "../ledger/Token";
import BigNumber from "bignumber.js";
import { InvestorProfile } from "./InvestorProfile";
import { InstrumentRiskProfile } from "./InstrumentRiskProfile";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";
import { AssetClassDEPRECATED } from "./AssetClassDEPRECATED";
import { FinancialInstrumentState } from "./InstrumentState";
import { CouponFrequency } from "./CouponFrequency";
import { BusinessDayConvention } from "./BusinessDayConvention";
import { DayCountConvention } from "./DayCountConvention";
import { DebtSeniorityLevel } from "./DebtSeniorityLevel";
import { InstrumentOwnershipLimitations } from "./InstrumentOwnershipLimitations";
import { DigitalBond } from "./DigitalBond";
import { Calendar } from "./Calendar";
import { DateGenerationRule } from "./DateGenerationRule";
import { RateSource } from "@mesh/common-js/dist/financial/rateSource_pb";

export const DigitalFloatingRateBondTypeName =
  "mesh::financial/DigitalFloatingRateBond";

export class DigitalFloatingRateBond implements DigitalBond {
  public ["@type"]: string = DigitalFloatingRateBondTypeName;

  public id = "";
  public ownerID = "";
  public state: BondState | "" = "";
  public auditEntry: AuditEntry = new AuditEntry();
  public isin = "";
  public name = "";
  public shortName = "";
  public token: Token = new Token();
  public fractionalisationAllowed = false;
  public supportingDocuments: Document[] = [];
  public investorProfile: InvestorProfile | "" = "";
  public investorProfileDescription = "";
  public riskProfile: InstrumentRiskProfile | "" = "";
  public riskProfileDescription = "";
  public debtSeniorityLevel: DebtSeniorityLevel | "" = "";
  public covenants = "";
  public votingRights = false;
  public votingRightsDescription = "";
  public ownershipLimitations: InstrumentOwnershipLimitations =
    new InstrumentOwnershipLimitations();
  public issuePrice: Amount = new Amount();
  public nominal: Amount = new Amount();

  // --------------- FixedRateBondStub fields (i.e. fields required for calculations) ----------------------
  public totalNominal: Amount = new Amount();
  public redemption: BigNumber = new BigNumber("0");
  public issueDate: string = dayjs().format();
  public maturityDate: string = dayjs().format();
  public calendar: Calendar = Calendar.UNDEFINED_CALENDAR;
  public couponPaymentFrequency: CouponFrequency | "" = "";
  public firstCouponPaymentDate: string | null = null;
  public nextToLastCouponPaymentDate: string | null = null;
  public couponInterestCommencementDate: string | null = null;
  public dayCountConvention: DayCountConvention | "" = "";
  public endOfMonthConvention = false;
  public businessDayConvention: BusinessDayConvention | "" = "";
  public terminationDateBusinessDayConvention: BusinessDayConvention | "" = "";
  public dateGenerationRule: DateGenerationRule =
    DateGenerationRule.UNDEFINED_DATE_GENERATION_RULE;
  public fixDays = 0;
  public exCouponDays = 0;
  public recordDays = 0;
  public couponRateSource: RateSource = RateSource.UNDEFINED_RATE_SOURCE;
  public couponRateVariance: BigNumber = new BigNumber("0");

  constructor(digitalFloatingRateBond?: DigitalFloatingRateBond) {
    if (!digitalFloatingRateBond) {
      return;
    }
    this.id = digitalFloatingRateBond.id;
    this.ownerID = digitalFloatingRateBond.ownerID;
    this.state = digitalFloatingRateBond.state;
    this.auditEntry = new AuditEntry(digitalFloatingRateBond.auditEntry);
    this.isin = digitalFloatingRateBond.isin;
    this.name = digitalFloatingRateBond.name;
    this.shortName = digitalFloatingRateBond.shortName;
    this.token = new Token(digitalFloatingRateBond.token);
    this.fractionalisationAllowed =
      digitalFloatingRateBond.fractionalisationAllowed;
    if (digitalFloatingRateBond.supportingDocuments) {
      this.supportingDocuments =
        digitalFloatingRateBond.supportingDocuments.map((d) => new Document(d));
    }
    this.investorProfile = digitalFloatingRateBond.investorProfile;
    this.investorProfileDescription =
      digitalFloatingRateBond.investorProfileDescription;
    this.riskProfile = digitalFloatingRateBond.riskProfile;
    this.riskProfileDescription =
      digitalFloatingRateBond.riskProfileDescription;
    this.debtSeniorityLevel = digitalFloatingRateBond.debtSeniorityLevel;
    this.covenants = digitalFloatingRateBond.covenants;
    this.votingRights = digitalFloatingRateBond.votingRights;
    this.votingRightsDescription =
      digitalFloatingRateBond.votingRightsDescription;
    if (digitalFloatingRateBond.ownershipLimitations) {
      this.ownershipLimitations = new InstrumentOwnershipLimitations(
        digitalFloatingRateBond.ownershipLimitations,
      );
    }
    this.issuePrice = new Amount(digitalFloatingRateBond.issuePrice);
    this.nominal = new Amount(digitalFloatingRateBond.nominal);

    // --------------- FixedRateBondStub fields (i.e. fields required for calculations) ----------------------
    this.totalNominal = new Amount(digitalFloatingRateBond.totalNominal);
    this.redemption = new BigNumber(digitalFloatingRateBond.redemption);
    this.issueDate = digitalFloatingRateBond.issueDate;
    this.maturityDate = digitalFloatingRateBond.maturityDate;
    this.calendar = digitalFloatingRateBond.calendar;
    this.couponPaymentFrequency =
      digitalFloatingRateBond.couponPaymentFrequency;

    this.couponInterestCommencementDate = dayjs(
      digitalFloatingRateBond.couponInterestCommencementDate,
    ).isSame(dayjs("0001-01-01T00:00:00Z"))
      ? null
      : digitalFloatingRateBond.couponInterestCommencementDate;
    this.firstCouponPaymentDate = dayjs(
      digitalFloatingRateBond.firstCouponPaymentDate,
    ).isSame(dayjs("0001-01-01T00:00:00Z"))
      ? null
      : digitalFloatingRateBond.firstCouponPaymentDate;
    this.nextToLastCouponPaymentDate = dayjs(
      digitalFloatingRateBond.nextToLastCouponPaymentDate,
    ).isSame(dayjs("0001-01-01T00:00:00Z"))
      ? null
      : digitalFloatingRateBond.nextToLastCouponPaymentDate;
    this.dayCountConvention = digitalFloatingRateBond.dayCountConvention;
    this.endOfMonthConvention = digitalFloatingRateBond.endOfMonthConvention;
    this.businessDayConvention = digitalFloatingRateBond.businessDayConvention;
    this.terminationDateBusinessDayConvention =
      digitalFloatingRateBond.terminationDateBusinessDayConvention;
    this.dateGenerationRule = digitalFloatingRateBond.dateGenerationRule;
    this.fixDays = digitalFloatingRateBond.fixDays;
    this.exCouponDays = digitalFloatingRateBond.exCouponDays;
    this.recordDays = digitalFloatingRateBond.recordDays;
    this.couponRateSource = digitalFloatingRateBond.couponRateSource;
    this.couponRateVariance = new BigNumber(
      digitalFloatingRateBond.couponRateVariance,
    );
  }

  assetID(): string {
    return this.id;
  }

  assetIssueDate(): string {
    return this.issueDate;
  }

  assetName(): string {
    return this.name;
  }

  assetOwnerID(): string {
    return this.ownerID;
  }

  assetShortName(): string {
    return this.shortName;
  }

  assetToken(): Token {
    return this.token;
  }

  assetFractionalisationAllowed(): boolean {
    return this.fractionalisationAllowed;
  }

  bondState(): BondState | "" {
    return this.state;
  }

  bondTotalNominal(): Amount {
    return this.totalNominal;
  }

  digitalInstrumentIssuePrice(): Amount {
    return this.issuePrice;
  }

  digitalInstrumentMaximumUnits(): Amount {
    return this.token.newAmountOf(
      this.totalNominal.value.dividedBy(this.nominal.value),
    );
  }

  digitalInstrumentValuationToken(): Token {
    return this.totalNominal.token;
  }

  instrumentAnnualPerformanceLog(): InstrumentAnnualPerformanceLogEntry[] {
    return [];
  }

  instrumentAssetClass(): AssetClassDEPRECATED | "" {
    return AssetClassDEPRECATED.Debt;
  }

  instrumentID(): string {
    return this.id;
  }

  instrumentInvestorProfile(): InvestorProfile | "" {
    return this.investorProfile;
  }

  instrumentIssueDate(): string {
    return this.issueDate;
  }

  instrumentMaturityDate(): string {
    return this.maturityDate;
  }

  instrumentName(): string {
    return this.name;
  }

  instrumentOwnerID(): string {
    return this.ownerID;
  }

  instrumentRiskProfile(): InstrumentRiskProfile | "" {
    return this.riskProfile;
  }

  instrumentState(): FinancialInstrumentState {
    return this.state;
  }

  instrumentSupportingDocuments(): Document[] {
    return this.supportingDocuments;
  }
}
