import React, { useEffect, useRef, useState } from "react";
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  CircularProgressProps,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Amount as LedgerAmount,
  LedgerAccountCategory,
  Token,
} from "james/ledger";
import {
  MarketListingViewModel,
  Reader as MarketListingViewReader,
} from "james/views/marketListingView";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";
import { Amount } from "components/Ledger/Amount";
import {
  CouldNotGetPrice,
  ExcessiveTruncationError,
  SpotType,
} from "pkgTemp/market";
import {
  Model as AccountViewModel,
  Model as StellarAccountViewModel,
} from "james/views/stellarAccountView";
import { useValidatedForm } from "hooks/useForm";
import {
  formDataUpdaterSpecs,
  formDataValidationFunc,
  FormData,
} from "./useValidatedForm";
import {
  Close as CloseIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Info as InfoIcon,
  Refresh as ReloadIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";
import { Determiner, ScopeFields } from "james/search/scope/Determiner";
import { Permission } from "james/security";
import {
  Mechanism,
  MechanismType,
  Spot,
  SpotStateController,
  SpotStateControllerServiceProviderName,
  SpotType as TheRealSpotType,
} from "james/market";
import {
  TextExactCriterion,
  TextListCriterion,
  TextNINListCriterion,
} from "james/search/criterion";
import { TextNumField } from "components/FormFields";
import {
  Model as LedgerTokenViewModel,
  Reader as LedgerTokenViewReader,
} from "james/views/ledgerTokenView";
import { TokenIconViewUpload } from "components/Ledger/Token";
import { useMarketContext } from "context/Market";
import dayjs from "dayjs";
import BigNumber from "bignumber.js";
import { useSnackbar } from "notistack";
import { useCurrentAPICall, useIsMounted } from "hooks";
import { NewSorting, Query } from "james/search/query";
import { ListingState } from "james/market/Listing";
import { AssetType } from "james/views/marketListingView/Model";
import range from "lodash/range";
import LogRocket from "logrocket";
import { userTypingAPICallDebounceIntervalMS } from "common/debouncing";
import { useAccountContext } from "context/Account/Account";
import { LedgerIDIdentifier } from "james/search/identifier";
import { useApplicationContext } from "context/Application/Application";
import { AssetEvent } from "const/logRocket";
import {
  DataComponentInfo,
  DataComponentTransaction,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { useErrorContext } from "context/Error";

const priceInfoText =
  "This is an indicative price and is subject to market movement at the time of execution. Please indicate slippage preferences below.";

const big0 = new BigNumber("0");
const big1 = new BigNumber("1");
const big100 = new BigNumber("100");
const priceValiditySeconds = new BigNumber("30");
const priceExpiryCountdownResolutionMilliseconds = 200;

export enum EstValueField {
  Pay = 1,
  Receive = 2,
}

export type SpotTradeDialogProps = {
  baseToken: Token;
  initialQuoteTokenCode?: string;
  listingViewModel?: MarketListingViewModel;
  sourceAccountID?: string;
  closeDialog: () => void;
};

// The root group pays no fees for spot trading
const rootGroupID = "01DD32GZ7R0000000000000001";

const loaderDialogContent = (
  <DialogContent
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: { sm: 565 },
      width: { sm: 420 },
    }}
  >
    <Box
      sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: "1fr",
        rowGap: theme.spacing(2),
        alignItems: "center",
        justifyItems: "center",
      })}
    >
      <CircularProgress size={70} />
      <Typography
        variant={"h5"}
        color={"textSecondary"}
        children={"Getting things ready for you..."}
      />
    </Box>
  </DialogContent>
);

export const SpotTradeDialog = (props: SpotTradeDialogProps) => {
  const { errorContextErrorTranslator } = useErrorContext();
  const isMounted = useIsMounted();
  const { authContext } = useApplicationContext();
  const { marketContextSpotCalculator, marketContextMonitorSpot } =
    useMarketContext();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { stellarAccountContext } = useAccountContext();
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const [potentialSourceAccounts, setPotentialSourceAccounts] = useState<
    StellarAccountViewModel[]
  >([]);
  const [potentialBaseTokenListings, setPotentialBaseTokenListings] = useState<
    MarketListingViewModel[]
  >([]);
  const [potentialQuoteAssetTokens, setPotentialQuoteAssetTokens] = useState<
    LedgerTokenViewModel[]
  >([]);
  const [
    formData,
    formDataValidationResult,
    formDataUpdate,
    formDataValidationInProgress,
  ] = useValidatedForm(
    formDataValidationFunc,
    async () => {
      const initialFormData: FormData = {
        baseTokenListingViewModel: props.listingViewModel,
        userID: authContext.userID,
        userKeys: stellarAccountContext.keys,
        spotType: SpotType.Buy,
        selectedSourceAccountViewModel: null,
        signatoryOnSourceAccount: true,
        payAmount: new LedgerAmount(),
        receiveAmount: props.baseToken.newAmountOf("0"),
        availablePayAssetBalance: new LedgerAmount(),
        feeAmount: new LedgerAmount(),
        estimatedPrice: new LedgerAmount(),
        path: [],
        slippage: new BigNumber("10"),
        estField: undefined,
        marketMechanism: new Mechanism(),
        recalculationRequired: true,
        ledgerTokenViewModel: new LedgerTokenViewModel(),
      };

      // retrieve data required in form
      try {
        await Promise.all([
          // get 50 base token listing view models
          // TODO: once there are more than 50 listings use a search with autocomplete
          //  that includes batch retrieves of some sort. This approach is better for
          //  now since it improves UX.
          (async () => {
            const retrievedPotentialBaseTokenListingViewModels = (
              await MarketListingViewReader.Read({
                context: authContext,
                criteria: {
                  $or: [
                    { listingState: TextExactCriterion(ListingState.Active) },
                    props.baseToken.toFilter(),
                  ],
                  assetType: TextListCriterion([
                    AssetType.RightsToAFiatCurrency,
                    AssetType.YieldBearingStablecoin,
                    AssetType.RightsToACryptoCurrency,
                    AssetType.CryptoCurrency,
                  ]),
                },
                query: new Query({
                  limit: 50,
                  offset: 0,
                  sorting: [NewSorting("id", "desc")],
                }),
              })
            ).models;

            // build an index of potential quote tokens
            const quoteTokenCodeIdx: {
              [key: string]: Token;
            } = {};
            let initialBaseTokenSpotMarketMechanism!: Mechanism;
            let initialBaseTokenListingViewModel!: MarketListingViewModel;
            retrievedPotentialBaseTokenListingViewModels.forEach((bt) => {
              // find spot market mechanism
              const spotMarketMechanism = bt.listingMarketMechanisms.find(
                (mm) => mm.type === MechanismType.Spot,
              );
              if (!spotMarketMechanism) {
                console.error(
                  `no spot market mechanism on listing retrieved for ${bt.token.code}`,
                );
                throw new Error(
                  `no spot market mechanism on listing retrieved for ${bt.token.code}`,
                );
              }

              // set initial base token market mechanism
              if (
                !(
                  initialBaseTokenSpotMarketMechanism ||
                  initialBaseTokenListingViewModel
                ) &&
                bt.token.isEqualTo(props.baseToken)
              ) {
                initialBaseTokenSpotMarketMechanism = spotMarketMechanism;
                initialBaseTokenListingViewModel = bt;
              }

              // add to quote token idx
              spotMarketMechanism.quoteParameters.forEach((qp) => {
                quoteTokenCodeIdx[qp.quoteToken.string()] = qp.quoteToken;
              });
            });
            if (
              !(
                initialBaseTokenSpotMarketMechanism ||
                initialBaseTokenListingViewModel
              )
            ) {
              console.error(
                "initial base token spot market mechanism and listing view model not among those retrieved",
              );
              throw new Error(
                "initial base token spot market mechanism and listing view model not among those retrieved",
              );
            }

            // get potential quote asset tokens
            const potentialQuoteAssetTokens = (
              await LedgerTokenViewReader.Read({
                criteria: {
                  $or: Object.keys(quoteTokenCodeIdx).map((tokenStr) =>
                    quoteTokenCodeIdx[tokenStr].toFilter(),
                  ),
                },
              })
            ).models;

            // determine initial quote token
            let initialQuoteToken: Token =
              initialBaseTokenSpotMarketMechanism.quoteParameters[0].quoteToken;
            if (props.initialQuoteTokenCode) {
              const initialQuoteTokenParameter =
                initialBaseTokenSpotMarketMechanism.quoteParameters.find(
                  (qp) => qp.quoteToken.code === props.initialQuoteTokenCode,
                );
              if (initialQuoteTokenParameter) {
                initialQuoteToken = initialQuoteTokenParameter.quoteToken;
              }
            }

            // set listing view model, spot market mechanism and starting pay & fee amount
            initialFormData.marketMechanism =
              initialBaseTokenSpotMarketMechanism;
            initialFormData.payAmount = initialQuoteToken.newAmountOf("0");
            initialFormData.feeAmount = initialQuoteToken.newAmountOf("0");
            initialFormData.estimatedPrice = initialQuoteToken.newAmountOf("0");
            if (!isMounted()) {
              return;
            }
            initialFormData.baseTokenListingViewModel =
              initialBaseTokenListingViewModel;

            if (isMounted()) {
              setPotentialBaseTokenListings(
                retrievedPotentialBaseTokenListingViewModels,
              );
              setPotentialQuoteAssetTokens(potentialQuoteAssetTokens);
            }
          })(),
          // retrieve the base token ledger token view model
          (async () => {
            initialFormData.ledgerTokenViewModel =
              await getLedgerTokenViewModel(props.baseToken);
          })(),
        ]);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error fetching required data: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `error fetching required data: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
        props.closeDialog();
      }

      return initialFormData;
    },
    formDataUpdaterSpecs,
    {
      baseTokenListingViewModel: props.listingViewModel,
      userID: authContext.userID,
      userKeys: [],
      spotType: SpotType.Buy,
      selectedSourceAccountViewModel: null,
      payAmount: new LedgerAmount(),
      receiveAmount: props.baseToken.newAmountOf("0"),
      signatoryOnSourceAccount: true,
      availablePayAssetBalance: new LedgerAmount(),
      feeAmount: new LedgerAmount(),
      estimatedPrice: new LedgerAmount(),
      path: [],
      slippage: new BigNumber("10"),
      estField: undefined,
      marketMechanism: new Mechanism(),
      recalculationRequired: false,
      ledgerTokenViewModel: new LedgerTokenViewModel(),
    },
    new Set<string>(["selectedSourceAccountViewModel", "marketMechanism"]),
  );

  const isBuy = formData.spotType === SpotType.Buy;
  const payIsEstimated = formData.estField === EstValueField.Pay;
  const receiveIsEstimated = formData.estField === EstValueField.Receive;

  const [spotSubmissionInProgress, setSpotSubmissionInProgress] =
    useState(false);
  const handleSubmitSpot = async () => {
    setSpotSubmissionInProgress(true);
    let spot: Spot;
    try {
      // confirm that data is set as expected
      if (
        !(
          formData.selectedSourceAccountViewModel &&
          formData.estField &&
          formData.baseTokenListingViewModel
        )
      ) {
        throw Error("unexpected undefined data");
      }

      // submit the spot trade
      spot = (
        await SpotStateController.SubmitSpot({
          context: authContext,
          sourceAccountID: formData.selectedSourceAccountViewModel.id,
          listingID: formData.baseTokenListingViewModel.listingID,
          spotType: formData.spotType as TheRealSpotType,
          baseAmount:
            formData.spotType === SpotType.Buy
              ? // BUY: pay Quote, receive Base
                formData.estField === EstValueField.Receive
                ? new LedgerAmount()
                : formData.receiveAmount
              : // SELL: pay Base, receive Quote
                formData.estField === EstValueField.Pay
                ? new LedgerAmount()
                : formData.payAmount,
          quoteAmount:
            formData.spotType === SpotType.Buy
              ? // BUY: pay Quote, receive Base
                formData.estField === EstValueField.Pay
                ? new LedgerAmount()
                : formData.payAmount
              : // SELL: pay Base, receive Quote
                formData.estField === EstValueField.Receive
                ? new LedgerAmount()
                : formData.receiveAmount,
          estimatedPrice: formData.estimatedPrice,
          slippage: formData.slippage,
          path: formData.path,
        })
      ).spot;

      // close the dialog immediately after spot submission
      props.closeDialog();

      // notify that submission is in progress
      enqueueSnackbar(`Trade #${spot.number} is being processed`, {
        variant: "info",
      });

      // track the spot submission in logrocket
      LogRocket.track(AssetEvent.doSpotTrade, {
        assetName: formData.baseTokenListingViewModel.assetName,
        assetShortName: formData.baseTokenListingViewModel.assetShortName,
        assetType: formData.baseTokenListingViewModel.assetType,
        revenue: formData.feeAmount.value.toNumber(),
      });
    } catch (e) {
      console.error(`error submitting spot`, e);
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(`Error Submitting Spot Trade: ${err.message}`, {
        variant: "info",
      });
      setSpotSubmissionInProgress(false);
      return;
    }

    try {
      // start monitoring spot
      await marketContextMonitorSpot(spot, {
        assetListingViewModel: formData.baseTokenListingViewModel,
        fee: formData.feeAmount.value,
        ledgerTokenViewModel: formData.ledgerTokenViewModel,
      });
    } catch (e) {
      console.error("error registering for notifications on spot trade", e);
      enqueueSnackbar(
        "Warning! Unable to Register for Notifications on Spot Trade - Please Check the Trade Table and Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  };

  const dialogContentRef = useRef<HTMLDivElement>(null);
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [spotCalculationInProgress, setSpotCalculationInProgress] =
    useState(false);
  const [errorGettingPrice, setErrorGettingPrice] = useState("");
  const [warningGettingPrice, setWarningGettingPrice] = useState("");
  const spotCalculationTimeoutRef = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );
  const [isCurrentCalculateSpotAPICall, initCalculateSpotAPICall] =
    useCurrentAPICall();
  const [waitingForCountDownToStart, setWaitingForCountdownToStart] =
    useState(false);
  useEffect(() => {
    // only recalculate if recalculation is required
    if (!formData.recalculationRequired) {
      return;
    }

    // if source account not found return
    if (!formData.selectedSourceAccountViewModel) {
      return;
    }

    // clear any existing error or warning getting the price
    setErrorGettingPrice("");
    setWarningGettingPrice("");

    // clear any existing calculation or price expiry countdown functions
    clearTimeout(spotCalculationTimeoutRef.current);

    // show spot calculation is in progress before it actually is
    // this is to show the loading indicator as soon as the user starts typing
    setSpotCalculationInProgress(true);

    // indicate waiting for countdown to start
    setWaitingForCountdownToStart(true);

    // initialise API call
    const { apiCallID } = initCalculateSpotAPICall();

    // set up calculation to take place after debounce interval
    spotCalculationTimeoutRef.current = setTimeout(async () => {
      // confirm source account is set
      if (!formData.selectedSourceAccountViewModel) {
        console.error("source account not set");
        return;
      }

      // determine base and quote tokens
      const quoteToken =
        formData.spotType === SpotType.Buy
          ? formData.payAmount.token
          : formData.receiveAmount.token;
      const baseToken =
        formData.spotType === SpotType.Buy
          ? formData.receiveAmount.token
          : formData.payAmount.token;

      // confirm market mechanism is set
      if (!formData.marketMechanism) {
        if (isMounted() && isCurrentCalculateSpotAPICall(apiCallID)) {
          setWarningGettingPrice(
            `Trading pair ${baseToken.code}/${quoteToken.code} is not supported`,
          );
          setSpotCalculationInProgress(false);
        }
        return;
      }

      // get associated market mechanism quote parameter
      const quoteParameter = formData.marketMechanism.quoteParameters.find(
        (qp) => qp.quoteToken.isEqualTo(quoteToken),
      );
      if (!quoteParameter) {
        console.error("cannot find associated quote parameter");
        return;
      }

      // a default indicative price should be shown if...
      const showDefaultIndicativePrice =
        // pay and receive amounts are zero OR
        (formData.payAmount.value.isZero() &&
          formData.receiveAmount.value.isZero()) ||
        // which field is estimated has not yet been determined OR
        formData.estField === undefined ||
        // receive field is estimated and pay amount is zero OR
        (formData.estField === EstValueField.Receive &&
          formData.payAmount.value.isZero()) ||
        // pay field is estimated receive amount is zero
        (formData.estField === EstValueField.Pay &&
          formData.receiveAmount.value.isZero());

      // If a default price should be shown then price the spot with
      // minimum deal parameters and zero the fee amount.
      if (showDefaultIndicativePrice) {
        try {
          // calculate spot with minimum base amount deal size
          const calculateSpotResponse =
            await marketContextSpotCalculator.CalculateSpot({
              noFee: true, // Never include fee for default indicative price generation
              spotType: formData.spotType,
              baseAmount: quoteParameter.minimumDealSize,
              quoteAmount: quoteToken.newAmountOf("0"),
            });

          // update spot calculation response with:
          // - base and quote amount as given
          // - price from spot pricing
          // - zero fee amount in either base or quote
          //   (depending on direction)
          // - path from spot pricing
          if (!(isMounted() && isCurrentCalculateSpotAPICall(apiCallID))) {
            return;
          }
          formDataUpdate.calculateSpotResponse({
            baseAmount:
              formData.spotType === SpotType.Buy
                ? formData.receiveAmount
                : formData.payAmount,
            quoteAmount: quoteToken.newAmountOf("0"),
            estimatedPrice: calculateSpotResponse.estimatedPrice,
            exactFeeAmount:
              formData.spotType === SpotType.Buy
                ? quoteToken.newAmountOf("0")
                : baseToken.newAmountOf("0"),
            estFeeAmount:
              formData.spotType === SpotType.Buy
                ? quoteToken.newAmountOf("0")
                : baseToken.newAmountOf("0"),
            path: calculateSpotResponse.path,
          });
        } catch (e) {
          if (!(isMounted() && isCurrentCalculateSpotAPICall(apiCallID))) {
            return;
          }

          // log all errors
          console.error(`error pricing spot: ${e}`);

          if (
            e instanceof ExcessiveTruncationError ||
            e instanceof CouldNotGetPrice
          ) {
            setWarningGettingPrice(
              "Default pricing parameters were too small to find a price. Please enter some values to begin.",
            );
          } else {
            setErrorGettingPrice(
              `Unexpected error trying to find a spot price for ${baseToken.code}/${quoteToken.code} in the market`,
            );
          }
        }
      } else {
        // confirm est. field is set
        if (formData.estField === undefined) {
          console.error("est. field not set");
          return;
        }

        // either base or quote amount are set, do full spot calculation with fee
        try {
          const calculateSpotResponse =
            await marketContextSpotCalculator.CalculateSpot({
              noFee:
                formData.selectedSourceAccountViewModel.ownerID === rootGroupID,
              spotType: formData.spotType,
              baseAmount:
                formData.spotType === SpotType.Buy
                  ? // BUY: pay Quote, receive Base
                    formData.estField === EstValueField.Receive
                    ? formData.receiveAmount.setValue("0")
                    : formData.receiveAmount
                  : // SELL: pay Base, receive Quote
                    formData.estField === EstValueField.Pay
                    ? formData.payAmount.setValue("0")
                    : formData.payAmount,
              quoteAmount:
                formData.spotType === SpotType.Buy
                  ? // BUY: pay Quote, receive Base
                    formData.estField === EstValueField.Pay
                    ? formData.payAmount.setValue("0")
                    : formData.payAmount
                  : // SELL: pay Base, receive Quote
                    formData.estField === EstValueField.Receive
                    ? formData.receiveAmount.setValue("0")
                    : formData.receiveAmount,
            });
          if (!(isMounted() && isCurrentCalculateSpotAPICall(apiCallID))) {
            return;
          }
          formDataUpdate.calculateSpotResponse(
            calculateSpotResponse,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ["payAmount", "receiveAmount"],
          );
        } catch (e) {
          if (!(isMounted() && isCurrentCalculateSpotAPICall(apiCallID))) {
            return;
          }

          // log all errors
          console.error(`error pricing spot: ${e}`);

          if (e instanceof ExcessiveTruncationError) {
            setWarningGettingPrice(
              formData.estField === EstValueField.Pay
                ? "The amount that is to be paid in this trade is too small to be processed. Try increasing receive amount."
                : "The amount that that will be received in this trade is too small to be processed. Try increasing pay amount.",
            );
          } else if (e instanceof CouldNotGetPrice) {
            setWarningGettingPrice(
              `Could not find a price for ${baseToken.code}/${quoteToken.code} in the market to do a trade with the given amounts. This may be due to insufficient liquidity in the market or values that are too small.  Please try different values.`,
            );
          } else {
            setErrorGettingPrice(
              `Unexpected error trying to find a price for ${baseToken.code}/${quoteToken.code} in the market`,
            );
          }
        }
      }
      if (!(isMounted() && isCurrentCalculateSpotAPICall(apiCallID))) {
        return;
      }

      // indicate that calculation is complete
      setSpotCalculationInProgress(false);

      // After 250ms indicate that waiting for timeout is over.
      // This delay is to account for delay between when calculation
      // ends and the countdown starts.
      setTimeout(() => {
        if (isMounted() && isCurrentCalculateSpotAPICall(apiCallID)) {
          setWaitingForCountdownToStart(false);
        }
      }, 250);
    }, userTypingAPICallDebounceIntervalMS);
  }, [
    formData.recalculationRequired,
    formData.payAmount,
    formData.receiveAmount,
    formData.selectedSourceAccountViewModel,
    potentialSourceAccounts,
  ]);

  // each time the estimated price changes start a new expiry countdown
  const priceExpiryCountdownIntervalRef = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );
  const [priceExpiryTime, setPriceExpiryTime] = useState(dayjs());
  const [tick, setTick] = useState(1);
  const [secondsToPriceExpiry, setSecondsToPriceExpiry] = useState(
    new BigNumber("0"),
  );
  const priceIsExpired = secondsToPriceExpiry.isLessThanOrEqualTo(big1);
  const countdownRelevant =
    (receiveIsEstimated || payIsEstimated) &&
    !spotSubmissionInProgress &&
    !(errorGettingPrice || warningGettingPrice) &&
    formDataValidationResult.valid;
  useEffect(() => {
    // clear any existing countdown interval
    clearInterval(priceExpiryCountdownIntervalRef.current);

    // do not set up a new countdown interval if:
    // - there was an error or warning getting the price OR
    // - countdown is not relevant
    if (!countdownRelevant) {
      return;
    }

    // reset seconds to expiry and get a new expiry time
    setSecondsToPriceExpiry(priceValiditySeconds);
    setPriceExpiryTime(dayjs().add(Number(priceValiditySeconds), "second"));

    priceExpiryCountdownIntervalRef.current = setInterval(() => {
      if (!isMounted()) {
        return;
      }
      setTick((oldTick) => oldTick + 1);
    }, priceExpiryCountdownResolutionMilliseconds);

    return () => clearInterval(priceExpiryCountdownIntervalRef.current);
  }, [formData.estimatedPrice, countdownRelevant]);
  useEffect(() => {
    const timeToExpiry = new BigNumber(
      priceExpiryTime.diff(dayjs(), "second", true),
    );
    const newSecondsToPriceExpiry = timeToExpiry.isLessThanOrEqualTo(big0)
      ? big0
      : timeToExpiry;
    if (newSecondsToPriceExpiry.isZero()) {
      clearInterval(priceExpiryCountdownIntervalRef.current);
    }
    if (!isMounted()) {
      return;
    }
    setSecondsToPriceExpiry(newSecondsToPriceExpiry);
  }, [tick]);
  useEffect(() => {
    // if fetch has not happened yet return
    if (stellarAccountContext.previousFetchTimeStamp === undefined) {
      return;
    }

    // payAmount is undefined return
    if (formData.payAmount.isUndefined()) {
      return;
    }

    // if source account is selected do nothing
    if (formData.selectedSourceAccountViewModel) {
      return;
    }

    // get potential source accounts and set selected account
    try {
      (async () => {
        // fetch potential source accounts
        const determineScopeCriteriaByRolesResponse =
          await Determiner.DetermineScopeCriteriaByRoles({
            context: authContext,
            service: new Permission({
              serviceName: "SubmitSpot",
              serviceProvider: SpotStateControllerServiceProviderName,
              description: "-",
            }),
            criteria: {
              category: TextNINListCriterion([
                LedgerAccountCategory.Issuance,
                LedgerAccountCategory.Clearance,
              ]),
            },
            scopeFields: [ScopeFields.OwnerIDField],
            buildScopeTree: true,
          });

        const ownerIDList: string[] =
          determineScopeCriteriaByRolesResponse.criteria.ownerID.list;

        const retrievedPotentialSourceAccounts: AccountViewModel[] =
          stellarAccountContext.accounts.filter(
            (val) =>
              ownerIDList.includes(val.ownerID) &&
              !(
                val.category === LedgerAccountCategory.Issuance ||
                val.category === LedgerAccountCategory.Clearance
              ),
          );

        if (!retrievedPotentialSourceAccounts.length) {
          console.error("expected at least 1 potential source account, got 0");
          throw new Error(
            "expected at least 1 potential source account, got 0",
          );
        }

        // set potential source accounts
        if (!isMounted()) {
          return;
        }

        // set selected account
        // if the props sourceAccountID is set then
        let chosenSourceAccountViewModel: StellarAccountViewModel;
        if (props.sourceAccountID) {
          const foundSourceAccountViewModel =
            retrievedPotentialSourceAccounts.find(
              (psa) => psa.id === props.sourceAccountID,
            );
          chosenSourceAccountViewModel =
            foundSourceAccountViewModel ?? retrievedPotentialSourceAccounts[0];
        } else {
          chosenSourceAccountViewModel = retrievedPotentialSourceAccounts[0];
        }
        formDataUpdate.selectedSourceAccViewModel(chosenSourceAccountViewModel);

        // determine if the user is a signatory on the chosen source account
        const userSignatoryOnSourceAccount =
          await stellarAccountContext.checkUserSignatoryOnAccount(
            LedgerIDIdentifier(
              chosenSourceAccountViewModel
                ? chosenSourceAccountViewModel.ledgerID
                : "",
            ),
          );
        formDataUpdate.userSignatoryOnSourceAccount(
          userSignatoryOnSourceAccount,
        );

        // if selected source account was initialised then set initial pay asset balance
        if (chosenSourceAccountViewModel) {
          const payAssetBalance = chosenSourceAccountViewModel.getTokenBalance(
            formData.payAmount.token,
          );

          formDataUpdate.availablePayAssetBalance(
            payAssetBalance
              ? payAssetBalance.availableBalance()
              : formData.payAmount.setValue("0"),
          );
        }

        setPotentialSourceAccounts(retrievedPotentialSourceAccounts);
      })();
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error fetching required data: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `error fetching required data: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
      props.closeDialog();
    }
  }, [
    formData.payAmount,
    formData.selectedSourceAccountViewModel,
    formDataUpdate,
    stellarAccountContext.previousFetchTimeStamp,
    stellarAccountContext.accounts.length,
    stellarAccountContext.error,
  ]);
  // prepare base and quote amount token section of their input adornment fields
  const baseAmountInputAdornmentTokenSection = (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option === value}
      id={
        "spotTradeDialog-baseAmountToken-autocomplete-textNumFieldInputAdornment"
      }
      filterSelectedOptions
      fullWidth
      noOptionsText={"No more base tokens"}
      readOnly // TODO: remove once base token switch is permitted
      popupIcon={null} // TODO: remove once base token switch is permitted
      options={(() => {
        const quoteToken =
          formData.spotType === SpotType.Buy
            ? formData.payAmount.token
            : formData.receiveAmount.token;
        return potentialBaseTokenListings.filter(
          (btl) => !btl.token.isEqualTo(quoteToken),
        );
      })()}
      value={potentialBaseTokenListings.find((bt) =>
        bt.token.isEqualTo(
          formData.spotType === SpotType.Buy
            ? formData.receiveAmount.token
            : formData.payAmount.token,
        ),
      )}
      disableClearable
      disabled={spotSubmissionInProgress}
      onChange={(_, selected: MarketListingViewModel | null) => {
        if (selected) {
          formDataUpdate.baseTokenListingViewModel(selected);
        }
      }}
      getOptionLabel={(option: MarketListingViewModel) => option.token.code}
      renderOption={(addProps, option: MarketListingViewModel) => (
        <li {...addProps}>
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: theme.spacing(1),
            })}
          >
            <TokenIconViewUpload
              disableChangeIcon
              size={23}
              token={option.token}
            />
            <Typography children={option.token.code} />
          </Box>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          id={
            "spotTradeDialog-baseAmountToken-autocompleteTextField-textNumFieldInputAdornment"
          }
          variant={"standard"}
          InputProps={{
            ...params.InputProps,
            sx: (theme) => ({
              ...theme.typography.subtitle1,
              color: "text.secondary",
            }),
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position={"start"}>
                <TokenIconViewUpload
                  disableChangeIcon
                  size={23}
                  token={
                    formData.spotType === SpotType.Buy
                      ? formData.receiveAmount.token
                      : formData.payAmount.token
                  }
                />
              </InputAdornment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            readOnly: true,
          }}
        />
      )}
    />
  );
  const quoteAmountInputAdornmentTokenSection = (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option === value}
      id={
        "spotTradeDialog-quoteAmountToken-autocomplete-textNumFieldInputAdornment"
      }
      filterSelectedOptions
      fullWidth
      noOptionsText={"No more quote tokens"}
      // options={potentialQuoteAssetTokens}
      options={(() => {
        const baseToken =
          formData.spotType === SpotType.Buy
            ? formData.receiveAmount.token
            : formData.payAmount.token;
        return potentialQuoteAssetTokens.filter(
          (btl) => !btl.token.isEqualTo(baseToken),
        );
      })()}
      value={potentialQuoteAssetTokens.find((qt) =>
        qt.token.isEqualTo(
          formData.spotType === SpotType.Buy
            ? formData.payAmount.token
            : formData.receiveAmount.token,
        ),
      )}
      disableClearable
      disabled={spotSubmissionInProgress}
      onChange={(_, selected: LedgerTokenViewModel | null) => {
        if (selected) {
          formDataUpdate.quoteAmountToken(selected.token);
        }
      }}
      getOptionLabel={(option: LedgerTokenViewModel) => option.token.code}
      renderOption={(addProps, option: LedgerTokenViewModel) => (
        <li {...addProps}>
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: theme.spacing(1),
            })}
          >
            <TokenIconViewUpload
              disableChangeIcon
              size={23}
              token={option.token}
            />
            <Typography children={option.token.code} />
          </Box>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          id={
            "spotTradeDialog-quoteAmountToken-autocompleteTextField-textNumFieldInputAdornment"
          }
          variant={"standard"}
          InputProps={{
            ...params.InputProps,
            sx: (theme) => ({
              ...theme.typography.subtitle1,
              color: "text.secondary",
            }),
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position={"start"}>
                <TokenIconViewUpload
                  disableChangeIcon
                  size={23}
                  token={
                    formData.spotType === SpotType.Buy
                      ? formData.payAmount.token
                      : formData.receiveAmount.token
                  }
                />
              </InputAdornment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            readOnly: true,
          }}
        />
      )}
    />
  );

  const tradeButton = (
    <Tooltip
      placement={isMobile ? "top" : "bottom"}
      arrow
      title={(() => {
        switch (true) {
          case !!formDataValidationResult.fieldValidations
            .selectedSourceAccountViewModel &&
            potentialSourceAccounts.length === 1:
            return "You are not a signatory on the trading account";
          case !formDataValidationResult.valid:
            return "Please ensure that all fields have been completed correctly";
          case priceIsExpired && countdownRelevant:
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: theme.spacing(1),
                }}
              >
                <Typography
                  color={"inherit"}
                  variant={"inherit"}
                  children={"Please refresh quoted price"}
                />
                <IconButton
                  id={"spotTradeDialog-quotedPriceRefreshToolTip-iconButton"}
                  size={"small"}
                  sx={{ color: "secondary.main" }}
                  disabled={
                    spotCalculationInProgress ||
                    spotSubmissionInProgress ||
                    !!formDataValidationResult.fieldValidations.marketMechanism
                  }
                  onClick={() => formDataUpdate.recalculationRequired()}
                >
                  <ReloadIcon />
                </IconButton>
              </Box>
            );
        }
        return "";
      })()}
    >
      <span>
        <Button
          sx={{
            height: {
              xs: "48px",
              sm: "33px",
            },
          }}
          id={"spotTradeDialog-trade-button"}
          fullWidth
          variant={"contained"}
          color={"primary"}
          children={"trade"}
          data-link-info={JSON.stringify({
            content_interaction_id: "transact-cta",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: `${isBuy ? "buy" : "sell"}-trade`,
            content_interaction_type: InteractionType.Button,
            content_interaction_driver: InteractionDriver.TransactionComplete,
          } as DataLinkInfoType)}
          disabled={
            priceIsExpired ||
            spotCalculationInProgress ||
            formDataValidationInProgress ||
            !formDataValidationResult.valid ||
            spotSubmissionInProgress
          }
          onClick={handleSubmitSpot}
        />
      </span>
    </Tooltip>
  );

  const dialogTitle = formData.baseTokenListingViewModel && (
    <DialogTitle
      sx={(theme) => ({
        width: { sm: 420 },
        padding: {
          sm: theme.spacing(1, 1, 1, 3),
          xs: theme.spacing(1, 1, 1, 2),
        },
      })}
    >
      <Grid container direction={"row"} spacing={2} alignItems={"center"}>
        <Grid item>
          <IconViewUpload
            size={64}
            disableChangeIcon
            token={formData.baseTokenListingViewModel.token}
          />
        </Grid>
        <Grid item>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            sx={(theme) => ({
              fontWeight: { sm: theme.typography.fontWeightBold },
            })}
            children={formData.baseTokenListingViewModel.assetName}
          />
          <Typography
            variant={"caption"}
            sx={(theme) => ({ color: theme.palette.text.secondary })}
            children={formData.baseTokenListingViewModel.assetShortName}
          />
        </Grid>
        {spotSubmissionInProgress && (
          <Grid item>
            <CircularProgress size={20} />
          </Grid>
        )}
      </Grid>
      <Box sx={{ alignSelf: "start" }}>
        <Tooltip title={"Close"} placement={"top"} arrow>
          <span>
            <IconButton
              id={"spotTradeDialog-close-iconButton"}
              size={"small"}
              onClick={props.closeDialog}
              disabled={spotSubmissionInProgress}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </DialogTitle>
  );

  if (stellarAccountContext.previousFetchTimeStamp === undefined)
    return (
      <Dialog open fullScreen={isMobile}>
        {loaderDialogContent}
      </Dialog>
    );
  return potentialSourceAccounts.length &&
    potentialQuoteAssetTokens.length &&
    formData.baseTokenListingViewModel &&
    !(
      formData.payAmount.isUndefined() || formData.receiveAmount.isUndefined()
    ) ? (
    <>
      <Dialog
        PaperProps={{
          "data-component-info": JSON.stringify({
            component_id: "asset_card",
            component_business_name: "asset_card",
            component_title: formData.baseTokenListingViewModel.assetShortName,
            component_driver: InteractionDriver.DriveTransaction,
          } as DataComponentInfo),
          "data-component-transaction": JSON.stringify({
            transaction_asset_buy_price:
              formData.estimatedPrice.value.toString(),
            transaction_asset_sell_price:
              formData.estimatedPrice.value.toString(),
            transaction_asset_id: `${formData.baseTokenListingViewModel.token.code}:${formData.baseTokenListingViewModel.token.code}:${formData.baseTokenListingViewModel.token.network}`,
            transaction_asset_name:
              formData.baseTokenListingViewModel.assetName,
            transaction_asset_issuer: formData.ledgerTokenViewModel.issuer,
            transaction_asset_type:
              formData.baseTokenListingViewModel.assetType,
            transaction_asset_risk_rating:
              formData.baseTokenListingViewModel.instrumentRiskProfile,
            transaction_asset_investor_profile:
              formData.baseTokenListingViewModel.instrumentRiskProfile,
            transaction_asset_currency: formData.payAmount.token.code,
          } as DataComponentTransaction),
        }}
        open
        fullScreen={isMobile}
      >
        {dialogTitle}
        <DialogContent
          ref={dialogContentRef}
          sx={{
            width: { sm: 420 },
            display: "flex",
            flexDirection: "column",
            alignItems: { sm: "center" },
            padding: "unset !important",
          }}
          className={isMobile ? undefined : "meshScroll"}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: { sm: "center" },
              padding: {
                xs: `${theme.spacing(3)} !important`,
                sm: `${theme.spacing(3, 5, 5, 5)} !important`,
              },
            }}
          >
            <Typography
              variant={isMobile ? "h4" : "subtitle1"}
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  fontWeight: theme.typography.fontWeightBold,
                },
                marginBottom: theme.spacing(3),
              })}
            >
              I would like to
            </Typography>
            <ButtonGroup
              id={"spotTradeDialog-buySell-buttonGroup"}
              disabled={spotSubmissionInProgress || spotCalculationInProgress}
              variant={"outlined"}
              aria-label={"split button"}
              color={"secondary"}
              sx={{
                width: { sm: 220 },
                marginBottom: theme.spacing(5),
              }}
              fullWidth={isMobile}
              disableFocusRipple={isMobile}
            >
              <Button
                id={"spotTradeDialog-buy-button"}
                disabled={spotSubmissionInProgress || spotCalculationInProgress}
                fullWidth
                sx={(theme) => ({
                  borderColor: isBuy ? theme.palette.secondary.main : undefined,
                  color: isBuy
                    ? `${theme.palette.secondary.main} !important`
                    : undefined,
                  backgroundColor: isBuy
                    ? `${alpha(theme.palette.secondary.main, 0.3)} !important`
                    : undefined,
                })}
                children={"buy"}
                onClick={() => {
                  if (formData.spotType === SpotType.Buy) {
                    return;
                  }
                  formDataUpdate.spotType(SpotType.Buy);
                }}
              />
              <Button
                id={"spotTradeDialog-sell-button"}
                data-link-info={JSON.stringify({
                  content_interaction_id: "transact-cta",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_type: InteractionType.Button,
                  content_interaction_text: "trade-sell",
                  content_interaction_driver:
                    InteractionDriver.TransactionComplete,
                } as DataLinkInfoType)}
                disabled={spotSubmissionInProgress || spotCalculationInProgress}
                fullWidth
                sx={(theme) => ({
                  borderColor: !isBuy
                    ? theme.palette.secondary.main
                    : undefined,
                  color: !isBuy
                    ? `${theme.palette.secondary.main} !important`
                    : undefined,
                  backgroundColor: !isBuy
                    ? `${alpha(theme.palette.secondary.main, 0.3)} !important`
                    : undefined,
                })}
                children={"sell"}
                onClick={() => {
                  if (formData.spotType === SpotType.Sell) {
                    return;
                  }
                  formDataUpdate.spotType(SpotType.Sell);
                }}
              />
            </ButtonGroup>
            {potentialSourceAccounts.length > 1 && (
              <Box sx={(theme) => ({ marginBottom: theme.spacing(5) })}>
                <Typography
                  sx={{
                    color: "text.disabled",
                    marginBottom: theme.spacing(1),
                  }}
                  variant={"body2"}
                >
                  Choose which account you would like to process the trade with:
                </Typography>
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option === value}
                  id={"spotTradeDialog-sourceAccountSelection-autocomplete"}
                  disabled={spotSubmissionInProgress}
                  fullWidth
                  value={potentialSourceAccounts.find(
                    (psa) =>
                      psa.id === formData.selectedSourceAccountViewModel?.id,
                  )}
                  onChange={(
                    e,
                    selectedSourceAccountViewModel: StellarAccountViewModel | null,
                  ) => {
                    if (selectedSourceAccountViewModel) {
                      formDataUpdate.selectedSourceAccViewModel(
                        selectedSourceAccountViewModel,
                      );
                    }
                  }}
                  options={potentialSourceAccounts}
                  getOptionLabel={(option: StellarAccountViewModel) =>
                    `${option.accountOwnerGroupName} ${option.category}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={
                        "spotTradeDialog-sourceAccountSelection-autocompleteTextField"
                      }
                      label={"Account"}
                      error={
                        !!formDataValidationResult.fieldValidations
                          .selectedSourceAccountViewModel
                      }
                      helperText={
                        formDataValidationResult.fieldValidations
                          .selectedSourceAccountViewModel
                      }
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                />
              </Box>
            )}
            <Card
              sx={(theme) => ({
                minHeight: "52px",
                borderRadius: theme.spacing(1),
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: theme.spacing(1, 2),
                width: "100%",
                backgroundColor: theme.palette.custom.midnight,
                marginBottom: theme.spacing(3),
              })}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant={"caption"}
                  color={"textSecondary"}
                  children={"Quoted Price"}
                />
                <Amount
                  amount={formData.estimatedPrice}
                  formatTextNumOpts={{
                    noDecimalPlaces: 7,
                    addDecimalPadding: false,
                  }}
                  codeTypographyProps={{
                    sx: {
                      color: (() => {
                        if (errorGettingPrice) {
                          return theme.palette.error.main;
                        } else if (
                          warningGettingPrice ||
                          spotCalculationInProgress ||
                          (priceIsExpired && countdownRelevant)
                        ) {
                          return "text.disabled";
                        }
                      })(),
                    },
                  }}
                  valueTypographyProps={{
                    sx: {
                      color: (() => {
                        if (errorGettingPrice) {
                          return theme.palette.error.main;
                        } else if (
                          warningGettingPrice ||
                          spotCalculationInProgress ||
                          (priceIsExpired && countdownRelevant)
                        ) {
                          return "text.disabled";
                        }
                      })(),
                    },
                  }}
                />
              </Box>
              {(() => {
                if (errorGettingPrice) {
                  return (
                    <Tooltip title={errorGettingPrice} arrow placement={"top"}>
                      <WarningIcon
                        id={"spotTradeDialog-quotedPriceError-icon"}
                        color={"error"}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            color: theme.palette.error.light,
                            textDecoration: "underline",
                          },
                        }}
                      />
                    </Tooltip>
                  );
                } else if (warningGettingPrice) {
                  return (
                    <Tooltip
                      title={warningGettingPrice}
                      arrow
                      placement={"top"}
                    >
                      <WarningIcon
                        id={"spotTradeDialog-quotedPriceWarning-icon"}
                        sx={{
                          color: theme.palette.warning.main,
                          cursor: "pointer",
                          "&:hover": {
                            color: theme.palette.warning.light,
                            textDecoration: "underline",
                          },
                        }}
                      />
                    </Tooltip>
                  );
                }

                return (
                  <Tooltip title={priceInfoText} arrow placement={"top"}>
                    <InfoIcon
                      id={"spotTradeDialog-quotedPriceInfo-icon"}
                      sx={{
                        color: "secondary.light",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                );
              })()}
              {spotCalculationInProgress || waitingForCountDownToStart ? (
                <Box
                  sx={{
                    width: 34,
                    height: 34,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <CircularProgress size={25} />
                </Box>
              ) : !priceIsExpired && countdownRelevant ? (
                <Box
                  sx={{
                    width: 34,
                    height: 34,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => formDataUpdate.recalculationRequired()}
                >
                  <CircularProgressWithLabel
                    size={25}
                    currentValue={secondsToPriceExpiry}
                    max={priceValiditySeconds}
                  />
                </Box>
              ) : (
                <IconButton
                  id={"spotTradeDialog-quotedPriceRefresh-iconButton"}
                  size={"small"}
                  sx={{ color: "secondary.main" }}
                  disabled={
                    spotCalculationInProgress ||
                    spotSubmissionInProgress ||
                    !!formDataValidationResult.fieldValidations.marketMechanism
                  }
                  onClick={() => formDataUpdate.recalculationRequired()}
                >
                  <ReloadIcon />
                </IconButton>
              )}
            </Card>
            <TextNumField
              id={"spotTradeDialog-pay-textNumField"}
              disabled={spotSubmissionInProgress}
              fullWidth
              noDecimalPlaces={7}
              label={payIsEstimated ? "Pay (Est.)" : "Pay"}
              disallowNegative
              value={formData.payAmount.value}
              onChange={(e) =>
                formDataUpdate.payAmount(
                  formData.payAmount.setValue(e.target.value),
                )
              }
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: 0,
                  paddingRight: "2px",
                  display: "grid",
                  alignItems: "center",
                  gridTemplateColumns: "1fr 185px",
                },
              }}
              InputProps={{
                sx: {
                  color:
                    payIsEstimated &&
                    (spotCalculationInProgress ||
                      (priceIsExpired && countdownRelevant) ||
                      warningGettingPrice ||
                      errorGettingPrice)
                      ? "text.disabled"
                      : undefined,
                },
                endAdornment: (
                  <InputAdornment
                    position={"end"}
                    sx={{ alignItems: "center" }}
                  >
                    <Button
                      id={"spotTradeDialog-paySetMax-button"}
                      variant={"text"}
                      color={"secondary"}
                      children={"max"}
                      onClick={() => {
                        if (formData.availablePayAssetBalance.value.isZero()) {
                          return;
                        }
                        formDataUpdate.payAmount(
                          formData.payAmount.setValue(
                            formData.availablePayAssetBalance.value,
                          ),
                        );
                      }}
                      sx={{
                        width: "39px !important",
                        minWidth: 0,
                        padding: theme.spacing(0, 0.5),
                        marginBottom: "-3px",
                      }}
                    />
                    <Box
                      sx={{
                        borderRight: `solid 1px ${theme.palette.text.disabled}`,
                        height: "23px",
                        marginBottom: "-3px",
                        marginRight: theme.spacing(1),
                        marginLeft: theme.spacing(2),
                      }}
                    />
                    <Divider orientation={"vertical"} />
                    {isBuy
                      ? quoteAmountInputAdornmentTokenSection
                      : baseAmountInputAdornmentTokenSection}
                  </InputAdornment>
                ),
              }}
              error={!!formDataValidationResult.fieldValidations.payAmount}
              helperText={formDataValidationResult.fieldValidations.payAmount}
            />
            {!formDataValidationResult.fieldValidations.payAmount && (
              <Box
                sx={(theme) => ({
                  marginLeft: theme.spacing(2),
                  marginBottom: theme.spacing(1),
                  alignSelf: "start",
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(1),
                })}
              >
                <Typography
                  children={"Available:"}
                  variant={"caption"}
                  sx={{ color: "text.disabled" }}
                />
                <Amount
                  id={"spotTradeDialog-payAvailableBalance-amount"}
                  amount={formData.availablePayAssetBalance}
                  formatTextNumOpts={{ noDecimalPlaces: 7 }}
                  codeTypographyProps={{
                    variant: "caption",
                    color: "textSecondary",
                  }}
                  valueTypographyProps={{
                    variant: "caption",
                    color: "textSecondary",
                  }}
                />
              </Box>
            )}
            <TextNumField
              id={"spotTradeDialog-receive-textNumField"}
              disabled={spotSubmissionInProgress}
              noDecimalPlaces={7}
              fullWidth
              label={receiveIsEstimated ? "Receive (Est.)" : "Receive"}
              disallowNegative
              value={formData.receiveAmount.value}
              onChange={(e) =>
                formDataUpdate.receiveAmount(
                  formData.receiveAmount.setValue(e.target.value),
                )
              }
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: 0,
                  display: "grid",
                  alignItems: "center",
                  gridTemplateColumns: "1fr 131px",
                },
              }}
              InputProps={{
                sx: {
                  color:
                    receiveIsEstimated &&
                    (spotCalculationInProgress ||
                      (priceIsExpired && countdownRelevant) ||
                      warningGettingPrice ||
                      errorGettingPrice)
                      ? "text.disabled"
                      : undefined,
                },
                endAdornment: (
                  <InputAdornment
                    position={"end"}
                    sx={{ alignItems: "center" }}
                  >
                    {isBuy
                      ? baseAmountInputAdornmentTokenSection
                      : quoteAmountInputAdornmentTokenSection}
                  </InputAdornment>
                ),
              }}
              error={!!formDataValidationResult.fieldValidations.receiveAmount}
              helperText={
                formDataValidationResult.fieldValidations.receiveAmount
              }
            />
            <Box
              sx={(theme) => ({
                margin: theme.spacing(3, 0, 5, 0),
                alignSelf: "start",
                display: "flex",
                flexDirection: "row",
                gap: theme.spacing(1),
              })}
            >
              <Typography
                children={"Trade Fee:"}
                variant={"caption"}
                sx={{ color: "text.disabled" }}
              />
              <Amount
                amount={formData.feeAmount}
                formatTextNumOpts={{ noDecimalPlaces: 7 }}
                codeTypographyProps={{
                  variant: "caption",
                  color: "textSecondary",
                }}
                valueTypographyProps={{
                  variant: "caption",
                  color: "textSecondary",
                }}
              />
              <Typography
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: theme.palette.secondary.light,
                    textDecoration: "underline",
                  },
                }}
                data-link-info={JSON.stringify({
                  content_interaction_id: "info-link",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_text: "Why these fees",
                  content_interaction_type: InteractionType.Link,
                  content_interaction_driver: InteractionDriver.Navigation,
                } as DataLinkInfoType)}
                variant={"caption"}
                color={"secondary"}
                onClick={() => window.open("https://mesh.trade/fees", "_blank")}
                children={"Why these fees?"}
              />
            </Box>
            <Box
              sx={(theme) => ({
                marginLeft: "-10px",
                alignSelf: "start",
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
                marginBottom: theme.spacing(3),
              })}
            >
              <IconButton
                size={"small"}
                color={"primary"}
                id={"spotTradeDialog-tradeBreakdownDisplayToggle-iconButton"}
                onClick={() => {
                  // open breakdown breakdown
                  setShowBreakdown(!showBreakdown);

                  // and scroll to the bottom in 500ms
                  range(10, 500, 10).forEach((v) =>
                    setTimeout(() => {
                      if (dialogContentRef.current) {
                        dialogContentRef.current.scrollTop =
                          dialogContentRef.current.scrollHeight;
                      }
                    }, v),
                  );
                }}
              >
                {showBreakdown ? (
                  <ExpandLessIcon
                    data-link-info={JSON.stringify({
                      content_interaction_id: "info-link",
                      content_interaction_action: InteractionAction.Click,
                      content_interaction_text: "Trade preview & Slippage",
                      content_interaction_type: InteractionType.Link,
                      content_interaction_driver: InteractionDriver.Navigation,
                    } as DataLinkInfoType)}
                    color={"primary"}
                  />
                ) : (
                  <ExpandMoreIcon
                    data-link-info={JSON.stringify({
                      content_interaction_id: "info-link",
                      content_interaction_action: InteractionAction.Click,
                      content_interaction_text: "Trade preview & Slippage",
                      content_interaction_type: InteractionType.Link,
                      content_interaction_driver: InteractionDriver.Navigation,
                    } as DataLinkInfoType)}
                    color={"primary"}
                  />
                )}
              </IconButton>
              <Typography
                variant={"h6"}
                children={"Trade Preview & Slippage"}
                data-link-info={JSON.stringify({
                  content_interaction_id: "info-link",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_text: "Trade preview & Slippage",
                  content_interaction_type: InteractionType.Link,
                  content_interaction_driver: InteractionDriver.Navigation,
                } as DataLinkInfoType)}
              />
            </Box>
            <Box
              sx={{
                alignSelf: "start",
                width: "100%",
              }}
            >
              <Collapse in={showBreakdown}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 1fr",
                    columnGap: theme.spacing(5),
                    paddingBottom: theme.spacing(3),
                    alignItems: "center",
                  }}
                >
                  {/* Pay */}
                  <Typography
                    children={"Pay:"}
                    variant={"caption"}
                    sx={{ color: "text.disabled" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}
                  >
                    <Amount
                      amount={formData.payAmount}
                      formatTextNumOpts={{ noDecimalPlaces: 7 }}
                      codeTypographyProps={{ variant: "caption" }}
                      valueTypographyProps={{ variant: "caption" }}
                    />
                    <Typography
                      variant={"caption"}
                      children={payIsEstimated ? "(Est.)" : ""}
                    />
                  </Box>

                  {/* Trade Fee */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}
                  >
                    <Typography
                      children={"Trade Fee:"}
                      variant={"caption"}
                      sx={{ color: "text.disabled" }}
                    />
                    <Tooltip
                      title={
                        "This indicates the 0.2% Mesh trade fee. Stellar fees are separate and already included in the price."
                      }
                      placement={isMobile ? "top" : "right"}
                    >
                      <InfoIcon
                        sx={{
                          color: "secondary.light",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}
                  >
                    <Amount
                      amount={formData.feeAmount}
                      formatTextNumOpts={{ noDecimalPlaces: 7 }}
                      codeTypographyProps={{ variant: "caption" }}
                      valueTypographyProps={{ variant: "caption" }}
                    />
                    <Typography variant={"caption"} children={"(Est.)"} />
                  </Box>

                  {/* Receive */}
                  <Typography
                    children={"Receive:"}
                    variant={"caption"}
                    sx={{ color: "text.disabled" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}
                  >
                    <Amount
                      amount={formData.receiveAmount}
                      formatTextNumOpts={{ noDecimalPlaces: 7 }}
                      codeTypographyProps={{ variant: "caption" }}
                      valueTypographyProps={{ variant: "caption" }}
                    />
                    <Typography
                      variant={"caption"}
                      children={receiveIsEstimated ? "(Est.)" : ""}
                    />
                  </Box>

                  <Box
                    sx={{
                      marginTop: theme.spacing(2),
                      gridColumn: "1/3",
                      display: "grid",
                      gridTemplateColumns: "120px 1fr",
                      alignItems: "center",
                      columnGap: theme.spacing(1),
                    }}
                  >
                    <TextNumField
                      id={"spotTradeDialog-slippage-textNumField"}
                      disabled={spotSubmissionInProgress}
                      label={"Slippage"}
                      disallowNegative
                      value={formData.slippage}
                      onChange={(e) => formDataUpdate.slippage(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            <Typography
                              color={"textSecondary"}
                              children={"%"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      error={
                        !!formDataValidationResult.fieldValidations.slippage
                      }
                      helperText={
                        formDataValidationResult.fieldValidations.slippage
                      }
                    />
                    <Tooltip
                      title={
                        "The amount of unfavorable price movement you are willing to tolerate between quoted and execution price"
                      }
                      placement={isMobile ? "top" : "right"}
                    >
                      <InfoIcon
                        sx={{
                          color: "secondary.light",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </Box>
                </Box>
              </Collapse>
              {!isMobile && <>{tradeButton}</>}
            </Box>
          </Box>
          {isMobile && (
            <Box
              sx={{
                zIndex: theme.zIndex.modal + 1,
                marginTop: "auto",
                position: "sticky",
                bottom: 0,
                backgroundColor: theme.palette.background.paper,
                padding: theme.spacing(3, 3, 4, 3),
                height: "104px",
                width: "100%",
                boxShadow: 24,
              }}
            >
              {tradeButton}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <>
      <Dialog open fullScreen={isMobile}>
        {dialogTitle}
        {loaderDialogContent}
      </Dialog>
    </>
  );
};

type CircularProgressWithLabelProps = CircularProgressProps & {
  currentValue: BigNumber;
  max: BigNumber;
};

function CircularProgressWithLabel(props: CircularProgressWithLabelProps) {
  const { currentValue, max, ...rest } = props;

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <CircularProgress
        variant={"determinate"}
        {...rest}
        value={currentValue.dividedBy(max).multipliedBy(big100).toNumber()}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"caption"}
          component={"div"}
          color={"text.secondary"}
          sx={{
            fontSize: "11px",
            lineHeight: "11px",
          }}
        >
          {currentValue.integerValue().toString()}
        </Typography>
      </Box>
    </Box>
  );
}
