import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
  Button,
  Tooltip,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Amount } from "components/Ledger/Amount";
import dayjs from "dayjs";
import { Model } from "james/views/ledgerAssetHolderView";

interface AssetHoldersDialogProps {
  closeDialog: () => void;
  open: boolean;
  assetHolderView: Model;
}

export const AssetHoldersDialog = ({
  open,
  closeDialog,
  assetHolderView,
}: AssetHoldersDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogTitle sx={{ pl: 3 }}>
        <Typography variant="h5">Subscription Information</Typography>
        <IconButton sx={{ ml: "auto " }} onClick={closeDialog}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 3,
            p: 3,
            pb: 4,
            pr: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 3,
              "& > *": {
                display: "flex",
                flexDirection: "column",
                gap: 3,
              },
            }}
          >
            <Box
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                display: "grid",
                gridTemplateColumns: "auto auto",
                "& > .title": {
                  fontWeight: 700,
                  color: theme.palette.text.primary,
                },
              })}
            >
              <Typography className="title">Name</Typography>
              <Typography>{assetHolderView.holderName}</Typography>
              <Typography className="title">Notes</Typography>
              <Amount
                rootStyles={{
                  "& > .Amount-tokenAmountIssuer": {
                    display: "none",
                  },
                }}
                amount={assetHolderView.amountHeld}
              />
              <Typography className="title">Issue Date</Typography>
              <Typography>
                {dayjs(assetHolderView.assetIssuanceDate).format(
                  "YYYY/MM/DD HH:mm",
                )}
              </Typography>
              <Typography className="title">Mesh Account</Typography>
              <Typography>{assetHolderView.accountNumber}</Typography>
              <Typography className="title">Ledger Account</Typography>
              <Tooltip title={assetHolderView.accountLedgerID}>
                <Typography noWrap sx={{ maxWidth: 180 }}>
                  {assetHolderView.accountLedgerID}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 3,
              "& > *": {
                display: "flex",
                flexDirection: "column",
                gap: 3,
              },
            }}
          >
            <Box
              sx={(theme) => ({
                color: theme.palette.text.primary,
                "& > *": {
                  fontWeight: 700,
                },
              })}
            >
              <Typography>Email</Typography>
              <Typography>Address</Typography>
            </Box>
            <Box sx={(theme) => ({ color: theme.palette.text.secondary })}>
              <Typography>{assetHolderView.holderEmailAddress}</Typography>
              <Box sx={(theme) => ({ color: theme.palette.text.secondary })}>
                <Typography>
                  {assetHolderView.holderAddress.addressLine1}
                </Typography>
                <Typography>
                  {assetHolderView.holderAddress.addressLine2}
                </Typography>
                <Typography>
                  {assetHolderView.holderAddress.postalCode}
                </Typography>
                <Typography>{assetHolderView.holderAddress.city}</Typography>
                <Typography>
                  {assetHolderView.holderAddress.province}
                </Typography>
                <Typography>
                  {assetHolderView.holderAddress.countryCode}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            px: 3,
            pb: 4,
          }}
        >
          <Button variant="outlined" onClick={closeDialog}>
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
