import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormHelperText,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  AddAPhotoRounded as AddAPhotoIcon,
  ReportProblem as KYCStatusBadIcon,
  VerifiedUser as KYCStatusGoodIcon,
} from "@mui/icons-material";
import cx from "classnames";
import {
  ClientManagerWrite,
  ClientProfile as ClientProfileEntity,
  ClientStatus,
} from "james/client";
import { IDIdentifier } from "james/search/identifier";
import { ImageSourceFromBase64 } from "utilities/image";
import { ValidateEmail } from "utilities/validation";
import { useSnackbar } from "notistack";
import { Manager, Profile as CompanyProfileClass } from "james/legal/company";
import isEqual from "lodash/isEqual";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
const PREFIX = "CompanyProfile";

const classes = {
  root: `${PREFIX}-root`,
  cardHeaderRootOverride: `${PREFIX}-cardHeaderRootOverride`,
  userProfileCardHeaderTitleLayout: `${PREFIX}-userProfileCardHeaderTitleLayout`,
  cardHeaderTitleText: `${PREFIX}-cardHeaderTitleText`,
  loadingCircularProgressWrapper: `${PREFIX}-loadingCircularProgressWrapper`,
  cardHeaderTitleButtonLayout: `${PREFIX}-cardHeaderTitleButtonLayout`,
  cardContentRootOverride: `${PREFIX}-cardContentRootOverride`,
  profilePictureLayout: `${PREFIX}-profilePictureLayout`,
  profilePictureAvatar: `${PREFIX}-profilePictureAvatar`,
  profilePictureAvatarNoImage: `${PREFIX}-profilePictureAvatarNoImage`,
  profilePictureVertical: `${PREFIX}-profilePictureVertical`,
  profilePictureHorizontal: `${PREFIX}-profilePictureHorizontal`,
  editPictureLabel: `${PREFIX}-editPictureLabel`,
  editPictureLabelDisabled: `${PREFIX}-editPictureLabelDisabled`,
  hiddenInput: `${PREFIX}-hiddenInput`,
  heading: `${PREFIX}-heading`,
  companyInfoTextArea: `${PREFIX}-companyInfoTextArea`,
  kycLayout: `${PREFIX}-kycLayout`,
  kycBadgeStatusLayout: `${PREFIX}-kycBadgeStatusLayout`,
  reviewKYCDetailsLabel: `${PREFIX}-reviewKYCDetailsLabel`,
  publiclyViewableToggleLayout: `${PREFIX}-publiclyViewableToggleLayout`,
  publiclyViewableSwitchText: `${PREFIX}-publiclyViewableSwitchText`,
  formField: `${PREFIX}-formField`,
  emailFormField: `${PREFIX}-emailFormField`,
  kycBadIcon: `${PREFIX}-kycBadIcon`,
  kycGoodIcon: `${PREFIX}-kycGoodIcon`,
  kycStatusBadMessage: `${PREFIX}-kycStatusBadMessage`,
  kycStatusGoodMessage: `${PREFIX}-kycStatusGoodMessage`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.companyInfoTextArea}`]: {
    color: theme.palette.text.primary,
    fontSize: 16,
    backgroundColor: theme.palette.background.paper,
    width: 508,
    maxWidth: 508,
  },

  [`& .${classes.kycLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto",
    gridTemplateRows: "auto auto auto",
    gridRowGap: theme.spacing(1),
  },

  [`& .${classes.kycBadgeStatusLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.reviewKYCDetailsLabel}`]: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    fontSize: 16,
    "&:hover": {
      textDecoration: "underline",
    },
  },

  [`& .${classes.publiclyViewableToggleLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.publiclyViewableSwitchText}`]: {
    color: theme.palette.text.primary,
    fontSize: 14,
  },

  [`& .${classes.formField}`]: {
    width: 220,
  },

  [`& .${classes.emailFormField}`]: {
    width: 448,
  },

  [`& .${classes.kycBadIcon}`]: {
    color: theme.palette.error.main,
    width: 44,
    height: 38,
  },

  [`& .${classes.kycGoodIcon}`]: {
    width: 44,
    height: 38,
    color: "#43ff23",
  },

  [`& .${classes.kycStatusBadMessage}`]: {
    color: theme.palette.error.main,
    fontSize: 16,
  },

  [`& .${classes.kycStatusGoodMessage}`]: {
    color: "#43ff23",
    fontSize: 16,
  },
}));

const clientProfileStates = {
  viewing: 0,
  editing: 1,
};

export const CompanyProfile = () => {
  const { errorContextErrorTranslator } = useErrorContext();
  const { myClient, authContext, loginClaims } = useApplicationContext();
  const [loading, setLoading] = useState(false);
  const [clientProfileState, setClientProfileState] = useState(
    clientProfileStates.viewing,
  );
  const [originalCompanyProfile, setOriginalCompanyProfile] = useState(
    new CompanyProfileClass(),
  );
  const [originalClientProfile, setOriginalClientProfile] = useState(
    new ClientProfileEntity(),
  );
  const [companyProfile, setCompanyProfile] = useState(
    new CompanyProfileClass(),
  );
  const [clientProfile, setClientProfile] = useState(new ClientProfileEntity());
  const [uploadInputRef, setUploadInputRef] = useState<HTMLInputElement | null>(
    null,
  );
  const [uploadClick, setUploadClick] = useState<(() => void) | null>(null);
  const [fieldValidation, setFieldValidation] = useState<{
    [key: string]: string | undefined;
  }>({});
  const { enqueueSnackbar } = useSnackbar();
  const profilePictureRef = useRef<HTMLImageElement>(null);
  const [profilePictureVertical, setProfilePictureVertical] = useState(false);

  useEffect(() => {
    if (!myClient) {
      return;
    }

    Manager.RetrieveMyCompanyProfile({
      context: authContext,
    }).then((response) => {
      setCompanyProfile(() => new CompanyProfileClass(response.profile));
    });

    setClientProfile({
      clientName: myClient.name,
      description: myClient.description,
      website: myClient.website,
      linkedIn: myClient.linkedIn,
      twitter: myClient.twitter,
      facebook: myClient.facebook,
      instagram: myClient.instagram,
      youtube: myClient.youtube,
      otherSite: myClient.otherSite,
    });
  }, [myClient]);

  const performValidation: () => { [key: string]: string | undefined } = () => {
    const newValidationState: { [key: string]: string | undefined } = {};
    if (companyProfile.companyRepresentative) {
      if (companyProfile.companyRepresentative.firstName === "") {
        newValidationState.firstName = "Cannot Be Blank";
      }
      if (companyProfile.companyRepresentative.lastName === "") {
        newValidationState.lastName = "Cannot Be Blank";
      }
      if (companyProfile.companyRepresentative.emailAddress === "") {
        newValidationState.emailAddress = "Cannot Be Blank";
      } else if (
        !ValidateEmail(companyProfile.companyRepresentative.emailAddress)
      ) {
        newValidationState.emailAddress = "Invalid";
      }
    }
    return newValidationState;
  };

  const handleClearValidation = (field: string) => {
    setFieldValidation({
      ...fieldValidation,
      [field]: undefined,
    });
  };

  const attachInputClick = (inputRef: HTMLInputElement) => {
    if (uploadInputRef !== null) {
      return;
    }
    if (!inputRef) {
      return;
    }
    if (uploadClick !== null) {
      return;
    }
    setUploadInputRef(inputRef);
    setUploadClick(() => () => {
      inputRef.click();
    });
  };

  const handleSelectPictureFile = async () => {
    if (uploadInputRef === null) {
      console.error("upload input ref is null");
      return;
    }
    try {
      if (uploadInputRef.files && uploadInputRef.files.length > 0) {
        const fileReader = new FileReader();
        fileReader.onloadend = async () => {
          const fileData: string | null = fileReader.result as string | null;
          if (fileData === null) {
            console.error("file data is null");
            return;
          }
          setLoading(true);
          try {
            const pictureData = fileData.slice(fileData.indexOf(",") + 1);
            const mimeType = fileData.slice(0, fileData.indexOf(","));
            await Manager.ChangeCompanyProfilePicture({
              context: authContext,
              clientIdentifier: IDIdentifier(loginClaims.clientID),
              pictureData,
              mimeType: mimeType.slice(
                mimeType.indexOf(":") + 1,
                mimeType.indexOf(";"),
              ),
            });
            companyProfile.profilePictureData = pictureData;
            companyProfile.profilePictureMIMEType = mimeType;
            setLoading(false);
            return;
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `error changing profile picture: ${
                err.message ? err.message : err.toString()
              }`,
            );
            enqueueSnackbar(
              `error changing profile picture: ${
                err.message ? err.message : err.toString()
              }`,
              { variant: "error" },
            );
          }
          setLoading(false);
          enqueueSnackbar("Image uploaded successfully", {
            variant: "success",
          });
        };
        fileReader.readAsDataURL(uploadInputRef.files[0]);
      } else {
        console.error("no upload input ref files");
        enqueueSnackbar("no upload input ref files", {
          variant: "error",
        });
      }
    } catch (e) {
      console.error("error trying to access picture file", e);
      enqueueSnackbar("error trying to access picture file", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const handleUpdate = async () => {
    // perform field validations
    const validations = performValidation();
    if (Object.keys(validations).length > 0) {
      // if there are any errors, return
      setFieldValidation(validations);
      return;
    }

    setLoading(true);

    try {
      await Manager.UpdateCompanyProfile({
        clientIdentifier: IDIdentifier(loginClaims.clientID),
        context: authContext,
        profile: companyProfile,
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error updating company profile: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `error updating company profile: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
      setLoading(false);
      return;
    }

    try {
      await ClientManagerWrite.UpdateClientProfile({
        clientIdentifier: IDIdentifier(loginClaims.clientID),
        context: authContext,
        profile: clientProfile,
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`Error Updating Company Profile: ${err.message}`);
      enqueueSnackbar(`error updating client profile: ${e}`, {
        variant: "error",
      });
    }

    setClientProfileState(clientProfileStates.viewing);
    setLoading(false);
  };

  if (!myClient || isEqual(companyProfile, new CompanyProfileClass())) {
    return (
      <Backdrop
        sx={(theme) => ({
          color: "#fff",
          zIndex: theme.zIndex.drawer + 1,
        })}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Root
      className="meshScroll"
      sx={{
        padding: 2,
        display: "grid",
        gridTemplateRows: "auto",
        overflowY: "auto",
        gridRowGap: 2,
        maxHeight: "100%",
      }}
    >
      <Card>
        <CardHeader
          sx={(theme) => ({
            height: "50px !important",
            padding: `${theme.spacing(3)} !important`,
          })}
          title={
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 1fr auto",
                alignItems: "center",
              }}
            >
              <Typography>Company profile</Typography>
              <Box
                sx={{
                  pl: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {loading && <CircularProgress size={25} />}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {(() => {
                  switch (clientProfileState) {
                    case clientProfileStates.viewing:
                      return (
                        <Button
                          disabled={loading}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setOriginalCompanyProfile(
                              new CompanyProfileClass(companyProfile),
                            );
                            setOriginalClientProfile(
                              new ClientProfileEntity(clientProfile),
                            );
                            setClientProfileState(clientProfileStates.editing);
                          }}
                        >
                          Edit
                        </Button>
                      );
                    case clientProfileStates.editing:
                      return (
                        <>
                          <Button
                            disabled={loading}
                            variant="outlined"
                            onClick={() => {
                              setCompanyProfile(
                                new CompanyProfileClass(originalCompanyProfile),
                              );
                              setClientProfile(
                                new ClientProfileEntity(originalClientProfile),
                              );
                              setFieldValidation({});
                              setClientProfileState(
                                clientProfileStates.viewing,
                              );
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={loading}
                            variant="contained"
                            color="primary"
                            onClick={handleUpdate}
                            sx={{ ml: 2 }}
                          >
                            Update
                          </Button>
                        </>
                      );
                    default:
                      return null;
                  }
                })()}
              </Box>
            </Box>
          }
        />
        <CardContent
          sx={{
            pb: 2,
            gap: 3,
            display: "grid",
            gridTemplateColumns: "auto 1fr",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", gap: 3 }}>
              <Box id="logo-container">
                <Typography
                  variant="h6"
                  sx={{ mb: 3 }}
                  children="Company Logo"
                />
                <Avatar
                  className={cx({
                    noImage: !companyProfile.profilePictureData,
                  })}
                  sx={(theme) => ({
                    width: 120,
                    height: 120,
                    cursor: "pointer",
                    alignItems: "start",
                    "&.noImage": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.text.primary,
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.dark,
                      },
                    },
                  })}
                  onClick={() => {
                    if (uploadClick != null) {
                      uploadClick();
                    }
                  }}
                >
                  {companyProfile.profilePictureData ? (
                    <Box
                      component={"img"}
                      alt=""
                      onLoad={() => {
                        if (profilePictureRef.current) {
                          setProfilePictureVertical(
                            profilePictureRef.current.naturalWidth <
                              profilePictureRef.current.naturalHeight,
                          );
                        }
                      }}
                      ref={profilePictureRef}
                      src={ImageSourceFromBase64(
                        companyProfile.profilePictureData,
                        companyProfile.profilePictureMIMEType,
                      )}
                      sx={{
                        "&.profilePictureVertical": {
                          width: 120,
                        },
                      }}
                      className={cx({
                        profilePictureVertical: profilePictureVertical,
                        profilePictureHorizontal: !profilePictureVertical,
                      })}
                    />
                  ) : (
                    <AddAPhotoIcon />
                  )}
                </Avatar>

                <Typography
                  onClick={() => {
                    if (loading) {
                      return;
                    }
                    if (uploadClick != null) {
                      uploadClick();
                    }
                  }}
                  variant="subtitle1"
                  textAlign="center"
                  sx={{ mt: 3 }}
                  className={cx(classes.editPictureLabel, {
                    [classes.editPictureLabelDisabled]: loading,
                  })}
                >
                  {companyProfile.profilePictureData
                    ? "Update Picture"
                    : "Add Picture"}
                </Typography>
                <input
                  disabled={loading}
                  ref={attachInputClick}
                  onChange={handleSelectPictureFile}
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  style={{
                    visibility: "hidden",
                    width: 0,
                    height: 0,
                  }}
                />
              </Box>
              <Box>
                <Typography variant="h6" sx={{ mb: 3 }}>
                  Company Bio
                </Typography>
                <TextareaAutosize
                  minRows={9}
                  maxRows={9}
                  value={clientProfile.description}
                  onChange={(e) => {
                    let newValue: string = e.target.value;
                    if (newValue.length >= 800) {
                      newValue = newValue.slice(0, 800);
                    }
                    handleClearValidation("companyDescription");
                    clientProfile.description = newValue;
                    setClientProfile(new ClientProfileEntity(clientProfile));
                  }}
                  placeholder="Description"
                  className={classes.companyInfoTextArea}
                  readOnly={clientProfileState !== clientProfileStates.editing}
                  disabled={loading}
                />
                <FormHelperText>
                  {800 - clientProfile.description.length} Characters Left
                </FormHelperText>
              </Box>
            </Box>

            <Box id="companyRepresentative-container" sx={{ mt: 2 }}>
              {companyProfile.companyRepresentative && (
                <>
                  <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Representative Details
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: 2,
                    }}
                  >
                    <TextField
                      margin="dense"
                      label="First Name"
                      variant="outlined"
                      value={companyProfile.companyRepresentative?.firstName}
                      onChange={(e) => {
                        handleClearValidation("firstName");
                        if (companyProfile.companyRepresentative) {
                          companyProfile.companyRepresentative.firstName =
                            e.target.value;
                        }
                        setCompanyProfile(
                          new CompanyProfileClass(companyProfile),
                        );
                      }}
                      error={!!fieldValidation.firstName}
                      helperText={fieldValidation.firstName}
                      InputProps={{
                        readOnly:
                          clientProfileState !== clientProfileStates.editing,
                      }}
                      disabled={loading}
                    />
                    <TextField
                      margin="dense"
                      label="Middle Names"
                      variant="outlined"
                      value={companyProfile.companyRepresentative.middleNames}
                      onChange={(e) => {
                        handleClearValidation("middleNames");
                        if (companyProfile.companyRepresentative) {
                          companyProfile.companyRepresentative.middleNames =
                            e.target.value;
                        }
                        setCompanyProfile(
                          new CompanyProfileClass(companyProfile),
                        );
                      }}
                      error={!!fieldValidation.middleNames}
                      helperText={fieldValidation.middleNames}
                      InputProps={{
                        readOnly:
                          clientProfileState !== clientProfileStates.editing,
                      }}
                      disabled={loading}
                    />
                    <TextField
                      margin="dense"
                      label="Last Name"
                      variant="outlined"
                      value={companyProfile.companyRepresentative.lastName}
                      onChange={(e) => {
                        handleClearValidation("lastName");
                        if (companyProfile.companyRepresentative) {
                          companyProfile.companyRepresentative.lastName =
                            e.target.value;
                        }
                        setCompanyProfile(
                          new CompanyProfileClass(companyProfile),
                        );
                      }}
                      error={!!fieldValidation.lastName}
                      helperText={fieldValidation.lastName}
                      InputProps={{
                        readOnly:
                          clientProfileState !== clientProfileStates.editing,
                      }}
                      disabled={loading}
                    />
                  </Box>

                  <Typography
                    sx={{
                      mt: 2,
                      mb: 1,
                    }}
                  >
                    Contact Details
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      maxWidth: "440px",
                      rowGap: 1,
                      columnGap: 2,
                      gridTemplate: `
                      "a a"
                      "b c"
                    `,
                      gridTemplateColumns: "1fr 1fr",
                    }}
                  >
                    <TextField
                      margin="dense"
                      label="Email"
                      variant="outlined"
                      sx={{ gridArea: "a" }}
                      value={companyProfile.companyRepresentative.emailAddress}
                      onChange={(e) => {
                        handleClearValidation("emailAddress");
                        if (companyProfile.companyRepresentative) {
                          companyProfile.companyRepresentative.emailAddress =
                            e.target.value;
                        }
                        setCompanyProfile(
                          new CompanyProfileClass(companyProfile),
                        );
                      }}
                      error={!!fieldValidation.emailAddress}
                      helperText={fieldValidation.emailAddress}
                      InputProps={{
                        readOnly:
                          clientProfileState !== clientProfileStates.editing,
                      }}
                      disabled={loading}
                    />
                    <TextField
                      sx={{ gridArea: "b" }}
                      margin="dense"
                      className={classes.formField}
                      label="Mobile"
                      variant="outlined"
                      value={
                        companyProfile.companyRepresentative.cellphoneNumber
                      }
                      onChange={(e) => {
                        handleClearValidation("cellphoneNumber");
                        if (companyProfile.companyRepresentative) {
                          companyProfile.companyRepresentative.cellphoneNumber =
                            e.target.value;
                        }
                        setCompanyProfile(
                          new CompanyProfileClass(companyProfile),
                        );
                      }}
                      error={!!fieldValidation.cellphoneNumber}
                      helperText={fieldValidation.cellphoneNumber}
                      InputProps={{
                        readOnly:
                          clientProfileState !== clientProfileStates.editing,
                      }}
                      disabled={loading}
                    />
                    <TextField
                      sx={{ gridArea: "c" }}
                      margin="dense"
                      className={classes.formField}
                      label="Telephone"
                      variant="outlined"
                      value={
                        companyProfile.companyRepresentative.telephoneNumber
                      }
                      onChange={(e) => {
                        handleClearValidation("telephoneNumber");
                        if (companyProfile.companyRepresentative) {
                          companyProfile.companyRepresentative.telephoneNumber =
                            e.target.value;
                        }
                        setCompanyProfile(
                          new CompanyProfileClass(companyProfile),
                        );
                      }}
                      error={!!fieldValidation.telephoneNumber}
                      helperText={fieldValidation.telephoneNumber}
                      InputProps={{
                        readOnly:
                          clientProfileState !== clientProfileStates.editing,
                      }}
                      disabled={loading}
                    />
                  </Box>
                </>
              )}

              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                Public Company Support Details
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: 2,
                  maxWidth: 440,
                }}
              >
                <TextField
                  margin="dense"
                  className={classes.formField}
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  value={companyProfile.publicContactInfo?.firstName}
                  onChange={(e) => {
                    handleClearValidation("publicContactInfoFirstName");
                    if (companyProfile.publicContactInfo) {
                      companyProfile.publicContactInfo.firstName =
                        e.target.value;
                    }
                    setCompanyProfile(new CompanyProfileClass(companyProfile));
                  }}
                  error={!!fieldValidation.publicContactInfoFirstName}
                  helperText={fieldValidation.publicContactInfoFirstName}
                  InputProps={{
                    readOnly:
                      clientProfileState !== clientProfileStates.editing,
                  }}
                  disabled={loading}
                />
                <TextField
                  margin="dense"
                  fullWidth
                  className={classes.formField}
                  label="Last Name"
                  variant="outlined"
                  value={companyProfile.publicContactInfo?.lastName}
                  onChange={(e) => {
                    handleClearValidation("publicContactInfoLastName");
                    if (companyProfile.publicContactInfo) {
                      companyProfile.publicContactInfo.lastName =
                        e.target.value;
                    }
                    setCompanyProfile(new CompanyProfileClass(companyProfile));
                  }}
                  error={!!fieldValidation.publicContactInfoLastName}
                  helperText={fieldValidation.publicContactInfoLastName}
                  InputProps={{
                    readOnly:
                      clientProfileState !== clientProfileStates.editing,
                  }}
                  disabled={loading}
                />
              </Box>

              <Typography sx={{ mt: 2, mb: 1 }}>Contact Details</Typography>
              <Box
                sx={{
                  display: "grid",
                  maxWidth: "440px",
                  rowGap: 1,
                  columnGap: 2,
                  gridTemplate: `
                      "a a"
                      "b c"
                    `,
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <TextField
                  margin="dense"
                  label="Email"
                  variant="outlined"
                  sx={{ gridArea: "a" }}
                  value={companyProfile.publicContactInfo?.emailAddress}
                  onChange={(e) => {
                    handleClearValidation("publicContactInfo.emailAddress");
                    if (companyProfile.publicContactInfo) {
                      companyProfile.publicContactInfo.emailAddress =
                        e.target.value;
                    }
                    setCompanyProfile(new CompanyProfileClass(companyProfile));
                  }}
                  error={!!fieldValidation.publicContactInfoEmailAddress}
                  helperText={fieldValidation.publicContactInfoEmailAddress}
                  InputProps={{
                    readOnly:
                      clientProfileState !== clientProfileStates.editing,
                  }}
                  disabled={loading}
                />
                <TextField
                  sx={{ gridArea: "b" }}
                  margin="dense"
                  className={classes.formField}
                  label="Mobile"
                  variant="outlined"
                  value={companyProfile.publicContactInfo?.cellphoneNumber}
                  onChange={(e) => {
                    handleClearValidation("cellphoneNumber");
                    if (companyProfile.publicContactInfo) {
                      companyProfile.publicContactInfo.cellphoneNumber =
                        e.target.value;
                    }
                    setCompanyProfile(new CompanyProfileClass(companyProfile));
                  }}
                  error={!!fieldValidation.publicContactInfoCellphoneNumber}
                  helperText={fieldValidation.publicContactInfoCellphoneNumber}
                  InputProps={{
                    readOnly:
                      clientProfileState !== clientProfileStates.editing,
                  }}
                  disabled={loading}
                />
                <TextField
                  sx={{ gridArea: "c" }}
                  margin="dense"
                  className={classes.formField}
                  label="Telephone"
                  variant="outlined"
                  value={companyProfile.publicContactInfo?.telephoneNumber}
                  onChange={(e) => {
                    handleClearValidation("publicContactInfoTelephoneNumber");
                    if (companyProfile.publicContactInfo) {
                      companyProfile.publicContactInfo.telephoneNumber =
                        e.target.value;
                    }
                    setCompanyProfile(new CompanyProfileClass(companyProfile));
                  }}
                  error={!!fieldValidation.publicContactInfoTelephoneNumber}
                  helperText={fieldValidation.publicContactInfoTelephoneNumber}
                  InputProps={{
                    readOnly:
                      clientProfileState !== clientProfileStates.editing,
                  }}
                  disabled={loading}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography variant="h6" sx={{ mb: 3 }}>
                KYC Status
              </Typography>
              <div className={classes.kycLayout}>
                <div className={classes.kycBadgeStatusLayout}>
                  {loginClaims.clientStatus === ClientStatus.ActiveStatus ? (
                    <>
                      <KYCStatusGoodIcon className={classes.kycGoodIcon} />
                      <div className={classes.kycStatusGoodMessage}>
                        Company KYC Complete
                      </div>
                    </>
                  ) : (
                    <>
                      <KYCStatusBadIcon className={classes.kycBadIcon} />
                      <div className={classes.kycStatusBadMessage}>
                        Company KYC Not Complete
                      </div>
                    </>
                  )}
                </div>
                <div className={classes.reviewKYCDetailsLabel}>
                  Review KYC Details
                </div>
                <div className={classes.publiclyViewableToggleLayout}>
                  <Switch color="primary" />
                  <div className={classes.publiclyViewableSwitchText}>
                    Publicly Viewable
                  </div>
                </div>
              </div>
            </Box>
            <Box id="webLinks-container">
              <Typography variant="h6">Web Links</Typography>
              <TextField
                margin="dense"
                className={classes.formField}
                label="Website"
                variant="outlined"
                value={clientProfile.website}
                onChange={(e) => {
                  handleClearValidation("companyWebsite");
                  clientProfile.website = e.target.value;
                  setClientProfile(new ClientProfileEntity(clientProfile));
                }}
                error={!!fieldValidation.companyWebsite}
                helperText={fieldValidation.companyWebsite}
                InputProps={{
                  readOnly: clientProfileState !== clientProfileStates.editing,
                }}
                disabled={loading}
              />
              <TextField
                margin="dense"
                className={classes.formField}
                label="Linked-in"
                variant="outlined"
                value={clientProfile.linkedIn}
                onChange={(e) => {
                  handleClearValidation("companyLinkedIn");
                  clientProfile.linkedIn = e.target.value;
                  setClientProfile(new ClientProfileEntity(clientProfile));
                }}
                error={!!fieldValidation.companyLinkedIn}
                helperText={fieldValidation.companyLinkedIn}
                InputProps={{
                  readOnly: clientProfileState !== clientProfileStates.editing,
                }}
                disabled={loading}
              />
              <TextField
                margin="dense"
                className={classes.formField}
                label="Twitter"
                variant="outlined"
                value={clientProfile.twitter}
                onChange={(e) => {
                  handleClearValidation("companyTwitter");
                  clientProfile.twitter = e.target.value;
                  setClientProfile(new ClientProfileEntity(clientProfile));
                }}
                error={!!fieldValidation.companyTwitter}
                helperText={fieldValidation.companyTwitter}
                InputProps={{
                  readOnly: clientProfileState !== clientProfileStates.editing,
                }}
                disabled={loading}
              />
              <TextField
                margin="dense"
                className={classes.formField}
                label="Facebook"
                variant="outlined"
                value={clientProfile.facebook}
                onChange={(e) => {
                  handleClearValidation("companyFacebook");
                  clientProfile.facebook = e.target.value;
                  setClientProfile(new ClientProfileEntity(clientProfile));
                }}
                error={!!fieldValidation.companyFacebook}
                helperText={fieldValidation.companyFacebook}
                InputProps={{
                  readOnly: clientProfileState !== clientProfileStates.editing,
                }}
                disabled={loading}
              />
              <TextField
                margin="dense"
                className={classes.formField}
                label="Instagram"
                variant="outlined"
                value={clientProfile.instagram}
                onChange={(e) => {
                  handleClearValidation("companyInstagram");
                  clientProfile.instagram = e.target.value;
                  setClientProfile(new ClientProfileEntity(clientProfile));
                }}
                error={!!fieldValidation.companyInstagram}
                helperText={fieldValidation.companyInstagram}
                InputProps={{
                  readOnly: clientProfileState !== clientProfileStates.editing,
                }}
                disabled={loading}
              />
              <TextField
                margin="dense"
                className={classes.formField}
                label="Other Site"
                variant="outlined"
                value={clientProfile.otherSite}
                onChange={(e) => {
                  handleClearValidation("companyOtherSite");
                  clientProfile.otherSite = e.target.value;
                  setClientProfile(new ClientProfileEntity(clientProfile));
                }}
                error={!!fieldValidation.companyOtherSite}
                helperText={fieldValidation.companyOtherSite}
                InputProps={{
                  readOnly: clientProfileState !== clientProfileStates.editing,
                }}
                disabled={loading}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Root>
  );
};
