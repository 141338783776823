import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { DigitalFloatingRateBond } from "./DigitalFloatingRateBond";
import { InvestorProfile } from "./InvestorProfile";
import { InstrumentRiskProfile } from "./InstrumentRiskProfile";
import { DebtSeniorityLevel } from "./DebtSeniorityLevel";
import { InstrumentOwnershipLimitations } from "./InstrumentOwnershipLimitations";
import { Amount } from "../ledger";
import BigNumber from "bignumber.js";
import { Calendar } from "./Calendar";
import { CouponFrequency } from "./CouponFrequency";
import { DayCountConvention } from "./DayCountConvention";
import { BusinessDayConvention } from "./BusinessDayConvention";
import { DateGenerationRule } from "./DateGenerationRule";

export type CreateDraftDigitalFloatingRateBondRequest = {
  context: Context;
  ownerID: string;
  isin: string;
  name: string;
  fractionalisationAllowed: boolean;
  investorProfile: InvestorProfile | string;
  investorProfileDescription: string;
  riskProfile: InstrumentRiskProfile | string;
  riskProfileDescription: string;
  debtSeniorityLevel: DebtSeniorityLevel | string;
  covenants: string;
  votingRights: boolean;
  votingRightsDescription: string;
  ownershipLimitations: InstrumentOwnershipLimitations;
  issuePrice: Amount;
  nominal: Amount;
  totalNominal: Amount;
  redemption: BigNumber;
  issueDate: string;
  maturityDate: string;
  calendar: Calendar;
  couponPaymentFrequency: CouponFrequency | string;
  firstCouponPaymentDate: string | null;
  nextToLastCouponPaymentDate: string | null;
  couponInterestCommencementDate: string | null;
  dayCountConvention: DayCountConvention | string;
  endOfMonthConvention: boolean;
  businessDayConvention: BusinessDayConvention | string;
  terminationDateBusinessDayConvention: BusinessDayConvention | string;
  dateGenerationRule: DateGenerationRule;
  fixDays: number;
  exCouponDays: number;
  recordDays: number;
};

export type CreateDraftDigitalFloatingRateBondResponse = {
  digitalFloatingRateBond: DigitalFloatingRateBond;
};

export type PreIssueDigitalFloatingRateBondRequest = {
  context: Context;
  digitalFloatingRateBondID: string;
};

export type PreIssueDigitalFloatingRateBondResponse = {
  digitalFloatingRateBond: DigitalFloatingRateBond;
};

export const DigitalFloatingRateBondStateControllerServiceProviderName =
  "financial-DigitalFloatingRateBondStateController";

export const DigitalFloatingRateBondStateController = {
  async CreateDraftDigitalFloatingRateBond(
    request: CreateDraftDigitalFloatingRateBondRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CreateDraftDigitalFloatingRateBondResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DigitalFloatingRateBondStateControllerServiceProviderName}.CreateDraftDigitalFloatingRateBond`,
        request,
      },
      opts,
    );

    return {
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        response.digitalFloatingRateBond,
      ),
    };
  },
  async PreIssueDigitalFloatingRateBond(
    request: PreIssueDigitalFloatingRateBondRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<PreIssueDigitalFloatingRateBondResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${DigitalFloatingRateBondStateControllerServiceProviderName}.PreIssueDigitalFloatingRateBond`,
        request,
      },
      opts,
    );

    return {
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        response.digitalFloatingRateBond,
      ),
    };
  },
};
