import React, { useContext, useMemo } from "react";
import { FeeScheduleCreatorPromiseClient } from "@mesh/common-js/dist/remuneration/feeScheduleCreator_grpc_web_pb";
import { FeeScheduleUpdaterPromiseClient } from "@mesh/common-js/dist/remuneration/feeScheduleUpdater_grpc_web_pb";
import { FeeScheduleReaderPromiseClient } from "@mesh/common-js/dist/remuneration/feeScheduleReader_meshproto_grpc_web_pb";
import { SmartInstrumentRateResetGeneratorPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentRateResetGenerator_grpc_web_pb";
import { SmartInstrumentCalculatorPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentCalculator_grpc_web_pb";
import { SmartInstrumentStateControllerPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentStateController_grpc_web_pb";
import { SmartInstrumentUpdaterPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentUpdater_grpc_web_pb";
import { SmartInstrumentReaderPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentReader_meshproto_grpc_web_pb";
import { SmartInstrumentReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentReaderUNSCOPED_meshproto_grpc_web_pb";
import { RateResetCorporateActionReaderPromiseClient } from "@mesh/common-js/dist/financial/rateResetCorporateActionReader_meshproto_grpc_web_pb";
import { RateResetCorporateActionReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/rateResetCorporateActionReaderUNSCOPED_meshproto_grpc_web_pb";
import { PaymentDeferralCorporateActionReaderPromiseClient } from "@mesh/common-js/dist/financial/paymentDeferralCorporateActionReader_meshproto_grpc_web_pb";
import { PaymentDeferralCorporateActionReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/paymentDeferralCorporateActionReaderUNSCOPED_meshproto_grpc_web_pb";
import { AssetflowReaderPromiseClient } from "@mesh/common-js/dist/financial/assetflowReader_meshproto_grpc_web_pb";
import { AssetflowReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/assetflowReaderUNSCOPED_meshproto_grpc_web_pb";
import { PaymentReaderPromiseClient } from "@mesh/common-js/dist/financial/paymentReader_meshproto_grpc_web_pb";
import { PaymentReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/paymentReaderUNSCOPED_meshproto_grpc_web_pb";
import { PublicSubscriptionCommitmentManagerPromiseClient } from "@mesh/common-js/dist/market/publicSubscriptionCommitmentManager_grpc_web_pb";
import { SubscriptionCommitmentManagerPromiseClient } from "@mesh/common-js/dist/market/subscriptionCommitmentManager_grpc_web_pb";
import config from "react-global-configuration";
import { LoggingInterceptor } from "./LoggingInterceptor";

export type API = {
  remuneration: {
    feeScheduleCreator: FeeScheduleCreatorPromiseClient;
    feeScheduleUpdater: FeeScheduleUpdaterPromiseClient;
    feeScheduleReader: FeeScheduleReaderPromiseClient;
  };
  financial: {
    smartInstrumentRateResetGenerator: SmartInstrumentRateResetGeneratorPromiseClient;
    smartInstrumentCalculator: SmartInstrumentCalculatorPromiseClient;
    smartInstrumentStateController: SmartInstrumentStateControllerPromiseClient;
    smartInstrumentUpdater: SmartInstrumentUpdaterPromiseClient;
    smartInstrumentReader: SmartInstrumentReaderPromiseClient;
    smartInstrumentReaderUNSCOPED: SmartInstrumentReaderUNSCOPEDPromiseClient;
    rateResetCorporateActionReader: RateResetCorporateActionReaderPromiseClient;
    rateResetCorporateActionReaderUNSCOPED: RateResetCorporateActionReaderUNSCOPEDPromiseClient;
    paymentDeferralCorporateActionReader: PaymentDeferralCorporateActionReaderPromiseClient;
    paymentDeferralCorporateActionReaderUNSCOPED: PaymentDeferralCorporateActionReaderUNSCOPEDPromiseClient;
    assetflowReader: AssetflowReaderPromiseClient;
    assetflowReaderUNSCOPED: AssetflowReaderUNSCOPEDPromiseClient;
    paymentReader: PaymentReaderPromiseClient;
    paymentReaderUNSCOPED: PaymentReaderUNSCOPEDPromiseClient;
  };
  market: {
    subscriptionCommitmentManager: SubscriptionCommitmentManagerPromiseClient;
    publicSubscriptionCommitmentManager: PublicSubscriptionCommitmentManagerPromiseClient;
  };
};

const defaultContext: API = {
  remuneration: {
    feeScheduleCreator: new FeeScheduleCreatorPromiseClient(""),
    feeScheduleUpdater: new FeeScheduleUpdaterPromiseClient(""),
    feeScheduleReader: new FeeScheduleReaderPromiseClient(""),
  },
  financial: {
    smartInstrumentRateResetGenerator:
      new SmartInstrumentRateResetGeneratorPromiseClient(""),
    smartInstrumentCalculator: new SmartInstrumentCalculatorPromiseClient(""),
    smartInstrumentStateController:
      new SmartInstrumentStateControllerPromiseClient(""),
    smartInstrumentUpdater: new SmartInstrumentUpdaterPromiseClient(""),
    smartInstrumentReader: new SmartInstrumentReaderPromiseClient(""),
    smartInstrumentReaderUNSCOPED:
      new SmartInstrumentReaderUNSCOPEDPromiseClient(""),
    rateResetCorporateActionReader:
      new RateResetCorporateActionReaderPromiseClient(""),
    rateResetCorporateActionReaderUNSCOPED:
      new RateResetCorporateActionReaderUNSCOPEDPromiseClient(""),
    paymentDeferralCorporateActionReader:
      new PaymentDeferralCorporateActionReaderPromiseClient(""),
    paymentDeferralCorporateActionReaderUNSCOPED:
      new PaymentDeferralCorporateActionReaderUNSCOPEDPromiseClient(""),
    assetflowReader: new AssetflowReaderPromiseClient(""),
    assetflowReaderUNSCOPED: new AssetflowReaderUNSCOPEDPromiseClient(""),
    paymentReader: new PaymentReaderPromiseClient(""),
    paymentReaderUNSCOPED: new PaymentReaderUNSCOPEDPromiseClient(""),
  },
  market: {
    subscriptionCommitmentManager:
      new SubscriptionCommitmentManagerPromiseClient(""),
    publicSubscriptionCommitmentManager:
      new PublicSubscriptionCommitmentManagerPromiseClient(""),
  },
};

type ServiceProviderPromiseClientConstructor<T> = new (
  hostname: string,
  credentials?:
    | {
        [index: string]: string;
      }
    | null
    | undefined,
  options?:
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [index: string]: any;
      }
    | null
    | undefined,
) => T;

export const APIContext = React.createContext<API>(defaultContext);

export const APIProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const loggingInterceptor = useMemo<LoggingInterceptor>(
    () => new LoggingInterceptor(),
    [],
  );
  const apiURL = config.get("apiGatewayURL");

  const serviceProviderConstructor: <T>(
    serviceProviderConstructor: ServiceProviderPromiseClientConstructor<T>,
  ) => T = (serviceProviderConstructor) => {
    return new serviceProviderConstructor(apiURL, null, {
      withCredentials: true,
      unaryInterceptors: [loggingInterceptor],
    });
  };

  const api: API = useMemo<API>(
    () => ({
      remuneration: {
        feeScheduleCreator: serviceProviderConstructor(
          FeeScheduleCreatorPromiseClient,
        ),
        feeScheduleUpdater: serviceProviderConstructor(
          FeeScheduleUpdaterPromiseClient,
        ),
        feeScheduleReader: serviceProviderConstructor(
          FeeScheduleReaderPromiseClient,
        ),
      },
      financial: {
        smartInstrumentRateResetGenerator: serviceProviderConstructor(
          SmartInstrumentRateResetGeneratorPromiseClient,
        ),
        smartInstrumentCalculator: serviceProviderConstructor(
          SmartInstrumentCalculatorPromiseClient,
        ),
        smartInstrumentStateController: serviceProviderConstructor(
          SmartInstrumentStateControllerPromiseClient,
        ),
        smartInstrumentUpdater: serviceProviderConstructor(
          SmartInstrumentUpdaterPromiseClient,
        ),
        smartInstrumentReader: serviceProviderConstructor(
          SmartInstrumentReaderPromiseClient,
        ),
        smartInstrumentReaderUNSCOPED: serviceProviderConstructor(
          SmartInstrumentReaderUNSCOPEDPromiseClient,
        ),
        rateResetCorporateActionReader: serviceProviderConstructor(
          RateResetCorporateActionReaderPromiseClient,
        ),
        rateResetCorporateActionReaderUNSCOPED: serviceProviderConstructor(
          RateResetCorporateActionReaderUNSCOPEDPromiseClient,
        ),
        paymentDeferralCorporateActionReader: serviceProviderConstructor(
          PaymentDeferralCorporateActionReaderPromiseClient,
        ),
        paymentDeferralCorporateActionReaderUNSCOPED:
          serviceProviderConstructor(
            PaymentDeferralCorporateActionReaderUNSCOPEDPromiseClient,
          ),
        assetflowReader: serviceProviderConstructor(
          AssetflowReaderPromiseClient,
        ),
        assetflowReaderUNSCOPED: serviceProviderConstructor(
          AssetflowReaderUNSCOPEDPromiseClient,
        ),
        paymentReader: serviceProviderConstructor(PaymentReaderPromiseClient),
        paymentReaderUNSCOPED: serviceProviderConstructor(
          PaymentReaderUNSCOPEDPromiseClient,
        ),
      },
      market: {
        subscriptionCommitmentManager: serviceProviderConstructor(
          SubscriptionCommitmentManagerPromiseClient,
        ),
        publicSubscriptionCommitmentManager: serviceProviderConstructor(
          PublicSubscriptionCommitmentManagerPromiseClient,
        ),
      },
    }),
    [apiURL],
  );

  return <APIContext.Provider value={api}>{children}</APIContext.Provider>;
};

export const useAPIContext = () => useContext(APIContext);
