import { Amount } from "james/ledger";
import { Fee, FeeState } from "./Fee";
import { Entry } from "../audit/Entry";

export const Instrument1stPlacementTradeFeeTypeName =
  "mesh::remuneration/Instrument1stPlacementTradeFee";

export class Instrument1stPlacementTradeFee implements Fee {
  public ["@type"]: string = Instrument1stPlacementTradeFeeTypeName;

  public id = "";

  public responsibleGroupID = "";

  public instrumentID = "";

  public amount: Amount = new Amount();

  public transactionID = "";

  public auditEntry: Entry = new Entry();

  public state: FeeState = FeeState.Pending;

  constructor(fee?: Instrument1stPlacementTradeFee) {
    if (!fee) {
      return;
    }
    this.id = fee.id;
    this.responsibleGroupID = fee.responsibleGroupID;
    this.instrumentID = fee.instrumentID;
    this.amount = new Amount(fee.amount);
    this.transactionID = fee.transactionID;
    this.auditEntry = new Entry(fee.auditEntry);
    this.state = fee.state;
  }

  feeAmount(): Amount {
    return this.amount;
  }

  feeID(): string {
    return this.id;
  }

  feeName(): string {
    return "Instrument 1st Placement / Trade Fee";
  }

  feeResponsibleGroupID(): string {
    return this.responsibleGroupID;
  }

  feeTransactionID(): string {
    return this.transactionID;
  }

  feeState(): FeeState {
    return this.state;
  }
}
