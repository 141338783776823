"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringToCalendar = exports.calendarToString = exports.allCalendars = void 0;
const calendar_pb_1 = require("./calendar_pb");
// Get all calendars as enum values
exports.allCalendars = Object.values(calendar_pb_1.Calendar).filter((value) => typeof value === "number");
// Define explicit mappings between Calendar enums and custom string representations
const calendarToStringMapping = {
    [calendar_pb_1.Calendar.UNDEFINED_CALENDAR]: "-",
    [calendar_pb_1.Calendar.AUSTRALIA_CALENDAR]: "Australia",
    [calendar_pb_1.Calendar.ARGENTINA_CALENDAR]: "Argentina",
    [calendar_pb_1.Calendar.BRAZIL_CALENDAR]: "Brazil",
    [calendar_pb_1.Calendar.CANADA_CALENDAR]: "Canada",
    [calendar_pb_1.Calendar.CHINA_CALENDAR]: "China",
    [calendar_pb_1.Calendar.CZECH_REPUBLIC_CALENDAR]: "Czech Republic",
    [calendar_pb_1.Calendar.DENMARK_CALENDAR]: "Denmark",
    [calendar_pb_1.Calendar.FINLAND_CALENDAR]: "Finland",
    [calendar_pb_1.Calendar.FRANCE_CALENDAR]: "France",
    [calendar_pb_1.Calendar.GERMANY_CALENDAR]: "Germany",
    [calendar_pb_1.Calendar.HONG_KONG_CALENDAR]: "Hong Kong",
    [calendar_pb_1.Calendar.HUNGARY_CALENDAR]: "Hungary",
    [calendar_pb_1.Calendar.ICELAND_CALENDAR]: "Iceland",
    [calendar_pb_1.Calendar.INDIA_CALENDAR]: "India",
    [calendar_pb_1.Calendar.INDONESIA_CALENDAR]: "Indonesia",
    [calendar_pb_1.Calendar.ISRAEL_CALENDAR]: "Israel",
    [calendar_pb_1.Calendar.ITALY_CALENDAR]: "Italy",
    [calendar_pb_1.Calendar.JAPAN_CALENDAR]: "Japan",
    [calendar_pb_1.Calendar.MEXICO_CALENDAR]: "Mexico",
    [calendar_pb_1.Calendar.NEW_ZEALAND_CALENDAR]: "New Zealand",
    [calendar_pb_1.Calendar.NORWAY_CALENDAR]: "Norway",
    [calendar_pb_1.Calendar.POLAND_CALENDAR]: "Poland",
    [calendar_pb_1.Calendar.ROMANIA_CALENDAR]: "Romania",
    [calendar_pb_1.Calendar.RUSSIA_CALENDAR]: "Russia",
    [calendar_pb_1.Calendar.RUSSIA_SETTLEMENT_CALENDAR]: "Russia (Settlement)",
    [calendar_pb_1.Calendar.RUSSIA_MOEX_CALENDAR]: "Russia (MOEX)",
    [calendar_pb_1.Calendar.SAUDI_ARABIA_CALENDAR]: "Saudi Arabia",
    [calendar_pb_1.Calendar.SINGAPORE_CALENDAR]: "Singapore",
    [calendar_pb_1.Calendar.SLOVAKIA_CALENDAR]: "Slovakia",
    [calendar_pb_1.Calendar.SOUTH_AFRICA_CALENDAR]: "South Africa",
    [calendar_pb_1.Calendar.SOUTH_KOREA_CALENDAR]: "South Korea",
    [calendar_pb_1.Calendar.SWEDEN_CALENDAR]: "Sweden",
    [calendar_pb_1.Calendar.SWITZERLAND_CALENDAR]: "Switzerland",
    [calendar_pb_1.Calendar.TAIWAN_CALENDAR]: "Taiwan",
    [calendar_pb_1.Calendar.TARGET_CALENDAR]: "TARGET",
    [calendar_pb_1.Calendar.THAILAND_CALENDAR]: "Thailand",
    [calendar_pb_1.Calendar.TURKEY_CALENDAR]: "Turkey",
    [calendar_pb_1.Calendar.UKRAINE_CALENDAR]: "Ukraine",
    [calendar_pb_1.Calendar.UNITED_KINGDOM_CALENDAR]: "United Kingdom",
    [calendar_pb_1.Calendar.UNITED_KINGDOM_SETTLEMENT_CALENDAR]: "United Kingdom (Settlement)",
    [calendar_pb_1.Calendar.UNITED_STATES_SETTLEMENT_CALENDAR]: "United States (Settlement)",
    [calendar_pb_1.Calendar.UNITED_STATES_NYSE_CALENDAR]: "United States (NYSE)",
    [calendar_pb_1.Calendar.UNITED_STATES_GOVERNMENT_BOND_CALENDAR]: "United States (Government Bond)",
    [calendar_pb_1.Calendar.UNITED_STATES_NERC_CALENDAR]: "United States (NERC)",
    [calendar_pb_1.Calendar.UNITED_STATES_LIBOR_IMPACT_CALENDAR]: "United States (LIBOR Impact)",
    [calendar_pb_1.Calendar.UNITED_STATES_FEDERAL_RESERVE_CALENDAR]: "United States (Federal Reserve)",
    [calendar_pb_1.Calendar.UTC_BLANK_CALENDAR]: "UTC Blank Calendar",
};
// Reverse mapping from string to Calendar enum
const stringToCalendarMapping = {};
for (const [key, value] of Object.entries(calendarToStringMapping)) {
    stringToCalendarMapping[value] = Number(key);
}
class UnsupportedCalendarError extends Error {
    constructor(calendar) {
        const message = `Unsupported Calendar: ${calendar}`;
        super(message);
        this.calendar = calendar;
    }
}
/**
 * Converts a Calendar enum instance to a custom string representation.
 * @param {Calendar} calendar - The calendar to convert.
 * @returns {string} The custom string representation of the calendar.
 */
function calendarToString(calendar) {
    if (calendar in calendarToStringMapping) {
        return calendarToStringMapping[calendar];
    }
    else {
        throw new UnsupportedCalendarError(calendar);
    }
}
exports.calendarToString = calendarToString;
class UnsupportedCalendarStringError extends Error {
    constructor(calendarStr) {
        const message = `Unsupported calendar string: ${calendarStr}`;
        super(message);
        this.calendarStr = calendarStr;
    }
}
/**
 * Converts a custom string representation to a Calendar enum instance.
 * @param {string} calendarStr - The custom string representation of the calendar.
 * @returns {Calendar} The corresponding Calendar enum instance.
 */
function stringToCalendar(calendarStr) {
    if (calendarStr in stringToCalendarMapping) {
        return stringToCalendarMapping[calendarStr];
    }
    else {
        throw new UnsupportedCalendarStringError(calendarStr);
    }
}
exports.stringToCalendar = stringToCalendar;
