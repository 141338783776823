import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import cx from "classnames";
import { formatTextNum } from "utilities/number";

const PREFIX = "ReturnIndicator";

const classes = {
  root: `${PREFIX}-root`,
  rootReverse: `${PREFIX}-rootReverse`,
  caption: `${PREFIX}-caption`,
  bold: `${PREFIX}-bold`,
  value: `${PREFIX}-value`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "grid",
    gridTemplateColumns: "auto",
    justifyItems: "end",
  },

  [`&.${classes.rootReverse}`]: {
    justifyItems: "start",
  },

  [`& .${classes.caption}`]: {
    color: theme.palette.text.secondary,
    lineHeight: "15px",
  },

  [`& .${classes.bold}`]: {
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${classes.value}`]: {
    marginTop: 2,
  },
}));

interface ReturnIndicatorProps {
  returnDescription: string;
  returnValue: string;
  returnDisclaimer?: string;
  subtitle?: string;
  reverseAlign?: boolean;
}

export const ReturnIndicator = ({
  returnDescription,
  returnDisclaimer,
  returnValue,
  subtitle,
  reverseAlign,
}: ReturnIndicatorProps) => {
  return (
    <StyledBox
      className={cx(classes.root, {
        [classes.rootReverse]: reverseAlign,
      })}
    >
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.tertiary,
          lineHeight: "15px",
          fontSize: "12px",
        })}
      >
        {returnDescription}
      </Typography>
      {(!returnDisclaimer || subtitle) && (
        <Typography
          id="returnValue-Typography"
          variant={"h4"}
          sx={{ fontWeight: "bold" }}
          children={`${formatTextNum(returnValue, {
            noDecimalPlaces: 2,
          })}%`}
        />
      )}
      <Box
        sx={{
          height: returnDisclaimer ? "auto" : 16,
        }}
      >
        {subtitle ? (
          <Box>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                fontWeight: "bold",
                fontSize: 9,
                color: theme.palette.warning.light,
                lineHeight: "14px",
              })}
            >
              *{subtitle}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              flexWrap: "wrap",
              maxWidth: 160,
              mb: 2,
            }}
          >
            <Typography
              id="returnDisclaimer-Typography"
              variant="h4"
              sx={{ fontWeight: "bold", mr: 1, fontSize: { sm: 18, xs: 14 } }}
            >
              {returnDisclaimer?.split(/[-+]+/g)[0].replace("*", "") ?? <></>}
            </Typography>
            <Typography id="returnDisclaimerPercentage-Typography" variant="h6">
              {returnDisclaimer?.replace(/[^-+]*/g, "")}
              {returnDisclaimer?.split(/[-+]+/g)[1] ?? <></>}
            </Typography>
          </Box>
        )}
      </Box>
    </StyledBox>
  );
};
