"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringToBusinessDayConvention = exports.businessDayConventionToString = exports.allBusinessDayConventions = void 0;
const businessDayConvention_pb_1 = require("./businessDayConvention_pb");
// Get all business day conventions as enum values
exports.allBusinessDayConventions = Object.values(businessDayConvention_pb_1.BusinessDayConvention).filter((value) => typeof value === "number");
// Define explicit mappings between BusinessDayConvention enums and custom string representations
const businessDayConventionToStringMapping = {
    [businessDayConvention_pb_1.BusinessDayConvention.UNDEFINED_BUSINESS_DAY_CONVENTION]: "-",
    [businessDayConvention_pb_1.BusinessDayConvention.FOLLOWING_BUSINESS_DAY_CONVENTION]: "Following",
    [businessDayConvention_pb_1.BusinessDayConvention.MODIFIED_FOLLOWING_BUSINESS_DAY_CONVENTION]: "Modified Following",
    [businessDayConvention_pb_1.BusinessDayConvention.PRECEDING_BUSINESS_DAY_CONVENTION]: "Preceding",
    [businessDayConvention_pb_1.BusinessDayConvention.MODIFIED_PRECEDING_BUSINESS_DAY_CONVENTION]: "Modified Preceding",
    [businessDayConvention_pb_1.BusinessDayConvention.UNADJUSTED_BUSINESS_DAY_CONVENTION]: "Unadjusted",
    [businessDayConvention_pb_1.BusinessDayConvention.HALF_MONTH_MODIFIED_FOLLOWING_BUSINESS_DAY_CONVENTION]: "Half-Month Modified Following",
    [businessDayConvention_pb_1.BusinessDayConvention.NEAREST_BUSINESS_DAY_CONVENTION]: "Nearest",
};
// Reverse mapping from string to BusinessDayConvention enum
const stringToBusinessDayConventionMapping = {};
for (const [key, value] of Object.entries(businessDayConventionToStringMapping)) {
    stringToBusinessDayConventionMapping[value] = Number(key);
}
class UnsupportedBusinessDayConventionError extends Error {
    constructor(businessDayConvention) {
        const message = `Unsupported BusinessDayConvention: ${businessDayConvention}`;
        super(message);
        this.businessDayConvention = businessDayConvention;
    }
}
/**
 * Converts a BusinessDayConvention enum instance to a custom string representation.
 * @param {BusinessDayConvention} businessDayConvention - The business day convention to convert.
 * @returns {string} The custom string representation of the business day convention.
 */
function businessDayConventionToString(businessDayConvention) {
    if (businessDayConvention in businessDayConventionToStringMapping) {
        return businessDayConventionToStringMapping[businessDayConvention];
    }
    else {
        throw new UnsupportedBusinessDayConventionError(businessDayConvention);
    }
}
exports.businessDayConventionToString = businessDayConventionToString;
class UnsupportedBusinessDayConventionStringError extends Error {
    constructor(businessDayConventionStr) {
        const message = `Unsupported business day convention string: ${businessDayConventionStr}`;
        super(message);
        this.businessDayConventionStr = businessDayConventionStr;
    }
}
/**
 * Converts a custom string representation to a BusinessDayConvention enum instance.
 * @param {string} businessDayConventionStr - The custom string representation of the business day convention.
 * @returns {BusinessDayConvention} The corresponding BusinessDayConvention enum instance.
 */
function stringToBusinessDayConvention(businessDayConventionStr) {
    if (businessDayConventionStr in stringToBusinessDayConventionMapping) {
        return stringToBusinessDayConventionMapping[businessDayConventionStr];
    }
    else {
        throw new UnsupportedBusinessDayConventionStringError(businessDayConventionStr);
    }
}
exports.stringToBusinessDayConvention = stringToBusinessDayConvention;
