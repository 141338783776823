import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { TextField } from "components/FormFields";
import { Asset } from "../../../james/ledger/Asset";
import { Amount, AssetFetcher, Token } from "../../../james/ledger";
import { useApplicationContext } from "../../../context/Application/Application";
import {
  IDIdentifier,
  TokenIdentifier,
} from "../../../james/search/identifier";
import { useErrorContext } from "../../../context/Error";
import {
  Calendar,
  DateGenerationRule,
  DigitalFixedRateBond,
  DigitalFloatingRateBond,
  ETFStablecoinCreatorCoordinator,
  FinancialCurrencyCollection,
  InstrumentRiskProfile,
  InvestorProfile,
} from "../../../james/financial";
import {
  DigitalFixedRateBondForm,
  DigitalFloatingRateBondForm,
  AssetListingForm,
  FormData,
  TabPath,
  TabPaths,
} from "./components";
import { useIsMounted } from "../../../hooks";
import {
  TextExactCriterion,
  TextListCriterion,
} from "../../../james/search/criterion";
import { FinancialCurrencyStablecoinViewReader } from "../../../james/views/financialCurrencyStablecoinView";
import {
  Model as FinancialCurrencyStablecoinViewModel,
  UnderlyingCurrencyCategory,
} from "../../../james/views/financialCurrencyStablecoinView/Model";
import { Currency } from "../../../james/financial/Currency";
import { Group, GroupRepository } from "../../../james/group";
import {
  Determiner,
  ScopeFields,
} from "../../../james/search/scope/Determiner";
import { Permission } from "../../../james/security";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import { CouponFrequency } from "../../../james/financial/CouponFrequency";
import { DayCountConvention } from "../../../james/financial/DayCountConvention";
import { BusinessDayConvention } from "../../../james/financial/BusinessDayConvention";
import { BondState } from "../../../james/financial/Bond";
import { Router } from "../../../routes";
import { DebtSeniorityLevel } from "../../../james/financial/DebtSeniorityLevel";
import {
  Listing,
  ListingInspector,
  ListingRepository,
  Mechanism as MarketMechanism,
  MechanismType,
  QuoteParameter,
} from "../../../james/market";
import { NoDataCard, PrimaryMarket } from "./components/PrimaryMarket";
import { CorporateActions } from "./components/CorporateActions";
import {
  MarketSubscriptionOrderBookViewModel,
  MarketSubscriptionOrderBookViewReader,
  MarketSubscriptionOrderBookViewUnscopedReader,
} from "../../../james/views/marketSubscriptionOrderBookView";
import { ErrModelNotFound } from "../../../james/views/marketSubscriptionOrderBookView/ReaderErrors";
import { Refresh as ReloadIcon } from "@mui/icons-material";
import { InstrumentsViewPaths } from "../Instruments/Instruments";
import { MarketingMediaForm } from "./components/MarketingMediaForm";
import { ListingState } from "james/market/Listing";
import {
  SubscriptionOrderBook,
  SubscriptionOrderBookState,
} from "james/market/SubscriptionOrderBook";
import { StellarNetwork } from "james/stellar";
import { Entry } from "james/audit/Entry";

const PREFIX = "AllInstrumentsSelectiones";
const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  heading: `${PREFIX}-heading`,
  miniLogoWrapper: `${PREFIX}-miniLogoWrapper`,
  dialogContent: `${PREFIX}-dialogContent`,
  chooseInstrumentsSelectionLayout: `${PREFIX}-chooseInstrumentsSelectionLayout`,
  chooseInstrumentsSelectionFirstRow: `${PREFIX}-chooseInstrumentsSelectionFirstRow`,
  chooseInstrumentsSelectionFormField: `${PREFIX}-chooseInstrumentsSelectionFormField`,
  boldText: `${PREFIX}-boldText`,
  lastActionAnnotationDialogTitleRootOverride: `${PREFIX}-lastActionAnnotationDialogTitleRootOverride`,
  dialogContentRootOverride: `${PREFIX}-dialogContentRootOverride`,
};

export enum InstrumentsSelection {
  ETN = "Exchange-Traded Note",
  ETF = "Exchange-Traded Fund",
  // RETN = "Rights to Exchange-Traded Notes",
  // RETF = "Rights to Exchange-Traded Funds",
  // RUT = "Rights to Unit Trusts",
  FixedRateBond = "Fixed Rate Bond",
  FloatingRateBond = "Floating Rate Bond",
}

export const AllInstrumentsSelectiones: InstrumentsSelection[] = [
  InstrumentsSelection.ETN,
  InstrumentsSelection.ETF,
  InstrumentsSelection.FixedRateBond,
  InstrumentsSelection.FloatingRateBond,
];

export type Props = {
  system: boolean;
  backPath: string;
  basePath: string;
};
export const V2 = (props: Props) => {
  const isMounted = useIsMounted();
  const { authContext, viewConfiguration } = useApplicationContext();
  const { errorContextErrorTranslator, errorContextDefaultWarningFeedback } =
    useErrorContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [asset, setAsset] = useState<Asset | undefined>(undefined);
  const [instrumentSelection, setInstrumentSelection] = useState<
    InstrumentsSelection | undefined
  >(undefined);

  const [instrumentTypeFriendlyName, setInstrumentTypeFriendlyName] = useState(
    DigitalFixedRateBond.name,
  );

  const [apiLoading, setAPILoading] = useState(false);
  const [preIssueState, setPreIssueState] = useState(false);
  const [draftState, setDraftState] = useState(false);

  // prepare forms
  const assetFormIndex: {
    [key: string]: {
      friendlyName: "Fixed Rate Bond" | "Floating Rate Bond";
      getNewDraft: (formData: FormData) => Asset;
      formComponent: () => React.ReactNode;
    };
  } = {
    // ------------------------ fixed rate bond ------------------------------------
    [DigitalFixedRateBond.name]: {
      friendlyName: "Fixed Rate Bond",
      getNewDraft: (formData: FormData) => {
        // prepare new bond and get required data from provided form data
        const newBond = new DigitalFixedRateBond();
        const groupOwnerID = formData.groupOwners[0].id;
        const ccyStablecoin = formData.currencyStablecoins[0];
        const ccy = formData.currencies.find(
          (ccy) => ccy.currencyID() === ccyStablecoin?.currencyID,
        );
        if (!ccy) {
          console.error(
            "error finding ccy for ccy stablecoin",
            ccyStablecoin,
            formData.currencies,
          );
          return newBond;
        }

        newBond.debtSeniorityLevel = DebtSeniorityLevel.SeniorSubordinated;
        newBond.riskProfile = InstrumentRiskProfile.High;
        newBond.investorProfile = InvestorProfile.Aggressive;
        newBond.state = BondState.Draft;
        newBond.ownerID = groupOwnerID;
        newBond.issuePrice = ccyStablecoin.token.newAmountOf("5000");
        newBond.nominal = ccyStablecoin.token.newAmountOf("5000");

        // --------------- FixedRateBondStub fields (i.e. fields required for calculations) ----------------------
        newBond.totalNominal =
          formData.currencyStablecoins[0].token.newAmountOf("100000000");
        newBond.redemption = new BigNumber("100");
        newBond.issueDate = ccy.firstStartOfDayBefore(
          ccy.firstCutOffAfter(dayjs().add(7, "day").format()),
        );
        newBond.maturityDate = ccy.firstStartOfDayBefore(
          ccy.firstCutOffAfter(dayjs().add(7, "day").add(10, "year").format()),
        );
        newBond.calendar = Calendar.SOUTH_AFRICA_CALENDAR;
        newBond.couponPaymentFrequency = CouponFrequency.Monthly;
        newBond.firstCouponPaymentDate = null;
        newBond.nextToLastCouponPaymentDate = null;
        newBond.couponInterestCommencementDate = null;
        newBond.dayCountConvention = DayCountConvention.ActualOvr365Fixed;
        newBond.endOfMonthConvention = false;
        newBond.businessDayConvention = BusinessDayConvention.ModifiedFollowing;
        newBond.terminationDateBusinessDayConvention = "";
        newBond.dateGenerationRule =
          DateGenerationRule.BACKWARD_DATE_GENERATION_RULE;
        newBond.fixDays = 7;
        newBond.exCouponDays = 0;
        newBond.recordDays = 0;
        newBond.couponRate = new BigNumber("1");

        return newBond;
      },
      formComponent: () => (
        <DigitalFixedRateBondForm
          setAPILoading={(newValue: boolean) => setAPILoading(newValue)}
          apiLoading={apiLoading}
          formData={formData}
          digitalFixedRateBond={asset as DigitalFixedRateBond}
          onDigitalFixedRateBondChange={(bond) => {
            setAsset(bond);
          }}
        />
      ),
    },

    // ------------------------ floating rate bond ------------------------------------
    [DigitalFloatingRateBond.name]: {
      friendlyName: "Floating Rate Bond",
      getNewDraft: (formData: FormData) => {
        // prepare new bond and get required data from provided form data
        const newBond = new DigitalFloatingRateBond();
        const groupOwnerID = formData.groupOwners[0].id;
        const ccyStablecoin = formData.currencyStablecoins[0];
        const ccy = formData.currencies.find(
          (ccy) => ccy.currencyID() === ccyStablecoin?.currencyID,
        );
        if (!ccy) {
          console.error(
            "error finding ccy for ccy stablecoin",
            ccyStablecoin,
            formData.currencies,
          );
          return newBond;
        }

        newBond.debtSeniorityLevel = DebtSeniorityLevel.SeniorSubordinated;
        newBond.riskProfile = InstrumentRiskProfile.High;
        newBond.investorProfile = InvestorProfile.Aggressive;
        newBond.state = BondState.Draft;
        newBond.ownerID = groupOwnerID;
        newBond.issuePrice = ccyStablecoin.token.newAmountOf("5000");
        newBond.nominal = ccyStablecoin.token.newAmountOf("5000");

        // --------------- FixedRateBondStub fields (i.e. fields required for calculations) ----------------------
        newBond.totalNominal =
          formData.currencyStablecoins[0].token.newAmountOf("100000000");
        newBond.redemption = new BigNumber("100");
        newBond.issueDate = ccy.firstStartOfDayBefore(
          ccy.firstCutOffAfter(dayjs().add(7, "day").format()),
        );
        newBond.maturityDate = ccy.firstStartOfDayBefore(
          ccy.firstCutOffAfter(dayjs().add(7, "day").add(10, "year").format()),
        );
        newBond.calendar = Calendar.SOUTH_AFRICA_CALENDAR;
        newBond.couponPaymentFrequency = CouponFrequency.Monthly;
        newBond.firstCouponPaymentDate = null;
        newBond.nextToLastCouponPaymentDate = null;
        newBond.couponInterestCommencementDate = null;
        newBond.dayCountConvention = DayCountConvention.ActualOvr365Fixed;
        newBond.endOfMonthConvention = false;
        newBond.businessDayConvention = BusinessDayConvention.ModifiedFollowing;
        newBond.terminationDateBusinessDayConvention = "";
        newBond.dateGenerationRule =
          DateGenerationRule.BACKWARD_DATE_GENERATION_RULE;
        newBond.fixDays = 7;
        newBond.exCouponDays = 0;
        newBond.recordDays = 0;
        // newBond.couponRateSource = CouponRateSource.JIBAR1;
        newBond.couponRateVariance = new BigNumber("0");

        return newBond;
      },
      formComponent: () => (
        <DigitalFloatingRateBondForm
          setAPILoading={(newValue: boolean) => setAPILoading(newValue)}
          apiLoading={apiLoading}
          formData={formData}
          digitalFloatingRateBond={asset as DigitalFloatingRateBond}
          onDigitalFloatingRateBondChange={(bond: DigitalFloatingRateBond) => {
            setAsset(bond);
          }}
        />
      ),
    },
  };

  const getDefaultAsset = (formData: FormData) =>
    assetFormIndex[instrumentTypeFriendlyName].getNewDraft(formData);

  // initial data fetch
  const [initialDataFetchRequired, setInitialDataFetchRequired] =
    useState(true);
  const [formData, setFormData] = useState<FormData>({
    groupOwners: [],
    currencies: [],
    currencyStablecoins: [],
    instrumentTypeFriendlyNames: Object.keys(assetFormIndex).map((a) => ({
      name: a,
      friendlyName: assetFormIndex[a].friendlyName,
    })),
  });
  useEffect(() => {
    // do nothing if form not mounted
    if (!isMounted()) {
      return;
    }

    // do nothing unless initial data fetch is required
    if (!initialDataFetchRequired) {
      return;
    }

    // fetch initial form data
    (async () => {
      // prepare required initial form data
      let retrievedPotentialGroupOwners: Group[] = [];
      let retrievedValuationStablecoins: FinancialCurrencyStablecoinViewModel[] =
        [];
      let retrievedCurrencies: Currency[] = [];

      try {
        await Promise.all([
          // fetch potential instrument group owners
          (async () => {
            retrievedPotentialGroupOwners = props.system
              ? (
                  await GroupRepository.SearchGroups({
                    context: authContext,
                    criteria: {},
                  })
                ).records
              : (
                  await GroupRepository.SearchGroups({
                    context: authContext,
                    criteria: (
                      await Determiner.DetermineScopeCriteriaByRoles({
                        context: authContext,
                        service: new Permission({
                          // FIXME: use a general instrument creation permission here
                          serviceName: "CoordinateCreateNewETFStablecoin",
                          serviceProvider:
                            ETFStablecoinCreatorCoordinator.serviceProvider,
                          description: "-",
                        }),
                        criteria: {},
                        scopeFields: [ScopeFields.IDField],
                        buildScopeTree: false,
                      })
                    ).criteria,
                  })
                ).records;
          })(),

          // fetch potential currency stablecoins
          (async () => {
            // retrieve all potential valuation currency stablecoins
            retrievedValuationStablecoins = (
              await FinancialCurrencyStablecoinViewReader.Read({
                context: authContext,
                criteria: {
                  underlyingCurrencyCategory: TextExactCriterion(
                    UnderlyingCurrencyCategory.Fiat,
                  ),
                },
              })
            ).models;

            // retrieve each associated currency
            retrievedCurrencies = (
              await FinancialCurrencyCollection.SearchCurrency({
                context: authContext,
                criteria: {
                  id: TextListCriterion(
                    retrievedValuationStablecoins.map((sc) => sc.currencyID),
                  ),
                },
              })
            ).records;
          })(),
        ]);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error fetching data required for instrument builder: ${
            err.message ? err.message : err.toString()
          }`,
        );
        errorContextDefaultWarningFeedback(
          e,
          "Unable to Initialise Instrument Builder",
        );
        navigate(props.backPath);
        return;
      }

      // at least 1 of each of the bits of required data should have been retrieved
      if (
        !(
          retrievedCurrencies.length &&
          retrievedValuationStablecoins.length &&
          retrievedPotentialGroupOwners.length
        )
      ) {
        console.error(
          "required form data unexpectedly empty",
          retrievedCurrencies,
          retrievedValuationStablecoins,
          retrievedPotentialGroupOwners,
        );
        errorContextDefaultWarningFeedback(
          undefined,
          "Unable to Initialise Instrument Builder",
        );
        navigate(props.backPath);
        return;
      }

      if (isMounted()) {
        setFormData({
          groupOwners: retrievedPotentialGroupOwners,
          currencies: retrievedCurrencies,
          currencyStablecoins: retrievedValuationStablecoins,
          instrumentTypeFriendlyNames: formData.instrumentTypeFriendlyNames,
        });
        setInitialDataFetchRequired(false);
      }
    })();
  }, [isMounted, initialDataFetchRequired, authContext]);

  // initialise asset and mode
  const [initialisingAssetAndMode, setInitialisingAssetAndMode] =
    useState(true);
  const [listing, setListing] = useState<Listing>(new Listing());
  const [subscriptionOrderBook, setSubscriptionOrderBook] =
    useState<MarketSubscriptionOrderBookViewModel>(
      new MarketSubscriptionOrderBookViewModel(),
    );
  const [reloadRequired, setReloadRequired] = useState(false);
  useEffect(() => {
    // do nothing if form is not mounted
    if (!isMounted()) {
      return;
    }

    // do nothing if form data still needs to be fetched
    if (initialDataFetchRequired) {
      return;
    }

    // initialise asset and mode
    (async () => {
      // look for an asset ID in the url
      const id = searchParams.get("id");

      // if no asset ID is found then clear the asset and set default form state
      if (!id) {
        if (!viewConfiguration?.Instruments?.InstrumentActions?.Create) {
          navigate(props.backPath);
          return;
        }
        // ensure that a default asset is set
        if (!asset || asset.assetID() !== "") {
          try {
            setAsset(getDefaultAsset(formData));
          } catch (e) {
            console.error("error initialising default asset", formData);
            errorContextDefaultWarningFeedback(
              e,
              "Unable to Initialise Instrument Builder",
            );
            navigate(props.backPath);
            return;
          }
        }

        // indicate that initialisation no longer in progress
        if (initialisingAssetAndMode) {
          setInitialisingAssetAndMode(false);
          setInstrumentSelection(undefined);
          setDraftState(true);
          setPreIssueState(false);
        }

        return;
      }
      // if execution reaches here then an asset ID was found

      // if an asset is already set and the asset is the asset identified by
      // the id found in the URL then do nothing
      if (asset && asset.assetID() === id) {
        // unless reload is required
        if (reloadRequired) {
          setReloadRequired(false);
        } else {
          return;
        }
      }

      // otherwise fetch the asset
      let fetchedAsset: Asset;
      try {
        fetchedAsset = (
          await AssetFetcher.FetchAsset({
            context: authContext,
            identifier: IDIdentifier(id),
          })
        ).asset;
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error fetching asset: ${err.message ? err.message : err.toString()}`,
        );
        errorContextDefaultWarningFeedback(e, "Unable to Fetch Asset");
        navigate(props.backPath);
        return;
      }

      // fetch listing if it exists
      let fetchedListing: Listing = new Listing();
      if (!fetchedAsset.assetToken().isEqualTo(new Token())) {
        try {
          if (
            (
              await ListingInspector.DoesListingForTokenExist({
                context: authContext,
                token: fetchedAsset.assetToken(),
              })
            ).exists
          ) {
            fetchedListing = (
              await ListingRepository.RetrieveListing({
                context: authContext,
                identifier: TokenIdentifier(fetchedAsset.assetToken()),
              })
            ).listing;
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error determining if listing for token exists: ${
              err.message ? err.message : err.toString()
            }`,
          );
          errorContextDefaultWarningFeedback(e, "Unable to Fetch Listing");
          navigate(props.backPath);
          return;
        }
      }

      // fetch subscription order book if it exists
      let fetchedSubscriptionOrderBook: MarketSubscriptionOrderBookViewModel =
        new MarketSubscriptionOrderBookViewModel();
      if (
        fetchedListing.marketMechanisms.length &&
        fetchedListing.marketMechanisms[0].type === MechanismType.Subscription
      ) {
        try {
          fetchedSubscriptionOrderBook = props.system
            ? (
                await MarketSubscriptionOrderBookViewUnscopedReader.UnscopedReadOne(
                  {
                    context: authContext,
                    criteria: fetchedAsset.assetToken().toFilter(),
                  },
                )
              ).model
            : (
                await MarketSubscriptionOrderBookViewReader.ReadOne({
                  context: authContext,
                  criteria: fetchedAsset.assetToken().toFilter(),
                })
              ).model;
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          if (err.code !== ErrModelNotFound) {
            errorContextDefaultWarningFeedback(
              e,
              "error getting subscription order book",
            );
            navigate(props.backPath);
            return;
          }
        }
      }

      if (isMounted()) {
        setAsset(fetchedAsset);
        setListing(fetchedListing);
        setSubscriptionOrderBook(fetchedSubscriptionOrderBook);
        setInitialisingAssetAndMode(false);
      }
    })();
  }, [
    asset,
    isMounted,
    initialDataFetchRequired,
    initialisingAssetAndMode,
    authContext,
    reloadRequired,
    instrumentTypeFriendlyName,
  ]);

  useEffect(() => {
    if (!asset) {
      return;
    } else if (asset["assetName"]() === "") {
      setInstrumentSelection(instrumentSelection);
    } else if (asset instanceof DigitalFixedRateBond) {
      setAssetState(asset.state as BondState);
      setInstrumentTypeFriendlyName(DigitalFixedRateBond.name);
      setInstrumentSelection(InstrumentsSelection.FixedRateBond);
    } else if (asset instanceof DigitalFloatingRateBond) {
      setAssetState(asset.state as BondState);
      setInstrumentTypeFriendlyName(DigitalFloatingRateBond.name);
      setInstrumentSelection(InstrumentsSelection.FloatingRateBond);
    }
  }, [asset, instrumentSelection]);

  useEffect(() => {
    if (instrumentSelection !== undefined) {
      switch (instrumentSelection) {
        case InstrumentsSelection.FixedRateBond:
          setInstrumentTypeFriendlyName(DigitalFixedRateBond.name);
          break;
        case InstrumentsSelection.FloatingRateBond:
          setInstrumentTypeFriendlyName(DigitalFloatingRateBond.name);
          break;
        default:
          setInstrumentTypeFriendlyName("");
          break;
      }
    }
  }, [instrumentSelection]);

  const setAssetState = (state: BondState | undefined) => {
    setPreIssueState(state === BondState.PreIssued);
    setDraftState(state === BondState.Draft);
  };

  const assetListingContent = () => {
    const quoteToken = new Token({
      code: "mZAR",
      issuer: "GC57ZJLYGUOMGDGPD5XFDOTQHQER3QL6B72SPTR3XTEKRY3HJ75NCNBL",
      network: StellarNetwork.TestSDFNetwork,
    });
    const newListing = new Listing(listing);
    newListing.marketMechanisms = [
      new MarketMechanism({
        type: MechanismType.Subscription,
        quoteParameters: [
          new QuoteParameter({
            quoteToken: quoteToken,
            minimumDealSize: new Amount({
              value: BigNumber(1),
              token: asset?.assetToken() ?? new Token(),
            }),
            maximumDealSize: new Amount({
              value: BigNumber(0),
              token: asset?.assetToken() ?? new Token(),
            }),
          }),
        ],
      }),
    ];

    const assetSubscriptionOrderBook = new SubscriptionOrderBook();

    if (subscriptionOrderBook.id !== "") {
      assetSubscriptionOrderBook.id = subscriptionOrderBook.id;
      assetSubscriptionOrderBook.ownerID = subscriptionOrderBook.ownerID;
      assetSubscriptionOrderBook.number = subscriptionOrderBook.number;
      assetSubscriptionOrderBook.token = new Token(asset?.assetToken());
      assetSubscriptionOrderBook.exchangeNetwork =
        subscriptionOrderBook.exchangeNetwork;
      assetSubscriptionOrderBook.unitPrice = new Amount({
        ...subscriptionOrderBook.unitPrice,
        token: quoteToken,
      });
      assetSubscriptionOrderBook.state = subscriptionOrderBook.state;
      assetSubscriptionOrderBook.openDate = subscriptionOrderBook.openDate;
      assetSubscriptionOrderBook.closeDate = subscriptionOrderBook.closeDate;
      assetSubscriptionOrderBook.settlementDate =
        subscriptionOrderBook.settlementDate;
      assetSubscriptionOrderBook.fractionalisationAllowed =
        subscriptionOrderBook.fractionalisationAllowed;
      assetSubscriptionOrderBook.subscriptionAmount = new Amount({
        ...subscriptionOrderBook.subscriptionAmount,
        token: quoteToken,
      });
      assetSubscriptionOrderBook.overSubscriptionAmount = new Amount({
        ...subscriptionOrderBook.overSubscriptionAmount,
        token: quoteToken,
      });
      assetSubscriptionOrderBook.subscribedAmount = new Amount(
        subscriptionOrderBook.subscribedAmount,
      );
      assetSubscriptionOrderBook.subscribedTokens = new Amount(
        subscriptionOrderBook.subscribedTokens,
      );
      assetSubscriptionOrderBook.settlementAmount = new Amount(
        subscriptionOrderBook.settlementTokens,
      );
      assetSubscriptionOrderBook.settlementTokens = new Amount(
        subscriptionOrderBook.settlementTokens,
      );
      assetSubscriptionOrderBook.leftOverTokens = new BigNumber(
        subscriptionOrderBook.leftOverTokens,
      );
      assetSubscriptionOrderBook.stateResolutionCount =
        subscriptionOrderBook.stateResolutionCount;
      assetSubscriptionOrderBook.auditEntry = new Entry();
    }

    const listingAsset =
      instrumentTypeFriendlyName === DigitalFixedRateBond.name
        ? (asset as DigitalFixedRateBond)
        : (asset as DigitalFloatingRateBond);

    return (
      <>
        {asset && listing.state === ListingState.Active ? (
          listing.marketMechanisms[0]?.type === MechanismType.Subscription ? (
            subscriptionOrderBook.state !==
            SubscriptionOrderBookState.Closed ? (
              subscriptionOrderBook.id !== "" ||
              subscriptionOrderBook.subscriptionOrderBookID !== "" ? (
                <PrimaryMarket
                  system={props.system}
                  subscriptionOrderBook={subscriptionOrderBook}
                  reload={() => setReloadRequired(true)}
                />
              ) : (
                <>
                  <AssetListingForm
                    asset={listingAsset}
                    listing={listing}
                    subscriptionOrderBook={assetSubscriptionOrderBook}
                  />
                </>
              )
            ) : (
              <NoDataCard
                title={"Your Subscription is Closed!"}
                subTitle={["Set Up Secondary Market Listing!"]}
              />
            )
          ) : (
            <AssetListingForm
              asset={listingAsset}
              listing={newListing}
              subscriptionOrderBook={assetSubscriptionOrderBook}
            />
          )
        ) : asset && listing.state !== ListingState.Active ? (
          <AssetListingForm
            asset={listingAsset}
            listing={newListing}
            subscriptionOrderBook={assetSubscriptionOrderBook}
          />
        ) : (
          <NoDataCard
            title={"No Listing Found!"}
            subTitle={["Set Up Listing!"]}
          />
        )}
      </>
    );
  };

  const loading = initialDataFetchRequired || initialisingAssetAndMode;
  let cardContent = <></>;
  if (loading) {
    cardContent = (
      <CardContent
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "1fr",
          rowGap: theme.spacing(2),
          alignItems: "center",
          justifyItems: "center",
        })}
      >
        <CircularProgress size={70} />
        <Typography
          variant={"h5"}
          color={"textSecondary"}
          children={"Getting things ready for you..."}
        />
      </CardContent>
    );
  } else if (instrumentSelection === undefined) {
    cardContent = <></>;
  } else {
    cardContent = (
      <>
        <Card>
          <CardHeader
            sx={{ padding: 0 }}
            title={
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box>
                  <Tabs
                    value={TabPaths(props.basePath).findIndex((tp) =>
                      window.location.pathname.includes(tp.path),
                    )}
                    onChange={(_, value) => {
                      navigate(
                        `${
                          TabPaths(props.basePath)[value].path
                        }?${searchParams.toString()}`,
                      );
                    }}
                  >
                    {TabPaths(props.basePath).map((tabPath, idx) => (
                      <Tab
                        key={idx}
                        label={tabPath.name}
                        value={idx}
                        sx={(theme) => ({
                          textTransform: "capitalize",
                          "&.Mui-selected": {
                            color: theme.palette.text.primary,
                          },
                        })}
                      />
                    ))}
                  </Tabs>
                </Box>
                <Box
                  sx={(theme) => ({
                    marginLeft: "auto",
                    marginRight: theme.spacing(4),
                    display: "flex",
                    alignItems: "center",
                  })}
                >
                  {apiLoading && <CircularProgress size={20} />}
                  <Tooltip placement={"top"} title={"Refresh"}>
                    <span>
                      <IconButton
                        onClick={() => setReloadRequired(true)}
                        size={"small"}
                      >
                        <ReloadIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            }
          />
          <Box
            className={"meshScroll"}
            sx={{
              maxHeight: draftState ? "71vh" : "77vh",
              overflowY: "auto",
              overflowX: "hidden",
              mb: 1,
            }}
          >
            <Router
              baseURL={props.basePath}
              redirectPath={TabPath.Overview}
              routes={[
                {
                  name: "overview",
                  id: "v2-overview",
                  path: `${props.basePath}/${TabPath.Overview}`,
                  component: (
                    <>
                      {asset
                        ? assetFormIndex[instrumentTypeFriendlyName]
                          ? assetFormIndex[
                              instrumentTypeFriendlyName
                            ].formComponent()
                          : "Error: Unsupported Asset Type"
                        : null}
                    </>
                  ),
                  allowSubPaths: false,
                },
                {
                  name: "primary-market",
                  id: "v2-primaryMarket",
                  path: `${props.basePath}/${TabPath.PrimaryMarket}`,
                  component: assetListingContent(),
                  allowSubPaths: false,
                },
                {
                  name: "marketingMedia",
                  id: "v2-marketingMedia",
                  path: `${props.basePath}/${TabPath.MarketingMedia}`,
                  component: (
                    <>
                      {/* TODO: This logic will change when the marketing media doesn't depend on an active listing */}
                      {asset &&
                      !draftState &&
                      listing.state == ListingState.Active ? (
                        <MarketingMediaForm asset={asset} listing={listing} />
                      ) : (
                        <CardContent>
                          <NoDataCard
                            title={"Instrument not ready for marketing media"}
                            subTitle={[
                              "You can add marketing information for your instrument as soon as the asset has been pre-issued.",
                            ]}
                          />
                        </CardContent>
                      )}
                    </>
                  ),
                  allowSubPaths: false,
                },
                {
                  name: "marketingMedia",
                  id: "v2-marketingMedia",
                  path: `${props.basePath}/${TabPath.MarketingMedia}`,
                  component: (
                    <>
                      {asset && !draftState ? (
                        <MarketingMediaForm asset={asset} listing={listing} />
                      ) : (
                        <CardContent>
                          <NoDataCard
                            title={"Instrument not ready for marketing media"}
                            subTitle={[
                              "You can add marketing information for your instrument as soon as the asset has been pre-issued.",
                            ]}
                          />
                        </CardContent>
                      )}
                    </>
                  ),
                  allowSubPaths: false,
                },
                {
                  name: "corporate-actions",
                  id: "v2-corporateActions",
                  path: `${props.basePath}/${TabPath.CorporateActions}`,
                  component: (
                    <>
                      {asset && listing && subscriptionOrderBook ? (
                        <CorporateActions asset={asset} system={props.system} />
                      ) : null}
                    </>
                  ),
                  allowSubPaths: false,
                },
              ]}
            />
          </Box>
        </Card>
      </>
    );
  }

  return (
    <>
      {loading ? null : (
        <>
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              margin: theme.spacing(-0.05, 0, 2, 0),
              gap: theme.spacing(1),
            })}
          >
            <Link to={props.backPath} style={{ textDecoration: "none" }}>
              <Typography
                sx={(theme) => ({
                  cursor: "pointer",
                  color: theme.palette.secondary.main,
                  "&:hover": {
                    color: theme.palette.secondary.light,
                    textDecoration: "underline",
                  },
                })}
                variant="body1"
              >
                {"< Back to Instruments"}
              </Typography>
            </Link>
            <Link
              to={props.backPath}
              style={{
                textDecoration: "none",
                marginLeft: "auto",
              }}
            >
              <Typography
                sx={(theme) => ({
                  cursor: "pointer",
                  color: theme.palette.secondary.main,
                  "&:hover": {
                    color: theme.palette.secondary.light,
                    textDecoration: "underline",
                  },
                })}
                variant="body1"
              >
                {"Instruments"}
              </Typography>
            </Link>
            <Typography
              variant="body1"
              sx={(theme) => ({
                color: theme.palette.text.disabled,
              })}
            >
              {"/"}
            </Typography>
            <Typography
              variant="body1"
              sx={(theme) => ({
                color: theme.palette.text.primary,
              })}
            >
              {asset?.assetShortName() === "" ? "New" : asset?.assetShortName()}
            </Typography>
          </Box>

          <Box paddingBottom={"0.8rem"}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => {
                if (value === "") {
                  return true;
                }
                return option === value;
              }}
              className={classes.chooseInstrumentsSelectionFormField}
              id="newInstrumentDialog-tokenClass-autoComplete"
              options={AllInstrumentsSelectiones}
              readOnly={preIssueState || !draftState}
              onChange={(__: object, value) => {
                if (!value) {
                  setInstrumentSelection(undefined);
                  return;
                }
                // TODO: This should be refactored as soon as the new ETN, ETF and Unit trust builder pages are
                // Should be moved to the instrument selection use effect
                switch (value) {
                  case InstrumentsSelection.ETN:
                    navigate(InstrumentsViewPaths.NewInstrument);
                    break;
                  case InstrumentsSelection.ETF:
                    navigate(InstrumentsViewPaths.NewInstrument);
                    break;
                  default:
                    setInstrumentSelection(value as InstrumentsSelection);
                    break;
                }
              }}
              value={instrumentSelection?.toString() ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="newInstrumentDialog-tokenClass-autoCompleteTextField"
                  label="Select Instrument Type"
                  placeholder="Select..."
                  variant="outlined"
                />
              )}
            />
          </Box>
        </>
      )}

      {cardContent}
    </>
  );
};
