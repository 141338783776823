import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  formatTextNum,
  FormatTextNumOpts,
  giveMe0s,
  stripThousandSeparators,
} from "utilities/number";
import { TextField, TextFieldProps } from "./TextField";
import BigNumber from "bignumber.js";

type AddTextNumFieldProps = {
  value: BigNumber | string | undefined;
  percentage?: boolean;
};

export type TextNumFieldProps = FormatTextNumOpts &
  TextFieldProps &
  AddTextNumFieldProps;

export function TextNumField({
  disallowNegative,
  noDecimalPlaces,
  percentage,
  value: externalValue,
  ...props
}: TextNumFieldProps) {
  const formattedExternalValue = formatTextNum(
    externalValue === undefined ? "" : externalValue,
    {
      disallowNegative,
      noDecimalPlaces,
    },
  );
  const [neverChanged, setNeverChanged] = useState(true);
  const [renderedValue, setRenderedValue] = useState<string>(
    formattedExternalValue,
  );
  const prevRenderedValue = usePrevious(renderedValue);

  // parse relevant values before doing any maths
  const bigRenderedValue = new BigNumber(
    stripThousandSeparators(renderedValue),
  );
  const bigExternalValue = new BigNumber(`${externalValue}`);

  // to update rendered value when value of field is updated by an action
  // external to this component
  if (
    // (if formatted external value is different to both the
    // current and last rendered values) AND
    !(
      formattedExternalValue === renderedValue ||
      formattedExternalValue === prevRenderedValue
    ) &&
    // the current external value and rendered values are
    // valid numbers that differ numerically OR
    ((!bigExternalValue.isNaN() &&
      !bigRenderedValue.isNaN() &&
      !bigExternalValue.eq(bigRenderedValue)) ||
      // the current external value is a valid number
      // and the rendered value is not
      (!bigExternalValue.isNaN() && bigRenderedValue.isNaN()))
  ) {
    setRenderedValue(formattedExternalValue);
  }

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    // format changed value to implement rounding, special character processing etc
    let value = e.target.value;
    if (percentage) {
      value = value.replace("%", "");
    }
    if (value === "0-" || /^[-]\D$/g.test(value)) {
      value = "-";
    }

    // Apply logic to disallow decimal values if noDecimalPlaces is set to 0
    if (noDecimalPlaces === 0) {
      // Remove any decimal points and fractional parts
      value = value.replace(/\./g, "");
    }

    const formattedValue = formatTextNum(value, {
      disallowNegative,
      noDecimalPlaces,
    });

    const bigFormattedWithoutSeparators = new BigNumber(
      stripThousandSeparators(formattedValue),
    );
    if (
      // if an onChange handler is given AND
      props.onChange && // new value IS a number AND
      ((!bigFormattedWithoutSeparators.isNaN() &&
        // old value is NOT a number OR
        (bigRenderedValue.isNaN() ||
          // old value is a number that differs numerically from the new value
          !bigRenderedValue.eq(bigFormattedWithoutSeparators))) ||
        // new formatted value is a different length to the old value OR
        formattedValue.length !== renderedValue.length ||
        // value has changed from the rendered value in
        // such a way that the formatted version is ''
        formattedValue === "")
    ) {
      // call on change
      props.onChange({
        ...e,
        target: {
          ...e.target,
          value:
            formattedValue === ""
              ? // call with 0 if formatted value is blank
                // so that clearing a field triggers onChange(value = 0)
                // even if field is shown blank
                "0"
              : bigFormattedWithoutSeparators.toString(),
        },
      });
    }

    // indicate that field has changed at least once
    if (neverChanged) {
      setNeverChanged(false);
    }

    // update value rendered in text field
    setRenderedValue(formattedValue);
  };

  return (
    <TextField
      {...props}
      value={
        // If field has never been changed, the value to render is '0' and the
        // field is not in read only mode, then render '' instead of the zero.
        // This is to show number fields as blank on blank forms on first render
        // instead of the 0 that a blank BigNumber.toString() will resolve to.
        `${
          neverChanged && renderedValue === "0" && !props.readOnly
            ? ""
            : renderedValue
        }${percentage ? "%" : ""}`
      }
      placeholder={"0." + giveMe0s(noDecimalPlaces ? noDecimalPlaces : 2)}
      onChange={handleChange}
      inputProps={{
        ...props.inputProps,
        type: undefined,
      }}
    />
  );
}

function usePrevious(value: string) {
  const ref = useRef<string | undefined>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
