"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringToCorporateActionState = exports.corporateActionStateToString = exports.allCorporateActionStates = void 0;
const corporateActionState_pb_1 = require("./corporateActionState_pb");
// Get all frequencies as enum values
exports.allCorporateActionStates = Object.values(corporateActionState_pb_1.CorporateActionState).filter((value) => typeof value === "number");
// Define explicit mappings between CorporateActionState enums and custom string representations
const corporateActionStateToStringMapping = {
    [corporateActionState_pb_1.CorporateActionState.UNDEFINED_CORPORATE_ACTION_STATE]: "-",
    [corporateActionState_pb_1.CorporateActionState.DRAFT_CORPORATE_ACTION_STATE]: "Draft",
    [corporateActionState_pb_1.CorporateActionState.DELETED_CORPORATE_ACTION_STATE]: "Deleted",
    [corporateActionState_pb_1.CorporateActionState.FIXED_CORPORATE_ACTION_STATE]: "Fixed",
    [corporateActionState_pb_1.CorporateActionState.EXECUTED_CORPORATE_ACTION_STATE]: "Executed",
};
// Reverse mapping from string to CorporateActionState enum
const stringToCorporateActionStateMapping = {};
for (const [key, value] of Object.entries(corporateActionStateToStringMapping)) {
    stringToCorporateActionStateMapping[value] = Number(key);
}
class UnsupportedCorporateActionStateError extends Error {
    constructor(corporateActionState) {
        const message = `Unsupported CorporateActionState: ${corporateActionState}`;
        super(message);
        this.corporateActionState = corporateActionState;
    }
}
/**
 * Converts a CorporateActionState enum instance to a custom string representation.
 * @param {CorporateActionState} corporateActionState - The corporateActionState to convert.
 * @returns {string} The custom string representation of the corporateActionState.
 */
function corporateActionStateToString(corporateActionState) {
    if (corporateActionState in corporateActionStateToStringMapping) {
        return corporateActionStateToStringMapping[corporateActionState];
    }
    else {
        throw new UnsupportedCorporateActionStateError(corporateActionState);
    }
}
exports.corporateActionStateToString = corporateActionStateToString;
class UnsupportedCorporateActionStateStringError extends Error {
    constructor(corporateActionStateStr) {
        const message = `Unsupported corporateActionState string: ${corporateActionStateStr}`;
        super(message);
        this.corporateActionStateStr = corporateActionStateStr;
    }
}
/**
 * Converts a custom string representation to a CorporateActionState enum instance.
 * @param {string} corporateActionStateStr - The custom string representation of the corporateActionState.
 * @returns {CorporateActionState} The corresponding CorporateActionState enum instance.
 */
function stringToCorporateActionState(corporateActionStateStr) {
    if (corporateActionStateStr in stringToCorporateActionStateMapping) {
        return stringToCorporateActionStateMapping[corporateActionStateStr];
    }
    else {
        throw new UnsupportedCorporateActionStateStringError(corporateActionStateStr);
    }
}
exports.stringToCorporateActionState = stringToCorporateActionState;
