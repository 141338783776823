import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { AssetClass } from "@mesh/common-js/dist/financial/assetClass_pb";
import { UnitCategory } from "@mesh/common-js/dist/financial/unitCategory_pb";
import { AmountWrapper } from "@mesh/common-js/dist/ledger";
import { FutureToken } from "@mesh/common-js/dist/ledger/token_pb";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { FutureAmount } from "@mesh/common-js/dist/ledger/amount_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { ValidationResult } from "common/validation";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { SmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLeg_pb";
import { SmartInstrumentLegType } from "@mesh/common-js/dist/financial/smartInstrumentLegType_pb";
import { BulletSmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLegBullet_pb";
import { NonDeferrableFloatingRateSmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLegNonDeferrableFloatingRate_pb";
import { DeferrableFloatingRateSmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLegDeferrableFloatingRate_pb";
import { v4 as uuidV4 } from "uuid";
import { ScheduleConfiguration } from "@mesh/common-js/dist/financial/scheduleConfiguration_pb";
import { NonPerpetualScheduleConfiguration } from "@mesh/common-js/dist/financial/scheduleConfigurationNonPerpetual_pb";
import { AssetflowCategory } from "@mesh/common-js/dist/financial/assetflowCategory_pb";
import {
  dayjsToProtobufTimestamp,
  protobufTimestampToDayjs,
} from "@mesh/common-js/dist/googleProtobufConverters";
import { Calendar } from "@mesh/common-js/dist/financial/calendar_pb";
import { BusinessDayConvention } from "@mesh/common-js/dist/financial/businessDayConvention_pb";
import { DateGenerationRule } from "@mesh/common-js/dist/financial/dateGenerationRule_pb";
import { Frequency } from "@mesh/common-js/dist/financial/frequency_pb";
import { DayCountConvention } from "@mesh/common-js/dist/financial/dayCountConvention_pb";
import { RateSource } from "@mesh/common-js/dist/financial/rateSource_pb";
import { PeriodUnit } from "@mesh/common-js/dist/financial/periodUnit_pb";
import { validateSmartInstrumentLeg } from "./components/LegsForm/validation";
import dayjs from "dayjs";
import {
  bigNumberToDecimal,
  decimalToBigNumber,
} from "@mesh/common-js/dist/num";
import BigNumber from "bignumber.js";
import { FutureDocument } from "@mesh/common-js/dist/document/document_pb";

export type SmartInstrumentFormData = {
  smartIntrumentCopy: SmartInstrument;
  smartInstrument: SmartInstrument;
};

export type FormUpdaterSpecsType = {
  smartInstrument: (
    smartInstrument: SmartInstrument,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  ownerID: (
    ownerID: string,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  issueDate: (
    issueDate?: Timestamp,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  nominalAmount: (
    nominalAmount: FutureAmount,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  nominalAmountToken: (
    nominalAmountToken: FutureToken,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  nominalAmountValue: (
    nominalAmountValue: Decimal,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  name: (
    name: string,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  assetClass: (
    assetClass: AssetClass,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  unitCategory: (
    unitCategory: UnitCategory,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  fractionalisationAllowed: (
    fractionalisationAllowed: boolean,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  timezone: (
    timezone: Timezone,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  addLeg: (
    smartInstrumentLegType: SmartInstrumentLegType,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  removeLeg: (
    legIdx: number,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  updateLeg: (
    args: { smartInstrumentLeg: SmartInstrumentLeg; legIdx: number },
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  addDocuments: (
    documents: FutureDocument[],
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  removeDocument: (
    docdx: number,
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
  changeDocumentDescription: (
    args: { docIdx: number; newDescription: string },
    prevFormData?: SmartInstrumentFormData,
  ) => SmartInstrumentFormData;
};

export const formDataUpdaterSpecs: FormUpdaterSpecsType = {
  smartInstrument(
    smartInstrument: SmartInstrument,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: smartInstrument,
      smartIntrumentCopy: SmartInstrument.deserializeBinary(
        smartInstrument.serializeBinary(),
      ),
    };
  },
  ownerID(
    ownerID: string,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setOwnerid(ownerID),
    };
  },
  issueDate(
    issueDate?: Timestamp,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setIssuedate(issueDate),
    };
  },
  nominalAmount(
    nominalAmount: FutureAmount,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setUnitnominal(nominalAmount),
    };
  },
  nominalAmountToken(
    nominalAmountToken: FutureToken,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setUnitnominal(
        new AmountWrapper(formData.smartInstrument.getUnitnominal()).setToken(
          nominalAmountToken,
        ).amount,
      ),
    };
  },
  nominalAmountValue(
    nominalAmountValue: Decimal,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setUnitnominal(
        new AmountWrapper(formData.smartInstrument.getUnitnominal()).setValue(
          nominalAmountValue,
        ).amount,
      ),
    };
  },
  name(
    name: string,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setName(
        name.length > 60 ? formData.smartInstrument.getName() : name,
      ),
    };
  },
  assetClass(
    assetClass: AssetClass,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setAssetclass(assetClass),
    };
  },
  unitCategory(
    unitCategory: UnitCategory,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setUnitcategory(unitCategory),
    };
  },
  fractionalisationAllowed(
    fractionalisationAllowed: boolean,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setFractionalisationallowed(
        fractionalisationAllowed,
      ),
    };
  },
  timezone(
    timezone: Timezone,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    return {
      ...formData,
      smartInstrument: formData.smartInstrument.setTimezone(timezone),
    };
  },
  addLeg(
    smartInstrumentLegType: SmartInstrumentLegType,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;

    switch (smartInstrumentLegType) {
      case SmartInstrumentLegType.BULLET_SMART_INSTRUMENT_LEG_TYPE:
        formData.smartInstrument.addLegs(
          new SmartInstrumentLeg().setBulletsmartinstrumentleg(
            new BulletSmartInstrumentLeg()
              .setId(uuidV4())
              .setName(
                `Leg ${formData.smartInstrument.getLegsList().length}: Bullet Leg`,
              )
              .setAmount(formData.smartInstrument.getUnitnominal())
              .setAssetflowcategory(
                AssetflowCategory.PRINCIPAL_ASSETFLOW_CATEGORY,
              )
              .setDate(
                dayjsToProtobufTimestamp(
                  protobufTimestampToDayjs(
                    formData.smartInstrument.getIssuedate() ?? new Timestamp(),
                  ).add(5, "y"),
                ),
              )
              .setBusinessdayconvention(
                BusinessDayConvention.MODIFIED_FOLLOWING_BUSINESS_DAY_CONVENTION,
              )
              .setCalendarsList([Calendar.SOUTH_AFRICA_CALENDAR]),
          ),
        );
        break;

      case SmartInstrumentLegType.DEFERRABLE_FLOATING_RATE_SMART_INSTRUMENT_LEG_TYPE:
        formData.smartInstrument.addLegs(
          new SmartInstrumentLeg().setDeferrablefloatingratesmartinstrumentleg(
            new DeferrableFloatingRateSmartInstrumentLeg()
              .setId(uuidV4())
              .setName(
                `Leg ${formData.smartInstrument.getLegsList().length}: Deferrable Floating Rate Leg`,
              )
              .setNotional(formData.smartInstrument.getUnitnominal())
              .setAssetflowcategory(
                AssetflowCategory.INTEREST_ASSETFLOW_CATEGORY,
              )
              .setDaycountconvention(
                DayCountConvention.ACTUAL_OVER_365_FIXED_DAY_COUNT_CONVENTION,
              )
              .setRatesource(RateSource.SOUTH_AFRICA_PRIME_RATE_SOURCE)
              .setReferenceratefactor(bigNumberToDecimal(new BigNumber("100")))
              .setRatespread(bigNumberToDecimal(new BigNumber("0")))
              .setRatefloor(bigNumberToDecimal(new BigNumber("0")))
              .setRateresetperiodunit(PeriodUnit.DAYS_PERIOD_UNIT)
              .setRateresetperiodcount(0)
              .setRateresetbusinessdayconvention(
                BusinessDayConvention.UNADJUSTED_BUSINESS_DAY_CONVENTION,
              )
              .setScheduleconfiguration(
                new ScheduleConfiguration().setNonperpetualscheduleconfiguration(
                  new NonPerpetualScheduleConfiguration()
                    .setStartdate(formData.smartInstrument.getIssuedate())
                    .setEnddate(
                      dayjsToProtobufTimestamp(
                        protobufTimestampToDayjs(
                          formData.smartInstrument.getIssuedate() ??
                            new Timestamp(),
                        ).add(5, "y"),
                      ),
                    )
                    .setFrequency(Frequency.MONTHLY_FREQUENCY)
                    .setDategenerationrule(
                      DateGenerationRule.BACKWARD_DATE_GENERATION_RULE,
                    )
                    .setBusinessdayconvention(
                      BusinessDayConvention.MODIFIED_FOLLOWING_BUSINESS_DAY_CONVENTION,
                    )
                    .setEnddatebusinessdayconvention(
                      BusinessDayConvention.UNDEFINED_BUSINESS_DAY_CONVENTION,
                    )
                    .setCalendarsList([Calendar.SOUTH_AFRICA_CALENDAR]),
                ),
              ),
          ),
        );
        break;

      case SmartInstrumentLegType.NON_DEFERRABLE_FLOATING_RATE_SMART_INSTRUMENT_LEG_TYPE:
        formData.smartInstrument.addLegs(
          new SmartInstrumentLeg().setNondeferrablefloatingratesmartinstrumentleg(
            new NonDeferrableFloatingRateSmartInstrumentLeg()
              .setId(uuidV4())
              .setNotional(formData.smartInstrument.getUnitnominal())
              .setAssetflowcategory(
                AssetflowCategory.INTEREST_ASSETFLOW_CATEGORY,
              )
              .setScheduleconfiguration(
                new ScheduleConfiguration().setNonperpetualscheduleconfiguration(
                  new NonPerpetualScheduleConfiguration()
                    .setStartdate(formData.smartInstrument.getIssuedate())
                    .setEnddate(
                      dayjsToProtobufTimestamp(
                        protobufTimestampToDayjs(
                          formData.smartInstrument.getIssuedate() ??
                            new Timestamp(),
                        ).add(5, "y"),
                      ),
                    )
                    .setFrequency(Frequency.MONTHLY_FREQUENCY)
                    .setDategenerationrule(
                      DateGenerationRule.BACKWARD_DATE_GENERATION_RULE,
                    )
                    .setBusinessdayconvention(
                      BusinessDayConvention.MODIFIED_FOLLOWING_BUSINESS_DAY_CONVENTION,
                    )
                    .setCalendarsList([Calendar.SOUTH_AFRICA_CALENDAR]),
                ),
              ),
          ),
        );
        break;
    }

    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
  removeLeg(
    legIdx: number,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;

    formData.smartInstrument.setLegsList(
      formData.smartInstrument.getLegsList().filter((_, idx) => idx !== legIdx),
    );

    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
  updateLeg(
    {
      smartInstrumentLeg,
      legIdx,
    }: { smartInstrumentLeg: SmartInstrumentLeg; legIdx: number },
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;

    const updatedLegsList = formData.smartInstrument.getLegsList();
    updatedLegsList[legIdx] = smartInstrumentLeg;

    formData.smartInstrument.setLegsList(updatedLegsList);

    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
  addDocuments(
    documents: FutureDocument[],
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    documents.forEach((d) => formData.smartInstrument.addDocuments(d));
    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
  removeDocument(
    docdx: number,
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    const updatedDocsList = formData.smartInstrument
      .getDocumentsList()
      .filter((_, idx) => idx !== docdx);
    formData.smartInstrument.setDocumentsList(updatedDocsList);
    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
  changeDocumentDescription(
    args: { docIdx: number; newDescription: string },
    prevFormData?: SmartInstrumentFormData,
  ): SmartInstrumentFormData {
    const formData = prevFormData as SmartInstrumentFormData;
    const updatedDocsList = formData.smartInstrument.getDocumentsList();
    updatedDocsList[args.docIdx] = updatedDocsList[args.docIdx].setDescription(
      args.newDescription,
    );
    formData.smartInstrument.setDocumentsList(updatedDocsList);
    return {
      ...formData,
      smartInstrument: formData.smartInstrument,
    };
  },
};

export const formDataValidationFunc = async (
  formData: SmartInstrumentFormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  // perform necessary conversions
  const issueDate = protobufTimestampToDayjs(
    formData.smartInstrument.getIssuedate() ?? new Timestamp(),
  );
  const unitNominalValue =
    formData.smartInstrument.getUnitnominal()?.getValue() ?? new Decimal();

  //perform validations
  if (formData.smartInstrument.getOwnerid() === "") {
    validationResult.valid = false;
    validationResult.fieldValidations.ownerID = "Must be set";
  }

  if (formData.smartInstrument.getName().length === 0) {
    validationResult.valid = false;
    validationResult.fieldValidations.name = "Must be set";
  } else if (formData.smartInstrument.getName().length < 3) {
    validationResult.valid = false;
    validationResult.fieldValidations.name = "Must be more than 3 characters";
  }

  if (
    formData.smartInstrument.getAssetclass() ===
    AssetClass.UNDEFINED_ASSET_CLASS
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.assetClass = "Must be set";
  }

  if (issueDate.isBefore(dayjs())) {
    validationResult.valid = false;
    validationResult.fieldValidations.issueDate = "Must be in the future";
  }

  if (formData.smartInstrument.getTimezone() === Timezone.UNDEFINED_TIMEZONE) {
    validationResult.valid = false;
    validationResult.fieldValidations.timezone = "Must be set";
  }

  if (
    formData.smartInstrument.getUnitcategory() ===
    UnitCategory.UNDEFINED_UNIT_CATEGORY
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.unitCategory = "Must be set";
  }

  if (
    !decimalToBigNumber(unitNominalValue).isPositive() &&
    !decimalToBigNumber(unitNominalValue).isNegative()
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.unitNominalValue = "Invalid character";
  } else if (decimalToBigNumber(unitNominalValue).isLessThanOrEqualTo(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations.unitNominalValue =
      "Must be greater than 0";
  }

  if (formData.smartInstrument.getLegsList().length === 0) {
    validationResult.valid = false;
    validationResult.fieldValidations.legs = "Add at least 1 leg";
  }

  formData.smartInstrument.getLegsList().forEach((smartInstrumentLeg) => {
    const legValidationResult = validateSmartInstrumentLeg(smartInstrumentLeg, {
      smartInstrument: formData.smartInstrument,
    });
    validationResult.valid =
      legValidationResult.valid && validationResult.valid;
    for (const field in legValidationResult.fieldValidations) {
      validationResult.fieldValidations[field] =
        legValidationResult.fieldValidations[field];
    }
  });

  return validationResult;
};
