import React from "react";
import { Box, TextareaAutosize, Typography, useTheme } from "@mui/material";

export type Props = {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  id?: string;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength: number;
};
export const TextArea = (props: Props) => {
  const theme = useTheme();

  if (props.readOnly) {
    return (
      <Box>
        <Typography
          sx={{
            width: "100%",
            maxWidth: "100%",
            maxHeight: 131,
            overflowY: "auto",
          }}
          className={"meshScroll"}
          variant="body1"
          color="textPrimary"
          children={props.value}
        />
      </Box>
    );
  } else {
    return (
      <Box
        id={props.id}
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "1fr",
          rowGap: theme.spacing(0.5),
        })}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          children={props.label}
        />
        <TextareaAutosize
          style={{
            color: theme.palette.text.primary,
            padding: theme.spacing(0.5, 1),
            fontSize: 16,
            backgroundColor: theme.palette.background.paper,
            width: "100%",
            maxWidth: "100%",
            borderRadius: 4,
            fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
            resize: "none",
          }}
          id={props.id}
          minRows={5}
          maxRows={5}
          disabled={props.disabled}
          value={props.value}
          onChange={(e) => {
            let newValue: string = e.target.value;
            if (newValue.length >= props.maxLength) {
              newValue = newValue.slice(0, props.maxLength);
            }
            props.onChange(newValue);
          }}
          className={"meshScroll"}
        />
        <Typography
          variant="body2"
          color="textSecondary"
          children={`${500 - props.value.length} characters left`}
        />
      </Box>
    );
  }
};
