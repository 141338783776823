import React from "react";
import { Banner } from "./components/Banner";
import { Offers } from "./components/Offers";
import { Theme, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Footer } from "layouts/Marketplace/components/Footer";

export const Markets = () => {
  const showBanner = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm"),
  );

  return (
    <>
      <Box
        sx={(theme) => ({
          width: "100%",
          maxWidth: theme.breakpoints.values.lg - 32,
          px: {
            xs: 2,
            sm: 3,
            lg: 0,
          },
          pb: {
            xs: 5,
            sm: 0,
          },
        })}
      >
        {showBanner && (
          <Box sx={{ pt: 3 }}>
            <Banner />
          </Box>
        )}
        <Offers />
      </Box>
      <Box
        sx={(theme) => ({
          width: "100%",
          mt: "auto",
          [theme.breakpoints.down("sm")]: {
            position: "absolute",
            bottom: 0,
          },
        })}
      >
        <Footer />
      </Box>
    </>
  );
};
