import { useCurrentAPICall, useIsMounted } from "hooks";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import {
  DirectOrderSubmitter,
  IssuerSubmitDirectSellOrderRequest,
} from "james/market/DirectOrderSubmitter";
import {
  DirectOrderFeeCalculator,
  CalculateDirectOrderFeeResponse,
} from "james/remuneration/DirectOrderFeeCalculator";
import {
  DirectOrder,
  DirectOrderState,
  DirectOrderType,
} from "james/market/DirectOrder";
import { TouchedFields, ValidationResult } from "common/validation";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { TextNumField } from "components/FormFields";
import { Amount } from "components/Ledger/Amount";
import cx from "classnames";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { Balance } from "james/stellar/Account";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { formatTextNum } from "utilities/number";
import { Header } from "views/Marketplace/components/PlaceOrderDialog/components/Header";
import {
  Model as PotDirectOrderParticipantViewModel,
  usePotDirectOrderParticipantViewReaderRead,
} from "james/views/potDirectOrderParticipantView";
import {
  TextNEExactCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { NumFieldHlpTxt, TextFieldHlpTxt } from "validationHelperText";
import { useNotificationContext } from "context/Notification";
import { GroupNotificationChannel } from "james/group";
import {
  MarketDirectOrderViewModelChangedNotification,
  MarketDirectOrderViewModelChangedNotificationTypeName,
  MarketDirectOrderViewNotificationChannelName,
} from "james/views/marketDirectOrderView";
import { Notification } from "james/notification/Notification";
import { QuoteParameter } from "james/market/Mechanism";
import { IssuerDirectOrderCardProps } from "./IssuerDirectOrderCard";
import BigNumber from "bignumber.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import range from "lodash/range";
import LogRocket from "logrocket";
import { userTypingAPICallDebounceIntervalMS } from "common/debouncing";
import { JSONRPCCallAbortedError } from "utilities/network/jsonRPCRequest";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { AssetEvent } from "const/logRocket";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
const PREFIX = "SellCard";

const classes = {
  sectionWithPadding: `${PREFIX}-sectionWithPadding`,
  sectionWithColumns2Gap: `${PREFIX}-sectionWithColumns2Gap`,
  textNumFieldCode: `${PREFIX}-textNumFieldCode`,
  balanceLayout: `${PREFIX}-balanceLayout`,
  balanceAvailableText: `${PREFIX}-balanceAvailableText`,
  errorHelperTextLayout: `${PREFIX}-errorHelperTextLayout`,
  tradeFeeWhyTheseFeesLink: `${PREFIX}-tradeFeeWhyTheseFeesLink`,
  orderBreakdownControlRow: `${PREFIX}-orderBreakdownControlRow`,
  orderBreakDownLayout: `${PREFIX}-orderBreakDownLayout`,
  secondaryText: `${PREFIX}-secondaryText`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Dialog)(({ theme }) => ({
  [`& .${classes.sectionWithPadding}`]: {
    width: "100%",
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 6),
    },
  },

  [`& .${classes.sectionWithColumns2Gap}`]: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },

  [`& .${classes.textNumFieldCode}`]: {
    marginRight: 8,
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.balanceLayout}`]: {
    paddingLeft: theme.spacing(1.5),
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1),
    alignItems: "center",
  },

  [`& .${classes.balanceAvailableText}`]: {
    color: theme.palette.text.secondary,
  },

  [`& .${classes.errorHelperTextLayout}`]: {
    maxWidth: 300,
    paddingLeft: theme.spacing(1.5),
  },

  [`& .${classes.tradeFeeWhyTheseFeesLink}`]: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  [`& .${classes.orderBreakdownControlRow}`]: {
    padding: theme.spacing(3, 0),
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1),
    alignItems: "center",
  },

  [`& .${classes.orderBreakDownLayout}`]: {
    display: "grid",
    paddingBottom: theme.spacing(3),
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(5),
  },

  [`& .${classes.secondaryText}`]: {
    color: theme.palette.text.secondary,
  },
}));

function validateIssuerSubmitDirectSellOrderRequest(
  request: IssuerSubmitDirectSellOrderRequest,
  enteredAmountIncl: BigNumber,
  assetIssuanceTokenBalance: Balance,
  assetIssuanceTokenViewModel: LedgerTokenViewModel,
  touchedFields: TouchedFields,
  ignoreTouchedFields: boolean,
  marketMechanismQuoteParameter: QuoteParameter,
  assetFractionalisationAllowed: boolean,
): ValidationResult {
  // prepare a validation result
  const validationResult: ValidationResult = {
    // assumed to be true -
    // any error must set to false regardless of touched field state
    valid: true,
    // field validations
    fieldValidations: {},
  };

  // if counterparty not set
  if (request.counterpartyGroupID === "") {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.counterpartyGroupID) {
      // then an error message should be shown on it
      validationResult.fieldValidations.counterpartyGroupID =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  // if price is not set
  if (request.price.value.isZero()) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.price) {
      // then an error message should be shown on it
      validationResult.fieldValidations.price =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  }

  // if tokens not set
  if (request.tokens.value.isZero()) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.tokens) {
      // then an error message should be shown on it
      validationResult.fieldValidations.tokens =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  } else if (
    // otherwise if resultant token amount is out of
    // the deal size bounds as set on the listing
    request.tokens.value.gt(
      marketMechanismQuoteParameter.maximumDealSize.value,
    ) ||
    request.tokens.value.lt(marketMechanismQuoteParameter.minimumDealSize.value)
  ) {
    // then validation has failed
    validationResult.valid = false;

    // then REGARDLESS of touched field status an error message should be shown on it
    validationResult.fieldValidations.tokens = `Order size out of bounds. Min ${formatTextNum(
      marketMechanismQuoteParameter.minimumDealSize.value,
      {
        noDecimalPlaces: 7,
        addDecimalPadding: false,
      },
    )} Max ${formatTextNum(
      marketMechanismQuoteParameter.maximumDealSize.value,
      {
        noDecimalPlaces: 7,
        addDecimalPadding: false,
      },
    )} ${assetIssuanceTokenViewModel.token.code}`;
  } else if (assetIssuanceTokenBalance.amount.value.lt(request.tokens.value)) {
    // if issuance token balance is not sufficient
    // then validation has failed
    validationResult.valid = false;

    // then an error message should be shown on it REGARDLESS of touched field state
    validationResult.fieldValidations.tokens = `Insufficient Balance: ${formatTextNum(
      assetIssuanceTokenBalance.amount.value,
      {
        noDecimalPlaces: 7,
        addDecimalPadding: false,
      },
    )} ${assetIssuanceTokenViewModel.token.code}`;
  }

  // if entered amount is not set
  if (enteredAmountIncl.isZero()) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.enteredAmountIncl) {
      // then an error message should be shown on it
      validationResult.fieldValidations.enteredAmountIncl =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  }

  // apply asset fractionalisation allowed check
  if (!(assetFractionalisationAllowed || request.tokens.value.isInteger())) {
    // if the check fails then validation has failed
    validationResult.valid = false;

    // and if the tokensField has been touched
    if (ignoreTouchedFields || touchedFields.tokens) {
      // then an error message should be shwon on the tokens text num field
      validationResult.fieldValidations.tokens = "Must be a whole number";
    }
  }

  return validationResult;
}

enum LastFieldChanged {
  Neither,
  RequestTokens,
  EnteredAmountIncl,
}

export function SellCard(props: IssuerDirectOrderCardProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const isMounted = useIsMounted();

  const { authContext, loginClaims } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { registerNotificationCallback } = useNotificationContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    loading: loadingPotentialCounterparties,
    readResponse: { models: potentialCounterParties },
    readRequest: potentialCounterpartiesReadRequest,
    setReadRequest: setPotentialCounterpartiesReadRequest,
  } = usePotDirectOrderParticipantViewReaderRead({
    context: authContext,
    criteria: { id: TextNEExactCriterion(loginClaims.clientID) },
  });

  const dialogContentRef = useRef<HTMLDivElement>(null);
  const [showOrderBreakdown, setShowOrderBreakdown] = useState(false);
  const toggleShowOrderBreakDown = () => {
    // open order breakdown
    setShowOrderBreakdown(!showOrderBreakdown);

    // and scroll to the bottom in 500ms
    range(10, 500, 10).forEach((v) =>
      setTimeout(() => {
        if (dialogContentRef.current) {
          dialogContentRef.current.scrollTop =
            dialogContentRef.current.scrollHeight;
        }
      }, v),
    );
  };

  // ---- request ----
  const [issuerDirectSellOrderRequest, setIssuerDirectSellOrderRequest] =
    useState<IssuerSubmitDirectSellOrderRequest>({
      context: authContext,
      counterpartyGroupID: "",
      tokens: props.listing.token.newAmountOf("0"),
      price: props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
        props.indicativePrice.buyPrice.value,
      ),
    });
  const [useRequestForCalc, setUseRequestForCalc] = useState(false);
  const [enteredAmountIncl, setEnteredAmountIncl] = useState(
    new BigNumber("0"),
  );
  const [useAmountInclForCalc, setUseAmountInclForCalc] = useState(false);

  // ---- fee calculation (updated each time request changes) ----
  const [lastFieldChanged, setLastFieldChanged] = useState(
    LastFieldChanged.Neither,
  );
  const [calculateDirectOrderFeeResponse, setCalculateDirectOrderFeeResponse] =
    useState<CalculateDirectOrderFeeResponse>({
      feeAmount: new BigNumber("0"),
      amountIncl: new BigNumber("0"),
      amountExcl: new BigNumber("0"),
      tokens: new BigNumber("0"),
    });
  const [feeCalculationInProgress, setFeeCalculationInProgress] =
    useState(false);
  const feeCalculationTimeoutRef = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );
  const [
    isCurrentCalculateDirectOrderFeeAPICall,
    initCalculateDirectOrderFeeAPICall,
  ] = useCurrentAPICall();
  useEffect(() => {
    if (!isMounted()) {
      return;
    }

    // only proceed if either request or tokens should be used for calc
    if (!(useRequestForCalc || useAmountInclForCalc)) {
      return;
    }

    if (useRequestForCalc) {
      setUseRequestForCalc(false);

      // only perform fee calculation if a non-zero tokens and price is entered
      if (
        issuerDirectSellOrderRequest.tokens.value.isZero() ||
        issuerDirectSellOrderRequest.price.value.isZero()
      ) {
        setCalculateDirectOrderFeeResponse({
          feeAmount: new BigNumber("0"),
          amountIncl: new BigNumber("0"),
          amountExcl: new BigNumber("0"),
          tokens: new BigNumber("0"),
        });
        return;
      }
    }

    if (useAmountInclForCalc) {
      setUseAmountInclForCalc(false);

      // only perform fee calculation if a non-zero value of amountIncl and price is entered
      if (
        enteredAmountIncl.isZero() ||
        issuerDirectSellOrderRequest.price.value.isZero()
      ) {
        setCalculateDirectOrderFeeResponse({
          feeAmount: new BigNumber("0"),
          amountIncl: new BigNumber("0"),
          amountExcl: new BigNumber("0"),
          tokens: new BigNumber("0"),
        });
        return;
      }
    }

    // indicate that fee calculation is in progress
    setFeeCalculationInProgress(true);

    // initialise API call
    const { apiCallID, abortController } = initCalculateDirectOrderFeeAPICall();

    // set up new fee calculation to take place after debounce interval
    clearTimeout(feeCalculationTimeoutRef.current);
    feeCalculationTimeoutRef.current = setTimeout(async () => {
      try {
        // if request should be used for calc...
        if (useRequestForCalc) {
          // then use
          const result = await DirectOrderFeeCalculator.CalculateDirectOrderFee(
            {
              context: authContext,
              directOrderType: DirectOrderType.Sell,
              price: issuerDirectSellOrderRequest.price.value, // price from request
              tokens: issuerDirectSellOrderRequest.tokens.value, // tokens from request
            },
            { signal: abortController.signal },
          );
          if (
            isMounted() &&
            isCurrentCalculateDirectOrderFeeAPICall(apiCallID)
          ) {
            setEnteredAmountIncl(result.amountIncl); // update amountIncl field from result
            setCalculateDirectOrderFeeResponse(result);
          } else {
            return;
          }
        }

        // if amountIncl should be used for calc...
        if (useAmountInclForCalc) {
          // then use
          const result = await DirectOrderFeeCalculator.CalculateDirectOrderFee(
            {
              context: authContext,
              directOrderType: DirectOrderType.Sell,
              price: issuerDirectSellOrderRequest.price.value, // only price from request
              amountIncl: enteredAmountIncl, // amountIncl as entered
            },
            { signal: abortController.signal },
          );
          if (
            isMounted() &&
            isCurrentCalculateDirectOrderFeeAPICall(apiCallID)
          ) {
            setIssuerDirectSellOrderRequest({
              // update request from result
              ...issuerDirectSellOrderRequest,
              tokens: issuerDirectSellOrderRequest.tokens.setValue(
                result.tokens,
              ),
            });
            setCalculateDirectOrderFeeResponse(result);
          } else {
            return;
          }
        }
      } catch (e) {
        if (
          errorContextErrorTranslator.translateError(e).code ===
          JSONRPCCallAbortedError.ErrorCode
        ) {
          return;
        }
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error calculating direct order fee: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setFeeCalculationInProgress(false);
    }, userTypingAPICallDebounceIntervalMS);
  }, [
    useAmountInclForCalc,
    useRequestForCalc,
    enteredAmountIncl,
    isMounted,
    authContext,
    issuerDirectSellOrderRequest,
  ]);

  // ----- Request update and validation -----
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [requestValidationResult, setRequestValidationResult] =
    useState<ValidationResult>({
      valid: false,
      fieldValidations: {},
    });
  const validationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  type IssuerSubmitDirectSellOrderRequestField =
    keyof IssuerSubmitDirectSellOrderRequest;
  type IssuerSubmitDirectSellOrderRequestValue<
    T extends IssuerSubmitDirectSellOrderRequestField,
  > = IssuerSubmitDirectSellOrderRequest[T];
  const handleUpdateRequest =
    (field: string, fieldsAffected?: string[]) =>
    <T extends IssuerSubmitDirectSellOrderRequestField>(
      newValue: IssuerSubmitDirectSellOrderRequestValue<T>,
    ) => {
      // prepare updated request
      const updatedRequest = {
        ...issuerDirectSellOrderRequest,
        [field]: newValue,
      };

      // prepare updated touched fields
      const updatedTouchedFields = {
        ...touchedFields,
        [field]: true,
      };
      if (fieldsAffected) {
        fieldsAffected.forEach((f) => {
          updatedTouchedFields[f] = true;
        });
      }

      // determine what field should be used next for calculation
      switch (field) {
        case "counterparty":
          // do not use either request nor amountIncl for calc
          // regardless of which was the last field that was changed
          setUseAmountInclForCalc(false);
          setUseRequestForCalc(false);
          break;

        case "price":
          // depending on last field changed,
          // set what should be used for calc
          switch (lastFieldChanged) {
            case LastFieldChanged.EnteredAmountIncl:
              setUseAmountInclForCalc(true);
              break;

            case LastFieldChanged.RequestTokens:
            case LastFieldChanged.Neither:
            default:
              setUseRequestForCalc(true);
          }
          break;

        case "tokens":
          // use request for calc
          setUseRequestForCalc(true);
          // and update the field that was changed
          setLastFieldChanged(LastFieldChanged.RequestTokens);
          break;
      }

      setTouchedFields(updatedTouchedFields);
      setIssuerDirectSellOrderRequest(updatedRequest);
    };

  // perform request validation as required
  useEffect(() => {
    // clear any pending validation
    clearTimeout(validationTimeoutRef.current);

    // defer validation to take place in 200ms
    setValidationInProgress(true);
    clearTimeout(validationTimeoutRef.current);
    validationTimeoutRef.current = setTimeout(() => {
      setRequestValidationResult(
        validateIssuerSubmitDirectSellOrderRequest(
          issuerDirectSellOrderRequest,
          enteredAmountIncl,
          props.assetIssuanceTokenBalance,
          props.assetIssuanceTokenViewModel,
          touchedFields,
          false,
          props.marketMechanismQuoteParameter,
          props.marketListingViewModel.assetFractionalisationAllowed,
        ),
      );
      setValidationInProgress(false);
    }, 200);
  }, [
    issuerDirectSellOrderRequest,
    props.listing,
    enteredAmountIncl,
    props.assetIssuanceTokenBalance,
    props.assetValuationTokenViewModel,
    props.assetIssuanceTokenViewModel,
    touchedFields,
  ]);

  // order submission
  const [orderSubmissionInProgress, setOrderSubmissionInProgress] =
    useState(false);
  const handleSubmitOrder = async () => {
    setOrderSubmissionInProgress(true);
    let directOrder: DirectOrder;
    try {
      // submit direct order
      directOrder = (
        await DirectOrderSubmitter.IssuerSubmitDirectSellOrder(
          issuerDirectSellOrderRequest,
        )
      ).directOrder;

      // notify that submission is in progress
      enqueueSnackbar(`Order #${directOrder.number} is being submitted`, {
        variant: "info",
      });

      LogRocket.track(AssetEvent.placeDirectOrder, {
        assetName: props.marketListingViewModel.assetName,
        assetShortName: props.marketListingViewModel.assetShortName,
        assetType: props.marketListingViewModel.assetType,
        revenue: calculateDirectOrderFeeResponse.feeAmount.toNumber(),
      });

      // close the dialog
      props.closeDialog();
    } catch (e) {
      console.error(`submitting order`, e);
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(`Error Submitting Order: ${err.message}`, {
        variant: "warning",
      });
      setOrderSubmissionInProgress(false);
      return;
    }

    try {
      // register callback to fire once the order has reached awaiting confirmation
      const deregister = await registerNotificationCallback(
        new GroupNotificationChannel({
          groupID: directOrder.initiatingPartyGroupID,
          name: MarketDirectOrderViewNotificationChannelName,
          private: true,
        }),
        [MarketDirectOrderViewModelChangedNotificationTypeName],
        (n: Notification) => {
          if (
            n instanceof MarketDirectOrderViewModelChangedNotification &&
            n.model.directOrderID === directOrder.id
          ) {
            // notify based on state
            switch (n.model.state) {
              case DirectOrderState.Pending:
                // Do nothing during transient states
                // Return so that deregister is not called.
                return;

              case DirectOrderState.AwaitingConfirmation:
                enqueueSnackbar(
                  `Order #${n.model.number} is awaiting confirmation`,
                  { variant: "success" },
                );
                break;

              case DirectOrderState.Failed:
                enqueueSnackbar(`Order #${n.model.number} has failed`, {
                  variant: "error",
                });
                break;

              case DirectOrderState.UnderInvestigation:
                enqueueSnackbar(
                  `Something has gone wrong with Order #${n.model.number} - it's status is being investigated`,
                  { variant: "warning" },
                );
                break;
            }
            deregister();
          }
        },
      );
    } catch (e) {
      console.error("error registring for order transaction notifications", e);
      enqueueSnackbar(
        "Warning! Unable to Register for Notifications on Order - Please Check the Orders Table and Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  };

  const placeOrderButton = (
    <Tooltip
      placement={isMobile ? "top" : "bottom"}
      arrow
      title={(() => {
        switch (true) {
          case feeCalculationInProgress: {
            return "Calculation in Progress";
          }
          case validationInProgress: {
            return "Validation in Progress";
          }
          case !requestValidationResult.valid: {
            const specialMsg = requestValidationResult.fieldValidations.tokens
              ? requestValidationResult.fieldValidations.tokens.includes(
                  "Order size out of bounds.",
                )
                ? requestValidationResult.fieldValidations.tokens
                : ""
              : "";
            return (
              specialMsg ||
              "Please ensure that all fields have been completed correctly"
            );
          }
          case !props.userIsSignatoryOnTradingAcc: {
            return "You are not a Signatory on the Trading Account";
          }
        }
        return "";
      })()}
    >
      <span>
        <Button
          sx={{
            height: {
              xs: "48px",
              sm: "33px",
            },
          }}
          data-link-info={JSON.stringify({
            content_interaction_id: "transact-cta",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "place order",
            content_interaction_type: InteractionType.Button,
            content_interaction_driver: InteractionDriver.TransactionComplete,
          } as DataLinkInfoType)}
          fullWidth
          color="primary"
          variant="contained"
          children="place order"
          disabled={
            feeCalculationInProgress ||
            validationInProgress ||
            !requestValidationResult.valid ||
            orderSubmissionInProgress ||
            !props.userIsSignatoryOnTradingAcc
          }
          onClick={handleSubmitOrder}
        />
      </span>
    </Tooltip>
  );

  return (
    <Root open fullScreen={isMobile}>
      <Header
        {...{
          disableClose: orderSubmissionInProgress,
          showLoading: orderSubmissionInProgress,
          ...props,
        }}
      />
      <DialogContent
        ref={dialogContentRef}
        sx={{
          backgroundColor: theme.palette.custom.midnight,
          position: "relative",
          width: { sm: 420 },
          display: "flex",
          flexDirection: "column",
          alignItems: { sm: "center" },
          padding: "unset !important",
        }}
        className={isMobile ? undefined : "meshScroll"}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.custom.cardInner,
            display: "flex",
            flexDirection: "column",
            gap: {
              sm: theme.spacing(3),
              xs: theme.spacing(3),
            },
            padding: {
              sm: theme.spacing(3, 6),
              xs: theme.spacing(3, 3),
            },
          }}
        >
          <Typography
            sx={{ fontWeight: "bold" }}
            variant="h4"
            children="Who would you like to sell the instrument to?"
          />
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            fullWidth
            disabled={orderSubmissionInProgress}
            getOptionLabel={(option: PotDirectOrderParticipantViewModel) =>
              option.name
            }
            options={potentialCounterParties}
            loading={loadingPotentialCounterparties}
            onChange={(
              _,
              selectedModel: PotDirectOrderParticipantViewModel | null,
            ) =>
              handleUpdateRequest("counterpartyGroupID")(
                selectedModel ? selectedModel.groupID : "",
              )
            }
            value={(() => {
              const selectedModel = potentialCounterParties.find(
                (cp) =>
                  cp.groupID ===
                  issuerDirectSellOrderRequest.counterpartyGroupID,
              );
              return selectedModel || null;
            })()}
            onClose={() =>
              setPotentialCounterpartiesReadRequest({
                ...potentialCounterpartiesReadRequest,
                criteria: {
                  id: TextNEExactCriterion(loginClaims.clientID),
                },
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Counterparty"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setPotentialCounterpartiesReadRequest({
                      ...potentialCounterpartiesReadRequest,
                      criteria: {
                        id: TextNEExactCriterion(loginClaims.clientID),
                      },
                    });
                  } else {
                    setPotentialCounterpartiesReadRequest({
                      ...potentialCounterpartiesReadRequest,
                      criteria: {
                        ...potentialCounterpartiesReadRequest.criteria,
                        name: TextSubstringCriterion(e.target.value),
                      },
                    });
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Select/Start Typing...",
                  endAdornment: (
                    <>
                      {loadingPotentialCounterparties ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                error={
                  !!requestValidationResult.fieldValidations.counterpartyGroupID
                }
                helperText={
                  requestValidationResult.fieldValidations.counterpartyGroupID
                }
              />
            )}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: {
              sm: theme.spacing(3),
              xs: theme.spacing(1.5),
            },
            padding: {
              sm: theme.spacing(3, 6),
              xs: theme.spacing(3, 3),
            },
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography
            variant="h6"
            children="How much of the instrument would you like to sell?"
          />
          <div>
            <TextNumField
              id="marketDirectOrderIssuerSellCard-tokens-textNumField"
              disallowNegative
              label="Tokens"
              noDecimalPlaces={7}
              fullWidth
              disabled={orderSubmissionInProgress}
              value={issuerDirectSellOrderRequest.tokens.value}
              onChange={(e) =>
                handleUpdateRequest("tokens")(
                  issuerDirectSellOrderRequest.tokens.setValue(e.target.value),
                )
              }
              InputProps={{
                endAdornment: (
                  <>
                    <Box
                      sx={{
                        borderRight: `0.5px solid ${theme.palette.text.secondary}`,
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                        paddingRight: theme.spacing(1),
                        marginRight: theme.spacing(1),
                      }}
                    >
                      <InputAdornment
                        position={"end"}
                        sx={{ alignItems: "center" }}
                      >
                        <Button
                          id={
                            "marketDirectOrderIssuerDirectOrder-sellCard-paySetMax-button"
                          }
                          sx={{
                            width: "39px !important",
                            minWidth: 0,
                            padding: theme.spacing(0, 0.5),
                          }}
                          variant={"text"}
                          color={"secondary"}
                          children={"max"}
                          onClick={() => {
                            if (
                              props.assetValuationTokenBalance.amount.value.isZero()
                            ) {
                              return;
                            }
                            handleUpdateRequest("tokens")(
                              props.assetIssuanceTokenBalance.amount.setValue(
                                props.assetIssuanceTokenBalance.availableBalance()
                                  .value,
                              ),
                            );
                          }}
                        />
                      </InputAdornment>
                    </Box>
                    <Tooltip
                      title={`Issued by ${props.assetIssuanceTokenViewModel.issuer}`}
                      placement="top"
                    >
                      <Typography
                        id={
                          "marketDirectOrderIssuerSellCard-tokensTextNumField-unitsEndAdornment"
                        }
                        variant="body1"
                        className={classes.textNumFieldCode}
                        children={props.assetIssuanceTokenViewModel.token.code}
                      />
                    </Tooltip>
                  </>
                ),
              }}
              error={
                requestValidationResult.fieldValidations.tokens
                  ? !requestValidationResult.fieldValidations.tokens.includes(
                      "Order size out of bounds.",
                    )
                  : false
              }
            />
            {!requestValidationResult.fieldValidations.tokens ? (
              // if there are no field validations to show then available balance
              <div className={classes.balanceLayout}>
                <Typography
                  className={classes.balanceAvailableText}
                  variant="caption"
                  children="Available:"
                />
                <Amount
                  reverse
                  codeTypographyProps={{
                    variant: "caption",
                    color: "textSecondary",
                  }}
                  valueTypographyProps={{
                    variant: "caption",
                    color: "textSecondary",
                  }}
                  formatTextNumOpts={{
                    noDecimalPlaces: 7,
                    addDecimalPadding: false,
                  }}
                  amount={props.assetIssuanceTokenBalance.availableBalance()}
                />
              </div>
            ) : (
              (() => {
                // otherwise show error message
                const errMessage = requestValidationResult.fieldValidations
                  .tokens
                  ? requestValidationResult.fieldValidations.tokens.includes(
                      "Order size out of bounds.",
                    )
                    ? undefined
                    : requestValidationResult.fieldValidations.tokens
                  : undefined;
                return errMessage ? (
                  <Typography
                    className={classes.errorHelperTextLayout}
                    variant="body2"
                    color="error"
                    children={errMessage}
                  />
                ) : null;
              })()
            )}
          </div>
          <TextNumField
            id="marketDirectOrderIssuerSellCard-price-textNumField"
            disallowNegative
            label="Price"
            noDecimalPlaces={2}
            fullWidth
            disabled={orderSubmissionInProgress}
            value={issuerDirectSellOrderRequest.price.value}
            onChange={(e) =>
              handleUpdateRequest("price")(
                issuerDirectSellOrderRequest.price.setValue(e.target.value),
              )
            }
            InputProps={{
              startAdornment: (
                <Tooltip
                  title={`Issued by ${props.assetValuationTokenViewModel.issuer}`}
                  placement="top"
                >
                  <Typography
                    id={
                      "marketDirectOrderIssuerSellCard-priceTextNumField-unitsStartAdornment"
                    }
                    variant="body1"
                    className={classes.textNumFieldCode}
                    children={props.assetValuationTokenViewModel.token.code}
                  />
                </Tooltip>
              ),
            }}
            error={!!requestValidationResult.fieldValidations.price}
            helperText={requestValidationResult.fieldValidations.price}
          />
          <TextNumField
            id="marketDirectOrderIssuerSellCard-amountIncl-textNumField"
            disallowNegative
            label="Amount"
            noDecimalPlaces={2}
            fullWidth
            disabled={orderSubmissionInProgress}
            value={enteredAmountIncl}
            onChange={(e) => {
              setLastFieldChanged(LastFieldChanged.EnteredAmountIncl);
              setTouchedFields({
                ...touchedFields,
                enteredAmountIncl: true,
              });
              setEnteredAmountIncl(new BigNumber(e.target.value));
              setUseAmountInclForCalc(true);
            }}
            InputProps={{
              startAdornment: (
                <Tooltip
                  title={`Issued by ${props.assetValuationTokenViewModel.issuer}`}
                  placement="top"
                >
                  <Typography
                    id={
                      "marketDirectOrderIssuerBuyCard-amountTextNumField-unitsStartAdornment"
                    }
                    variant="body1"
                    className={classes.textNumFieldCode}
                    children={props.assetValuationTokenViewModel.token.code}
                  />
                </Tooltip>
              ),
            }}
            error={!!requestValidationResult.fieldValidations.enteredAmountIncl}
            helperText={
              requestValidationResult.fieldValidations.enteredAmountIncl
            }
          />

          <div className={classes.sectionWithColumns2Gap}>
            <Typography
              variant="caption"
              className={classes.secondaryText}
              children="Trade Fee:"
            />
            <Amount
              codeTypographyProps={{
                variant: "caption",
                color: "textSecondary",
                className: cx({
                  [classes.secondaryText]: feeCalculationInProgress,
                }),
              }}
              valueTypographyProps={{
                variant: "caption",
                color: "textSecondary",
                className: cx({
                  [classes.secondaryText]: feeCalculationInProgress,
                }),
              }}
              formatTextNumOpts={{
                noDecimalPlaces: 2,
                addDecimalPadding: true,
              }}
              amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                calculateDirectOrderFeeResponse.feeAmount,
              )}
            />
            <Typography
              variant="caption"
              color="secondary"
              className={classes.tradeFeeWhyTheseFeesLink}
              onClick={() => window.open("https://mesh.trade/fees", "_blank")}
              children="Why these fees?"
            />
          </div>
        </Box>

        <div className={cx(classes.sectionWithPadding)}>
          <div className={classes.orderBreakdownControlRow}>
            <IconButton
              data-link-info={JSON.stringify({
                content_interaction_id: "info-link",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "Order Breakdown",
                content_interaction_type: InteractionType.Icon,
                content_interaction_driver: InteractionDriver.MoreInfo,
              } as DataLinkInfoType)}
              size="small"
              onClick={toggleShowOrderBreakDown}
            >
              {showOrderBreakdown ? (
                <ExpandLessIcon
                  data-link-info={JSON.stringify({
                    content_interaction_id: "info-link",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_text: "Order Breakdown",
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_driver: InteractionDriver.MoreInfo,
                  } as DataLinkInfoType)}
                  color="primary"
                />
              ) : (
                <ExpandMoreIcon
                  data-link-info={JSON.stringify({
                    content_interaction_id: "info-link",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_text: "Order Breakdown",
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_driver: InteractionDriver.MoreInfo,
                  } as DataLinkInfoType)}
                  color="primary"
                />
              )}
            </IconButton>
            <Typography variant="h6" children="Order Breakdown" />
          </div>
          <Collapse in={showOrderBreakdown}>
            <div className={classes.orderBreakDownLayout}>
              <Typography
                className={classes.secondaryText}
                variant="caption"
                children="Pay Tokens:"
              />
              <Amount
                reverse
                codeTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                valueTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 7,
                  addDecimalPadding: false,
                }}
                amount={props.listing.token.newAmountOf(
                  calculateDirectOrderFeeResponse.tokens,
                )}
              />

              <Typography
                className={classes.secondaryText}
                variant="caption"
                children="Trade Fee:"
              />
              <Amount
                codeTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                valueTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                  calculateDirectOrderFeeResponse.feeAmount,
                )}
              />

              <Typography
                className={classes.secondaryText}
                variant="caption"
                children="Receive Stablecoin:"
              />
              <Amount
                codeTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                valueTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                  calculateDirectOrderFeeResponse.amountIncl,
                )}
              />
            </div>
          </Collapse>
          {!isMobile && (
            <Box
              sx={{
                width: "100%",
                paddingBottom: 5,
              }}
            >
              {placeOrderButton}
            </Box>
          )}
        </div>
        {isMobile && (
          <Box
            sx={{
              marginTop: "auto",
              position: "sticky",
              bottom: 0,
              backgroundColor: theme.palette.custom.midnight,
              padding: theme.spacing(3, 3, 4, 3),
              height: "104px",
              width: "100%",
              boxShadow: 24,
            }}
          >
            {placeOrderButton}
          </Box>
        )}
      </DialogContent>
    </Root>
  );
}
