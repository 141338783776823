import config from "react-global-configuration";
import { useEffect, useRef, useState } from "react";
import { Context } from "../security";
import { Query } from "../search/query";
import { FundingReceipt } from "./fundingReceipt";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { useApplicationContext } from "../../context/Application/Application";
import { useErrorContext } from "context/Error";

export interface SearchFundingReceiptsRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchFundingReceiptsResponse {
  records: FundingReceipt[];
  total: number;
}

export const FundingReceiptsRepository = {
  serviceProvider: "banking-FundingReceiptRepository",
  async SearchFundingReceipts(
    request: SearchFundingReceiptsRequest,
  ): Promise<SearchFundingReceiptsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${FundingReceiptsRepository.serviceProvider}.SearchFundingReceipts`,
      request,
    });
    return {
      records: response.records.map(
        (f: FundingReceipt) => new FundingReceipt(f),
      ),
      total: response.total,
    };
  },
};

export function useSearchFundingReceipts(
  initialSearchInitialFundingReceiptsRequests?: SearchFundingReceiptsRequest,
) {
  const { authContext } = useApplicationContext();
  const [searchFundingReceiptsRequest, setSearchFundingReceiptsRequest] =
    useState<SearchFundingReceiptsRequest>(
      initialSearchInitialFundingReceiptsRequests || {
        context: authContext,
        query: new Query(),
        criteria: {},
      },
    );
  const [searchFundingReceiptsResponse, setSearchFundingReceiptsResponse] =
    useState<SearchFundingReceiptsResponse>({
      total: 0,
      records: [],
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setSearchFundingReceiptsResponse(
          await FundingReceiptsRepository.SearchFundingReceipts(
            searchFundingReceiptsRequest,
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching funding receipts: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching funding receipts: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchFundingReceiptsRequest]);

  return {
    searchFundingReceiptsRequest,
    setSearchFundingReceiptsRequest,
    searchFundingReceiptsResponse,
    loading,
    error,
  };
}
