import dayjs from "dayjs";
import { AuditEntry } from "../audit";
import { Amount } from "james/ledger";
import BigNumber from "bignumber.js";

export const CouponPaymentTypeName = "mesh::financial/CouponPayment";

export enum CouponPaymentState {
  Draft = "Draft",
  Deleted = "Deleted",
  Fixed = "Fixed",
  FundingInProgress = "Funding in Progress",
  FundingUnderInvestigation = "Funding Under Investigation",
  FundingFailed = "Funding Failed",
  Funded = "Funded",
  PaymentInProgress = "Payment in Progress",
  PaymentFailed = "Payment Failed",
  Paid = "Paid",
  CleanUpInProgress = "Clean Up in Progress",
  CleanUpUnderInvestigation = "Clean Up Under Investigation",
  CleanUpFailed = "Clean Up Failed",
  Complete = "Complete",
}

export const AllCouponPaymentStates: CouponPaymentState[] = [
  CouponPaymentState.Draft,
  CouponPaymentState.Deleted,
  CouponPaymentState.Fixed,
  CouponPaymentState.FundingInProgress,
  CouponPaymentState.FundingUnderInvestigation,
  CouponPaymentState.FundingFailed,
  CouponPaymentState.Funded,
  CouponPaymentState.PaymentInProgress,
  CouponPaymentState.PaymentFailed,
  CouponPaymentState.Paid,
  CouponPaymentState.CleanUpInProgress,
  CouponPaymentState.CleanUpUnderInvestigation,
  CouponPaymentState.CleanUpFailed,
  CouponPaymentState.Complete,
];

export class CouponPayment {
  public ["@type"]: string = CouponPaymentTypeName;

  public id = "";
  public number = "";
  public auditEntry: AuditEntry = new AuditEntry();
  public holdingAccountLedgerID = "";
  public ownerID = "";
  public bondID = "";
  public state: CouponPaymentState | "" = "";
  public stateResolutionCount = 0;
  public feeAmount: Amount = new Amount();

  // --------------- CouponPaymentStub fields ----------------------
  public sequenceNumber = 0;
  public paymentDate: string = dayjs().format();
  public accrualStartDate: string = dayjs().format();
  public accrualEndDate: string = dayjs().format();
  public referencePeriodStartDate: string = dayjs().format();
  public referencePeriodEndDate: string = dayjs().format();
  public fixDate: string = dayjs().format();
  public exCouponDate: string = dayjs().format();
  public recordDate: string = dayjs().format();
  public nominalAmount: Amount = new Amount();
  public interestAmount: Amount = new Amount();
  public rate: BigNumber = new BigNumber("0");

  constructor(couponPayment?: CouponPayment) {
    if (!couponPayment) {
      return;
    }
    this.id = couponPayment.id;
    this.number = couponPayment.number;
    this.auditEntry = new AuditEntry(couponPayment.auditEntry);
    this.holdingAccountLedgerID = couponPayment.holdingAccountLedgerID;
    this.ownerID = couponPayment.ownerID;
    this.bondID = couponPayment.bondID;
    this.state = couponPayment.state;
    this.stateResolutionCount = couponPayment.stateResolutionCount;
    this.feeAmount = new Amount(couponPayment.feeAmount);

    // --------------- CouponPaymentStub fields ----------------------
    this.sequenceNumber = couponPayment.sequenceNumber;
    this.paymentDate = couponPayment.paymentDate;
    this.accrualStartDate = couponPayment.accrualStartDate;
    this.accrualEndDate = couponPayment.accrualEndDate;
    this.referencePeriodStartDate = couponPayment.referencePeriodStartDate;
    this.referencePeriodEndDate = couponPayment.referencePeriodEndDate;
    this.fixDate = couponPayment.fixDate;
    this.exCouponDate = couponPayment.exCouponDate;
    this.recordDate = couponPayment.recordDate;
    this.nominalAmount = new Amount(couponPayment.nominalAmount);
    this.interestAmount = new Amount(couponPayment.interestAmount);
    this.rate = new BigNumber(couponPayment.rate);
  }
}
