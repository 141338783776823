import {
  DigitalInstrumentTokenMintingFee,
  DigitalInstrumentTokenMintingFeeTypeName,
} from "./DigitalInstrumentTokenMintingFee";
import {
  DigitalInstrumentFirstTimeMintingFee,
  DigitalInstrumentFirstTimeMintingFeeTypeName,
} from "./DigitalInstrumentFirstTimeMintingFee";
import {
  DigitalInstrumentListingFee,
  DigitalInstrumentListingFeeTypeName,
} from "./DigitalInstrumentListingFee";
import {
  InstrumentStablecoinTokenMintingFee,
  InstrumentStablecoinTokenMintingFeeTypeName,
} from "./InstrumentStablecoinTokenMintingFee";
import {
  InstrumentStablecoinFirstTimeMintingFee,
  InstrumentStablecoinFirstTimeMintingFeeTypeName,
} from "./InstrumentStablecoinFirstTimeMintingFee";
import {
  InstrumentStablecoinListingFee,
  InstrumentStablecoinListingFeeTypeName,
} from "./InstrumentStablecoinListingFee";
import { TransferFee, TransferFeeTypeName } from "./TransferFee";
import { Amount } from "../ledger/Amount";
import {
  InstrumentStablecoinTokenBurningFee,
  InstrumentStablecoinTokenBurningFeeTypeName,
} from "./InstrumentStablecoinTokenBurningFee";
import {
  DigitalInstrumentTokenBurningFee,
  DigitalInstrumentTokenBurningFeeTypeName,
} from "./DigitalInstrumentTokenBurningFee";
import {
  Instrument1stPlacementTradeFee,
  Instrument1stPlacementTradeFeeTypeName,
} from "./Instrument1stPlacementTradeFee";
import {
  InstrumentCreationFee,
  InstrumentCreationFeeTypeName,
} from "./InstrumentCreationFee";
import {
  InstrumentUtilisationOfStandardLegalAgreementFee,
  InstrumentUtilisationOfStandardLegalAgreementFeeTypeName,
} from "./InstrumentUtilisationOfStandardLegalAgreementFee";
import {
  InstrumentAssetsUnderManagementFee,
  InstrumentAssetsUnderManagementFeeTypeName,
} from "./InstrumentAssetsUnderManagementFee";

export enum FeeState {
  Pending = "Pending",
  Failed = "Failed",
  UnderInvestigation = "Under Investigation",
  Settled = "Settled",
}

export interface Fee {
  ["@type"]: string;

  feeID(): string;

  feeResponsibleGroupID(): string;

  feeName(): string;

  feeAmount(): Amount;

  feeTransactionID(): string;

  feeState(): FeeState;
}

export function NewFee(f: Fee): Fee {
  switch (f["@type"]) {
    case DigitalInstrumentFirstTimeMintingFeeTypeName:
      return new DigitalInstrumentFirstTimeMintingFee(
        f as DigitalInstrumentFirstTimeMintingFee,
      );

    case DigitalInstrumentTokenMintingFeeTypeName:
      return new DigitalInstrumentTokenMintingFee(
        f as DigitalInstrumentTokenMintingFee,
      );

    case DigitalInstrumentListingFeeTypeName:
      return new DigitalInstrumentListingFee(f as DigitalInstrumentListingFee);

    case InstrumentStablecoinFirstTimeMintingFeeTypeName:
      return new InstrumentStablecoinFirstTimeMintingFee(
        f as InstrumentStablecoinFirstTimeMintingFee,
      );

    case InstrumentStablecoinTokenMintingFeeTypeName:
      return new InstrumentStablecoinTokenMintingFee(
        f as InstrumentStablecoinTokenMintingFee,
      );

    case InstrumentStablecoinListingFeeTypeName:
      return new InstrumentStablecoinListingFee(
        f as InstrumentStablecoinListingFee,
      );

    case TransferFeeTypeName:
      return new TransferFee(f as TransferFee);

    case InstrumentStablecoinTokenBurningFeeTypeName:
      return new InstrumentStablecoinTokenBurningFee(
        f as InstrumentStablecoinTokenBurningFee,
      );

    case DigitalInstrumentTokenBurningFeeTypeName:
      return new DigitalInstrumentTokenBurningFee(
        f as DigitalInstrumentTokenBurningFee,
      );

    case Instrument1stPlacementTradeFeeTypeName:
      return new Instrument1stPlacementTradeFee(
        f as Instrument1stPlacementTradeFee,
      );

    case InstrumentCreationFeeTypeName:
      return new InstrumentCreationFee(f as InstrumentCreationFee);

    case InstrumentUtilisationOfStandardLegalAgreementFeeTypeName:
      return new InstrumentUtilisationOfStandardLegalAgreementFee(
        f as InstrumentUtilisationOfStandardLegalAgreementFee,
      );

    case InstrumentAssetsUnderManagementFeeTypeName:
      return new InstrumentAssetsUnderManagementFee(
        f as InstrumentAssetsUnderManagementFee,
      );

    default:
      throw new TypeError(`unknown fee type ${f["@type"]}`);
  }
}
