import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Edit as EditIcon, Info as InfoIcon } from "@mui/icons-material";
import { CreateOrEditGroupDialog } from "components/CreateOrEditGroupDialog/CreateOrEditGroupDialog";
import { Group, GroupRepository } from "james/group";
import { IDIdentifier } from "james/search/identifier";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { BPTable } from "components/Table";
import { useSearchRoles } from "james/role/Repository";
import { TextExactCriterion } from "james/search/criterion";
import { Query } from "james/search/query";
import { Role } from "james/role";
import cx from "classnames";
import { UsersTable } from "./UsersTable";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";

const PREFIX = "Explore";

const classes = {
  loadingLayout: `${PREFIX}-loadingLayout`,
  layout: `${PREFIX}-layout`,
  tabs: `${PREFIX}-tabs`,
  breadCrumbLayout: `${PREFIX}-breadCrumbLayout`,
  clickableBreadcrumb: `${PREFIX}-clickableBreadcrumb`,
  nonClickableBreadcrumb: `${PREFIX}-nonClickableBreadcrumb`,
  groupOverviewTitleRow: `${PREFIX}-groupOverviewTitleRow`,
  groupOverviewCardRoot: `${PREFIX}-groupOverviewCardRoot`,
  groupOverviewCardContent: `${PREFIX}-groupOverviewCardContent`,
  rolesLayout: `${PREFIX}-rolesLayout`,
  rolesLayoutPermissionCard: `${PREFIX}-rolesLayoutPermissionCard`,
  rolesLayoutPermissionCardVisible: `${PREFIX}-rolesLayoutPermissionCardVisible`,
  rolesLayoutPermissionCardNotVisible: `${PREFIX}-rolesLayoutPermissionCardNotVisible`,
  rolesLayoutPermissionCardTableRow: `${PREFIX}-rolesLayoutPermissionCardTableRow`,
  rolesLayoutPermissionCardTableRowWrapper: `${PREFIX}-rolesLayoutPermissionCardTableRowWrapper`,
  permissionInfoIcon: `${PREFIX}-permissionInfoIcon`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.loadingLayout}`]: {
    height: "calc(100vh - 150px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.layout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "repeat(3, auto)",
    gridRowGap: theme.spacing(1.5),
  },

  [`& .${classes.tabs}`]: {
    paddingLeft: theme.spacing(2),
  },

  //
  // bread crumbs
  //
  [`& .${classes.breadCrumbLayout}`]: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.clickableBreadcrumb}`]: {
    cursor: "pointer",
    color: theme.palette.text.hint,
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
    marginRight: theme.spacing(0.5),
  },

  [`& .${classes.nonClickableBreadcrumb}`]: {
    marginRight: theme.spacing(0.5),
  },

  //
  // group overview
  //
  [`& .${classes.groupOverviewTitleRow}`]: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "1fr repeat(3, auto)",
    gridColumnGap: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.groupOverviewCardRoot}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.groupOverviewCardContent}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(3, auto)",
    gridRowGap: theme.spacing(1),
  },

  //
  // roles
  //
  [`& .${classes.rolesLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
  },

  [`& .${classes.rolesLayoutPermissionCard}`]: {},

  [`& .${classes.rolesLayoutPermissionCardVisible}`]: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: 0.35 * window.innerWidth,
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.rolesLayoutPermissionCardNotVisible}`]: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
  },

  [`& .${classes.rolesLayoutPermissionCardTableRow}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 0),
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: theme.spacing(1),
    alignItems: "center",
  },

  [`& .${classes.rolesLayoutPermissionCardTableRowWrapper}`]: {
    maxHeight: window.innerHeight - 480,
    overflowX: "hidden",
    overflowY: "auto",
  },

  [`& .${classes.permissionInfoIcon}`]: {
    cursor: "pointer",
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
  },
}));

enum GroupTabs {
  UsersTab = "Users",
  RolesTab = "Roles",
}

const initialQuery = new Query({
  limit: 10,
  offset: 0,
  sorting: [],
});

export function Explore() {
  const { errorContextErrorTranslator } = useErrorContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [showGroupEdit, setShowGroupsEdit] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { authContext } = useApplicationContext();

  const [group, setGroup] = useState<Group>(new Group());
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading: searchRolesLoading,
    searchRolesResponse,
    searchRolesRequest,
    setSearchRolesRequest,
  } = useSearchRoles(undefined, !group.id);
  const [selectedTab, setSelectedTab] = useState(GroupTabs.UsersTab);
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  // on screen load
  useEffect(() => {
    (async () => {
      // try and get a group ID from the url
      const groupIDFromURL = searchParams.get("id");

      if (groupIDFromURL === null) {
        // if no group ID is given then navigate back to the group table
        navigate("/administration/manage/groups/table");
        return;
      }
      if (groupIDFromURL === group.id && !refresh) {
        // if the group ID in the url is the same as the group already
        // stored then do nothing
        // but only if a refresh is not requested
        return;
      }
      setRefresh(false);

      setLoading(true);

      // filter role search by owner
      setSearchRolesRequest({
        context: authContext,
        criteria: {
          ownerID: TextExactCriterion(groupIDFromURL),
        },
        query: new Query(initialQuery),
      });

      // otherwise retrieve the group
      let retrievedGroup: Group;
      try {
        retrievedGroup = (
          await GroupRepository.RetrieveGroup({
            context: authContext,
            identifier: IDIdentifier(groupIDFromURL),
          })
        ).group;
        setGroup(retrievedGroup);
      } catch (e) {
        navigate("/administration/manage/groups/table");
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving group: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `Error Retrieving Group: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
        return;
      }

      setLoading(false);
    })();
  }, [
    setSearchRolesRequest,
    authContext,
    enqueueSnackbar,
    group,
    history,
    refresh,
  ]);

  if (loading) {
    return (
      <Root>
        {/* Bread Crumbs */}
        <div className={classes.breadCrumbLayout}>
          <Typography
            variant="caption"
            className={classes.clickableBreadcrumb}
            onClick={() => navigate("/administration/manage/groups/table")}
          >
            Groups
          </Typography>
          <Typography
            variant="caption"
            className={classes.nonClickableBreadcrumb}
          >
            {group.name}
          </Typography>
        </div>
        <div className={classes.loadingLayout}>
          <CircularProgress size={50} />
        </div>
      </Root>
    );
  }

  return (
    <Root>
      {/* Bread Crumbs */}
      <div className={classes.breadCrumbLayout}>
        <Typography
          variant="caption"
          className={classes.clickableBreadcrumb}
          onClick={() => navigate("/administration/manage/groups/table")}
        >
          Groups
        </Typography>
        <Typography
          variant="caption"
          className={classes.nonClickableBreadcrumb}
        >
          {group.name}
        </Typography>
      </div>
      <div className={classes.layout}>
        <div>
          <div className={classes.groupOverviewTitleRow}>
            <Typography variant="subtitle1" children="Group Overview" />
            <IconButton
              id="exploreGroups-edit-button"
              size="small"
              onClick={() => setShowGroupsEdit(true)}
            >
              <EditIcon />
            </IconButton>
          </div>
          <Card className={classes.groupOverviewCardRoot}>
            <CardContent className={classes.groupOverviewCardContent}>
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  children="Name"
                />
                <Typography variant="h5" children={group.name} />
              </div>
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  children="Classification"
                />
                <Typography variant="h5" children={group.classification} />
              </div>
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  children="Description"
                />
                <Typography
                  variant="h5"
                  children={group.description ? group.description : "-"}
                />
              </div>
            </CardContent>
          </Card>
        </div>

        <Paper square>
          <Grid container>
            <Grid item>
              <Tabs
                className={classes.tabs}
                value={selectedTab}
                textColor={"inherit"}
                onChange={(_, newTab) => setSelectedTab(newTab)}
              >
                <Tab value={GroupTabs.UsersTab} label={GroupTabs.UsersTab} />
                <Tab value={GroupTabs.RolesTab} label={GroupTabs.RolesTab} />
              </Tabs>
            </Grid>
          </Grid>
        </Paper>

        {(() => {
          switch (selectedTab) {
            case GroupTabs.UsersTab:
              return (
                <UsersTable
                  groupID={group.id}
                  groupRoles={searchRolesResponse.records}
                />
              );

            case GroupTabs.RolesTab:
              return (
                <div className={classes.rolesLayout}>
                  <BPTable
                    height={window.innerHeight - 370 - noticeBannerHeight}
                    loading={searchRolesLoading}
                    title="Group Roles"
                    data={searchRolesResponse.records}
                    totalNoRecords={searchRolesResponse.total}
                    onSelectedDataChange={(allSelectedData) =>
                      setSelectedRoles(allSelectedData as Role[])
                    }
                    columns={[
                      {
                        field: "name",
                        label: "Name",
                      },
                    ]}
                    query={searchRolesRequest.query}
                    onQueryChange={(query) =>
                      setSearchRolesRequest({
                        ...searchRolesRequest,
                        query,
                      })
                    }
                  />

                  <div
                    className={cx(classes.rolesLayoutPermissionCard, {
                      [classes.rolesLayoutPermissionCardNotVisible]:
                        !selectedRoles.length,
                      [classes.rolesLayoutPermissionCardVisible]:
                        !!selectedRoles.length,
                    })}
                  >
                    {!!selectedRoles.length && (
                      <Card>
                        <CardHeader
                          title={
                            selectedRoles.length > 1
                              ? "Permissions in Selected Roles"
                              : selectedRoles[0].name
                          }
                        />
                        <CardContent>
                          <Typography
                            className={
                              classes.rolesLayoutPermissionCardTableRow
                            }
                            children="Service"
                            variant="h6"
                          />
                          <div
                            className={cx(
                              classes.rolesLayoutPermissionCardTableRowWrapper,
                              "meshScroll",
                            )}
                          >
                            {selectedRoles
                              .map((r) => r.permissions)
                              .reduce((allPermissions, permissions) => [
                                ...allPermissions,
                                ...permissions,
                              ])
                              .map((perm, idx) => (
                                <div
                                  key={idx}
                                  className={
                                    classes.rolesLayoutPermissionCardTableRow
                                  }
                                >
                                  <Typography
                                    children={`${perm.serviceProvider}.${perm.serviceName}`}
                                    variant="body2"
                                  />
                                  <Tooltip
                                    title={perm.description}
                                    placement="top"
                                  >
                                    <Icon
                                      className={classes.permissionInfoIcon}
                                    >
                                      <InfoIcon />
                                    </Icon>
                                  </Tooltip>
                                </div>
                              ))}
                          </div>
                        </CardContent>
                      </Card>
                    )}
                  </div>
                </div>
              );
          }
        })()}
      </div>

      {showGroupEdit && (
        <CreateOrEditGroupDialog
          closeDialog={() => setShowGroupsEdit(!showGroupEdit)}
          onUpdateGroup={() => setRefresh(true)}
          group={group}
        />
      )}
    </Root>
  );
}
