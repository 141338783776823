import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  allCalendars,
  AllInstrumentRiskProfiles,
  AllInvestorProfiles,
  calendarToString,
  DigitalFixedRateBond,
  DigitalFixedRateBondStateController,
  DigitalFixedRateBondUpdater,
  InstrumentRiskProfile,
  InvestorProfile,
} from "../../../../../james/financial";
import { FormData, FormMode } from "../index";
import {
  DateField,
  TextField,
  TextNumField,
} from "../../../../../components/FormFields";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useValidatedForm } from "hooks/useForm";
import {
  formDataUpdaterSpecs,
  formDataValidationFunc,
} from "./useValidatedForm";
import { TokenIconViewUpload } from "../../../../../components/Ledger/Token";
import {
  allCouponFrequencies,
  CouponFrequency,
} from "../../../../../james/financial/CouponFrequency";
import {
  allDayCountConventions,
  DayCountConvention,
} from "../../../../../james/financial/DayCountConvention";
import {
  allBusinessDayConventions,
  BusinessDayConvention,
} from "../../../../../james/financial/BusinessDayConvention";
import { Info as InfoIcon } from "@mui/icons-material";
import { BondState } from "../../../../../james/financial/Bond";
import {
  allDebtSeniorityLevels,
  DebtSeniorityLevel,
} from "../../../../../james/financial/DebtSeniorityLevel";
import { TextArea } from "../TextArea/TextArea";
import { useApplicationContext } from "../../../../../context/Application/Application";
import { useSnackbar } from "notistack";
import { useErrorContext } from "../../../../../context/Error";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Token } from "../../../../../james/ledger";
import isEqual from "lodash/isEqual";
import { InstrumentStateChip } from "../../../Instruments/Chips";
import { Documents } from "../Documents";
import { Document } from "../../../../../james/document";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import cx from "classnames";

export type Props = {
  setAPILoading: (newValue: boolean) => void;
  apiLoading: boolean;
  formData: FormData;
  digitalFixedRateBond: DigitalFixedRateBond;
  onDigitalFixedRateBondChange: (
    digitalFixedRateBond: DigitalFixedRateBond,
  ) => void;
};

export const DigitalFixedRateBondForm = (props: Props) => {
  const { authContext, viewConfiguration } = useApplicationContext();
  const { errorContextDefaultWarningFeedback } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [
    formData,
    formDataValidationResult,
    formDataUpdate,
    formDataValidationInProgress,
  ] = useValidatedForm(
    formDataValidationFunc,
    async () => {
      if (
        dayjs(props.digitalFixedRateBond.firstCouponPaymentDate).isSame(
          dayjs("0001-01-01T00:00:00Z"),
        )
      ) {
        props.digitalFixedRateBond.firstCouponPaymentDate = null;
      }
      if (
        dayjs(props.digitalFixedRateBond.nextToLastCouponPaymentDate).isSame(
          dayjs("0001-01-01T00:00:00Z"),
        )
      ) {
        props.digitalFixedRateBond.nextToLastCouponPaymentDate = null;
      }
      if (
        dayjs(props.digitalFixedRateBond.couponInterestCommencementDate).isSame(
          dayjs("0001-01-01T00:00:00Z"),
        )
      ) {
        props.digitalFixedRateBond.couponInterestCommencementDate = null;
      }
      return {
        generalFormData: props.formData,
        digitalFixedRateBond: new DigitalFixedRateBond(
          props.digitalFixedRateBond,
        ),
      };
    },
    formDataUpdaterSpecs,
    {
      generalFormData: props.formData,
      digitalFixedRateBond: new DigitalFixedRateBond(
        props.digitalFixedRateBond,
      ),
    },
    new Set<string>([]),
  );

  useEffect(() => {
    formDataUpdate.digitalFixedRateBond(
      new DigitalFixedRateBond(props.digitalFixedRateBond),
    );
  }, [props.digitalFixedRateBond]);

  const unsavedChangesExist = !isEqual(
    formData.digitalFixedRateBond,
    props.digitalFixedRateBond,
  );
  const creatingNewInstrument = formData.digitalFixedRateBond.id === "";

  useEffect(() => {
    const formMode = searchParams.get("mode");
    if (!formMode) {
      const updatedSearchParams = new URLSearchParams(searchParams);
      updatedSearchParams.set(
        "mode",
        formData.digitalFixedRateBond.id === "" ? FormMode.Edit : FormMode.View,
      );
      setSearchParams(updatedSearchParams);
    }
  }, [formData.digitalFixedRateBond]);
  const readOnly = !((searchParams.get("mode") as FormMode) === FormMode.Edit);

  const draftState = formData.digitalFixedRateBond.state === BondState.Draft;

  // handlePerformRequiredUpdates performs any updates required on the DigitalFixedRateBond.
  // i.e. either creation, draft update or discrete update
  // The updated DigitalFixedRateBond is returned.
  const handlePerformRequiredUpdates: () => Promise<DigitalFixedRateBond> =
    async () => {
      // if creation is in progress
      if (creatingNewInstrument) {
        // then create the new digital fixed rate bond
        return (
          await DigitalFixedRateBondStateController.CreateDraftDigitalFixedRateBond(
            {
              context: authContext,
              ownerID: formData.digitalFixedRateBond.ownerID,
              isin: formData.digitalFixedRateBond.isin,
              name: formData.digitalFixedRateBond.name,
              fractionalisationAllowed:
                formData.digitalFixedRateBond.fractionalisationAllowed,
              investorProfile: formData.digitalFixedRateBond.investorProfile,
              investorProfileDescription:
                formData.digitalFixedRateBond.investorProfileDescription,
              riskProfile: formData.digitalFixedRateBond.riskProfile,
              riskProfileDescription:
                formData.digitalFixedRateBond.riskProfileDescription,
              debtSeniorityLevel:
                formData.digitalFixedRateBond.debtSeniorityLevel,
              covenants: formData.digitalFixedRateBond.covenants,
              votingRights: formData.digitalFixedRateBond.votingRights,
              votingRightsDescription:
                formData.digitalFixedRateBond.votingRightsDescription,
              ownershipLimitations:
                formData.digitalFixedRateBond.ownershipLimitations,
              issuePrice: formData.digitalFixedRateBond.issuePrice,
              nominal: formData.digitalFixedRateBond.nominal,
              totalNominal: formData.digitalFixedRateBond.totalNominal,
              redemption: formData.digitalFixedRateBond.redemption,
              issueDate: formData.digitalFixedRateBond.issueDate,
              maturityDate: formData.digitalFixedRateBond.maturityDate,
              calendar: formData.digitalFixedRateBond.calendar,
              couponPaymentFrequency:
                formData.digitalFixedRateBond.couponPaymentFrequency,
              firstCouponPaymentDate:
                formData.digitalFixedRateBond.firstCouponPaymentDate,
              nextToLastCouponPaymentDate:
                formData.digitalFixedRateBond.nextToLastCouponPaymentDate,
              couponInterestCommencementDate:
                formData.digitalFixedRateBond.couponInterestCommencementDate,
              dayCountConvention:
                formData.digitalFixedRateBond.dayCountConvention,
              endOfMonthConvention:
                formData.digitalFixedRateBond.endOfMonthConvention,
              businessDayConvention:
                formData.digitalFixedRateBond.businessDayConvention,
              terminationDateBusinessDayConvention:
                formData.digitalFixedRateBond
                  .terminationDateBusinessDayConvention,
              dateGenerationRule:
                formData.digitalFixedRateBond.dateGenerationRule,
              fixDays: formData.digitalFixedRateBond.fixDays,
              exCouponDays: formData.digitalFixedRateBond.exCouponDays,
              recordDays: formData.digitalFixedRateBond.recordDays,
              couponRate: formData.digitalFixedRateBond.couponRate,
            },
          )
        ).digitalFixedRateBond;
      }

      // Otherwise the instrument already exists
      if (formData.digitalFixedRateBond.state === BondState.Draft) {
        // if the instrument is in draft then perform a draft update
        return unsavedChangesExist
          ? (
              await DigitalFixedRateBondUpdater.DraftUpdateDigitalFixedRateBond(
                {
                  context: authContext,
                  digitalFixedRateBond: formData.digitalFixedRateBond,
                },
              )
            ).digitalFixedRateBond
          : formData.digitalFixedRateBond;
      }

      return formData.digitalFixedRateBond;
    };

  const handleUpdateSupportingDocs: (
    doc: Document,
  ) => Promise<DigitalFixedRateBond> = async (doc: Document) => {
    if (creatingNewInstrument) return formData.digitalFixedRateBond;
    if (formData.digitalFixedRateBond.state === BondState.Draft) {
      return (
        await DigitalFixedRateBondUpdater.AddDigitalFixedRateBondSupportingDocument(
          {
            context: authContext,
            digitalFixedRateBondID: formData.digitalFixedRateBond.assetID(),
            supportingDocument: doc,
          },
        )
      ).digitalFixedRateBond;
    }

    return formData.digitalFixedRateBond;
  };

  return (
    <>
      <CardContent
        sx={{
          p: 3,
          px: 4,
          pb: 5,
        }}
      >
        <Box>
          <Box
            className={cx({
              readOnly: readOnly || !draftState,
            })}
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "1fr auto",
              columnGap: theme.spacing(2),
              "&.readOnly": {
                mb: 2,
                borderBottom: `${theme.palette.divider} 1px solid`,
              },
            })}
          >
            {!readOnly ? (
              <TextField
                id={"digitalFixedRateBondForm-header-instrumentName-textField"}
                value={formData.digitalFixedRateBond.name}
                placeholder={"Enter a Fixed Rate Bond Name"}
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                onChange={(e) => {
                  formDataUpdate.name(e.target.value);
                }}
                sx={{
                  fontSize: 20, // h4 font size
                  fontWeight: 600, // Bold font
                  letterSpacing: 0,
                  maxWidth: "100%",
                  width: "100%",
                  marginBottom: "1rem", // Space below the heading,
                  "&::placeholder": {
                    color: "rgba(0, 0, 0, 0.54)", // Placeholder color
                    fontWeight: 600,
                    fontSize: "1.2rem", // Placeholder font size to match h4
                  },
                }}
                InputProps={{
                  sx: {
                    fontSize: "1.2rem", // h4 font size
                    fontWeight: 600, // Bold font
                    letterSpacing: 0,
                  },
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <InstrumentStateChip
                        state={formData.digitalFixedRateBond.state}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  pb: 2,
                }}
              >
                <Typography
                  variant="h4"
                  sx={(theme) => ({
                    fontSize: 20, // h4 font size
                    fontWeight: theme.typography.fontWeightBold, // Bold font
                    letterSpacing: 0,
                  })}
                >
                  {formData.digitalFixedRateBond.name}
                </Typography>
                <InstrumentStateChip
                  state={formData.digitalFixedRateBond.state}
                />
              </Box>
            )}
            <Box
              className={cx({
                readOnly: readOnly || !draftState,
              })}
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: theme.spacing(1),
                ml: "auto",
                mb: 1,
                "&.readOnly": {
                  mb: 2,
                },
              })}
            >
              {(() => {
                const buttons: React.ReactNode[] = [];

                if (draftState) {
                  if (readOnly) {
                    if (
                      viewConfiguration?.Instruments
                        ?.DigitalFixedRateBondActions?.Update
                    ) {
                      // if in view mode then show the edit button to transition to edit mode
                      buttons.push(
                        <IconButton
                          disabled={
                            props.apiLoading || formDataValidationInProgress
                          }
                          onClick={() => {
                            const newSearchParams = new URLSearchParams(
                              searchParams,
                            );
                            newSearchParams.set("mode", FormMode.Edit);
                            setSearchParams(newSearchParams);
                          }}
                        >
                          <EditIcon />
                        </IconButton>,
                      );
                    }
                  } else {
                    // otherwise the save button should always be shown to exit edit mode
                    if (
                      viewConfiguration?.Instruments
                        ?.DigitalFixedRateBondActions?.CreateDraft &&
                      viewConfiguration?.Instruments
                        ?.DigitalFixedRateBondActions?.Update
                    ) {
                      buttons.push(
                        <Button
                          variant={"outlined"}
                          disabled={
                            props.apiLoading || formDataValidationInProgress
                          }
                          onClick={async () => {
                            props.setAPILoading(true);

                            // if unsaved changes exist then perform any required updates
                            let updatedDigitalFixedRateBond =
                              formData.digitalFixedRateBond;
                            if (creatingNewInstrument || unsavedChangesExist) {
                              try {
                                updatedDigitalFixedRateBond =
                                  await handlePerformRequiredUpdates();
                                enqueueSnackbar("Instrument Saved", {
                                  variant: "success",
                                });
                              } catch (e) {
                                console.error("error saving instrument", e);
                                errorContextDefaultWarningFeedback(
                                  e,
                                  "Could Not Save Instrument",
                                );
                                props.setAPILoading(false);
                                return;
                              }
                            }

                            // stop loading and turn view mode on
                            props.setAPILoading(false);
                            formDataUpdate.digitalFixedRateBond(
                              updatedDigitalFixedRateBond,
                            );
                            navigate(
                              `${window.location.pathname}?id=${updatedDigitalFixedRateBond.id}&mode=${FormMode.View}`,
                            );
                            props.onDigitalFixedRateBondChange(
                              new DigitalFixedRateBond(
                                updatedDigitalFixedRateBond,
                              ),
                            );
                          }}
                        >
                          Save
                        </Button>,
                      );
                    }
                  }
                }

                // if the asset is in draft mode then show Pre-Issue button
                if (formData.digitalFixedRateBond.state === BondState.Draft) {
                  if (
                    viewConfiguration?.Instruments?.DigitalFixedRateBondActions
                      ?.PreIssue
                  ) {
                    buttons.push(
                      <Button
                        disabled={
                          props.apiLoading || formDataValidationInProgress
                        }
                        color={"primary"}
                        variant={"contained"}
                        onClick={async () => {
                          props.setAPILoading(true);

                          let updatedDigitalFixedRateBond =
                            formData.digitalFixedRateBond;
                          try {
                            // perform any required updates
                            updatedDigitalFixedRateBond =
                              await handlePerformRequiredUpdates();

                            // pre-issue
                            updatedDigitalFixedRateBond = (
                              await DigitalFixedRateBondStateController.PreIssueDigitalFixedRateBond(
                                {
                                  context: authContext,
                                  digitalFixedRateBondID:
                                    updatedDigitalFixedRateBond.id,
                                },
                              )
                            ).digitalFixedRateBond;

                            enqueueSnackbar("Instrument Pre-Issued", {
                              variant: "success",
                            });
                          } catch (e) {
                            console.error("error saving instrument", e);
                            errorContextDefaultWarningFeedback(
                              e,
                              "Could Not Pre-Issue Instrument",
                            );
                            props.setAPILoading(false);
                            return;
                          }

                          // stop loading and turn view mode on
                          props.setAPILoading(false);
                          formDataUpdate.digitalFixedRateBond(
                            updatedDigitalFixedRateBond,
                          );
                          navigate(
                            `${window.location.pathname}?id=${updatedDigitalFixedRateBond.id}&mode=${FormMode.View}`,
                          );
                          props.onDigitalFixedRateBondChange(
                            new DigitalFixedRateBond(
                              updatedDigitalFixedRateBond,
                            ),
                          );
                        }}
                      >
                        Pre-Issue
                      </Button>,
                    );
                  }
                }

                return buttons;
              })().map((b, idx) => (
                <React.Fragment key={idx}>{b}</React.Fragment>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            columnGap: 2,
            rowGap: 2,
            "& > *": {
              mt: 0.5,
            },
          }}
        >
          <>
            <TextField
              readOnly={readOnly || !draftState}
              disabled={props.apiLoading}
              SelectProps={{
                IconComponent: readOnly || !draftState ? () => null : undefined,
              }}
              id={"digitalFixedRateBondForm-header-ownerGroup-selectField"}
              label="Owner Group"
              select
              value={formData.digitalFixedRateBond.ownerID}
            >
              {props.formData.groupOwners.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </TextField>
          </>

          <>
            <TextField
              readOnly={readOnly || !draftState}
              disabled={props.apiLoading}
              id={"digitalFixedRateBondForm-isin-textField"}
              label="ISIN"
              value={
                readOnly || !draftState
                  ? formData.digitalFixedRateBond.isin === ""
                    ? "-"
                    : formData.digitalFixedRateBond.isin
                  : formData.digitalFixedRateBond.isin
              }
              onChange={(e) => formDataUpdate.isin(e.target.value)}
              helperText={formDataValidationResult.fieldValidations.isin}
              error={!!formDataValidationResult.fieldValidations.isin}
            />
            {formData.digitalFixedRateBond.state !== BondState.Draft && (
              <>
                <TextField
                  readOnly
                  disabled={props.apiLoading}
                  id={"digitalFixedRateBondForm-shortName-textField"}
                  label="Short Name"
                  value={formData.digitalFixedRateBond.shortName}
                />
                <TextField
                  readOnly
                  disabled={props.apiLoading}
                  id={"digitalFixedRateBondForm-token-textField"}
                  label="Token"
                  value={formData.digitalFixedRateBond.token.code}
                />
              </>
            )}
          </>
        </Box>
      </CardContent>

      {/* --------------- Bond Information -----------------------*/}
      <Accordion defaultExpanded>
        <AccordionSummary
          sx={(theme) => ({
            pl: 4,
            flexDirection: "row-reverse",
            backgroundColor: theme.palette.custom.midnight,
          })}
          expandIcon={
            <ExpandMoreIcon
              id="digitalFixedRateBondForm-bondInformation-accordionToggleIconButton"
              color="primary"
            />
          }
        >
          <Typography sx={{ ml: 4 }} variant={"h6"}>
            Bond Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            columnGap: theme.spacing(3),
            p: 0,
          })}
        >
          {/* ----------- Payment Schedule ----------------*/}
          <Box
            sx={(theme) => ({
              display: "grid",
              padding: theme.spacing(3, 4),
              gridTemplateColumns: "repeat(3, 1fr)",
              columnGap: 5,
              rowGap: 1,
              "& > *": {
                mt: 0.5,
              },
            })}
          >
            <Typography variant={"h6"} sx={{ gridColumn: "1/4", mb: 1 }}>
              Payment Schedule
            </Typography>

            <>
              <DateField
                label="Issue Date"
                id="digitalFixedRateBondForm-issueDate-dateField"
                disabled={props.apiLoading}
                readOnly={readOnly || !draftState}
                onChange={(newDate) =>
                  formDataUpdate.issueDate(newDate ? newDate.format() : "")
                }
                value={formData.digitalFixedRateBond.issueDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="digitalFixedRateBondForm-issueDate-dateFieldTextField"
                    fullWidth
                    disabled={props.apiLoading}
                    readOnly={readOnly || !draftState}
                    helperText={
                      formDataValidationResult.fieldValidations.issueDate
                    }
                    error={
                      !!formDataValidationResult.fieldValidations.issueDate
                    }
                  />
                )}
              />
              <TextField
                id="digitalFixedRateBondForm-calendar-selectField"
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                SelectProps={{
                  IconComponent:
                    readOnly || !draftState ? () => null : undefined,
                }}
                fullWidth
                label="Calendar"
                select
                value={formData.digitalFixedRateBond.calendar}
                onChange={(e) =>
                  formDataUpdate.calendar(Number(e.target.value))
                }
                error={!!formDataValidationResult.fieldValidations.calendar}
                helperText={formDataValidationResult.fieldValidations.calendar}
              >
                {allCalendars.map((cal, idx) => (
                  <MenuItem key={idx} value={cal}>
                    {calendarToString(cal)}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="digitalFixedRateBondForm-dayCountConvention-selectField"
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                SelectProps={{
                  IconComponent:
                    readOnly || !draftState ? () => null : undefined,
                }}
                fullWidth
                label="Day Count Convention"
                select
                value={formData.digitalFixedRateBond.dayCountConvention}
                onChange={(e) =>
                  formDataUpdate.dayCountConvention(
                    e.target.value as DayCountConvention,
                  )
                }
                error={
                  !!formDataValidationResult.fieldValidations.dayCountConvention
                }
                helperText={
                  formDataValidationResult.fieldValidations.dayCountConvention
                }
              >
                {allDayCountConventions.map((dcc, idx) => (
                  <MenuItem key={idx} value={idx + 1}>
                    {dcc}
                  </MenuItem>
                ))}
              </TextField>
            </>
            <>
              <DateField
                label="Maturity Date"
                id="digitalFixedRateBondForm-maturityDate-dateField"
                disabled={props.apiLoading}
                readOnly={readOnly || !draftState}
                onChange={(newDate) =>
                  formDataUpdate.maturityDate(newDate ? newDate.format() : "")
                }
                value={formData.digitalFixedRateBond.maturityDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="digitalFixedRateBondForm-maturityDate-dateFieldTextField"
                    fullWidth
                    disabled={props.apiLoading}
                    readOnly={readOnly || !draftState}
                    helperText={
                      formDataValidationResult.fieldValidations.maturityDate
                    }
                    error={
                      !!formDataValidationResult.fieldValidations.maturityDate
                    }
                  />
                )}
              />
              <TextField
                fullWidth
                id="digitalFixedRateBondForm-businessDayConvention-selectField"
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                SelectProps={{
                  IconComponent:
                    readOnly || !draftState ? () => null : undefined,
                }}
                label="Business Day Conv."
                select
                value={formData.digitalFixedRateBond.businessDayConvention}
                onChange={(e) => {
                  formDataUpdate.businessDayConvention(
                    e.target.value as BusinessDayConvention,
                  );
                }}
                error={!!formDataValidationResult.fieldValidations.calendar}
                helperText={formDataValidationResult.fieldValidations.calendar}
              >
                {allBusinessDayConventions.map((bdc, idx) => (
                  <MenuItem key={idx} value={idx + 1}>
                    {bdc}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="digitalFixedRateBondForm-couponPaymentFrequency-selectField"
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                SelectProps={{
                  IconComponent:
                    readOnly || !draftState ? () => null : undefined,
                }}
                fullWidth
                label="Coupon Payment Freq."
                select
                value={formData.digitalFixedRateBond.couponPaymentFrequency}
                onChange={(e) => {
                  formDataUpdate.couponPaymentFrequency(
                    e.target.value as CouponFrequency,
                  );
                }}
                error={!!formDataValidationResult.fieldValidations.calendar}
                helperText={formDataValidationResult.fieldValidations.calendar}
              >
                {allCouponFrequencies.map((freq, idx) => (
                  <MenuItem key={idx} value={idx + 1}>
                    {freq}
                  </MenuItem>
                ))}
              </TextField>
            </>
            <>
              <TextField
                type="number"
                id="digitalFixedRateBondForm-fixDays-numberField"
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                value={formData.digitalFixedRateBond.fixDays}
                onChange={(e) => formDataUpdate.fixDays(Number(e.target.value))}
                label="Fix Days"
                sx={{
                  maxWidth: "100%",
                  width: readOnly || !draftState ? 88 : "100%",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 1,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      children={
                        <Tooltip
                          placement="top"
                          title="Number of days before coupon payment date that calculations are finalized and funding may begin."
                        >
                          <InfoIcon
                            sx={(theme) => ({
                              cursor: "pointer",
                              color: theme.palette.text.secondary,
                              width: "16px",
                              height: "16px",
                              mb: 0.5,
                            })}
                          />
                        </Tooltip>
                      }
                    />
                  ),
                }}
                variant="outlined"
                error={!!formDataValidationResult.fieldValidations.fixDays}
                helperText={formDataValidationResult.fieldValidations.fixDays}
              />
              <TextField
                type="number"
                id="digitalFixedRateBondForm-recordDays-numberField"
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                value={formData.digitalFixedRateBond.recordDays}
                onChange={(e) =>
                  formDataUpdate.recordDays(Number(e.target.value))
                }
                label="Record Days"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 0,
                }}
                variant="outlined"
                error={!!formDataValidationResult.fieldValidations.recordDays}
                helperText={
                  formDataValidationResult.fieldValidations.recordDays
                }
                sx={{
                  maxWidth: "100%",
                  width: readOnly || !draftState ? "112px" : "100%",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      children={
                        <Tooltip
                          placement="top"
                          title="Number of days before coupon payment date that ownership records are taken to determine recipients."
                        >
                          <InfoIcon
                            sx={(theme) => ({
                              cursor: "pointer",
                              color: theme.palette.text.secondary,
                              width: "16px",
                              height: "16px",
                              mb: 0.5,
                            })}
                          />
                        </Tooltip>
                      }
                    />
                  ),
                }}
              />
              <TextField
                type="number"
                id="digitalFixedRateBondForm-exCouponDays-numberField"
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                value={formData.digitalFixedRateBond.exCouponDays}
                onChange={(e) =>
                  formDataUpdate.exCouponDays(Number(e.target.value))
                }
                label="ExCoupon Days"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 0,
                }}
                sx={{
                  maxWidth: "100%",
                  width: readOnly || !draftState ? "132px" : "100%",
                }}
                variant="outlined"
                error={!!formDataValidationResult.fieldValidations.exCouponDays}
                helperText={
                  formDataValidationResult.fieldValidations.exCouponDays
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      children={
                        <Tooltip
                          placement="top"
                          title="Estimated of days before coupon payment date that the bond will trade while the seller is likely to maintain rights to next payment."
                        >
                          <InfoIcon
                            sx={(theme) => ({
                              cursor: "pointer",
                              color: theme.palette.text.secondary,
                              width: "16px",
                              height: "16px",
                              mb: 0.5,
                            })}
                          />
                        </Tooltip>
                      }
                    />
                  ),
                }}
              />
            </>
          </Box>

          {/* ----------- Financials ----------------*/}
          <Box
            sx={(theme) => ({
              display: "grid",
              padding: theme.spacing(3, 4),
              gridTemplateColumns: "repeat(3, 1fr)",
              columnGap: 5,
              rowGap: 1,
              "& > *": {
                mt: 0.5,
              },
            })}
          >
            <Typography variant={"h6"} sx={{ gridColumn: "1/4", mb: 1 }}>
              Financials
            </Typography>

            <>
              <TextField
                id="digitalFixedRateBondForm-currencyStablecoin-selectField"
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                SelectProps={{
                  IconComponent:
                    readOnly || !draftState ? () => null : undefined,
                }}
                fullWidth
                label="Currency Stablecoin"
                select
                value={
                  props.formData.currencyStablecoins.find((cst) =>
                    cst.token.isEqualTo(
                      formData.digitalFixedRateBond.totalNominal.token,
                    ),
                  )?.id ?? ""
                }
                onChange={(e) =>
                  formDataUpdate.currencyStablecoinToken(
                    props.formData.currencyStablecoins.find(
                      (cst) => cst.id === e.target.value,
                    )?.token ?? new Token(),
                  )
                }
                error={
                  !!formDataValidationResult.fieldValidations
                    .terminationDateBusinessDayConvention
                }
                helperText={
                  formDataValidationResult.fieldValidations
                    .terminationDateBusinessDayConvention
                }
              >
                {props.formData.currencyStablecoins.map((cst, idx) => (
                  <MenuItem key={idx} value={cst.id}>
                    <Box
                      sx={(theme) => ({
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: theme.spacing(1),
                      })}
                    >
                      <TokenIconViewUpload
                        disableChangeIcon
                        size={23}
                        token={cst.token}
                      />
                      <Typography children={cst.token.code} />
                    </Box>
                  </MenuItem>
                ))}
              </TextField>
              <TextNumField
                id={"digitalFixedRateBondForm-couponRate-textNumField"}
                percentage={readOnly || !draftState}
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                fullWidth
                noDecimalPlaces={7}
                label={"Coupon Rate"}
                disallowNegative
                value={formData.digitalFixedRateBond.couponRate}
                onChange={(e) => formDataUpdate.couponRate(e.target.value)}
                error={!!formDataValidationResult.fieldValidations.couponRate}
                helperText={
                  formDataValidationResult.fieldValidations.couponRate
                }
              />
              <TextNumField
                id={"digitalFixedRateBondForm-redemption-textNumField"}
                percentage={readOnly || !draftState}
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                label={"Redemption"}
                noDecimalPlaces={7}
                disallowNegative
                fullWidth
                sx={{
                  maxWidth: "100%",
                  width: readOnly || !draftState ? "112px" : "100%",
                }}
                onChange={(e) => formDataUpdate.redemption(e.target.value)}
                value={formData.digitalFixedRateBond.redemption}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <Box
                        sx={(theme) => ({
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: theme.spacing(0.5),
                        })}
                      >
                        <Tooltip
                          placement="top"
                          title="Percentage of total nominal paid back at maturity."
                        >
                          <InfoIcon
                            sx={(theme) => ({
                              cursor: "pointer",
                              color: theme.palette.text.secondary,
                              width: "16px",
                              height: "16px",
                              mb: 0.5,
                            })}
                          />
                        </Tooltip>
                      </Box>
                    </InputAdornment>
                  ),
                }}
                error={!!formDataValidationResult.fieldValidations.redemption}
                helperText={
                  formDataValidationResult.fieldValidations.redemption
                }
              />
            </>
            <>
              <TextNumField
                id={"digitalFixedRateBondForm-nominal-textNumField"}
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                fullWidth
                noDecimalPlaces={7}
                label={"Nominal"}
                disallowNegative
                value={formData.digitalFixedRateBond.nominal.value}
                onChange={(e) =>
                  formDataUpdate.nominal(
                    formData.digitalFixedRateBond.nominal.setValue(
                      e.target.value,
                    ),
                  )
                }
                sx={{
                  maxWidth: "100%",
                  width:
                    readOnly || !draftState
                      ? formData.digitalFixedRateBond.nominal.toString()
                          .length *
                          12 +
                        8
                      : "100%",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                      {formData.digitalFixedRateBond.nominal.token.code}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      children={
                        <Tooltip
                          placement="top"
                          title="Nominal associated with a single unit of the bond."
                        >
                          <InfoIcon
                            sx={(theme) => ({
                              cursor: "pointer",
                              color: theme.palette.text.secondary,
                              width: "16px",
                              height: "16px",
                              mb: 0.5,
                            })}
                          />
                        </Tooltip>
                      }
                    />
                  ),
                }}
                error={!!formDataValidationResult.fieldValidations.nominal}
                helperText={formDataValidationResult.fieldValidations.nominal}
              />
              <TextNumField
                id={"digitalFixedRateBondForm-issuePrice-textNumField"}
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                fullWidth
                noDecimalPlaces={7}
                label={"Issue Price"}
                disallowNegative
                value={formData.digitalFixedRateBond.issuePrice.value}
                onChange={(e) =>
                  formDataUpdate.issuePrice(
                    formData.digitalFixedRateBond.issuePrice.setValue(
                      e.target.value,
                    ),
                  )
                }
                sx={{
                  maxWidth: "100%",
                  width:
                    readOnly || !draftState
                      ? formData.digitalFixedRateBond.issuePrice.toString()
                          .length *
                          12 +
                        8
                      : "100%",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                      {formData.digitalFixedRateBond.issuePrice.token.code}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      children={
                        <Tooltip
                          placement="top"
                          title="The price of a bond unit on the primary market."
                        >
                          <InfoIcon
                            sx={(theme) => ({
                              cursor: "pointer",
                              color: theme.palette.text.secondary,
                              width: "16px",
                              height: "16px",
                              mb: 0.5,
                            })}
                          />
                        </Tooltip>
                      }
                    />
                  ),
                }}
                error={!!formDataValidationResult.fieldValidations.issuePrice}
                helperText={
                  formDataValidationResult.fieldValidations.issuePrice
                }
              />
              <TextNumField
                id={"digitalFixedRateBondForm-totalNominal-textNumField"}
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                fullWidth
                noDecimalPlaces={7}
                label={"Total Nominal"}
                disallowNegative
                value={formData.digitalFixedRateBond.totalNominal.value}
                onChange={(e) =>
                  formDataUpdate.totalNominal(
                    formData.digitalFixedRateBond.totalNominal.setValue(
                      e.target.value,
                    ),
                  )
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                      {formData.digitalFixedRateBond.totalNominal.token.code}
                    </InputAdornment>
                  ),
                }}
                error={!!formDataValidationResult.fieldValidations.totalNominal}
                helperText={
                  formDataValidationResult.fieldValidations.totalNominal
                }
              />
            </>
          </Box>

          {/* ----------- Advanced ----------------*/}
          <Box
            sx={(theme) => ({
              display: "grid",
              padding: theme.spacing(3, 4, 5, 3),
              gridTemplateColumns: "repeat(3, 1fr)",
              columnGap: 5,
              rowGap: 1,
              "& > *": {
                mt: 0.5,
              },
            })}
          >
            <Typography variant={"h6"} sx={{ gridColumn: "1/4", mb: 1 }}>
              Advanced
            </Typography>

            <>
              <DateField
                nullable
                label="First Coupon Payment Date"
                id="digitalFixedRateBondForm-firstCouponPaymentDate-dateField"
                disabled={props.apiLoading}
                readOnly={readOnly || !draftState}
                onChange={(newDate) => {
                  formDataUpdate.firstCouponPaymentDate(
                    newDate ? newDate.format() : null,
                  );
                }}
                value={formData.digitalFixedRateBond.firstCouponPaymentDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="digitalFixedRateBondForm-firstCouponPaymentDate-dateFieldTextField"
                    fullWidth
                    disabled={props.apiLoading}
                    readOnly={readOnly || !draftState}
                    helperText={
                      formDataValidationResult.fieldValidations
                        .firstCouponPaymentDate
                    }
                    error={
                      !!formDataValidationResult.fieldValidations
                        .firstCouponPaymentDate
                    }
                  />
                )}
              />
              <DateField
                nullable
                label="Next to Last Coupon Payment Date"
                id="digitalFixedRateBondForm-nextToLastCouponPaymentDate-dateField"
                disabled={props.apiLoading}
                readOnly={readOnly || !draftState}
                onChange={(newDate) => {
                  formDataUpdate.nextToLastCouponPaymentDate(
                    newDate ? newDate.format() : null,
                  );
                }}
                value={
                  formData.digitalFixedRateBond.nextToLastCouponPaymentDate
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="digitalFixedRateBondForm-nextToLastCouponPaymentDate-dateFieldTextField"
                    fullWidth
                    disabled={props.apiLoading}
                    readOnly={readOnly || !draftState}
                    helperText={
                      formDataValidationResult.fieldValidations
                        .nextToLastCouponPaymentDate
                    }
                    error={
                      !!formDataValidationResult.fieldValidations
                        .nextToLastCouponPaymentDate
                    }
                  />
                )}
              />
              <DateField
                nullable
                label="Coupon Interest Commencement Date"
                id="digitalFixedRateBondForm-couponInterestCommencementDate-dateField"
                disabled={props.apiLoading}
                readOnly={readOnly || !draftState}
                onChange={(newDate) =>
                  formDataUpdate.couponInterestCommencementDate(
                    newDate ? newDate.format() : "",
                  )
                }
                value={
                  formData.digitalFixedRateBond.couponInterestCommencementDate
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="digitalFixedRateBondForm-couponInterestCommencementDate-dateFieldTextField"
                    fullWidth
                    helperText={
                      formDataValidationResult.fieldValidations
                        .couponInterestCommencementDate
                    }
                    error={
                      !!formDataValidationResult.fieldValidations
                        .couponInterestCommencementDate
                    }
                  />
                )}
              />
            </>

            <>
              <TextField
                id="digitalFixedRateBondForm-terminationDateBusinessDayConvention-selectField"
                select
                fullWidth
                disabled={props.apiLoading}
                readOnly={readOnly || !draftState}
                SelectProps={{
                  IconComponent:
                    readOnly || !draftState ? () => null : undefined,
                }}
                label="Termination Date Business Day Conv."
                value={
                  formData.digitalFixedRateBond
                    .terminationDateBusinessDayConvention
                }
                onChange={(e) =>
                  formDataUpdate.terminationDateBusinessDayConvention(
                    e.target.value as BusinessDayConvention,
                  )
                }
                error={
                  !!formDataValidationResult.fieldValidations
                    .terminationDateBusinessDayConvention
                }
                helperText={
                  formDataValidationResult.fieldValidations
                    .terminationDateBusinessDayConvention
                }
              >
                {allBusinessDayConventions.map((bdc, idx) => (
                  <MenuItem key={idx} value={idx + 1}>
                    {bdc}
                  </MenuItem>
                ))}
              </TextField>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  disabled={props.apiLoading || readOnly || !draftState}
                  control={<Checkbox sx={{ mr: 1 }} />}
                  label={
                    <Typography color={"textPrimary"}>
                      End of Month Convention
                    </Typography>
                  }
                  checked={formData.digitalFixedRateBond.endOfMonthConvention}
                  onChange={(_, checked) =>
                    formDataUpdate.endOfMonthConvention(checked)
                  }
                />
                <Tooltip
                  placement="top"
                  title="Indicates whether dates should be adjusted to the end of the month if they don't fall on a business day."
                >
                  <InfoIcon
                    sx={(theme) => ({
                      cursor: "pointer",
                      color: theme.palette.text.secondary,
                      width: "16px",
                      height: "16px",
                      mb: 0.5,
                    })}
                  />
                </Tooltip>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  disabled={props.apiLoading || readOnly || !draftState}
                  control={<Checkbox sx={{ mr: 1 }} />}
                  label={
                    <Typography color={"textPrimary"}>
                      Fractionalisation Allowed
                    </Typography>
                  }
                  checked={
                    formData.digitalFixedRateBond.fractionalisationAllowed
                  }
                  onChange={(_, checked) =>
                    formDataUpdate.fractionalisationAllowed(checked)
                  }
                />
                <Tooltip
                  placement="top"
                  title="Indicates whether bond units may be broken down into non-integer values."
                >
                  <InfoIcon
                    sx={(theme) => ({
                      cursor: "pointer",
                      color: theme.palette.text.secondary,
                      width: "16px",
                      height: "16px",
                      mb: 0.5,
                    })}
                  />
                </Tooltip>
              </Box>
            </>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* --------------- Legal & Compliance -----------------------*/}
      <Accordion defaultExpanded>
        <AccordionSummary
          sx={(theme) => ({
            pl: 4,
            flexDirection: "row-reverse",
            backgroundColor: theme.palette.custom.midnight,
          })}
          expandIcon={
            <ExpandMoreIcon
              id="digitalFixedRateBondForm-riskAssessment-accordionToggleIconButton"
              color="primary"
            />
          }
        >
          <Typography sx={{ ml: 4 }} variant={"h6"}>
            Legal and Compliance
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Box
            sx={(theme) => ({
              display: "grid",
              p: 3,
              px: 4,
              pb: 5,
              gridTemplateColumns: "repeat(3, 1fr)",
              columnGap: theme.spacing(5),
              alignItems: "start",
            })}
          >
            <Box
              className="checkboxes"
              sx={{ display: "flex", gap: 2, width: "100%", ml: 1 }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  disabled={props.apiLoading || readOnly}
                  control={<Checkbox sx={{ mr: 1 }} />}
                  label={
                    <Typography color={"textPrimary"}>Voting Rights</Typography>
                  }
                  checked={formData.digitalFixedRateBond.votingRights}
                  onChange={(_, checked) =>
                    formDataUpdate.votingRights(checked)
                  }
                />
                <Tooltip
                  placement="top"
                  title="Indicates whether investors are granted voting rights."
                >
                  <InfoIcon
                    sx={(theme) => ({
                      cursor: "pointer",
                      color: theme.palette.text.secondary,
                      width: "16px",
                      height: "16px",
                      mb: 0.5,
                    })}
                  />
                </Tooltip>
              </Box>
            </Box>
            <TextField
              id="digitalFixedRateBondForm-debtSeniorityLevel-selectField"
              readOnly={readOnly || !draftState}
              disabled={props.apiLoading}
              SelectProps={{
                IconComponent: readOnly || !draftState ? () => null : undefined,
              }}
              fullWidth
              label="Debt Seniority Level"
              select
              value={formData.digitalFixedRateBond.debtSeniorityLevel}
              onChange={(e) =>
                formDataUpdate.debtSeniorityLevel(
                  e.target.value as DebtSeniorityLevel,
                )
              }
              error={
                !!formDataValidationResult.fieldValidations.debtSeniorityLevel
              }
              helperText={
                formDataValidationResult.fieldValidations.debtSeniorityLevel
              }
            >
              {allDebtSeniorityLevels.map((bdc, idx) => (
                <MenuItem key={idx} value={bdc}>
                  {bdc}
                </MenuItem>
              ))}
            </TextField>
            <Box />

            <TextArea
              id={"digitalFixedRateBondForm-votingRightsDescription-textArea"}
              label={"Voting Rights Description"}
              value={formData.digitalFixedRateBond.votingRightsDescription}
              onChange={(newValue) =>
                formDataUpdate.votingRightsDescription(newValue)
              }
              readOnly={readOnly || !draftState}
              disabled={props.apiLoading}
              maxLength={500}
            />
            <TextArea
              id={"digitalFixedRateBondForm-covenants-textArea"}
              label={"Covenants"}
              value={formData.digitalFixedRateBond.covenants}
              onChange={(newValue) => formDataUpdate.covenants(newValue)}
              readOnly={readOnly || !draftState}
              disabled={props.apiLoading}
              maxLength={500}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* --------------- Risk Assessment -----------------------*/}
      <Accordion defaultExpanded>
        <AccordionSummary
          sx={(theme) => ({
            pl: 4,
            flexDirection: "row-reverse",
            backgroundColor: theme.palette.custom.midnight,
          })}
          expandIcon={
            <ExpandMoreIcon
              id="digitalFixedRateBondForm-riskAssessment-accordionToggleIconButton"
              color="primary"
            />
          }
        >
          <Typography sx={{ ml: 4 }} variant={"h6"}>
            Risk Assessment
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: 3,
            px: 4,
            pb: 5,
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: 5,
            rowGap: 3,
          }}
        >
          <Box>
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.primary,
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: "14px",
                mb: 1,
              })}
            >
              Investor Profile
            </Typography>
            <TextField
              id="digitalFixedRateBondForm-investorProfile-selectField"
              select
              fullWidth
              label="Investor Profile"
              disabled={props.apiLoading}
              readOnly={readOnly || !draftState}
              SelectProps={{
                IconComponent: readOnly || !draftState ? () => null : undefined,
              }}
              value={formData.digitalFixedRateBond.investorProfile}
              onChange={(e) =>
                formDataUpdate.investorProfile(
                  e.target.value as InvestorProfile,
                )
              }
              error={
                !!formDataValidationResult.fieldValidations.investorProfile
              }
              helperText={
                formDataValidationResult.fieldValidations.investorProfile
              }
            >
              {AllInvestorProfiles.map((bdc, idx) => (
                <MenuItem key={idx} value={bdc}>
                  {bdc}
                </MenuItem>
              ))}
            </TextField>
            <TextArea
              id={
                "digitalFixedRateBondForm-investorProfileDescription-textArea"
              }
              label={"Description"}
              value={formData.digitalFixedRateBond.investorProfileDescription}
              onChange={(newValue) =>
                formDataUpdate.investorProfileDescription(newValue)
              }
              readOnly={readOnly || !draftState}
              disabled={props.apiLoading}
              maxLength={500}
            />
          </Box>
          <Box>
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.primary,
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: "14px",
                mb: 1,
              })}
            >
              Risk Profile
            </Typography>
            <TextField
              id="digitalFixedRateBondForm-riskProfile-selectField"
              readOnly={readOnly || !draftState}
              disabled={props.apiLoading}
              fullWidth
              label="Risk Profile"
              select
              SelectProps={{
                IconComponent: readOnly || !draftState ? () => null : undefined,
              }}
              value={formData.digitalFixedRateBond.riskProfile}
              onChange={(e) =>
                formDataUpdate.riskProfile(
                  e.target.value as InstrumentRiskProfile,
                )
              }
              error={!!formDataValidationResult.fieldValidations.riskProfile}
              helperText={formDataValidationResult.fieldValidations.riskProfile}
            >
              {AllInstrumentRiskProfiles.map((bdc, idx) => (
                <MenuItem key={idx} value={bdc}>
                  {bdc}
                </MenuItem>
              ))}
            </TextField>
            <TextArea
              id={"digitalFixedRateBondForm-riskProfileDescription-textArea"}
              label={"Description"}
              value={formData.digitalFixedRateBond.riskProfileDescription}
              onChange={(newValue) =>
                formDataUpdate.riskProfileDescription(newValue)
              }
              readOnly={readOnly || !draftState}
              disabled={props.apiLoading}
              maxLength={500}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* --------------- Documents -----------------------*/}
      <Accordion defaultExpanded>
        <AccordionSummary
          sx={(theme) => ({
            pl: 4,
            flexDirection: "row-reverse",
            backgroundColor: theme.palette.custom.midnight,
          })}
          expandIcon={
            <ExpandMoreIcon
              id="digitalFixedRateBondForm-riskAssessment-accordionToggleIconButton"
              color="primary"
            />
          }
        >
          <Typography sx={{ ml: 4 }} variant={"h6"}>
            Documents
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(3, 4, 5, 4),
          })}
        >
          <Documents
            showButton={
              formData.digitalFixedRateBond.bondState() === BondState.Draft
            }
            creatingNewInstrument={creatingNewInstrument}
            documents={formData.digitalFixedRateBond.supportingDocuments}
            onDelete={async (doc) => {
              props.setAPILoading(true);
              try {
                const response =
                  await DigitalFixedRateBondUpdater.DeleteDigitalFixedRateBondSupportingDocument(
                    {
                      context: authContext,
                      digitalFixedRateBondID:
                        formData.digitalFixedRateBond.assetID(),
                      supportingDocumentID: doc.id,
                    },
                  );
                formDataUpdate.digitalFixedRateBond(
                  response.digitalFixedRateBond,
                );
                props.setAPILoading(false);
              } catch (e) {
                props.setAPILoading(false);
                console.error(e);
              }
            }}
            onDocumentUpload={async (doc) => {
              props.setAPILoading(true);
              let updatedDigitalFixedRateBond = formData.digitalFixedRateBond;
              try {
                updatedDigitalFixedRateBond =
                  await handleUpdateSupportingDocs(doc);
                enqueueSnackbar("Document uploaded", {
                  variant: "success",
                });
                formDataUpdate.digitalFixedRateBond(
                  updatedDigitalFixedRateBond,
                );
                props.setAPILoading(false);
                navigate(
                  `${window.location.pathname}?id=${updatedDigitalFixedRateBond.id}&mode=${FormMode.View}`,
                );
                updatedDigitalFixedRateBond.supportingDocuments =
                  formData.digitalFixedRateBond.supportingDocuments.concat(doc);
                props.onDigitalFixedRateBondChange(
                  new DigitalFixedRateBond(updatedDigitalFixedRateBond),
                );
              } catch (e) {
                console.error(e);
                props.setAPILoading(false);
              }
              props.setAPILoading(false);
            }}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};
