import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { TextField } from "../../../../../../../../../components/FormFields";
import {
  allAssetClasses,
  allUnitCategories,
  assetClassToString,
  unitCategoryToString,
} from "@mesh/common-js/dist/financial";
import { Token } from "james/ledger";
import { TokenIconViewUpload } from "components/Ledger/Token";
import {
  DateTimeField,
  LedgerAmountField,
} from "@mesh/common-js-react/dist/FormFields";
import { FutureAmount } from "@mesh/common-js/dist/ledger/amount_pb";
import { Info as InfoIcon } from "@mui/icons-material";
import { allTimezones, timezoneToString } from "@mesh/common-js/dist/i8n";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { ViewMode, useBuilderContext } from "../../../../Context";

export const InstrumentSection = () => {
  const {
    apiCallInProgress,
    viewMode,
    formData,
    formDataValidationResult,
    formDataUpdater,
    potentialGroupOwners,
    ledgerTokenViewModels,
  } = useBuilderContext();

  const disabled = apiCallInProgress;
  const readOnly = viewMode === ViewMode.View;

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexWrap: "wrap",
        columnGap: theme.spacing(1),
      })}
    >
      <TextField
        id={"smartInstrumentBuilder-instrument-name-textField"}
        fullWidth
        sx={{ maxWidth: 320 }}
        label="Name"
        readOnly={readOnly}
        disabled={disabled}
        placeholder="Enter an Instrument Name"
        value={formData.smartInstrument.getName()}
        onChange={(e) => formDataUpdater.name(e.target.value)}
        error={!!formDataValidationResult.fieldValidations.name}
        helperText={formDataValidationResult.fieldValidations.name}
      />
      {formData.smartInstrument.getToken() && (
        <TextField
          id={"smartInstrumentBuilder-instrument-tokenCode-textField"}
          fullWidth
          sx={{ maxWidth: 320 }}
          label="Token Code"
          readOnly
          value={formData.smartInstrument.getToken()?.getCode()}
        />
      )}
      <TextField
        id={"smartInstrumentBuilder-instrument-ownerID-select"}
        fullWidth
        sx={{ maxWidth: 320 }}
        readOnly={readOnly}
        disabled={disabled}
        label="Owner Group"
        select
        value={formData.smartInstrument.getOwnerid()}
        onChange={(e) => formDataUpdater.ownerID(e.target.value)}
        error={!!formDataValidationResult.fieldValidations.ownerID}
        helperText={formDataValidationResult.fieldValidations.ownerID}
      >
        {potentialGroupOwners.map((group) => (
          <MenuItem key={group.id} value={group.id}>
            {group.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="smartInstrumentBuilder-instrument-assetClass-select"
        readOnly={readOnly}
        disabled={disabled}
        fullWidth
        sx={{ maxWidth: 320 }}
        label="Asset Class"
        select
        value={formData.smartInstrument.getAssetclass()}
        onChange={(e) => formDataUpdater.assetClass(Number(e.target.value))}
        error={!!formDataValidationResult.fieldValidations.assetClass}
        helperText={formDataValidationResult.fieldValidations.assetClass}
      >
        {allAssetClasses.map((v) => {
          return (
            <MenuItem key={v} value={v}>
              {assetClassToString(v)}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        id="smartInstrumentBuilder-instrument-unitCategory-select"
        readOnly={readOnly}
        disabled={disabled}
        fullWidth
        sx={{ maxWidth: 320 }}
        label="Unit Type"
        select
        value={formData.smartInstrument.getUnitcategory()}
        onChange={(e) => formDataUpdater.unitCategory(Number(e.target.value))}
        error={!!formDataValidationResult.fieldValidations.unitCategory}
        helperText={formDataValidationResult.fieldValidations.unitCategory}
      >
        {allUnitCategories.map((v) => {
          return (
            <MenuItem key={v} value={v}>
              {unitCategoryToString(v)}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        id="smartInstrumentBuilder-instrument-nominalToken-select"
        readOnly={readOnly}
        disabled={disabled}
        fullWidth
        sx={{ maxWidth: 320 }}
        label="Base Token"
        select
        value={
          ledgerTokenViewModels.find((cst) =>
            cst.token.isEqualTo(
              Token.fromFutureToken(
                formData.smartInstrument.getUnitnominal()?.getToken(),
              ),
            ),
          )?.id ?? ""
        }
        onChange={(e) =>
          formDataUpdater.nominalAmountToken(
            (
              ledgerTokenViewModels.find((cst) => cst.id === e.target.value)
                ?.token ?? new Token()
            ).toFutureToken(),
          )
        }
        error={!!formDataValidationResult.fieldValidations.nominalAmountToken}
        helperText={
          formDataValidationResult.fieldValidations.nominalAmountToken
        }
      >
        {ledgerTokenViewModels.map((cst, idx) => (
          <MenuItem key={idx} value={cst.id}>
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: theme.spacing(1),
              })}
            >
              <TokenIconViewUpload
                disableChangeIcon
                size={23}
                token={cst.token}
              />
              <Typography children={`${cst.token.code} - ${cst.name}`} />
            </Box>
          </MenuItem>
        ))}
      </TextField>
      <LedgerAmountField
        id="smartInstrumentBuilder-instrument-nominalAmount-amountField"
        fullWidth
        readOnly={readOnly}
        disabled={disabled}
        sx={{ maxWidth: 320 }}
        noDecimalPlaces={7}
        label="Unit Nominal"
        value={formData.smartInstrument.getUnitnominal()}
        onChange={(newValue: FutureAmount) =>
          formDataUpdater.nominalAmount(newValue)
        }
        error={!!formDataValidationResult.fieldValidations.unitNominalValue}
        helperText={formDataValidationResult.fieldValidations.unitNominalValue}
      />
      <DateTimeField
        id="smartInstrumentBuilder-instrument-issueDate-dateField"
        sx={{ maxWidth: 340, minWidth: "100%" }}
        readOnly={readOnly}
        disabled={disabled}
        label={"Issue Date"}
        value={formData.smartInstrument.getIssuedate()}
        onChange={(newValue) => formDataUpdater.issueDate(newValue)}
        error={!!formDataValidationResult.fieldValidations.issueDate}
        helperText={formDataValidationResult.fieldValidations.issueDate}
      />
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <TextField
          id="smartInstrumentBuilder-instrument-timezone-select"
          label="Timezone"
          readOnly={readOnly}
          disabled={disabled}
          fullWidth
          sx={{ maxWidth: 290 }}
          select
          value={formData.smartInstrument.getTimezone()}
          onChange={(e) => formDataUpdater.timezone(Number(e.target.value))}
          error={!!formDataValidationResult.fieldValidations.timezone}
          helperText={formDataValidationResult.fieldValidations.timezone}
        >
          {allTimezones
            .filter((tz) => tz != Timezone.UNDEFINED_TIMEZONE)
            .map((tz, idx) => (
              <MenuItem key={idx} value={tz}>
                {timezoneToString(tz)}
              </MenuItem>
            ))}
        </TextField>
        <Tooltip
          placement={"top"}
          title="Determines the local time context for the instrument."
        >
          <InfoIcon sx={{ ml: 1 }} />
        </Tooltip>
      </Box>
      <Box
        className="checkboxes"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          width: "100%",
          maxWidth: 322,
        }}
      >
        <FormControlLabel
          sx={{ width: "100%", cursor: "text" }}
          id="smartInstrumentBuilder-instrument-fractionalisationAllowed-checkBox"
          disabled={disabled}
          control={<Checkbox disableTouchRipple={readOnly} />}
          label="Fractionalisation Allowed"
          checked={formData.smartInstrument.getFractionalisationallowed()}
          onChange={(_, checked) => {
            if (readOnly) {
              return;
            }
            formDataUpdater.fractionalisationAllowed(checked);
          }}
        />
        <Tooltip
          placement={"top"}
          title="Indicates whether the instrument can be held, traded, or transferred in fractional units."
        >
          <InfoIcon sx={{ mr: "auto" }} />
        </Tooltip>
      </Box>
    </Box>
  );
};
