"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringToPeriodUnit = exports.periodunitToString = exports.allPeriodUnits = void 0;
const periodUnit_pb_1 = require("./periodUnit_pb");
// Get all periodunits as enum values
exports.allPeriodUnits = Object.values(periodUnit_pb_1.PeriodUnit).filter((value) => typeof value === "number");
// Define explicit mappings between PeriodUnit enums and custom string representations
const periodunitToStringMapping = {
    [periodUnit_pb_1.PeriodUnit.UNDEFINED_PERIOD_UNIT]: "-",
    [periodUnit_pb_1.PeriodUnit.DAYS_PERIOD_UNIT]: "Days",
    [periodUnit_pb_1.PeriodUnit.MONTHS_PERIOD_UNIT]: "Months",
    [periodUnit_pb_1.PeriodUnit.YEARS_PERIOD_UNIT]: "Years",
};
// Reverse mapping from string to PeriodUnit enum
const stringToPeriodUnitMapping = {};
for (const [key, value] of Object.entries(periodunitToStringMapping)) {
    stringToPeriodUnitMapping[value] = Number(key);
}
class UnsupportedPeriodUnitError extends Error {
    constructor(periodunit) {
        const message = `Unsupported PeriodUnit: ${periodunit}`;
        super(message);
        this.periodunit = periodunit;
    }
}
/**
 * Converts a PeriodUnit enum instance to a custom string representation.
 * @param {PeriodUnit} periodunit - The periodunit to convert.
 * @returns {string} The custom string representation of the periodunit.
 */
function periodunitToString(periodunit) {
    if (periodunit in periodunitToStringMapping) {
        return periodunitToStringMapping[periodunit];
    }
    else {
        throw new UnsupportedPeriodUnitError(periodunit);
    }
}
exports.periodunitToString = periodunitToString;
class UnsupportedPeriodUnitStringError extends Error {
    constructor(periodunitStr) {
        const message = `Unsupported periodunit string: ${periodunitStr}`;
        super(message);
        this.periodunitStr = periodunitStr;
    }
}
/**
 * Converts a custom string representation to a PeriodUnit enum instance.
 * @param {string} periodunitStr - The custom string representation of the periodunit.
 * @returns {PeriodUnit} The corresponding PeriodUnit enum instance.
 */
function stringToPeriodUnit(periodunitStr) {
    if (periodunitStr in stringToPeriodUnitMapping) {
        return stringToPeriodUnitMapping[periodunitStr];
    }
    else {
        throw new UnsupportedPeriodUnitStringError(periodunitStr);
    }
}
exports.stringToPeriodUnit = stringToPeriodUnit;
