import React, { useMemo } from "react";
import { Chip, ChipProps, SxProps, useTheme } from "@mui/material";
import { CouponPaymentState } from "james/financial/CouponPayment";
import camelCase from "lodash/camelCase";

export interface CouponPaymentStateChipProps {
  state: CouponPaymentState | "";
  chipProps?: ChipProps;
}

export const CouponPaymentStateChip = ({
  state,
  chipProps,
}: CouponPaymentStateChipProps) => {
  const theme = useTheme();

  const styleMap: { [x in CouponPaymentState | ""]: SxProps } = useMemo(
    () => ({
      [CouponPaymentState.Draft]: {
        backgroundColor: theme.palette.info.main,
      },
      [CouponPaymentState.Deleted]: {
        backgroundColor: theme.palette.info.main,
      },
      [CouponPaymentState.Fixed]: {
        backgroundColor: theme.palette.info.main,
      },
      [CouponPaymentState.FundingInProgress]: {
        backgroundColor: theme.palette.info.main,
      },
      [CouponPaymentState.FundingUnderInvestigation]: {
        backgroundColor: theme.palette.error.main,
      },
      [CouponPaymentState.FundingFailed]: {
        backgroundColor: theme.palette.error.main,
      },
      [CouponPaymentState.Funded]: {
        backgroundColor: theme.palette.info.main,
      },
      [CouponPaymentState.PaymentInProgress]: {
        backgroundColor: theme.palette.info.main,
      },
      [CouponPaymentState.PaymentFailed]: {
        backgroundColor: theme.palette.error.main,
      },
      [CouponPaymentState.Paid]: {
        backgroundColor: theme.palette.info.main,
      },
      [CouponPaymentState.CleanUpInProgress]: {
        backgroundColor: theme.palette.info.main,
      },
      [CouponPaymentState.CleanUpUnderInvestigation]: {
        backgroundColor: theme.palette.error.main,
      },
      [CouponPaymentState.CleanUpFailed]: {
        backgroundColor: theme.palette.error.main,
      },
      [CouponPaymentState.Complete]: {
        backgroundColor: theme.palette.success.main,
      },
      "": {
        backgroundColor: theme.palette.info.main,
      },
    }),
    [],
  );

  return (
    <Chip
      {...chipProps}
      sx={styleMap[state]}
      id={`couponPaymentState-${camelCase(state)}-chip`}
      size="small"
      label={state}
    />
  );
};
