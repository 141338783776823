import { Amount } from "james/ledger";
import { Fee, FeeState } from "./Fee";
import { Entry } from "../audit/Entry";

export const InstrumentStablecoinListingFeeTypeName =
  "mesh::remuneration/InstrumentStablecoinListingFee";

export class InstrumentStablecoinListingFee implements Fee {
  public ["@type"]: string = InstrumentStablecoinListingFeeTypeName;

  public id = "";

  public responsibleGroupID = "";

  public instrumentStablecoinID = "";

  public amount: Amount = new Amount();

  public transactionID = "";

  public auditEntry: Entry = new Entry();

  public state: FeeState = FeeState.Pending;

  constructor(fee?: InstrumentStablecoinListingFee) {
    if (!fee) {
      return;
    }
    this.id = fee.id;
    this.responsibleGroupID = fee.responsibleGroupID;
    this.instrumentStablecoinID = fee.instrumentStablecoinID;
    this.amount = new Amount(fee.amount);
    this.transactionID = fee.transactionID;
    this.auditEntry = new Entry(fee.auditEntry);
  }

  feeAmount(): Amount {
    return this.amount;
  }

  feeID(): string {
    return this.id;
  }

  feeName(): string {
    return "Placement Fee";
  }

  feeResponsibleGroupID(): string {
    return this.responsibleGroupID;
  }

  feeTransactionID(): string {
    return this.transactionID;
  }

  feeState(): FeeState {
    return this.state;
  }
}
