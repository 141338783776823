import { Amount } from "james/ledger";
import { Address } from "james/location";
import dayjs from "dayjs";

export const ModelTypeName = "mesh::ledgerAssetHolderView/Model";

export class Model {
  ["@type"]: string = ModelTypeName;
  amountHeld: Amount = new Amount();
  accountNumber: string = "";
  accountLedgerID: string = "";
  holderName: string = "";
  holderEmailAddress: string = "";
  holderAddress: Address = new Address();
  assetIssuanceDate: string = dayjs().format();

  constructor(m?: Model) {
    if (!m) return;
    this.amountHeld = new Amount(m.amountHeld);
    this.accountNumber = m.accountNumber;
    this.accountLedgerID = m.accountLedgerID;
    this.holderName = m.holderName;
    this.holderEmailAddress = m.holderEmailAddress;
    this.holderAddress = new Address(m.holderAddress);
    this.assetIssuanceDate = m.assetIssuanceDate;
  }
}
