import { Context } from "james/security";
import { Identifier } from "james/search/identifier/Identifier";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { CouponPaymentRecipient } from "./CouponPaymentRecipient";
import { useApplicationContext } from "context/Application/Application";
import { useEffect, useRef, useState } from "react";
import { useErrorContext } from "context/Error";

export interface RetrieveCouponPaymentRecipientRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveCouponPaymentRecipientResponse {
  couponPaymentRecipient: CouponPaymentRecipient;
}

export interface SearchCouponPaymentRecipientsRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchCouponPaymentRecipientsResponse {
  records: CouponPaymentRecipient[];
  total: number;
}

export const CouponPaymentRecipientRepository = {
  serviceProvider: "financial-CouponPaymentRecipientRepository",
  async RetrieveCouponPaymentRecipient(
    request: RetrieveCouponPaymentRecipientRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<RetrieveCouponPaymentRecipientResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${CouponPaymentRecipientRepository.serviceProvider}.RetrieveCouponPaymentRecipient`,
        request,
      },
      opts,
    );
    return {
      couponPaymentRecipient: new CouponPaymentRecipient(
        response.couponPaymentRecipient,
      ),
    };
  },
  async SearchCouponPaymentRecipients(
    request: SearchCouponPaymentRecipientsRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<SearchCouponPaymentRecipientsResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${CouponPaymentRecipientRepository.serviceProvider}.SearchCouponPaymentRecipients`,
        request,
      },
      opts,
    );
    return {
      records: response.records.map(
        (c: CouponPaymentRecipient) => new CouponPaymentRecipient(c),
      ),
      total: response.total,
    };
  },
};

export function useSearchCouponPaymentRecipient(
  initialSearchCouponPaymentRecipientRequest?: SearchCouponPaymentRecipientsRequest,
  shouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const [
    searchCouponPaymentRecipientRequest,
    setSearchCouponPaymentRecipientRequest,
  ] = useState<SearchCouponPaymentRecipientsRequest>(
    initialSearchCouponPaymentRecipientRequest || {
      context: authContext,
      query: new Query(),
      criteria: {},
    },
  );
  const [
    searchCouponPaymentRecipientResponse,
    setSearchCouponPaymentRecipientResponse,
  ] = useState<SearchCouponPaymentRecipientsResponse>({
    total: 0,
    records: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setSearchCouponPaymentRecipientResponse(
          await CouponPaymentRecipientRepository.SearchCouponPaymentRecipients(
            searchCouponPaymentRecipientRequest,
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching for couponPaymentRecipient: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching for couponPaymentRecipient: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchCouponPaymentRecipientRequest, shouldNotExecute]);

  return {
    searchCouponPaymentRecipientRequest,
    setSearchCouponPaymentRecipientRequest,
    searchCouponPaymentRecipientResponse,
    loading,
    error,
  };
}
