import React from "react";
import { Router } from "routes";
import { DigitalInstrumentTable } from "components/Assets/DigitalInstrument/DigitalInstrumentTable";
import { V2 } from "../../InstrumentBuilder/v2";

export function DigitalInstrument() {
  return (
    <Router
      baseURL={"/market-management/assets/digital-instrument"}
      redirectPath={"/market-management/assets/digital-instrument/table"}
      routes={[
        {
          name: "Table",
          id: "table",
          path: "/market-management/assets/digital-instrument/table",
          component: (
            <DigitalInstrumentTable system height={window.innerHeight - 138} />
          ),
        },
        {
          name: "View",
          id: "view",
          path: "/market-management/assets/digital-instrument/view",
          component: (
            <V2
              system={true}
              backPath={"/market-management/assets/digital-instrument"}
              basePath={"/market-management/assets/digital-instrument/view"}
            />
          ),
          allowSubPaths: true,
        },
      ]}
    />
  );
}
