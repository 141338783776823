"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataTable = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const material_1 = require("@mui/material");
function DataTable({ data, columns, height, tableContainerClassName, }) {
    const [page, setPage] = (0, react_1.useState)(0);
    const [rowsPerPage, setRowsPerPage] = (0, react_1.useState)(10);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: data.length > 0 && ((0, jsx_runtime_1.jsxs)(material_1.Card, { children: [(0, jsx_runtime_1.jsx)(material_1.TableContainer, { sx: { position: "relative", height: height }, className: tableContainerClassName, children: (0, jsx_runtime_1.jsxs)(material_1.Table, { stickyHeader: true, children: [(0, jsx_runtime_1.jsx)(material_1.TableHead, { children: (0, jsx_runtime_1.jsx)(material_1.TableRow, { children: Object.keys(columns).map((key, idx) => {
                                        return ((0, jsx_runtime_1.jsx)(material_1.TableCell, { children: (0, jsx_runtime_1.jsx)("div", { children: columns[key].title }) }, idx));
                                    }) }) }), (0, jsx_runtime_1.jsx)(material_1.TableBody
                            // FIXME: add theme to common lib somehow
                            , { 
                                // FIXME: add theme to common lib somehow
                                sx: {
                                    backgroundColor: "#605B88",
                                }, children: data
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, i) => {
                                    return ((0, jsx_runtime_1.jsx)(material_1.TableRow, { children: Object.keys(columns).map((key) => {
                                            return ((0, jsx_runtime_1.jsx)(material_1.TableCell, { children: columns[key].renderCell(row) }, key));
                                        }) }, i));
                                }) })] }) }), (0, jsx_runtime_1.jsx)(material_1.Box, { sx: () => ({
                        display: "flex",
                        justifyContent: "flex-end",
                        backgroundColor: "#46426C",
                        // backgroundColor: theme.palette.custom.grape, // FIXME: add theme to common lib somehow
                    }), children: (0, jsx_runtime_1.jsx)(material_1.TablePagination, { component: "div", sx: { p: 0 }, count: data.length, page: page, onPageChange: (_e, page) => setPage(page), rowsPerPage: rowsPerPage, onRowsPerPageChange: handleChangeRowsPerPage, backIconButtonProps: { "aria-label": "previous page" }, nextIconButtonProps: { "aria-label": "next page" } }) })] })) }));
}
exports.DataTable = DataTable;
