import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NewSorting, Query } from "james/search/query";
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import {
  FinancialCouponPaymentViewModel,
  FinancialCouponPaymentViewUpdater,
  useFinancialCouponPaymentViewReaderRead,
} from "james/views/financialCouponPaymentView";
import {
  AllCouponPaymentStates,
  CouponPaymentState,
} from "james/financial/CouponPayment";
import {
  TextExactCriterion,
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { BPTable } from "components/Table";
import { CouponPaymentStateChip } from "./Chips";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  FaceOutlined,
  OpenInNew as OpenInNewIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { TextField } from "components/FormFields";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import dayjs from "dayjs";
import { Amount } from "components/Ledger/Amount";
import { useErrorContext } from "context/Error";
import Box from "@mui/material/Box";
import { CouponPaymentSynchroniser } from "james/financial/CouponPaymentSynchroniser";
import { CouponPaymentStateController } from "james/financial/CouponPaymentStateController";
import { formatTextNum } from "utilities/number";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { NoDataCard } from "views/InstrumentBuilder/v2/components/PrimaryMarket";

const PREFIX = "CouponPaymentTable";

const classes = {
  textSearchField: `${PREFIX}-textSearchField`,
  iconButton: `${PREFIX}-iconButton`,
  statusSelectFilterField: `${PREFIX}-statusSelectFilterField`,
  row: `${PREFIX}-row`,
};

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("sequenceNumber", "asc")],
});

export type CouponPaymentTableProps = {
  system: boolean;
  height: number;
  bondID?: string;
  hideColumns?: string[];
  showEmptyCard?: boolean;
  style?: SxProps<Theme>;
};

export function CouponPaymentTable(props: CouponPaymentTableProps) {
  const { viewConfiguration, authContext } = useApplicationContext();
  const { errorContextDefaultErrorFeedback, errorContextErrorTranslator } =
    useErrorContext();
  const [fullUpdateLoading, setFullUpdateLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  const startingCriteria = useMemo(
    () => (props.bondID ? { bondID: TextExactCriterion(props.bondID) } : {}),
    [],
  );
  const {
    readResponse,
    loading: readCouponPaymentViewLoading,
    setReadRequest,
    readRequest,
  } = useFinancialCouponPaymentViewReaderRead(
    {
      context: authContext,
      query: new Query(initialQuery),
      criteria: startingCriteria,
    },
    props.system,
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  const [couponPaymentStatusesCriterion, setCouponPaymentStatusesCriterion] =
    useState<CouponPaymentState[]>([]);
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [{ number: TextSubstringCriterion(textSearchCriterionTextField) }],
      });
    }
  }, [textSearchCriterionTextField]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = { ...startingCriteria };

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    if (couponPaymentStatusesCriterion.length) {
      criteria.state = TextListCriterion(couponPaymentStatusesCriterion);
    }

    setReadRequest({
      context: readRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [
    couponPaymentStatusesCriterion,
    textSearchCriterion,
    readRequest.context,
    setReadRequest,
    authContext,
  ]);

  const [syncInProgress, setSyncInProgress] = useState(false);
  const handleCorporateActionSynchronisation = useCallback(async () => {
    setSyncInProgress(true);
    try {
      if (!props.bondID) {
        return;
      }
      await CouponPaymentSynchroniser.SynchroniseCouponPaymentsForBond({
        context: authContext,
        bondID: props.bondID,
      });
      setReadRequest({ ...readRequest });
      enqueueSnackbar("Synchronisation Complete", {
        variant: "success",
      });
    } catch (e) {
      errorContextDefaultErrorFeedback(
        e,
        "Error Synchronising Coupon Payments For Bond",
      );
    }
    setSyncInProgress(false);
  }, [props.bondID, readRequest]);

  const [fundingInProgress, setFundingInProgress] = useState(false);
  const handleFund = useCallback(
    async (couponPaymentID: string) => {
      setFundingInProgress(true);
      try {
        await CouponPaymentStateController.FundCouponPayment({
          context: authContext,
          couponPaymentID,
        });
        enqueueSnackbar("Funding Started", { variant: "success" });
      } catch (e) {
        console.error(`error performing funding`, e);
        errorContextDefaultErrorFeedback(e, "Funding Failed");
      }
      setFundingInProgress(false);
    },
    [authContext],
  );

  const [selectedCouponPaymentViewModel, setSelectedCouponPaymentViewModel] =
    useState<FinancialCouponPaymentViewModel | undefined>(undefined);
  const [
    couponPaymentStateResolutionInProgress,
    setCouponPaymentStateResolutionInProgress,
  ] = useState(false);
  const handleResolveCouponPaymentState = useCallback(async () => {
    setCouponPaymentStateResolutionInProgress(true);
    try {
      if (!selectedCouponPaymentViewModel) {
        return;
      }
      await CouponPaymentStateController.ResolveCouponPaymentState({
        context: authContext,
        couponPaymentID: selectedCouponPaymentViewModel.couponPaymentID,
        manualInvocation: true,
      });
      enqueueSnackbar("State Resolved", { variant: "success" });
    } catch (e) {
      errorContextDefaultErrorFeedback(
        e,
        "Error Resolving Coupon Payment State",
      );
    }
    setCouponPaymentStateResolutionInProgress(false);
  }, [authContext, selectedCouponPaymentViewModel]);

  const loading =
    readCouponPaymentViewLoading ||
    fullUpdateLoading ||
    syncInProgress ||
    fundingInProgress ||
    couponPaymentStateResolutionInProgress;

  return (
    <>
      {readResponse.models.length === 0 &&
      props.showEmptyCard &&
      !couponPaymentStatusesCriterion.length &&
      !readCouponPaymentViewLoading &&
      textSearchCriterionTextField === "" ? (
        <NoDataCard
          title={"No Coupon Payments?"}
          subTitle={[
            "You will see a list once the instrument",
            "has been pre-issued",
          ]}
        />
      ) : (
        <BPTable
          singleSelect
          loading={loading}
          style={props.style}
          height={props.height - noticeBannerHeight}
          title={"Coupon Payments"}
          data={readResponse.models}
          onSingleSelectChange={(data) =>
            setSelectedCouponPaymentViewModel(
              data as FinancialCouponPaymentViewModel,
            )
          }
          columns={(() => {
            const columns = [
              {
                label: "Number",
                field: "number",
              },
              {
                label: "Seq.",
                field: "sequenceNumber",
              },
              {
                label: "Nominal",
                field: "nominalAmount.value.float",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [p: string]: any }) => {
                  return (
                    <Amount
                      amount={
                        (data as FinancialCouponPaymentViewModel).nominalAmount
                      }
                      formatTextNumOpts={{
                        noDecimalPlaces: 7,
                        addDecimalPadding: false,
                      }}
                    />
                  );
                },
              },
              {
                label: "Interest",
                field: "interestAmount.value.float",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [p: string]: any }) => {
                  return (
                    <Amount
                      amount={
                        (data as FinancialCouponPaymentViewModel).interestAmount
                      }
                      formatTextNumOpts={{
                        noDecimalPlaces: 7,
                        addDecimalPadding: false,
                      }}
                    />
                  );
                },
              },
              {
                label: "Fee",
                field: "feeAmount.value.float",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [p: string]: any }) => {
                  return (
                    <Amount
                      amount={
                        (data as FinancialCouponPaymentViewModel).feeAmount
                      }
                      formatTextNumOpts={{
                        noDecimalPlaces: 7,
                        addDecimalPadding: false,
                      }}
                    />
                  );
                },
              },
              {
                label: "Fix Date",
                field: "fixDate",
                minWidth: 180,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [key: string]: any }) =>
                  dayjs(
                    (data as FinancialCouponPaymentViewModel).fixDate,
                  ).format("DD/MM/YYYY HH:mm:ss"),
              },
              {
                label: "Payment Date",
                field: "paymentDate",
                minWidth: 180,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [key: string]: any }) =>
                  dayjs(
                    (data as FinancialCouponPaymentViewModel).paymentDate,
                  ).format("DD/MM/YYYY HH:mm:ss"),
              },
              {
                label: "State",
                field: "state",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [key: string]: any }) => {
                  return (
                    <CouponPaymentStateChip
                      state={(data as FinancialCouponPaymentViewModel).state}
                    />
                  );
                },
              },
              {
                label: "Rate",
                field: "rate.value.float",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [key: string]: any }) => {
                  return formatTextNum(
                    (data as FinancialCouponPaymentViewModel).rate,
                  );
                },
              },
            ];

            if (
              viewConfiguration?.["Corporate Action"]?.["Coupon Payment"]
                ?.Actions?.FundCouponPayment
            ) {
              columns.push({
                label: "",
                field: "",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [p: string]: any }) => {
                  if (
                    [
                      CouponPaymentState.Fixed,
                      CouponPaymentState.FundingFailed,
                    ].includes(
                      (data as FinancialCouponPaymentViewModel)
                        .state as CouponPaymentState,
                    )
                  ) {
                    return (
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        children={"Fund"}
                        disabled={loading}
                        onClick={() =>
                          handleFund(
                            (data as FinancialCouponPaymentViewModel)
                              .couponPaymentID,
                          )
                        }
                      />
                    );
                  }
                  return <Box />;
                },
              });
            }

            if (props.system) {
              columns.push({
                field: "",
                label: "",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [key: string]: any }) => (
                  <Tooltip
                    placement="top"
                    title="View coupon payment detail in new tab"
                  >
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                          `${
                            window.location.origin
                          }/market-management/corporate-action/coupon-payment/view?id=${
                            (data as FinancialCouponPaymentViewModel).id
                          }`,
                          "_blank",
                        );
                      }}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>
                ),
              });
            }

            const filteredCols = props.hideColumns ?? [];

            return columns.filter((c) => !filteredCols.includes(c.label));
          })()}
          query={readRequest.query}
          onQueryChange={(query: Query) =>
            setReadRequest({
              ...readRequest,
              query,
            })
          }
          totalNoRecords={readResponse.total}
          toolBarControls={(() => {
            const controls: React.ReactNode[] = [];

            if (
              !!props.bondID &&
              viewConfiguration?.["Corporate Action"]?.["Coupon Payment"]
                ?.Actions?.SynchroniseCouponPaymentsForBond
            ) {
              controls.push(
                <Button
                  disabled={loading}
                  variant={"contained"}
                  color={"primary"}
                  children={"Synchronise"}
                  onClick={handleCorporateActionSynchronisation}
                />,
              );
            }

            if (
              !!selectedCouponPaymentViewModel &&
              viewConfiguration?.["Corporate Action"]?.["Coupon Payment"]
                ?.Actions?.ResolveCouponPaymentState
            ) {
              controls.push(
                <Button
                  disabled={loading}
                  variant={"contained"}
                  color={"primary"}
                  children={"Resolve State"}
                  onClick={handleResolveCouponPaymentState}
                />,
              );
            }

            if (
              viewConfiguration?.["Corporate Action"]?.["Coupon Payment"]
                ?.ViewModel?.FullUpdate
            ) {
              controls.push(
                <Button
                  disabled={loading}
                  variant={"contained"}
                  color={"primary"}
                  children={"Full Update"}
                  onClick={async () => {
                    setFullUpdateLoading(true);
                    try {
                      await FinancialCouponPaymentViewUpdater.FullUpdate({
                        context: authContext,
                      });
                      setReadRequest({ ...readRequest });
                      enqueueSnackbar("Full Update Complete", {
                        variant: "success",
                      });
                    } catch (e) {
                      const err = errorContextErrorTranslator.translateError(e);
                      console.error(
                        `error performing full update: ${
                          err.message ? err.message : err.toString()
                        }`,
                      );
                      enqueueSnackbar(
                        `Error Performing Full Update: ${
                          err.message ? err.message : err.toString()
                        }`,
                        { variant: "error" },
                      );
                    }
                    setFullUpdateLoading(false);
                  }}
                />,
              );
            }

            controls.push(
              <Tooltip title={"Refresh"}>
                <span>
                  <IconButton
                    onClick={() =>
                      setReadRequest({
                        ...readRequest,
                        query: new Query(initialQuery),
                      })
                    }
                    id={"financialCouponPaymentsTable-refresh-iconButton"}
                    disabled={loading}
                    size={"small"}
                  >
                    <ReloadIcon />
                  </IconButton>
                </span>
              </Tooltip>,
            );

            return controls;
          })()}
          filters={[
            <TextField
              id={"financialCouponPaymentsTable-textFilter-textField"}
              variant={"outlined"}
              margin={"dense"}
              className={classes.textSearchField}
              label={"Search Coupon Number"}
              placeholder={"Start Typing..."}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: textSearchCriterionTextField ? (
                  <InputAdornment
                    position={"end"}
                    children={
                      <IconButton
                        id={
                          "financialCouponPaymentsTable-textFilterClearButton-iconButton"
                        }
                        size={"small"}
                        onClick={() => setTextSearchCriterionTextField("")}
                      >
                        <ClearIcon />
                      </IconButton>
                    }
                  />
                ) : undefined,
              }}
              value={textSearchCriterionTextField}
              onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
            />,
            <Autocomplete
              sx={{
                minWidth: 220,
                width: "50%",
              }}
              isOptionEqualToValue={(option, value) => option === value}
              id={"financialCouponPaymentsTable-stateFilter-autocomplete"}
              disabled={loading}
              multiple
              options={AllCouponPaymentStates}
              filterSelectedOptions
              onChange={(_, value: CouponPaymentState[]) =>
                setCouponPaymentStatusesCriterion(value)
              }
              ChipProps={{
                color: "info",
                size: "small",
                deleteIcon: (
                  <CancelIcon
                    sx={(theme) => ({
                      color: `${theme.palette.text.secondary} !important`,
                      "&:hover": {
                        color: `${theme.palette.secondary.contrastText} !important`,
                      },
                    })}
                  />
                ),
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={
                    "financialCouponPaymentsTable-stateFilter-autocompleteTextField"
                  }
                  className={classes.statusSelectFilterField}
                  label={"State"}
                  variant={"outlined"}
                  margin={"dense"}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  placeholder={
                    couponPaymentStatusesCriterion.length
                      ? undefined
                      : "Select..."
                  }
                />
              )}
            />,
          ]}
          noDataSplashComponent={
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaceOutlined
                sx={(theme) => ({
                  fontSize: 110,
                  color: alpha(theme.palette.background.default, 0.5),
                })}
              />
              <Typography
                variant="h4"
                sx={(theme) => ({
                  fontWeight: "bold",
                  color: theme.palette.text.tertiary,
                  margin: theme.spacing(2, 0),
                })}
                children={
                  !couponPaymentStatusesCriterion.length &&
                  textSearchCriterionTextField === ""
                    ? "No Coupon Payments Listed?"
                    : "No Coupon Payments Found"
                }
              />
              <Typography
                variant="h5"
                sx={{
                  width: "226px",
                  color: (theme) => theme.palette.text.disabled,
                  textAlign: "center",
                }}
                children={
                  !couponPaymentStatusesCriterion.length &&
                  textSearchCriterionTextField === ""
                    ? "You will see a list once the instrument has been pre-issued"
                    : "Please try using different search criteria"
                }
              />
            </Box>
          }
        />
      )}
    </>
  );
}
