import React from "react";
import { RouteType } from "routes/Route";
import {
  Assignment as MarketSubscriptionIcon,
  Assignment as InstrumentsIcon,
  Dashboard as DashboardIcon,
  Storefront as StorefrontIcon,
} from "@mui/icons-material";
import config from "react-global-configuration";
import { InstrumentsView } from "views/InstrumentBuilder/Instruments";
import { MarketSubscriptions } from "views/InstrumentBuilder/MarketSubscription";
import { PrimaryMarket } from "views/InstrumentBuilder/PrimaryMarket";
import { SmartInstruments } from "views/InstrumentBuilder/SmartInstruments";
import { ViewConfiguration } from "james/configuration";
import { InstrumentBuilderDashboard } from "views/Dashboard/InstrumentBuilderDashboard";
import { UserProfileView } from "views/UserProfile";
import { CompanyProfileView } from "views/CompanyProfile";
import { atLeastOneTrueFoundInViewConfigObject } from "routes/private/administrationRoutes";
import isObject from "lodash/isObject";
import { useLocalStorage } from "hooks/persistentStateStore/useLocalStorage";
import { Marketing } from "views/InstrumentBuilder/Marketing";
import { Environment } from "const";

interface InstrumentBuilderRoutesToReturn {
  homeRoute?: RouteType;
  otherRoutes: RouteType[];
  userProfileRoute: RouteType;
  clientProfileRoute?: RouteType;
}

export const instrumentBuilderSideBarRoutes: RouteType[] = [
  {
    name: "Instruments",
    id: "instruments",
    path: "/builder/instruments",
    icon: InstrumentsIcon,
    component: <InstrumentsView />,
    allowSubPaths: true,
  },
  {
    name: "Subscriptions",
    id: "subscriptions",
    path: "/builder/subscriptions",
    icon: MarketSubscriptionIcon,
    component: <MarketSubscriptions />,
    allowSubPaths: true,
  },
  {
    name: "Smart Instruments",
    id: "smartInstruments",
    path: "/builder/smart-instruments",
    icon: MarketSubscriptionIcon,
    component: <SmartInstruments />,
    allowSubPaths: true,
  },
  {
    name: "Primary Market",
    id: "primary market",
    path: "/builder/primary-market",
    icon: MarketSubscriptionIcon,
    component: <PrimaryMarket />,
    allowSubPaths: true,
  },
  {
    name: "Marketing",
    id: "marketing",
    path: "/builder/marketing",
    icon: StorefrontIcon,
    component: <Marketing />,
    allowSubPaths: true,
  },
];
export const InstrumentBuilderRouteBuilder: (
  viewConfiguration: ViewConfiguration,
) => InstrumentBuilderRoutesToReturn = (
  viewConfiguration: ViewConfiguration,
) => {
  const { getStore } = useLocalStorage();
  const smartInstrumentsSecretValue = config.get("smartInstrumentsSecret");
  const environment = config.get("environment");
  const routesToReturn: InstrumentBuilderRoutesToReturn = {
    homeRoute: {
      name: "Dashboard",
      id: "builder",
      path: "/builder",
      icon: DashboardIcon,
      component: <InstrumentBuilderDashboard />,
    },

    userProfileRoute: {
      name: "User Profile",
      id: "user-profile",
      path: "/builder/user-profile",
      icon: DashboardIcon,
      component: <UserProfileView />,
    },

    // the rest of the routes will be built considering the viewConfiguration
    clientProfileRoute: undefined,
    otherRoutes: [],
  };

  // conditionally set company profile route
  if (viewConfiguration["Edit Client Profile"]) {
    routesToReturn.clientProfileRoute = {
      name: "Company profile",
      id: "company-profile",
      path: "/builder/company-profile",
      icon: DashboardIcon,
      component: <CompanyProfileView />,
    };
  }

  // for every sidebar route...
  instrumentBuilderSideBarRoutes.forEach((route) => {
    // if the view configuration has an entry with the name of the route
    // hide smart instruments (need to also have the permission to use it)
    if (route.name === "Smart Instruments" || route.name === "Marketing") {
      // only if the secret is set should the route be added
      if (
        [
          Environment.Development,
          Environment.Testing,
          Environment.Local,
        ].includes(environment) ||
        getStore().smartInstrumentsSecret === smartInstrumentsSecretValue
      ) {
        routesToReturn.otherRoutes.push(route);
      }
    } else if (viewConfiguration[route.name]) {
      // and if the entry is an object
      if (isObject(viewConfiguration[route.name])) {
        // then check if at least one true is found with the embedded rules
        if (
          atLeastOneTrueFoundInViewConfigObject(viewConfiguration[route.name])
        ) {
          // then add the entry
          routesToReturn.otherRoutes.push(route);
        }
      } else {
        // if it is not an object then assume it to be a boolean
        if (viewConfiguration[route.name]) {
          routesToReturn.otherRoutes.push(route);
        }
      }
    }
  });

  return routesToReturn;
};
