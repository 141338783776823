export enum DayCountConvention {
  ActualOvr365Fixed = "Actual/365 (Fixed)",
  ActualOvr360 = "Actual/360",
  ActualOvr364 = "Actual/364",
  ActualOvr366 = "Actual/366",
  ActualOvrActualISMA = "Actual/Actual (ISMA)",
  _30Ovr360ISMA = "30/360 (ISMA)",
  _30Ovr365 = "30/365",
  ActualOvr36525 = "Actual/365.25",
}

export const allDayCountConventions: DayCountConvention[] = [
  DayCountConvention.ActualOvr365Fixed,
  DayCountConvention.ActualOvr360,
  DayCountConvention.ActualOvr364,
  DayCountConvention.ActualOvr366,
  DayCountConvention.ActualOvrActualISMA,
  DayCountConvention._30Ovr360ISMA,
  DayCountConvention._30Ovr365,
  DayCountConvention.ActualOvr36525,
];
