import { useEffect } from "react";
import { useErrorContext } from "context/Error";
import { Client } from "james/client";
import { useApplicationContext } from "context/Application/Application";
import { useBankingDetailsViewStore } from "../store";
import { BankAccountReader } from "james/banking/bankAccountReader";
import { TextExactCriterion } from "james/search/criterion";

// custom hook to fetch bank account details when the component mounts.
export const useInitialDataFetch = (client: Client) => {
  const state = useBankingDetailsViewStore();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const { authContext } = useApplicationContext();

  useEffect(() => {
    // Guard clauses to early exit if no client or authContext is provided.
    if (!client) {
      return;
    }

    if (!authContext) {
      return;
    }

    state.setLoading(true);
    // IIFE to handle async operations within useEffect.
    (async () => {
      // Attempt to fetch bank account details using ClientDetailsAccessor.
      try {
        const response = await BankAccountReader.ReadBankAccountsUnscoped({
          context: authContext,
          criteria: {
            ownerID: TextExactCriterion(client.ownerID),
          },
        });

        if (response.total == 0) {
          state.setBankNotFound(true);
        } else {
          // Update local state with the fetched bank account details.
          state.updateStateWithBankAccount(response.bankAccounts[0]);
          state.setLastUpdateBy(response.bankAccounts[0].auditEntry.userID);
          state.setLastUpdateDate(response.bankAccounts[0].auditEntry.time);
          state.setCachedBankAccount(response.bankAccounts[0]);
        }
      } catch (e) {
        // Handle errors by translating and possibly setting UI-specific flags.
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error update client bank account details: ${
            err.message ? err.message : err.toString()
          }`,
        );
        errorContextDefaultErrorFeedback(e);
      }
      // Reset loading state regardless of outcome.
      state.setLoading(false);
    })();
  }, [client, authContext]);
};
