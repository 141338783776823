import { AuditEntry } from "james/audit";
import { IdentificationType } from "james/legal";
import {
  PersonContactDetails,
  SourceOfIncomeAndWealthType,
} from "james/legal/person";
import { Address } from "../../location";

export class Person {
  public id = "";

  public auditEntry: AuditEntry = new AuditEntry();

  public firstName = "";
  public middleNames = "";
  public lastName = "";

  public dateOfBirth = "";

  public nationality = "";

  public influentialPerson: InfluentialPersonStatus | "" = "";

  public identificationType: IdentificationType | "" = "";
  public identificationNumber = "";
  public identificationNumberExpiry = "";

  public contactDetails: PersonContactDetails = new PersonContactDetails();
  public residentialAddress: Address = new Address();

  public sourceOfWealth: SourceOfIncomeAndWealthType | "" = "";
  public sourceOfIncome: SourceOfIncomeAndWealthType | "" = "";

  constructor(person?: Person) {
    if (!person) {
      return;
    }
    this.id = person.id;
    this.auditEntry = new AuditEntry(person.auditEntry);
    this.firstName = person.firstName;
    this.middleNames = person.middleNames;
    this.lastName = person.lastName;
    this.nationality = person.nationality;
    this.identificationType = person.identificationType;
    this.identificationNumber = person.identificationNumber;
    this.identificationNumberExpiry = person.identificationNumberExpiry;
    this.contactDetails = new PersonContactDetails(person.contactDetails);
    this.dateOfBirth = person.dateOfBirth;
    this.residentialAddress = new Address(person.residentialAddress);
    this.sourceOfWealth = person.sourceOfWealth;
    this.sourceOfIncome = person.sourceOfIncome;
    this.influentialPerson = person.influentialPerson;
  }
}

export enum InfluentialPersonStatus {
  InfluentialPersonStatus = "Yes",
  NotInfluentialPersonStatus = "No",
}

export const AllPEPDeclarationStatuses: InfluentialPersonStatus[] = [
  InfluentialPersonStatus.InfluentialPersonStatus,
  InfluentialPersonStatus.NotInfluentialPersonStatus,
];
