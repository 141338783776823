export const ManagingCompanyClientName = "Mesh B.V.";
export const UserAuthenticated = "UserAuthenticated";
export const mZARTokenCode = "mZAR";
export enum Environment {
  Local = "Local",
  Development = "Development",
  Testing = "Testing",
  Staging = "Staging",
  Production = "Production",
}
export const smartInstrumentsSecret = "smartInstruments@mesh";
