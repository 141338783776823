import { CouponPaymentState } from "james/financial/CouponPayment";
import { Amount } from "james/ledger";
import dayjs from "dayjs";
import BigNumber from "bignumber.js";

export class Model {
  public id = "";
  public bondName = "";
  public couponPaymentID = "";
  public totalAmount: Amount = new Amount();
  // coupon payment fields
  public number = "";
  public holdingAccountLedgerID = "";
  public ownerID = "";
  public bondID = "";
  public state: CouponPaymentState | "" = "";
  public stateResolutionCount = 0;
  public feeAmount: Amount = new Amount();
  public sequenceNumber = 0;
  public paymentDate: string = dayjs().format();
  public accrualStartDate: string = dayjs().format();
  public accrualEndDate: string = dayjs().format();
  public referencePeriodStartDate: string = dayjs().format();
  public referencePeriodEndDate: string = dayjs().format();
  public fixDate: string = dayjs().format();
  public exCouponDate: string = dayjs().format();
  public recordDate: string = dayjs().format();
  public nominalAmount: Amount = new Amount();
  public interestAmount: Amount = new Amount();
  public rate: BigNumber = new BigNumber("0");

  constructor(l?: Model) {
    if (!l) {
      return;
    }
    this.id = l.id;
    this.couponPaymentID = l.couponPaymentID;
    this.bondName = l.bondName;
    this.totalAmount = new Amount(l.totalAmount);
    // coupon payment fields
    this.number = l.number;
    this.holdingAccountLedgerID = l.holdingAccountLedgerID;
    this.ownerID = l.ownerID;
    this.bondID = l.bondID;
    this.state = l.state;
    this.stateResolutionCount = l.stateResolutionCount;
    this.feeAmount = new Amount(l.feeAmount);
    this.sequenceNumber = l.sequenceNumber;
    this.paymentDate = l.paymentDate;
    this.accrualStartDate = l.accrualStartDate;
    this.accrualEndDate = l.accrualEndDate;
    this.referencePeriodStartDate = l.referencePeriodStartDate;
    this.referencePeriodEndDate = l.referencePeriodEndDate;
    this.fixDate = l.fixDate;
    this.exCouponDate = l.exCouponDate;
    this.recordDate = l.recordDate;
    this.nominalAmount = new Amount(l.nominalAmount);
    this.interestAmount = new Amount(l.interestAmount);
    this.rate = new BigNumber(l.rate);
  }
}
