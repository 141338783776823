import { WriteOperation, WriteOperationTypeName } from "./WriteOperation";
import { ReadOperation, ReadOperationTypeName } from "./ReadOperation";
import { Entry } from "../../james/audit/Entry";

export interface Operation {
  ["@type"]: string;

  operationID(): string;

  operationConflictSignatures(): string[];

  operationServiceURI(): string;

  operationAuditEntry(): Entry;
}

export function NewOperation(o: Operation): Operation {
  switch (o["@type"]) {
    case WriteOperationTypeName:
      return new WriteOperation(o as WriteOperation);

    case ReadOperationTypeName:
      return new ReadOperation(o as ReadOperation);

    default:
      throw new TypeError(`unknown operation type ${o["@type"]}`);
  }
}
