import { ValidationResult } from "common/validation";
import { SubscriptionOrderBook } from "james/market/SubscriptionOrderBook";
import dayjs, { Dayjs } from "dayjs";
import { Amount } from "james/ledger";
import BigNumber from "bignumber.js";
import { DigitalFixedRateBond, DigitalFloatingRateBond } from "james/financial";

export interface SubscriptionOrderBookFormData {
  asset: DigitalFixedRateBond | DigitalFloatingRateBond;
  subscriptionOrderBook: SubscriptionOrderBook;
}

export type FormUpdaterSpecsType = {
  subscriptionOrderBook: (
    subscriptionOrderBook: SubscriptionOrderBook,
    prevFormData?: SubscriptionOrderBookFormData,
  ) => SubscriptionOrderBookFormData;
  openDate: (
    openDate: Dayjs,
    prevFormData?: SubscriptionOrderBookFormData,
  ) => SubscriptionOrderBookFormData;
  closeDate: (
    closeDate: Dayjs,
    prevFormData?: SubscriptionOrderBookFormData,
  ) => SubscriptionOrderBookFormData;
  settlementDate: (
    settlementDate: Dayjs,
    prevFormData?: SubscriptionOrderBookFormData,
  ) => SubscriptionOrderBookFormData;
  subscriptionAmount: (
    overSubscriptionAmount: BigNumber,
    prevFormData?: SubscriptionOrderBookFormData,
  ) => SubscriptionOrderBookFormData;
  overSubscriptionAmount: (
    overSubscriptionAmount: BigNumber,
    prevFormData?: SubscriptionOrderBookFormData,
  ) => SubscriptionOrderBookFormData;
  unitPrice: (
    unitPrice: BigNumber,
    prevFormData?: SubscriptionOrderBookFormData,
  ) => SubscriptionOrderBookFormData;
};

export const formDataUpdaterSpecs: FormUpdaterSpecsType = {
  subscriptionOrderBook(
    subscriptionOrderBook: SubscriptionOrderBook,
    prevFormData?: SubscriptionOrderBookFormData,
  ): SubscriptionOrderBookFormData {
    const formData = prevFormData as SubscriptionOrderBookFormData;
    return {
      ...formData,
      subscriptionOrderBook: new SubscriptionOrderBook(subscriptionOrderBook),
    };
  },
  openDate(
    openDate: Dayjs,
    prevFormData?: SubscriptionOrderBookFormData,
  ): SubscriptionOrderBookFormData {
    const formData = prevFormData as SubscriptionOrderBookFormData;

    if (openDate === null) {
      return {
        ...formData,
        subscriptionOrderBook: new SubscriptionOrderBook(
          formData.subscriptionOrderBook,
        ),
      };
    }

    formData.subscriptionOrderBook.openDate = openDate.toString();

    return {
      ...formData,
      subscriptionOrderBook: new SubscriptionOrderBook(
        formData.subscriptionOrderBook,
      ),
    };
  },
  closeDate(
    closeDate: Dayjs,
    prevFormData?: SubscriptionOrderBookFormData,
  ): SubscriptionOrderBookFormData {
    const formData = prevFormData as SubscriptionOrderBookFormData;

    if (closeDate === null) {
      return {
        ...formData,
        subscriptionOrderBook: new SubscriptionOrderBook(
          formData.subscriptionOrderBook,
        ),
      };
    }

    formData.subscriptionOrderBook.closeDate = closeDate.toString();

    return {
      ...formData,
      subscriptionOrderBook: new SubscriptionOrderBook(
        formData.subscriptionOrderBook,
      ),
    };
  },
  settlementDate(
    settlementDate: Dayjs,
    prevFormData?: SubscriptionOrderBookFormData,
  ): SubscriptionOrderBookFormData {
    const formData = prevFormData as SubscriptionOrderBookFormData;
    formData.subscriptionOrderBook.settlementDate = settlementDate.toString();
    return {
      ...formData,
      subscriptionOrderBook: new SubscriptionOrderBook(
        formData.subscriptionOrderBook,
      ),
    };
  },
  subscriptionAmount(
    subscriptionAmount: BigNumber,
    prevFormData?: SubscriptionOrderBookFormData,
  ): SubscriptionOrderBookFormData {
    const formData = prevFormData as SubscriptionOrderBookFormData;
    formData.subscriptionOrderBook.subscriptionAmount = new Amount({
      value: subscriptionAmount,
      token: formData.subscriptionOrderBook.subscriptionAmount.token,
    });

    return {
      ...formData,
      subscriptionOrderBook: new SubscriptionOrderBook(
        formData.subscriptionOrderBook,
      ),
    };
  },
  overSubscriptionAmount(
    overSubscriptionAmount: BigNumber,
    prevFormData?: SubscriptionOrderBookFormData,
  ): SubscriptionOrderBookFormData {
    const formData = prevFormData as SubscriptionOrderBookFormData;
    formData.subscriptionOrderBook.overSubscriptionAmount = new Amount({
      value: overSubscriptionAmount,
      token: formData.subscriptionOrderBook.overSubscriptionAmount.token,
    });

    return {
      ...formData,
      subscriptionOrderBook: new SubscriptionOrderBook(
        formData.subscriptionOrderBook,
      ),
    };
  },
  unitPrice(
    unitPrice: BigNumber,
    prevFormData?: SubscriptionOrderBookFormData,
  ): SubscriptionOrderBookFormData {
    const formData = prevFormData as SubscriptionOrderBookFormData;
    formData.subscriptionOrderBook.unitPrice = new Amount({
      value: unitPrice,
      token: formData.subscriptionOrderBook.unitPrice.token,
    });

    return {
      ...formData,
      subscriptionOrderBook: new SubscriptionOrderBook(
        formData.subscriptionOrderBook,
      ),
    };
  },
};

export const formDataValidationFunc = async (
  formData: SubscriptionOrderBookFormData,
): Promise<ValidationResult> => {
  const validationResult: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  if (!dayjs(formData.subscriptionOrderBook.openDate).isValid()) {
    validationResult.valid = false;
    validationResult.fieldValidations.openDate =
      "Open date must be a valid date";
  }

  if (!dayjs(formData.subscriptionOrderBook.closeDate).isValid()) {
    validationResult.valid = false;
    validationResult.fieldValidations.closeDate =
      "Close date must be a valid date";
  }

  if (
    dayjs(formData.subscriptionOrderBook.openDate).isBefore(
      dayjs().add(1, "day").startOf("day"),
    )
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.openDate =
      "Open date must be in the future";
  }

  if (
    dayjs(formData.subscriptionOrderBook.closeDate).isBefore(
      dayjs().add(1, "day").startOf("day"),
    )
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.closeDate =
      "Close date must be in the future";
  }

  if (
    dayjs(formData.subscriptionOrderBook.closeDate)
      .endOf("day")
      .isBefore(dayjs(formData.subscriptionOrderBook.openDate).endOf("day")) ||
    dayjs(formData.subscriptionOrderBook.closeDate)
      .endOf("day")
      .isSame(dayjs(formData.subscriptionOrderBook.openDate).endOf("day"))
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.openDate =
      "Open date must be before close date";
  }

  if (
    dayjs(formData.subscriptionOrderBook.closeDate).isAfter(
      dayjs(formData.subscriptionOrderBook.settlementDate).endOf("day"),
    )
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.closeDate =
      "Close date must be before settlement date";
  }

  if (
    formData.subscriptionOrderBook.overSubscriptionAmount.value.isLessThanOrEqualTo(
      0,
    )
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.overSubscriptionAmount =
      "Over subscription amount must be greater than 0";
  }

  if (
    formData.subscriptionOrderBook.overSubscriptionAmount.value.isLessThan(
      formData.subscriptionOrderBook.subscriptionAmount.value,
    )
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.overSubscriptionAmount =
      "Over subscription amount must be greater than the target subscription amount";
  }

  // TODO: Discuss rules with Dorota/Christoff
  // if (
  //   formData.overSubscriptionAmount.isGreaterThan(
  //     formData.asset.nominal.value.multipliedBy(
  //       formData.listing.marketMechanisms[0].quoteParameters[0].maximumDealSize
  //         .value,
  //     ),
  //   )
  // ) {
  //   validationResult.valid = false;
  //   validationResult.fieldValidations.overSubscriptionAmount =
  //     "Over subscription amount must be less than the maximum deal size * nominal value";
  // }

  return validationResult;
};
