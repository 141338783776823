import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Clear } from "@mui/icons-material";
import { ConnectedCompany, LegalForm } from "james/legal/ConnectedCompany";
import { Address } from "james/location";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import camelCase from "lodash/camelCase";
import React, { ChangeEvent, useState } from "react";
import cx from "classnames";
import { countries } from "james/country";
import {
  FormFieldsValidation,
  FormValidationState,
  FormValidator,
} from "./Validation";
import { CountryDropdown } from "components/FormFields/CountryDropdown";
import { CompanyRepresentative } from "james/legal";
const PREFIX = "NonConnectedIndividualDialog";

const classes = {
  dialogPaperOverride: `${PREFIX}-dialogPaperOverride`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  dialogContent: `${PREFIX}-dialogContent`,
  sectionHeading: `${PREFIX}-sectionHeading`,
  sectionWithColumns1Gap: `${PREFIX}-sectionWithColumns1Gap`,
  sectionHelperText: `${PREFIX}-sectionHelperText`,
  sectionWith2EqColumns2Gap: `${PREFIX}-sectionWith2EqColumns2Gap`,
  addressWithCopyTitleLayout: `${PREFIX}-addressWithCopyTitleLayout`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogPaperOverride}`]: {
    backgroundColor: theme.palette.custom.midnight,
  },

  [`& .${classes.dialogTitle}`]: {
    padding: theme.spacing(1, 3),
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.dialogContent}`]: {
    padding: theme.spacing(2, 3),
    display: "grid",
    gridTemplateColumns: "450px 450px",
    columnGap: theme.spacing(8),
    rowGap: theme.spacing(2.5),
  },

  [`& .${classes.sectionHeading}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.sectionWithColumns1Gap}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },

  [`& .${classes.sectionHelperText}`]: {
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.sectionWith2EqColumns2Gap}`]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
  },

  [`& .${classes.addressWithCopyTitleLayout}`]: {
    margin: theme.spacing(-0.8, 0),
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
  },
}));

interface ConnectedIndividualProps {
  open: boolean;
  onClose: () => void;
  entity?: ConnectedCompany;
  onChange?: (individual: ConnectedCompany) => void;
}

export const NonConnectedIndividualDialog: React.FC<
  ConnectedIndividualProps
> = (props: ConnectedIndividualProps) => {
  const { open, onClose, entity, onChange } = props;
  const [nonIndividual, setNonIndividual] = React.useState<ConnectedCompany>(
    entity
      ? new ConnectedCompany(entity)
      : new ConnectedCompany({
          ...new ConnectedCompany(),
          businessAddress: new Address(),
        } as ConnectedCompany),
  );
  const [toggle, setToggle] = React.useState(false);
  const [invalidFormFields, setInvalidFormFields] = useState<{
    [key: string]: string | undefined;
  }>({});
  const [postalAddress, setPostalAddress] = React.useState<Address>(
    new Address(entity ? entity.businessAddress : undefined),
  );
  const [representative, setRepresentative] =
    React.useState<CompanyRepresentative>(
      new CompanyRepresentative(
        entity ? entity.companyRepresentative : undefined,
      ),
    );

  const onUpdate = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    updateState(name, event.target.value);
  };

  const autoCompleteOnUpdate =
    (name: string) =>
    (
      __: React.SyntheticEvent<Element, Event>,
      countryOption: { value: string; label: string } | null,
    ) => {
      updateState(name, countryOption ? countryOption.value : "");
    };

  const updateState = (name: string, value: string) => {
    const keys = name.split(".") as (keyof ConnectedCompany)[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newIndividual: { [index: string]: any } = nonIndividual;
    const newPostalAddress = postalAddress;
    const newRepresentative = representative;

    switch (keys.length) {
      case 1:
        newIndividual[keys[0]] = value;
        break;
      case 2:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (newIndividual[keys[0]] as any)[keys[1]] = value;
        if (keys[0] === "companyRepresentative") {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (newRepresentative as any)[`${[keys[1]]}`] = value;
        }

        if (keys[0] === "businessAddress") {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (newPostalAddress as any)[`${[keys[1]]}`] = value;
          newIndividual.bussinessAddress = newPostalAddress as Address;
        }
        break;
      default:
        console.error("unable to update company representative");
    }
    InlineFormValidation(camelCase(name), value)();
    setNonIndividual(newIndividual as ConnectedCompany);
    setPostalAddress(new Address(newPostalAddress));
    setRepresentative(new CompanyRepresentative(newRepresentative));
    setToggle(!toggle);
  };

  const InlineFormValidation = (field: string, value: string) =>
    debounce(() => {
      const errors = FormValidator(field, value);
      let InvalidFormField = { ...invalidFormFields };

      if (
        nonIndividual.companyRepresentative.emailAddress !== "" ||
        nonIndividual.companyRepresentative.cellphoneNumber !== "" ||
        nonIndividual.companyRepresentative.telephoneNumber !== ""
      ) {
        delete InvalidFormField.companyRepresentativeEmailAddress;
        delete InvalidFormField.companyRepresentativeCellphoneNumber;
        delete InvalidFormField.companyRepresentativeTelephoneNumber;
      }

      if (
        isEqual(
          new Address(nonIndividual.companyRepresentative.postalAddress),
          new Address(),
        )
      ) {
        delete InvalidFormField.postalAddressCountryCode;
        delete InvalidFormField.postalAddressCity;
        delete InvalidFormField.postalAddressAddressLine1;
      }

      if (errors.length !== 0) {
        InvalidFormField = { ...InvalidFormField, [field]: errors.join("; ") };
      } else {
        delete InvalidFormField[field];
      }
      setInvalidFormFields({ ...InvalidFormField });
    }, 600);

  const onUpdateIndividual = () => {
    // Validation
    if (
      FormFieldsValidation(
        invalidFormFields,
        nonIndividual,
        setInvalidFormFields,
      )
    ) {
      if (onChange) {
        onChange(nonIndividual);
      }
      close();
    }
  };

  const close = () => {
    onClose();
    setInvalidFormFields({ ...FormValidationState });
  };

  const onClear = () => {
    setNonIndividual(new ConnectedCompany());
  };

  const onAddIndividual = () => {
    // Validation Needed
    if (
      FormFieldsValidation(
        invalidFormFields,
        nonIndividual,
        setInvalidFormFields,
      )
    ) {
      if (onChange) {
        onChange({ ...nonIndividual, id: String(Math.random() * 100) });
      }
      close();
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={close}
      maxWidth="lg"
      classes={{ paper: classes.dialogPaperOverride }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h5">New Connected Entity</Typography>
        <div className={classes.sectionWithColumns1Gap}>
          {!entity && (
            <Button
              onClick={onAddIndividual}
              variant="contained"
              color="primary"
              id="connectedNonIndividual-add"
            >
              Add Entity
            </Button>
          )}
          {entity && (
            <Button onClick={onClear} variant="outlined">
              Clear Info
            </Button>
          )}
          {entity && (
            <Button
              variant="contained"
              color="primary"
              onClick={onUpdateIndividual}
            >
              Update Info
            </Button>
          )}
          <IconButton
            id="connectedNonIndividual-closeDialog"
            onClick={close}
            size="small"
          >
            <Icon>
              <Clear />
            </Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={cx(classes.dialogContent, "meshScroll")}>
        {/* ----Company Details ---- */}
        <div>
          <Typography
            className={classes.sectionHeading}
            variant="h6"
            children="Company Details"
          />
          <TextField
            id="connectedNonIndividual-companyDetails-businessName"
            variant="outlined"
            fullWidth
            label="Name"
            value={nonIndividual.businessName}
            error={Boolean(invalidFormFields.businessName)}
            helperText={invalidFormFields.businessName}
            onChange={onUpdate("businessName")}
          />
          <TextField
            variant="outlined"
            fullWidth
            id="connectedNonIndividual-companyDetails-registeredName"
            label="Registered Name"
            value={nonIndividual.registeredName}
            error={Boolean(invalidFormFields.registeredName)}
            helperText={invalidFormFields.registeredName}
            onChange={onUpdate("registeredName")}
          />
          <TextField
            variant="outlined"
            fullWidth
            margin="dense"
            id="connectedNonIndividual-companyDetails-registrationNumber"
            label="Reg. Number"
            value={nonIndividual.registrationNumber}
            error={Boolean(invalidFormFields.registrationNumber)}
            helperText={invalidFormFields.registrationNumber}
            onChange={onUpdate("registrationNumber")}
            placeholder="YYYY/MMMMMM/NN"
          />
          <TextField
            select
            id="connectedNonIndividual-companyDetails-legalForm"
            variant="outlined"
            fullWidth
            margin="dense"
            label="Legal Form"
            value={nonIndividual.legalForm}
            error={Boolean(invalidFormFields.legalForm)}
            helperText={invalidFormFields.legalForm}
            onChange={onUpdate("legalForm")}
          >
            <MenuItem
              id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                LegalForm.SoleProprietorShipLegalForm,
              )}`}
              value={LegalForm.SoleProprietorShipLegalForm}
            >
              {LegalForm.SoleProprietorShipLegalForm}
            </MenuItem>
            <MenuItem
              id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                LegalForm.CloseCorporationLegalForm,
              )}`}
              value={LegalForm.CloseCorporationLegalForm}
            >
              {LegalForm.CloseCorporationLegalForm}
            </MenuItem>
            <MenuItem
              id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                LegalForm.SouthAfricanCompanyLegalForm,
              )}`}
              value={LegalForm.SouthAfricanCompanyLegalForm}
            >
              {LegalForm.SouthAfricanCompanyLegalForm}
            </MenuItem>
            <MenuItem
              id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                LegalForm.ForeignCompanyLegalForm,
              )}`}
              value={LegalForm.ForeignCompanyLegalForm}
            >
              {LegalForm.ForeignCompanyLegalForm}
            </MenuItem>
            <MenuItem
              id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                LegalForm.ListedCompanyLegalForm,
              )}`}
              value={LegalForm.ListedCompanyLegalForm}
            >
              {LegalForm.ListedCompanyLegalForm}
            </MenuItem>
            <MenuItem
              id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                LegalForm.PartnershipLegalForm,
              )}`}
              value={LegalForm.PartnershipLegalForm}
            >
              {LegalForm.PartnershipLegalForm}
            </MenuItem>
            <MenuItem
              id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                LegalForm.TrustLegalForm,
              )}`}
              value={LegalForm.TrustLegalForm}
            >
              {LegalForm.TrustLegalForm}
            </MenuItem>
            <MenuItem
              id={`connectedNonIndividual-companyDetails-legalForm-${camelCase(
                LegalForm.OtherLegalForm,
              )}`}
              value={LegalForm.OtherLegalForm}
            >
              {LegalForm.OtherLegalForm}
            </MenuItem>
          </TextField>
        </div>

        {/* ---- Representative Details ---- */}
        <div>
          <Typography
            className={classes.sectionHeading}
            variant="h6"
            children="Representative Details"
          />
          <div className={classes.sectionWith2EqColumns2Gap}>
            <TextField
              id="connectedNonIndividual-representativeContactDetails-firstName"
              variant="outlined"
              fullWidth
              label="Name"
              margin="dense"
              value={nonIndividual.companyRepresentative.firstName}
              onChange={onUpdate("companyRepresentative.firstName")}
              error={Boolean(invalidFormFields.companyRepresentativeFirstName)}
              helperText={invalidFormFields.companyRepresentativeFirstName}
            />
            <TextField
              id="connectedNonIndividual-representativeContactDetails-middleName"
              variant="outlined"
              fullWidth
              label="Middle Names"
              margin="dense"
              placeholder="Optional"
              value={nonIndividual.companyRepresentative.middleNames}
              onChange={onUpdate("companyRepresentative.middleNames")}
            />
            <TextField
              id="connectedNonIndividual-representativeContactDetails-lastName"
              variant="outlined"
              fullWidth
              label="Surname"
              margin="dense"
              value={nonIndividual.companyRepresentative.lastName}
              onChange={onUpdate("companyRepresentative.lastName")}
              error={Boolean(invalidFormFields.companyRepresentativeLastName)}
              helperText={invalidFormFields.companyRepresentativeLastName}
            />
          </div>
          <Typography variant="body2" className={classes.sectionHelperText}>
            You are required to enter at least one of the following.
          </Typography>
          <TextField
            id="connectedNonIndividual-representativeContactDetails-emailAddress"
            variant="outlined"
            fullWidth
            label="Email"
            margin="dense"
            value={nonIndividual.companyRepresentative.emailAddress}
            onChange={onUpdate("companyRepresentative.emailAddress")}
            error={Boolean(invalidFormFields.companyRepresentativeEmailAddress)}
            helperText={invalidFormFields.companyRepresentativeEmailAddress}
          />
          <div className={classes.sectionWith2EqColumns2Gap}>
            <TextField
              id="connectedNonIndividual-representativeContactDetails-cellphoneNumber"
              variant="outlined"
              fullWidth
              label="Mobile"
              margin="dense"
              value={nonIndividual.companyRepresentative.cellphoneNumber}
              error={Boolean(
                invalidFormFields.companyRepresentativeCellphoneNumber,
              )}
              helperText={
                invalidFormFields.companyRepresentativeCellphoneNumber
              }
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                if (
                  /^[+]?[0-9]{0,15}$/.test(String(value)) ||
                  value.length === 0
                ) {
                  onUpdate("companyRepresentative.cellphoneNumber")(event);
                }
              }}
            />
            <TextField
              variant="outlined"
              fullWidth
              id="connectedNonIndividual-representativeContactDetails-telephoneNumber"
              label="Telephone"
              margin="dense"
              value={nonIndividual.companyRepresentative.telephoneNumber}
              error={Boolean(
                invalidFormFields.companyRepresentativeTelephoneNumber,
              )}
              helperText={
                invalidFormFields.companyRepresentativeTelephoneNumber
              }
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                if (
                  /^[+]?[0-9]{0,15}$/.test(String(value)) ||
                  value.length === 0
                ) {
                  onUpdate("companyRepresentative.telephoneNumber")(event);
                }
              }}
            />
          </div>
        </div>

        {/* ---- Registered Address ---- */}
        <div>
          <Typography
            className={classes.sectionHeading}
            variant="h6"
            children="Operating Address"
          />
          {nonIndividual.businessAddress && (
            <>
              <CountryDropdown
                id="connectedNonIndividual-businessAddress-country"
                isOptionEqualToValue={(option, value) => {
                  if (value.value === "") {
                    return true;
                  }
                  return option === value;
                }}
                onChange={autoCompleteOnUpdate("businessAddress.countryCode")}
                value={
                  nonIndividual.businessAddress.countryCode === ""
                    ? {
                        value: "",
                        label: "",
                      }
                    : {
                        value: nonIndividual.businessAddress.countryCode,
                        label: countries.filter(
                          (c) =>
                            c.value ===
                            new Address(nonIndividual.businessAddress)
                              .countryCode,
                        )[0].label,
                      }
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Country"
                    InputProps={{
                      ...params.InputProps,
                      placeholder: "Select...",
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    error={Boolean(
                      invalidFormFields.businessAddressCountryCode,
                    )}
                    fullWidth
                    helperText={invalidFormFields.businessAddressCountryCode}
                  />
                )}
              />
              <TextField
                id="connectedNonIndividual-businessAddress-addressLine1"
                variant="outlined"
                fullWidth
                label="Address 1"
                margin="dense"
                value={nonIndividual.businessAddress.addressLine1}
                onChange={onUpdate("businessAddress.addressLine1")}
                error={Boolean(invalidFormFields.businessAddressAddressLine1)}
                helperText={invalidFormFields.businessAddressAddressLine1}
              />
              <TextField
                id="connectedNonIndividual-businessAddress-addressLine2"
                variant="outlined"
                fullWidth
                margin="dense"
                placeholder="Optional"
                label="Address 2"
                value={nonIndividual.businessAddress.addressLine2}
                onChange={onUpdate("businessAddress.addressLine2")}
              />
              <div className={classes.sectionWith2EqColumns2Gap}>
                <TextField
                  id="connectedNonIndividual-businessAddress-suburb"
                  variant="outlined"
                  fullWidth
                  label="Suburb"
                  margin="dense"
                  value={nonIndividual.businessAddress.suburb}
                  onChange={onUpdate("businessAddress.suburb")}
                  error={Boolean(invalidFormFields.businessAddressSuburb)}
                  helperText={invalidFormFields.businessAddressSuburb}
                />
                <TextField
                  id="connectedNonIndividual-businessAddress-city"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  label="City"
                  value={nonIndividual.businessAddress.city}
                  onChange={onUpdate("businessAddress.city")}
                  error={Boolean(invalidFormFields.businessAddressSuburb)}
                  helperText={invalidFormFields.businessAddressSuburb}
                />
                <TextField
                  id="connectedNonIndividual-businessAddress-province"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  label="Province"
                  value={nonIndividual.businessAddress.province}
                  onChange={onUpdate("businessAddress.province")}
                  error={Boolean(invalidFormFields.businessAddressProvince)}
                  helperText={invalidFormFields.businessAddressProvince}
                />
                <TextField
                  id="connectedNonIndividual-businessAddress-postalCode"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  label="Postal Code"
                  value={nonIndividual.businessAddress.postalCode}
                  onChange={onUpdate("businessAddress.postalCode")}
                  error={Boolean(invalidFormFields.businessAddressPostalCode)}
                  helperText={invalidFormFields.businessAddressPostalCode}
                />
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </StyledDialog>
  );
};
