// source: financial/smartInstrumentLegDeferrableFloatingRate.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var ledger_amount_pb = require('../ledger/amount_pb.js');
goog.object.extend(proto, ledger_amount_pb);
var num_decimal_pb = require('../num/decimal_pb.js');
goog.object.extend(proto, num_decimal_pb);
var financial_assetflowCategory_pb = require('../financial/assetflowCategory_pb.js');
goog.object.extend(proto, financial_assetflowCategory_pb);
var financial_dayCountConvention_pb = require('../financial/dayCountConvention_pb.js');
goog.object.extend(proto, financial_dayCountConvention_pb);
var financial_businessDayConvention_pb = require('../financial/businessDayConvention_pb.js');
goog.object.extend(proto, financial_businessDayConvention_pb);
var financial_rateSource_pb = require('../financial/rateSource_pb.js');
goog.object.extend(proto, financial_rateSource_pb);
var financial_periodUnit_pb = require('../financial/periodUnit_pb.js');
goog.object.extend(proto, financial_periodUnit_pb);
var financial_scheduleConfiguration_pb = require('../financial/scheduleConfiguration_pb.js');
goog.object.extend(proto, financial_scheduleConfiguration_pb);
goog.exportSymbol('proto.financial.DeferrableFloatingRateSmartInstrumentLeg', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.DeferrableFloatingRateSmartInstrumentLeg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.DeferrableFloatingRateSmartInstrumentLeg.displayName = 'proto.financial.DeferrableFloatingRateSmartInstrumentLeg';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.DeferrableFloatingRateSmartInstrumentLeg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assetflowcategory: jspb.Message.getFieldWithDefault(msg, 3, 0),
    notional: (f = msg.getNotional()) && ledger_amount_pb.FutureAmount.toObject(includeInstance, f),
    scheduleconfiguration: (f = msg.getScheduleconfiguration()) && financial_scheduleConfiguration_pb.ScheduleConfiguration.toObject(includeInstance, f),
    daycountconvention: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ratesource: jspb.Message.getFieldWithDefault(msg, 7, 0),
    referenceratefactor: (f = msg.getReferenceratefactor()) && num_decimal_pb.Decimal.toObject(includeInstance, f),
    ratespread: (f = msg.getRatespread()) && num_decimal_pb.Decimal.toObject(includeInstance, f),
    ratefloor: (f = msg.getRatefloor()) && num_decimal_pb.Decimal.toObject(includeInstance, f),
    rateresetperiodcount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    rateresetperiodunit: jspb.Message.getFieldWithDefault(msg, 12, 0),
    rateresetbusinessdayconvention: jspb.Message.getFieldWithDefault(msg, 13, 0),
    cumulative: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.DeferrableFloatingRateSmartInstrumentLeg;
  return proto.financial.DeferrableFloatingRateSmartInstrumentLeg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.financial.AssetflowCategory} */ (reader.readEnum());
      msg.setAssetflowcategory(value);
      break;
    case 4:
      var value = new ledger_amount_pb.FutureAmount;
      reader.readMessage(value,ledger_amount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setNotional(value);
      break;
    case 5:
      var value = new financial_scheduleConfiguration_pb.ScheduleConfiguration;
      reader.readMessage(value,financial_scheduleConfiguration_pb.ScheduleConfiguration.deserializeBinaryFromReader);
      msg.setScheduleconfiguration(value);
      break;
    case 6:
      var value = /** @type {!proto.financial.DayCountConvention} */ (reader.readEnum());
      msg.setDaycountconvention(value);
      break;
    case 7:
      var value = /** @type {!proto.financial.RateSource} */ (reader.readEnum());
      msg.setRatesource(value);
      break;
    case 8:
      var value = new num_decimal_pb.Decimal;
      reader.readMessage(value,num_decimal_pb.Decimal.deserializeBinaryFromReader);
      msg.setReferenceratefactor(value);
      break;
    case 9:
      var value = new num_decimal_pb.Decimal;
      reader.readMessage(value,num_decimal_pb.Decimal.deserializeBinaryFromReader);
      msg.setRatespread(value);
      break;
    case 10:
      var value = new num_decimal_pb.Decimal;
      reader.readMessage(value,num_decimal_pb.Decimal.deserializeBinaryFromReader);
      msg.setRatefloor(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRateresetperiodcount(value);
      break;
    case 12:
      var value = /** @type {!proto.financial.PeriodUnit} */ (reader.readEnum());
      msg.setRateresetperiodunit(value);
      break;
    case 13:
      var value = /** @type {!proto.financial.BusinessDayConvention} */ (reader.readEnum());
      msg.setRateresetbusinessdayconvention(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCumulative(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.DeferrableFloatingRateSmartInstrumentLeg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetflowcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getNotional();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ledger_amount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getScheduleconfiguration();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      financial_scheduleConfiguration_pb.ScheduleConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getDaycountconvention();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getRatesource();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getReferenceratefactor();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      num_decimal_pb.Decimal.serializeBinaryToWriter
    );
  }
  f = message.getRatespread();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      num_decimal_pb.Decimal.serializeBinaryToWriter
    );
  }
  f = message.getRatefloor();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      num_decimal_pb.Decimal.serializeBinaryToWriter
    );
  }
  f = message.getRateresetperiodcount();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getRateresetperiodunit();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getRateresetbusinessdayconvention();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getCumulative();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AssetflowCategory assetflowCategory = 3;
 * @return {!proto.financial.AssetflowCategory}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.getAssetflowcategory = function() {
  return /** @type {!proto.financial.AssetflowCategory} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.financial.AssetflowCategory} value
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.setAssetflowcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ledger.FutureAmount notional = 4;
 * @return {?proto.ledger.FutureAmount}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.getNotional = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_amount_pb.FutureAmount, 4));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
*/
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.setNotional = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.clearNotional = function() {
  return this.setNotional(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.hasNotional = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ScheduleConfiguration scheduleConfiguration = 5;
 * @return {?proto.financial.ScheduleConfiguration}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.getScheduleconfiguration = function() {
  return /** @type{?proto.financial.ScheduleConfiguration} */ (
    jspb.Message.getWrapperField(this, financial_scheduleConfiguration_pb.ScheduleConfiguration, 5));
};


/**
 * @param {?proto.financial.ScheduleConfiguration|undefined} value
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
*/
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.setScheduleconfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.clearScheduleconfiguration = function() {
  return this.setScheduleconfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.hasScheduleconfiguration = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DayCountConvention dayCountConvention = 6;
 * @return {!proto.financial.DayCountConvention}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.getDaycountconvention = function() {
  return /** @type {!proto.financial.DayCountConvention} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.financial.DayCountConvention} value
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.setDaycountconvention = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional RateSource rateSource = 7;
 * @return {!proto.financial.RateSource}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.getRatesource = function() {
  return /** @type {!proto.financial.RateSource} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.financial.RateSource} value
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.setRatesource = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional num.Decimal referenceRateFactor = 8;
 * @return {?proto.num.Decimal}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.getReferenceratefactor = function() {
  return /** @type{?proto.num.Decimal} */ (
    jspb.Message.getWrapperField(this, num_decimal_pb.Decimal, 8));
};


/**
 * @param {?proto.num.Decimal|undefined} value
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
*/
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.setReferenceratefactor = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.clearReferenceratefactor = function() {
  return this.setReferenceratefactor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.hasReferenceratefactor = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional num.Decimal rateSpread = 9;
 * @return {?proto.num.Decimal}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.getRatespread = function() {
  return /** @type{?proto.num.Decimal} */ (
    jspb.Message.getWrapperField(this, num_decimal_pb.Decimal, 9));
};


/**
 * @param {?proto.num.Decimal|undefined} value
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
*/
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.setRatespread = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.clearRatespread = function() {
  return this.setRatespread(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.hasRatespread = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional num.Decimal rateFloor = 10;
 * @return {?proto.num.Decimal}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.getRatefloor = function() {
  return /** @type{?proto.num.Decimal} */ (
    jspb.Message.getWrapperField(this, num_decimal_pb.Decimal, 10));
};


/**
 * @param {?proto.num.Decimal|undefined} value
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
*/
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.setRatefloor = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.clearRatefloor = function() {
  return this.setRatefloor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.hasRatefloor = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional uint32 rateResetPeriodCount = 11;
 * @return {number}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.getRateresetperiodcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.setRateresetperiodcount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional PeriodUnit rateResetPeriodUnit = 12;
 * @return {!proto.financial.PeriodUnit}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.getRateresetperiodunit = function() {
  return /** @type {!proto.financial.PeriodUnit} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.financial.PeriodUnit} value
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.setRateresetperiodunit = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional BusinessDayConvention rateResetBusinessDayConvention = 13;
 * @return {!proto.financial.BusinessDayConvention}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.getRateresetbusinessdayconvention = function() {
  return /** @type {!proto.financial.BusinessDayConvention} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.financial.BusinessDayConvention} value
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.setRateresetbusinessdayconvention = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional bool cumulative = 14;
 * @return {boolean}
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.getCumulative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.financial.DeferrableFloatingRateSmartInstrumentLeg} returns this
 */
proto.financial.DeferrableFloatingRateSmartInstrumentLeg.prototype.setCumulative = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


goog.object.extend(exports, proto.financial);
