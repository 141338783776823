import React, { useEffect } from "react";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { useSnackbar } from "notistack";
import { Token } from "../../../../../james/ledger";
import { Info as InfoIcon } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AllInstrumentRiskProfiles,
  AllInvestorProfiles,
  DigitalFloatingRateBond,
  InstrumentRiskProfile,
  InvestorProfile,
  allCalendars,
  calendarToString,
} from "james/financial";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { BondState } from "james/financial/Bond";
import { InstrumentStateChip } from "views/InstrumentBuilder/Instruments/Chips";
import {
  DateField,
  TextField,
  TextNumField,
} from "../../../../../components/FormFields";
import {
  formDataUpdaterSpecs,
  formDataValidationFunc,
} from "./useValidatedForms";
import { useValidatedForm } from "hooks/useForm";
import isEqual from "lodash/isEqual";
import { DigitalFloatingRateBondStateController } from "james/financial/DigitalFloatingRateBondStateController";
import { FormData, FormMode } from "../index";
import { DigitalFloatingRateBondUpdater } from "james/financial/DigitalFloatingRateBondUpdater";
import { TokenIconViewUpload } from "components/Ledger/Token";
import {
  BusinessDayConvention,
  allBusinessDayConventions,
} from "james/financial/BusinessDayConvention";
import {
  CouponFrequency,
  allCouponFrequencies,
} from "james/financial/CouponFrequency";
import {
  DayCountConvention,
  allDayCountConventions,
} from "james/financial/DayCountConvention";
import {
  DebtSeniorityLevel,
  allDebtSeniorityLevels,
} from "james/financial/DebtSeniorityLevel";
import { Documents } from "../Documents";
import { TextArea } from "../TextArea/TextArea";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  allRateSources,
  rateSourceToString,
} from "@mesh/common-js/dist/financial/rateSource";
import { RateSource } from "@mesh/common-js/dist/financial/rateSource_pb";
import BigNumber from "bignumber.js";
import { Document } from "james/document";
import EditIcon from "@mui/icons-material/Edit";
import cx from "classnames";

interface DigitalFloatingRateBondFormProps {
  setAPILoading: (newValue: boolean) => void;
  apiLoading: boolean;
  formData: FormData;
  digitalFloatingRateBond: DigitalFloatingRateBond;
  onDigitalFloatingRateBondChange: (
    digitalFloatingRateBond: DigitalFloatingRateBond,
  ) => void;
}

export const DigitalFloatingRateBondForm = (
  props: DigitalFloatingRateBondFormProps,
) => {
  const { authContext, viewConfiguration } = useApplicationContext();
  const { errorContextDefaultWarningFeedback } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [
    formData,
    formDataValidationResult,
    formDataUpdate,
    formDataValidationInProgress,
  ] = useValidatedForm(
    formDataValidationFunc,
    async () => {
      return {
        generalFormData: props.formData,
        digitalFloatingRateBond: new DigitalFloatingRateBond(
          props.digitalFloatingRateBond,
        ),
      };
    },
    formDataUpdaterSpecs,
    {
      generalFormData: props.formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        props.digitalFloatingRateBond,
      ),
    },
    new Set<string>([]),
  );

  useEffect(() => {
    formDataUpdate.digitalFloatingRateBond(
      new DigitalFloatingRateBond(props.digitalFloatingRateBond),
    );
  }, [props.digitalFloatingRateBond]);

  // handlePerformRequiredUpdates performs any updates required on the DigitalFloatingRateBond.
  // i.e. either creation, draft update or discrete update
  // The updated DigitalFloatingRateBond is returned.
  const handlePerformRequiredUpdates: () => Promise<DigitalFloatingRateBond> =
    async () => {
      // if creation is in progress
      if (creatingNewInstrument) {
        // then create the new digital Floating rate bond
        return (
          await DigitalFloatingRateBondStateController.CreateDraftDigitalFloatingRateBond(
            {
              context: authContext,
              ownerID: formData.digitalFloatingRateBond.ownerID,
              isin: formData.digitalFloatingRateBond.isin,
              name: formData.digitalFloatingRateBond.name,
              fractionalisationAllowed:
                formData.digitalFloatingRateBond.fractionalisationAllowed,
              investorProfile: formData.digitalFloatingRateBond.investorProfile,
              investorProfileDescription:
                formData.digitalFloatingRateBond.investorProfileDescription,
              riskProfile: formData.digitalFloatingRateBond.riskProfile,
              riskProfileDescription:
                formData.digitalFloatingRateBond.riskProfileDescription,
              debtSeniorityLevel:
                formData.digitalFloatingRateBond.debtSeniorityLevel,
              covenants: formData.digitalFloatingRateBond.covenants,
              votingRights: formData.digitalFloatingRateBond.votingRights,
              votingRightsDescription:
                formData.digitalFloatingRateBond.votingRightsDescription,
              ownershipLimitations:
                formData.digitalFloatingRateBond.ownershipLimitations,
              issuePrice: formData.digitalFloatingRateBond.issuePrice,
              nominal: formData.digitalFloatingRateBond.nominal,
              totalNominal: formData.digitalFloatingRateBond.totalNominal,
              redemption: formData.digitalFloatingRateBond.redemption,
              issueDate: formData.digitalFloatingRateBond.issueDate,
              maturityDate: formData.digitalFloatingRateBond.maturityDate,
              calendar: formData.digitalFloatingRateBond.calendar,
              couponPaymentFrequency:
                formData.digitalFloatingRateBond.couponPaymentFrequency,
              firstCouponPaymentDate:
                formData.digitalFloatingRateBond.firstCouponPaymentDate,
              nextToLastCouponPaymentDate:
                formData.digitalFloatingRateBond.nextToLastCouponPaymentDate,
              couponInterestCommencementDate:
                formData.digitalFloatingRateBond.couponInterestCommencementDate,
              dayCountConvention:
                formData.digitalFloatingRateBond.dayCountConvention,
              endOfMonthConvention:
                formData.digitalFloatingRateBond.endOfMonthConvention,
              businessDayConvention:
                formData.digitalFloatingRateBond.businessDayConvention,
              terminationDateBusinessDayConvention:
                formData.digitalFloatingRateBond
                  .terminationDateBusinessDayConvention,
              dateGenerationRule:
                formData.digitalFloatingRateBond.dateGenerationRule,
              fixDays: formData.digitalFloatingRateBond.fixDays,
              exCouponDays: formData.digitalFloatingRateBond.exCouponDays,
              recordDays: formData.digitalFloatingRateBond.recordDays,
            },
          )
        ).digitalFloatingRateBond;
      }

      // Otherwise the instrument already exists
      if (formData.digitalFloatingRateBond.state === BondState.Draft) {
        // if the instrument is in draft then perform a draft update
        return unsavedChangesExist
          ? (
              await DigitalFloatingRateBondUpdater.DraftUpdateDigitalFloatingRateBond(
                {
                  context: authContext,
                  digitalFloatingRateBond: formData.digitalFloatingRateBond,
                },
              )
            ).digitalFloatingRateBond
          : formData.digitalFloatingRateBond;
      }

      return formData.digitalFloatingRateBond;
    };

  const handleUpdateSupportingDocs: (
    doc: Document,
  ) => Promise<DigitalFloatingRateBond> = async (doc: Document) => {
    if (creatingNewInstrument) return formData.digitalFloatingRateBond;
    if (formData.digitalFloatingRateBond.state === BondState.Draft) {
      return (
        await DigitalFloatingRateBondUpdater.AddDigitalFloatingRateBondSupportingDocument(
          {
            context: authContext,
            digitalFloatingRateBondID:
              formData.digitalFloatingRateBond.assetID(),
            supportingDocument: doc,
          },
        )
      ).digitalFloatingRateBond;
    }

    return formData.digitalFloatingRateBond;
  };

  const unsavedChangesExist = !isEqual(
    formData.digitalFloatingRateBond,
    props.digitalFloatingRateBond,
  );
  const creatingNewInstrument = formData.digitalFloatingRateBond.id === "";

  useEffect(() => {
    const formMode = searchParams.get("mode");
    if (!formMode) {
      const updatedSearchParams = new URLSearchParams(searchParams);
      updatedSearchParams.set(
        "mode",
        formData.digitalFloatingRateBond.id === ""
          ? FormMode.Edit
          : FormMode.View,
      );
      setSearchParams(updatedSearchParams);
    }
  }, [formData.digitalFloatingRateBond, FormMode]);

  const readOnly = !((searchParams.get("mode") as FormMode) === FormMode.Edit);

  const draftState = formData.digitalFloatingRateBond.state === BondState.Draft;

  return (
    <>
      <CardContent
        sx={{
          p: 3,
          px: 4,
          pb: 5,
        }}
      >
        <Box>
          <Box
            className={cx({
              readOnly: readOnly || !draftState,
            })}
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "1fr auto",
              columnGap: theme.spacing(2),
              "&.readOnly": {
                mb: 2,
                borderBottom: `${theme.palette.divider} 1px solid`,
              },
            })}
          >
            {!readOnly ? (
              <TextField
                id={
                  "digitalFloatingRateBondForm-header-instrumentName-textField"
                }
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                placeholder={"Enter a Floating Rate Bond Name"}
                value={formData.digitalFloatingRateBond.name}
                onChange={(e) => {
                  formDataUpdate.name(e.target.value);
                }}
                sx={{
                  fontSize: 20, // h4 font size
                  fontWeight: 600,
                  letterSpacing: 0,
                  maxWidth: "100%",
                  width: "100%",
                  marginBottom: "1rem", // Space below the heading,
                  "&::placeholder": {
                    color: "rgba(0, 0, 0, 0.54)", // Placeholder color
                    fontWeight: 600,
                    fontSize: "1.2rem", // Placeholder font size to match h4
                  },
                }}
                InputProps={{
                  sx: {
                    fontSize: "1.2rem", // h5 font size
                    fontWeight: 600,
                    letterSpacing: 0,
                  },
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <InstrumentStateChip
                        state={formData.digitalFloatingRateBond.state}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  pb: 2,
                }}
              >
                <Typography
                  variant="h4"
                  sx={(theme) => ({
                    fontSize: 20, // h4 font size
                    fontWeight: theme.typography.fontWeightBold, // Bold font
                    letterSpacing: 0,
                  })}
                >
                  {formData.digitalFloatingRateBond.name}
                </Typography>
                <InstrumentStateChip
                  state={formData.digitalFloatingRateBond.state}
                />
              </Box>
            )}
            <Box
              className={cx({
                readOnly: readOnly || !draftState,
              })}
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: theme.spacing(1),
                ml: "auto",
                mb: 1,
                "&.readOnly": {
                  mb: 2,
                },
              })}
            >
              {(() => {
                const buttons: React.ReactNode[] = [];

                if (draftState) {
                  if (readOnly) {
                    if (
                      viewConfiguration?.Instruments
                        ?.DigitalFloatingRateBondActions?.Update
                    ) {
                      // if in view mode then show the edit button to transition to edit mode
                      buttons.push(
                        <IconButton
                          disabled={
                            props.apiLoading || formDataValidationInProgress
                          }
                          onClick={() => {
                            const newSearchParams = new URLSearchParams(
                              searchParams,
                            );
                            newSearchParams.set("mode", FormMode.Edit);
                            setSearchParams(newSearchParams);
                          }}
                        >
                          <EditIcon />
                        </IconButton>,
                      );
                    }
                  } else {
                    // otherwise the save button should always be shown to exit edit mode
                    if (
                      viewConfiguration?.Instruments
                        ?.DigitalFloatingRateBondActions?.CreateDraft &&
                      viewConfiguration?.Instruments
                        ?.DigitalFloatingRateBondActions?.Update
                    ) {
                      buttons.push(
                        <Button
                          variant={"outlined"}
                          disabled={
                            props.apiLoading || formDataValidationInProgress
                          }
                          onClick={async () => {
                            props.setAPILoading(true);

                            // if unsaved changes exist then perform any required updates
                            let updatedDigitalFloatingRateBond =
                              formData.digitalFloatingRateBond;
                            if (creatingNewInstrument || unsavedChangesExist) {
                              try {
                                updatedDigitalFloatingRateBond =
                                  await handlePerformRequiredUpdates();
                                enqueueSnackbar("Instrument Saved", {
                                  variant: "success",
                                });
                              } catch (e) {
                                console.error("error saving instrument", e);
                                errorContextDefaultWarningFeedback(
                                  e,
                                  "Could Not Save Instrument",
                                );
                                props.setAPILoading(false);
                                return;
                              }
                            }

                            // stop loading and turn view mode on
                            props.setAPILoading(false);
                            formDataUpdate.digitalFloatingRateBond(
                              updatedDigitalFloatingRateBond,
                            );
                            navigate(
                              `${window.location.pathname}?id=${updatedDigitalFloatingRateBond.id}&mode=${FormMode.View}`,
                            );
                            props.onDigitalFloatingRateBondChange(
                              new DigitalFloatingRateBond(
                                updatedDigitalFloatingRateBond,
                              ),
                            );
                          }}
                        >
                          Save
                        </Button>,
                      );
                    }
                  }
                }

                // if the asset is in draft mode then show Pre-Issue button
                if (
                  formData.digitalFloatingRateBond.state === BondState.Draft
                ) {
                  if (
                    viewConfiguration?.Instruments
                      ?.DigitalFloatingRateBondActions?.PreIssue
                  ) {
                    buttons.push(
                      <Button
                        disabled={
                          // !formDataValidationResult.valid || BUG: validation result not forwarder to frontend
                          props.apiLoading || formDataValidationInProgress
                        }
                        color={"primary"}
                        variant={"contained"}
                        onClick={async () => {
                          props.setAPILoading(true);

                          let updatedDigitalFloatingRateBond =
                            formData.digitalFloatingRateBond;
                          try {
                            // perform any required updates
                            updatedDigitalFloatingRateBond =
                              await handlePerformRequiredUpdates();

                            // pre-issue
                            updatedDigitalFloatingRateBond = (
                              await DigitalFloatingRateBondStateController.PreIssueDigitalFloatingRateBond(
                                {
                                  context: authContext,
                                  digitalFloatingRateBondID:
                                    updatedDigitalFloatingRateBond.id,
                                },
                              )
                            ).digitalFloatingRateBond;

                            enqueueSnackbar("Instrument Pre-Issued", {
                              variant: "success",
                            });
                          } catch (e) {
                            console.error("error saving instrument", e);
                            errorContextDefaultWarningFeedback(
                              e,
                              "Could Not Pre-Issue Instrument",
                            );
                            props.setAPILoading(false);
                            return;
                          }

                          // stop loading and turn view mode on
                          props.setAPILoading(false);
                          formDataUpdate.digitalFloatingRateBond(
                            updatedDigitalFloatingRateBond,
                          );
                          navigate(
                            `${window.location.pathname}?id=${updatedDigitalFloatingRateBond.id}&mode=${FormMode.View}`,
                          );
                          props.onDigitalFloatingRateBondChange(
                            new DigitalFloatingRateBond(
                              updatedDigitalFloatingRateBond,
                            ),
                          );
                        }}
                      >
                        Pre-Issue
                      </Button>,
                    );
                  }
                }

                return buttons;
              })().map((b, idx) => (
                <React.Fragment key={idx}>{b}</React.Fragment>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            columnGap: 2,
            rowGap: 2,
            "& > *": {
              mt: 0.5,
            },
          }}
        >
          <>
            <TextField
              readOnly={readOnly || !draftState}
              disabled={props.apiLoading}
              SelectProps={{
                IconComponent: readOnly || !draftState ? () => null : undefined,
              }}
              id={"digitalFloatingRateBondForm-header-ownerGroup-selectField"}
              label="Owner Group"
              select
              value={formData.digitalFloatingRateBond.ownerID}
            >
              {props.formData.groupOwners.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </TextField>
          </>

          <>
            <TextField
              readOnly={readOnly || !draftState}
              disabled={props.apiLoading}
              id={"digitalFloatingRateBondForm-isin-textField"}
              label="ISIN"
              value={
                readOnly || !draftState
                  ? formData.digitalFloatingRateBond.isin === ""
                    ? "-"
                    : formData.digitalFloatingRateBond.isin
                  : formData.digitalFloatingRateBond.isin
              }
              onChange={(e) => formDataUpdate.isin(e.target.value)}
              helperText={formDataValidationResult.fieldValidations.isin}
              error={!!formDataValidationResult.fieldValidations.isin}
            />
            {formData.digitalFloatingRateBond.state !== BondState.Draft && (
              <>
                <TextField
                  readOnly
                  disabled={props.apiLoading}
                  id={"digitalFloatingRateBondForm-shortName-textField"}
                  label="Short Name"
                  value={formData.digitalFloatingRateBond.shortName}
                />
                <TextField
                  readOnly
                  disabled={props.apiLoading}
                  id={"digitalFloatingRateBondForm-token-textField"}
                  label="Token"
                  value={formData.digitalFloatingRateBond.token.code}
                />
              </>
            )}
          </>
        </Box>
      </CardContent>

      <CardContent sx={{ p: 0 }}>
        {/* --------------- Bond Information -----------------------*/}
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={(theme) => ({
              pl: 4,
              flexDirection: "row-reverse",
              backgroundColor: theme.palette.custom.midnight,
            })}
            expandIcon={
              <ExpandMoreIcon
                id="digitalFloatingRateBondForm-bondInformation-accordionToggleIconButton"
                color="primary"
              />
            }
          >
            <Typography sx={{ ml: 4 }} variant={"h6"}>
              Bond Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              columnGap: theme.spacing(3),
              p: 0,
            })}
          >
            {/* ----------- Payment Schedule ----------------*/}
            <Box
              sx={(theme) => ({
                display: "grid",
                padding: theme.spacing(3, 4),
                gridTemplateColumns: "repeat(3, 1fr)",
                columnGap: 5,
                rowGap: 1,
                "& > *": {
                  mt: 0.5,
                },
              })}
            >
              <Typography variant={"h6"} sx={{ gridColumn: "1/4", mb: 1 }}>
                Payment Schedule
              </Typography>

              <>
                <DateField
                  label="Issue Date"
                  id="digitalFloatingRateBondForm-issueDate-dateField"
                  disabled={props.apiLoading}
                  readOnly={readOnly || !draftState}
                  onChange={(newDate) =>
                    formDataUpdate.issueDate(newDate ? newDate.format() : "")
                  }
                  value={formData.digitalFloatingRateBond.issueDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="digitalFloatingRateBondForm-issueDate-dateFieldTextField"
                      fullWidth
                      disabled={props.apiLoading}
                      readOnly={readOnly || !draftState}
                      helperText={
                        formDataValidationResult.fieldValidations.issueDate
                      }
                      error={
                        !!formDataValidationResult.fieldValidations.issueDate
                      }
                    />
                  )}
                />
                <TextField
                  id="digitalFloatingRateBondForm-calendar-selectField"
                  readOnly={readOnly || !draftState}
                  disabled={props.apiLoading}
                  SelectProps={{
                    IconComponent:
                      readOnly || !draftState ? () => null : undefined,
                  }}
                  fullWidth
                  label="Calendar"
                  select
                  value={formData.digitalFloatingRateBond.calendar}
                  onChange={(e) =>
                    formDataUpdate.calendar(Number(e.target.value))
                  }
                  error={!!formDataValidationResult.fieldValidations.calendar}
                  helperText={
                    formDataValidationResult.fieldValidations.calendar
                  }
                >
                  {allCalendars.map((cal, idx) => (
                    <MenuItem key={idx} value={cal}>
                      {calendarToString(cal)}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="digitalFloatingRateBondForm-dayCountConvention-selectField"
                  readOnly={readOnly || !draftState}
                  disabled={props.apiLoading}
                  SelectProps={{
                    IconComponent:
                      readOnly || !draftState ? () => null : undefined,
                  }}
                  fullWidth
                  label="Day Count Convention"
                  select
                  value={formData.digitalFloatingRateBond.dayCountConvention}
                  onChange={(e) =>
                    formDataUpdate.dayCountConvention(
                      e.target.value as DayCountConvention,
                    )
                  }
                  error={
                    !!formDataValidationResult.fieldValidations
                      .dayCountConvention
                  }
                  helperText={
                    formDataValidationResult.fieldValidations.dayCountConvention
                  }
                >
                  {allDayCountConventions.map((dcc, idx) => (
                    <MenuItem key={idx} value={idx + 1}>
                      {dcc}
                    </MenuItem>
                  ))}
                </TextField>
              </>
              <>
                <DateField
                  label="Maturity Date"
                  id="digitalFloatingRateBondForm-maturityDate-dateField"
                  disabled={props.apiLoading}
                  readOnly={readOnly || !draftState}
                  onChange={(newDate) =>
                    formDataUpdate.maturityDate(newDate ? newDate.format() : "")
                  }
                  value={formData.digitalFloatingRateBond.maturityDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="digitalFloatingRateBondForm-maturityDate-dateFieldTextField"
                      fullWidth
                      disabled={props.apiLoading}
                      readOnly={readOnly || !draftState}
                      helperText={
                        formDataValidationResult.fieldValidations.maturityDate
                      }
                      error={
                        !!formDataValidationResult.fieldValidations.maturityDate
                      }
                    />
                  )}
                />
                <TextField
                  fullWidth
                  id="digitalFloatingRateBondForm-businessDayConvention-selectField"
                  readOnly={readOnly || !draftState}
                  disabled={props.apiLoading}
                  SelectProps={{
                    IconComponent:
                      readOnly || !draftState ? () => null : undefined,
                  }}
                  label="Business Day Conv."
                  select
                  value={formData.digitalFloatingRateBond.businessDayConvention}
                  onChange={(e) => {
                    formDataUpdate.businessDayConvention(
                      e.target.value as BusinessDayConvention,
                    );
                  }}
                  error={!!formDataValidationResult.fieldValidations.calendar}
                  helperText={
                    formDataValidationResult.fieldValidations.calendar
                  }
                >
                  {allBusinessDayConventions.map((bdc, idx) => (
                    <MenuItem key={idx} value={idx + 1}>
                      {bdc}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="digitalFloatingRateBondForm-couponPaymentFrequency-selectField"
                  readOnly={readOnly || !draftState}
                  disabled={props.apiLoading}
                  SelectProps={{
                    IconComponent:
                      readOnly || !draftState ? () => null : undefined,
                  }}
                  fullWidth
                  label="Coupon Payment Freq."
                  select
                  value={
                    formData.digitalFloatingRateBond.couponPaymentFrequency
                  }
                  onChange={(e) => {
                    formDataUpdate.couponPaymentFrequency(
                      e.target.value as CouponFrequency,
                    );
                  }}
                  error={!!formDataValidationResult.fieldValidations.calendar}
                  helperText={
                    formDataValidationResult.fieldValidations.calendar
                  }
                >
                  {allCouponFrequencies.map((freq, idx) => (
                    <MenuItem key={idx} value={idx + 1}>
                      {freq}
                    </MenuItem>
                  ))}
                </TextField>
              </>
              <>
                <TextField
                  type="number"
                  id="digitalFloatingRateBondForm-fixDays-numberField"
                  readOnly={readOnly || !draftState}
                  disabled={props.apiLoading}
                  value={formData.digitalFloatingRateBond.fixDays}
                  onChange={(e) =>
                    formDataUpdate.fixDays(Number(e.target.value))
                  }
                  label="Fix Days"
                  sx={{
                    maxWidth: "100%",
                    width: readOnly || !draftState ? 88 : "100%",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: 1,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        children={
                          <Tooltip
                            placement="top"
                            title="Number of days before coupon payment date that calculations are finalized and funding may begin."
                          >
                            <InfoIcon
                              sx={(theme) => ({
                                cursor: "pointer",
                                color: theme.palette.text.secondary,
                                width: "16px",
                                height: "16px",
                                mb: 0.5,
                              })}
                            />
                          </Tooltip>
                        }
                      />
                    ),
                  }}
                  variant="outlined"
                  error={!!formDataValidationResult.fieldValidations.fixDays}
                  helperText={formDataValidationResult.fieldValidations.fixDays}
                />
                <TextField
                  type="number"
                  id="digitalFloatingRateBondForm-recordDays-numberField"
                  readOnly={readOnly || !draftState}
                  disabled={props.apiLoading}
                  value={formData.digitalFloatingRateBond.recordDays}
                  onChange={(e) =>
                    formDataUpdate.recordDays(Number(e.target.value))
                  }
                  label="Record Days"
                  sx={{
                    maxWidth: "100%",
                    width: readOnly || !draftState ? "112px" : "100%",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: 0,
                  }}
                  variant="outlined"
                  error={!!formDataValidationResult.fieldValidations.recordDays}
                  helperText={
                    formDataValidationResult.fieldValidations.recordDays
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        children={
                          <Tooltip
                            placement="top"
                            title="Number of days before coupon payment date that ownership records are taken to determine recipients."
                          >
                            <InfoIcon
                              sx={(theme) => ({
                                cursor: "pointer",
                                color: theme.palette.text.secondary,
                                width: "16px",
                                height: "16px",
                                mb: 0.5,
                              })}
                            />
                          </Tooltip>
                        }
                      />
                    ),
                  }}
                />
                <TextField
                  type="number"
                  id="digitalFloatingRateBondForm-exCouponDays-numberField"
                  readOnly={readOnly || !draftState}
                  disabled={props.apiLoading}
                  value={formData.digitalFloatingRateBond.exCouponDays}
                  onChange={(e) =>
                    formDataUpdate.exCouponDays(Number(e.target.value))
                  }
                  label="ExCoupon Days"
                  sx={{
                    maxWidth: "100%",
                    width: readOnly || !draftState ? "132px" : "100%",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: 0,
                  }}
                  variant="outlined"
                  error={
                    !!formDataValidationResult.fieldValidations.exCouponDays
                  }
                  helperText={
                    formDataValidationResult.fieldValidations.exCouponDays
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        children={
                          <Tooltip
                            placement="top"
                            title="Estimated of days before coupon payment date that the bond will trade while the seller is likely to maintain rights to next payment."
                          >
                            <InfoIcon
                              sx={(theme) => ({
                                cursor: "pointer",
                                color: theme.palette.text.secondary,
                                width: "16px",
                                height: "16px",
                                mb: 0.5,
                              })}
                            />
                          </Tooltip>
                        }
                      />
                    ),
                  }}
                />
              </>
            </Box>

            {/* ----------- Financials ----------------*/}
            <Box
              sx={(theme) => ({
                display: "grid",
                padding: theme.spacing(3, 4),
                gridTemplateColumns: "repeat(3, 1fr)",
                columnGap: 5,
                rowGap: 1,
                "& > *": {
                  mt: 0.5,
                },
              })}
            >
              <Typography variant={"h6"} sx={{ gridColumn: "1/4", mb: 1 }}>
                Financials
              </Typography>

              <>
                <TextField
                  id="digitalFloatingRateBondForm-currencyStablecoin-selectField"
                  readOnly={readOnly || !draftState}
                  disabled={props.apiLoading}
                  SelectProps={{
                    IconComponent:
                      readOnly || !draftState ? () => null : undefined,
                  }}
                  fullWidth
                  label="Currency Stablecoin"
                  select
                  value={
                    props.formData.currencyStablecoins.find((cst) =>
                      cst.token.isEqualTo(
                        formData.digitalFloatingRateBond.totalNominal.token,
                      ),
                    )?.id ?? ""
                  }
                  onChange={(e) =>
                    formDataUpdate.currencyStablecoinToken(
                      props.formData.currencyStablecoins.find(
                        (cst) => cst.id === e.target.value,
                      )?.token ?? new Token(),
                    )
                  }
                  error={
                    !!formDataValidationResult.fieldValidations
                      .terminationDateBusinessDayConvention
                  }
                  helperText={
                    formDataValidationResult.fieldValidations
                      .terminationDateBusinessDayConvention
                  }
                >
                  {props.formData.currencyStablecoins.map((cst, idx) => (
                    <MenuItem key={idx} value={cst.id}>
                      <Box
                        sx={(theme) => ({
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: theme.spacing(1),
                        })}
                      >
                        <TokenIconViewUpload
                          disableChangeIcon
                          size={23}
                          token={cst.token}
                        />
                        <Typography children={cst.token.code} />
                      </Box>
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="digitalFloatingRateBondForm-rateSource-selectField"
                  select
                  fullWidth
                  label="Rate Source"
                  readOnly={readOnly || !draftState}
                  disabled={props.apiLoading}
                  SelectProps={{
                    IconComponent:
                      readOnly || !draftState ? () => null : undefined,
                  }}
                  value={formData.digitalFloatingRateBond.couponRateSource}
                  onChange={(e) =>
                    formDataUpdate.couponRateSource(
                      Number(e.target.value) as RateSource,
                    )
                  }
                  error={
                    !!formDataValidationResult.fieldValidations.couponRateSource
                  }
                  helperText={
                    formDataValidationResult.fieldValidations.couponRateSource
                  }
                >
                  {allRateSources.map((rateSource: RateSource, idx: number) => {
                    return (
                      <MenuItem key={idx} value={rateSource}>
                        {rateSourceToString(rateSource)}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <TextNumField
                  id="digitalFloatingRateBondForm-rate-textNumbField"
                  readOnly={readOnly || !draftState}
                  disabled={props.apiLoading}
                  fullWidth
                  noDecimalPlaces={7}
                  label={"Spread"}
                  disallowNegative
                  percentage={readOnly || !draftState}
                  value={formData.digitalFloatingRateBond.couponRateVariance}
                  onChange={(e) =>
                    formDataUpdate.couponRateVariance(
                      new BigNumber(e.target.value),
                    )
                  }
                  error={
                    !!formDataValidationResult.fieldValidations
                      .couponRateVariance
                  }
                  helperText={
                    formDataValidationResult.fieldValidations.couponRateVariance
                  }
                />
                <>
                  <TextNumField
                    id={"digitalFloatingRateBondForm-redemption-textNumField"}
                    label={"Redemption"}
                    disabled={props.apiLoading}
                    readOnly={readOnly || !draftState}
                    percentage={readOnly || !draftState}
                    value={formData.digitalFloatingRateBond.redemption}
                    onChange={(e) => formDataUpdate.redemption(e.target.value)}
                    fullWidth
                    disallowNegative
                    noDecimalPlaces={7}
                    sx={{
                      maxWidth: "100%",
                      width: readOnly || !draftState ? "112px" : "100%",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={"end"}>
                          <Box
                            sx={(theme) => ({
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: theme.spacing(0.5),
                            })}
                          >
                            <Tooltip
                              placement="top"
                              title="Percentage of total nominal paid back at maturity."
                            >
                              <InfoIcon
                                sx={(theme) => ({
                                  cursor: "pointer",
                                  color: theme.palette.text.secondary,
                                  width: "16px",
                                  height: "16px",
                                  mb: 0.5,
                                })}
                              />
                            </Tooltip>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    error={
                      !!formDataValidationResult.fieldValidations.redemption
                    }
                    helperText={
                      formDataValidationResult.fieldValidations.redemption
                    }
                  />

                  <TextNumField
                    id={"digitalFloatingRateBondForm-nominal-textNumField"}
                    readOnly={readOnly || !draftState}
                    disabled={props.apiLoading}
                    fullWidth
                    noDecimalPlaces={7}
                    label={"Nominal"}
                    disallowNegative
                    value={formData.digitalFloatingRateBond.nominal.value}
                    sx={{
                      maxWidth: "100%",
                      width:
                        readOnly || !draftState
                          ? formData.digitalFloatingRateBond.nominal.toString()
                              .length *
                              12 +
                            8
                          : "100%",
                    }}
                    onChange={(e) =>
                      formDataUpdate.nominal(
                        formData.digitalFloatingRateBond.nominal.setValue(
                          e.target.value,
                        ),
                      )
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                          {formData.digitalFloatingRateBond.nominal.token.code}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          children={
                            <Tooltip
                              placement="top"
                              title="Nominal associated with a single unit of the bond."
                            >
                              <InfoIcon
                                sx={(theme) => ({
                                  cursor: "pointer",
                                  color: theme.palette.text.secondary,
                                  width: "16px",
                                  height: "16px",
                                  mb: 0.5,
                                })}
                              />
                            </Tooltip>
                          }
                        />
                      ),
                    }}
                    error={!!formDataValidationResult.fieldValidations.nominal}
                    helperText={
                      formDataValidationResult.fieldValidations.nominal
                    }
                  />
                  <TextNumField
                    id={"digitalFloatingRateBondForm-issuePrice-textNumField"}
                    readOnly={readOnly || !draftState}
                    disabled={props.apiLoading}
                    fullWidth
                    noDecimalPlaces={7}
                    label={"Issue Price"}
                    sx={{
                      maxWidth: "100%",
                      width:
                        readOnly || !draftState
                          ? formData.digitalFloatingRateBond.issuePrice.toString()
                              .length *
                              12 +
                            8
                          : "100%",
                    }}
                    disallowNegative
                    value={formData.digitalFloatingRateBond.issuePrice.value}
                    onChange={(e) =>
                      formDataUpdate.issuePrice(
                        formData.digitalFloatingRateBond.issuePrice.setValue(
                          e.target.value,
                        ),
                      )
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                          {
                            formData.digitalFloatingRateBond.issuePrice.token
                              .code
                          }
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          children={
                            <Tooltip
                              placement="top"
                              title="The price of a bond unit on the primary market."
                            >
                              <InfoIcon
                                sx={(theme) => ({
                                  cursor: "pointer",
                                  color: theme.palette.text.secondary,
                                  width: "16px",
                                  height: "16px",
                                  mb: 0.5,
                                })}
                              />
                            </Tooltip>
                          }
                        />
                      ),
                    }}
                    error={
                      !!formDataValidationResult.fieldValidations.issuePrice
                    }
                    helperText={
                      formDataValidationResult.fieldValidations.issuePrice
                    }
                  />
                </>
                <TextNumField
                  id={"digitalFloatingRateBondForm-totalNominal-textNumField"}
                  readOnly={readOnly || !draftState}
                  disabled={props.apiLoading}
                  fullWidth
                  noDecimalPlaces={7}
                  label={"Total Nominal"}
                  disallowNegative
                  value={formData.digitalFloatingRateBond.totalNominal.value}
                  onChange={(e) =>
                    formDataUpdate.totalNominal(
                      formData.digitalFloatingRateBond.totalNominal.setValue(
                        e.target.value,
                      ),
                    )
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                        {
                          formData.digitalFloatingRateBond.totalNominal.token
                            .code
                        }
                      </InputAdornment>
                    ),
                  }}
                  error={
                    !!formDataValidationResult.fieldValidations.totalNominal
                  }
                  helperText={
                    formDataValidationResult.fieldValidations.totalNominal
                  }
                />
              </>
            </Box>

            {/* ----------- Advanced ----------------*/}
            <Box
              sx={(theme) => ({
                display: "grid",
                padding: theme.spacing(3, 4, 5, 4),
                gridTemplateColumns: "repeat(3, 1fr)",
                columnGap: theme.spacing(5),
                rowGap: 1,
                "& > *": {
                  mt: 0.5,
                },
              })}
            >
              <Typography variant={"h6"} sx={{ gridColumn: "1/4", mb: 1 }}>
                Advanced
              </Typography>

              <>
                <DateField
                  nullable
                  label="First Coupon Payment Date"
                  id="digitalFloatingRateBondForm-firstCouponPaymentDate-dateField"
                  disabled={props.apiLoading}
                  readOnly={readOnly || !draftState}
                  onChange={(newDate) =>
                    formDataUpdate.firstCouponPaymentDate(
                      newDate ? newDate.format() : null,
                    )
                  }
                  value={
                    formData.digitalFloatingRateBond.firstCouponPaymentDate ??
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="digitalFloatingRateBondForm-firstCouponPaymentDate-dateFieldTextField"
                      fullWidth
                      disabled={props.apiLoading}
                      readOnly={readOnly || !draftState}
                      helperText={
                        formDataValidationResult.fieldValidations
                          .firstCouponPaymentDate
                      }
                      error={
                        !!formDataValidationResult.fieldValidations
                          .firstCouponPaymentDate
                      }
                    />
                  )}
                />
                <DateField
                  nullable
                  label="Next to Last Coupon Payment Date"
                  id="digitalFloatingRateBondForm-nextToLastCouponPaymentDate-dateField"
                  disabled={props.apiLoading}
                  readOnly={readOnly || !draftState}
                  onChange={(newDate) =>
                    formDataUpdate.nextToLastCouponPaymentDate(
                      newDate ? newDate.format() : null,
                    )
                  }
                  value={
                    formData.digitalFloatingRateBond.nextToLastCouponPaymentDate
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="digitalFloatingRateBondForm-nextToLastCouponPaymentDate-dateFieldTextField"
                      fullWidth
                      disabled={props.apiLoading}
                      readOnly={readOnly || !draftState}
                      helperText={
                        formDataValidationResult.fieldValidations
                          .nextToLastCouponPaymentDate
                      }
                      error={
                        !!formDataValidationResult.fieldValidations
                          .nextToLastCouponPaymentDate
                      }
                    />
                  )}
                />
                <DateField
                  nullable
                  label="Coupon Interest Commencement Date"
                  id="digitalFloatingRateBondForm-couponInterestCommencementDate-dateField"
                  disabled={props.apiLoading}
                  readOnly={readOnly || !draftState}
                  onChange={(newDate) =>
                    formDataUpdate.couponInterestCommencementDate(
                      newDate ? newDate.format() : null,
                    )
                  }
                  value={
                    formData.digitalFloatingRateBond
                      .couponInterestCommencementDate
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="digitalFloatingRateBondForm-couponInterestCommencementDate-dateFieldTextField"
                      fullWidth
                      disabled={props.apiLoading}
                      readOnly={readOnly || !draftState}
                      helperText={
                        formDataValidationResult.fieldValidations
                          .couponInterestCommencementDate
                      }
                      error={
                        !!formDataValidationResult.fieldValidations
                          .couponInterestCommencementDate
                      }
                    />
                  )}
                />
              </>

              <>
                <TextField
                  id="digitalFloatingRateBondForm-terminationDateBusinessDayConvention-selectField"
                  readOnly={readOnly || !draftState}
                  disabled={props.apiLoading}
                  SelectProps={{
                    IconComponent:
                      readOnly || !draftState ? () => null : undefined,
                  }}
                  fullWidth
                  label="Termination Date Business Day Conv."
                  select
                  value={
                    formData.digitalFloatingRateBond
                      .terminationDateBusinessDayConvention
                  }
                  onChange={(e) =>
                    formDataUpdate.terminationDateBusinessDayConvention(
                      e.target.value as BusinessDayConvention,
                    )
                  }
                  error={
                    !!formDataValidationResult.fieldValidations
                      .terminationDateBusinessDayConvention
                  }
                  helperText={
                    formDataValidationResult.fieldValidations
                      .terminationDateBusinessDayConvention
                  }
                >
                  {allBusinessDayConventions.map((bdc, idx) => (
                    <MenuItem key={idx} value={idx + 1}>
                      {bdc}
                    </MenuItem>
                  ))}
                </TextField>
              </>
              <>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    disabled={props.apiLoading || readOnly || !draftState}
                    control={<Checkbox sx={{ mr: 1 }} />}
                    label={
                      <Typography color={"textPrimary"}>
                        End of Month Convention
                      </Typography>
                    }
                    checked={
                      formData.digitalFloatingRateBond.endOfMonthConvention
                    }
                    onChange={(_, checked) =>
                      formDataUpdate.endOfMonthConvention(checked)
                    }
                  />
                  <Tooltip
                    placement="top"
                    title="Indicates whether dates should be adjusted to the end of the month if they don't fall on a business day."
                  >
                    <InfoIcon
                      sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.text.secondary,
                        width: "16px",
                        height: "16px",
                        mb: 0.5,
                      })}
                    />
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    disabled={props.apiLoading || readOnly || !draftState}
                    control={<Checkbox sx={{ mr: 1 }} />}
                    label={
                      <Typography color={"textPrimary"}>
                        Fractionalisation Allowed
                      </Typography>
                    }
                    checked={
                      formData.digitalFloatingRateBond.fractionalisationAllowed
                    }
                    onChange={(_, checked) =>
                      formDataUpdate.fractionalisationAllowed(checked)
                    }
                  />
                  <Tooltip
                    placement="top"
                    title="Indicates whether bond units may be broken down into non-integer values."
                  >
                    <InfoIcon
                      sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.text.secondary,
                        width: "16px",
                        height: "16px",
                        mb: 0.5,
                      })}
                    />
                  </Tooltip>
                </Box>
              </>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* --------------- Legal & Compliance -----------------------*/}
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={(theme) => ({
              pl: 4,
              flexDirection: "row-reverse",
              backgroundColor: theme.palette.custom.midnight,
            })}
            expandIcon={
              <ExpandMoreIcon
                id="digitalFloatingRateBondForm-riskAssessment-accordionToggleIconButton"
                color="primary"
              />
            }
          >
            <Typography sx={{ ml: 4 }} variant={"h6"}>
              Legal and Compliance
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <Box
              sx={(theme) => ({
                display: "grid",
                padding: theme.spacing(3, 4, 5, 4),
                gridTemplateColumns: "repeat(3, 1fr)",
                columnGap: theme.spacing(5),
                rowGap: theme.spacing(2),
                alignItems: "start",
              })}
            >
              <Box
                className="checkboxes"
                sx={{ display: "flex", gap: 2, width: "100%", ml: 1 }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    disabled={props.apiLoading || readOnly}
                    control={<Checkbox sx={{ mr: 1 }} />}
                    label={
                      <Typography color={"textPrimary"}>
                        Voting Rights
                      </Typography>
                    }
                    checked={formData.digitalFloatingRateBond.votingRights}
                    onChange={(_, checked) =>
                      formDataUpdate.votingRights(checked)
                    }
                  />
                  <Tooltip
                    placement="top"
                    title="Indicates whether investors are granted voting rights."
                  >
                    <InfoIcon
                      sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.text.secondary,
                        width: "16px",
                        height: "16px",
                        mb: 0.5,
                      })}
                    />
                  </Tooltip>
                </Box>
              </Box>
              <TextField
                id="digitalFloatingRateBondForm-debtSeniorityLevel-selectField"
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                SelectProps={{
                  IconComponent:
                    readOnly || !draftState ? () => null : undefined,
                }}
                fullWidth
                label="Debt Seniority Level"
                select
                value={formData.digitalFloatingRateBond.debtSeniorityLevel}
                onChange={(e) =>
                  formDataUpdate.debtSeniorityLevel(
                    e.target.value as DebtSeniorityLevel,
                  )
                }
                error={
                  !!formDataValidationResult.fieldValidations.debtSeniorityLevel
                }
                helperText={
                  formDataValidationResult.fieldValidations.debtSeniorityLevel
                }
              >
                {allDebtSeniorityLevels.map((bdc, idx) => (
                  <MenuItem key={idx} value={bdc}>
                    {bdc}
                  </MenuItem>
                ))}
              </TextField>
              <Box />

              <TextArea
                id={
                  "digitalFloatingRateBondForm-votingRightsDescription-textArea"
                }
                label={"Voting Rights Description"}
                value={formData.digitalFloatingRateBond.votingRightsDescription}
                onChange={(newValue) =>
                  formDataUpdate.votingRightsDescription(newValue)
                }
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                maxLength={500}
              />
              <TextArea
                id={"digitalFloatingRateBondForm-covenants-textArea"}
                label={"Covenants"}
                value={formData.digitalFloatingRateBond.covenants}
                onChange={(newValue) => formDataUpdate.covenants(newValue)}
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                maxLength={500}
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* --------------- Risk Assessment -----------------------*/}
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={(theme) => ({
              pl: 4,
              flexDirection: "row-reverse",
              backgroundColor: theme.palette.custom.midnight,
            })}
            expandIcon={
              <ExpandMoreIcon
                id="digitalFloatingRateBondForm-riskAssessment-accordionToggleIconButton"
                color="primary"
              />
            }
          >
            <Typography sx={{ ml: 4 }} variant={"h6"}>
              Risk Assessment
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              p: 3,
              px: 4,
              pb: 5,
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: 5,
              rowGap: 3,
            }}
          >
            <Box>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                  fontWeight: theme.typography.fontWeightMedium,
                  fontSize: "14px",
                  mb: 1,
                })}
              >
                Investor Profile
              </Typography>
              <TextField
                id="digitalFloatingRateBondForm-investorProfile-selectField"
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                fullWidth
                sx={{ mb: 2 }}
                label="Investor Profile"
                select
                SelectProps={{
                  IconComponent:
                    readOnly || !draftState ? () => null : undefined,
                }}
                value={formData.digitalFloatingRateBond.investorProfile}
                onChange={(e) =>
                  formDataUpdate.investorProfile(
                    e.target.value as InvestorProfile,
                  )
                }
                error={
                  !!formDataValidationResult.fieldValidations.investorProfile
                }
                helperText={
                  formDataValidationResult.fieldValidations.investorProfile
                }
              >
                {AllInvestorProfiles.map((bdc, idx) => (
                  <MenuItem key={idx} value={bdc}>
                    {bdc}
                  </MenuItem>
                ))}
              </TextField>
              <TextArea
                id={
                  "digitalFloatingRateBondForm-investorProfileDescription-textArea"
                }
                label={"Description"}
                value={
                  formData.digitalFloatingRateBond.investorProfileDescription
                }
                onChange={(newValue) =>
                  formDataUpdate.investorProfileDescription(newValue)
                }
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                maxLength={500}
              />
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                  fontWeight: theme.typography.fontWeightMedium,
                  fontSize: "14px",
                  mb: 1,
                })}
              >
                Risk Profile
              </Typography>
              <TextField
                id="digitalFloatingRateBondForm-riskProfile-selectField"
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                fullWidth
                label="Risk Profile"
                select
                sx={{ mb: 2 }}
                SelectProps={{
                  IconComponent:
                    readOnly || !draftState ? () => null : undefined,
                }}
                value={formData.digitalFloatingRateBond.riskProfile}
                onChange={(e) =>
                  formDataUpdate.riskProfile(
                    e.target.value as InstrumentRiskProfile,
                  )
                }
                error={!!formDataValidationResult.fieldValidations.riskProfile}
                helperText={
                  formDataValidationResult.fieldValidations.riskProfile
                }
              >
                {AllInstrumentRiskProfiles.map((bdc, idx) => (
                  <MenuItem key={idx} value={bdc}>
                    {bdc}
                  </MenuItem>
                ))}
              </TextField>
              <TextArea
                id={
                  "digitalFloatingRateBondForm-riskProfileDescription-textArea"
                }
                label={"Description"}
                value={formData.digitalFloatingRateBond.riskProfileDescription}
                onChange={(newValue) =>
                  formDataUpdate.riskProfileDescription(newValue)
                }
                readOnly={readOnly || !draftState}
                disabled={props.apiLoading}
                maxLength={500}
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* --------------- Documents -----------------------*/}
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={(theme) => ({
              pl: 4,
              flexDirection: "row-reverse",
              backgroundColor: theme.palette.custom.midnight,
            })}
            expandIcon={
              <ExpandMoreIcon
                id="digitalFloatingRateBondForm-riskAssessment-accordionToggleIconButton"
                color="primary"
              />
            }
          >
            <Typography sx={{ ml: 4 }} variant={"h6"}>
              Documents
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              padding: theme.spacing(3, 4, 5),
            })}
          >
            <Documents
              showButton={
                formData.digitalFloatingRateBond.bondState() === BondState.Draft
              }
              creatingNewInstrument={creatingNewInstrument}
              documents={formData.digitalFloatingRateBond.supportingDocuments}
              onDelete={async (doc) => {
                props.setAPILoading(true);
                try {
                  const response =
                    await DigitalFloatingRateBondUpdater.DeleteDigitalFloatingRateBondSupportingDocument(
                      {
                        context: authContext,
                        digitalFloatingRateBondID:
                          formData.digitalFloatingRateBond.assetID(),
                        supportingDocumentID: doc.id,
                      },
                    );
                  formDataUpdate.digitalFloatingRateBond(
                    response.digitalFloatingRateBond,
                  );
                  props.setAPILoading(false);
                } catch (e) {
                  props.setAPILoading(false);
                  console.error(e);
                }
              }}
              onDocumentUpload={async (doc) => {
                props.setAPILoading(true);
                let updatedDigitalFloatingRateBond =
                  formData.digitalFloatingRateBond;
                try {
                  updatedDigitalFloatingRateBond =
                    await handleUpdateSupportingDocs(doc);
                  formDataUpdate.digitalFloatingRateBond(
                    updatedDigitalFloatingRateBond,
                  );
                  props.setAPILoading(false);
                  navigate(
                    `${window.location.pathname}?id=${updatedDigitalFloatingRateBond.id}&mode=${FormMode.View}`,
                  );
                  updatedDigitalFloatingRateBond.supportingDocuments =
                    formData.digitalFloatingRateBond.supportingDocuments.concat(
                      doc,
                    );
                  props.onDigitalFloatingRateBondChange(
                    new DigitalFloatingRateBond(updatedDigitalFloatingRateBond),
                  );
                } catch (e) {
                  props.setAPILoading(false);
                }
                props.setAPILoading(false);
              }}
            />
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </>
  );
};
