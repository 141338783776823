import {
  Calendar,
  DateGenerationRule,
  DigitalFloatingRateBond,
  InstrumentRiskProfile,
  InvestorProfile,
} from "../../../../../james/financial";
import { FormData } from "../index";
import { ValidationResult } from "../../../../../common/validation";
import dayjs from "dayjs";
import { DebtSeniorityLevel } from "../../../../../james/financial/DebtSeniorityLevel";
import { InstrumentOwnershipLimitations } from "../../../../../james/financial/InstrumentOwnershipLimitations";
import { Amount, Token } from "../../../../../james/ledger";
import BigNumber from "bignumber.js";
import { CouponFrequency } from "../../../../../james/financial/CouponFrequency";
import { DayCountConvention } from "../../../../../james/financial/DayCountConvention";
import { BusinessDayConvention } from "../../../../../james/financial/BusinessDayConvention";
import { RateSource } from "@mesh/common-js/dist/financial/rateSource_pb";

export type DigitalFloatingRateBondFormData = {
  generalFormData: FormData;
  digitalFloatingRateBond: DigitalFloatingRateBond;
};

export type FormUpdaterSpecsType = {
  digitalFloatingRateBond: (
    digitalFloatingRateBond: DigitalFloatingRateBond,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  name: (
    name: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  isin: (
    isin: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  fractionalisationAllowed: (
    fractionalisationAllowed: boolean,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  investorProfile: (
    investorProfile: InvestorProfile,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  investorProfileDescription: (
    investorProfileDescription: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  riskProfile: (
    riskProfile: InstrumentRiskProfile,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  riskProfileDescription: (
    riskProfileDescription: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  debtSeniorityLevel: (
    debtSeniorityLevel: DebtSeniorityLevel,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  covenants: (
    covenants: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  votingRights: (
    votingRights: boolean,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  votingRightsDescription: (
    votingRights: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  ownershipLimitations: (
    ownershipLimitations: InstrumentOwnershipLimitations,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  issuePrice: (
    issuePrice: Amount,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  nominal: (
    nominal: Amount,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  currencyStablecoinToken: (
    token: Token,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;

  // --------------- FixedRateBondStub fields (i.e. fields required for calculations) ----------------------
  totalNominal: (
    totalNominal: Amount,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  redemption: (
    redemption: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  issueDate: (
    issueDate: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  maturityDate: (
    maturityDate: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  calendar: (
    calendar: Calendar,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  couponPaymentFrequency: (
    couponPaymentFrequency: CouponFrequency,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  firstCouponPaymentDate: (
    firstCouponPaymentDate: string | null,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  nextToLastCouponPaymentDate: (
    nextToLastCouponPaymentDate: string | null,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  couponInterestCommencementDate: (
    couponInterestCommencementDate: string | null,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  dayCountConvention: (
    dayCountConvention: DayCountConvention,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  endOfMonthConvention: (
    endOfMonthConvention: boolean,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  businessDayConvention: (
    businessDayConvention: BusinessDayConvention,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  terminationDateBusinessDayConvention: (
    terminationDateBusinessDayConvention: BusinessDayConvention,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  dateGenerationRule: (
    dateGenerationRule: DateGenerationRule,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  fixDays: (
    fixDays: number,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  exCouponDays: (
    exCouponDays: number,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  recordDays: (
    recordDays: number,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  couponRateSource: (
    rateSource: RateSource,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
  couponRateVariance: (
    rateSource: BigNumber,
    prevFormData?: DigitalFloatingRateBondFormData,
  ) => DigitalFloatingRateBondFormData;
};

export const formDataUpdaterSpecs: FormUpdaterSpecsType = {
  digitalFloatingRateBond(
    digitalFloatingRateBond: DigitalFloatingRateBond,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        digitalFloatingRateBond,
      ),
    };
  },
  name(
    name: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // perform update
    formData.digitalFloatingRateBond.name =
      name.length > 60 ? formData.digitalFloatingRateBond.name : name;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  isin(
    isin: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // perform update
    formData.digitalFloatingRateBond.isin =
      isin.length > 20 ? formData.digitalFloatingRateBond.isin : isin;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  fractionalisationAllowed(
    fractionalisationAllowed: boolean,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.fractionalisationAllowed =
      fractionalisationAllowed;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },

  investorProfile(
    investorProfile: InvestorProfile,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.investorProfile = investorProfile;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  investorProfileDescription(
    investorProfileDescription: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.investorProfileDescription =
      investorProfileDescription.length > 400
        ? formData.digitalFloatingRateBond.investorProfileDescription
        : investorProfileDescription;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  riskProfile(
    riskProfile: InstrumentRiskProfile,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.riskProfile = riskProfile;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  riskProfileDescription(
    riskProfileDescription: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.riskProfileDescription =
      riskProfileDescription.length > 400
        ? formData.digitalFloatingRateBond.riskProfileDescription
        : riskProfileDescription;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  debtSeniorityLevel(
    debtSeniorityLevel: DebtSeniorityLevel,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.debtSeniorityLevel = debtSeniorityLevel;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  covenants(
    covenants: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.covenants =
      covenants.length > 200
        ? formData.digitalFloatingRateBond.covenants
        : covenants;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  votingRights(
    votingRights: boolean,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.votingRights = votingRights;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  votingRightsDescription(
    votingRightsDescription: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.votingRightsDescription =
      votingRightsDescription.length > 400
        ? formData.digitalFloatingRateBond.votingRightsDescription
        : votingRightsDescription;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  ownershipLimitations(
    ownershipLimitations: InstrumentOwnershipLimitations,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.ownershipLimitations =
      ownershipLimitations;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  issuePrice(
    issuePrice: Amount,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.issuePrice = issuePrice;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  nominal(
    nominal: Amount,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.nominal = nominal;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  currencyStablecoinToken(
    token: Token,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.issuePrice = token.newAmountOf(
      formData.digitalFloatingRateBond.issuePrice.value,
    );
    formData.digitalFloatingRateBond.nominal = token.newAmountOf(
      formData.digitalFloatingRateBond.nominal.value,
    );
    formData.digitalFloatingRateBond.totalNominal = token.newAmountOf(
      formData.digitalFloatingRateBond.totalNominal.value,
    );
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },

  // --------------- FixedRateBondStub fields (i.e. fields required for calculations) ----------------------
  totalNominal(
    totalNominal: Amount,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.totalNominal = totalNominal;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  redemption(
    redemption: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.redemption = new BigNumber(redemption);
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  issueDate(
    issueDate: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // parse given date
    const newIssueDate = dayjs(issueDate);

    // find associated currency stablecoin and underlying currency
    const ccyStablecoin = formData.generalFormData.currencyStablecoins.find(
      (ccyStablecoin) =>
        ccyStablecoin.token.isEqualTo(
          formData.digitalFloatingRateBond.totalNominal.token,
        ),
    );
    const ccy = formData.generalFormData.currencies.find(
      (ccy) => ccy.currencyID() === ccyStablecoin?.currencyID,
    );

    // confirm currency found and date valid
    if (!ccy || !newIssueDate.isValid()) {
      return {
        ...formData,
      };
    }

    // adjust dates appropriately
    formData.digitalFloatingRateBond.issueDate = ccy.firstStartOfDayBefore(
      ccy.firstCutOffAfter(newIssueDate.format()),
    );

    // update bond
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  maturityDate(
    maturityDate: string,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // parse given date
    const newMaturityDate = dayjs(maturityDate);

    // find associated currency stablecoin and underlying currency
    const ccyStablecoin = formData.generalFormData.currencyStablecoins.find(
      (ccyStablecoin) =>
        ccyStablecoin.token.isEqualTo(
          formData.digitalFloatingRateBond.totalNominal.token,
        ),
    );
    const ccy = formData.generalFormData.currencies.find(
      (ccy) => ccy.currencyID() === ccyStablecoin?.currencyID,
    );

    // confirm currency found and date valid
    if (!ccy || !newMaturityDate.isValid()) {
      return {
        ...formData,
      };
    }

    // adjust dates appropriately
    formData.digitalFloatingRateBond.maturityDate = ccy.firstStartOfDayBefore(
      ccy.firstCutOffAfter(newMaturityDate.format()),
    );

    // update bond
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  calendar(
    calendar: Calendar,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.calendar = calendar;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  couponPaymentFrequency(
    couponPaymentFrequency: CouponFrequency,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.couponPaymentFrequency =
      couponPaymentFrequency;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  firstCouponPaymentDate(
    firstCouponPaymentDate: string | null,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    if (firstCouponPaymentDate === null) {
      return {
        ...formData,
        digitalFloatingRateBond: new DigitalFloatingRateBond(
          formData.digitalFloatingRateBond,
        ),
      };
    }

    // parse given date
    const newFirstCouponPaymentDate = dayjs(firstCouponPaymentDate);

    // find associated currency stablecoin and underlying currency
    const ccyStablecoin = formData.generalFormData.currencyStablecoins.find(
      (ccyStablecoin) =>
        ccyStablecoin.token.isEqualTo(
          formData.digitalFloatingRateBond.totalNominal.token,
        ),
    );
    const ccy = formData.generalFormData.currencies.find(
      (ccy) => ccy.currencyID() === ccyStablecoin?.currencyID,
    );

    // confirm currency found and date valid
    if (!ccy || !newFirstCouponPaymentDate.isValid()) {
      formData.digitalFloatingRateBond.firstCouponPaymentDate = null;
      return {
        ...formData,
        digitalFloatingRateBond: new DigitalFloatingRateBond(
          formData.digitalFloatingRateBond,
        ),
      };
    }

    // adjust dates appropriately
    formData.digitalFloatingRateBond.firstCouponPaymentDate =
      ccy.firstStartOfDayBefore(
        ccy.firstCutOffAfter(newFirstCouponPaymentDate.format()),
      );

    // update bond
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  nextToLastCouponPaymentDate(
    nextToLastCouponPaymentDate: string | null,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    if (nextToLastCouponPaymentDate === null) {
      return {
        ...formData,
        digitalFloatingRateBond: new DigitalFloatingRateBond(
          formData.digitalFloatingRateBond,
        ),
      };
    }

    // parse given date
    const newNextToLastCouponPaymentDate = dayjs(nextToLastCouponPaymentDate);

    // find associated currency stablecoin and underlying currency
    const ccyStablecoin = formData.generalFormData.currencyStablecoins.find(
      (ccyStablecoin) =>
        ccyStablecoin.token.isEqualTo(
          formData.digitalFloatingRateBond.totalNominal.token,
        ),
    );
    const ccy = formData.generalFormData.currencies.find(
      (ccy) => ccy.currencyID() === ccyStablecoin?.currencyID,
    );

    // confirm currency found and date valid
    if (!ccy || !newNextToLastCouponPaymentDate.isValid()) {
      formData.digitalFloatingRateBond.nextToLastCouponPaymentDate = null;
      return {
        ...formData,
        digitalFloatingRateBond: new DigitalFloatingRateBond(
          formData.digitalFloatingRateBond,
        ),
      };
    }

    // adjust dates appropriately
    formData.digitalFloatingRateBond.nextToLastCouponPaymentDate =
      ccy.firstStartOfDayBefore(
        ccy.firstCutOffAfter(newNextToLastCouponPaymentDate.format()),
      );

    // update bond
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  couponInterestCommencementDate(
    couponInterestCommencementDate: string | null,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    if (couponInterestCommencementDate === null) {
      return {
        ...formData,
        digitalFloatingRateBond: new DigitalFloatingRateBond(
          formData.digitalFloatingRateBond,
        ),
      };
    }

    // parse given date
    const newCouponInterestCommencementDate = dayjs(
      couponInterestCommencementDate,
    );

    // find associated currency stablecoin and underlying currency
    const ccyStablecoin = formData.generalFormData.currencyStablecoins.find(
      (ccyStablecoin) =>
        ccyStablecoin.token.isEqualTo(
          formData.digitalFloatingRateBond.totalNominal.token,
        ),
    );
    const ccy = formData.generalFormData.currencies.find(
      (ccy) => ccy.currencyID() === ccyStablecoin?.currencyID,
    );

    // confirm currency found and date valid
    if (!ccy || !newCouponInterestCommencementDate.isValid()) {
      formData.digitalFloatingRateBond.couponInterestCommencementDate = null;
      return {
        ...formData,
        digitalFloatingRateBond: new DigitalFloatingRateBond(
          formData.digitalFloatingRateBond,
        ),
      };
    }

    // adjust dates appropriately
    formData.digitalFloatingRateBond.couponInterestCommencementDate =
      ccy.firstStartOfDayBefore(
        ccy.firstCutOffAfter(newCouponInterestCommencementDate.format()),
      );

    // update bond
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  dayCountConvention(
    dayCountConvention: DayCountConvention,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.dayCountConvention = dayCountConvention;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  endOfMonthConvention(
    endOfMonthConvention: boolean,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.endOfMonthConvention =
      endOfMonthConvention;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  businessDayConvention(
    businessDayConvention: BusinessDayConvention,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.businessDayConvention =
      businessDayConvention;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  terminationDateBusinessDayConvention(
    terminationDateBusinessDayConvention: BusinessDayConvention,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.terminationDateBusinessDayConvention =
      terminationDateBusinessDayConvention;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  dateGenerationRule(
    dateGenerationRule: DateGenerationRule,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.dateGenerationRule = dateGenerationRule;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  fixDays(
    fixDays: number,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.fixDays = fixDays;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  exCouponDays(
    exCouponDays: number,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.exCouponDays = exCouponDays;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  recordDays(
    recordDays: number,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.recordDays = recordDays;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  couponRateSource: (
    couponRateSource: RateSource,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData => {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.couponRateSource = couponRateSource;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
  couponRateVariance: (
    couponRateVariance: BigNumber,
    prevFormData?: DigitalFloatingRateBondFormData,
  ): DigitalFloatingRateBondFormData => {
    const formData = prevFormData as DigitalFloatingRateBondFormData;

    // update bond
    formData.digitalFloatingRateBond.couponRateVariance = couponRateVariance;
    return {
      ...formData,
      digitalFloatingRateBond: new DigitalFloatingRateBond(
        formData.digitalFloatingRateBond,
      ),
    };
  },
};

const isinRegex = /\b[A-Z]{2}[A-Z0-9]{9}[0-9]\b/;

export const formDataValidationFunc = async (
  formData: DigitalFloatingRateBondFormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  // name
  if (formData.digitalFloatingRateBond.name.length === 0) {
    validationResult.valid = false;
    validationResult.fieldValidations.name = "Cannot be blank";
  } else if (formData.digitalFloatingRateBond.name.length < 3) {
    validationResult.valid = false;
    validationResult.fieldValidations.name = "Cannot be less than 3 characters";
  }

  // isin
  if (
    formData.digitalFloatingRateBond.isin.length > 0 &&
    !isinRegex.test(formData.digitalFloatingRateBond.isin)
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.name = "Must be a valid ISIN";
  }

  // investor profile description
  if (
    formData.digitalFloatingRateBond.investorProfileDescription.length === 0
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.investorProfileDescription =
      "Cannot be blank";
  }

  // risk profile description
  if (formData.digitalFloatingRateBond.riskProfileDescription.length === 0) {
    validationResult.valid = false;
    validationResult.fieldValidations.riskProfileDescription =
      "Cannot be blank";
  }

  // risk profile description
  if (
    formData.digitalFloatingRateBond.votingRights &&
    formData.digitalFloatingRateBond.votingRightsDescription.length === 0
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.votingRightsDescription =
      "Cannot be blank";
  }

  // issue price
  if (formData.digitalFloatingRateBond.issuePrice.value.lte(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations.issuePrice = "Must be greater than 0";
  }

  // nominal
  if (formData.digitalFloatingRateBond.nominal.value.lte(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations.nominal = "Must be greater than 0";
  }

  // --------------- FixedRateBondStub fields (i.e. fields required for calculations) ----------------------

  // total nominal
  if (formData.digitalFloatingRateBond.totalNominal.value.lte(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations.totalNominal = "Must be greater than 0";
  } else if (
    formData.digitalFloatingRateBond.totalNominal.value.lte(
      formData.digitalFloatingRateBond.nominal.value,
    )
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.totalNominal =
      "Must be greater than nominal";
  }

  // redemption
  if (formData.digitalFloatingRateBond.redemption.lte(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations.redemption = "Must be greater than 0";
  }

  // issueDate
  const issueDate = dayjs(formData.digitalFloatingRateBond.issueDate);
  if (!issueDate.isValid()) {
    validationResult.valid = false;
    validationResult.fieldValidations.issueDate = "Must be a valid date";
  } else if (issueDate.isBefore(dayjs())) {
    validationResult.valid = false;
    validationResult.fieldValidations.issueDate = "Must be in the future";
  }
  // TODO: add valid business day checking for dates

  // first coupon payment date
  const firstCouponPaymentDate = dayjs(
    formData.digitalFloatingRateBond.firstCouponPaymentDate,
  );
  if (!firstCouponPaymentDate.isValid()) {
    validationResult.valid = false;
    validationResult.fieldValidations.firstCouponPaymentDate =
      "Must be a valid date";
  }

  const nextToLastCouponPaymentDate = dayjs(
    formData.digitalFloatingRateBond.nextToLastCouponPaymentDate,
  );
  if (!nextToLastCouponPaymentDate.isValid()) {
    validationResult.valid = false;
    validationResult.fieldValidations.nextToLastCouponPaymentDate =
      "Must be valid";
  }

  // coupon interest commencement date
  const couponInterestCommencementDate = dayjs(
    formData.digitalFloatingRateBond.couponInterestCommencementDate,
  );
  if (!couponInterestCommencementDate.isValid()) {
    validationResult.valid = false;
    validationResult.fieldValidations.couponInterestCommencementDate =
      "Must be a valid date";
  }

  // maturityDate
  const maturityDate = dayjs(formData.digitalFloatingRateBond.maturityDate);
  if (!maturityDate.isValid()) {
    validationResult.valid = false;
    validationResult.fieldValidations.maturityDate = "Must be a valid date";
  }

  // issue & maturity date
  if (issueDate.isAfter(maturityDate) || issueDate.isSame(maturityDate)) {
    validationResult.valid = false;
    validationResult.fieldValidations.maturityDate = "Must be after issue date";
    validationResult.fieldValidations.issueDate =
      "Must be before maturity date";
  }

  // first coupon payment dates
  if (firstCouponPaymentDate.isBefore(issueDate)) {
    validationResult.valid = false;
    validationResult.fieldValidations.firstCouponPaymentDate =
      "should be >= issue date";
  }

  if (
    nextToLastCouponPaymentDate.isBefore(firstCouponPaymentDate) ||
    nextToLastCouponPaymentDate.isSame(firstCouponPaymentDate)
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.nextToLastCouponPaymentDate =
      "Must be after first coupon payment date";
  }

  // coupon commencement date
  if (couponInterestCommencementDate.isBefore(issueDate)) {
    validationResult.valid = false;
    validationResult.fieldValidations.couponInterestCommencementDate =
      "should be >= issue date";
  }

  if (
    couponInterestCommencementDate.isAfter(firstCouponPaymentDate) ||
    couponInterestCommencementDate.isSame(firstCouponPaymentDate)
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.couponInterestCommencementDate =
      "should be < first coupon payment date";
  }

  // calendar
  if (
    formData.digitalFloatingRateBond.calendar === Calendar.UNDEFINED_CALENDAR
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.calendar = "Must be set";
  }

  // fix days
  if (formData.digitalFloatingRateBond.fixDays <= 0) {
    validationResult.valid = false;
    validationResult.fieldValidations.fixDays = "Must be greater than 0";
  }

  // record days
  if (formData.digitalFloatingRateBond.recordDays > 0) {
    validationResult.valid = false;
    validationResult.fieldValidations.recordDays =
      "Greater than 0 not yet supported";
  }

  // exCoupon, and record days
  if (
    formData.digitalFloatingRateBond.exCouponDays <
    formData.digitalFloatingRateBond.recordDays
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.fixDays =
      "Must be greater than or equal to record days";
    validationResult.fieldValidations.recordDays =
      "Must be less than or equal to fix days";
  }

  return validationResult;
};
