// source: financial/scheduleConfigurationNonPerpetual.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var financial_businessDayConvention_pb = require('../financial/businessDayConvention_pb.js');
goog.object.extend(proto, financial_businessDayConvention_pb);
var financial_frequency_pb = require('../financial/frequency_pb.js');
goog.object.extend(proto, financial_frequency_pb);
var financial_calendar_pb = require('../financial/calendar_pb.js');
goog.object.extend(proto, financial_calendar_pb);
var financial_dateGenerationRule_pb = require('../financial/dateGenerationRule_pb.js');
goog.object.extend(proto, financial_dateGenerationRule_pb);
goog.exportSymbol('proto.financial.NonPerpetualScheduleConfiguration', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.NonPerpetualScheduleConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.NonPerpetualScheduleConfiguration.repeatedFields_, null);
};
goog.inherits(proto.financial.NonPerpetualScheduleConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.NonPerpetualScheduleConfiguration.displayName = 'proto.financial.NonPerpetualScheduleConfiguration';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.NonPerpetualScheduleConfiguration.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.NonPerpetualScheduleConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.NonPerpetualScheduleConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.NonPerpetualScheduleConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    firstscheduleddate: (f = msg.getFirstscheduleddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    secondtolastscheduleddate: (f = msg.getSecondtolastscheduleddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    frequency: jspb.Message.getFieldWithDefault(msg, 5, 0),
    businessdayconvention: jspb.Message.getFieldWithDefault(msg, 6, 0),
    enddatebusinessdayconvention: jspb.Message.getFieldWithDefault(msg, 7, 0),
    calendarsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    endofmonthconvention: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    dategenerationrule: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.NonPerpetualScheduleConfiguration}
 */
proto.financial.NonPerpetualScheduleConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.NonPerpetualScheduleConfiguration;
  return proto.financial.NonPerpetualScheduleConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.NonPerpetualScheduleConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.NonPerpetualScheduleConfiguration}
 */
proto.financial.NonPerpetualScheduleConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFirstscheduleddate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSecondtolastscheduleddate(value);
      break;
    case 5:
      var value = /** @type {!proto.financial.Frequency} */ (reader.readEnum());
      msg.setFrequency(value);
      break;
    case 6:
      var value = /** @type {!proto.financial.BusinessDayConvention} */ (reader.readEnum());
      msg.setBusinessdayconvention(value);
      break;
    case 7:
      var value = /** @type {!proto.financial.BusinessDayConvention} */ (reader.readEnum());
      msg.setEnddatebusinessdayconvention(value);
      break;
    case 8:
      var values = /** @type {!Array<!proto.financial.Calendar>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCalendars(values[i]);
      }
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEndofmonthconvention(value);
      break;
    case 10:
      var value = /** @type {!proto.financial.DateGenerationRule} */ (reader.readEnum());
      msg.setDategenerationrule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.NonPerpetualScheduleConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.NonPerpetualScheduleConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.NonPerpetualScheduleConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFirstscheduleddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSecondtolastscheduleddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBusinessdayconvention();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getEnddatebusinessdayconvention();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCalendarsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      8,
      f
    );
  }
  f = message.getEndofmonthconvention();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getDategenerationrule();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp startDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
*/
proto.financial.NonPerpetualScheduleConfiguration.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp firstScheduledDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.getFirstscheduleddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
*/
proto.financial.NonPerpetualScheduleConfiguration.prototype.setFirstscheduleddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.clearFirstscheduleddate = function() {
  return this.setFirstscheduleddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.hasFirstscheduleddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
*/
proto.financial.NonPerpetualScheduleConfiguration.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp secondToLastScheduledDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.getSecondtolastscheduleddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
*/
proto.financial.NonPerpetualScheduleConfiguration.prototype.setSecondtolastscheduleddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.clearSecondtolastscheduleddate = function() {
  return this.setSecondtolastscheduleddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.hasSecondtolastscheduleddate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Frequency frequency = 5;
 * @return {!proto.financial.Frequency}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.getFrequency = function() {
  return /** @type {!proto.financial.Frequency} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.financial.Frequency} value
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional BusinessDayConvention businessDayConvention = 6;
 * @return {!proto.financial.BusinessDayConvention}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.getBusinessdayconvention = function() {
  return /** @type {!proto.financial.BusinessDayConvention} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.financial.BusinessDayConvention} value
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.setBusinessdayconvention = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional BusinessDayConvention endDateBusinessDayConvention = 7;
 * @return {!proto.financial.BusinessDayConvention}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.getEnddatebusinessdayconvention = function() {
  return /** @type {!proto.financial.BusinessDayConvention} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.financial.BusinessDayConvention} value
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.setEnddatebusinessdayconvention = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated Calendar calendars = 8;
 * @return {!Array<!proto.financial.Calendar>}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.getCalendarsList = function() {
  return /** @type {!Array<!proto.financial.Calendar>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.financial.Calendar>} value
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.setCalendarsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.financial.Calendar} value
 * @param {number=} opt_index
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.addCalendars = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.clearCalendarsList = function() {
  return this.setCalendarsList([]);
};


/**
 * optional bool endOfMonthConvention = 9;
 * @return {boolean}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.getEndofmonthconvention = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.setEndofmonthconvention = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional DateGenerationRule dateGenerationRule = 10;
 * @return {!proto.financial.DateGenerationRule}
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.getDategenerationrule = function() {
  return /** @type {!proto.financial.DateGenerationRule} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.financial.DateGenerationRule} value
 * @return {!proto.financial.NonPerpetualScheduleConfiguration} returns this
 */
proto.financial.NonPerpetualScheduleConfiguration.prototype.setDategenerationrule = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


goog.object.extend(exports, proto.financial);
