import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import Carousel from "react-material-ui-carousel";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useFirebaseContext } from "context/Firebase";
import {
  DieMosWaitingListBanner,
  DieMosSubscribeBanner,
} from "./ExpirableBanners";
import {
  ConvergencePartnerBanner,
  DirectOwnershipBanner,
  MarketplacePortfolioBanner,
} from "./Banners";

const expirableBannersMap = {
  die_mos_waiting_list_banner: DieMosWaitingListBanner,
  die_mos_subscribe_banner: DieMosSubscribeBanner,
};

const PREFIX = "Banner";

const classes = {
  indicatorContainer: `${PREFIX}-indicatorContainer`,
  indicator: `${PREFIX}-indicator`,
  activeIndicator: `${PREFIX}-activeIndicator`,
};

const StyledCarousel = styled(Carousel)(({ theme }) => ({
  [`& .${classes.indicatorContainer}`]: {
    marginTop: theme.spacing(-4),
  },

  [`& .${classes.indicator}`]: {
    margin: theme.spacing(0, 1),
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.activeIndicator}`]: {
    color: theme.palette.primary.main,
  },
}));

export const Banner = () => {
  const [now, setNow] = useState<Date>(new Date());
  const { firebaseRemoteConfig } = useFirebaseContext();

  useEffect(() => {
    // reset the current date-time every second
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [now]);

  // construct a list of banners to render where startAt <= now <= expireAt
  const banners = useMemo(
    () =>
      Object.entries(expirableBannersMap)
        .filter(
          ([key]) =>
            firebaseRemoteConfig?.marketplaceBannersConfig &&
            key in firebaseRemoteConfig.marketplaceBannersConfig &&
            new Date(
              firebaseRemoteConfig.marketplaceBannersConfig[key].startAt,
            ) <= now &&
            new Date(
              firebaseRemoteConfig.marketplaceBannersConfig[key].expireAt,
            ) >= now,
        )
        .map(([key, Component]) => <Component key={key} />)
        .concat([
          <DirectOwnershipBanner key={1} />,
          <MarketplacePortfolioBanner key={2} />,
          <ConvergencePartnerBanner key={3} />,
        ]),
    [firebaseRemoteConfig?.marketplaceBannersConfig, now],
  );

  return (
    <StyledCarousel
      indicators={false}
      interval={10000}
      animation={"fade"}
      sx={{
        borderRadius: "8px",
      }}
      /**
       * TODO There is a bug/feature in the 3.4.2 version of the Carousel that causes a grow animation to occur on the
       *  first render when used with React 18. To disable the animation, we had have to swipe to false. However,
       *  mobile users will still be able to navigate the carousel. It is recommended to keep an eye on updates for the
       *  react-material-ui-carousel library for a fix for this issue.
       */
      swipe={false}
      indicatorContainerProps={{
        className: classes.indicatorContainer,
        style: {},
      }}
      indicatorIconButtonProps={{
        className: classes.indicator,
        style: {},
      }}
      activeIndicatorIconButtonProps={{
        className: classes.activeIndicator,
        style: {},
      }}
      NextIcon={<ChevronRightIcon id="banner-NextIcon-Button" />}
      PrevIcon={<ChevronLeftIcon id="banner-PrevIcon-Button" />}
      // sx={(theme) => ({
      //   borderBottom: `2px solid ${theme.palette.custom.cardInner}`,
      // })}
    >
      {banners}
    </StyledCarousel>
  );
};
