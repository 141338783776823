//convert shortname to componentID for data tracking
export function convertShortnameToComponentID(assetShortname: string) {
  if (assetShortname === "MOS_BFL_P2_20340430_01") {
    return "die_mos";
  }
  return assetShortname.replace(" ", "_");
}

export function convertShortnameToComponentBusinessName(
  assetShortname: string,
) {
  if (assetShortname === "MOS_BFL_P2_20340430_01") {
    return "die mos";
  }
  return assetShortname;
}

export function convertShortnameToComponentTitle(assetShortname: string) {
  if (assetShortname === "MOS_BFL_P2_20340430_01") {
    return "Die Mos";
  }
  return assetShortname;
}

export function convertShortnameToContentInteractionID(assetShortname: string) {
  if (assetShortname === "MOS_BFL_P2_20340430_01") {
    return "die-mos";
  }
  return assetShortname.replace(" ", "-");
}
