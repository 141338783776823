import React, { useCallback, useEffect, useState } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import config from "react-global-configuration";
import { ApplicantInspector } from "james/sumsub/ApplicantInspector";
import { Button, Dialog, DialogContent, DialogProps, Box } from "@mui/material";
import { useApplicationContext } from "context/Application/Application";
import { ClientKYCStatus } from "james/client";
import { useSnackbar } from "notistack";
import { Environment } from "const";
import { useGTMTriggersPusher } from "../../../../hooks/analytics/useGTMTriggersPusher";
import dayjs from "dayjs";
import { KYCSubmittedDetails } from "../../../../types/gtm";

interface onApplicantStatusChangedPayload {
  reviewStatus: string;
  reviewResult: {
    moderationComment: string;
    reviewAnswer: string;
    reviewRejectType: string;
  };
}

interface SumsubDialogProps extends DialogProps {
  onComplete?: VoidFunction;
}

export function SumsubDialog({
  open,
  onClose,
  onComplete,
  ...other
}: SumsubDialogProps) {
  const [accessToken, setAccessToken] = useState("");
  const { authContext, loginClaims, myClientKYCStatus } =
    useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { pushKYCSubmittedEvent } = useGTMTriggersPusher();

  const generateAccessToken = useCallback(async () => {
    const requestWebSDKAccessTokenResponse =
      await ApplicantInspector.GenerateAccessToken({
        context: authContext,
      });
    setAccessToken(requestWebSDKAccessTokenResponse.token);
  }, [authContext, setAccessToken]);

  useEffect(() => {
    // make request to backend service (meshUserID) => accessToken
    if (
      loginClaims.userID === "" ||
      myClientKYCStatus == ClientKYCStatus.VerifiedStatus ||
      !open
    ) {
      return;
    }

    generateAccessToken();
  }, [loginClaims, open, myClientKYCStatus, generateAccessToken]);

  const handleOnApplicantStatusChanged = useCallback(
    (payLoad: onApplicantStatusChangedPayload) => {
      switch (payLoad.reviewStatus) {
        case "pending":
          pushKYCSubmittedEvent({
            user_kyc_request_date: dayjs().format(),
            user_kyc_status: ClientKYCStatus.VerificationInProgressStatus,
          } as KYCSubmittedDetails);
          enqueueSnackbar("Identity Verification Info Submitted", {
            variant: "info",
          });
          setAccessToken("");
          onComplete && onComplete();
          break;
        case "completed":
          if (payLoad.reviewResult.reviewAnswer === "GREEN") {
            setAccessToken("");
          }
      }
    },
    [enqueueSnackbar, setAccessToken],
  );

  // see https://docs.sumsub.com/docs/handle-messages
  const handleMessage = useCallback(
    (messageType: unknown, payLoad: unknown) => {
      switch (messageType) {
        case "idCheck.onApplicantStatusChanged":
          handleOnApplicantStatusChanged(
            payLoad as onApplicantStatusChangedPayload,
          );
          return;
      }
    },
    [handleOnApplicantStatusChanged],
  );

  return (
    <Dialog open={accessToken != "" && open} fullWidth {...other}>
      <DialogContent>
        <SumsubWebSdk
          testEnv={config.get("environment") !== Environment.Production}
          accessToken={accessToken}
          expirationHandler={generateAccessToken}
          onMessage={handleMessage}
        />
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.main,
              width: "200px",
            })}
            onClick={(e) => onClose && onClose(e, "backdropClick")}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
