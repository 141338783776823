import React from "react";
import { Box, Button, Typography } from "@mui/material";
import background from "./background.jpg";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

export const DieMosWaitingListBanner = () => {
  return (
    <Box
      id="die_mos_explore_bond_banner"
      sx={{
        height: "200px",
        backgroundSize: "1280px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundImage: `url(${background})`,
      }}
      data-component-info={JSON.stringify({
        component_id: "hero_banner",
        component_business_name: "hero banner",
        component_title: "die mos",
        component_driver: "showcase",
      })}
      data-testid="die_mos_explore_bond_banner"
    >
      <Box
        sx={(theme) => ({
          width: {
            lg: 500,
            md: 350,
            sm: 250,
          },
          ml: "600px",
          "& .red": {
            color: theme.palette.primary.main,
          },
        })}
      >
        <Typography
          textAlign="start"
          fontSize={28}
          letterSpacing={0}
          lineHeight={"32px"}
        >
          Prime+2% returns on the
        </Typography>
        <Typography
          textAlign="start"
          fontSize={28}
          letterSpacing={0}
          lineHeight={"32px"}
          pb="14px"
        >
          {" "}
          first tokenised bond in Africa.
        </Typography>
        <Button
          id="dieMosBanner-exploreBond-button"
          href="https://app.mesh.trade/public/market/asset-overview?code=ZAMOSBFL0001&issuer=GD7TASWQ6E2LIZ4XP3QWRJEKGRRXPQUPQWGKHWVQ3W4JK5ON5G7BV4QN&network=Stellar%20Public&utm_marketing_tactic=acq&utm_creative_format=image&utm_source_platform=carousel-banners"
          variant="contained"
          color="primary"
          rel="noopener noreferrer"
          target="_blank"
          data-link-info={JSON.stringify({
            content_interaction_id: "hero-banner-link",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "explore bond",
            content_interaction_type: InteractionType.Button,
            content_interaction_driver: InteractionDriver.Explore,
          } as DataLinkInfoType)}
        >
          Explore Bond
        </Button>
      </Box>
    </Box>
  );
};
