import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { ValidationResult } from "common/validation";
import { Listing, Mechanism } from "james/market";
import { SubscriptionOrderBook } from "james/market/SubscriptionOrderBook";

export type ListingFormData = {
  smartInstrumentToList: SmartInstrument;
  listing: Listing;
  subscriptionOrderBook: SubscriptionOrderBook;
};

export type FormUpdaterSpecsType = {
  smartInstrumentToList: (
    smartInstrumentToList: SmartInstrument,
    prevFormData?: ListingFormData,
  ) => ListingFormData;
  listing: (
    listing: Listing,
    prevFormData?: ListingFormData,
  ) => ListingFormData;
  subscriptionOrderBook: (
    subscriptionOrderBook: SubscriptionOrderBook,
    prevFormData?: ListingFormData,
  ) => ListingFormData;
  mechanisms: (
    mechanisms: Mechanism[],
    prevFormData?: ListingFormData,
  ) => ListingFormData;
};

export const formDataUpdaterSpecs: FormUpdaterSpecsType = {
  smartInstrumentToList(
    smartInstrumentToList: SmartInstrument,
    prevFormData?: ListingFormData,
  ): ListingFormData {
    const formData = prevFormData as ListingFormData;
    return {
      ...formData,
      smartInstrumentToList,
    };
  },
  listing(listing: Listing, prevFormData?: ListingFormData): ListingFormData {
    const formData = prevFormData as ListingFormData;
    return {
      ...formData,
      listing,
    };
  },
  subscriptionOrderBook(
    subscriptionOrderBook: SubscriptionOrderBook,
    prevFormData?: ListingFormData,
  ): ListingFormData {
    const formData = prevFormData as ListingFormData;
    return {
      ...formData,
      subscriptionOrderBook,
    };
  },
  mechanisms(
    mechanisms: Mechanism[],
    prevFormData?: ListingFormData,
  ): ListingFormData {
    const formData = prevFormData as ListingFormData;
    formData.listing.marketMechanisms = mechanisms;
    return {
      ...formData,
      listing: formData.listing,
    };
  },
};

export const formDataValidationFunc = async (
  formData: ListingFormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  if (formData.subscriptionOrderBook.overSubscriptionAmount.value.isZero()) {
    validationResult.valid = false;
  }

  return validationResult;
};
