import { SmartInstrumentLegWrapper } from "@mesh/common-js/dist/financial";
import { SmartInstrumentLegType } from "@mesh/common-js/dist/financial/smartInstrumentLegType_pb";
import { SmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLeg_pb";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { ValidationResult } from "common/validation";
import {
  validateBulletSmartInstrumentLeg,
  validateDeferrableFloatingRateSmartInstrumentLeg,
} from "./components";

export type SmartInstrumentLegValidationData = {
  smartInstrument: SmartInstrument;
};

export function validateSmartInstrumentLeg(
  smartInstrumentLeg: SmartInstrumentLeg,
  data: SmartInstrumentLegValidationData,
): ValidationResult {
  switch (
    new SmartInstrumentLegWrapper(smartInstrumentLeg).smartInstrumentLegType
  ) {
    case SmartInstrumentLegType.BULLET_SMART_INSTRUMENT_LEG_TYPE: {
      const leg = smartInstrumentLeg.getBulletsmartinstrumentleg();
      if (!leg) {
        throw new TypeError("leg is not set");
      }
      return validateBulletSmartInstrumentLeg(leg, data);
    }

    case SmartInstrumentLegType.DEFERRABLE_FLOATING_RATE_SMART_INSTRUMENT_LEG_TYPE: {
      const leg =
        smartInstrumentLeg.getDeferrablefloatingratesmartinstrumentleg();
      if (!leg) {
        throw new TypeError("leg is not set");
      }
      return validateDeferrableFloatingRateSmartInstrumentLeg(leg, data);
    }

    case SmartInstrumentLegType.NON_DEFERRABLE_FLOATING_RATE_SMART_INSTRUMENT_LEG_TYPE:
      return {
        valid: false,
        fieldValidations: {},
      };

    case SmartInstrumentLegType.UNDEFINED_SMART_INSTRUMENT_LEG_TYPE:
    default:
      throw new TypeError("invalid or unknown smart instrument leg type");
  }
}
